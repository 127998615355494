import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { ForgotPasswordApi } from "./../../store/actions/userActions";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email:''
    };
    this.validator = new SimpleReactValidator();
  }

  onChangeHandler = e => {
      this.setState({[e.target.name]:e.target.value})
  }

  userFormChangeHandler = (value) => {
      this.props.UserFormChange(value);
    };


  ResetPassword =  (e) => {
    const validator = this.validator;
    if(validator.allValid()){
       this.props.dispatch(ForgotPasswordApi({ email: this.state.email }));
    }else {
        validator.showMessages();
        this.forceUpdate();
    }
  };

  render() {
    return (
      <div className="modal-body modal-body-loader">
        <span className="alert-info">
          Don't worry. Resetting your password is easy, just tell us the email address you registered with MamaChai.
        </span>
        <div className="input-filed">
          <i className="icon-email"></i>
          <input
            type="email"
            placeholder="Enter email address..."
            name="email"
            value={this.state.email}
            onChange={this.onChangeHandler.bind(this)}
          />
           {this.validator.message( "Email", this.state.email,"required|email|min:10,string|max:30,string")}
        </div>
        <div className="input-filed ">
          <Link
            to="#"
            className="btn btn-black btn-block"
            ref={(input) => {
              this.sendOtp = input;
            }}
            onClick={(e) => this.ResetPassword()}
          >
          Submit
          </Link>
        </div>
        {
          // <div className="forget-password">
          //   <Link
          //     to="#"
          //     className="cs-login-switch forgot-switch triggered-click"
          //     onClick={(e) => this.userFormChangeHandler("user_login")}
          //   >
          //   Login Here
          //   </Link>
          // </div>
        }

      </div>
    );
  }
}

export default connect()(ForgotPassword);
