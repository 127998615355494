import React, { Component} from "react";
import { bucketPath } from "../../store/helpers/common";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import {agroListing} from './../../store/actions/agroActions';
import Banner from "../Banner";
import CenterSidebar from "../CenterSidebar";

class AgroListing extends Component {
  constructor(props) {
    super(props);
    this.state = { pageOfItems: [], currentPage: 1 };
    this.favouriteList = []
  }

  componentDidMount() {
      this.props.dispatch(agroListing())
  }





  render() {
    return (
      <div className="main-section">
        <Banner name="Agro Center/Farms" description={`${this.props.agro_listing && this.props.agro_listing.data && this.props.agro_listing.data.length > 0 ? this.props.agro_listing.data.length : 0} Agro Center's Found`}/>
        <div
          className="page-section"
          style={{
            marginTop: "0px",
            paddingTop: "0px",
            paddingBottom: "10px",
            marginBottom: "0px",
          }}
        >
          <div className="container">
					<div className="row">
						<div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 agro-listing-div">
							<div className="row">
								{/* <aside className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
									<div className="filter-wrapper">
										<div className="foodbakery-filters listing-filter">
											<div className="filter-holder panel-default sort-by-left">
												<div className="filter-heading">
                            <h6><i className="icon-location-pin2"></i>Popular Area</h6>
												</div>
												<ul>
                            <li><a href="#" className="sort-by-alphabetical">Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016</a>
													</li>
                            <li><a href="#" className="sort-by-ratings">Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016</a></li>
                            <li><a href="#" className="sort-by-minimum_order_value">Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016</a>
													</li>
												</ul>
											</div>
                      </div>
									</div>
								</aside> */}

								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {
                  // <div className="listing-sorting-holder">
									// 	<div className="row">
									// 		<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									// 			<h4>{`${this.props.agro_listing && this.props.agro_listing.data && this.props.agro_listing.data.length > 0 ? this.props.agro_listing.data.length : 0} Agro Center's Found`}
									// 				<span className="pull-right list-grid-ico">
									// 			</span></h4>
									// 		</div>
									// 	</div>
									// </div>
                }

									<div className="listing simple">
										<div className="row">
                    {this.props.agro_listing && this.props.agro_listing.data && this.props.agro_listing.data.length > 0 ?
                      this.props.agro_listing.data.map((item,index) =>{
                        const building = item.building !== null && item.building !== "" ? item.building + "," : "";
                        const city = item.city !== null && item.city !== ""  ? item.city + "," : "";
                        const state = item.state !== null && item.state !== "" ? item.state + "," : "";
                        const country = item.country !== null && item.country !== "" ? item.country  : "";
                      return (
                        <div className="col-md-6  col-sm-6 col-xs-12 agro-outer-bx" key={index}>
                          <div className="agro-inner-bx">
                            <div className="img-holder">
                              <figure>
                                <Link to="#"><img src={item.profile_image && item.profile_image !== null ? bucketPath + item.profile_image : "" } className="img-list wp-post-image" alt="" /></Link>
                              </figure>
                              {item.agrocenter_status === "" || item.agrocenter_status === "CLOSED" ? (
                                <span className="restaurant-status close"><em className="bookmarkRibbon"></em>Close</span>
                              ):(
                                <span className="restaurant-status open"><em className="bookmarkRibbon"></em>Open</span>
                              )}
                            </div>
                            <div className="text-holder">
													<div className="post-title">
														<h5>
                                <Link to={`/agro-info/${item.uniqueID}/${item.agroID}`}>{item.firstname + " " + item.lastname}</Link>
															{/* <span className="sponsored text-color">Sponsored</span> */}
														</h5>
													</div>

                            {/* <span className="post-categories">
                            {
                              <>
                              <span>Type of food :
    														</span> Apple
    														Juice
                                </>
                            }

                              </span> */}
                            <div className="delivery-potions">
                              <div className="post-time">
                                <i className="icon-location-pin"></i>
                              </div>
                              <span>{building + city + state + country}</span>
                            </div>

                              <div className="delivery-potions phone-num">
                                <div className="post-time">
                                  <i className="icon-phone4"></i>
                                </div>
                                <span>{item.phone}</span>
                              </div>


													<div className="list-rating">
														<div className="rating-star">
															<span className="rating-box" style={{width: '100%'}}></span>
														</div>
														<span className="reviews">(1)</span>
													</div>
												</div>
                        {
                          //<div class="no-padding col-md-12 book-your-visit-btn"><a class="book-now">Book Your Visit</a></div>
                        }

											</div>
                        </div>
                      )}):null
                    }
										</div>
									</div>
									<div className="row">
										<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
											<div className="page-nation">
												<ul className="pagination pagination-large">
													<li className="disabled"><span>Prev</span></li>
													<li className="active"><span><Link to="#" className="page-numbers active">1</Link></span></li>
													<li><Link to="#">2</Link></li>
													<li><Link to="#">Next</Link></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
              </div>
						</div>
            <CenterSidebar center="agro"/>
					</div>
				</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
    agro_listing:state.agro.agro_listing
})

export default connect(mapStateToProps)(AgroListing);
