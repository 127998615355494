import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { IdentityContext } from "./../store/helpers/userServices";
import { bucketPath } from "./../store/helpers/common";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.storageType = localStorage.getItem("type");
  }

  submitHandler = (e) => {
    localStorage.removeItem("token");
    localStorage.removeItem("data");
    localStorage.removeItem("type");
    this.props.history.push("/");
  };

  render() {
    const currentUrl = this.props?.match?.path;
    return (
      <>
        {/* Buyer's Navbar */}
        {this.storageType === "U" && (
          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div className="user-account-nav user-account-sidebar">
              <div className="user-nav-list">
                <IdentityContext.Consumer>
                  {(context) => (
                    <div className="user-profile">
                      <div className="user-info-widget">
                        <Link to="#" className="user-img">
                          <img
                            src={
                              context?.profile_pic
                                ? `${bucketPath}${context.profile_pic}`
                                : "assets/extra-images/team-medium-img1.jpg"
                            }
                            alt="User"
                          />
                        </Link>
                        <div className="user-det-info">
                          <h3>{context.firstname ?? "Unknown"}</h3>
                          <div className="user-phne">
                            <h5>{context.phone ?? "NA"}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </IdentityContext.Consumer>
                <ul>
                  <li
                    className={
                      currentUrl === "/buyer-dashboard" ? "active" : ""
                    }
                  >
                    <Link to="/buyer-dashboard">
                      <i className="icon-dashboard3"></i>Dashboard
                    </Link>
                  </li>
                  <li
                    className={currentUrl === "/buyer-orders" ? "active" : ""}
                  >
                    <Link to="/buyer-orders" className="btn-edit-profile">
                      <i className="icon-add_shopping_cart"></i>My Food Orders
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/buyer-product-orders" ? "active" : ""
                    }
                  >
                    <Link
                      to="/buyer-product-orders"
                      className="btn-edit-profile"
                    >
                      <i className="icon-add_shopping_cart"></i>My Product
                      Orders
                    </Link>
                  </li>
                  <li
                    className={currentUrl === "/buyer-bookings" ? "active" : ""}
                  >
                    <Link to="/buyer-bookings" className="btn-edit-profile">
                      <i className="icon-file-text2"></i>My Bookings
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/buyer-account-setting" ? "active" : ""
                    }
                  >
                    <Link to="/buyer-account-setting">
                      <i className="icon-build"></i>Profile Settings
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/buyer-more-address" ? "active" : ""
                    }
                  >
                    <Link to="/buyer-more-address">
                      <i className="icon-location"></i>My Address
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/buyer-short-list" ? "active" : ""
                    }
                  >
                    <Link to="/buyer-short-list">
                      <i className="icon-heart"></i>ShortList
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/buyer-transcation" ? "active" : ""
                    }
                  >
                    <Link to="/buyer-transcation">
                      <i className="icon-file-text22"></i>Transcations
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/buyer-change-password" ? "active" : ""
                    }
                  >
                    <Link to="/buyer-change-password">
                      <i className="icon-build"></i>Change Password
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={(e) => this.submitHandler(e)}>
                      <i className="icon-log-out"></i>Sign out
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}

        {/* Restaurant's Navbar */}

        {this.storageType === "R" && (
          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div className="user-account-nav user-account-sidebar">
              <div className="user-nav-list">
                <ul>
                  <li
                    className={
                      currentUrl === "/restaurant-dashboard" ? "active" : ""
                    }
                  >
                    <Link to="/restaurant-dashboard">
                      <i className="icon-dashboard3"></i>Dashboard
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/restaurant-profile" ? "active" : ""
                    }
                  >
                    <Link to="/restaurant-profile">
                      <i className="icon-building"></i>Mama Profile
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/restaurant-menu" ? "active" : ""
                    }
                  >
                    <Link to="/restaurant-menu">
                      <i className="icon-menu5"></i>Menu Builder
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/restaurant-orders" ? "active" : ""
                    }
                  >
                    <Link to="/restaurant-orders">
                      <i className="icon-add_shopping_cart"></i>Orders
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/restaurant-cooking-classes"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/restaurant-cooking-classes">
                      <i className="icon-restaurant_menu"></i>Cooking Classes
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/restaurant-earnings" ? "active" : ""
                    }
                  >
                    <Link to="/restaurant-earnings">
                      <i className="icon-money"></i>Earnings
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/restaurant-recepies" ? "active" : ""
                    }
                  >
                    <Link to="/restaurant-recepies">
                      <i className="icon-food"></i>Recepies
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/restaurant-products" ? "active" : ""
                    }
                  >
                    <Link to="/restaurant-products">
                      <i className="icon-lastfm-square"></i>Products
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/recepies-videos" ? "active" : ""
                    }
                  >
                    <Link to="/recepies-videos">
                      <i className="icon-video-camera"></i>Videos
                    </Link>
                  </li>
                  <li className={currentUrl === "/logout-btn" ? "active" : ""}>
                    <Link
                      className="logout-btn"
                      to="#"
                      onClick={(e) => this.submitHandler(e)}
                    >
                      <i className="icon-log-out"></i>Signout
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}

        {/* Agro's Navbar */}
        {this.storageType === "AG" && (
          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div className="user-account-nav user-account-sidebar">
              <div className="user-nav-list">
                <ul className="argro-side-menu">
                  <h2>Agro Information</h2>
                  <li
                    className={
                      currentUrl === "/contact-Information" ? "active" : ""
                    }
                  >
                    <Link
                      to="/contact-Information"
                      className="btn-edit-profile"
                    >
                      <i className="icon-book"></i>Contact Information
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/location-Information" ? "active" : ""
                    }
                  >
                    <Link to="/location-Information">
                      <i className="icon-location-pin2"></i>Location Information
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/other-Information" ? "active" : ""
                    }
                  >
                    <Link to="/other-Information">
                      <i className="icon-perm_device_information"></i>Other
                      Information
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/upload-information" ? "active" : ""
                    }
                  >
                    <Link to="/upload-information">
                      <i className="icon-upload"></i>Upload Video/Logo/Pictures
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/visitor-packages" ? "active" : ""
                    }
                  >
                    <Link to="/visitor-packages">
                      <i className="fa fa-gift"></i>Packages
                    </Link>
                  </li>
                  <li className={currentUrl === "/facilities" ? "active" : ""}>
                    <Link to="/facilities">
                      <i className="far fa-building "></i>Facilities
                    </Link>
                  </li>
                  <li
                    className={currentUrl === "/all-membership" ? "active" : ""}
                  >
                    <Link to="/all-membership">
                      <i className="fa fa-users"></i>Memberships
                    </Link>
                  </li>
                  <li
                    className={currentUrl === "/agro-products" ? "active" : ""}
                  >
                    <Link to="/agro-products">
                      <i className="icon-lastfm-square"></i>Products
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={(e) => this.submitHandler(e)}>
                      <i className="icon-log-out"></i>Sign out
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}

        {/* Food's Navbar */}
        {this.storageType === "FC" && (
          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div className="user-account-nav user-account-sidebar">
              <div className="user-nav-list">
                <ul className="argro-side-menu">
                  <h2>Restaurant Information</h2>
                  <li
                    className={
                      currentUrl === "/food-center-contact-information"
                        ? "active"
                        : ""
                    }
                  >
                    <Link
                      to="/food-center-contact-information"
                      className="btn-edit-profile"
                    >
                      <i className="icon-book"></i>Contact Information
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/food-center-location-information"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/food-center-location-information">
                      <i className="icon-location-pin2"></i>Location Information
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/food-center-other-information"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/food-center-other-information">
                      <i className="icon-perm_device_information"></i>Other
                      Information
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/food-center-upload-information"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/food-center-upload-information">
                      <i className="icon-upload"></i>Upload Pictures
                    </Link>
                  </li>
                  <li
                    className={
                      currentUrl === "/food-center-bookings" ? "active" : ""
                    }
                  >
                    <Link to="/food-center-bookings">
                      <i className="icon-file-text2"></i>Bookings
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={(e) => this.submitHandler(e)}>
                      <i className="icon-log-out"></i>Sign out
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(NavBar);
