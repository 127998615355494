import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Login } from "./../../store/actions/userActions";
import SimpleReactValidator from "simple-react-validator";
import swal from 'sweetalert';
import {connect} from "react-redux" 

class RestaurantLogin extends Component {
  constructor(props){
    super(props);
    this.state = {email:'',password:'',Class:'',Message:'',TimeOut:100000, passwordHidden:true,modalType:"R",passwordtypinginfoshow:true}
    this.storageType = localStorage.getItem("type");
    this.validator = new SimpleReactValidator();
  }

  userFormChangeHandler = (value) => {
    this.props.UserFormChange(value);
  };

  submitHandler = async () => {
    const validator = this.validator;
    if(validator.allValid()){
      const response = await this.props.dispatch(Login(this.state));
      if(response.code===1){
        this.setState({email:'',password:'',Class:'success',Message:response.response},()=>{
          this.props.closeModal()
        })
        if(response.type==="R"){
          this.props.history.push('/restaurant-profile')
        }
        else{
          swal({ title: "Error!", text: " Unauthorized!", icon: "error" });
        }
      }else{
        this.setState({Class:'danger',Message:response.response})
      }
    }else{
      validator.showMessages();
      this.setState({
        passwordtypinginfoshow:false
      })
      this.forceUpdate()

    }
  }

  togglePasswordShow = () =>{
      this.setState({
        passwordHidden: !this.state.passwordHidden
      })
    }
  render() {
    return (
      <div className="modal-body">
        <p className="foodbakery-dev-login-top-msg"></p>
        <div className="cs-login-pbox">
          <div className="status status-message"></div>
          <form
            method="post"
            className="wp-user-form webkit login-form-area"
            id="ControlForm_43687" 
          >
            <div className="input-filed">
              <i className="icon-email"></i>
              <input type="text" placeholder="Email" className={`margin-bottom-0`} name="email" value={this.state.email} onChange={e=>this.setState({email:e.target.value})} />
              {this.validator.message(
                              "Email",
                              this.state.email,
                              "required|email|min:10,string|max:30,string"
                            )}
            </div>
            <div className="input-filed login-password">
              <i className="icon-unlock-alt"></i>
              <input type={this.state.passwordHidden ? "password" : "text"} className={`${this.state.password.length < 6 && (this.state.passwordtypinginfoshow || !this.state.passwordtypinginfoshow) ? "margin-bottom-0" : ""}`} name="password" value={this.state.password} onChange={e=>this.setState({password:e.target.value})} />
                {this.state.passwordHidden ? (
                  <i className="far fa-eye eye-password-icon"  onClick={() =>this.togglePasswordShow()}></i>
                ) : (
                  <i className="fa fa-eye-slash eye-password-icon" aria-hidden="true" onClick={() =>this.togglePasswordShow()}></i>
                )}
            </div>
            {this.validator.message( "Password",
                              this.state.password,
                              "required"
                            )}
            {/* {this.state.password.length < 6 && this.state.passwordtypinginfoshow ?
                            <div className="srv-validation-message">The  password must be at least 6 characters.</div>
                            :this.validator.message(
                            "Password",
                            this.state.password,
                            "required|min:6,string|max:30,string"
                          )} */}
            <div className="forget-password">
              <div className="row col-md-12">
                  <Link
                      to="#"
                      className="cs-forgot-switch forgot-switch"
                      onClick={(e) => this.userFormChangeHandler("forgot_password")}
                      >
                      Forgot Password?
                  </Link>
                  {/* <Link
                      to="#"
                      className="cs-forgot-switch forgot-switch pull-right"
                      onClick={(e) => this.userFormChangeHandler("login_phone")}
                      >
                      Login by phone?
                  </Link> */}
              </div>

            </div>
            <div className="input-filed">
              <Link to="#" className="btn btn-black btn-block" onClick={e=>this.submitHandler()}>
                Login
              </Link>
            </div>
          </form>
          <br />
          <div className="signin-tab-link forget-password">
            Don't have an account?&nbsp;
            <Link
              to="#"
              className="foodbakery-dev-login-box-btn forgot-switch"
              onClick={(e) => this.userFormChangeHandler("res_signup")}
            >
              SignUp
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(RestaurantLogin);
