import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { SignUp } from "./../../store/actions/userActions";

import {
  SendOtp,
  VerifyOtp,
  VerifyAgroOtp,
  VerifyFoodOtp,
} from "./../../store/actions/buyerActions";
import { connect } from "react-redux";
import PhoneInput, {
  parsePhoneNumber,
  getCountryCallingCode,
} from "react-phone-number-input";

const initialState = {
  showError: false,
  firstname: "",
  lastname: "",
  email: "",
  phone: "+91",
  type: "",
  password: "",
  restaurant_name: "",
  prefix: "",
  country: "",
  userTab: "info",
  otp: "",
  terms: false,
};
class UserSignup extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.formValidator = new SimpleReactValidator();
  }

  componentDidUpdate(prevProps) {
    let putVal =
      this.props.modalType === "user_signup" ||
      this.props.modalType === "user_otp"
        ? "user"
        : this.props.modalType === "res_signup"
        ? "restaurant"
        : this.props.modalType === "agro_signup" ||
          this.props.modalType === "agro_otp"
        ? "AG"
        : this.props.modalType === "food_center_signup" ||
          this.props.modalType === "food_center_otp"
        ? "FC"
        : "";

    if (this.state.type !== putVal) {
      this.setState({ type: putVal });
    }
  }

  userFormChangeHandler = (value) => {
    this.props.UserFormChange(value);
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeNameHandler = (e) => {
    this.setState(
      {
        showError: true,
        restaurant_name: e.target.value,
      },
      () => {
        const { restaurant_name } = this.state;
        let reg_name = /^[A-zA0-9_ '.-]+$/;
        //Validation to the user_name input field
        if (restaurant_name && reg_name.test(restaurant_name)) {
          this.setState({
            showError: false,
          });
        }
      }
    );
  };

  onSubmitHandler = async () => {
    if (this.props.modalType === "res_signup") {
      const validator = this.formValidator;
      if (validator.allValid()) {
        const response = await this.props.dispatch(SignUp(this.state));
        if (response === 1) {
          this.setState(initialState);
          window.$("#user-actions").modal("hide");
        }
      } else {
        validator.showMessages();
        this.forceUpdate();
      }
    }
  };

  onSendOtp = async () => {
    const { email, phone } = this.state;
    const validator = this.formValidator;
    if (validator.allValid()) {
      const response = await this.props.dispatch(
        SendOtp({ email, number: phone.replace("+", "") })
      );
      if (response.code === 1) {
        this.setState({ userTab: "Otp" });
        if (this.props.modalType === "user_signup") {
          //this.openModal("user_otp")
          this.userFormChangeHandler("user_otp");
        } else if (this.props.modalType === "agro_signup") {
          this.userFormChangeHandler("agro_otp");
        } else if (this.props.modalType === "food_center_signup") {
          this.userFormChangeHandler("food_center_otp");
        }
      }
    } else {
      validator.showMessages();
      this.forceUpdate();
    }
  };

  onOtpVerifyHandler = async () => {
    const { email, phone, otp, firstname, lastname, prefix, country } =
      this.state;
    let request_id = sessionStorage.getItem("request_id");
    const validator = this.formValidator;
    if (this.props.modalType === "user_otp") {
      if (validator.allValid()) {
        const response = await this.props.dispatch(
          VerifyOtp({
            email,
            code: otp,
            firstname,
            lastname,
            prefix,
            request_id,
            country,
            type: "user",
            phone,
          })
        );
        if (response.code === 1) {
          this.setState(initialState, () => {
            window.$("#user-actions").modal("hide");
            this.props.closeModal();
            this.props.history.push("/buyer-dashboard");
          });
        }
      } else {
        validator.showMessages();
        this.forceUpdate();
      }
    } else if (this.props.modalType === "agro_otp") {
      if (validator.allValid()) {
        const response = await this.props.dispatch(
          VerifyAgroOtp({
            email,
            code: otp,
            firstname,
            lastname,
            prefix,
            request_id,
            country,
            type: this.state.type,
            phone,
          })
        );
        if (response.code === 1) {
          this.setState(initialState, () => {
            window.$("#user-actions").modal("hide");
            this.props.closeModal();
            this.props.history.push("/contact-Information");
          });
        }
      } else {
        validator.showMessages();
        this.forceUpdate();
      }
    } else if (this.props.modalType === "food_center_otp") {
      if (validator.allValid()) {
        const response = await this.props.dispatch(
          VerifyFoodOtp({
            email,
            code: otp,
            firstname,
            lastname,
            prefix,
            request_id,
            country,
            type: this.state.type,
            phone,
          })
        );
        if (response.code === 1) {
          this.setState(initialState, () => {
            window.$("#user-actions").modal("hide");
            this.props.closeModal();
            this.props.history.push("/food-center-contact-information");
          });
        }
      } else {
        validator.showMessages();
        this.forceUpdate();
      }
    }
  };

  numberChange = (number) => {
    let phoneNumber = number ? parsePhoneNumber(number.toString()) : "";
    let prefix = "";
    const country = phoneNumber ? phoneNumber.country : "";
    if (phoneNumber) {
      if (phoneNumber.country) {
        if (getCountryCallingCode(phoneNumber.country)) {
          prefix = getCountryCallingCode(phoneNumber.country);
        }
      }
    }
    this.setState({ phone: number, prefix: prefix, country: country });
  };

  UserFormChange = (value) => {
    this.setState({ modalType: value });
  };

  NavigateToPagesHandler = () => {
    this.props.closeModal();
    window.$("body").removeClass("modal-open");
    window.$(".modal-backdrop").remove();
  };

  render() {
    const { modalType } = this.props;
    const { userTab, showError } = this.state;
    console.log("showError", showError);
    return (
      <>
        <div className="modal-body">
          <div role="tabpanel" className="tab-pane active">
            <div id="result_296069" className="status-message"></div>
            <form method="post" className="wp-user-form demo_test">
              {userTab === "info" && (
                <>
                  <div className="input-filed">
                    <i className="icon-user4"></i>
                    <input
                      type="text"
                      name="firstname"
                      value={this.state.firstname}
                      onChange={this.onChangeHandler.bind(this)}
                      placeholder="First Name"
                    />
                    {this.formValidator.message(
                      "firstname",
                      this.state.firstname,
                      "required|alpha_num_dash_space"
                    )}
                  </div>
                  <div className="input-filed">
                    <i className="icon-user4"></i>
                    <input
                      type="text"
                      name="lastname"
                      value={this.state.lastname}
                      onChange={this.onChangeHandler.bind(this)}
                      placeholder="Last Name"
                    />
                    {this.formValidator.message(
                      "lastname",
                      this.state.lastname,
                      "required|alpha_num_dash_space"
                    )}
                  </div>
                  <div className="input-filed">
                    <i className="icon-email"></i>
                    <input
                      type="text"
                      name="email"
                      value={this.state.email}
                      onChange={this.onChangeHandler.bind(this)}
                      placeholder="Email"
                    />
                    {this.formValidator.message(
                      "email",
                      this.state.email,
                      "required|email"
                    )}
                  </div>
                  {modalType === "res_signupp" && (
                    <div className="input-filed">
                      <i className="icon-unlock-alt"></i>
                      <input
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.onChangeHandler.bind(this)}
                      />
                      {this.formValidator.message(
                        "password",
                        this.state.password,
                        "required"
                      )}
                    </div>
                  )}
                  <div className="input-filed">
                    {/* <i className="icon-phone4"></i> */}
                    <PhoneInput
                      name="phone"
                      placeholder="Enter phone number"
                      value={this.state.phone}
                      onChange={(number) => this.numberChange(number)}
                      defaultCountry="IN"
                    />
                    {this.formValidator.message(
                      "phone",
                      this.state.phone,
                      "required|phone"
                    )}
                  </div>
                  {modalType === "res_signup" && (
                    <div className="input-filed">
                      <i className="icon-food"></i>
                      <input
                        type="text"
                        name="restaurant_name"
                        value={this.state.restaurant_name}
                        onChange={this.onChangeNameHandler.bind(this)}
                        placeholder="Restaurant Name"
                      />
                      {this.formValidator.message(
                        "restaurant_name",
                        this.state.restaurant_name,
                        "required"
                      )}
                      {showError && (
                        <div class="srv-validation-message">
                          The restaurant name may only contain letters, numbers,
                          dashes,apostrophe and spaces.
                        </div>
                      )}
                    </div>
                  )}
                  {(modalType === "user_signup" ||
                    modalType === "agro_signup" ||
                    modalType === "food_center_signup") && (
                    <div className="checkbox-area">
                      <input
                        type="checkbox"
                        id="terms-head"
                        className="foodbakery-dev-req-field"
                        name="terms"
                        value={this.state.terms}
                        onChange={(e) =>
                          this.setState((prevState) => ({
                            terms: !prevState.terms,
                          }))
                        }
                        checked={this.state.terms}
                      />
                      {this.formValidator.message(
                        "Terms And Conditions",
                        this.state.terms,
                        "required|accepted"
                      )}
                      <label htmlFor="terms-head">
                        By registering you confirm that you accept&nbsp;
                        <Link
                          to="/terms"
                          style={{ color: "#399900" }}
                          onClick={this.NavigateToPagesHandler}
                        >
                          Terms &amp; Conditions
                        </Link>
                        &nbsp;and&nbsp;
                        <Link
                          to="/privacy"
                          style={{ color: "#399900" }}
                          onClick={this.NavigateToPagesHandler}
                        >
                          Privacy Policy
                        </Link>
                      </label>
                    </div>
                  )}
                  <div className="side-by-side select-icon clearfix">
                    <div className="select-holder"></div>
                  </div>
                  <div className="checks-holder">
                    <div className="input-filed">
                      <Link
                        to="#"
                        className="btn btn-black btn-block"
                        onClick={(e) =>
                          modalType === "user_signup" ||
                          modalType === "agro_signup" ||
                          modalType === "food_center_signup"
                            ? this.onSendOtp()
                            : this.onSubmitHandler()
                        }
                      >
                        {modalType === "user_signup" ||
                        modalType === "agro_signup" ||
                        modalType === "food_center_signup"
                          ? "Send OTP"
                          : "Sign Up"}
                      </Link>
                    </div>
                  </div>
                </>
              )}
              {userTab === "Otp" &&
                (modalType === "agro_otp" ||
                  modalType === "user_otp" ||
                  modalType === "food_center_otp") && (
                  <>
                    <div className="form-group ">
                      <input
                        type="number"
                        name="otp"
                        placeholder="Enter OTP"
                        value={this.state.otp}
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ [e.target.name]: e.target.value })
                        }
                      />
                      {this.formValidator.message(
                        "OTP",
                        this.state.otp,
                        "required"
                      )}
                    </div>
                    <div className="side-by-side select-icon clearfix">
                      <div className="select-holder"></div>
                    </div>
                    <div className="checks-holder">
                      <div className="input-filed">
                        <Link
                          to="#"
                          className="btn btn-black btn-block"
                          onClick={(e) => this.onOtpVerifyHandler()}
                        >
                          Submit
                        </Link>
                      </div>
                    </div>
                  </>
                )}
            </form>
            <br />
            <div className="register_content"></div>
            {userTab !== "Otp" && modalType === "user_signup" && (
              <div className="create- signin-tab-link ">
                Already have an account?&nbsp;
                <Link
                  to="#"
                  className="foodbakery-dev-signup-box-btn"
                  onClick={(e) => this.userFormChangeHandler("user_login")}
                >
                  Login here
                </Link>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default connect()(UserSignup);
