import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Pagination from "./../../store/helpers/Pagination";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RestaurantCookingClassListing, DeleteCookingClass} from './../../store/actions/restaurantActions'

import swal from 'sweetalert';
class RestaurantCookingClasses extends Component {
	constructor(props) {
		super(props);
		this.state = { pageOfItems: [], currentPage: 1 };
	}

	componentDidMount() {
		this.props.dispatch(RestaurantCookingClassListing());
	}

	onChangePage = (pageOfItems, page) => {
		this.setState({ pageOfItems: pageOfItems, currentPage: page });
	};

	DeleteRow = (uniqueID) => {
		swal({
			title: "Are you sure want to delete this record?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					this.props.dispatch(DeleteCookingClass({ uniqueID }));
				} else {
					swal({ title: "Record is safe!", icon: "success", });
				}
			});
	}


	render() {
		const { cooking_class_list } = this.props.restaurant_data
		const { pageOfItems} = this.state;
		return (
			<div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
				<div className="user-dashboard">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="element-title has-border right-filters-row">
								<h5>Cooking Classes</h5>
								<Link to="/add-cooking-class" className="add-menu-item collapsed margin-bottom-10">
									Add Cooking Class
								</Link>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="user-orders-list">
								<div className="table-responsive">
									{/* <ul className="table-generic table-generic-temp"> */}
									<DragDropContext onDragEnd={e => this.onDragEnd(e)}>
										<Droppable droppableId="droppable">
											{(provided, snapshot) => (
												<ul className="table-generic table-generic-temp" {...provided.droppableProps} ref={provided.innerRef} >
													<li className="order-heading-titles">
														<div>Sr No</div>
														<div>Title</div>
														<div>Description</div>
														<div>Action</div>
													</li>
													{pageOfItems instanceof Array && pageOfItems.map((value, index) => (
														<Draggable key={index} draggableId={value.uniqueID.toString()} index={value.uniqueID}>
															{(provided, snapshot) => (
																<li className="order-heading-titles" draggable={true} key={`category_${value.uniqueID}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
																	<div>{index + 1}</div>
																	<div>{value.title}</div>
																	<div className='cookingClassDescription'><p
																className='facilitydescription'
																dangerouslySetInnerHTML={{
																	__html: value?.description ?? '<p className="facilitydescription"></p>',
																}}
																/></div>
																	<div>
																	{
																		// <Link className="action-ico view-ico"  title="View" to={"/recipe-info/" + value.recipeID}>
																		// 	<i className="fa fa-eye"></i>
																		// </Link>&nbsp;&nbsp;
																	}


																		<Link className="action-ico edit-ico"  title="Edit" to={"/edit-cooking-class/" + value.uniqueID}>
																			<i className="fa fa-edit"></i>
																		</Link>&nbsp;&nbsp;

																		<Link className="action-ico del-ico"  title="Delete" to="#" onClick={e => this.DeleteRow(value.uniqueID)}>
																			<i className="fa fa-trash"></i>
																		</Link>&nbsp;&nbsp;
																	</div>
																</li>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</ul>
											)}
										</Droppable>
									</DragDropContext>
								</div>
							</div>
						</div>
						{cooking_class_list instanceof Array && (
							<Pagination
								pageSize={10}
								items={cooking_class_list}
								onChangePage={this.onChangePage}
							/>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	restaurant_data: state.restaurant
});

export default connect(mapStateToProps)(RestaurantCookingClasses);
