import React, { PureComponent } from "react";
import Banner from "./Banner";
class DietPlan extends PureComponent {
    render() {
        return (
            <>
            {/* Main Section Start */}
    <div className="main-section diet-plan">
      <Banner name="Diet Plan"/>
      {/* 404 Page Start */}
      <div className="page-section nopadding cs-nomargin">
        <div className="container">
          <div className="row">
            <div className="page-content col-lg-12 col-md-12 col-sm-12 col-xs-12">
              {
                //<div className="cs-section-title"><h2>Diet Plan</h2></div>
              }
              <div className="page-not-found coming-soon-page">
                <div className="cs-text">
                  <span className="red-color">Coming soon</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 404 Page End */}
    </div>
            </>
        );
    }
}


export default DietPlan;
