import { combineReducers } from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import googleMapReducer from './googleMapReducer';
import BuyerReducer from './buyerReducer';
import RestaurantReducer from './restaurantReducer';
import AgroReducer from './agroReducer';
import FoodReducer from './foodReducer';

import staticReducer from './staticReducer';
import cartReducer from './cartReducer';
import adminReducer from './adminReducer';
import paymentsReducer from './paymentsReducer';
import CondimentCartReducer from './condimentCartReducer';
import CondimentReducer from './condimentReducer'; 
import AgroProductCartReducer from './AgroProductCartReducer'

const persistConfig = {key:'root',storage,whitelist:['cart','condimentCart','AgroCart']};

const rootReducer = combineReducers({
    maps:googleMapReducer,
    buyer:BuyerReducer,
    restaurant:RestaurantReducer,
    static:staticReducer,
    cart:cartReducer,
    admin:adminReducer,
    payment:paymentsReducer,
    agro:AgroReducer,
    food:FoodReducer,
    condimentCart:CondimentCartReducer,
    condiments:CondimentReducer,
    AgroCart:AgroProductCartReducer
});

export default persistReducer(persistConfig,rootReducer);
