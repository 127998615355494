import * as types from "../types/types";

const initalState = {
    mama_cart:[],
    currency:'INR'
}

const cartReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.MAMA_CART:
            return {
                ...state,
                mama_cart: action.payload
            }
        case types.MAMA_CURRENCY:
            return {
                ...state,
                currency: action.payload
            }
        default:
            return state
    }
}

export default cartReducer;