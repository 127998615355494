import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { AddCategory,CategoryListing,DeleteCategory } from '../../../store/actions/adminActions';
import Pagination from '../../../store/helpers/Pagination';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { BaseUrl } from "../../../store/helpers/common";
import Modals from '../../../modules/Html/Modals';
import ModalHeader from '../../../modules/Html/ModalHeader';
import ModalBody from '../../../modules/Html/ModalBody';

const initialState = {open:false,pageOfItems:[],currentPage:1 }

class Category extends Component {
    constructor(props) {
        super(props)
        this.formValidator = new SimpleReactValidator();
        this.state = initialState
    }

    componentDidMount(){
        this.props.dispatch(CategoryListing({type:'list',Newtype:"Admin"}))
    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    setOpen = (value) => {
        this.setState({
            open: false, isEdit: false
        });
    };

    onSubmitCategory= async (e) => {
        const {name,description,id,isEdit} = this.state
        let type = isEdit ? 'edit' : 'insert'
        const data = {name,description,type,id}
        if (this.formValidator.allValid()) {
            let response = await this.props.dispatch(AddCategory(data))
            if(response === 1){
                this.setState({
                    type:'',
                    open:false,
                    isEdit:false
                })
            }
        } else {
            this.formValidator.showMessages();
            this.forceUpdate();
          }
          e.preventDefault();
    }

    DeleteCategory = (e, uniquekey) => {
        let data={type:'delete',id:uniquekey}
        swal({
            title: "Are you sure you want to delete this Category?",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.dispatch(DeleteCategory(data));
            } else {
                console.log("you clicked on false");
            }
        });
    };

    EditCategory = (e, id) => {
        const request = new Request(`${BaseUrl}/api/category-api`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-Auth-Token": `${localStorage.getItem("admin-token")}`,
            },
            body: JSON.stringify({ id,type:'view' }),
        });
        return fetch(request)
            .then((res) => res.json())
            .then((res) => {
                const data = res.data;
                this.setState({
                    id: data.uniquekey,
                    name:data.name,
                    description:data.description,
                    isEdit: true,
                    open: true,
                });
            });
    };


    handleOpenModal =()=>{
        this.setState({
            open:true,
            name:'',
            description:'',
            isEdit:false
        })
    }

    render() {
        const {open,pageOfItems,isEdit} = this.state
        const {category_listing} = this.props
        return (
            <div>
                <div className="tab-content">
                    <div id="menu-cats-items" className="tab-pane fade active in">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="element-title">
                                    <h5>Category</h5>
                                    <Link to="#" className="add-menu-item collapsed" onClick={()=>this.handleOpenModal()}>Add Category</Link>
                                </div>
                                <div className="form-elements">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="user-orders-list">
                                                <div className="table-responsive">
                                                    <ul className="table-generic table-generic-temp">
                                                        <li className="order-heading-titles">
                                                            <div>CID</div>
                                                            <div>Name</div>
                                                            <div>Description</div>
                                                            <div>Action</div>
                                                        </li>
                                                        {pageOfItems instanceof Array &&
                                                        pageOfItems.map((value, index) => {
                                                            return (
                                                                <li className="order-heading-titles" key={`menu_${index}`}>
                                                                    <div>{value.uniquekey}</div>
                                                                    <div>{value.name}</div>
                                                                    <div>{value.description}</div>
                                                                    <div>
                                                                    <Link
                                                                        to="#"
                                                                        onClick={(e) =>this.EditCategory(e,value.uniquekey)}
                                                                    >
                                                                    <i className="fa fa-edit alert-warning"></i>
                                                                    </Link>&nbsp;&nbsp;
                                                                    <Link
                                                                        data-toggle="modal"
                                                                        to="#"
                                                                        onClick={( e ) => this.DeleteCategory(e,value.uniquekey)}
                                                                    >
                                                                        <i className="fa fa-trash alert-danger"></i>
                                                                    </Link>
                                                                    </div>

                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Pagination
                                    pageSize={20}
                                    items={category_listing}
                                    onChangePage={this.onChangePage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <Modals open={open} size="sm" >
                <ModalHeader title= {isEdit ? 'Edit Category' : 'Add Category' }  CloseModal={this.setOpen}/>
                <ModalBody>
                    <form>
                        <div className="row form-row label-star">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={this.state.name}
                                        onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                    />
                                        {this.formValidator.message( "Name", this.state.name,"required")}
                                </div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea
                                        className="form-control"
                                        name="description"
                                        value={this.state.description}
                                        onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                    >
                                    </textarea>
                                    {this.formValidator.message( "Description", this.state.description,"required")}
                                </div>
                            </div>
                        </div>
                        <div className="field-holder">
                            <Link to="#" className="add-menu-item add-menu-item-list" onClick={e => this.onSubmitCategory(e)}>Save</Link>
                        </div>
                    </form>
                </ModalBody>
            </Modals>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    category_listing:state.admin.category_listing
});

export default connect(mapStateToProps)(Category);
