import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import SimpleReactvalidator from 'simple-react-validator'
import { RestaurantAddCookingClass, ViewRestaurantCookingClass } from './../../store/actions/restaurantActions'
import { bucketPath } from '../../store/helpers/common'; 

const initialState = {
	title: '',
	price:'',
	date:'',
	description: '',
	duration:'',
	summary: '',
	editID: '',
	image: '',
	cooking_images: [],
};


const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
let start_time={}
let end_time={}
let closed = {}
let hideHour = {}
days.map(val => {
	start_time[`${val}_start`] = "";
    end_time[`${val}_end`] = "";
    closed[`${val}_closed`] = false
	hideHour[`${val}_hideHour`] = false
	return true
});
class AddCookingClass extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		Object.assign(
			this.state
		);
		this.validator = new SimpleReactvalidator();
		this.storageData = localStorage.getItem("data");
		Object.assign(this.state,start_time,end_time);
	}

	componentDidMount() {
		let uniqueID = this.props.match.params.uniqueID;
		if (uniqueID) {
			this.props.dispatch(ViewRestaurantCookingClass(uniqueID));
		}

	}

	componentDidUpdate(nextProps, prevState) {
		let particular_cooking_class_data = this.props.particular_cooking_class_data;
		const { menu_listing } = this.props.restaurant_data;
		if ((!particular_cooking_class_data || particular_cooking_class_data === nextProps.particular_cooking_class_data) || (!menu_listing || menu_listing === nextProps.menu_listing)) {
			return false;
		}
		// let selectedMenuItem = menu_listing?.filter(x => x.uniquekey == particular_cooking_class_data.menu_uniqueID)[0];
		// let ingredients = [];
		// let cooking_time_data = particular_cooking_class_data.time
		let rest_data = "";
		if(particular_cooking_class_data.time !==""){
			rest_data = JSON.parse(particular_cooking_class_data.time)
		} 
		this.setState({
			title: particular_cooking_class_data.title,
			date: particular_cooking_class_data.date,
			price: particular_cooking_class_data.price,
			description: particular_cooking_class_data.description,
			duration: particular_cooking_class_data.duration,
		 	editID: particular_cooking_class_data.uniqueID,
			image: particular_cooking_class_data.image,
			 cooking_images: particular_cooking_class_data.image ? particular_cooking_class_data.image.split(",") : [],
		})
		rest_data && rest_data.length > 0 && rest_data.map((val, i) => {
				this.setState({[`${val.day_name}_start`]:val.starttime,[`${val.day_name}_end`]:val.endtime});
				if(val.starttime === '' && val.endtime === ''){
						this.setState({[`${val.day_name}_closed`]:true,[`${val.day_name}_hideHour`]:true})
				}
			return true
		})
	}

	ClosedHours=(e,id,val)=>{
      window.$('#'+id).addClass('hide');
      this.setState({[`${val}_start`]:'',[`${val}_end`]:'',[`${val}_closed`]:true});
  }

  OpenHours=(e,id,val)=>{
      window.$('#'+id).removeClass('hide');
      this.setState({[`${val}_hideHour`]:false,[`${val}_closed`]:false})
  }

	handleDescEditorChange = (content, editor) => {
		this.setState({ description: content })
	}

	onTimeChange = (e, name) => {
      this.setState({[e.target.name]:e.target.value})
  }

	addRecepie = async (e) => {
		const { title,time,date,price,duration, description, editID } = this.state;
		let response;
		let logo_image = JSON.parse(this.storageData)?.profile_pic
		if (this.validator.allValid()) {
			let data = [];
					days.map((val) => {
						 return data.push({ day_name: val, "starttime": this.state[`${val}_start`], "endtime": this.state[`${val}_end`] });
					});
			if (editID !== "") {
				response = await this.props.dispatch(RestaurantAddCookingClass({ title,time,date,price,editID,duration,description,logo_image:logo_image,data:data }, this));
			} else {
				response = await this.props.dispatch(RestaurantAddCookingClass({ title,time,date,price,duration,description,logo_image:logo_image,data:data  }));
			}
			if (response === 1) {
				this.props.history.push('/restaurant-cooking-classes');
			}
		} else {
			this.validator.showMessages();
			this.forceUpdate()
		}
	}



	onChangeHandler = e => {
		this.setState({ [e.target.name]: e.target.value });
		if (e.target.name === "image") {
			var file = window.$('#file_icon')[0].files;
			let cooking_images = [];
			for (let i = 0; i < file.length; i++) {
				let imageFile = URL.createObjectURL(file[i])
				cooking_images.push(imageFile);
			}
			this.setState({
				cooking_images: cooking_images
			})
		}
	}

	openFileInput = e => {
		this.fileinput.click()
	}

	cookingimageDeleteHandler = () =>{
			this.setState({
				cooking_images:[],
				image:""
			})
	}

	render() {
		const { menu_listing } = this.props.restaurant_data;
		const { title,date,price, duration, description, image, editID} = this.state; 
		this.validator.purgeFields();
		let menu_option_array = []
		if (menu_listing && menu_listing.length > 0) {
			menu_listing.map((item, index) => {
				const objectnew = { label: item.name, value: item.uniquekey }
				return menu_option_array.push(objectnew);
			})
		}

		return (
			<div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">

				<div className="user-dashboard loader-holder">
					<div className="user-holder">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Title *</label>
								<input className="menu-item-title" id="title" name="title" value={title} onChange={e => this.onChangeHandler(e)} type="text"
									placeholder="Title" />
								{this.validator.message('title', title, 'required')}
							</div>
						</div>

						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Starting Date *</label>
								<input className="menu-item-title" id="date" name="date" value={date} onChange={e => this.onChangeHandler(e)} type="date"
									placeholder="Date" />
								{this.validator.message('Date', date, 'required')}
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder cook-price-input">
								<label>Price *</label>
								<span className="price-input-cooking">₹</span>
								<input className="menu-item-title" id="price" name="price" value={price} onChange={e => this.onChangeHandler(e)} type="number"
									placeholder="Price" />
								{this.validator.message('Price', price, 'required')}
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<div className="form-group row">
								<label
									className="col-md-7"
									htmlFor="recipe_image"
								>
									Images
									<small className="form-text allow-format-text" >Allowed JPG, GIF or PNG. Max size of 2MB</small>
                            </label>
								<div className="col-md-5">
									<div id="file_icon-sec" className="browse-btn-sec" style={{ display: 'block !important' }}>
										<input
											type="file"
											id="file_icon"
											ref={(input) => { this.fileinput = input; }}
											name="image"
											onChange={this.onChangeHandler}
											className="browse-menu-icon-file"
											style={{ display: 'none' }}
											accept="image/*"
											multiple
										/>
										<i
											className="fas fa-camera camera-ico"
											onClick={() => {
												this.fileinput.click();
											}}
										></i>
										{editID === "" ? this.validator.message('image', image, 'required') : null}
									</div>
								</div>
								<div className="col-md-8">
									{
										this.state.cooking_images.length > 0 && this.state.cooking_images.map((value, index) => {
											let imgSrc = ""

											if (value.includes("http://") === true || value.includes("https://") === true) {
												imgSrc = value;
											}
											else{
												imgSrc = `${bucketPath}${value}`
											}
											return (
												<div key={"img_" + index} className="add-recepie-img-video">
													<Link href="">
														<i className="fas fa-trash-alt del-profile-ico" onClick={() =>this.cookingimageDeleteHandler()}></i>
													</Link>
													<img alt="User" src={`${imgSrc}`} />
												</div>
											)

										})
									}
								</div>
							</div>
						</div>

						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Duration *</label>
								<input className="menu-item-title" id="duration" name="duration" value={duration} onChange={e => this.onChangeHandler(e)} type="text"
									placeholder="duration" />
								{this.validator.message('Duration', duration, 'required')}
							</div>
						</div>

						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
														<label className="other-information-heading">Hours Of Class</label>
														</div>
														<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
																<div className="field-holder field-holder-custom">
																		<div className="time-list">
																				<ul>
																				{
																				days.map((val, i) => {
																						return (
																								<li key={i}>
																										<div className="open-close-time opening-time">
																												<div className="day-sec">
																														<span>{val}</span>
																												</div>
																												<div className={`time-sec ${this.state[`${val}_hideHour`] ? 'hide' : ''}`} id={i} >
																														<select className="" name={`${val}_start`} value={this.state[`${val}_start`]} onChange={(e) => this.onTimeChange(e)}>
																																<option>Select</option>
																																<option value="00:00:00">12:00 AM</option>
																																<option value="01:00:00">01:00 AM</option>
																																<option value="02:00:00">02:00 AM</option>
																																<option value="03:00:00">03:00 AM</option>
																																<option value="04:00:00">04:00 AM</option>
																																<option value="05:00:00">05:00 AM</option>
																																<option value="06:00:00">06:00 AM</option>
																																<option value="07:00:00">07:00 AM</option>
																																<option value="08:00:00">08:00 AM</option>
																																<option value="09:00:00">09:00 AM</option>
																																<option value="10:00:00">10:00 AM</option>
																																<option value="11:00:00">11:00 AM</option>
																																<option value="12:00:00">12:00 PM</option>
																																<option value="13:00:00">01:00 PM</option>
																																<option value="14:00:00">02:00 PM</option>
																																<option value="15:00:00">03:00 PM</option>
																																<option value="16:00:00">04:00 PM </option>
																																<option value="17:00:00">05:00 PM</option>
																																<option value="18:00:00">06:00 PM</option>
																																<option value="19:00:00">07:00 PM</option>
																																<option value="20:00:00">08:00 PM</option>
																																<option value="21:00:00">09:00 PM</option>
																																<option value="22:00:00">10:00 PM</option>
																																<option value="23:00:00">11:00 PM</option>
																														</select>
																														<span className="option-label">to</span>
																														<select className="" name={`${val}_end`} value={this.state[`${val}_end`]} onChange={(e) => this.onTimeChange(e, `${val}_end`)} >
																																<option>Select</option>
																																<option value="00:00:00">12:00 AM</option>
																																<option value="01:00:00">01:00 AM</option>
																																<option value="02:00:00">02:00 AM</option>
																																<option value="03:00:00">03:00 AM</option>
																																<option value="04:00:00">04:00 AM</option>
																																<option value="05:00:00">05:00 AM</option>
																																<option value="06:00:00">06:00 AM</option>
																																<option value="07:00:00">07:00 AM</option>
																																<option value="08:00:00">08:00 AM</option>
																																<option value="09:00:00">09:00 AM</option>
																																<option value="10:00:00">10:00 AM</option>
																																<option value="11:00:00">11:00 AM</option>
																																<option value="12:00:00">12:00 PM</option>
																																<option value="13:00:00">01:00 PM</option>
																																<option value="14:00:00">02:00 PM</option>
																																<option value="15:00:00">03:00 PM</option>
																																<option value="16:00:00">04:00 PM </option>
																																<option value="17:00:00">05:00 PM</option>
																																<option value="18:00:00">06:00 PM</option>
																																<option value="19:00:00">07:00 PM</option>
																																<option value="20:00:00">08:00 PM</option>
																																<option value="21:00:00">09:00 PM</option>
																																<option value="22:00:00">10:00 PM</option>
																																<option value="23:00:00">11:00 PM</option>
																														</select>
																														<Link to="#" value={`${i}`}  onClick={(e)=>this.ClosedHours(e,i,val)} >
																															<i className="icon-close2"  value={`${i}`}></i>
																														</Link>
																												</div>
																												<div className={`time-sec ${this.state[`${val}_closed`] ? '' : 'hide'}`} >
																														<Link to="#" value={`${i}`}  onClick={(e)=>this.OpenHours(e,i,val)} >
																															<i className="fa fa-plus-circle" aria-hidden="true" value={`${i}`}></i>
																														</Link>
																														 <h4>Closed</h4>
																												</div>
																										</div>

																								</li>
																						)
																				})
																				}
																		</ul>
																</div>
														</div>
												</div>

						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Description *</label>
								<Editor
									apiKey="1ivg36dmtmtmhn742kx1fndzhnxxmn9wzvo5u5bwlv6kj4kn"
									init={{
										height: 200,
										plugins: [
											"advlist autolink lists link image",
											"charmap print preview anchor help",
											"searchreplace visualblocks code",
											"insertdatetime media table paste wordcount",
										],
										menubar: 'file edit view insert format tools table help',
										toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
									}}
									value={this.state.description}
									onEditorChange={this.handleDescEditorChange}
								/>
								{this.validator.message('description', description, 'required')}
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
							<div className="field-holder">
								<Link className="add-menu-item add-menu-item-list center-btn" to="#" onClick={e => this.addRecepie(e)}>Save Class
								</Link >
							</div>
						</div>
					</div>
				</div >
			</div >
		);
	}
}

const mapStateToProps = (state) => ({
	particular_cooking_class_data: state.restaurant.particular_cooking_class_data,
	restaurant_data: state.restaurant
});

export default connect(mapStateToProps)(AddCookingClass);
