import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { detectAddress } from "./../../store/actions/googleMapActions";
import {
  GetLocalWithExpiry,
  SetLocalExpiry,
} from "./../../store/helpers/timeIntervals";
import {
  RestaurantSearchList,
  SearchNearByLocations,
  FetchLatLongs
} from "./../../store/actions/restaurantActions";
import { connect } from "react-redux";

// const locationStyle = {
//   color: "#999",
//   position: "absolute",
//   top: "11px",
//   left: "205px",
// };
class LocationSearch extends Component {
  constructor(props) {
    super(props);
    this.state = { autolocation: "", location: "", open: false };
  }

  componentDidMount() {
    //   TO FETCH THE LOCATION
    let location = GetLocalWithExpiry("location");
    if (location && location.location) {
      this.setState({ location: location.location 
      });
    }
  }

  detectLocation = async (city = false) => {
    let getData = sessionStorage.getItem("_lcn_event");
    !getData &&
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;
          this.Savelocations(latitude, longitude, city);
        },
        (error) => {
          sessionStorage.setItem("_lcn_event", "block");
          alert(error.message);
        }
      );
  };

  Savelocations = async (latitude, longitude, city = false) => {
    let response = await this.props.dispatch(
      detectAddress({ latitude, longitude })
    );
    if (response.code === 1) {
      let location = city
        ? response.data.city
        : `${response.data.city + ", " ?? ""}${
            response.data.state + ", " ?? ""
          }${response.data.country + ", " ?? ""}${
            response.data.postal_code ?? ""
          }`;
      SetLocalExpiry("location", { location, latitude, longitude });
      this.setState(
        {
          location: location,
          autolocation: "",
        },
        () => {
          this.props.dispatch(
            RestaurantSearchList({ latitude, longitude, search: "" })
          );
          this.props._FetchLocation(location);
        }
      );
    }
  };

  FetchAndSearch = async(place_id) => { 
    const {location} = this.state
    const GetResponse = await this.props.dispatch(FetchLatLongs({place_id}));
    if(GetResponse){
      this.props.dispatch(
        RestaurantSearchList({ latitude:GetResponse.latitude??'', longitude:GetResponse.longitude??'', search: "" })
      );
      SetLocalExpiry("location", { location, latitude:GetResponse.latitude??'',longitude:GetResponse.longitude??'' });
    } 
  }

  ChangeLocation = (e) => {
    this.setState({ location: e.target.value }, () => {
      const { location } = this.state;
      this.props.dispatch(SearchNearByLocations({ location }));
      this.props._FetchLocation(location);
    });
  };

  render() {
    const {nearby_locations} = this.props 
    return (
      <Fragment>
        <li className="search-li-1">
          <span className="location-ico">
            <i className="icon-location-pin2"></i>
          </span>
          <input
            className="dropdown-toggle form-control Search-control-outer loction-control-outer"
            data-toggle="dropdown"
            id="myInput"
            name="location"
            type="text"
            placeholder="Enter Location"
            value={this.state.location}
            onChange={(e) => this.ChangeLocation(e)}
            autoComplete="off"
          />
          <span
            className={`input-group-text clear-location ${
              !this.state.location ? "hide" : ""
            }`}
            id="basic-addon1" 
          >
            <i
              className="fas fa-times"
              onMouseUp={() => {
                this.setState({ location: "" });
              }}
            ></i>
          </span>
          <div className="dropdown-menu Search-control-drop dropdown-content dropdown-menu-right">
            <h5
              className="dropdown-item add-current-location"
              to="#"
              onClick={(e) => this.detectLocation()}
            >
              <Link to="#">Detect Current Location</Link>
            </h5>
            {nearby_locations &&
              nearby_locations.map((value, index) => {
                return (
                  <Link
                    className="dropdown-item"
                    to="#"
                    key={`doctorscustom_${index}`}
                    onClick={(e) =>
                      this.setState(
                        { location: `${value.city}` },
                        () => {
                          this.FetchAndSearch(value.place_id);
                        }
                      )
                    }
                  >
                    {`${value.description}`}
                  </Link>
                );
              })} 
          </div>
        </li>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  nearby_locations: state.restaurant.nearby_locations,
});

export default connect(mapStateToProps)(LocationSearch);
