// @flow
import * as React from "react";
import Pagination from "./../../../store/helpers/Pagination";
import {bucketPath} from "./../../../store/helpers/common";
import {Link} from 'react-router-dom'

class MenuListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pageOfItems: [], currentPage: 1 };
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

  render() {
    const {menu_listing} = this.props;
    const {pageOfItems,currentPage} = this.state
    return (
      <div className="form-elements">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="user-orders-list">
              <div className="table-responsive">
                <ul className="table-generic table-generic-temp">
                  <li className="order-heading-titles">
                    <div>Sr No</div>
                    <div>Category Name</div>
                    <div>Menu Item</div>
                    <div>Price</div>
                    <div>Food Image</div>
                    <div>Description</div>
                    <div>Action</div>
                  </li>
                  {pageOfItems instanceof Array &&
                    pageOfItems.map((value, index) => {
                      return (
                        <li className="order-heading-titles" key={`menu_${index}`}>
                            <div>{(index+1)+((currentPage-1)*10)}</div>
                            <div>{value.category_name}</div>
                            <div>{value.name}</div>
                            <div>{value.price}</div>
                            <div><img className='thumbnail' src={value.image?`${bucketPath}${value.image}`:`/assets/extra-images/logo1.png`} alt={value.name}/></div>
                            <div><p className="facilitydescription">{value.description}</p></div>
                            <div><Link to="#" onClick={e=>this.props.ViewModal(value.uniquekey,'add_menu')}><i className="fa fa-eye"></i></Link>&nbsp;&nbsp;<Link to="#" onClick={e=>this.props.EditModal(value.uniquekey,'add_menu')}><i className="fa fa-edit alert-warning"></i></Link>&nbsp;&nbsp;<Link to="#" onClick={e=>this.props.DeleteRow(value.uniquekey,'menu')}><i className="fa fa-trash alert-danger"></i></Link></div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {menu_listing instanceof Array && (
          <Pagination
            pageSize={10}
            items={menu_listing}
            onChangePage={this.onChangePage}
          />
        )}
      </div>
    );
  }
}
export default MenuListing;
