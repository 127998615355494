import React,{PureComponent} from 'react'
import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Modals from './../modules/Html/Modals';
import ModalHeader from './../modules/Html/ModalHeader';
import ModalBody from './../modules/Html/ModalBody';
import { CondimentListing} from '../store/actions/restaurantActions'
import { connect } from "react-redux";
import { bucketPath } from '../store/helpers/common'
import { condimentCart,addCart,minusCart,removeCart } from '../store/actions/condimentCartActions'
import { forceCondimentModelOpen} from '../store/actions/condimentActions'
import * as RestaurantAction from '../store/actions/restaurantActions';
import SimpleReactvalidator from 'simple-react-validator'
import swal from 'sweetalert';
import Banner from "./Banner";
import CenterSidebar from "./CenterSidebar";
class MainStore extends PureComponent {
  subTotal = 0;
  pickup = 0;
  delivery = 0;
  vat   = 0;
  vatApply = 0;
  totalToPay = 0;
  constructor(props){
    super(props);
    this.state = {
      tabclass : "Condiments",
      open: false,
      cartItems:[],
      condimentPopHide:false,
      modal_type:"",
      title: '',
      description: '',
      rating: 1,
      addedIntoCard:false,
      cartAddPop:false,
      delivery_type:'delivery',
      payment_type:'card'
    }
    this.validator = new SimpleReactvalidator();
  }

  componentDidMount(){
    const urldata = this.props.match.params.urldata;
    if(urldata === "condiments"){
      this.setState({
        tabclass : "Condiments"
      },() =>{
        //this.forceUpdate()
      })
    }
    else if(urldata === "spices"){
      this.setState({
        tabclass : "Spices"
      },() =>{
        // this.setState({
        //   tabclass : "Spices"
        // })
        //this.forceUpdate()
      })
    }
    else if(urldata === "kitchen-products"){
      this.setState({
        tabclass : "KitchenProducts"
      },() =>{
        //this.forceUpdate()
      })
    }
    else if(urldata === "festival-kit"){
      this.setState({
        tabclass : "FestivalKit"
      },() =>{
        //this.forceUpdate()
      })
    }
    else if (urldata === "snacks") {
      this.setState({
        tabclass: "Snacks"
      }, () => {
        //this.forceUpdate()
      })
    }
      this.props.dispatch(CondimentListing());
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    let condiment_cart = newProps.condiment_cart ?? [];
      this.setState({ cartItems: condiment_cart });
      // if (newProps?.force_condiment_modal_open_data) {
      //   if(newProps.force_condiment_modal_open_data.model_open && newProps.force_condiment_modal_open_data.model_open === true){
      //     let filtercondiment = condiment && condiment.length > 0 ? condiment.filter(item =>item.uniquekey === newProps.force_condiment_modal_open_data.uniquekey) : [];
      //     let productID = filtercondiment && filtercondiment.length > 0 ? filtercondiment[0].productID : ""
      //     this.ViewCondiment(newProps.force_condiment_modal_open_data.uniquekey,productID);
      //     this.props.dispatch(forceCondimentModelOpen({}))
      //     //this.forceUpdate()
      //   }
      // }
    }
  condimentPopShow = (value) =>{
    this.setState({
      condimentPopHide:value
    })
  }
  condimentPopHideHandler = () =>{
    this.setState({
      condimentPopHide:false
    })
  }

  onAddCart = (value) => {
    this.props.dispatch(addCart(value,this.state));
    //this.condimentPopHideHandler()
    return null;
  };

  onMinusCart = (value) => {
    this.props.dispatch(minusCart(value,this.state));
    //this.condimentPopHideHandler()
    return null;
  };

  addedItemPop = () =>{
    this.setState({
      addedIntoCard:true
    })
    setTimeout(() => {
      this.setState({
        addedIntoCard:false,
        cartAddPop:true
      },() =>{
        setTimeout(() =>{
          this.setState({
            cartAddPop:false
          })
        },1500);
      })
  }, 2000);
  }

  cartAddPopClose = () =>{
    this.setState({
      cartAddPop:false
    })
  }





  onRemoveCart = (value) =>{
    this.props.dispatch(removeCart(value,this.props.condiment_cart))
  }
  onEditCart = (value) =>{
    const data = {
      uniquekey:value,
      model_open:true
    }
    this.props.dispatch(forceCondimentModelOpen(data))
  }

  placeOrder = e => {
    const getUserIdentity = localStorage.getItem('token');
    if(!getUserIdentity){
      swal({
        text: "You need to login to make an order!",
        icon: "info",
        buttons: true,
        dangerMode: true,
      })
      .then((yesDo) => {
        if (yesDo) {
          this.props.dispatch(RestaurantAction.ForceloginClick(true))
        } else {
          console.log("Record is Safe");
        }
      });
      return false;
    }
    const {delivery_type,payment_type} = this.state;
    let deliveryType = delivery_type==="delivery"?"D":"P";
    let order_type = "condiment";
    let data = {delivery_type:deliveryType,payment_type,pickup_cost:this.pickup,delivery_cost:this.delivery,vatPercentage:this.vat,vatApplied:this.vatApply,subTotal:this.subTotal,totalToPay:this.totalToPay,cartItems:this.props.condiment_cart,order_type:order_type};

    const promise = new Promise(async (resolve,reject)=>{
      let response = await this.props.dispatch(RestaurantAction.PlaceOrder(data));
      if(response.code===1){
        resolve(response)
      }else{
        reject()
      }
    })

    promise.then((response)=>{
      this.props.dispatch(condimentCart([]));
      if(response.type==='cash'){
        this.props.history.push('/buyer-orders')
      }else if(response.type==='card'){
        this.props.history.push(`/stripe-payment/${response.order_number}`)
      }

    })

  }

  tabclassHandler = (value) =>{
    const data = value;
    if(data === "Condiments"){
      this.setState({
        tabclass : "Condiments"
      },() =>{
        this.forceUpdate()
      })
    }
    else if(data === "Spices"){
      this.setState({
        tabclass : "Spices"
      },() =>{
        // this.setState({
        //   tabclass : "Spices"
        // })
        this.forceUpdate()
      })
    }
    else if(data === "KitchenProducts"){
      this.setState({
        tabclass : "KitchenProducts"
      },() =>{
        this.forceUpdate()
      })
    }
    else if(data === "FestivalKit"){
      this.setState({
        tabclass : "FestivalKit"
      },() =>{
        this.forceUpdate()
      })
    }
    else if (data === "Snacks") {
      this.setState({
        tabclass: "Snacks"
      }, () => {
        this.forceUpdate()
      })
    }
  }
  render(){

    const {tabclass, title, rating ,modal_type,open,delivery_type} = this.state;
    const {condiment,view_condiment,condiment_cart,condiment_review_list} = this.props
    let name = view_condiment?.name ?? '';
    let price = view_condiment?.price ?? '';
    let description = view_condiment?.description ?? '';
    let product_highlight = view_condiment?.product_highlight ?? '';
    let images = view_condiment?.image ?? ''
    let viewarrayFilter =
      condiment_cart instanceof Array &&
      condiment_cart.findIndex(
        (obj) =>
          obj.uniqueID === view_condiment.uniquekey
      );
      let condimentfilterdata = condiment && condiment.length > 0 && condiment_cart && condiment_cart.length > 0 ? condiment.filter(item =>item.uniquekey === condiment_cart[condiment_cart.length -1].uniqueID) :[]
      let total_price = 0;

      const rightCart = <div className="col-md-12 col-xs-12 condiment-left-cart condiment-sidebar-left-cart">
      <ul className="dropdown-menu add-cart-drop">
      {condiment_cart && condiment_cart.length > 0 ? (
        <>
        {condiment_cart.map((item,index) =>{
          let filterdata = condiment && condiment.filter(data =>data.uniquekey === item.uniqueID)
           total_price += parseFloat(item.price);
           this.subTotal = index === 0 ?  0 : this.subTotal;
           this.subTotal += parseFloat(item.price);
           this.vatApply = ((this.subTotal*this.vat)/100).toFixed(2);
           this.totalToPay = (parseFloat(this.subTotal)+parseFloat(this.vatApply)+parseFloat(`${delivery_type==="delivery"?this.delivery:this.pickup}`)).toFixed(2);
          return (
            <li key={index}>
              <div className="cart-items-main-div">
                <div className="row">
                    <div className="col-md-3 col-xs-3 image-area">
                      <Link className="cart-item-img" to="#"><img src={filterdata && filterdata.length > 0 ? bucketPath + filterdata[0].image :""} alt="Mamachai"/></Link>
                    </div>
                    <div className="col-md-9 col-xs-9 cart-item-name">
                      <h3><Link to="#">{item.name}</Link></h3>
                      {
                      // <>
                      // <small>{filterdata && filterdata.length > 0 && filterdata[0].quantity}</small>
                      // <div className="edit-remove">
                      //   <Link
                      //     to='#'  onClick={(e) =>
                      //     this.onEditCart(item.uniqueID)
                      //   }><i className="icon-edit2"></i></Link>
                      //   <Link
                      //     to='#'  onClick={(e) =>
                      //     this.onRemoveCart(item.uniqueID)
                      //   }><i className="icon-trash-o"></i></Link>
                      // </div>
                      // </>
                    }
                    </div>
                </div>
                    <div className="row">
                      <div className="col-md-3 col-xs-3"></div>
                      <div className="col-md-5 col-xs-5">
                      <div className={`condiment-increment-decrement-area condiment-right-cart-area `}>
                      <div className="col-md-4 col-xs-4 delete-icon-area  no-padding">
                          <Link
                            to='#'  onClick={(e) =>
                            this.onMinusCart(filterdata[0])
                          }>{item.count > 1 ? <i className="icon-minus-circle"></i> : <i className="icon-trash-o text-red condiment-delete-icon"></i>}</Link>
                        </div>
                                    <div className="col-md-4 col-xs-4 no-padding"><p className="count-area">{item.count}</p></div>
                        <div className="col-md-4 col-xs-4 no-padding"><Link
                          to='#'  onClick={(e) =>
                          this.onAddCart(filterdata[0])
                        }><i className="icon-plus-circle"></i></Link></div>
                      </div>
                      </div>
                      <div className="col-md-4 col-xs-4 no-padding">
                        <p className="cart-item-price">Rs {item.price}</p>
                      </div>
                    </div>
                    {
                  //   <>
                  // <div className="col-md-2">
                  //   <Link to="#" className="cart-item-count">{item.count}</Link>
                  // </div>
                  // <div className="col-md-2">
                  //   <p className="cart-item-price">RS {item.price}</p>
                  // </div>
                  // </>
                }
              </div>
            </li>
          )
        })}
        <div className="check-out-btn-div">
          <Link to="#" className="btn btn-red btn-chek-out-drop btn-block right-condiment-cart-checkout" onClick={e=>this.placeOrder(e)}>Go to Checkout
          <span className="total-price">Rs {total_price}</span>
          </Link>
        </div>
        </>
      ):(
        <div className="check-out-btn-div cart-empty-area">
          <Link to="#" className="btn btn-red btn-chek-out-drop btn-block">Cart is Empty
          </Link>
        </div>
      )}
      </ul>
      </div>
    return(
      <>
      <div className="main-section diet-plan">
        <Banner name="Store"/>
        <div className='container' >
        {
          condiment_cart && condiment_cart.length > 0 && (
          <div className={`cart-items-main-div cart-items-main-div-2 col-md-4 ${this.state.addedIntoCard === false ? "small-pop-up" : ""}`}>
              <div className="col-md-2">
            <Link className="cart-item-img" to="#"><img src={condimentfilterdata && condimentfilterdata.length > 0 ? bucketPath + condimentfilterdata[0].image : ""} alt="Mamachai"/></Link>
              </div>
              <div className="col-md-5 cart-item-name">
                <h3><Link to="#">{condiment_cart[condiment_cart.length -1].name}</Link></h3>
                <small>{condimentfilterdata && condimentfilterdata.length > 0 && condimentfilterdata[0].quantity}</small>
              </div>
              <div className="col-md-2">
                <Link to="#" className="cart-item-count">{condiment_cart[condiment_cart.length -1].count}</Link>
              </div>
              <div className="col-md-3">
                <p className="cart-item-price">RS {condiment_cart[condiment_cart.length -1].price}</p>
              </div>
          </div>
        )}

        <div className={`cart-add-popup col-md-4 ${this.state.cartAddPop === false ? "cart-add-pop-hide" : ""} `}>
          <button type="button" className="close" onClick={this.cartAddPopClose}><span aria-hidden="true">×</span></button>
                  <span>Added to Cart</span>
        </div>
        <OwlCarousel items={5}

          className="owl-theme store-carousel"
          id="store-carousel"
          dots={false}
          loop={true}
          margin={10}
          nav
          responsiveclassname="true"
          responsive={{
                0: {
                  items: 1,
                  nav: true
                },
                600: {
                  items: 2,
                  nav: true
                },
                800: {
                  items: 3,
                  nav: true
                },
                1000: {
                  items: 5,
                  nav: true,
                  loop: false,
                  margin: 20
                }
              }
            }>

           <div >
                <Link to="#" className={`${tabclass === "Condiments" ? "active" : ""}`}  onClick = {(e) =>this.tabclassHandler("Condiments")}>
                  <img className="img" alt="" src={'/assets/extra-images/store-ico-1.png'}/>
                  <h3>Condiments</h3>
                </Link>
            </div>

           <div>
                <Link to="#" className={`${tabclass === "Spices" ? "active" : ""}`}  onClick = {(e) =>this.tabclassHandler("Spices")}>
                  <img className="img" alt="" src={'/assets/extra-images/store-ico-2.png'} />
                  <h3>Spices</h3>
                </Link>
           </div>
           <div>
                <Link to="#" className={`${tabclass === "KitchenProducts" ? "active" : ""}`}  onClick = {(e) =>this.tabclassHandler("KitchenProducts")}>
                  <img className="img" alt="" src={'/assets/extra-images/store-ico-3.png'} />
                  <h3>Kitchen Products</h3>
                </Link>
           </div>
            <div>
                <Link to="#" className={`${tabclass === "FestivalKit" ? "active" : ""}`}  onClick = {(e) =>this.tabclassHandler("FestivalKit")}>
                  <img className="img" alt="" src={'/assets/extra-images/store-ico-4.png'} />
                  <h3>Festival Kit</h3>
                </Link>
           </div>
           <div>
                <Link to="#" className={`${tabclass === "Snacks" ? "active" : ""}`}  onClick={(e) => this.tabclassHandler("Snacks")}>
                  <img className="img" alt="" src={'/assets/extra-images/store-ico-5.png'} />
                  <h3>Snacks</h3>
                </Link>
           </div>
           {/* <div><img  className="img" src= {'assets/images/banner-7.jpg'}/></div>

           <div><img  className="img" src= {'assets/images/banner-1.jpg'}/></div>
           <div><img  className="img" src= {'assets/images/banner-2.jpg'}/></div>

           <div><img  className="img" src= {'assets/images/banner-7.jpg'}/></div> */}
      </OwlCarousel>
{/* tab content start here */}
	                <div className="tab-content">

                    {tabclass === "Condiments" ? (
                      <>
                      {/* tab 1 */}
                      <div id="condinment-tab">
  											<div className="row recepies-section condiment-sections main-store-tab-sec">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 condiments-area">
                              <div className="row">
                                <div className="col-md-9 condiment-rightbody-sec">
                                    <div className="listing grid-listing three-cols">
                                    {condiment && condiment.filter(filteritem =>filteritem.category_name === "condiments").map((value,index)=>{
                                    let arrayFilter =
                                      condiment_cart instanceof Array &&
                                      condiment_cart.findIndex(
                                        (obj) =>
                                          obj.uniqueID === value.uniquekey
                                      );
                                      return (
                                        <div className="col-md-4 col-xs-6 recipe-part grid-listing-col " key={`condiment_${index}`} >
                                            <div className="condiments-inner-box">
                                                <div className="img-holder">
                                                    <figure>

                                                        <Link to="#" onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>
                                                            <img
                                                                // src="assets/extra-images/condiment-product-img.jpg"
                                                                src={`${bucketPath}${value.image}`}
                                                                alt=""
                                                                className="img-responsive"
                                                        />
                                                        </Link>
                                                        {condiment_cart instanceof Array &&
                                                        arrayFilter !== -1 ? (
                                                          <>
                                                          <Link
                                                            to='#' className="add-ico-condiment condiment-count-area"
                                                            onClick={()=>this.condimentPopShow(value.uniquekey)}
                                                          ><span className="condiment-count">{condiment_cart[arrayFilter].count}</span></Link>
                                                          <div className={`condiment-increment-decrement-area ${this.state.condimentPopHide === value.uniquekey ? "" :"hide-condiment-area"} `}>
                                                            <div className="col-md-4">
                                                              <Link
                                                                to='#'  onClick={(e) =>
                                                                this.onMinusCart(value)
                                                              }>{condiment_cart[arrayFilter].count > 1 ? <i className="icon-minus-circle"></i> : <i className="icon-trash-o text-red condiment-delete-icon"></i>}</Link>
                                                            </div>
                                                                        <div className="col-md-4 no-padding"><p>{condiment_cart[arrayFilter].count}</p></div>
                                                            <div className="col-md-4"><Link
                                                              to='#'  onClick={(e) =>
                                                              this.onAddCart(value)
                                                            }><i className="icon-plus-circle"></i></Link></div>
                                                          </div>
                                                          </>
                                                        ):(
                                                          <Link
                                                            to='#' className="add-ico-condiment" onClick={(e) =>{
                                                              this.onAddCart(value);this.addedItemPop()
                                                            }
                                                          }><i className="icon-plus"></i></Link>
                                                        )}


                                                    </figure>

                                                </div>
                                                <div className="text-holder">
                                                    <div className="listing-inner text-center">
                                                        <h5 onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>{value.name.toUpperCase()} {`(${value.quantity})`}</h5>
                                                        <p onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>{`MRP ₹${value.price}`}</p>
                                                        {
                                                        <div className="no-padding col-md-12 recipe-part food-coach-btn text-center">
                                                                <Link to="#" className="book-now" onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>View Detail</Link>

                                                                {/* <Link className="btn btn-red btn-condinment-cart pull-right" to="/">Add to Cart</Link> */}

                                                          </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      )})}

                                    </div>
                                </div>
                                  <CenterSidebar center="condiment" rightCart={rightCart}/>
                              </div>
                           </div>
                        </div>
  										</div>
                      </>
                    ):tabclass === "Spices" ? (
                      <>
                      {/* tab 2 */}
  										<div id="spices-tab">
  											<div className="row recepies-section condiment-sections main-store-tab-sec">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 condiments-area">
                              <div className="row">
                                <div className="col-md-9 condiment-rightbody-sec">
                                    <div className="listing grid-listing three-cols">
                                    {condiment && condiment.filter(filteritem =>filteritem.category_name === "Spices").map((value,index)=>{
                                    let arrayFilter =
                                      condiment_cart instanceof Array &&
                                      condiment_cart.findIndex(
                                        (obj) =>
                                          obj.uniqueID === value.uniquekey
                                      );
                                      return (
                                        <div className="col-md-4 col-xs-6 recipe-part grid-listing-col " key={`condiment_${index}`} >
                                            <div className="condiments-inner-box">
                                                <div className="img-holder">
                                                    <figure>

                                                        <Link to="#" onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>
                                                            <img
                                                                // src="assets/extra-images/condiment-product-img.jpg"
                                                                src={`${bucketPath}${value.image}`}
                                                                alt=""
                                                                className="img-responsive"
                                                        />
                                                        </Link>
                                                        {condiment_cart instanceof Array &&
                                                        arrayFilter !== -1 ? (
                                                          <>
                                                          <Link
                                                            to='#' className="add-ico-condiment condiment-count-area"
                                                            onClick={()=>this.condimentPopShow(value.uniquekey)}
                                                          ><span className="condiment-count">{condiment_cart[arrayFilter].count}</span></Link>
                                                          <div className={`condiment-increment-decrement-area ${this.state.condimentPopHide === value.uniquekey ? "" :"hide-condiment-area"} `}>
                                                            <div className="col-md-4">
                                                              <Link
                                                                to='#'  onClick={(e) =>
                                                                this.onMinusCart(value)
                                                              }>{condiment_cart[arrayFilter].count > 1 ? <i className="icon-minus-circle"></i> : <i className="icon-trash-o text-red condiment-delete-icon"></i>}</Link>
                                                            </div>
                                                                        <div className="col-md-4 no-padding"><p>{condiment_cart[arrayFilter].count}</p></div>
                                                            <div className="col-md-4"><Link
                                                              to='#'  onClick={(e) =>
                                                              this.onAddCart(value)
                                                            }><i className="icon-plus-circle"></i></Link></div>
                                                          </div>
                                                          </>
                                                        ):(
                                                          <Link
                                                            to='#' className="add-ico-condiment" onClick={(e) =>{
                                                              this.onAddCart(value);this.addedItemPop()
                                                            }
                                                          }><i className="icon-plus"></i></Link>
                                                        )}


                                                    </figure>

                                                </div>
                                                <div className="text-holder">
                                                    <div className="listing-inner text-center">
                                                        <h5 onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>{value.name.toUpperCase()} {`(${value.quantity})`}</h5>
                                                        <p onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>{`MRP ₹${value.price}`}</p>
                                                        {
                                                        <div className="no-padding col-md-12 recipe-part food-coach-btn text-center">
                                                                <Link to="#" className="book-now" onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>View Detail</Link>

                                                                {/* <Link className="btn btn-red btn-condinment-cart pull-right" to="/">Add to Cart</Link> */}

                                                          </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      )})}

                                    </div>
                                </div>
                                <CenterSidebar center="condiment" rightCart={rightCart}/>
                              </div>
                           </div>
                        </div>
  										</div>
                      </>
                    ):tabclass === "KitchenProducts" ? (
                      <>
                      {/* tab 3 */}
  										<div id="kitchan-tab">
  											<div className="row recepies-section condiment-sections main-store-tab-sec">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 condiments-area">
                              <div className="row">
                                <div className="col-md-9 condiment-rightbody-sec">
                                    <div className="listing grid-listing three-cols">
                                    {condiment && condiment.filter(filteritem =>filteritem.category_name === "Kitchen product").map((value,index)=>{
                                    let arrayFilter =
                                      condiment_cart instanceof Array &&
                                      condiment_cart.findIndex(
                                        (obj) =>
                                          obj.uniqueID === value.uniquekey
                                      );
                                      return (
                                        <div className="col-md-4 col-xs-6 recipe-part grid-listing-col " key={`condiment_${index}`} >
                                            <div className="condiments-inner-box">
                                                <div className="img-holder">
                                                    <figure>

                                                        <Link to="#" onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>
                                                            <img
                                                                // src="assets/extra-images/condiment-product-img.jpg"
                                                                src={`${bucketPath}${value.image}`}
                                                                alt=""
                                                                className="img-responsive"
                                                        />
                                                        </Link>
                                                        {condiment_cart instanceof Array &&
                                                        arrayFilter !== -1 ? (
                                                          <>
                                                          <Link
                                                            to='#' className="add-ico-condiment condiment-count-area"
                                                            onClick={()=>this.condimentPopShow(value.uniquekey)}
                                                          ><span className="condiment-count">{condiment_cart[arrayFilter].count}</span></Link>
                                                          <div className={`condiment-increment-decrement-area ${this.state.condimentPopHide === value.uniquekey ? "" :"hide-condiment-area"} `}>
                                                            <div className="col-md-4">
                                                              <Link
                                                                to='#'  onClick={(e) =>
                                                                this.onMinusCart(value)
                                                              }>{condiment_cart[arrayFilter].count > 1 ? <i className="icon-minus-circle"></i> : <i className="icon-trash-o text-red condiment-delete-icon"></i>}</Link>
                                                            </div>
                                                                        <div className="col-md-4 no-padding"><p>{condiment_cart[arrayFilter].count}</p></div>
                                                            <div className="col-md-4"><Link
                                                              to='#'  onClick={(e) =>
                                                              this.onAddCart(value)
                                                            }><i className="icon-plus-circle"></i></Link></div>
                                                          </div>
                                                          </>
                                                        ):(
                                                          <Link
                                                            to='#' className="add-ico-condiment" onClick={(e) =>{
                                                              this.onAddCart(value);this.addedItemPop()
                                                            }
                                                          }><i className="icon-plus"></i></Link>
                                                        )}


                                                    </figure>

                                                </div>
                                                <div className="text-holder">
                                                    <div className="listing-inner text-center">
                                                        <h5 onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>{value.name.toUpperCase()} {`(${value.quantity})`}</h5>
                                                        <p onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>{`MRP ₹${value.price}`}</p>
                                                        {
                                                        <div className="no-padding col-md-12 recipe-part food-coach-btn text-center">
                                                                <Link to="#" className="book-now" onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>View Detail</Link>

                                                                {/* <Link className="btn btn-red btn-condinment-cart pull-right" to="/">Add to Cart</Link> */}

                                                          </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      )})}

                                    </div>
                                </div>
                                <CenterSidebar center="condiment" rightCart={rightCart}/>
                              </div>
                           </div>
                        </div>
  										</div>
                      </>
                    ):tabclass === "FestivalKit" ? (
                      <>
                      {/* tab 4 */}
                      <div id="festival-tab">
                        <div className="row recepies-section condiment-sections main-store-tab-sec">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 condiments-area">
                              <div className="row">
                                <div className="col-md-9 condiment-rightbody-sec">
                                    <div className="listing grid-listing three-cols">
                                    {condiment && condiment.filter(filteritem =>filteritem.category_name === "Festival Kit").map((value,index)=>{
                                    let arrayFilter =
                                      condiment_cart instanceof Array &&
                                      condiment_cart.findIndex(
                                        (obj) =>
                                          obj.uniqueID === value.uniquekey
                                      );
                                      return (
                                        <div className="col-md-4 col-xs-6 recipe-part grid-listing-col " key={`condiment_${index}`} >
                                            <div className="condiments-inner-box">
                                                <div className="img-holder">
                                                    <figure>

                                                        <Link to="#" onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>
                                                            <img
                                                                // src="assets/extra-images/condiment-product-img.jpg"
                                                                src={`${bucketPath}${value.image}`}
                                                                alt=""
                                                                className="img-responsive"
                                                        />
                                                        </Link>
                                                        {condiment_cart instanceof Array &&
                                                        arrayFilter !== -1 ? (
                                                          <>
                                                          <Link
                                                            to='#' className="add-ico-condiment condiment-count-area"
                                                            onClick={()=>this.condimentPopShow(value.uniquekey)}
                                                          ><span className="condiment-count">{condiment_cart[arrayFilter].count}</span></Link>
                                                          <div className={`condiment-increment-decrement-area ${this.state.condimentPopHide === value.uniquekey ? "" :"hide-condiment-area"} `}>
                                                            <div className="col-md-4">
                                                              <Link
                                                                to='#'  onClick={(e) =>
                                                                this.onMinusCart(value)
                                                              }>{condiment_cart[arrayFilter].count > 1 ? <i className="icon-minus-circle"></i> : <i className="icon-trash-o text-red condiment-delete-icon"></i>}</Link>
                                                            </div>
                                                                        <div className="col-md-4 no-padding"><p>{condiment_cart[arrayFilter].count}</p></div>
                                                            <div className="col-md-4"><Link
                                                              to='#'  onClick={(e) =>
                                                              this.onAddCart(value)
                                                            }><i className="icon-plus-circle"></i></Link></div>
                                                          </div>
                                                          </>
                                                        ):(
                                                          <Link
                                                            to='#' className="add-ico-condiment" onClick={(e) =>{
                                                              this.onAddCart(value);this.addedItemPop()
                                                            }
                                                          }><i className="icon-plus"></i></Link>
                                                        )}


                                                    </figure>

                                                </div>
                                                <div className="text-holder">
                                                    <div className="listing-inner text-center">
                                                        <h5 onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>{value.name.toUpperCase()} {`(${value.quantity})`}</h5>
                                                        <p onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>{`MRP ₹${value.price}`}</p>
                                                        {
                                                        <div className="no-padding col-md-12 recipe-part food-coach-btn text-center">
                                                                <Link to="#" className="book-now" onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>View Detail</Link>

                                                                {/* <Link className="btn btn-red btn-condinment-cart pull-right" to="/">Add to Cart</Link> */}

                                                          </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      )})}

                                    </div>
                                </div>
                                <CenterSidebar center="condiment" rightCart={rightCart}/>
                              </div>
                           </div>
                        </div>
                      </div>
                      </>
                    ):tabclass === "Snacks" ? (<>
                      {/* tab 4 */}
                      <div id="Snacks-tab">
                        <div className="row recepies-section condiment-sections main-store-tab-sec">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 condiments-area">
                              <div className="row">
                                <div className="col-md-9 condiment-rightbody-sec">
                                    <div className="listing grid-listing three-cols">
                                    {condiment && condiment.filter(filteritem =>filteritem.category_name === "Snacks").map((value,index)=>{
                                    let arrayFilter =
                                      condiment_cart instanceof Array &&
                                      condiment_cart.findIndex(
                                        (obj) =>
                                          obj.uniqueID === value.uniquekey
                                      );
                                      return (
                                        <div className="col-md-4 col-xs-6 recipe-part grid-listing-col " key={`condiment_${index}`} >
                                            <div className="condiments-inner-box">
                                                <div className="img-holder">
                                                    <figure>

                                                        <Link to="#" onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>
                                                            <img
                                                                // src="assets/extra-images/condiment-product-img.jpg"
                                                                src={`${bucketPath}${value.image}`}
                                                                alt=""
                                                                className="img-responsive"
                                                        />
                                                        </Link>
                                                        {condiment_cart instanceof Array &&
                                                        arrayFilter !== -1 ? (
                                                          <>
                                                          <Link
                                                            to='#' className="add-ico-condiment condiment-count-area"
                                                            onClick={()=>this.condimentPopShow(value.uniquekey)}
                                                          ><span className="condiment-count">{condiment_cart[arrayFilter].count}</span></Link>
                                                          <div className={`condiment-increment-decrement-area ${this.state.condimentPopHide === value.uniquekey ? "" :"hide-condiment-area"} `}>
                                                            <div className="col-md-4">
                                                              <Link
                                                                to='#'  onClick={(e) =>
                                                                this.onMinusCart(value)
                                                              }>{condiment_cart[arrayFilter].count > 1 ? <i className="icon-minus-circle"></i> : <i className="icon-trash-o text-red condiment-delete-icon"></i>}</Link>
                                                            </div>
                                                                        <div className="col-md-4 no-padding"><p>{condiment_cart[arrayFilter].count}</p></div>
                                                            <div className="col-md-4"><Link
                                                              to='#'  onClick={(e) =>
                                                              this.onAddCart(value)
                                                            }><i className="icon-plus-circle"></i></Link></div>
                                                          </div>
                                                          </>
                                                        ):(
                                                          <Link
                                                            to='#' className="add-ico-condiment" onClick={(e) =>{
                                                              this.onAddCart(value);this.addedItemPop()
                                                            }
                                                          }><i className="icon-plus"></i></Link>
                                                        )}


                                                    </figure>

                                                </div>
                                                <div className="text-holder">
                                                    <div className="listing-inner text-center">
                                                        <h5 onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>{value.name.toUpperCase()} {`(${value.quantity})`}</h5>
                                                        <p onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>{`MRP ₹${value.price}`}</p>
                                                        {
                                                        <div className="no-padding col-md-12 recipe-part food-coach-btn text-center">
                                                                <Link to="#" className="book-now" onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>View Detail</Link>

                                                                {/* <Link className="btn btn-red btn-condinment-cart pull-right" to="/">Add to Cart</Link> */}

                                                          </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      )})}
                                    </div>
                                </div>
                                <CenterSidebar center="condiment" rightCart={rightCart}/>
                              </div>
                           </div>
                        </div>
                      </div>
                      </>) : null}





									</div>
{/* tab content end here */}
      </div>
        </div>
        <Modals open={open} >
            <ModalHeader CloseModal={this.CloseModal} title={modal_type === "add_review" ? "Add Review" : "Product Details"}  />
            <ModalBody>
            {modal_type === "view_detail" ? (
              <div className="product-details-body">
                  <div className="row">
                      <div className="col-md-6">
                          <div className="prudct-slider-side main-post">
                              <figure className="col-md-9">
                              <div
                                  id='carousel-example-generic'
                                  className='carousel slide'
                                  data-ride='carousel'
                              >
                                  <ol className="carousel-indicators">
                                  {images && images.map(
                                      (imgVal, imgIndex) => {
                                      return (
                                          <li key={"dot_" + imgIndex} data-target="#carousel-example-generic" data-slide-to={`${imgIndex}`} className=""></li>
                                      )
                                      })
                                  }
                                  </ol>
                                  <div
                                  className='carousel-inner'
                                  role='listbox'
                                  >
                                  {images && images.map(
                                      (imgVal, imgIndex) => {
                                      return (
                                          <div className={`item ${imgIndex === 0 ? "active" : ""}`} key={`item_${imgIndex}`}>
                                          <Link to='#'>
                                              <img
                                                  src={`${bucketPath}${imgVal.image}`}
                                                  alt=''
                                              />
                                          </Link>
                                          </div>
                                      );
                                      }
                                  )}
                                  </div>
                                  <Link
                                  className='left carousel-control'
                                  to='#carousel-example-generic'
                                  role='button'
                                  data-slide='prev'
                                  >
                                  <span
                                      className='icon-arrows-1'
                                      aria-hidden='true'
                                  ></span>
                                  <span className='sr-only'>Previous</span>
                                  </Link>
                                  <Link
                                  className='right carousel-control'
                                  to='#carousel-example-generic'
                                  role='button'
                                  data-slide='next'
                                  >
                                  <span
                                      className='icon-arrows'
                                      aria-hidden='true'
                                  ></span>
                                  <span className='sr-only'>Next</span>
                                  </Link>
                              </div>
                              </figure>
                              {/* <img
                                  src="assets/extra-images/condiment-product-img.jpg"
                                  src={`${bucketPath}${this.state.image}`}
                                  className="img-fluid mx-auto d-block"
                                  alt="..."
                              />  */}
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="prudct-content-side ">
                              <h2 className="product-dt-name">{name.toUpperCase()}</h2>
                              <div className="list-rating">
                                  <div className="rating-star">
                                      <span className="rating-box" style={{ width: "100%" }}></span>
                                  </div>
                                  <span className="reviews">(1)</span>
                              </div>
                              <h3 className="prodcut-md-price">{`₹${price}`}</h3>
                              {condiment_cart instanceof Array &&
                              viewarrayFilter !== -1 ? (
                                <div className={`row condiment-increment-decrement-area modal-condiment-increment-decrement-area `}>
                                  <div className="col-md-4">
                                    <Link
                                      to='#'  onClick={(e) =>
                                      this.onMinusCart(view_condiment)
                                    }>{condiment_cart[viewarrayFilter].count > 1 ? <i class="icon-minus-circle"></i> : <i className="icon-trash-o text-red condiment-delete-icon"></i>}</Link>
                                  </div>
                                              <div className="col-md-4 no-padding"><p>{condiment_cart[viewarrayFilter].count}</p></div>
                                  <div className="col-md-4"><Link
                                    to='#'  onClick={(e) =>
                                    this.onAddCart(view_condiment)
                                  }><i class="icon-plus-circle"></i></Link></div>
                                </div>
                              ):(
                                <Link className="btn btn-sm book-now " to="#" onClick={(e) =>{
                                  this.onAddCart(view_condiment);this.addedItemPop()
                                }

                              }>Add to Cart</Link>
                              )}

                              <ul className="discription-body highlight">
                                  <h4 className="margin-top-10">Product Highlights</h4>
                                  <p>{product_highlight}</p>
                                  {/* <li>Phasellus suscipit ante id congue maximus.</li>
                                  <li>Etiam ut urna accumsan, tempus metus a, sagittis nunc.</li>
                                  <li>Sed suscipit leo vel pharetra varius.</li>
                                  <li>Etiam ut urna accumsan, tempus metus a, sagittis nunc.</li>
                                  <li>Sed suscipit leo vel pharetra varius.</li> */}
                              </ul>
                          </div>
                      </div>
                  </div>
                  {/* comment area strt */}
                  <div className="row">
                      <div className="col-md-12">
                          <div className="discription-body">
                              <h2>Product Description</h2>
                              <p>{description}</p>
                          </div>
                      </div>
                  </div>
                  {/* comment area strt */}
                  <div className="row">
                      <div className="col-md-12">
                          <div className="comment-body">
                              <h2><i className="icon-comment2"></i>Comments / Reviews</h2>
                              <ul className="product-review">
                              {condiment_review_list && condiment_review_list.length > 0 ? condiment_review_list.map((item,index)=>{
                                return(
                                  <li className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding" key={index}>
                                      <div className="list-holder ">
                                          <div className="product-review-text">
                                              <div className="product-title">
                                                  <h6>{item.title}</h6>
                                              </div>
                                              <div className="list-rating">
                                                  <div className="rating-star">
                                                      <span className="rating-box" style={{ width: item.points + "%" }}></span>
                                                  </div>
                                                  <em className="prodcut-date pull-right">{item.lastupdate.split(" ")[0]}</em>
                                              </div>
                                          </div>

                                          <p>{item.description}</p>
                                      </div>
                                  </li>
                                )
                              }):null}

                              </ul>


                          </div>
                      </div>
                      <div className="col-md-12 text-center review-btn-div">
                        <Link to="#" className="write-review-btn" onClick={() =>
                          this.openReviewModal()
                        }>Wirte Review</Link>
                      </div>
                  </div>
                  {/* comment area end */}
              </div>
            ):modal_type === "add_review" ? (
              <div className="order-detail-inner">
                <div className="reviews-holder">
                  <div className="add-new-review-holder add-new-review-2067">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <h3>Rate and Write a Review</h3>
                      </div>
                      <div className="foodbakery-add-review-data">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div
                            className="total-rating user-rating-container overall-rating"
                            data-overall-rating="1"
                          >
                            <h6>Rating</h6>
                            <div className="rating-star">
                              <input
                                id="star-13"
                                type="radio"
                                name="rating1"
                                value="1"
                                className="star-1"
                                checked={rating === 1 || rating === 2 || rating === 3 || rating === 4 || rating === 5 ? "checked" : ""}//"checked"
                                onClick={e => this.onRadioChangeHandler(e)}
                              />
                              <label className="star-13" htmlFor="star-13">
                                1
                              </label>
                              <input
                                id="star-23"
                                type="radio"
                                name="rating2"
                                value="2"
                                className="star-2"
                                checked={rating === 2 || rating === 3 || rating === 4 || rating === 5 ? "checked" : ""}//"checked"
                                onClick={e => this.onRadioChangeHandler(e)}
                              />
                              <label className="star-2" htmlFor="star-23">
                                2
                              </label>
                              <input
                                id="star-33"
                                type="radio"
                                name="rating3"
                                value="3"
                                className="star-3"
                                checked={rating === 3 || rating === 4 || rating === 5 ? "checked" : ""}//"checked"
                                onClick={e => this.onRadioChangeHandler(e)}
                              />
                              <label className="star-3" htmlFor="star-33">
                                3
                              </label>
                              <input
                                id="star-43"
                                type="radio"
                                name="rating4"
                                value="4"
                                className="star-4"
                                checked={rating === 4 || rating === 5 ? "checked" : ""}//"checked"
                                onClick={e => this.onRadioChangeHandler(e)}
                              />
                              <label className="star-4" htmlFor="star-43">
                                4
                              </label>
                              <input
                                id="star-53"
                                type="radio"
                                name="rating5"
                                value="5"
                                className="star-5"
                                checked={rating === 5 ? "checked" : ""}//"checked"
                                onClick={e => this.onRadioChangeHandler(e)}
                              />
                              <label className="star-5" htmlFor="star-53">
                                5
                              </label>
                              <span style={{ width: (rating * 20) + "%" }}></span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="form-element">
                            <i className="icon-edit2"></i>
                            <input
                              type="text"
                              placeholder="Title of your review *"
                              value={title}
                              name="title"
                              onChange={e => this.onChangeHandler(e)}
                            />
                            {this.validator.message('title', title, 'required')}
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="form-element">
                            <textarea
                              placeholder="Tell about your experience or leave a tip for others"
                              cols="30"
                              rows="10"
                              maxLength="500"
                              name="description"
                              value={this.state.description}
                              onChange={e => this.onChangeHandler(e)}
                            ></textarea>
                            {this.validator.message('description', this.state.description, 'required|min:10')}
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="form-element message-length">
                            <span>Min characters: 10</span>
                            <span>Max characters: 500</span>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="form-element">
                            <input
                              type="button"
                              defaultValue="Submit your Review"
                              onClick={e => this.addRivew(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ):null}

            </ModalBody>
        </Modals>
      </>
    )
  }
}
const mapStateToProps = (state) => ({
    condiment: state.restaurant.condiment_listing,
    view_condiment:state.restaurant.view_condiment,
    condiment_cart:state.condimentCart.condiment_cart,
    force_condiment_modal_open_data:state.condiments.force_condiment_modal_open_data,
    condiment_review_list:state.condiments.condiment_review_list
});
export default connect(mapStateToProps)(MainStore)
