import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from "react-redux"

class RecipeDetail extends Component {
    constructor(props) {
        super(props);
        this.state = { pageOfItems: [], currentPage: 1 };
    }

    componentDidMount() {
        //let recipeID = this.props.match.params.recipeID;
        // this.props.dispatch(ViewRestaurantRecepie(recipeID));
    }
    render() {
        return (
            <div>
               <div className="main-section receipes-details">
                <div className="container">
                    <div className="row">
                        <div className="page-content col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div className="page-section">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="blog-detail">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="title-area">
                                                        <h2>Creating the world’s greatest food
                                                        </h2>
                                                        <span>One of the best cities in India to experience a variety of
                                                            dishes is apni Dilli aka Delhi. In this article, we take you
                                                            through Posuere ultrices tortor curae tristique cubilia
                                                            ultricies metus duis, integer lorem luctus felis porta ad
                                                            curabitur cras, aliquet tincidunt sagittis sed sit fusce
                                                            sagittis in cubilia tempor euismod rhoncus luctus condimentum
                                                            nibh justo eu massa metus feugiat id vel vitae <Link to="#">Read
                                                                More</Link>
                                                        </span>
                                                    </div>
                                                    <div className="main-post">
                                                        <figure>
                                                            {/* <Link to="#"><img src="assets/extra-images/blogs-food-bakery-02-750x422.jpg" alt="" /></Link> */}
                                                            <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">

                                                            {/* <!-- Wrapper for slides --> */}
                                                            <div className="carousel-inner" role="listbox">
                                                                <div className="item active">
                                                                 <Link to="#"><img src="assets/extra-images/blogs-food-bakery-02-750x422.jpg" alt="" /></Link>

                                                                </div>
                                                                <div className="item">
                                                                 <Link to="#"><img src="assets/extra-images/blogs-food-bakery-02-750x422.jpg" alt="" /></Link>

                                                                </div>
                                                            </div>

                                                            {/* <!-- Controls --> */}
                                                            <Link className="left carousel-control" to="#carousel-example-generic" role="button" data-slide="prev">
                                                                        <span className="icon-arrows-1" aria-hidden="true"></span>
                                                                <span className="sr-only">Previous</span>
                                                            </Link>
                                                            <Link className="right carousel-control" to="#carousel-example-generic" role="button" data-slide="next">
                                                                        <span className="icon-arrows" aria-hidden="true"></span>
                                                                <span className="sr-only">Next</span>
                                                            </Link>
                                                            </div>
                                                        </figure>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="rich-editor-text">
                                                        <p>One of the best cities in India to experience a variety of dishes
                                                            is apni Dilli aka Delhi. In this article, we take you through
                                                            Posuere ultrices tortor curae tristique cubilia ultricies metus
                                                            duis, integer lorem luctus felis porta ad curabitur cras,
                                                            aliquet tincidunt sagittis sed sit fusce sagittis in cubilia
                                                            tempor euismod rhoncus luctus condimentum nibh justo eu massa
                                                            metus feugiat id vel vitae velit quam ultrices feugiat platea
                                                            feugiat risus velit est justo rutrum inceptos. Netus euismod
                                                            pulvinar laoreet augue odio dui risus arcu, augue purus
                                                            venenatis pharetra adipiscing enim ante quis nec, eleifend
                                                            gravida ante lobortis consectetur donec tempus rutrum curabitur
                                                            enim fames dui turpis suspendisse mi fames, venenatis ante
                                                            senectus elit rutrum vestibulum quam, className porttitor odio id
                                                            pellentesque commodo sodales.</p>
                                                        <p>Odio eros cursus diam ut curae odio nibh facilisis, litora eros
                                                            suscipit nisi aptent netus in ligula mollis, dictumst tristique
                                                            consectetur aliquet eu adipiscing id in amet sem diam sit erat
                                                            faucibus vivamus fames aliquam per, faucibus libero
                                                            curabiturarcu phasellus risus adipiscing.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <aside className="page-sidebar right col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="widget-holder">
                                <div className="widget widget_search">
                                    <div className="widget-title">
                                        <h5>Tulip Mama</h5>
                                    </div>
                                        <div className="text-holder side-bar-mama">
                                            <div className="row">
                                                <div className="col-md-4">
                                                        <Link to="/" ><img src="assets/extra-images/mama-logo.png" class="img-list wp-post-image" alt="..."/></Link>
                                                </div>
                                                <div className="col-md-8 no-padding">
                                                    <span className="post-categories"> Apple Juice</span>
                                                    <div className="delivery-potions">
                                                                <div className="post-time">
                                                            <i className="icon-location-pin"></i>
                                                        </div>
                                                        <span>Kangra (HP)</span>
                                                    </div>
                                                    <div className="list-rating">
                                                        <div className="rating-star">
                                                            <span className="rating-box" ></span>
                                                        </div>
                                                        <span className="reviews">(1)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                <div className="widget widget-related-post">
                                    <div className="widget widget-recent-blog-post">
                                        <div className="widget-title">
                                            <h5>Popular Recepies</h5>
                                        </div>
                                        <ul>
                                            <li>
                                                <div className="img-holder">
                                                    <figure><Link to="#"><img src="assets/extra-images/blogs-food-bakery-02-150x150.jpg" alt="" /></Link></figure>
                                                </div>
                                                <div className="text-holder">
                                                    <div className="post-title">
                                                        <h6><Link to="#">Creating the world’s greatest food community with
                                                                food ...</Link></h6>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="img-holder">
                                                    <figure><Link to="#"> <img src="assets/extra-images/blogs-food-bakery-17-150x150.jpg" alt="" /> </Link></figure>
                                                </div>
                                                <div className="text-holder">
                                                    <div className="post-title">
                                                        <h6><Link to="#">half year technology to update customers and
                                                                restaurants</Link></h6>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="img-holder">
                                                    <figure><Link to="#"> <img src="assets/extra-images/blogs-food-bakery-07-150x150.jpg" alt="" /> </Link></figure>
                                                </div>
                                                <div className="text-holder">
                                                    <div className="post-title">
                                                        <h6><Link to="#">Take away fans select celeb winners of Food ...</Link>
                                                        </h6>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="img-holder">
                                                    <figure><Link to="#"> <img src="assets/extra-images/blogs-food-bakery-06-150x150.jpg" alt="" /> </Link></figure>
                                                </div>
                                                <div className="text-holder">
                                                    <div className="post-title">
                                                        <h6><Link to="#">United Kingdom Fire Brigade launch have take
                                                                away...</Link></h6>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
		    </div>
		{/* <!-- Main Section End --></div> */}
        </div>
        )
    }
}
const mapStateToProps = (state) => ({
    restaurant_data: state.restaurant
});

export default connect(mapStateToProps)(RecipeDetail);
