import { BaseUrl } from '../helpers/common';
import * as actionTypes from '../types/types';
import { handleResponse, queryResponse } from '../helpers/userServices';
import {userUniqueID} from './../helpers/identity'
import swal from 'sweetalert';
import { ShowLoader, ShowAlert } from './staticActions'
import { UserOrderList } from './buyerActions'
import axios from 'axios'

export const fetchStart = () => {
    return {
        type: actionTypes.FETCH_DATA_BEGIN
    }
}

export function restaurantInfo(restaurantId) {
    const uniqueID = restaurantId ? restaurantId : localStorage.getItem("data") ? `${JSON.parse(localStorage.getItem("data")).uniqueID}` : "";
    return dispatch => {
        dispatch(fetchStart());
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/profile`, {
            method: 'POST',
            body: JSON.stringify({ uniqueID: uniqueID }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(restaurantId ? queryResponse : handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.FETCH_RESTAURANT_PROFILE, payload: data })
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}


export function restaurantViewInfo(restaurantId) {
    const uniqueID = restaurantId;
    return dispatch => {
        dispatch(fetchStart());
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/profile`, {
            method: 'POST',
            body: JSON.stringify({ uniqueID: uniqueID }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.FETCH_RESTAURANT_VIEW_PROFILE, payload: data })
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function RestaurantUpdate(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/update`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch(restaurantInfo());
                swal({ title: "Success!", text: "Restaurant Updated successfully!", icon: "success" });
                return 1;
            } else {
                swal({ title: "Error!", text: data.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function RestaurantDeliveryPickup(data) {
    return dispatch => {
        dispatch(fetchStart());
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/update-setting`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                swal({ title: "Success!", text: "Restaurant Delivery/Pickup details added successfully!", icon: "success" });
                return 1;
            } else {
                swal({ title: "Error!", text: data.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function RestaurantWorkingHours(data) {
    return dispatch => {
        dispatch(fetchStart());
        dispatch(ShowLoader(true));
        let formData = new FormData();
        formData.append("restaurant_working_hour", JSON.stringify(data.data));
        formData.append("food_truck_timing", JSON.stringify(data.food_truck_timing));
        const request = new Request(`${BaseUrl}/api/restaurant/update-workinghours`, {
            method: 'POST',
            body: formData,
            headers: new Headers({'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                swal({ title: "Success!", text: "Restaurant working hours added successfully!", icon: "success" });
                return 1;
            } else {
                swal({ title: "Error!", text: data.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function RestaurantCategoryListing(uniqueID) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/category-listing`, {
            method: 'POST',
            body: JSON.stringify({ uniqueID: uniqueID }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.CATEGORY_LISTING, payload: data.data })
                return 1;
            } else {
                dispatch({ type: actionTypes.CATEGORY_LISTING, payload :[]})
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function RestaurantMenuListing(uniqueID) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/menu-listing`, {
            method: 'POST',
            body: JSON.stringify({ uniqueID: uniqueID }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.MENU_LISTING, payload: data.data })
                return 1;
            } else {
                dispatch({ type: actionTypes.MENU_LISTING, payload :[]})
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function RestaurantAddCategory(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/add-category`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                dispatch(RestaurantCategoryListing(JSON.parse(localStorage.getItem('data')).uniqueID))
                dispatch(ShowAlert({ Class: "alert-success", Message: response.ResponseText, Timeout: 1500 }));
                return 1;
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function RestaurantAddMenu(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let request;
        let imageFile = window.$('#file_icon')[0].files[0]
        let formData = new FormData();
        if (data.uniquekey !== "" && data.uniquekey) {
            formData.append("uniquekey", data.uniquekey);
        }
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("categoryID", data.category);
        formData.append("price", data.price);
        if (imageFile) {
            formData.append('image', imageFile);
        }
        request = {
            method: 'POST',
            url: `${BaseUrl}/api/restaurant/${data.uniquekey !== "" && data.uniquekey ? "update-menu" : "add-menu"}`,
            headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
            data: formData
        };

        return axios(request).then((data) => {
            dispatch(ShowLoader(false));
            dispatch(ShowAlert({ Class: "alert-success", Message: `Menu ${data.uniquekey !== "" && data.uniquekey ? "Updated" : "Added"} Successfully.`, Timeout: 1500 }));
            dispatch(RestaurantMenuListing(JSON.parse(localStorage.getItem('data')).uniqueID));
            return 1;
        }).catch((error) => {
            dispatch(ShowLoader(false));
            if (error && error.response && error.response.data) {
                dispatch(ShowAlert({ Class: "alert-danger", Message: error.response.data.ResponseText, Timeout: 1500 }));
                return 0;
            } else {
                dispatch(ShowAlert({ Class: "alert-danger", Message: 'Error Occured', Timeout: 1500 }));
                return 0;
            }
        });
    }
}

export function RecepieVideoListing(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/listing-video-recording`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.VIDEO_LISTING, payload: data.data })
                return 1;
            } else {
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function DeleteRecipeVideo(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/delete-video-recording`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                let data = JSON.parse(localStorage.getItem("data"));
		        let restaurantID = data["restaurantID"];
                dispatch(RecepieVideoListing({restaurantID}));
                swal({ title: "Deleted!", text: response.ResponseText, icon: "success" });
                return 1;
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });
    }
}

export function PublishVideo(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/update-video-status`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                let data = JSON.parse(localStorage.getItem("data"));
		        let restaurantID = data["restaurantID"];
                dispatch(RecepieVideoListing({restaurantID}));
                dispatch(ShowAlert({ Class: "alert-success", Message: response.ResponseText, Timeout: 1500 }));
                return 1;
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });
    }
}

export function ViewRecepieVideo(recordingID) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let data = {
            recordingID
        }
        const request = new Request(`${BaseUrl}/api/view-video-recording`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                dispatch({ type: actionTypes.VIDEO_VIEW, payload: response.data });
                return 1;
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function AddRecepieVideo(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let request;
        let videoFile = window.$('#file_icon1')[0].files;
        let duration = videoFile && videoFile?.[0]?.size; 
        let formData = new FormData();
        if (data.recordingID !== "" && data.recordingID) {
            formData.append("recordingID", data.recordingID);
        }
        formData.append("title", data.title);
        formData.append("description", data.description);
        formData.append("file",window.$('#file_icon1')[0].files[0]);
        formData.append("duration",duration)  
        request = {
            method: 'POST',
            url: `${BaseUrl}/api/${data.recordingID ? "update-video-recording" : "upload-video"}`,
            headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
            data: formData
        };

        return axios(request).then((data) => {
            dispatch(ShowLoader(false));
            dispatch(ShowAlert({ Class: "alert-success", Message: `Menu ${data.uniquekey !== "" && data.uniquekey ? "Updated" : "Added"} Successfully.`, Timeout: 1500 }));
            dispatch(RestaurantMenuListing(JSON.parse(localStorage.getItem('data')).uniqueID));
            return 1;
        }).catch((error) => {
            dispatch(ShowLoader(false));
            if (error && error.response && error.response.data) {
                dispatch(ShowAlert({ Class: "alert-danger", Message: error.response.data.ResponseText, Timeout: 1500 }));
                return 0;
            } else {
                dispatch(ShowAlert({ Class: "alert-danger", Message: 'Error Occured', Timeout: 1500 }));
                return 0;
            }
        });
    }
}

export function RestaurantRecepieListing(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/recipe/list`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.RECEPI_LISTING, payload: data.data })
                return 1;
            } else {
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function ViewRestaurantRecepie(recipeID) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let data = {
            recipeID
        }
        const request = new Request(`${BaseUrl}/api/recipe/view`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                dispatch({ type: actionTypes.RECEPI_VIEW, payload: response.data });
                return 1;
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function RestaurantAddRecepie(data, this_ref) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let request;
        let imageFile = window.$('#file_icon')[0].files;
        let videoFile = window.$('#file_icon1')[0].files;


        let formData = new FormData();
        if (data.recipeID !== "" && data.recipeID) {
            formData.append("recipeID", data.recipeID);
        }
        formData.append("title", data.title);
        formData.append("description", data.description);
        formData.append("summary", data.summary);
        formData.append("menu_uniqueID", data.menu_uniqueID);
        formData.append("prepration_time", data.prepration_time);
        formData.append("cooking_time", data.cooking_time);
        formData.append("additional_time", data.additional_time);
        formData.append("serving_person", data.serving_person);
        formData.append("live_status", data.live_status);
        // if(data.live_status === "Y"){
        //   let liveVideoFile = window.$('#live_video')[0].files[0];
        //   formData.append(`live_video`, liveVideoFile);
        // }

        formData.append("ingredients", JSON.stringify(data.ingredients));
        if (imageFile.length > 0) {
            let selectedImagesLength = imageFile.length;
            for(let k = 0; k < selectedImagesLength; k++) {
                formData.append(`image[${k}]`, imageFile[k]);
            }
        }
        // else{
        //     formData.append("image", this_ref.state.recipe_images);
        // }
        if (videoFile.length > 0) {
            let selectedVideosLength = videoFile.length;
            for(let k = 0; k < selectedVideosLength; k++) {
                formData.append(`video[${k}]`, videoFile[k]);
            }
        }
        // else{
        //     formData.append("video", this_ref.state.recipe_videos);
        // }
        request = {
            method: 'POST',
            url: `${BaseUrl}/api/recipe/${data.recipeID ? "update" : "create" }`,
            headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
            data: formData
        };

        return axios(request).then((data) => {
            dispatch(ShowLoader(false));
            dispatch(ShowAlert({ Class: "alert-success", Message: `Menu ${data.uniquekey !== "" && data.uniquekey ? "Updated" : "Added"} Successfully.`, Timeout: 1500 }));
            dispatch(RestaurantMenuListing(JSON.parse(localStorage.getItem('data')).uniqueID));
            return 1;
        }).catch((error) => {
            dispatch(ShowLoader(false));
            if (error && error.response && error.response.data) {
                dispatch(ShowAlert({ Class: "alert-danger", Message: error.response.data.ResponseText, Timeout: 1500 }));
                return 0;
            } else {
                dispatch(ShowAlert({ Class: "alert-danger", Message: 'Error Occured', Timeout: 1500 }));
                return 0;
            }
        });
    }
}

export function DeleteRecipe(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/recipe/delete`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 1;
            } else {
                let data = JSON.parse(localStorage.getItem("data"));
		        let restaurantID = data["restaurantID"];
                dispatch(RestaurantRecepieListing({restaurantID}))
                swal({ title: "Deleted!", text: response.ResponseText, icon: "success" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });
    }
}

export function PublishRecipe(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/recipe/status/update`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                let data = JSON.parse(localStorage.getItem("data"));
		        let restaurantID = data["restaurantID"];
                dispatch(RestaurantRecepieListing({restaurantID}))
                dispatch(ShowAlert({ Class: "alert-success", Message: response.ResponseText, Timeout: 1500 }));
                return 1;
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });
    }
}

export function ChangingPriority(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/update-category-priority`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((response) => {
            setTimeout(() => { dispatch(ShowLoader(false)) }, 1000);
            if (response.ResponseCode === '1') {
                dispatch(RestaurantCategoryListing(JSON.parse(localStorage.getItem('data')).uniqueID))
                swal({ title: "Success!", text: response.Responsetext, icon: "success" });
                return 1;
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function DeleteMenu(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/delete-menu`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                dispatch(RestaurantMenuListing(JSON.parse(localStorage.getItem('data')).uniqueID))
                dispatch(ShowAlert({ Class: "alert-success", Message: response.ResponseText, Timeout: 1500 }));
                return 1;
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });
    }
}

export function DeleteCategory(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/delete-category`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                dispatch(RestaurantCategoryListing(JSON.parse(localStorage.getItem('data')).uniqueID))
                dispatch(ShowAlert({ Class: "alert-success", Message: response.ResponseText, Timeout: 1500 }));
                return 1;
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });
    }
}

export function UpdateCategory(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/update-category`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                dispatch(RestaurantCategoryListing(JSON.parse(localStorage.getItem('data')).uniqueID))
                dispatch(ShowAlert({ Class: "alert-success", Message: response.ResponseText, Timeout: 1500 }));
                return 1;
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });
    }
}

export function UpdateMenu(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/update-menu`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                dispatch(RestaurantMenuListing(JSON.parse(localStorage.getItem('data')).uniqueID))
                dispatch(ShowAlert({ Class: "alert-success", Message: response.ResponseText, Timeout: 1500 }));
                return 1;
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });
    }
}

export const RecipeList = data => {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/recipe-list`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json'})
        });
        return fetch(request).then(queryResponse).then((response) => {
            dispatch(ShowLoader(false));
            dispatch({ type: actionTypes.RECIPE_LIST, payload: response.data??[] })
            if (response.ResponseCode === '1') {
                return 1;
            } else {
                return 0;
            }
        }).catch(error => {
            dispatch({ type: actionTypes.RECIPE_LIST, payload: [] })
            dispatch(ShowLoader(false));
            return 0;
        });
    }
}

export const VideoList = data => {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant-video-list`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json'})
        });
        return fetch(request).then(queryResponse).then((response) => {
            dispatch(ShowLoader(false));
            dispatch({ type: actionTypes.FETCH_VIDEO_LIST, payload: response.data??[] })
            if (response.ResponseCode === '1') {
                return 1;
            } else {
                return 0;
            }
        }).catch(error => {
            dispatch({ type: actionTypes.FETCH_VIDEO_LIST, payload: [] })
            dispatch(ShowLoader(false));
            return 0;
        });
    }
}

export const RecipeInfo = data => {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/recipe-info`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json'})
        });
        return fetch(request).then(queryResponse).then((response) => {
            dispatch(ShowLoader(false));
            dispatch({ type: actionTypes.RECIPE_INDIVIDUAL_INFO, payload: response.data??[] })
            if (response.ResponseCode === '1') {
                return 1;
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch({ type: actionTypes.RECIPE_INDIVIDUAL_INFO, payload: [] })
            dispatch(ShowLoader(false));
            return 0;
        });
    }
}

export const RestaurantListing = (data) => {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/list`, {
            method: 'POST',
            body:JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(queryResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                dispatch({ type: actionTypes.RESTAURANT_LISTING, payload: response.data })
                return 1;
            } else {
                dispatch({ type: actionTypes.RESTAURANT_LISTING, payload: [] })
                return 0;
            }
        }).catch(error => {
        });
    }
}

export const PlaceOrder = (data) => {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/order/create`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                if(data.payment_type==='cash'){
                    swal({ title: "Success!", text: response.ResponseText, icon: "success" });
                    dispatch(ShowAlert({ Class: "alert-success", Message: response.ResponseText, Timeout: 10000 }));
                    return {code:1,type:'cash'}
                }else{
                    // dispatch(ShowAlert({ Class: "alert-success", Message: 'Added to Cart', Timeout: 10000 }));
                    return {code:1,type:'card',order_number:response.data}
                }
            } else {
                return {code:0}
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
            return {code:0}
        });
    }
}

export const OrderDetail = (data) => {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/order/detail`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                dispatch({ type: actionTypes.ORDER_DETAIL, payload: response.data })
                return {code:1,data:response.data}
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return {code:0}
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
            swal({ title: "Error!", text: error, icon: "error" });
            return {code:0}
        });
    }
}

export const AcceptOrder = (data) => {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/accept-order`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                swal({ title: "Success!", text: response.ResponseText, icon: "success" });
                dispatch(UserOrderList());
                return 1;
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
            return 0;
        });
    }
}

export const ForceloginClick = (data) => {
    return dispatch => {
        dispatch({ type: actionTypes.FORCE_LOGIN_CLICK, payload: data })
    }
}

export const ForceMamaloginClick = (data) => {
    return dispatch => {
        dispatch({ type: actionTypes.FORCE_MAMA_LOGIN_CLICK, payload: data })
    }
}

export const AfterForceloginClickHeaderUpdate = (data) => {
    return dispatch => {
        dispatch({ type: actionTypes.FORCE_LOGIN_CLICK_HEADER_UPDATE, payload: data })
    }
}

export const UploadImage = (data) => {
    return dispatch => {
        dispatch(ShowLoader(true));
        let request;
        let imageFile = data === 'profile' ? window.$('#logo_image')[0].files[0] : window.$('#cover_image')[0].files[0]
        let formData = new FormData();
        formData.append('file', imageFile);
        formData.append('Imagetype',data);
        request = {
            method: 'POST',
            url: `${BaseUrl}/api/restaurant/upload-profile-image`,
            headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
            data: formData
        };
        return axios(request).then((data) => {
            dispatch(ShowLoader(false));
            dispatch(ShowAlert({ Class: "alert-success", Message:  data.data.ResponseText, Timeout: 1500 }));
            dispatch(restaurantInfo())
            return 1;
        }).catch((error) => {
            dispatch(ShowLoader(false));
            if (error && error.response && error.response.data) {
                dispatch(ShowAlert({ Class: "alert-danger", Message: error.response.data.ResponseText, Timeout: 1500 }));
                return 0;
            } else {
                dispatch(ShowAlert({ Class: "alert-danger", Message: 'Error Occured', Timeout: 1500 }));
                return 0;
            }
        });
    }
}

export const RestaurantSearchList = (data) =>{
    return dispatch => {
        // dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/search`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json'})
        });
        return fetch(request).then(queryResponse).then((response) => {
            // dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                dispatch({ type: actionTypes.NEARBY_RESTAURANTS, payload: response.data })
                return 1;
            } else {
                dispatch({ type: actionTypes.NEARBY_RESTAURANTS, payload: [] })
                return 0;
            }
        }).catch(error => {
            dispatch({ type: actionTypes.NEARBY_RESTAURANTS, payload: [] })
            // dispatch(ShowLoader(false));
            return 0;
        });
    }
}

export const FavouriteListing = (data) =>{
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/favourite`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`})
        });
        return fetch(request).then(queryResponse).then((response) => {
            dispatch(ShowLoader(false));
            dispatch({ type: actionTypes.FAVOURITE_RESTAURANTS, payload: response.data??[] })
            if (response.ResponseCode === '1') {
                return 1;
            } else {
                return 0;
            }
        }).catch(error => {
            dispatch({ type: actionTypes.FAVOURITE_RESTAURANTS, payload: [] })
            dispatch(ShowLoader(false));
            return 0;
        });
    }
}

export const AddToFavourite = (data) =>{
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/restaurant/add-remove-favourite`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`})
        });
        return fetch(request).then(queryResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                dispatch(FavouriteListing({userUniqueID:userUniqueID()}))
                return 1;
            } else {
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
            return 0;
        });
    }
}

export function RestaurantReviewListing(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/review-listing`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.REVIEW_LISTING, payload: data.data })
                return 1;
            } else {
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function AddReview(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/add-review`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                swal({ title: "Success!", text: "Review Added successfully!", icon: "success" });
                return 1;
            } else {
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function AddRecipeReview(data) {
    let recipeID = data["recipeID"];
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/add-recipe-review`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                swal({ title: "Success!", text: "Review Added successfully!", icon: "success" });
                dispatch(RestaurantRecipeReviewListing({ "recipeID": recipeID }))
                return 1;
            } else {
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function RestaurantRecipeReviewListing(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/recipe-review-listing`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.RECIPE_REVIEW_LISTING, payload: data.data })
                return 1;
            } else {
                dispatch({ type: actionTypes.RECIPE_REVIEW_LISTING, payload: [] })
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function UpdateStreamingStatus(data) {
    return dispatch => {
        const request = new Request(`${BaseUrl}/api/edit-streaming`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            if (responseData.ResponseCode === "1") {
                return 1
            } else {
                return 0
            }
        })
    }
}

export const fetchFoodData = (data) => {
    return {
        type: actionTypes.FETCH_FOOD_DATA,
        payload: data
    }
}

export function CondimentListing() {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/global-product-listing`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.FETCH_CONDIMENT_LISTING, payload: data.data })
                return 1;
            } else {
                dispatch({ type: actionTypes.FETCH_CONDIMENT_LISTING, payload :[]})
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function ViewCondiment(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/global-product-view`, {
            method: 'POST',
            body:JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.FETCH_CONDIMENT_VIEW, payload: data.data })
                return 1;
            } else {
                dispatch({ type: actionTypes.FETCH_CONDIMENT_VIEW, payload :[]})
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function RestaurantAddCookingClass(data, this_ref) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let request;
        let imageFile = window.$('#file_icon')[0].files[0];
        let formData = new FormData();
        if (data.editID !== "" && data.editID) {
            formData.append("uniqueID", data.editID);
        }
        formData.append("title", data.title);
        formData.append("date", data.date);
        formData.append("price", data.price);
        formData.append("description", data.description);
        formData.append("duration", data.duration);
        formData.append("image", imageFile);
        formData.append("logo_image", data.logo_image);
        formData.append("data", JSON.stringify(data.data));
        request = {
            method: 'POST',
            url: `${BaseUrl}/api/${data.editID ? "update-cooking-class" : "add-cooking-class" }`,
            headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
            data: formData
        };

        return axios(request).then((data) => {
          if (data.data.ResponseCode === '1') {
            dispatch(ShowLoader(false));
            dispatch(RestaurantCookingClassListing())
            swal({ title: "Success!", text: data.data.ResponseText, icon: "success" });
              return 1;
          } else {
              dispatch(ShowLoader(false));
              swal({ title: "Error!", text: data.data.ResponseText, icon: "error" });
              return 0;
          }
        }).catch((error) => {
            dispatch(ShowLoader(false));
            if (error && error.response && error.response.data) {
                dispatch(ShowAlert({ Class: "alert-danger", Message: error.response.data.ResponseText, Timeout: 1500 }));
                return 0;
            } else {
                dispatch(ShowAlert({ Class: "alert-danger", Message: 'Error Occured', Timeout: 1500 }));
                return 0;
            }
        });
    }
}

// export function RestaurantAddCookingClass (data, this_ref){
//     return(dispatch) => {
//       dispatch(ShowLoader(true));
//       let image = window.$('#file_icon')[0].files[0];
//       //console.log("imageFile",imageFile)
//       let formData = new FormData();
//       formData.append("title", data.title);
//       //formData.append("time", data.time);
//       formData.append("date", data.date);
//       formData.append("price", data.price);
//       formData.append("description", data.description);
//       formData.append("duration", data.duration);
//       formData.append("image", image);
//       formData.append("logo_image", data.logo_image);
//       //console.log("newDate",newDate)
//       let abcd = JSON.stringify({
//          uniqueID: data.editID !== "" && data.editID ? data.editID : undefined,
//          title:data.title,
//          date:data.date,
//          price:data.price,
//          description:data.description,
//          duration:data.duration,
//          image:image,
//          logo_image:data.logo_image,
//          data:data.data
//        })
//       console.log("data.data",abcd)
//       const newdata = data.data
//       formData.append("data", JSON.stringify(data.data));
//       console.log("formData",formData)
//       const request = new Request(`${BaseUrl}/api/${data.editID ? "update-cooking-class" : "add-cooking-class" }`, {
//           method: 'POST',
//           body: formData,
//           headers: new Headers({'X-Auth-Token': `${localStorage.getItem('token')}` })
//       });
//       return fetch(request).then( handleResponse)
//     .then(data =>{
//       dispatch(ShowLoader(false));
//       if(data.ResponseCode === "1"){
//         dispatch(ShowLoader(false));
//         dispatch(RestaurantCookingClassListing())
//         //dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 1500 }));
//         swal({ title: "Success!", text: data.data.ResponseText, icon: "success" });
//           return 1;
//       }
//       else {
//           dispatch(ShowLoader(false));
//           swal({ title: "Error!", text: data.data.ResponseText, icon: "error" });
//           return 0;
//       }
//     })
//     .catch(error => {
//       console.log("error",error)
//         dispatch(ShowLoader(false));
//         if (error && error.response && error.response.data) {
//             dispatch(ShowAlert({ Class: "alert-danger", Message: error.response.data.ResponseText, Timeout: 1500 }));
//             return 0;
//         } else {
//             dispatch(ShowAlert({ Class: "alert-danger", Message: 'Error Occured', Timeout: 1500 }));
//             return 0;
//         }
//     })
//     }
// }

// export function RestaurantAddCookingClass(data, this_ref) {
//     return dispatch => {
//         dispatch(ShowLoader(true));
//         let request;
//         //let imageFile = window.$('#file_icon')[0].files;
//         //let videoFile = window.$('#file_icon1')[0].files;
//         // if(window.$('#file_icon')[0].files && window.$('#file_icon')[0].files[0]){
//         //   let imageFile = window.$('#file_icon')[0].files[0];
//         // }
//         let imageFile = window.$('#file_icon')[0].files[0];
//         console.log("imageFile",imageFile)
//         let formData = new FormData();
//         if (data.editID !== "" && data.editID) {
//             formData.append("uniqueID", data.editID);
//         }
//         formData.append("title", data.title);
//         //formData.append("time", data.time);
//         formData.append("date", data.date);
//         formData.append("price", data.price);
//         formData.append("description", data.description);
//         formData.append("duration", data.duration);
//         formData.append("image", imageFile);
//         formData.append("logo_image", data.logo_image);
//         console.log("data.data",data.data)
//         const newdata = data.data
//         formData.append("data", data.data);
//         // if (newdata.length > 0) {
//         //     let selectedInewdataLength = newdata.length;
//         //     for(let k = 0; k < selectedInewdataLength; k++) {
//         //         formData.append(`data[${k}]`, newdata[k]);
//         //     }
//         // }
//         // formData.append("cooking_time", data.cooking_time);
//         // formData.append("additional_time", data.additional_time);
//         // formData.append("serving_person", data.serving_person);
//         // formData.append("live_status", data.live_status);
//         // if(data.live_status === "Y"){
//         //   let liveVideoFile = window.$('#live_video')[0].files[0];
//         //   formData.append(`live_video`, liveVideoFile);
//         // }
//
//         //formData.append("ingredients", JSON.stringify(data.ingredients));
//         // if (imageFile.length > 0) {
//         //     let selectedImagesLength = imageFile.length;
//         //     for(let k = 0; k < selectedImagesLength; k++) {
//         //         formData.append(`image[${k}]`, imageFile[k]);
//         //     }
//         // }
//         //
//         // if (videoFile.length > 0) {
//         //     let selectedVideosLength = videoFile.length;
//         //     for(let k = 0; k < selectedVideosLength; k++) {
//         //         formData.append(`video[${k}]`, videoFile[k]);
//         //     }
//         // }
//
//         request = {
//             method: 'POST',
//             url: `${BaseUrl}/api/${data.editID ? "update-cooking-class" : "add-cooking-class" }`,
//             headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
//             data: formData
//         };
//
//         return axios(request).then((data) => {
//           console.log("data",data)
//           if (data.data.ResponseCode === '1') {
//             dispatch(ShowLoader(false));
//             dispatch(RestaurantCookingClassListing())
//             //dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 1500 }));
//             swal({ title: "Success!", text: data.data.ResponseText, icon: "success" });
//               return 1;
//           } else {
//               dispatch(ShowLoader(false));
//               swal({ title: "Error!", text: data.data.ResponseText, icon: "error" });
//               return 0;
//           }
//         }).catch((error) => {
//           console.log("error",error)
//             dispatch(ShowLoader(false));
//             if (error && error.response && error.response.data) {
//                 dispatch(ShowAlert({ Class: "alert-danger", Message: error.response.data.ResponseText, Timeout: 1500 }));
//                 return 0;
//             } else {
//                 dispatch(ShowAlert({ Class: "alert-danger", Message: 'Error Occured', Timeout: 1500 }));
//                 return 0;
//             }
//         });
//     }
// }

export function RestaurantCookingClassListing() {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/cooking-class-list`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.COOKING_CLASS_LISTING, payload: data.data })
                return 1;
            } else {
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function DeleteCookingClass(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/delete-cooking-class`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
              dispatch(RestaurantCookingClassListing())
              swal({ title: "Deleted!", text: response.ResponseText, icon: "success" });
                return 1;
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });
    }
  }

  export function ViewRestaurantCookingClass(uniqueID) {
      return dispatch => {
          dispatch(ShowLoader(true));
          let data = {
              uniqueid:uniqueID
          }
          const request = new Request(`${BaseUrl}/api/get-cooking-class`, {
              method: 'POST',
              body: JSON.stringify(data),
              headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
          });
          return fetch(request).then(handleResponse).then((response) => {
              dispatch(ShowLoader(false));
              if (response.ResponseCode === '1') {
                  dispatch({ type: actionTypes.COOKING_CLASS_VIEW, payload: response.data });
                  return 1;
              } else {
                  swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                  return 0;
              }
          }).catch(error => {
              dispatch(ShowLoader(false));
          });;
      }
  }

  export function AllRestaurantCookingClassListing() {
      return dispatch => {
          dispatch(ShowLoader(true));
          const request = new Request(`${BaseUrl}/api/all-cooking-class-list`, {
              method: 'POST',
              headers: new Headers({ 'Content-Type': 'application/json' })
          });
          return fetch(request).then(queryResponse).then((data) => {
              dispatch(ShowLoader(false));
              if (data.ResponseCode === '1') {
                  dispatch({ type: actionTypes.ALL_RESTAURANT_COOKING_CLASS_LISTING, payload: data.data })
                  return 1;
              } else {
                  return 0;
              }
          }).catch(error => {
              dispatch(ShowLoader(false));
          });;
      }
  }

  export const bookCookingClass = (data) => {
      return dispatch => {
          dispatch(ShowLoader(true));
          const request = new Request(`${BaseUrl}/api/book-cooking-class`, {
              method: 'POST',
              body: JSON.stringify(data),
              headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
          });
          return fetch(request).then(queryResponse).then((response) => {
              dispatch(ShowLoader(false));
              if (response.ResponseCode === '1') {
                  if(data.payment_type==='cash'){
                      swal({ title: "Success!", text: response.ResponseText, icon: "success" });
                      dispatch(ShowAlert({ Class: "alert-success", Message: response.ResponseText, Timeout: 10000 }));
                      return {code:1,type:'cash'}
                  }else{
                      // dispatch(ShowAlert({ Class: "alert-success", Message: 'Added to Cart', Timeout: 10000 }));
                      return {code:1,type:'card',order_number:response.data}
                  }
              } else {
                  return {code:0}
              }
          }).catch(error => {
              dispatch(ShowLoader(false));
              return {code:0}
          });
      }
  }

  export const bookCookingClassDetail = (data) => {
      return dispatch => {
          dispatch(ShowLoader(true));
          const request = new Request(`${BaseUrl}/api/cooking-class-order-detail`, {
              method: 'POST',
              body: JSON.stringify(data),
              headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
          });
          return fetch(request).then(queryResponse).then((response) => {
              dispatch(ShowLoader(false));
              if (response.ResponseCode === '1') {
                  dispatch({ type: actionTypes.BOOKING_CLASS_DETAIL, payload: response.data })
                  return {code:1,data:response.data}
              } else {
                  swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                  return {code:0}
              }
          }).catch(error => {
              dispatch(ShowLoader(false));
              swal({ title: "Error!", text: error, icon: "error" });
              return {code:0}
          });
      }
  }

  export function GetRestaurantIds(food_item) {
      return dispatch => {
          dispatch(ShowLoader(true));
          const request = new Request(`${BaseUrl}/api/restaurant/get-restaurant`, {
              method: 'POST',
              body: JSON.stringify({ food_item: food_item }),
              headers: new Headers({ 'Content-Type': 'application/json' })
          });
          return fetch(request).then(queryResponse).then((data) => {
              dispatch(ShowLoader(false));
              if (data.ResponseCode === '1') {
                  dispatch({ type: actionTypes.FILTER_RESTAURANT_ID, payload: data.data })
                  return 1;
                  //return {code:1,response:data.ResponseText,type:data.type};
              } else {
                  dispatch({ type: actionTypes.FILTER_RESTAURANT_ID, payload :[]})
                  return 0;
              }
          }).catch(error => {
              dispatch(ShowLoader(false));
          });;
      }
  }


  export const SearchNearByLocations = (data) =>{
    return dispatch => {
        // dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/api/suggest-locations`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json'})
        });
        return fetch(request).then(queryResponse).then((response) => {
            // dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                dispatch({ type: actionTypes.NEARBY_LOCATIONS, payload: response.data })
                return 1;
            } else {
                dispatch({ type: actionTypes.NEARBY_LOCATIONS, payload: [] })
                return 0;
            }
        }).catch(error => {
            dispatch({ type: actionTypes.NEARBY_RESTAURANTS, payload: [] })
            // dispatch(ShowLoader(false));
            return 0;
        });
    }
}

export const FetchLatLongs = (data) => { 
    return () => { 
        const request = new Request(`${BaseUrl}/api/api/fetch-lat-longs`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json'})
        });
        return fetch(request).then(queryResponse).then((response) => {
            if(response.data){
                return response.data
            }else{
                return {latitude:'',longitude:''}
            } 
        }).catch(error => { 
            return 0;
        });
    }
}
