import moment from 'moment';

export const getTimeStops = (start, end, slot,timeformat='HH:mm') => { //hh:mm A
    var startTime = moment(start, timeformat);
    var endTime = moment(end, timeformat);
    if( endTime.isBefore(startTime) ){
      endTime.add(1, 'day');
    }
    var timeStops = [];
    while(startTime <= endTime){
      timeStops.push(new moment(startTime).format(timeformat));
      startTime.add(slot, 'minutes');
    }
    return timeStops;
  }

export const Addtimes = (currentTime,minutes=15,format="HH:mm") => {
  return moment.utc(currentTime,format).add(minutes,'minutes').format(format)
}

export const timezone_offset = () => {
  let date      = new Date();
  let newOffset = date.getTimezoneOffset();
  return newOffset;
}

export const handlePermission = () => {
  if (!navigator.permissions){
    console.log("Geolocation is not supported by your browser");
     return;
  }
  navigator.permissions.query({name:'geolocation'}).then((result) => {
    if (result.state === 'granted') {
      return report(result.state);
    } else if (result.state === 'prompt') {
      return report(result.state);
    } else if (result.state === 'denied') {
      return report(result.state);
    }
    result.onchange = () => {
      return report(result.state);
    }
  });
}

export const report = async (state) => {
  if(state==='granted'){
    return detectLocation()
  }else{
    if(state==='denied'){
      alert('User denied to locate current position')
    }
    sessionStorage.removeItem('user_location')
  }
}

const detectLocation = () => {
  if (!navigator.geolocation){
    console.log("Geolocation is not supported by your browser");
     return;
  }
  navigator.geolocation.getCurrentPosition((position) => {
      let latitude = position.coords.latitude;
      let longitude = position.coords.longitude;
      sessionStorage.setItem('user_location',JSON.stringify({latitude,longitude}))
    },(error) => {
      alert(error.message);
    }
  );
}

export const SetLocalExpiry = (key, value, ttl=365*24*3600) => {
  let now = new Date();
  let item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const GetLocalWithExpiry = (key) => {
	let itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	let item = JSON.parse(itemStr)
	let now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		return null
	}
	return item.value
}
