import React,{useEffect} from "react";
import { useSelector,useDispatch } from 'react-redux';
import { AdminDataCountList } from './../../store/actions/adminActions'

function Dashboard() {
  // store data access start
const data_count = useSelector(state =>state.admin.data_count)
// store data access End

const dispatch = useDispatch()  // for accessing the redux function

useEffect(() =>{
  dispatch(AdminDataCountList())
},[dispatch])

    return (
      <div className="col-lg-10 col-md-9 col-sm-12 col-xs-12">
        <div className="row">

          <div className="col-lg-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <span className="dash-widget-icon text-success">
                    <i className="icon-user"></i>
                  </span>
                  <div className="dash-count">
                    <h3>{data_count && Object.keys(data_count).length > 0 && data_count.user_count ? data_count.user_count : ""}</h3>
                  </div>
                </div>
                <div className="dash-widget-info">

                  <h6 className="text-muted">User</h6>
                  <div className="progress progress-sm">
                    <div className="progress-bar bg-success w-50"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <span className="dash-widget-icon text-primary border-primary">
                    <i className="icon-user"></i>
                  </span>
                  <div className="dash-count">
                    <h3>{data_count && Object.keys(data_count).length > 0 && data_count.product_count ? data_count.product_count : ""}</h3>
                  </div>
                </div>
                <div className="dash-widget-info">
                  <h6 className="text-muted">Products</h6>
                  <div className="progress progress-sm">
                    <div className="progress-bar bg-primary w-50"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <span className="dash-widget-icon text-danger border-danger">
                    <i className="icon-shop"></i>
                  </span>
                  <div className="dash-count">
                    <h3>{data_count && Object.keys(data_count).length > 0 && data_count.order_count ? data_count.order_count : ""}</h3>
                  </div>
                </div>
                <div className="dash-widget-info">

                  <h6 className="text-muted">Orders</h6>
                  <div className="progress progress-sm">
                    <div className="progress-bar bg-danger w-50"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <span className="dash-widget-icon text-warning border-warning">
                    <i className="icon-account_balance_wallet"></i>
                  </span>
                  <div className="dash-count">
                    <h3> ₹ {data_count && Object.keys(data_count).length > 0 && data_count.revenue ? data_count.revenue : "0"}</h3>
                  </div>
                </div>
                <div className="dash-widget-info">

                  <h6 className="text-muted">Revenue</h6>
                  <div className="progress progress-sm">
                    <div className="progress-bar bg-warning w-50"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <div className="card card-chart">
              <div className="card-header">
                <h4 className="card-title">Revenue</h4>
              </div>
              <div className="card-body">
                <div id="morrisArea"></div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="card card-chart">
              <div className="card-header">
                <h4 className="card-title">Status</h4>
              </div>
              <div className="card-body">
                <div id="morrisLine"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
}



export default React.memo(Dashboard);
