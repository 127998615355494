import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {UserOrderList,DeleteAdminRecord} from './../../store/actions/adminActions'
import Pagination from './../../store/helpers/Pagination'
import {bucketPath} from './../../store/helpers/common'
import moment from 'moment'
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Modal from './../../modules/Html/Modal'
import swal from 'sweetalert';

const avatarCss = {
  verticalAlign: "middle",
  width: "35px",
  height: "35px",
  borderRadius: "50%",
};
const OrderStatus = {
  N: "New",
  P: "Preparing",
  A: "Accepted",
  R: "Rejected",
  D: "Delivered",
  C: "Completed",
};

const OrderColor = {
  N: "#1e73be",
  P: "#1e73be",
  R: "#c33332",
  D: "#047a06",
  C: "#047a06",
  A: "#047a06",
};
class AdminOrder extends Component {
    constructor(props){
        super(props);
        this.state = {
            pageOfItems:[],
            currentPage:1,
            name: "",
            date: "",
            address: "",
            price: "",
            status: "",
            pickerDate: "",
            open:false,
            viewData:[]
        }
    }

    componentDidMount() {
        this.props.dispatch(UserOrderList());
      }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page, open: false });
  };

  CloseModal = () => {
    this.setState({ open: false, viewData: [] });
  };

    onSearchChange = (event) => {
        this.setState({[event.target.name]: event.target.value}, () => {
            this.filterData();
        });
    }

    filterData = () => {
        const {name, date, address, price, status} = this.state;
        let updatedList = this.props.order_list;

        updatedList = this.props.order_list.filter(field => {
            if(name) {
                return (
                    field.restaurant_name.toString().search(name.toString()) !== -1
                  );
            } else if(date) {
                return (
                    field.creationdate.toString().search(date) !== -1
                  );
            } else if(address) {
                return (
                    field.restaurants_location.toString().search(address) !== -1
                );
            } else if(price) {
                return (
                    field.grand_total.toString().search(price.toString()) !== -1
                );
            } else if(status) {
                return (
                    field.status.toString().search(status.toString()) !== -1
                );
            } else {
                return field;
            }
        })
        this.setState({ pageOfItems: updatedList.slice(0, 10) });
    }

    onSearchBlur = () => {
        console.error(this.state);
    }

    renderDateTimePicker = () => {
        return (
          <ReactDatePicker
            selected={this.state.pickerDate}
            onChange={pickerDate => {
                this.setState({pickerDate, date: moment(pickerDate).format("YYYY-MM-DD HH:mm:ss")})
            }
            }
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
            dateFormat="MMMM d, yyyy h:mm aa"
          />
        );
    };

    renderStatusOptions = () => {
       const objectArray = Object.entries(OrderStatus);
       return objectArray.map(([key, value]) => {
           return <option key={key} value={key}>{value}</option>
       })
    }

    DeleteOrder = (id) => {
      let data={type:'order',uniqueID:id}
      swal({
          title: "Are you sure you want to delete this Order?",
          buttons: true,
          dangerMode: true,
      }).then((willDelete) => {
          if (willDelete) {
              this.props.dispatch(DeleteAdminRecord(data));
          } else { 
            console.log("Record is Safe");
          }
      });
    };

    render() {
        const {pageOfItems,currentPage, name, address, price, status, open,viewData} = this.state
        const {order_list} = this.props
        return (
            <div className="col-lg-10 col-md-9 col-sm-12 col-xs-12">
                <div className="user-dashboard loader-holder">
                    <div className="user-holder">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="row">
                                <div className="element-title has-border right-filters-row">
                                    <h5>Orders List</h5>
                                    <div className="right-filters row pull-right">
                                        <div className="col-lg-6 col-md-6 col-xs-6">
                                            {/* <div>
                                            <div className="input-field">
                                                <select className="chosen-select-no-single">
                                                    <option value="">Select order Status</option>
                                                </select>
                                            </div>
                                            </div> */}
                    </div>
                    {/* <div className="col-lg-6 col-md-6 col-xs-6">
                                            <div className="input-field">
                                                <i className="icon-angle-down"></i>
                                                <input
                                                    type="text"
                                                    data-id="daterange223"
                                                    id="daterange"
                                                    placeholder="Select Date Range"
                                                />
                                            </div>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="user-orders-list">
                  <div className="table-responsive">
                    <ul className="table-generic table-generic-temp">
                      <li className="order-heading-titles">
                        <div>Sr No</div>
                        <div>Name</div>
                        <div>Date</div>
                        <div>Address</div>
                        <div>Price</div>
                        <div>Profile</div>
                        <div>Status</div>
                        <div>Action</div>
                      </li>
                      <li>
                        <div></div>
                        <div><input className="form-control" type="text" name="name" onChange={this.onSearchChange} value={name} /></div>
                        <div>{this.renderDateTimePicker()}</div>
                        <div><input className="form-control" type="text" name="address" onChange={this.onSearchChange} value={address} /></div>
                        <div><input className="form-control" type="text" name="price" onChange={this.onSearchChange} value={price} /></div>
                        <div></div>
                        <div>
                            <select name="status" value={status} onChange={this.onSearchChange} className="form-control">
                                <option value="">All</option>
                                {this.renderStatusOptions()}
                            </select>
                        </div>
                    </li>
                      {pageOfItems instanceof Array &&
                        pageOfItems.map((value, index) => {
                          return (
                            <li
                              className="order-heading-titles"
                              key={`menu_${index}`}
                            >
                              <div>{index + 1 + (currentPage - 1) * 10}</div>
                              <div>{`${value.restaurant_name}`}</div>
                              <div>
                                {moment(value.creationdate).format("lll")}
                              </div>
                              <div>{value.user_location}</div>
                              <div> ₹ {value.grand_total}</div>
                              <div>
                                {value.restaurants_profile_pic && <img
                                  className="avatar"
                                  src={
                                    value.restaurants_profile_pic
                                      ? `${bucketPath}${value.restaurants_profile_pic}`
                                      : "assets/extra-images/listing-logo18.png"
                                  }
                                  alt="dp"
                                  style={avatarCss}
                                />}
                              </div>
                              <div>
                                <span
                                  className="order-status"
                                  style={{
                                    backgroundColor: OrderColor?.[value.status],
                                  }}
                                >
                                  {OrderStatus?.[value.status]}
                                </span>
                              </div>
                              <div>
                                <Link
                                  className="pull-left"
                                  to="#"
                                  onClick={(e) =>
                                    this.setState({
                                      open: true,
                                      viewData: value,
                                    })
                                  }
                                >
                                  <i className="fa fa-eye"></i>
                                </Link>&nbsp;&nbsp;
                                <Link
                                    data-toggle="modal"
                                    to="#" 
                                    onClick={e => this.DeleteOrder(value.order_number)}
                                >
                                    <i className="fa fa-trash alert-danger"></i> 
                                </Link>
                              </div>
                            </li>
                          );
                        })}
                      {pageOfItems instanceof Array &&
                        pageOfItems.length === 0 && (
                          <li className="order-heading-titles">
                            <p>No data available</p>
                          </li>
                        )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {order_list instanceof Array && (
              <Pagination
                pageSize={10}
                items={order_list}
                onChangePage={this.onChangePage}
              />
            )}
          </div>
        </div>
        {/*  NEXT IS VIEW MODAL */}
        <Modal
          open={open}
          size={"lg"}
          title={"Order Detail"}
          CloseModal={this.CloseModal}
        >
          <div role="tabpanel" className="tab-pane active">
            <div id="result_296069" className="status-message"></div>
            <form method="post" className="wp-user-form demo_test">
              <ul className="order-detail-options">
              <li className="order_number">
                  <strong>
                    <h6>Order No:</h6>

                  </strong>
                  <span>
                    <b>{viewData.order_number}</b>
                  </span></li>
                <li className="name">
                  <strong>
                    <h6>Name:</h6>
                  </strong>
                  <span>
                    <b>{viewData.restaurant_name}</b>
                  </span>
                </li>
                <li className="address">
                  <strong>
                    <h6>Address:</h6>
                  </strong>
                  <span>
                    <b>{viewData.user_location}</b>
                  </span>
                </li>
                <li className="restaurant_name">
                  <strong>
                    <h6>Restaurant Name:</h6>
                  </strong>
                  <span>
                    <b>{viewData.restaurant_name}</b>
                  </span>
                  
                </li>
                <li className="restaurants_phone">
                  <strong>
                    <h6>Restaurant Phone:</h6>
                  </strong>
                  <span>
                    <b>{viewData.restaurants_phone}</b>
                  </span>
                  </li>
                
                <li className="created-date">
                  <strong>
                    <h6> Date:</h6>{" "}
                  </strong>
                  <span>
                    <b>
                      {viewData.creationdate
                        ? moment(viewData.creationdate).format("lll")
                        : "NA"}
                    </b>
                  </span>
                </li>
                <li className="restaurants_phone">
                  <strong>
                    <h6>Restaurant Phone:</h6>
                  </strong>
                  <span>
                    <b>{viewData.restaurants_phone}</b>
                  </span>
                  </li>
                  <li className="user_name">
                  <strong>
                    <h6>Name:</h6>
                  </strong>
                  <span>
                    <b>{`${viewData.user_firstname} ${viewData.user_lastname}`}</b>
                  </span>
                  </li>
                  <li className="user_email">
                  <strong>
                    <h6>Email:</h6>
                  </strong>
                  <span>
                    <b>{viewData.user_email}</b>
                  </span>
                  </li>
                <li className="user_phone">
                  <strong>
                    <h6>Phone No:</h6>

                  </strong>
                  <span>
                    <b>{viewData.user_phone}</b>
                  </span></li>
                  <li className=" user_location">
                  <strong>
                    <h6>Location:</h6>

                  </strong>
                  <span>
                    <b>{viewData.user_location}</b>
                  </span></li>
             
                <li className="payment_type">
                  <strong>
                    <h6>Payment Type</h6>

                  </strong>
                  <span>
                    <b>{viewData.payment_type}</b>
                  </span></li>
                  <li className="pickup_fee">
                  <strong>
                    <h6>Pickup Fee:</h6>

                  </strong>
                  <span>
                    <b>{viewData.pickup_fee}</b>
                  </span></li>
                
                <li className="Delivery Fee">
                  <strong>
                    <h6>Delivery Fee:</h6>

                  </strong>
                  <span>
                    <b>{viewData.delivery_fee}</b>
                  </span></li>
                  <li className="vat_rate">
                  <strong>
                    <h6>Vat Rate:</h6>
                  </strong>
                  <span>
                    <b> {viewData.vat_rate}</b>
                  </span>
                </li>
                <li className="vat_">
                  <strong>
                    <h6>Vat:</h6>
                  </strong>
                  <span>
                    <b> {viewData.vat}</b>
                  </span>
                </li>
             
                <li className="grand-total">
                  <strong>
                 
                    <h6>Price:</h6>
                  </strong>
                  <span>
                    <b> {` ₹ ${viewData.grand_total}`}</b>
                  </span>
                </li>
                <li className="status">
                  <strong>
                    <h6> Status:</h6>
                  </strong>
                  <span>
                    <b>{viewData.status}</b>
                  </span>
                </li>
              </ul>
              <div className="side-by-side select-icon clearfix">
                <div className="select-holder"></div>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  order_list: state.admin.admin_order_list,
});

export default connect(mapStateToProps)(AdminOrder);
