import React, { Component,Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as RestaurantAction from "./../../store/actions/restaurantActions";
import {bucketPath} from './../../store/helpers/common'
import SimpleReactValidator from "simple-react-validator";
import { UserOrderList } from "./../../store/actions/buyerActions";
import { AddReview } from './../../store/actions/restaurantActions';
import moment from "moment";
import "react-phone-number-input/style.css";
import swal from 'sweetalert';
import Modal from "../../modules/Html/Modal";
import SimpleReactvalidator from 'simple-react-validator'

const OrderStatus = {
  N: "Waiting for Mama",
  P: "Preparing",
  A: "Accepted and Preparing",
  R: "Rejected",
  D: "Out For Delivery",
  C: "Completed",
};

const OrderColor = {
  N: "#1e73be",
  P: "#1e73be",
  R: "#c33332",
  D: "#047a06",
  C: "#047a06",
  A: "#047a06",
};


const RestaurantList = (props) => {
  //const [liveStream, setLiveStream] = useState("");
  const {favourite_restaurants} = props;


  const addToFavouriteHandler = () =>{
    const getUserIdentity = localStorage.getItem('token');
    if (!getUserIdentity) {
      swal({
        text: "You need to login to Add Favourite Restaurant !",
        icon: "info",
        buttons: true,
        dangerMode: true,
      })
        .then((yesDo) => {
          if (yesDo) {
            props.ParentforceloginUser()
          } else {
            console.log("Record is Safe");
          }
        });
      return false;
    }
    props.AddtoFavourite(props['restaurantsDetails'].uniqueID,favourite_restaurants.includes(props['restaurantsDetails'].uniqueID)?"N":"Y")
  }

  return (
    <Fragment>
      <div className="img-holder">
        <figure>
        {
          //<a href={void 0} onClick={() => openStream(props['restaurantsDetails'].streaming === "YES" ? props['restaurantsDetails'].uniqueID : '')} style={{cursor: "pointer"}}>
        }
          <Link to={`/menu-listing/${props['restaurantsDetails'] && props['restaurantsDetails'].uniqueID &&  props['restaurantsDetails'].uniqueID}`}>
            <img
              src={`${
                props['restaurantsDetails'] && props['restaurantsDetails'].profile_pic
                  ? `${bucketPath}${props['restaurantsDetails'].profile_pic}`
                  : "assets/extra-images/mama-logo.png"
              }`}
              className="img-list wp-post-image"
              alt="..."
            />
            </Link>
            {
              //</a>
            }

        </figure>
        <span className={`restaurant-status ${props['restaurantsDetails'] && (props['restaurantsDetails'].restaurant_status === "OPEN" || props['restaurantsDetails'].streaming === "YES") ? "open" : "close"}`}>
          <em className="bookmarkRibbon"></em>{ props['restaurantsDetails'] && (props['restaurantsDetails'].streaming === "YES" ? `Live` : props['restaurantsDetails'].restaurant_status === "OPEN") ? `OPEN` : `Close`}
        </span>
      </div>
      <div className="text-holder">
        <div className="post-title">
          <h5>
            <Link to={`/menu-listing/${props['restaurantsDetails'] && props['restaurantsDetails'].uniqueID}`}>
              {props['restaurantsDetails'] && props['restaurantsDetails'].restaurant_name}
            </Link>
          </h5>
        </div>
        {/* <span className="post-categories">
          <span>Apple Juice</span>
        </span> */}
        <div className="delivery-potions">
          <div className="post-time">
            <i className="icon-location-pin"></i>
          </div>
          <span>{props['restaurantsDetails'] && props['restaurantsDetails'].location}</span>
        </div>
        <div className="list-rating">
          <div className="rating-star">
            <span className="rating-box" style={{ width: `${ props['restaurantsDetails'] && props['restaurantsDetails'].average_points != null ? props['restaurantsDetails'].average_points : 0 }%` }}></span>
          </div>
          <span className="reviews">({props['restaurantsDetails'] && props['restaurantsDetails'].review_count})</span>
        </div>
      </div>
      <div className="list-option">
        <Link
          to="#"
          className="shortlist-btn"
          //onClick={e=>props.AddtoFavourite(props['restaurantsDetails'].uniqueID,favourite_restaurants.includes(props['restaurantsDetails'].uniqueID)?"D":"Y")}
          onClick={() =>addToFavouriteHandler()}
        >
          <i className={favourite_restaurants.includes(props['restaurantsDetails'] && props['restaurantsDetails'].uniqueID)?"icon-heart4":"icon-heart3"}></i>
        </Link>
        <Link
          to={`/menu-listing/${props['restaurantsDetails'] && props['restaurantsDetails'].uniqueID}`}
          className="viewmenu-btn text-color"
        >
          View Menu
        </Link>
      </div>
    </Fragment>
  );
};

class BuyerDashboardNew extends Component {
    constructor(props) {
        super(props);
        this.formValidator = new SimpleReactValidator();
        this.state ={
          pageOfItems: [],
          currentPage: 1,
          recentRestaurantList:[],
          open: false,
          modal_type: "",
          order_detail: [],
          title: '',
          description: '',
          rating: 1,
          recentOrderList:[]
        }
        this.favouriteList = [];
        this.validator = new SimpleReactvalidator();
      }

      componentDidMount() {
        let data = this.props.food_item ? this.props.food_item : ''
        const getUserType = localStorage.getItem('type');
        const getUserData =  localStorage.getItem('data');
        this.props.dispatch(RestaurantAction.RestaurantListing({food_item:data}));
        this.props.dispatch(RestaurantAction.fetchFoodData(this.props.food_item));
        if(getUserType === "U"){
          this.props.dispatch(RestaurantAction.FavouriteListing({userUniqueID:JSON.parse(getUserData).uniqueID}))
        }
        this.props.dispatch(UserOrderList());
      }

        AddtoFavourite = (restaurantUnique,favourite) => {
          const getUserData =  localStorage.getItem('data');
          this.props.dispatch(RestaurantAction.AddToFavourite({userUnique:JSON.parse(getUserData).uniqueID,restaurantUnique,favourite}))
        }

        onChangePage = (pageOfItems, page) => {
          this.setState({ pageOfItems: pageOfItems, currentPage: page });
        };

        UNSAFE_componentWillReceiveProps(newProps){
          let { restaurants:{favourite_restaurants} }= newProps
          const { restaurants:{restaurant_listing} } = newProps;
          const { order_list } = newProps;
          this.favouriteList = []
          if(favourite_restaurants instanceof Array){
            favourite_restaurants.map(value=>{
              this.favouriteList = [...this.favouriteList,value.restaurantID];
              return null
            })
          }
          const new_data = []
          restaurant_listing.slice(0,3).map((item,index) =>{
            new_data.push(item)
            return null
          })
           this.setState({
             recentRestaurantList:new_data
           })

           const order_data = []
           order_list.filter(filterItem =>filterItem.order_type === "food").slice(0,4).map((item,index) =>{
             order_data.push(item)
             return null;
           })
            this.setState({
              recentOrderList:order_data
            })
        }

        CloseModal = () => {
          this.setState({ open: false, modal_type: "" });
        };

        CloseReviewModal = e => {
          this.setState({
            reviewPopupOpen: false
          })
        }

        onChangeHandler = e => {
          this.setState({ [e.target.name]: e.target.value });
        }

        onRadioChangeHandler = e => {
          this.setState({ rating: e.target.value });
        }

        addRivew = async (e) => {
          const { title, description, rating, restaurantId } = this.state;
          if (this.validator.allValid()) {
            let response = await this.props.dispatch(AddReview({ title, description, points: (rating * 20), restaurantID: restaurantId }));
            if (response === 1) {
              this.CloseModal();
            }
          } else {
            this.validator.showMessages();
            this.forceUpdate()
          }
        }

  render() {
    const {recentRestaurantList,recentOrderList, open, modal_type, order_detail, title, description, rating } = this.state;
    //const { restaurants:{restaurant_listing} } = this.props;
    //const { restaurants:{favourite_restaurants} }= this.props
    //const { order_list } = this.props;
    //let new_order_details = order_detail.items ? order_detail.items : []

    return (
      <>
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div className="user-dashboard loader-holder">

        <div className="user-holder">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="row">
                <div className="element-title has-border right-filters-row">
                  <h5>Recent Orders</h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="user-orders-list">
                  <div className="row">
                    {recentOrderList instanceof Array &&
                      recentOrderList.map((value, index) => {
                        return (
                          <div
                            className="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                            key={`order_list_${index}`}
                          >
                            <div
                              className="order-list"
                              style={{ height: "272px" }}
                            >
                              <div className="author-info">
                                <div className="img-holder">
                                  <figure>
                                    <Link to="#">
                                      <img
                                        src={
                                          value.restaurants_profile_pic
                                            ? `${bucketPath}${value.restaurants_profile_pic}`
                                            : "assets/extra-images/listing-logo18.png"
                                        }
                                        alt=""
                                      />{" "}
                                    </Link>
                                  </figure>
                                </div>
                                <div className="text-holder">
                                  <h6>
                                    <Link to="#">{value.restaurant_name}</Link>
                                  </h6>
                                  <address>{value.restaurants_city != null && value.restaurants_address !=null ? value.restaurants_city + "," + value.restaurants_address : null}</address>
                                  <span className="price">
                                    Rs {value.grand_total}
                                  </span>

                                </div>
                                <div className="text-holder">
                                  <span className="orderId">Order ID: #{value.order_number}</span>
                                  <div className="pull-right">
                                    <Link to="#" className="read-more text-color" onClick={e => {
                                      this.setState({
                                        open: true,
                                        modal_type: "add_review",
                                        restaurantId: value.restaurants_uniqueID
                                      })
                                    }}>Add Review</Link>
                                  </div>
                                </div>
                              </div>
                              <div className="post-time">
                                <span>{value.user_location}</span>
                                <span>Deliver in 10 Minutes </span>
                              </div>
                              <span className="date-time">
                                {moment(value.creationdate).format("lll")}
                              </span>
                              <div className="order-btn">
                                <Link
                                  to="#"
                                  onClick={(e) =>
                                    this.setState({
                                      open: true,
                                      modal_type: "view_detail",
                                      order_detail: value,
                                    })
                                  }
                                >
                                  Order Detail
                                </Link>

                                {value.status === "R" && <Link className="btn-order-reject" to="#" >Rejected</Link>}

                                {value.status === "P" && <> <Link className="btn-order-process" to="#">Preparing</Link></>}

                                {value.status === "N" && <> <Link className="btn-order-process" to="#">Waiting For Mama</Link></>}

                                {value.status === "A" && <><Link className="btn-order-accept" to="#">Accepted and Preparing</Link></>}

                                {value.status === "D" && <><Link to="#" className="btn-order-dispatch">Out For Delivery</Link></>}

                                {value.status === "C" && <Link to="#" className="btn-order-completed" onClick={e => this.onClickHandler('C', value.order_number)}>Completed</Link>}

                                {/* {value.status==="R" && <Link className="btn-order-reject" to="#" >Rejected</Link>}

                                {value.status === "N" && <> <Link className="btn-order-process" to="#">Processing</Link> &nbsp; <Link className="btn-order-in-progress" to="#">In Progress</Link></>}

                                {value.status === "A" && <><Link className="btn-order-accept" to="#">Accepted</Link> &nbsp; <Link className="btn-order-prepar" to="#" onClick={e=>this.onClickHandler('D',value.order_number)}>Preparing</Link></>}

                                {value.status === "D" && <><Link className="btn-order-accept" to="#">Accepted</Link> &nbsp; <Link className="btn-order-processed" to="#">Processed</Link> &nbsp; <Link to="#" className="btn-order-dispatch">Dispatched</Link></>}

                                {value.status==="C" && <Link to="#" onClick={e=>this.onClickHandler('C',value.order_number)}>Completed</Link>} */}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>

            <div className="listing simple">
            <div className="element-title">
                        <h5>Suggested Restaurants</h5>
            </div>
              <ul>
                {recentRestaurantList instanceof Array &&
                  recentRestaurantList.map((value, index) => {
                    return (
                      <li key={`restaurant_list${index}`}>
                        <RestaurantList {...value} AddtoFavourite={this.AddtoFavourite} favourite_restaurants={this.favouriteList} ParentforceloginUser ={this.forceloginUser}/>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

              </div>
            </div>
        </div>

        {/* Modal Data */}
        <Modal
          open={open}
          size={"lg"}
          title={
            modal_type === "view_detail" ? "" : "Order Review"
          }
          CloseModal={this.CloseModal}
        >
          {modal_type === "add_review" && (
            <div className="order-detail-inner">
              <div className="reviews-holder">
                <div className="add-new-review-holder add-new-review-2067">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <h3>Rate and Write a Review</h3>
                    </div>
                    <div className="foodbakery-add-review-data">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div
                          className="total-rating user-rating-container overall-rating"
                          data-overall-rating="1"
                        >
                          <h6>Rating</h6>
                          <div className="rating-star">
                            <input
                              id="star-13"
                              type="radio"
                              name="rating1"
                              value="1"
                              className="star-1"
                              defaultChecked={rating === "1" || rating === "2" || rating === "3" || rating === "4" || rating === "5" ? "checked" : ""}//"checked"
                              onClick={e => this.onRadioChangeHandler(e)}
                            />
                            <label className="star-13" htmlFor="star-13">
                              1
                            </label>
                            <input
                              id="star-23"
                              type="radio"
                              name="rating2"
                              value="2"
                              className="star-2"
                              defaultChecked={rating === "2" || rating === "3" || rating === "4" || rating === "5" ? "checked" : ""}//"checked"
                              onClick={e => this.onRadioChangeHandler(e)}
                            />
                            <label className="star-2" htmlFor="star-23">
                              2
                            </label>
                            <input
                              id="star-33"
                              type="radio"
                              name="rating3"
                              value="3"
                              className="star-3"
                              defaultChecked={rating === "3" || rating === "4" || rating === "5" ? "checked" : ""}//"checked"
                              onClick={e => this.onRadioChangeHandler(e)}
                            />
                            <label className="star-3" htmlFor="star-33">
                              3
                            </label>
                            <input
                              id="star-43"
                              type="radio"
                              name="rating4"
                              value="4"
                              className="star-4"
                              defaultChecked={rating === "4" || rating === "5" ? "checked" : ""}//"checked"
                              onClick={e => this.onRadioChangeHandler(e)}
                            />
                            <label className="star-4" htmlFor="star-43">
                              4
                            </label>
                            <input
                              id="star-53"
                              type="radio"
                              name="rating5"
                              value="5"
                              className="star-5"
                              defaultChecked={rating === "5" ? "checked" : ""}//"checked"
                              onClick={e => this.onRadioChangeHandler(e)}
                            />
                            <label className="star-5" htmlFor="star-53">
                              5
                            </label>
                            <span style={{ width: (rating * 20) + "%" }}></span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="form-element">
                          <i className="icon-edit2"></i>
                          <input
                            type="text"
                            placeholder="Title of your review *"
                            value={title}
                            name="title"
                            onChange={e => this.onChangeHandler(e)}
                          />
                          {this.validator.message('title', title, 'required')}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="form-element">
                          <textarea
                            placeholder="Tell about your experience or leave a tip for others"
                            cols="30"
                            rows="10"
                            maxLength="500"
                            name="description"
                            value={description}
                            onChange={e => this.onChangeHandler(e)}
                          ></textarea>
                          {this.validator.message('description', description, 'required|min:10')}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="form-element message-length">
                          <span>Min characters: 10</span>
                          <span>Max characters: 500</span>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="form-element">
                          <input
                            type="button"
                            defaultValue="Submit your Review"
                            onClick={e => this.addRivew(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {modal_type === "view_detail" && (

            <div className="order-detail-inner">
      <div className="description-holder">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className="list-detail-options has-checkbox">
              <h3>Restaurant Demo </h3>
              <ul className="order-detail-options">
                <li className="order-number">
                  <strong>Order ID:</strong>
                  <span>{order_detail.order_number}</span>
                </li>
                <li className="req-delivery">
                  <strong>Delivery Time:</strong>
                  <span>10 Minutes </span>
                </li>
                <li className="created-date">
                  <strong>Delivery Date:</strong>
                  <span>Apr 9, 2020 06:38 AM </span>
                </li>
                <li className="order-type">
                  <strong>Type:</strong>
                  <span>order</span>
                </li>
                <li className="order-type">
                  <strong>Payment Status:</strong>
                  <span>Approved</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className="customer-detail-holder">
              <h3>Customer Detail</h3>
              <ul className="customer-detail">
                <li>
                  <strong>Name :</strong>
                  <span>{order_detail.user_firstname} {order_detail.user_lastname}</span>
                </li>
                <li>
                  <strong>Phone Number :</strong>
                  <span>{order_detail.user_phone}</span>
                </li>
                <li>
                  <strong>Email :</strong>
                  <span>{order_detail.user_email}</span>
                </li>
                <li>
                  <strong>Address :</strong>
                  <span>{order_detail.user_country}</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="order-status-holder">
            {order_detail.status === "N" ? (
              <div className="order-status-process order-status">
                <p style={{background: OrderColor.N}}>{OrderStatus.N}</p>
              </div>
            ):order_detail.status === "P" ? (
              <div className="order-status-process order-status">
                <p style={{background: OrderColor.P}}>{OrderStatus.P}</p>
              </div>
            ):order_detail.status === "R" ? (
              <div className="order-status-process order-status">
                <p style={{background: OrderColor.R}}>{OrderStatus.R}</p>
              </div>
            ):order_detail.status === "D" ? (
              <div className="order-status-process order-status">
                <p style={{background: OrderColor.D}}>{OrderStatus.D}</p>
              </div>
            ):order_detail.status === "C" ? (
              <div className="order-status-process order-status">
                <p style={{background: OrderColor.C}}>{OrderStatus.C}</p>
              </div>
            ):order_detail.status === "A" ? (
              <div className="order-status-process order-status">
                <p style={{background: OrderColor.A}}>{OrderStatus.A}</p>
              </div>
            ):null}

            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h2 className="heading">Food Menu</h2>
            <div className="responsive-table">
              <ul className="categories-order table-generic">
                <li className="order-heading-titles">
                  <div>Products</div>
                  <div>Price per</div>
                </li>
                {order_detail.items.map((item,index) =>(
                  <li className="order-heading-titles" key={`orderdetail${index}`}>
                    <div>
                      <h4>{item.name}</h4>

                    </div>
                    <div><span className="category-price">Rs {item.price}</span></div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                <h3>Order Total</h3>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <ul className="order-detail-options order-total">
                  <li className="created-date">
                    <strong>Subtotal:</strong>
                    <span>Rs {order_detail.total}</span>
                  </li>
                  <li className="order-type">
                    <strong>
                      {order_detail.type === "D" ? "delivery_fee:" : "Pick Up Fee:"} </strong>
                    <span>{`Rs ${order_detail.type === "D" ? order_detail.delivery_fee : order_detail.pickup_fee}`}</span>
                  </li>
                  <li className="order-type">
                    <strong>{`VAT (${order_detail.vat_rate})`}</strong>
                    <span>Rs {order_detail.vat}</span>
                  </li>
                  <li className="order-type total-price">
                    <strong>Total:</strong>
                    <span>Rs {order_detail.grand_total}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
          )}
        </Modal>
    </div>

    </>
    );
  }
}

const mapStateToProps = state => ({
    restaurants:state.restaurant,
    food_item:state.restaurant.food_item,
    restaurant_listing:state.restaurant.restaurant_listing,
    order_list: state.buyer.order_list,
})

export default connect(mapStateToProps)(BuyerDashboardNew);
