import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {uploadInformationUpdate,uploadInformation,agroImagesListing,deleteAgroImage} from './../../store/actions/agroActions';
import {BaseUrl,bucketPath } from "./../../store/helpers/common";
import SimpleReactValidator from "simple-react-validator";
import "react-phone-number-input/style.css";
import swal from 'sweetalert';
import {ShowLoader,ShowAlert} from "./../../store/actions/staticActions";
const axios = require("axios");


const initialState = {image:[],video:[],imagenew:'',videonew:'',imageFile:[]}




class UploadInformation extends Component {
    constructor(props) {
        super(props);
        this.formValidator = new SimpleReactValidator();
        this.state = initialState;
      }

    componentDidMount(){
      let agrodata = localStorage.getItem("data");
      let agroID = JSON.parse(agrodata)?.agroID
        this.props.dispatch(agroImagesListing({agroID:agroID}));
        //this.props.dispatch(uploadInformation())
    }

    // onChangeHandler = e => {
    //     if(e.target.name === "image"){
    //       let file = window.$('#file_icon')[0].files;
    //       let recipe_images = [];
    // 			for (let i = 0; i < file.length; i++) {
    // 				let imageFile = URL.createObjectURL(file[i])
    // 				recipe_images.push(imageFile);
    // 			}
    //       this.setState({
    // 				image: this.state.image.concat(recipe_images),
    //         imagenew : recipe_images,
    //         imageFile : this.state.imageFile.concat(file[0]),
    // 			})
    //     }
    //   else if(e.target.name === "video"){
    //   let file = window.$('#file_icon1')[0].files;
  	// 		let recipe_videos = [];
  	// 		for (let i = 0; i < file.length; i++) {
  	// 			let videoFile = URL.createObjectURL(file[i]);
  	// 			recipe_videos.push(videoFile);
  	// 		}
  	// 		this.setState({
  	// 			video: recipe_videos,
    //       videonew:recipe_videos
  	// 		})
    //   }
    // }
    // imageDeleteHandler = (value,value2) =>{
    //   let arrayimage = [...this.state.image];
    //   let index = arrayimage.indexOf(value)
    //   if (index !== -1) {
    //     arrayimage.splice(index, 1);
    //     this.setState({image: arrayimage});
    //   }
    //   let difference = this.state.image.length - this.state.imageFile.length;
    //   let indeximageFile = value2 - difference;
    //   let arrayimageFile = [...this.state.imageFile]
    //   if(indeximageFile !== -1){
    //     arrayimageFile.splice(indeximageFile, 1);
    //     this.setState({imageFile: arrayimageFile},() =>{
    //       if(this.state.imageFile.length === 0){
    //         this.setState({imagenew:''})
    //       }
    //     });
    //   }
    //
    //
    // }

    // videoDeleteHandler = (value) =>{
    //   let arrayvideo = [...this.state.video];
    //   let index = arrayvideo.indexOf(value)
    //   if (index !== -1) {
    //     arrayvideo.splice(index, 1);
    //     this.setState({video: arrayvideo},() =>{
    //       if(this.state.video.length === 0){
    //         this.setState({videonew:''})
    //       }
    //     });
    //   }

    //}



    // openModal = () => {
    //     this.setState({ openMap: true });
    //   };
    //
    //   closeModal = () => {
    //     this.setState({ openMap: false });
    //   };


    // componentDidUpdate = (prevProps, prevState) => {
    //     let nextProps = this.props;
    //     if(nextProps.agro_image_list !== prevProps.agro_image_list){
    //
    //       this.setState({
    //         image : nextProps.upload_information.data.image ? nextProps.upload_information.data.image.split(",") : [],
    //         video: nextProps.upload_information.data.video ? nextProps.upload_information.data.video.split(",") : [],
    //       })
    //
    //     }
    //   }


    onSubmitHandler = () => {
        const validator = this.formValidator;
        if(validator.allValid()){
           this.props.dispatch(uploadInformationUpdate(this.state,this));
        }else{
            validator.showMessages();
            this.forceUpdate();
        }
    }

    uploadImage = (e) => {
        let agrodata = localStorage.getItem("data");
        let agroID = JSON.parse(agrodata)?.agroID
        this.props.dispatch(ShowLoader(true));
        let request;
        let formData = new FormData();
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
        formData.append(`image[${i}]`, files[i]);
        }
        formData.append('agroID',agroID);
        formData.append('upload_type',"image");
        const token = localStorage.getItem('token');
        request = {
          method: "post",
          url: `${BaseUrl}/api/upload_agro_images`,
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Auth-Token": `${token}`,
          },
          data: formData,
        };
        axios(request)
          .then((res) => {
            if (res.data.ResponseCode === "1") {
              //let response = res.data.imagename;
                this.props.dispatch(agroImagesListing({agroID:agroID,list_type: "image"}));
                this.props.dispatch(ShowLoader(false));
                this.props.dispatch(ShowAlert({ Class: "alert-success", Message: "Image Uploaded successfully!", Timeout: 5000 }));
            }
          })
          .catch((error) => {
            this.props.dispatch(ShowLoader(false));
            console.log(error);
          });
      };


      DeleteAgroImageHandler = (e,value) => {
        e.preventDefault();
        let agrodata = localStorage.getItem("data");
        let agroID = JSON.parse(agrodata)?.agroID
        let data = {image:value,delete_type:"image"}
        swal({
            title: "Are you sure you want to delete this Product Image?",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.dispatch(deleteAgroImage(data));
            } else {
                console.log("you clicked on false");
            }
        });
    };

    uploadVideo = (e) => {
        let agrodata = localStorage.getItem("data");
        let agroID = JSON.parse(agrodata)?.agroID
        this.props.dispatch(ShowLoader(true));
        let request;
        let formData = new FormData();
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
        formData.append(`video[${i}]`, files[i]);
        }
        formData.append('agroID',agroID);
        formData.append('upload_type',"video");
        const token = localStorage.getItem('token');
        request = {
          method: "post",
          url: `${BaseUrl}/api/upload_agro_images`,
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Auth-Token": `${token}`,
          },
          data: formData,
        };
        axios(request)
          .then((res) => {
            if (res.data.ResponseCode === "1") {
              //let response = res.data.imagename;
                this.props.dispatch(agroImagesListing({agroID:agroID,list_type: "video"}));
                this.props.dispatch(ShowLoader(false));
                this.props.dispatch(ShowAlert({ Class: "alert-success", Message: "Video Uploaded successfully!", Timeout: 5000 }));
            }
          })
          .catch((error) => {
            this.props.dispatch(ShowLoader(false));
            console.log(error);
          });
      };

      DeleteAgroVideoHandler = (e,value) => {
            e.preventDefault();
        let agrodata = localStorage.getItem("data");
        let agroID = JSON.parse(agrodata)?.agroID
        let data = {video:value,delete_type:"video"}
        swal({
            title: "Are you sure you want to delete this Product Image?",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.dispatch(deleteAgroImage(data));
            } else {
                console.log("you clicked on false");
            }
        });
    };

  render() {
    console.log("agro_image_list",this.props.agro_image_list)

    return (
      <>
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div className="user-dashboard loader-holder">
            <div className="user-holder">
                <div className="user-profile">
                    <div className="element-title has-border">
                    {
                      //<h5>Account Settings</h5>
                    }

                    </div>
                      <form id="publisher_profile" encType="multipart/form-data">
                      <div className="form-fields-set">
                        <ul>
                          <li>
                              <div className="row">
                                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                      <div className="field-holder">
                                          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                              <label>Images*</label>
                                          </div>
                                          {
                                            // <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            //   <select>
                                            //     <option>Mr.</option>
                                            //     <option>Mrs.</option>
                                            //   </select>
                                            // </div>
                                          }

                                          <div className="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                                          <div id="file_icon-sec" className="browse-btn-sec" style={{ display: 'block !important' }}>
                        										<input
                        											type="file"
                        											id="file_icon"
                                              ref={(input) => { this.fileinput = input; }}
                        											name="image"
                        											onChange={e => this.uploadImage(e)}
                        											className="browse-menu-icon-file"
                        											style={{ display: 'none' }}
                        											accept="image/*"
                        											multiple
                        										/>
                                            {
                                              <i
                          											className="fas fa-camera camera-ico"
                          											onClick={() => {
                          												this.fileinput.click();
                          											}}
                          										></i>
                                            }


                        									</div>
                                          </div>
                                          <div className="argoupload-img col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                          {
                                            this.props && this.props.agro_image_list && this.props.agro_image_list.length > 0  && this.props.agro_image_list[0].image &&  this.props.agro_image_list[0].image.map((value, index) => {

                                                let imgSrc = `${bucketPath}${value.image}`


                                              return (
                                                <div key={"img_" + index} className="add-recepie-img-video">
                                                  <Link to="">
                                                    <i className="fas fa-trash-alt del-profile-ico" onClick={(e) => this.DeleteAgroImageHandler(e,value.image)}></i>
                                                  </Link>
                                                  <img src={`${imgSrc}`} alt=""/>
                                                </div>
                                              )

                                            })
                                          }
                                          </div>
                                      </div>

                                  </div>

                                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="field-holder">
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                                <label>Video*</label>
                                            </div>
                                            <div className="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                                            <div id="file_icon-sec" className="browse-btn-sec" style={{ display: 'block !important' }}>
                                              <input
                                                type="file"
                                                id="file_icon1"
                                                ref={(input) => { this.videoFileinput = input; }}
                                                name="video"
                                                //value={this.state.videonew}
                                                onChange={e => this.uploadVideo(e)}
                                                className="browse-menu-icon-file"
                                                style={{ display: 'none' }}
                                                accept="video/*"
                                                multiple
                                              />
                                              <i
                                                className="fas fa-camera camera-ico"
                                                onClick={() => {
                                                  this.videoFileinput.click();
                                                }}
                                              ></i>
                                            </div>
                                            </div>
                                            <div className="argoupload-img col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            {
                                              this.props && this.props.agro_image_list && this.props.agro_image_list.length > 0 && this.props.agro_image_list[0].video && this.props.agro_image_list[0].video.map((value, index) => {
                                                  let vdoSrc = `${bucketPath}${value.video}`

                                                return (
                                                  <div key={"video_" + index} className="add-recepie-img-video">
                                                    <Link to="/">
                                                      <i className="fas fa-trash-alt del-profile-ico" onClick={(e) => this.DeleteAgroVideoHandler(e,value.video)}></i>
                                                    </Link>
                                                    <video width="100">
                                                      <source src={`${vdoSrc}`} />
                                                                                  Your browser does not support HTML video.
                                                                              </video>
                                                  </div>
                                                )

                                              })
                                            }
                                            </div>
                                        </div>
                                    </div>




                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    </div>

                              </div>
                          </li>
                        </ul>
                      </div>
                      {
                        // <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        //     <div className="field-holder text-center">
                        //         <button name="button" type="button" className="btn btn-black" onClick={this.onSubmitHandler.bind(this)}>SAVE & CONTINUE</button>
                        //     </div>
                        // </div>
                      }

                      </form>
                </div>
            </div>
        </div>
    </div>
    </>
    );
  }
}

const mapStateToProps = (state) => ({
    agro_image_list : state.agro.agro_image_list
});

export default connect(mapStateToProps)(UploadInformation);
