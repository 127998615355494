import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {foodcontactInformation,foodContactInformationUpdate} from './../../store/actions/foodActions';
import {bucketPath} from './../../store/helpers/common'
import SimpleReactValidator from "simple-react-validator";
import "react-phone-number-input/style.css";
const initialState = {restaurant_name:'',restaurant_phone:'',manager_name:'',manager_phone:"",contact_email:"",website:'',facebook:'',twitter:'',instagram:'',profile_image:[],cover_image:[],profileimageFile:[],coverimageFile:[]}


class FoodContactInformation extends Component {
    constructor(props) {
        super(props);
        this.formValidator = new SimpleReactValidator();
        this.state = initialState;
      }

    componentDidMount(){
        this.props.dispatch(foodcontactInformation())
    }

    onChangeHandler = e => {
        this.setState({[e.target.name]:e.target.value})
    }

    openModal = () => {
        this.setState({ openMap: true });
      };

      closeModal = () => {
        this.setState({ openMap: false });
      };


    componentDidUpdate = (prevProps, prevState) => {
        let nextProps = this.props;
        if(nextProps.food_contact_information !== prevProps.food_contact_information){

          this.setState({
            restaurant_name : nextProps?.food_contact_information?.data?.restaurant_name,
            restaurant_phone: nextProps?.food_contact_information?.data?.restaurant_phone,
            manager_name : nextProps?.food_contact_information?.data?.manager_name,
            manager_phone:nextProps?.food_contact_information?.data?.manager_phone,
            contact_email:nextProps?.food_contact_information?.data?.contact_email,
            website:nextProps?.food_contact_information?.data?.website,
            facebook:nextProps?.food_contact_information?.data?.facebook,
            twitter:nextProps?.food_contact_information?.data?.twitter,
            instagram :nextProps?.food_contact_information?.data?.instagram,
            profile_image : nextProps.food_contact_information.data.profile_image ? nextProps.food_contact_information.data.profile_image.split(",") : [],
            cover_image: nextProps.food_contact_information.data.cover_image ? nextProps.food_contact_information.data.cover_image.split(",") : [],
          })

        }
      }


    onSubmitHandler = async() => {
        const validator = this.formValidator;
        if(validator.allValid()){
          const response = await this.props.dispatch(foodContactInformationUpdate(this.state));
          if(response===1){
              this.setState(initialState)
              this.props.dispatch(foodcontactInformation())
          }
        }else{
            validator.showMessages();
            this.forceUpdate();
        }
    }

    imageUploadHandler = e => {
        if(e.target.name === "profile_image"){
          let file = window.$('#file_icon')[0].files;
          let profile_images_data = [];
    			for (let i = 0; i < file.length; i++) {
    				let imageFile = URL.createObjectURL(file[i])
    				profile_images_data.push(imageFile);
    			}
          this.setState({
    				profile_image: profile_images_data,
            profileimageFile : file[0],
    			})
        }
      else if(e.target.name === "cover_image"){
      let file = window.$('#cover_image')[0].files;
  			let cover_image_data = [];
  			for (let i = 0; i < file.length; i++) {
  				let coverimageFile = URL.createObjectURL(file[i]);
  				cover_image_data.push(coverimageFile);
  			}
  			this.setState({
  				cover_image: cover_image_data,
          coverimageFile : file[0],
  			})
      }
    }
    profileimageDeleteHandler = () =>{
      if(this.state.profile_image.length > 0){
        this.setState({
          profile_image:[],
          profileimageFile:[]
        })
      }

    }

    coverimageDeleteHandler = () =>{
      if(this.state.cover_image.length > 0){
        this.setState({
          cover_image:[],
          coverimageFile:[]
        })
      }

    }

  render() {

    return (
      <>
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div className="user-dashboard loader-holder">
            <div className="user-holder">
                <div className="user-profile">
                    <div className="element-title has-border">
                        {
                        //<h5>Account Settings</h5>
                        }
                    </div>
                    <form id="publisher_profile">
                        <div className="form-fields-set">
                            <ul>
                                <li>
                                    <div className="row">
                                        {/* image upload */}
                                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <div className='restaurant-info'>
                          <div className='img-holder'>
                            <ul className='foodbakery-gallery-holder'>
                              <li className='gal-img'>
                                <div className='drag-list'>
                                  <div
                                    className='item-thumb'
                                    data-def-img='assets/extra-images/listing-logo18.png'
                                    data-img-type='default'
                                  >
                                    <figure>
                                      <Link to='#'>
                                      {this.state.profile_image.map((item,index) =>{
                                        let imgSrc = ""

                                        if (item.includes("http://") === true || item.includes("https://") === true) {
                                          imgSrc = item;
                                        }
                                        else{
                                          imgSrc = `${bucketPath}${item}`
                                        }
                                        return(
                                          <img
                                            src={`${imgSrc}`}
                                            alt='Logo'
                                            key={index}
                                          />
                                        )
                                      })}

                                      </Link>
                                    </figure>
                                  </div>
                                  <div className='item-assts'>
                                    <ul className='list-inline pull-right'>
                                      <li className='close-btn'>
                                        <Link to="#">
                                          <i className='icon-cross-out' onClick={() =>this.profileimageDeleteHandler()}></i>
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className='text-holder'>
                            <strong>{this.state.restaurant_name}</strong>
                            <div className='upload-gallery'>
                              <input
                                className='foodbakery-dev-gallery-uploader'
                                style={{ display: 'none' }}
                                type="file"
                                id="file_icon"
                                ref={(input) => { this.fileinput = input; }}
                                name="profile_image"
                                onChange={this.imageUploadHandler}
                                accept="image/*"
                              />
                              <Link
                                to='#'
                                className='upload-btn foodbakery-dev-featured-upload-btn'
                                onClick={(e) => this.fileinput.click()}
                              >
                                Upload profile image
                              </Link>

                            </div>
                            <span>
                              Update your profile image manually. Max Upload Size: 1MB,
                            </span>
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <div className='restaurant-info'>
                          <div className='img-holder'>
                            <ul className='foodbakery-gallery-holder'>
                              <li className='gal-img'>
                                <div className='drag-list'>
                                  <div
                                    className='item-thumb'
                                    data-def-img='assets/extra-images/cover-photo01.jpg'
                                    data-img-type='default'
                                  >
                                    <figure>
                                      <Link to='#'>
                                      {this.state.cover_image.map((item,index) =>{
                                        let imgSrc = ""

                                        if (item.includes("http://") === true || item.includes("https://") === true) {
                                          imgSrc = item;
                                        }
                                        else{
                                          imgSrc = `${bucketPath}${item}`
                                        }
                                        return(
                                          <img
                                            src={`${imgSrc}`}
                                            alt='cover'
                                            key={index}
                                          />
                                        )
                                      })}
                                      </Link>
                                    </figure>
                                  </div>
                                  <div className='item-assts'>
                                    <ul className='list-inline pull-right'>
                                      <li className='close-btn'>
                                        <Link to="#">
                                          <i className='icon-cross-out' onClick={() => this.coverimageDeleteHandler()}></i>
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className='text-holder'>
                            <div className='upload-gallery'>
                              <input
                                className='foodbakery-dev-gallery-uploader'
                                id='cover_image'
                                style={{ display: 'none' }}
                                type="file"
                                ref={(input) => { this.uploadcoverRef = input; }}
                                name="cover_image"
                                onChange={this.imageUploadHandler}
                                accept="image/*"
                              />
                              <Link
                                to='#'
                                className='upload-btn foodbakery-dev-featured-upload-btn'
                                onClick={(e) => this.uploadcoverRef.click()}
                              >
                                Upload Cover Image
                              </Link>
                            </div>
                            <span>
                              Update your cover image manually. Max Upload Size: 1MB,
                            </span>
                          </div>
                        </div>
                      </div>
                                        {/* image upload end */}
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className="field-holder">
                                        <label>Restaurant Name*</label>
                                        <input type="text" className="foodbakery-dev-req-field" name="restaurant_name" value={this.state.restaurant_name} onChange={this.onChangeHandler.bind(this)} />
                                        {this.formValidator.message("Restaurant Name", this.state.restaurant_name, "required|max:50,string")}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className="field-holder">
                                        <label>Restaurant Phone*</label>
                                        <input type="text" className="foodbakery-dev-req-field" name="restaurant_phone" value={this.state.restaurant_phone} onChange={this.onChangeHandler.bind(this)} />
                                        {this.formValidator.message("Restaurant Phone", this.state.restaurant_phone, "required|max:50,string")}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className="field-holder">
                                        <label>Manager Name*</label>
                                        <input type="text" className="foodbakery-dev-req-field" name="manager_name" value={this.state.manager_name} onChange={this.onChangeHandler.bind(this)} />
                                        {this.formValidator.message("Manager Name", this.state.manager_name, "required|max:50,string")}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className="field-holder">
                                    <label>Manager Contact Phone*</label>
                                    <input type="text" className="foodbakery-dev-req-field" name="manager_phone" value={this.state.manager_phone} onChange={this.onChangeHandler.bind(this)} />
                                    {this.formValidator.message("Manager Contact Phone", this.state.manager_phone, "required|max:50,string")}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className="field-holder">
                                    <label>Contact Email*</label>
                                    <input type="email" className="foodbakery-dev-req-field" name="contact_email" value={this.state.contact_email} onChange={this.onChangeHandler.bind(this)} />
                                    {this.formValidator.message("Email Id", this.state.contact_email, "required|max:50,string")}
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className="field-holder">
                                        <label>Website*</label>
                                        <input type="text" className="foodbakery-dev-req-field" name="website" value={this.state.website} onChange={this.onChangeHandler.bind(this)} />
                                        {this.formValidator.message("Website", this.state.website, "required|max:50,string")}
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className="field-holder">
                                        <label>Facebook*</label>
                                        <input type="text" className="foodbakery-dev-req-field" name="facebook" value={this.state.facebook} onChange={this.onChangeHandler.bind(this)} />
                                        {this.formValidator.message("Facebook", this.state.facebook, "required|max:50,string")}
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className="field-holder">
                                        <label>Twitter*</label>
                                        <input type="text" className="foodbakery-dev-req-field" name="twitter" value={this.state.twitter} onChange={this.onChangeHandler.bind(this)} />
                                        {this.formValidator.message("Twitter", this.state.twitter, "required|max:50,string")}
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className="field-holder">
                                        <label>Instagram*</label>
                                        <input type="text" className="foodbakery-dev-req-field" name="instagram" value={this.state.instagram} onChange={this.onChangeHandler.bind(this)} />
                                        {this.formValidator.message("Instagram", this.state.instagram, "required|max:50,string")}
                                    </div>
                                </div>


                            </div>


                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="field-holder text-center">
                                        <button name="button" type="button" className="btn btn-black" onClick={this.onSubmitHandler.bind(this)}>SAVE & CONTINUE</button>
                            </div>
                        </div>
                        </li>
                        </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>
    );
  }
}

const mapStateToProps = (state) => ({
    food_contact_information : state.food.food_contact_information
});

export default connect(mapStateToProps)(FoodContactInformation);
