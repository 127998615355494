import React, { Component } from "react";

class MapModal extends Component {

  UNSAFE_componentWillReceiveProps(props) {
    if (props.openMap) {
      this.openModal();
    }
  }

  openModal = () => {
    window.$("#MapModal").modal("show");
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div
        className="modal fade loginModal-div"
        id="MapModal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="MapModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className={`modal-content`}>
            <div className="modal-header">
              <h5
                className="modal-title"
                id="exampleModalLabel"
                style={{ color: "#fc0000" }}
              >
                Map
              </h5>
              <button
                type="button"
                className="close"
                onMouseUp={this.closeModal}
                id="closeMap"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ">{this.props.children}</div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default MapModal;
