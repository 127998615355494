import * as types from "./../types/types";

const initalState = {
    food_location_information: [],
    food_location_information_error:'',
    food_contact_information: [],
    food_contact_information_error:'',
    food_other_information: [],
    food_other_information_error:'',
    agro_listing:[],
    agro_listing_error:'',
    force_food_login_click:false,
    book_food_center_table_listing:[],
    book_food_center_table_listing_error:'',
    food_profile:[],
    food_profile_error:'',
    food_review_rating:[],
    food_center_like_and_dislike: [],
    food_center_like_and_dislike_error:'',
    food_like_and_dislike_list:[],
    particular_food_booking_list:[],
    food_upload_information:[],
    food_upload_information_error:'',

}


const FoodReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FOOD_LOCATION_INFORMATION_SUCCESS:
            return {
                ...state,
                food_location_information: action.payload
            }
        case types.FOOD_LOCATION_INFORMATION_FALIURE:
            return {
                ...state,
                food_location_information_error: action.payload
            }
            case types.FOOD_CONTACT_INFORMATION_SUCCESS:
                return {
                    ...state,
                    food_contact_information: action.payload
                }
            case types.FOOD_CONTACT_INFORMATION_FALIURE:
                return {
                    ...state,
                    food_contact_information_error: action.payload
                }
                case types.FOOD_OTHER_INFORMATION_SUCCESS:
                    return {
                        ...state,
                        food_other_information: action.payload
                    }
                case types.FOOD_OTHER_INFORMATION_FALIURE:
                    return {
                        ...state,
                        food_other_information_error: action.payload
                    }
                case types.FOOD_LISTING_SUCCESS:
                      return {
                            ...state,
                            food_listing: action.payload
                        }
                case types.FOOD_LISTING_FALIURE:
                        return {
                            ...state,
                            food_listing_error: action.payload
                        }
                case types.FORCE_FOOD_LOGIN_CLICK:
                      return {
                          ...state,
                          force_food_login_click: action.payload
                      }
                case types.BOOK_FOOD_CENTER_TABLE_LISTING_SUCCESS:
                        return {
                              ...state,
                              book_food_center_table_listing: action.payload
                          }
                case types.BOOK_FOOD_CENTER_TABLE_LISTING_FALIURE:
                        return {
                          ...state,
                            book_food_center_table_listing_error: action.payload
                      }
                case types.FOOD_PROFILE_SUCCESS:
                      return {
                          ...state,
                          food_profile: action.payload
                      }
                case types.FOOD_PROFILE_FALIURE:
                      return {
                          ...state,
                          food_profile_error: action.payload
                      }
                case types.FOOD_REVIEW_LISTING:
                      return {
                            ...state,
                            food_review_rating: action.payload
                      }
                case types.FOOD_CENTER_LIKE_AND_DISLIKE_SUCCESS:
                            return {
                                ...state,
                                food_center_like_and_dislike: action.payload
                            }
                case types.FOOD_CENTER_LIKE_AND_DISLIKE_FALIURE:
                            return {
                                  ...state,
                                  food_center_like_and_dislike_error: action.payload
                          }
                case types.FOOD_LIKE_AND_DISLIKE_LISTING:
                        return {
                          ...state,
                          food_like_and_dislike_list: action.payload
                        }
                case types.PARTICULAR_FOOD_BOOKINGS_LIST:
                          return {
                              ...state,
                            particular_food_booking_list: action.payload
                        }
                case types.FOOD_UPLOAD_INFORMATION_SUCCESS:
                        return {
                          ...state,
                          food_upload_information: action.payload
                      }
                case types.FOOD_UPLOAD_INFORMATION_FALIURE:
                        return {
                            ...state,
                            food_upload_information_error: action.payload
                      }

        default:
            return state
    }
}

export default FoodReducer;
