import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Pagination from "./../../store/helpers/Pagination";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RestaurantRecepieListing, DeleteRecipe, PublishRecipe } from './../../store/actions/restaurantActions'

import swal from 'sweetalert';
class RestaurantRecepies extends Component {
	constructor(props) {
		super(props);
		this.state = { pageOfItems: [], currentPage: 1 };
	}

	componentDidMount() {
		let data = JSON.parse(localStorage.getItem("data"));
		let restaurantID = data["restaurantID"];
		this.props.dispatch(RestaurantRecepieListing({ restaurantID }));
	}

	onChangePage = (pageOfItems, page) => {
		this.setState({ pageOfItems: pageOfItems, currentPage: page });
	};

	DeleteRow = (recipeID) => {
		swal({
			title: "Are you sure want to delete this record?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					this.props.dispatch(DeleteRecipe({ recipeID }));
				} else {
					swal({ title: "Record is safe!", icon: "success", });
				}
			});
	}

	publish = (recipeID, status) => {
		swal({
			title: status !== "P" ? "Are you sure want to remove the published recipes?" : "Are you sure want to publish this recipe?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					this.props.dispatch(PublishRecipe({ recipeID, status }));
				} else {
					swal({ title: "Record is safe!", icon: "success", });
				}
			});
	}

	render() {
		const { recepie_listing } = this.props.restaurant_data
		const { pageOfItems } = this.state;
		return (
			<div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
				<div className="user-dashboard">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="element-title has-border right-filters-row">
								<h5>My Recipes</h5>
								<Link to="/add-recepie" className="add-menu-item collapsed margin-bottom-10">
									Add Recipes
								</Link>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="user-orders-list">
								<div className="table-responsive">
									{/* <ul className="table-generic table-generic-temp"> */}
									<DragDropContext onDragEnd={e => this.onDragEnd(e)}>
										<Droppable droppableId="droppable">
											{(provided, snapshot) => (
												<ul className="table-generic table-generic-temp" {...provided.droppableProps} ref={provided.innerRef} >
													<li className="order-heading-titles">
														<div>Created On</div>
														<div>Updated On</div>
														<div>Title</div>
														<div>Action</div>
													</li>
													{pageOfItems instanceof Array && pageOfItems.map((value, index) => (
														<Draggable key={`key_${index}`} draggableId={value.recipeID.toString()} index={value.recipeID}>
															{(provided, snapshot) => (
																<li className="order-heading-titles" draggable={true} key={`category_${value.recipeID}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
																	<div>{value.creationdate}</div>
																	<div>{value.lastupdate}</div>
																	<div>{value.title}</div>
																	<div>
																		<Link className="action-ico view-ico"  title="View" to={"/recipe-info/" + value.recipeID}>
																			<i className="fa fa-eye"></i>
																		</Link>&nbsp;&nbsp;

																		<Link className="action-ico edit-ico"  title="Edit" to={"/edit-recepie/" + value.recipeID}>
																			<i className="fa fa-edit"></i>
																		</Link>&nbsp;&nbsp;

																		<Link className="action-ico del-ico"  title="Delete" to="#" onClick={e => this.DeleteRow(value.recipeID)}>
																			<i className="fa fa-trash"></i>
																		</Link>&nbsp;&nbsp;
																		{
																			value.status && value.status !== "P"
																				?
																				<Link className="action-ico publish-ico"  title="Publish" to="#" onClick={e => this.publish(value.recipeID, "P")}>
																					<i className="icon-share"></i>
																				</Link>
																				:
																				<Link className="action-ico discard-ico"  title="Remove Publish" to="#" onClick={e => this.publish(value.recipeID, "A")}>
																					<i className="icon-block2"></i>
																				</Link>
																		}

																	</div>
																</li>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</ul>
											)}
										</Droppable>
									</DragDropContext>
								</div>
							</div>
						</div>
						{recepie_listing instanceof Array && (
							<Pagination
								pageSize={10}
								items={recepie_listing}
								onChangePage={this.onChangePage}
							/>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	restaurant_data: state.restaurant
});

export default connect(mapStateToProps)(RestaurantRecepies);
