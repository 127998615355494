import React, { Component } from "react";
import { connect } from "react-redux";
import {locationInformation,locationInformationUpdate} from './../../store/actions/agroActions';
import SimpleReactValidator from "simple-react-validator";
const initialState = {business_name:'',address:'',city:'',pincode:'',state:'',country :''}


class LocationInformation extends Component {
    constructor(props) {
        super(props);
        this.formValidator = new SimpleReactValidator();
        this.state = initialState;
      }

    componentDidMount(){
        this.props.dispatch(locationInformation())
    }

    onChangeHandler = e => {
        this.setState({[e.target.name]:e.target.value})
    }

    openModal = () => {
        this.setState({ openMap: true });
      };

      closeModal = () => {
        this.setState({ openMap: false });
      };


    componentDidUpdate = (prevProps, prevState) => {
        let nextProps = this.props;
        if(nextProps.location_information !== prevProps.location_information){

          this.setState({
            business_name : nextProps?.location_information?.data?.business_name,
            address:nextProps?.location_information?.data?.address,
            city:nextProps?.location_information?.data?.city,
            pincode:nextProps?.location_information?.data?.pincode,
            state:nextProps?.location_information?.data?.state,
            country :nextProps?.location_information?.data?.country
          })

        }
      }



    onSubmitHandler = async() => {
        const validator = this.formValidator;
        if(validator.allValid()){
          const response = await this.props.dispatch(locationInformationUpdate(this.state));
          if(response===1){
              this.setState(initialState)
              this.props.dispatch(locationInformation())
          }
        }else{
            validator.showMessages();
            this.forceUpdate();
        }
    }




  render() {

    return (
      <>
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div className="user-dashboard loader-holder">
            <div className="user-holder">
                <div className="user-profile">
                    <div className="element-title has-border">
                    {
                      //<h5>Account Settings</h5>
                    }

                </div>
                      <form id="publisher_profile">
                            <div className="form-fields-set">
                            <ul>
                                <li>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="field-holder">
                                                <label>Business Name*</label>
                                                <input type="text" className="foodbakery-dev-req-field" name="business_name" value={this.state.business_name} onChange={this.onChangeHandler.bind(this)} />
                                                {this.formValidator.message("Bussiness name", this.state.business_name, "required|max:50,string")}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="field-holder">
                                                <label>Address*</label>
                                                <input type="text" className="foodbakery-dev-req-field" name="address" value={this.state.address} onChange={this.onChangeHandler.bind(this)} />
                                                {this.formValidator.message("Address", this.state.address, "required|max:200,string")}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="field-holder">
                                                <label>City*</label>
                                                <input type="text" className="foodbakery-dev-req-field" name="city" value={this.state.city} onChange={this.onChangeHandler.bind(this)} />
                                                {this.formValidator.message("City", this.state.city, "required|max:50,string")}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="field-holder">
                                                <label>Pincode*</label>
                                                <input type="number" className="foodbakery-dev-req-field" name="pincode" value={this.state.pincode} onChange={this.onChangeHandler.bind(this)} />
                                                {this.formValidator.message("Pincode", this.state.pincode, "required|max:50,string")}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="field-holder">
                                                <label>State*</label>
                                                <input type="text" className="foodbakery-dev-req-field" name="state" value={this.state.state} onChange={this.onChangeHandler.bind(this)} />
                                                {this.formValidator.message("State", this.state.state, "required|max:50,string")}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="field-holder">
                                                <label>Country*</label>
                                                <input type="text" className="foodbakery-dev-req-field" name="country" value={this.state.country} onChange={this.onChangeHandler.bind(this)} />
                                                {this.formValidator.message("Country", this.state.country, "required|max:50,string")}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div className="field-holder text-center">
                                        <button name="button" type="button" className="btn btn-black" onClick={this.onSubmitHandler.bind(this)}>SAVE & CONTINUE</button>
                              </div>
                          </div>
                      </form>
                </div>
            </div>
        </div>
    </div>
    </>
    );
  }
}

const mapStateToProps = (state) => ({
    location_information : state.agro.location_information
});

export default connect(mapStateToProps)(LocationInformation);
