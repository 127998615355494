import React from "react";
import {useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import {ForceFoodloginClick} from './../store/actions/foodActions';
import {ForceAgrologinClick} from './../store/actions/agroActions';
import {ForceMamaloginClick} from './../store/actions/restaurantActions';
import Agrocart from './Agro/Agrocart'

function CenterSidebar(props){
  const dispatch = useDispatch()  // for accessing the redux function
  return(
    <>
    <aside className={`page-sidebar right col-lg-3 col-md-3 col-sm-12 col-xs-12 center-sidebar ${props.center === "recipe" || props.center === "foodClass" || props.center === "food" || props.center === "agro" || props.center === "condiment" || props.center === "mama" ? "mgTop-0" : ""}`}>
                <div className="widget-holder">
                  <div className='widget widget_search'>
                    <div className="section-sidebar">
                    {props.rightCart && props.rightCart}
                    {props.center === "agro-info" && (<Agrocart />)}
                    <div className="message-box message-box-top widget widget-related-post popular-recipie-div" style={{ backgroundColor: "#fa9918" }}>
                      <strong>I‘ m not Listed!</strong>
                      <span>is your restaurant or business not listed on over site</span>
                      {props.center === "food" ? (
                        <Link to="#" className="request-btn" onClick={(e) =>dispatch(ForceFoodloginClick(true))}>Add Your Food Center</Link>
                      ):props.center === "agro" ? (
                        <Link to="#" className="request-btn" onClick={(e) =>dispatch(ForceAgrologinClick(true))}>Add Your Agro Center</Link>
                      ):props.center === "mama" || props.center === "recipe" || props.center === "foodClass" || props.center === "condiment" ? (
                        <Link to="#" className="request-btn" onClick={(e) =>dispatch(ForceMamaloginClick(true))}>Add Your Mama</Link>
                      ):null}

                    </div>
                    </div>

                    <div className='widget widget-related-post popular-recipie-div'>
                      {/* <div className='widget widget-recent-blog-post'> */}
                      <div className='widget-recent-blog-post'>
                        <div className='widget-title'>
                          <h5>
                          {`Popular ${props.center === "food" ? "Food Center" :
                          props.center === "agro" || props.center === "agro-info" ? "Agro Center" :
                          props.center === "recipe" ? "Recepies" :
                          props.center === "foodClass" ? "Food Classes" :
                          props.center === "condiment" ? "Condiment" :
                          props.center === "mama" ? "Mama" : ""} `}
                          </h5>
                        </div>
                        <ul>
                              <li>
                                <div className='img-holder'>
                                  <figure>
                                    <Link to='#'>
                                      <img
                                        src="/assets/extra-images/cover-photo01.jpg"
                                        alt=''
                                      />
                                    </Link>
                                  </figure>
                                </div>
                                <div className='text-holder'>
                                  <div className='post-title'>
                                    <h6>
                                      <Link to='#'>Neeraj saini</Link>
                                    </h6>
                                      <span style={{fontSize:'12px'}}>sdfs,Ambala,Haryana,India...</span>
                                    <div className='list-rating'>
                                      <div className='rating-star'>
                                        <span className='rating-box' style={{ width: "100%" }}></span>
                                      </div>
                                      <span className='reviews'>(1)</span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className='img-holder'>
                                  <figure>
                                    <Link to='#'>
                                      <img
                                        src="/assets/extra-images/cover-photo01.jpg"
                                        alt=''
                                      />
                                    </Link>
                                  </figure>
                                </div>
                                <div className='text-holder'>
                                  <div className='post-title'>
                                    <h6>
                                      <Link to='#'>Neeraj saini</Link>
                                    </h6>
                                      <span style={{fontSize:'12px'}}>sdfs,Ambala,Haryana,India...</span>
                                    <div className='list-rating'>
                                      <div className='rating-star'>
                                        <span className='rating-box' style={{ width: "100%" }}></span>
                                      </div>
                                      <span className='reviews'>(1)</span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                        </ul>
                      </div>
                    </div>

                    <div className='widget widget-related-post popular-recipie-div'>
                      {/* <div className='widget widget-recent-blog-post'> */}
                      <div className='widget-recent-blog-post'>
                        <div className='widget-title'>
                          <h5>
                          {`Popular Mama`}
                          </h5>
                        </div>
                        <ul>
                              <li>
                                <div className='img-holder'>
                                  <figure>
                                    <Link to='#'>
                                      <img
                                        src="/assets/extra-images/cover-photo01.jpg"
                                        alt=''
                                      />
                                    </Link>
                                  </figure>
                                </div>
                                <div className='text-holder'>
                                  <div className='post-title'>
                                    <h6>
                                      <Link to='#'>Neeraj saini</Link>
                                    </h6>
                                      <span style={{fontSize:'12px'}}>sdfs,Ambala,Haryana,India...</span>
                                    <div className='list-rating'>
                                      <div className='rating-star'>
                                        <span className='rating-box' style={{ width: "100%" }}></span>
                                      </div>
                                      <span className='reviews'>(1)</span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className='img-holder'>
                                  <figure>
                                    <Link to='#'>
                                      <img
                                        src="/assets/extra-images/cover-photo01.jpg"
                                        alt=''
                                      />
                                    </Link>
                                  </figure>
                                </div>
                                <div className='text-holder'>
                                  <div className='post-title'>
                                    <h6>
                                      <Link to='#'>Neeraj saini</Link>
                                    </h6>
                                      <span style={{fontSize:'12px'}}>sdfs,Ambala,Haryana,India...</span>
                                    <div className='list-rating'>
                                      <div className='rating-star'>
                                        <span className='rating-box' style={{ width: "100%" }}></span>
                                      </div>
                                      <span className='reviews'>(1)</span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                        </ul>
                      </div>
                    </div>

                  </div>

                </div>
              </aside>
              </>
  )
}

export default CenterSidebar;
