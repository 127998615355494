import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AdminLoginFxn } from './../../store/actions/userActions'
import { connect } from 'react-redux'
import SimpleReactvalidator from 'simple-react-validator'
import swal from 'sweetalert';

class AdminLogin extends Component {
    validator = new SimpleReactvalidator();
    state = {
        email: '',
        password: ''
    }

    onChangeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
    }
    

    Validate = async () => {
        const { email, password } = this.state
        if (this.validator.allValid()) {
            let response = await this.props.dispatch(AdminLoginFxn({ email, password }));
            if (response.code === 1) { 
                localStorage.setItem('admin-data', JSON.stringify(response.data));
                localStorage.setItem('admin-token', response.token);
                localStorage.setItem('admin-type', response.type);
                this.props.history.push('/admin-dashboard');
            } else {
                swal({ title: "Error!", text: response.text, icon: "error" });
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    onEnterPress = e => {
        if (e.keyCode === 13) {
            this.refOnclick.click()
        }
    }

    render() {
        const { email, password } = this.state;
    
        return (
            <div class="login-main">
        <div class="login-inner">
        <div class="container">
            <div class="col-md-6 col-md-offset-3">
            <form class="login-form">
                <div class="form-group login-logo">
                    <Link to="#"><img src="assets/extra-images/mama-logo.png" class="img-list wp-post-image" alt=""/></Link>
                </div>
                <div class="form-group">
                    <label for="exampleInputEmail1">Email/Username </label>
                    <input className="menu-item-title" id="exampleInputEmail1" name="email" value={email} onChange={e => this.onChangeHandler(e)} type="text" placeholder="Email/Username" />
                    {this.validator.message('Email', email, 'required')}
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1">Password</label>
                    <input className="menu-item-title" id="exampleInputPassword1" name="password" value={password} onChange={e => this.onChangeHandler(e)} type="Password" placeholder="Password" onKeyUp={e => this.onEnterPress(e)} />
                    {this.validator.message('Password', password, 'required')}
                </div>
                <div class="checkbox">
                    <Link to="#">Forgot Password?</Link>
                </div> 
                <Link to ="/admin-login"> <button class="btn btn-red btn-block" ref={input=>{this.refOnclick=input}} onClick={this.Validate.bind(this)}>LogIn</button></Link>
            </form>
     </div>
</div>
</div>
    </div>

        )
    }
}
export default connect()(AdminLogin);
















