import React from 'react';


function Amenities(props) {

    return (
      <div className="menu-itam-holder">
      {
        // <div className="field-holder sticky-search">
        //   <input id="menu-srch-6272" data-id={6272} className="input-field dev-menu-search-field" type="text" placeholder="Search food item" defaultValue />
        // </div>
      }
      {props && props.facility_list && props.facility_list.length > 0 ? props.facility_list.map((item,index) =>(
        <div id="menu-item-list-6272" className="menu-itam-list agro-menu-itam-list" key={index}>
        {
          // <div className="element-title" id="menu-category-5">
          //   <h5 className="text-color"> Appetizers/Starters</h5>
          // </div>
        }

          <div className="row">
            <div className="col-md-12">
              <ul className="facilites-ul">
              <p
            className=''
            dangerouslySetInnerHTML={{
              __html: item.description ? item.description : '<p className=""></p>',
            }}
            />
              </ul>
            </div>
          </div>
          </div>
      )):null}

      </div>
    )
  }

export default Amenities
