import { BaseUrl } from '../helpers/common';
//import * as actionTypes from '../types/types';
import { handleResponse,queryResponse,handleAdminLoginResponse } from '../helpers/userServices';
import {profileInfo} from './buyerActions';
import {restaurantInfo} from './restaurantActions';
import swal from 'sweetalert';
import {ShowLoader,ShowAlert} from './staticActions'
import axios from 'axios';
// THIS ACTION WILL CONTAIN ONLY LOGIN,SIGNUP AND UPDATE PROFILE ACTIONS


export function SignUp(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/sign-up`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                swal({title: "Success!",text: "Account created successfully. We have sent password reset link to your email",icon: "success"}); 
                return 1;
            } else {
                swal({title: "Error!",text: data.ResponseText,icon: "error"});
                return 0;
            }
        }).catch(error=>{
            dispatch(ShowLoader(false));
        });
    }
}


export function Login(logindata) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/login`, {
            method: 'POST',
            body: JSON.stringify(logindata),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
              if(data.type === 'R' && logindata.modalType === 'R'){
                localStorage.setItem('token',data.token)
                localStorage.setItem('type',data.type)
                localStorage.setItem('data',JSON.stringify(data.data))
                dispatch(restaurantInfo())
              }
              else if(data.type === 'U' && logindata.modalType === 'U'){
                localStorage.setItem('token',data.token)
                localStorage.setItem('type',data.type)
                localStorage.setItem('data',JSON.stringify(data.data))
                dispatch(profileInfo())
              }
              else if(data.type === 'AG' && logindata.modalType === 'AG'){
                localStorage.setItem('token',data.token)
                localStorage.setItem('type',data.type)
                localStorage.setItem('data',JSON.stringify(data.data))
              }
              else if(data.type === 'FC' && logindata.modalType === 'FC'){
                localStorage.setItem('token',data.token)
                localStorage.setItem('type',data.type)
                localStorage.setItem('data',JSON.stringify(data.data))
              }
                return {code:1,response:data.ResponseText,type:data.type};
            } else {
                swal({title: "Error!",text: data.ResponseText,icon: "error"});
                return {code:0,response:data.ResponseText};
            }
        }).catch(error=>{
            dispatch(ShowLoader(false));
        });
    }
}

export function LoginPhone(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/login-with-phone`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                localStorage.setItem('token',data.token)
                localStorage.setItem('type',data.type)
                localStorage.setItem('data',JSON.stringify(data.data))
                if(data.type === 'U'){
                    dispatch(profileInfo())
                } else if (data.type === 'R'){
                    dispatch(restaurantInfo())
                }
                return {code:1, data:data, text: data.ResponseText};
            } else {
                swal({title: "Error!",text: data.ResponseText,icon: "error"});
                return {code:0,response:data};
            }
        }).catch(error=>{
            dispatch(ShowLoader(false));
        });
    }
}

export function verifyOTP(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/verify-login`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                localStorage.setItem('token',data.token)
                localStorage.setItem('type',data.type)
                localStorage.setItem('data',JSON.stringify(data.data))
                dispatch(profileInfo())
                // if(data.type === 'U'){
                //     window.location.href = '/buyer-account-setting'
                // } else if (data.type === 'R'){
                //     window.location.href = '/restaurant-profile'
                // }
                return {code:1,response:data.ResponseText,type:data.type};
            } else {
                swal({title: "Error!",text: data.ResponseText,icon: "error"});
                return {code:0,response:data.ResponseText};
            }
        }).catch(error=>{
            dispatch(ShowLoader(false));
        });
    }
}

export function ForgotPassword(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/forgot-password`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                return 1;
            } else {
                swal({title: "Error!",text: data.ResponseText,icon: "error"});
                return 0;
            }
        }).catch(error=>{
            dispatch(ShowLoader(false));
        });
    }
}

export function ForgotPasswordApi(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/forgot-password`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === 1) {
                swal({title: "Success!",text: 'Click on Set password button which is sent to your email. Please check your email.',icon: "success"});
                return {code:1, data:data, text: data.ResponseText};
            } else {
                swal({title: "Error!",text: data.ResponseText,icon: "error"});
                return {code:0,response:data};
            }
        }).catch(error=>{
            dispatch(ShowLoader(false));
        });
    }
}

export function SetPassword(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/set-password`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                localStorage.setItem('token',data.token)
                localStorage.setItem('type','R')
                localStorage.setItem('data',JSON.stringify(data.data))
                dispatch(ShowAlert({ Class: "alert-success", Message: 'Password saved successfully', Timeout: 1500 }));
                return 1;
            } else {
                swal({title: "Error!",text: data.ResponseText,icon: "error"});
                return 0;
            }
        }).catch(error=>{
            swal({title: "Error!",text: error?.password?.[0] ?? 'Error Occured',icon: "error"});
            dispatch(ShowLoader(false));
        });
    }
}

export function AdminLoginFxn(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/login`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(handleAdminLoginResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                return {code:1,data:data.data,token:data.token,type:data.type}
            } else {
                swal({title: "Error!",text: data.ResponseText,icon: "error"});
                return {code:0,text:data.ResponseText};
            }
        }).catch(error=>{
            console.log(error)
            dispatch(ShowLoader(false));
        });
    }
}

export const UploadProfileImage = () => {
    return dispatch => {
        dispatch(ShowLoader(true));
        let request;
        let imageFile = window.$('#profile_image')[0].files[0]
        let formData = new FormData();
        formData.append('file', imageFile);
        request = {
            method: 'POST',
            url: `${BaseUrl}/api/user/upload-profile-image`,
            headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
            data: formData
        };
        return axios(request).then((data) => {
            dispatch(ShowLoader(false));
            dispatch(ShowAlert({ Class: "alert-success", Message:  data.data.ResponseText, Timeout: 1500 }));
            dispatch(profileInfo())
            return 1;
        }).catch((error) => {
            dispatch(ShowLoader(false));
            if (error && error.response && error.response.data) {
                dispatch(ShowAlert({ Class: "alert-danger", Message: error.response.data.ResponseText, Timeout: 1500 }));
                return 0;
            } else {
                dispatch(ShowAlert({ Class: "alert-danger", Message: 'Error Occured', Timeout: 1500 }));
                return 0;
            }
        });
    }
}

export function ChangePassword(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/change-password`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false)); 
            if (data.ResponseCode === "1") {
              return 1;
                //swal({ title: "Success!", text: "Password changed successfully !", icon: "success" });
                //dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));

            } else {
              swal({ title: "Error!", text: data.ResponseText, icon: "error" });
                //dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
                return 0;
            }
        }).catch(error=>{
            if(error.ResponseText){
              swal({ title: "Error!", text: error.ResponseText, icon: "error" });
                //dispatch(ShowAlert({ Class: "alert-danger", Message: error.ResponseText, Timeout: 5000 }));
            }else{
              swal({ title: "Error!", text: "Unexpected error occured", icon: "error" });
                //dispatch(ShowAlert({ Class: "alert-danger", Message: 'Unexpected error occured', Timeout: 5000 }));
            }
            dispatch(ShowLoader(false));
        });
    }
}

export function contactUS(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/contact_us`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                swal({ title: "Success!", text: "Query Added successfully!", icon: "success" });
                return 1;
            } else {
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}
