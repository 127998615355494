import React from 'react';

function SpecialOffer(props) {
    return (
        <div className="main-section diet-plan">
        <div
            className="page-section restaurant-detail-image-section"
            style={{
                background:
                `url("/assets/extra-images/cover-photo01.jpg") no-repeat scroll 0 0 / cover`,
            }}
        >
            <div className="container">
                <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="company-info-detail banner-info">
                    <div className="company-info">
                        <div className="text-holder">
                        <span className="restaurant-title"><b>Special Offers</b></span>
                        {/* <div className='text'>
                            <p>
                        Terms
                            </p>
                        </div> */}
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
     
      <div className="page-section">
        <div className="container">
          <div className="row">
              <div className="col-lg-2 "></div>
                <div className="col-lg-8 col-md-12">
                    <div className="terms_box">
                        <h4 className="m-0">Leverage a Big Local Event</h4>
                        <p>Plan a promotion around a big event nearby, like a local sporting event, concert, or festival. This helps drive business and generates awareness of your restaurant to a much larger audience made up of all the people attending the larger event. You’ll essentially be riding off the coattails of that event’s marketing. 
                        </p>
                        <h4>Charities & Celebrities</h4>
                        <p>Bringing in a celebrity (local or big-time, depending on your budget) can be expensive, but we’ve seen success in enticing them in by donating a certain portion of the day’s proceeds to a charity of their choosing. Getting a big name in the door not only gets people into the restaurant, but combining this effort with charity allows you to show your community that you care about a good cause. 
                        </p>
                        <h4>Special Offers</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum labore similique hic accusantium praesentium corrupti 
                            ullam molestias commodi voluptas nihil, inventore, quas, minus nostrum iste dolores numquam? Voluptatibus, temporibus voluptatum.
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum labore similique hic accusantium praesentium corrupti 
                            ullam molestias commodi voluptas nihil, inventore, quas, minus nostrum iste dolores numquam? Voluptatibus, temporibus voluptatum.
                        </p>
                        <h4>Special Offers</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum labore similique hic accusantium praesentium corrupti 
                            ullam molestias commodi voluptas nihil, inventore, quas, minus nostrum iste dolores numquam? Voluptatibus, temporibus voluptatum.
                        </p>
                        <h4>Special Offers</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum labore similique hic accusantium praesentium corrupti 
                            ullam molestias commodi voluptas nihil, inventore, quas, minus nostrum iste dolores numquam? Voluptatibus, temporibus voluptatum.
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum labore similique hic accusantium praesentium corrupti 
                            ullam molestias commodi voluptas nihil, inventore, quas, minus nostrum iste dolores numquam? Voluptatibus, temporibus voluptatum.
                        </p>
                        <ul className="privacy_list">
                            <li>
                                <i class="fa fa-check text-red"></i>&nbsp; Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            </li>
                            <li>
                            <i class="fa fa-check text-red"></i>&nbsp; Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            </li>
                            <li>
                            <i class="fa fa-check text-red"></i>&nbsp; Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            </li>
                            <li>
                            <i class="fa fa-check text-red"></i>&nbsp; Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            </li>
                        </ul>
                    </div>
                </div>
          </div>
        </div>
      </div>
    
    </div>
    );
}

export default SpecialOffer;