// @ts-nocheck
import React, { Fragment, PureComponent } from 'react';
import { bucketPath } from '../../../../store/helpers/common';
import {
  RestaurantCategoryListing,
  RestaurantMenuListing,
  restaurantViewInfo
} from '../../../../store/actions/restaurantActions';
import { mamaCart } from '../../../../store/actions/cartActions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MenuListingUS from './MenuListingUS';
import MenuListingRHS from './MenuListingRHS';
import MenuListingLHS from './MenuListingLHS';
import ProductListing from './ProductListing';
import MamaInfo from './MamaInfo';
import MenuVideos from './MenuVideos';
import RecipeTab from './RecipeTab';


class MenuListing extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageOfItems: [],
      currentPage: 1,
      cartItems: [],
      categoryID: '',
      tab_name :'home',
      searchItem:''
    };
    this.menuListArray = [];
    this.catListArray = [];
    this.newMenuList = []
  }

  componentDidMount() {
    let uniqueID = this.props.match.params.token;
    this.props.dispatch(restaurantViewInfo(uniqueID));
    this.props.dispatch(RestaurantCategoryListing(uniqueID));
    this.props.dispatch(RestaurantMenuListing(uniqueID));
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let restaurant_data = newProps.restaurant_data ?? [];
    let mama_cart = newProps.mama_cart ?? [];
    let listingData = [];
    let categoryData = [];
    if (restaurant_data.menu_listing && restaurant_data.category_listing) {
      restaurant_data.category_listing instanceof Array &&
        restaurant_data.category_listing.map((value, index) => {
          let getinfoList =
            restaurant_data.menu_listing instanceof Array &&
            restaurant_data.menu_listing.filter(
              (valuefilter) => valuefilter.categoryID === value.categoryID
            );
            categoryData[value.categoryID] = {
              name: value.name,
              description: value.description,
            };
          if (getinfoList instanceof Array && getinfoList.length > 0) {
            listingData[value.categoryID] = getinfoList;
          }
          return true
        });
      this.setState({ cartItems: mama_cart });
    }
    this.catListArray = categoryData;
    this.menuListArray = listingData;
  }

  onAddCart = (value) => {
    let uniqueID = value.uniquekey;
    let name = value.name;
    let price = parseFloat(value.price);
    let { cartItems } = this.state;
    let arrayFilter =
      cartItems instanceof Array &&
      cartItems.findIndex((obj) => obj.uniqueID === uniqueID);
    if (arrayFilter !== -1) {
      cartItems[arrayFilter] = {
        name: name,
        uniqueID: uniqueID,
        price: cartItems[arrayFilter].price + price,
        count: cartItems[arrayFilter].count + 1,
      };
    } else {
      cartItems = [
        ...cartItems,
        { name: name, uniqueID: uniqueID, price: price, count: 1 },
      ];
    }
    this.props.dispatch(mamaCart(cartItems));
    return null;
  };

  onMinusCart = (value) => {
    let uniqueID = value.uniquekey;
    let name = value.name;
    let price = parseFloat(value.price);
    let { cartItems } = this.state;
    let arrayFilter =
      cartItems instanceof Array &&
      cartItems.findIndex((obj) => obj.uniqueID === uniqueID);
    if (arrayFilter !== -1) {
      if (cartItems[arrayFilter].count > 1) {
        cartItems[arrayFilter] = {
          name: name,
          uniqueID: uniqueID,
          price: cartItems[arrayFilter].price - price,
          count: cartItems[arrayFilter].count - 1,
        };
      } else {
        cartItems.splice(arrayFilter, 1);
      }
    }
    this.props.dispatch(mamaCart(cartItems));
    return null;
  };

  onRemoveCart = (uniqueID) => {
    let { cartItems } = this.state;
    let arrayFilter =
      cartItems instanceof Array &&
      cartItems.filter((obj) => obj.uniqueID !== uniqueID);
    this.props.dispatch(mamaCart(arrayFilter));
    return null;
  };

  categoryChosen = (categoryID) => {
    if (categoryID !== '' && categoryID > 0) {
      this.setState({ categoryID: categoryID });
    }
  };

  render() {
    const { category_listing} = this.props.restaurant_data;
    const { mama_cart } = this.props;
    const { categoryID, tab_name,searchItem } = this.state;
    let new_data = []
    let mapmenuData = []
    if(searchItem !== ""){
      this.menuListArray && this.menuListArray.length > 0 && this.menuListArray.map(item =>{
        let data = [];
      data = item instanceof Array && item.filter(value =>{
        const lc = ((value.name).trim()).toLowerCase();
        const filter = (searchItem.trim()).toLowerCase();
        return lc.includes(filter);
      })
      if(data.length > 0 ){
        (new_data).push(data)
      }
      return null;
      })
      this.newMenuList = new_data
      mapmenuData = this.newMenuList
    }
    else {
      mapmenuData = this.menuListArray
    }
    const review_token =  this.props.match.params.token;
    return (
      <div className='main-section'>
        <MenuListingUS {...this.props}/>
        <div className='page-section'>
          <div className='container'>
            <div className='row'>
              <MenuListingLHS
                category_listing={category_listing}
                categoryChosen={this.categoryChosen}
                tabRef={this.tabRef}
              />
              <div className='col-lg-7 col-md-7 col-sm-9 col-xs-12'>
                <div className='tabs-holder horizontal'>
                  <ul className='stickynav-tabs nav nav-tabs'>
                    <li className={tab_name==='home'?"active":""}>
                      <Link data-toggle='tab' ref={input=>{this.tabRef = input}} to='#home' onClick={e=>this.setState({tab_name:'home'})}>
                        <i className='icon- icon-room_service'></i>Menu
                      </Link>
                    </li>
                    <li className={tab_name==='review'?"active":""}>
                      <Link data-toggle='tab' to='#menu1' onClick={e=>this.setState({tab_name:'review'})}>
                        <i className='icon-lastfm-square'></i>Products
                      </Link>
                    </li>
                    <li className={tab_name==='video'?"active":""}>
                      <Link data-toggle='tab' to='#menu4' onClick={e=>this.setState({tab_name:'video'})}>
                        <i className='icon- icon-video2 '></i>Videos
                      </Link>
                    </li>
                    <li className={tab_name==='recipe'?"active":""}>
                      <Link data-toggle='tab' to='#recipe' onClick={e=>this.setState({tab_name:'recipe'})}>
                        <i className='icon- icon-art'></i>Recipe
                      </Link>
                    </li>
                    <li className={tab_name==='mama_info'?"active":""}>
                      <Link data-toggle='tab' to='#menu3' onClick={e=>this.setState({tab_name:'mama_info'})}>
                        <i className='icon- icon-info3'></i>Mama Info/Review
                      </Link>
                    </li>
                  </ul>
                  <div className='tab-content'>
                    <div id='home' className='tab-pane fade in active'>
                      <div className='menu-itam-holder'>
                        <div className='field-holder sticky-search'>
                          <input
                            id='menu-srch-6272'
                            data-id='6272'
                            className='input-field dev-menu-search-field'
                            type='text'
                            placeholder='Search food item'
                            value={this.state.searchItem}
                            onChange={(e) =>this.setState({searchItem:e.target.value})}
                          />
                        </div>
                        <div
                          id='menu-item-list-6272'
                          className='menu-itam-list'
                        >
                          {mapmenuData.map((value, index) => {
                            if (categoryID > 0 && index !== categoryID) {
                              return null;
                            }
                            return (
                              <Fragment key={`listing_${index}`}>
                                <div
                                  className='element-title'
                                  id={`menu-category-${index}`}
                                >
                                  <h5 className='text-color'>
                                    {this.catListArray[index]
                                      ? this.catListArray[index].name
                                      : ''}
                                  </h5>
                                  {/* <span>
                                    {this.catListArray[index]
                                      ? this.catListArray[index].description
                                      : 'unknown'}
                                  </span> */}
                                </div>
                                <ul>
                                  {value instanceof Array &&
                                    value.map((valueMenu, keyMenu) => {
                                      let arrayFilter =
                                        mama_cart instanceof Array &&
                                        mama_cart.findIndex(
                                          (obj) =>
                                            obj.uniqueID === valueMenu.uniquekey
                                        );
                                      return (
                                        <li
                                          key={`list_${valueMenu.categoryID}_${keyMenu}`}
                                        >
                                          <div className='image-holder'>
                                            {' '}
                                            <Link to='#'>
                                              <img
                                                src={
                                                  valueMenu.image
                                                    ? `${bucketPath}${valueMenu.image}`
                                                    : `/assets/extra-images/logo1.png`
                                                }
                                                alt={valueMenu.name}
                                              />
                                            </Link>
                                          </div>
                                          <div className='text-holder'>
                                            <h6>{valueMenu.name}</h6>
                                            <span>{valueMenu.description}</span>
                                            {/* <ul className="nutri-icons">
                                                    <li>
                                                    <Link
                                                        to="#"
                                                        data-toggle="tooltip"
                                                        title="Contain Bnana"
                                                    >
                                                        <img
                                                        src="/assets/extra-images/natural-item03.png"
                                                        alt=""
                                                        />
                                                    </Link>
                                                    </li>
                                                </ul> */}
                                          </div>
                                          <div className='price-holder'>
                                            <span className='price'>
                                              Rs {valueMenu.price}
                                            </span>
                                            {mama_cart instanceof Array &&
                                              arrayFilter !== -1 ? (
                                                <Link
                                                  to='#'
                                                  className='restaurant-add-menu-btn restaurant-minus-menu-btn-53'
                                                  onClick={(e) =>
                                                    this.onMinusCart(valueMenu)
                                                  }
                                                >
                                                  <i className='icon-minus text-color'></i>
                                                </Link>
                                              ):(
                                                <Link
                                                  to='#'
                                                  className='restaurant-add-menu-btn restaurant-add-menu-btn-53'
                                                  onClick={(e) =>
                                                    this.onAddCart(valueMenu)
                                                  }
                                                >
                                                  <i className='icon-plus4 text-color'></i>
                                                </Link>
                                              )}

                                            {mama_cart instanceof Array &&
                                            arrayFilter !== -1
                                              ? `${mama_cart[arrayFilter].count}`
                                              : ''}
                                            {mama_cart instanceof Array &&
                                              arrayFilter !== -1 && (
                                                <Link
                                                  to='#'
                                                  className='restaurant-add-menu-btn restaurant-add-menu-btn-53'
                                                  onClick={(e) =>
                                                    this.onAddCart(valueMenu)
                                                  }
                                                >
                                                  <i className='icon-plus4 text-color margin-15'></i>
                                                </Link>
                                              )}
                                            {/* <Link
                                                    to="#"
                                                    className="restaurant-add-menu-btn restaurant-add-menu-btn-0"
                                                >
                                                    <i className="icon-plus4 text-color"></i>
                                                    <div
                                                    className="foodbakery-button-loader"
                                                    style={{ display: "block" }}
                                                    >
                                                    <div className="spinner">
                                                        <div className="double-bounce1"></div>
                                                        <div className="double-bounce2"></div>
                                                    </div>
                                                    </div>
                                                </Link> */}
                                            <span id='add-menu-loader-0'></span>
                                          </div>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </Fragment>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <ProductListing uniqueID = {review_token} {...this.props}/>
                   <MenuVideos {...this.props} />
                  <RecipeTab {...this.props}/>
                  <MamaInfo review_token={review_token}/>
                  </div>
                </div>
              </div>
              <MenuListingRHS
                onRemoveCart={this.onRemoveCart}
                {...this.props}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const importStateFromProps = (state) => ({
  restaurant_data: state.restaurant,
  mama_cart: state.cart.mama_cart,
});

export default connect(importStateFromProps)(MenuListing);
