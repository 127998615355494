import * as types from "../types/types";

const initalState = {
    condiment_cart:[]
}

const CondimentCartReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.CONDIMENT_CART: 
            return {
                ...state,
                condiment_cart: action.payload 
            }
           
        default:
            return state
    }
}

export default CondimentCartReducer;
