import React from "react";

const ModalHeader = (props) => {
  return (
    <div className="modal-header">
        <button type="button" className="close" onClick={() => props.CloseModal()} >
          <span aria-hidden="true">×</span>
        </button>
        <h5 className="modal-title foodbakery-dev-login-main-title">
          {props.title}
        </h5>
    </div>
  );
};
export default ModalHeader;
