import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { SetPassword } from "./../store/actions/userActions";
import { connect } from "react-redux";

const initialState = {
  password: "",
  confirm_password: "",
  token: "",
  newPasswordHidden: true,
  confirmPasswordHidden: true,
  passwordtypinginfoshow: true,
};

class setPassword extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = initialState;
  }
  componentDidMount() {
    const token = this.props.match.params.id;
    this.setState({ token: token });
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmitHandler = async () => {
    const validator = this.validator;
    const { password, confirm_password, token } = this.state;
    let data = { password, confirmpassword: confirm_password, token: token };
    if (validator.allValid()) {
      const response = await this.props.dispatch(SetPassword(data));
      if (response === 1) {
        this.setState(initialState, () => {
          this.props.history.push("/restaurant-profile");
        });
      } else {
      }
    } else {
      validator.showMessages();
      this.setState({
        passwordtypinginfoshow: false,
      });
      this.forceUpdate();
    }
  };
  toggleNewPasswordShow = () => {
    this.setState({
      newPasswordHidden: !this.state.newPasswordHidden,
    });
  };
  toggleConfirmPasswordShow = () => {
    this.setState({
      confirmPasswordHidden: !this.state.confirmPasswordHidden,
    });
  };
  render() {
    return (
      <div className="main-section">
        <div className="page-section nopadding cs-nomargin recepies-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4"></div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <div className="row set-password-area">
                  <div
                    className="cs-section-title"
                    style={{ textAlign: "center !important" }}
                  >
                    <h2>Set Password</h2>
                  </div>
                  <Form className="set-password-form">
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type={
                          this.state.newPasswordHidden ? "password" : "text"
                        }
                        placeholder="eg: Abc@123"
                        name="password"
                        value={this.state.password}
                        onChange={this.onChangeHandler.bind(this)}
                      />
                      {this.state.newPasswordHidden ? (
                        <i
                          className="far fa-eye eye-password-icon"
                          onClick={() => this.toggleNewPasswordShow()}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye-slash eye-password-icon"
                          aria-hidden="true"
                          onClick={() => this.toggleNewPasswordShow()}
                        ></i>
                      )}
                      {this.validator.message(
                        "Password",
                        this.state.password,
                        "required|min:8,string|max:30,string"
                      )}
                      {/* {this.state.password.length < 6 &&
                      this.state.passwordtypinginfoshow ? (
                        <div className="srv-validation-message">
                          The password must be at least 8 characters.
                        </div>
                      ) : (
                        this.validator.message(
                          "Password",
                          this.state.password,
                          "required|min:8,string|max:30,string"
                        )
                      )} */}
                      <div className="notes-pass">
                        {/* <label
                          className="form-label"
                          for="formBasicConfirmPassword"
                        >
                          NOTE:
                        </label> */}
                        <ul className="password-format">
                          <li>Passwords must be at least 8 characters</li>
                          <li>Atleast 1 uppercase,1 number and 1 special character</li> 
                          <li>Password should not contain user name.</li>
                        </ul>
                      </div>
                    </Form.Group>

                    <Form.Group controlId="formBasicConfirmPassword">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type={
                          this.state.confirmPasswordHidden ? "password" : "text"
                        }
                        placeholder="eg: Abc@123"
                        name="confirm_password"
                        value={this.state.confirm_password}
                        onChange={this.onChangeHandler.bind(this)}
                      />
                      {this.state.confirmPasswordHidden ? (
                        <i
                          className="far fa-eye eye-password-icon"
                          onClick={() => this.toggleConfirmPasswordShow()}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye-slash eye-password-icon"
                          aria-hidden="true"
                          onClick={() => this.toggleConfirmPasswordShow()}
                        ></i>
                      )}
                      {this.validator.message(
                        "Confirm Password",
                        this.state.confirm_password,
                        `required|in:${this.state.password}`
                      )}
                      {/* {this.state.confirm_password.length < 8 &&
                      this.state.passwordtypinginfoshow ? (
                        <div className="srv-validation-message">
                          The Confirm Password must be at least 8 characters.
                        </div>
                      ) : (
                        this.validator.message(
                          "Confirm Password",
                          this.state.confirm_password,
                          `required|in:${this.state.password}`
                        )
                      )} */}
                    </Form.Group>
                    {/* <Form.Group controlId="formBasicConfirmPassword">
                      <Form.Label>NOTE:</Form.Label>
                      <ul className="password-format">
                        <li>Passwords must be at least 8 characters</li>
                        <li>1 uppercase letter</li>
                        <li>1 number and 1 special character.</li>
                        <li>Password should not contain the username.</li>
                      </ul>
                    </Form.Group> */}
                    <Link
                      to="#"
                      className="btn btn-red"
                      onClick={this.onSubmitHandler.bind(this)}
                    >
                      Submit
                    </Link>
                  </Form>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(setPassword);
