import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {restaurantInfo,RestaurantWorkingHours} from './../../store/actions/restaurantActions';
import FoodTruckOpenHours from './FoodTruckOpenHours'


const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
let start_time={}
let end_time={}
let closed = {}
let hideHour = {}
days.map(val => {
	start_time[`${val}_start`] = "";
    end_time[`${val}_end`] = "";
    closed[`${val}_closed`] = false
    hideHour[`${val}_hideHour`] = false
		return null;
});

class RestaurantOpenClose extends Component {
    constructor(props) {
        super(props);
        this.state={
            start:"",
						foodData:{}
        }
        Object.assign(this.state,start_time,end_time);
      }

    componentDidMount(){
        this.props.dispatch(restaurantInfo())
    }

    onTimeChange = (e, name) => {
        this.setState({[e.target.name]:e.target.value})
    }

    onSubmitHandler = async() => {
			const resturants  = this.props.restaurant.data &&
													Object.keys(this.props.restaurant.data).length > 0 &&
													 this.props.restaurant.data.resturants ? this.props.restaurant.data.resturants : {} ;
			const mama_type = resturants && Object.keys(resturants).length > 0 && resturants.mama_type ? resturants.mama_type.split(",") :  [];
        let data = [];
				let datanew = []
            days.map((val) => {
               return data.push({ day_name: val, "starttime": this.state[`${val}_start`], "endtime": this.state[`${val}_end`] });
            });
						days.map((val) => {
               return datanew.push({ day_name: val, "starttime": this.state.foodData[`${val}_start`], "endtime": this.state.foodData[`${val}_end`] });
            });
        const postdata = {data:mama_type.includes("restaurant") ? data : [],
					food_truck_timing:mama_type.includes("food_truck") ? datanew : []
				}
        const response = await this.props.dispatch(RestaurantWorkingHours(postdata));
        if(response===1){
            this.props.history.push('/restaurant-profile');
            this.props.dispatch(restaurantInfo())
        }
    }


    componentDidUpdate = (prevProps, prevState) => {
        let nextProps = this.props;
        if(nextProps.restaurant !== prevProps.restaurant){
            let rest_data = nextProps.restaurant.data.restaurantworking &&
														nextProps.restaurant.data.restaurantworking.length > 0 &&
														nextProps.restaurant.data.restaurantworking[0].restaurant_working_hour ?
														JSON.parse(nextProps.restaurant.data.restaurantworking[0].restaurant_working_hour) :[]
            rest_data.map((val, i) => {
                this.setState({[`${val.day_name}_start`]:val.starttime,[`${val.day_name}_end`]:val.endtime});
                if(val.starttime === '' && val.endtime === ''){
                    this.setState({[`${val.day_name}_closed`]:true,[`${val.day_name}_hideHour`]:true})
                }
								return null;
            })
        }
    }

    ClosedHours=(e,id,val)=>{
        window.$('#'+id).addClass('hide');
        this.setState({[`${val}_start`]:'',[`${val}_end`]:'',[`${val}_closed`]:true});
    }

    OpenHours=(e,id,val)=>{
        window.$('#'+id).removeClass('hide');
        this.setState({[`${val}_hideHour`]:false,[`${val}_closed`]:false})
    }

		getDataFoodTruck = (fooddata) =>{
			const data = fooddata
			this.setState({
				foodData:data
			})
			//this.foodData = fooddata
			//console.log("fooddata",fooddata)
		}

    render() {
        let restaurantsetting = this.props.restaurant?.data?.restaurantsetting.length
        let restaurantworking = this.props.restaurant?.data?.restaurantworking.length
				const resturants  = this.props.restaurant.data &&
														Object.keys(this.props.restaurant.data).length > 0 &&
														 this.props.restaurant.data.resturants ? this.props.restaurant.data.resturants : {} ;
				const mama_type = resturants && Object.keys(resturants).length > 0 && resturants.mama_type ? resturants.mama_type.split(",") :  [];
    return (
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <div className="user-dashboard loader-holder">
                <div className="user-holder">
                <form>
                    {restaurantsetting > 0 && restaurantworking > 0 ?
                        (
                            <ul className='restaurant-settings-nav progressbar-nav'>
                                <li className='active cond-restaurant-settings'>
                                    <Link to='/restaurant-profile'>Restaurant Settings</Link>
                                </li>
                                <li className='cond-restaurant-settings active'>
                                    <Link to='/restaurant-delivery'>Delivery/Pickup</Link>
                                </li>
                                <li className='cond-restaurant-settings active'>
                                    <Link to='/restaurant-open-close'>Restaurant Open/Close</Link>
                                </li>
                            </ul>
                        ) : (
                            <ul className='restaurant-settings-nav progressbar-nav'>
                                <li className='active cond-restaurant-settings'>
                                    <Link to='#'>Restaurant Settings</Link>
                                </li>
                                <li className='active cond-restaurant-settings'>
                                    <Link to='#'>Delivery/Pickup</Link>
                                </li>
                                <li className='cond-restaurant-settings active processing'>
                                    <Link to='#'>Restaurant Open/Close</Link>
                                </li>
                          </ul>
                        )}

                    <div className="form-fields-set">
                            <ul>
                                <li>
																{mama_type.includes("restaurant") && (
																	<div className="row">
																			<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
																					<div className="element-title">
																							<h4>Restaurant Opening Hours</h4>
																					</div>
																			</div>
																			<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
																					<div className="field-holder field-holder-custom">
																							<div className="time-list">
																									<ul>
																									{
																									days.map((val, i) => {
																											return (
																													<li key={i}>
																															<div className="open-close-time opening-time">
																																	<div className="day-sec">
																																			<span>{val}</span>
																																	</div>
																																	<div className={`time-sec ${this.state[`${val}_hideHour`] ? 'hide' : ''}`} id={i} >
																																			<select className="" name={`${val}_start`} value={this.state[`${val}_start`]} onChange={(e) => this.onTimeChange(e)}>
																																					<option value="">Select</option>
																																					<option value="00:00">12:00 AM</option>
																																					<option value="01:00">01:00 AM</option>
																																					<option value="02:00">02:00 AM</option>
																																					<option value="03:00">03:00 AM</option>
																																					<option value="04:00">04:00 AM</option>
																																					<option value="05:00">05:00 AM</option>
																																					<option value="06:00">06:00 AM</option>
																																					<option value="07:00">07:00 AM</option>
																																					<option value="08:00">08:00 AM</option>
																																					<option value="09:00">09:00 AM</option>
																																					<option value="10:00">10:00 AM</option>
																																					<option value="11:00">11:00 AM</option>
																																					<option value="12:00">12:00 PM</option>
																																					<option value="13:00">01:00 PM</option>
																																					<option value="14:00">02:00 PM</option>
																																					<option value="15:00">03:00 PM</option>
																																					<option value="16:00">04:00 PM </option>
																																					<option value="17:00">05:00 PM</option>
																																					<option value="18:00">06:00 PM</option>
																																					<option value="19:00">07:00 PM</option>
																																					<option value="20:00">08:00 PM</option>
																																					<option value="21:00">09:00 PM</option>
																																					<option value="22:00">10:00 PM</option>
																																					<option value="23:00">11:00 PM</option>
																																			</select>
																																			<span className="option-label">to</span>
																																			<select className="" name={`${val}_end`} value={this.state[`${val}_end`]} onChange={(e) => this.onTimeChange(e, `${val}_end`)} >
																																					<option value="">Select</option>
																																					<option value="00:00">12:00 AM</option>
																																					<option value="01:00">01:00 AM</option>
																																					<option value="02:00">02:00 AM</option>
																																					<option value="03:00">03:00 AM</option>
																																					<option value="04:00">04:00 AM</option>
																																					<option value="05:00">05:00 AM</option>
																																					<option value="06:00">06:00 AM</option>
																																					<option value="07:00">07:00 AM</option>
																																					<option value="08:00">08:00 AM</option>
																																					<option value="09:00">09:00 AM</option>
																																					<option value="10:00">10:00 AM</option>
																																					<option value="11:00">11:00 AM</option>
																																					<option value="12:00">12:00 PM</option>
																																					<option value="13:00">01:00 PM</option>
																																					<option value="14:00">02:00 PM</option>
																																					<option value="15:00">03:00 PM</option>
																																					<option value="16:00">04:00 PM </option>
																																					<option value="17:00">05:00 PM</option>
																																					<option value="18:00">06:00 PM</option>
																																					<option value="19:00">07:00 PM</option>
																																					<option value="20:00">08:00 PM</option>
																																					<option value="21:00">09:00 PM</option>
																																					<option value="22:00">10:00 PM</option>
																																					<option value="23:00">11:00 PM</option>
																																			</select>
																																			<Link to="#" value={`${i}`}  onClick={(e)=>this.ClosedHours(e,i,val)} >
																																				<i className="icon-close2"  value={`${i}`}></i>
																																			</Link>
																																	</div>
																																	<div className={`time-sec ${this.state[`${val}_closed`] ? '' : 'hide'}`} >
																																			<Link to="#" value={`${i}`}  onClick={(e)=>this.OpenHours(e,i,val)} >
																																				<i className="fa fa-plus-circle" aria-hidden="true" value={`${i}`}></i>
																																			</Link>
																																			 <h4>Closed</h4>
																																	</div>
																															</div>

																													</li>
																											)
																									})
																									}
																							</ul>
																					</div>
																			</div>
																	</div>
															</div>
																)}
                                    {mama_type.includes("food_truck") && (
																			<FoodTruckOpenHours parentGetDataFoodTruck = {this.getDataFoodTruck}/>
																		)}

                            </li>
                        </ul>
                        <div>
                            <div className="field-holder text-center">
                                <div className="payment-holder input-button-loader">
                                    <Link to="/restaurant-delivery" className="btn btn-black">Back</Link>
                                </div>&nbsp;&nbsp;
                                <div className="payment-holder input-button-loader">
                                    <Link to="#" className="btn btn-black" onClick={this.onSubmitHandler.bind(this)}>Save Changes</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => ({
    restaurant : state.restaurant.restaurant_profile
});

export default connect(mapStateToProps)(RestaurantOpenClose);
