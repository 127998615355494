import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as RestaurantAction from './../../../../store/actions/restaurantActions';

class MenuVideos extends Component {

  componentDidMount = () => {
    let uniqueID = this.props.match.params.token;
    this.props.dispatch(RestaurantAction.VideoList({uniqueID }));
  };

  render() {
    const {
      restaurant_data: { video_list },
    } = this.props;
    return (
      <div id="menu4" className="video-tab tab-pane fade">
         <div className='menu-itam-holder'>
        <div className='menu-itam-list'>
            <div className='row'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <div className='blog blog-medium'>
                  <div className='row'>
                    {video_list && video_list.map((value, index) => {
                      return (
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' key={`video_${index}`}>
                          <div className='blog-post recepie-img-box'>
                            <div className='img-holder'>
                              <figure>
                                <div className='recepie-img-overlay'>
                                  <div className='list-option'>
                                    <span
                                      className='label bgcolor time-label'
                                      style={{ display: 'none' }}
                                    ></span>
                                    <Link
                                      to='#'
                                      className='shortlist-btn'
                                      data-toggle='modal'
                                      data-target='#sign-in'
                                    >
                                      <i className='icon-heart-o'></i>{' '}
                                    </Link>
                                    <Link
                                      to='#'
                                      className='shortlist-btn pull-right'
                                    >
                                      <i className='icon-video-camera'></i>
                                    </Link>
                                  </div>
                                </div>
                                <Link to='#'>
                                    {
                                       <video width="320" height="240" controls>
                                            <source src={`${value?.file}`} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                     </video>
                                    }
                                </Link>
                              </figure>
                            </div>
                            <div className='text-holder'>
                              <div className='post-title'>
                                <h4>
                                    <Link to='#'>{value.title}</Link>
                                </h4>
                              </div>
                              <div dangerouslySetInnerHTML={{__html:`${value?.description ? value?.description.substring(0,150) : ""}<p></p>'}`}}/>
                            </div>
                            {/* <div className="row no-padding col-md-12 view-and-star">
                              <div className="col-md-6 no-padding">
                                <Link
                                  to={`/recipe-info/${value.recipeID}`}
                                  className='read-more text-color'
                                >
                                  View More<i className='icon-arrow-right22'></i>
                                </Link>
                              </div>
                              <div className="col-md-6 text-right no-padding">
                                <div className='list-rating'>
                                  <div className='rating-star'>
                                    <span className='rating-box'></span>
                                  </div>
                                  <span className='reviews'>(1)</span>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  restaurant_data: state.restaurant,
});
export default connect(mapStateToProps)(MenuVideos);
