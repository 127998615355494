import * as types from "./../types/types";

const initalState = {
    data: [],
    restaurant_profile:[],
    restaurant_view_profile:[],
    category_listing:[],
    menu_listing:[],
    restaurant_listing:[],
    mama_cart:[],
    recepie_listing:[],
    force_login_click:false,
    order_detail:[],
    nearby_restaurants:[],
    recipe_data: {},
    recipe_list:[],
    recipe_individual_info:[],
    video_listing: [],
    video_data: {},
    favourite_restaurants:[],
    restaurant_review: [],
    restaurant_recipe_review: [],
    food_item:'',
    condiment_listing:[],
    view_condiment:[],
    cooking_class_list:[],
    particular_cooking_class_data:[],
    restaurant_Id_list:[],
    video_list:[],
    after_force_login_click_header_update:false,
    force_mama_login_click:false,
    nearby_locations:[]

}


const RestaurantReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_RESTAURANT_PROFILE:
            return {
                ...state,
                restaurant_profile: action.payload
            }
        case types.FETCH_RESTAURANT_VIEW_PROFILE:
            return {
                ...state,
                restaurant_view_profile: action.payload
            }
        case types.CATEGORY_LISTING:
            return {
                ...state,
                category_listing: action.payload
            }
        case types.MENU_LISTING:
            return {
                ...state,
                menu_listing: action.payload
            }
        case types.RECEPI_LISTING:
            return {
                ...state,
                recepie_listing: action.payload
            }
        case types.RESTAURANT_LISTING:
            return {
                ...state,
                restaurant_listing: action.payload
            }
        case types.MAMA_CART:
            return {
                ...state,
                mama_cart: action.payload
            }
        case types.FORCE_LOGIN_CLICK:
            return {
                ...state,
                force_login_click: action.payload
            }
        case types.ORDER_DETAIL:
            return {
                ...state,
                order_detail: action.payload
            }
        case types.RECEPI_VIEW:
            return {
                ...state,
                recipe_data: action.payload
            }
        case types.VIDEO_LISTING:
            return {
                ...state,
                video_listing: action.payload
            }
        case types.VIDEO_VIEW:
            return {
                ...state,
                video_data: action.payload
            }
        case types.NEARBY_RESTAURANTS:
            return {
                ...state,
                nearby_restaurants: action.payload
            }
        case types.RECIPE_LIST:
            return {
                ...state,
                recipe_list: action.payload
            }
        case types.RECIPE_INDIVIDUAL_INFO:
            return {
                ...state,
                recipe_individual_info: action.payload
            }
        case types.FAVOURITE_RESTAURANTS:
            return {
                ...state,
                favourite_restaurants: action.payload
            }
        case types.REVIEW_LISTING:
            return {
                ...state,
                restaurant_review: action.payload
            }
        case types.RECIPE_REVIEW_LISTING:
            return {
                ...state,
                restaurant_recipe_review: action.payload
            }
        case types.FETCH_FOOD_DATA:
            return {
                ...state,
                food_item: action.payload,
            }
        case types.FETCH_CONDIMENT_LISTING:
            return {
                ...state,
                condiment_listing: action.payload,
            }
        case types.FETCH_CONDIMENT_VIEW:
            return {
                ...state,
                view_condiment: action.payload,
            }
        case types.COOKING_CLASS_LISTING:
                return {
                    ...state,
                    cooking_class_list: action.payload,
                }
        case types.COOKING_CLASS_VIEW:
                return {
                    ...state,
                    particular_cooking_class_data: action.payload,
                }
        case types.ALL_RESTAURANT_COOKING_CLASS_LISTING:
                return {
                ...state,
                all_restaurant_cooking_class_list: action.payload,
                }
        case types.BOOKING_CLASS_DETAIL:
            return {
                ...state,
                booking_class_detail: action.payload
            }
        case types.FILTER_RESTAURANT_ID:
            return {
                ...state,
                restaurant_Id_list: action.payload
            }
        case types.FETCH_VIDEO_LIST:
            return {
                ...state,
                video_list: action.payload
            }
        case types.FORCE_LOGIN_CLICK_HEADER_UPDATE:
            return {
                ...state,
                after_force_login_click_header_update: action.payload
            }
        case types.FORCE_MAMA_LOGIN_CLICK:
            return {
                ...state,
                force_mama_login_click: action.payload
            }
        case types.NEARBY_LOCATIONS:
        return {
            ...state,
            nearby_locations: action.payload
        }


        default:
            return state
    }
}

export default RestaurantReducer;
