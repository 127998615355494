import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class NavBar extends Component {

	state = {activeClass:'db'}

	ChangeClass = value => {
		this.setState({activeClass:value})	 
	  }
	   logout=(e)=>{
		localStorage.clear()
		this.props.history.push("/admin-login")
	  }
	render() {
		const {activeClass} = this.state;
		return (
			<div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
				<div className="user-account-nav user-account-sidebar">
					<div className="user-nav-list">
						<ul>
							<li className={activeClass==="db"?"active":""}><Link to="/admin-dashboard" onClick={e=>this.ChangeClass('db')}><i className="icon-dashboard3"></i>Dashboard</Link></li>
							<li className={activeClass==="ao"?"active":""}><Link to="/admin-order" onClick={e=>this.ChangeClass('ao')}  className="btn-edit-profile"><i className="icon-add_shopping_cart"></i>My Orders</Link></li>
							<li className={activeClass === "ar" ? "active" : ""}><Link to="/admin-restaurant" onClick={e => this.ChangeClass('ar')}><i className="icon-restaurant_menu"></i>Restaurants</Link></li>
							<li className={activeClass==="au"?"active":""}><Link to="/admin-users" onClick={e=>this.ChangeClass('au')}><i className="icon-build"></i>My Users</Link></li>
							<li className={activeClass === "agro" ? "active" : ""}><Link to="/admin-agro-center" onClick={e => this.ChangeClass('agro')}><i className="icon-food"></i>Agro Center</Link></li>
							<li className={activeClass === "food" ? "active" : ""}><Link to="/admin-food-center" onClick={e => this.ChangeClass('food')}><i className="icon-food"></i>Food Center</Link></li>
							<li className={activeClass==="ap"?"active":""}><Link to="/admin-product" onClick={e=>this.ChangeClass('ap')}><i className="icon-lastfm-square"></i>Products</Link></li>
							<li><Link to="#" onClick={(e )=> this.logout(e)}><i className="icon-log-out"></i>Sign out</Link></li>
						</ul>
					</div>
				</div>
			</div>
			)
	}
}

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(NavBar);
