import * as types from "./../types/types";

const initalState = {
    location_information: [],
    location_information_error:'',
    contact_information: [],
    contact_information_error:'',
    other_information: [],
    other_information_error:'',
    agro_listing:[],
    agro_listing_error:'',
    upload_information:[],
    upload_information_error:'',
    agro_profile:[],
    agro_profile_error:'',
    force_agro_login_click:false,
    agro_review_rating:[],
    like_and_dislike: [],
    like_and_dislike_error:'',
    agro_like_and_dislike_list:[],
    package_list:[],
    package_data:{},
    agro_product:[],
    agro_product_images:[],
    membership_list:[],
    membership_data:{},
    facility_list:[],
    facility_data:{},
    global_agro_product:[],
    agro_image_list:[],
    agro_video_list:[]
}


const AgroReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.LOCATION_INFORMATION_SUCCESS:
            return {
                ...state,
                location_information: action.payload
            }
        case types.LOCATION_INFORMATION_FALIURE:
            return {
                ...state,
                location_information_error: action.payload
            }
            case types.CONTACT_INFORMATION_SUCCESS:
                return {
                    ...state,
                    contact_information: action.payload
                }
            case types.FETCH_AGROCENTER_PRODUCT_LISTING:
            return {
                ...state,
                agro_product: action.payload
            }
            case types.FETCH_AGRO_PRODUCT_IMAGES_LISTING:
            return {
                ...state,
                agro_product_images: action.payload
            }
            case types.FETCH_GLOBAL_AGRO_PRODUCT_LISTING:
                return {
                    ...state,
                    global_agro_product: action.payload
                }
            case types.CONTACT_INFORMATION_FALIURE:
                return {
                    ...state,
                    contact_information_error: action.payload
                }
                case types.OTHER_INFORMATION_SUCCESS:
                    return {
                        ...state,
                        other_information: action.payload
                    }
                case types.OTHER_INFORMATION_FALIURE:
                    return {
                        ...state,
                        other_information_error: action.payload
                    }
                    case types.AGRO_LISTING_SUCCESS:
                        return {
                            ...state,
                            agro_listing: action.payload
                        }
                    case types.AGRO_LISTING_FALIURE:
                        return {
                            ...state,
                            agro_listing_error: action.payload
                        }
                        case types.UPLOAD_INFORMATION_SUCCESS:
                            return {
                                ...state,
                                upload_information: action.payload
                            }
                        case types.UPLOAD_INFORMATION_FALIURE:
                            return {
                                ...state,
                                upload_information_error: action.payload
                            }
                            case types.AGRO_PROFILE_SUCCESS:
                                return {
                                    ...state,
                                    agro_profile: action.payload
                                }
                            case types.AGRO_PROFILE_FALIURE:
                                return {
                                    ...state,
                                    agro_profile_error: action.payload
                                }
                            case types.FORCE_AGRO_LOGIN_CLICK:
                                    return {
                                        ...state,
                                        force_agro_login_click: action.payload
                                    }

                              case types.AGRO_REVIEW_LISTING:
                                    return {
                                        ...state,
                                        agro_review_rating: action.payload
                                    }

                            case types.LIKE_AND_DISLIKE_SUCCESS:
                                  return {
                                      ...state,
                                      like_and_dislike: action.payload
                                  }
                            case types.LIKE_AND_DISLIKE_FALIURE:
                                  return {
                                        ...state,
                                        like_and_dislike_error: action.payload
                                }

                            case types.AGRO_LIKE_AND_DISLIKE_LISTING:
                                    return {
                                          ...state,
                                          agro_like_and_dislike_list: action.payload
                                      }
                            case types.PACKAGE_LISTING:
                                    return {
                                              ...state,
                                              package_list: action.payload
                                      }
                            case types.PACKAGE_VIEW:
                                      return {
                                            ...state,
                                            package_data: action.payload
                                    }
                          case types.MEMBERSHIP_LISTING:
                                  return {
                                      ...state,
                                      membership_list: action.payload
                                    }
                          case types.MEMBERSHIP_VIEW:
                                    return {
                                        ...state,
                                        membership_data: action.payload
                                      }
                        case types.FACILITY_LISTING:
                              return {
                                    ...state,
                                    facility_list: action.payload
                                  }
                        case types.FACILITY_VIEW:
                                  return {
                                      ...state,
                                      facility_data: action.payload
                              }
                        case types.FETCH_AGRO_IMAGES_LISTING:
                                return{
                                  ...state,
                                  agro_image_list:action.payload
                                }
                        case types.FETCH_AGRO_VIDEOS_LISTING :
                                return {
                                  ...state,
                                  agro_video_list:action.payload
                                }

        default:
            return state
    }
}

export default AgroReducer;
