import React, { Component } from "react";
import { Link } from "react-router-dom";
import {ChangePassword} from './../../store/actions/userActions'
import SimpleReactValidator from "simple-react-validator";
import swal from 'sweetalert';
import {connect} from "react-redux" 

const initialState = {password:"",new_password:"",confirm_password:"",oldPasswordHidden:true,newPasswordHidden:true,confirmPasswordHidden:true,Class:'',Message:'',passwordtypinginfoshow:true}
class ChangePasswordTab extends Component {
  constructor(props){
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({
        messages: {
          in: 'Passwords need to match!'
        }
    });
  }
  userFormChangeHandler = (value) => {
    this.props.UserFormChange(value);
  };

onChangeHandler = e => {
    this.setState({[e.target.name]:e.target.value});
}

onSubmitHandler = async () => {
    const {password,new_password,confirm_password} = this.state
    if(this.validator.allValid()){
        const response = await this.props.dispatch(ChangePassword({current_password: password,new_password,confirm_password}))
        if(response === 1){
          this.setState({password:"",new_password:"",confirm_password:"",oldPasswordHidden:true,newPasswordHidden:true,confirmPasswordHidden:true,Class:'success',Message:response.response},()=>{
            this.props.closeModal()
            swal({ title: "Success!", text: "Password changed successfully !", icon: "success" });
          })
        }
        else{
          this.props.closeModal()
          //swal({ title: "Error!", text: response.ResponseText, icon: "error" });
        }
    }else{
        this.validator.showMessages();
        this.setState({
          passwordtypinginfoshow:false
        })
        this.forceUpdate();
    }
}

toggleNewPasswordShow = () =>{
this.setState({
  newPasswordHidden: !this.state.newPasswordHidden
})
}
toggleConfirmPasswordShow = () =>{
this.setState({
  confirmPasswordHidden: !this.state.confirmPasswordHidden
})
}

toggleOldPasswordShow = () =>{
this.setState({
  oldPasswordHidden: !this.state.oldPasswordHidden
})
}
  render() {
    const {password,new_password,confirm_password} = this.state;
    return (
      <div className="modal-body">
        <p className="foodbakery-dev-login-top-msg"></p>
        <div className="cs-login-pbox">
          <div className="status status-message"></div>
          <form
            method="post"
            className="wp-user-form webkit login-form-area"
            id="ControlForm_43687" 
          >
            <div className="input-filed login-password">
              <i className="icon-unlock-alt"></i>
              <input type={this.state.oldPasswordHidden ? "password" : "text"} className={`${!this.state.passwordtypinginfoshow ? "margin-bottom-0" : ""}`} placeholder="Current Password" name="password" value={password} onChange={this.onChangeHandler.bind(this)} />
              {this.state.oldPasswordHidden ? (
                  <i className="far fa-eye eye-password-icon"  onClick={() =>this.toggleOldPasswordShow()}></i>
                ) : (
                  <i className="fa fa-eye-slash eye-password-icon" aria-hidden="true" onClick={() =>this.toggleOldPasswordShow()}></i>
                )}
            </div>
            {this.validator.message(
                            "Password",
                            this.state.password,
                            "required|min:6,string|max:30,string"
                          )}
            <div className="input-filed login-password">
              <i className="icon-unlock-alt"></i>
              <input type={this.state.newPasswordHidden ? "password" : "text"} className={`${!this.state.passwordtypinginfoshow ? "margin-bottom-0" : ""}`} placeholder="New Password" name="new_password" value={new_password} onChange={this.onChangeHandler.bind(this)}/>
              {this.state.newPasswordHidden ? (
                  <i className="far fa-eye eye-password-icon"  onClick={() =>this.toggleNewPasswordShow()}></i>
                ) : (
                  <i className="fa fa-eye-slash eye-password-icon" aria-hidden="true" onClick={() =>this.toggleNewPasswordShow()}></i>
                )}
            </div>
            {this.validator.message(
                            "New Password",
                            this.state.new_password,
                            "required|min:6,string|max:30,string"
                          )}
            <div className="input-filed login-password">
              <i className="icon-unlock-alt"></i>
              <input type={this.state.confirmPasswordHidden ? "password" : "text"} className={`${!this.state.passwordtypinginfoshow ? "margin-bottom-0" : ""}`} placeholder="Confirm New Password" name="confirm_password" value={confirm_password} onChange={this.onChangeHandler.bind(this)}/>
              {this.state.confirmPasswordHidden ? (
                <i className="far fa-eye eye-password-icon"  onClick={() =>this.toggleConfirmPasswordShow()}></i>
              ) : (
                <i className="fa fa-eye-slash eye-password-icon" aria-hidden="true" onClick={() =>this.toggleConfirmPasswordShow()}></i>
              )}
            </div>
            {this.validator.message(
                            "Confirm Password",
                            this.state.confirm_password,
                            `required|in:${new_password}`
                          )}
            <div className="input-filed">
              <Link to="#" className="btn btn-black btn-block" onClick={e=>this.onSubmitHandler()}>
                Save
              </Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default connect()(ChangePasswordTab);
