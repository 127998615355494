import React, { PureComponent } from "react";
import Header from "./Header";
import NavBar from "./NavBar";
import Alerts from "./../../modules/Html/Alerts"
class Adminlayout extends PureComponent {

    componentDidMount(){
        this.CheckIdentity();
    }

   

    CheckIdentity = () => {
        let admintoken = localStorage.getItem('admin-token');
        if(!admintoken){
            this.props.history.push('/admin-login')
        }
    }

    render() {     
        return (
                <div className="main-wrapper">
                    <Header {...this.props} />
                    <Alerts />
                    <div className="main-section">
                    <div className="page-section account-header buyer-logged-in admin-logged-in">
                            <div className="container">
                                <div className="row">
                                    <NavBar {...this.props}/>
                                    {this.props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}

export default Adminlayout;
