import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as RestaurantAction from '../store/actions/restaurantActions';
import { AllRestaurantCookingClassListing} from '../store/actions/restaurantActions'
import swal from 'sweetalert';
import { bucketPath } from '../store/helpers/common';
import Modals from './../modules/Html/Modals';
import ModalHeader from './../modules/Html/ModalHeader';
import ModalBody from './../modules/Html/ModalBody';
import Moment from 'moment';
import Banner from "./Banner";
import CenterSidebar from "./CenterSidebar";

class FoodCaoch extends Component {
  subTotal = 0;
  totalToPay = 0;
  constructor(props) {
      super(props);
      this.state = {
          open: false,
          classuniqueID:"",
          payment_type:'card',
          endDate:""
      };
  }
  componentDidMount() {
		this.props.dispatch(AllRestaurantCookingClassListing());
	}

    checkIfLoggedIn = (title,price,uniqueID) => {
      const getUserIdentity = localStorage.getItem('token');
      if(!getUserIdentity){
        swal({
          text: "You need to login to Book Class!",
          icon: "info",
          buttons: true,
          dangerMode: true,
        })
        .then((yesDo) => {
          if (yesDo) {
            this.props.dispatch(RestaurantAction.ForceloginClick(true))
          } else {
            console.log("Record is Safe");
          }
        });
        return false;
      }
      // let cooking_ID = cookingID
      // console.log("cooking_ID",cooking_ID)
      let foodClassItems = [
        {name:title,uniqueID:uniqueID,price:price}
      ]

      this.subTotal = parseFloat(price);
      this.totalToPay = this.subTotal
      const {payment_type} = this.state;

      let order_type = "cooking_class";

      let data = {payment_type,subTotal:this.subTotal,totalToPay:this.totalToPay,cartItems:foodClassItems,order_type:order_type};

      const promise = new Promise(async (resolve,reject)=>{
        let response = await this.props.dispatch(RestaurantAction.PlaceOrder(data));
        if(response.code===1){
          resolve(response)
        }else{
          reject()
        }
      })

      promise.then((response)=>{
        if(response.type==='card'){
          this.props.history.push(`/stripe-payment/${response.order_number}`)
        }

      })

      }

      CloseModal = () =>{
        this.setState({
          open:false,
          classuniqueID:"",
          endDate:""
        })
      }

      formattedTime = (value) => {
        let Time;
        Time = value.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [Time];
        let startTime = ''
        if (Time.length > 1) {
             Time = Time.slice (1);
             Time[5] = +Time[0] < 12 ? 'AM' : 'PM';
             Time[0] = +Time[0] % 12 || 12;
             startTime =  Time.join ('');
        }
        var formattedStartTime = startTime.slice(0, 4) + startTime.slice(7);
        return formattedStartTime
    }

  render() {
    const { open,classuniqueID } = this.state
    const { all_restaurant_cooking_class_list } = this.props.restaurant_data
    const modalData = all_restaurant_cooking_class_list && all_restaurant_cooking_class_list.length > 0 ? all_restaurant_cooking_class_list.filter(item =>item.uniqueID === classuniqueID) : [];
    let modalTime = "";
    if (modalData && modalData.length > 0 && modalData[0].time !== "") {
      modalTime = JSON.parse(modalData[0].time);
    }
    let newTime = [];
    if(modalTime && modalTime.length > 0){
      modalTime.map(item =>{
        if(item.starttime !=="" && item.endtime !==""){
          newTime.push(item)
        }
        return true
      })
    }

    return (
      <>
        <div className="main-section">
          <Banner name="Food Classes" description="Choose your food class"/>
        <div className="page-section recepies-section pdTop-0 col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <div className="container">
                <div className="row">

                    {
                      //<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      // <div className="cs-section-title" style={{textAlign:'center !important'}}>
                      //     <h2>Choose your food class</h2>
                      // </div>
                      //</div>
                    }


                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 food-classes">
                        <div className="row">
                            <div className="listing grid-listing three-cols">
                              {all_restaurant_cooking_class_list && all_restaurant_cooking_class_list.length > 0 && all_restaurant_cooking_class_list.map((item,index) =>{
                                // let time = (item.time).toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
                                // let newtime = ""
                                //     if (time.length > 1) {
                                //       time = time.slice (1);
                                //       time[5] = +time[0] < 12 ? 'AM' : 'PM';
                                //       time[0] = +time[0] % 12 || 12;
                                //        newtime = time.join ('');
                                //     }
                                    //console.log("newtime",newtime)
                                    let date = (item.date).split("-")
                                    let newDate = date[2] + "-" + date[1] + "-" + date[0]

                                    let lowercasedata = item.duration.toLowerCase()
                                    let array = lowercasedata.split(" ");
                                    let endDate = "";
                                    if(array.includes("week")){
                                      let days = array[0] * 7
                                      let d =  new Date(item.date);
                                      d.setDate((d).getDate() + days)
                                      endDate = Moment(d).format('DD-MM-YYYY');
                                    }
                                    else if(array.includes("day")){
                                      let days = array[0]
                                      let d =  new Date(item.date);
                                      d.setDate((d).getDate() + days)
                                      endDate = Moment(d).format('DD-MM-YYYY');
                                    }
                                    else if(array.includes("month")){
                                      let month = array[0]
                                      let d =  new Date(item.date);
                                      d.setMonth((d).getMonth() + month)
                                      endDate = Moment(d).format('DD-MM-YYYY');
                                    }
                                    else if(array.includes("year")){
                                      let year = array[0]
                                      let d =  new Date(item.date);
                                      d.setFullYear((d).getFullYear() + year)
                                      endDate = Moment(d).format('DD-MM-YYYY');
                                    }
                                return (
                                  <div className="col-md-4 col-xs-6 grid-listing-col recipe-part" key={index}>
                                    <div className="img-holder">
                                        <figure>
                                            <Link to="#" onClick={(e) =>this.setState({open:true,classuniqueID:item.uniqueID,endDate:endDate})} ><img src={`${item.image && item.image.length > 0 ? bucketPath + item.image : "assets/extra-images/chef-1.png"}`} alt="" className="img-responsive" /> </Link>
                                            <Link className="mama-coach-img" to="#"><img src={`${item.logo_image && item.logo_image.length > 0 ? bucketPath + item.logo_image : "assets/extra-images/fb-restaurant-09.png"}`} alt="" className="img-responsive" /> </Link>
                                        </figure>

                                    </div>
                                    <div className="text-holder">
                                        <div className="listing-inner">
                                                <h4><Link to="#" onClick={() =>this.setState({open:true,classuniqueID:item.uniqueID,endDate:endDate})}>{item.title}</Link></h4>
                                                <p
                                                onClick={() =>this.setState({open:true,classuniqueID:item.uniqueID})}
                                                className='recipeSummary'
                                              dangerouslySetInnerHTML={{
                                                __html: item?.description ?? '',
                                              }}
                                            />
                                            <h5 className="coach-date-time" onClick={() =>this.setState({open:true,classuniqueID:item.uniqueID,endDate:endDate})}>Price:<span>{`₹${item.price}`}</span></h5>
                                            <h5 className="coach-date-time" onClick={() =>this.setState({open:true,classuniqueID:item.uniqueID,endDate:endDate})}>Course Start Date:<span>{newDate}</span></h5>
                                            <h5 className="coach-date-time" onClick={() =>this.setState({open:true,classuniqueID:item.uniqueID,endDate:endDate})}>Course End Date:<span>{endDate}</span></h5>
                                            <h5 className="coach-duration-time" onClick={() =>this.setState({open:true,classuniqueID:item.uniqueID,endDate:endDate})}>Duration:<span>{item.duration}</span></h5>
                                            <div className="no-padding col-md-12 food-coach-btn">
                                                <Link to="#" className="book-now" onClick={() => this.checkIfLoggedIn(item.title,item.price,item.uniqueID)}>Book Now</Link>
                                                <Link to="#" className="btn btn-red pull-right" onClick={() =>this.setState({open:true,classuniqueID:item.uniqueID,endDate:endDate})}>View Detail</Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                              )})}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CenterSidebar center="foodClass" />
    </div>
    <Modals open={open} >
        <ModalHeader CloseModal={this.CloseModal} title= "Class Details" />
        <ModalBody>
        {modalData && modalData.length > 0 && (
          <div className="product-details-body">
              <div className="row">
                  <div className="col-md-6">
                      <div className="prudct-slider-side main-post cooking-class-modal-img">
                           <img
                              src={modalData[0].image !=="" ? bucketPath + modalData[0].image :"assets/extra-images/chef-1.png"}
                              className="img-fluid mx-auto d-block"
                              alt="..."

                          />
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="prudct-content-side ">
                          <h2 className="product-dt-name">{modalData[0].title}</h2>
                          {
                            // <div className="list-rating">
                            //     <div className="rating-star">
                            //         <span className="rating-box" style={{ width: "100%" }}></span>
                            //     </div>
                            //     <span className="reviews">(1)</span>
                            // </div>
                          }

                          <h3 className="prodcut-md-price">price: {`₹${modalData[0].price}`}</h3>
                          <h6 className="margin-top-10">Course Start Date: <span className="font-weight-normal">{Moment(modalData[0].date).format('DD-MM-YYYY')}</span></h6>
                          <h6 className="margin-top-10">Course End Date: <span className="font-weight-normal">{this.state.endDate}</span></h6>

                          <h6 className="margin-top-10">Duration: <span className="font-weight-normal">{modalData[0].duration}</span></h6>
                      </div>
                  </div>
              </div>
              {/* comment area strt */}
              <div className="row">
                  <div className="col-md-6">
                      <div className="discription-body">
                          <h5>Class Description</h5>
                          <p
                        dangerouslySetInnerHTML={{
                          __html: modalData[0].description ?? '<p></p>',
                        }}
                      />
                      </div>
                  </div>

                  <div className='col-lg-6 col-md-6 cooking-time-modal-area'>
                  {newTime && newTime.length > 0 ? (
                    <div className='widget widget-timing '>
                      <h5>Class Timing</h5>
                      <ul>
                      {newTime.map((item,index) =>(
                        <li key={index}>
                          <span>{item.starttime !=="" && item.endtime !=="" ? item.day_name : ""}</span>
                          {item.starttime !=="" && item.endtime !=="" ? this.formattedTime(item.starttime) + ' - '+ this.formattedTime(item.endtime) : ""}
                        </li>
                      ))}
                      </ul>
                    </div>
                  ):null}

                  </div>
              </div>
              {/* comment area strt */}
              <div className="row">




              </div>
              {/* comment area end */}
          </div>
        )}



        </ModalBody>
    </Modals>
    </>
    );
  }
}

const mapStateToProps = (state) => ({
  restaurant_data: state.restaurant
});

export default connect(mapStateToProps)(FoodCaoch);
