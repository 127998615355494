// @ts-nocheck
import React, { PureComponent } from 'react';
import { bucketPath } from './../../store/helpers/common';
//import Pagination from '../../../../store/helpers/Pagination';
import {
  agroProfile,ForceAgrologinClick,addAgroCenterReview,agroReviewListing,likeAndDislike,likeAndDislikeListing,PackageListing,MembershipListing,FacilityListing,agroImagesListing
} from './../../store/actions/agroActions';


import * as RestaurantAction from './../../store/actions/restaurantActions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Modals from './../../modules/Html/Modals';
import ModalHeader from './../../modules/Html/ModalHeader';
import ModalBody from './../../modules/Html/ModalBody';
import VisitorTour from './VisitorTour'
import Amenities from './Amenities'
import Products from './Products'
import Membership from './Membership'


import SimpleReactvalidator from 'simple-react-validator'
import swal from 'sweetalert';
import Agrocart from './Agrocart'
// import {
//   FacebookIcon,FacebookShareButton,TwitterIcon,LinkedinIcon,LinkedinShareButton,TwitterShareButton
// } from 'react-share'

class AgroInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageOfItems: [],
      currentPage: 1,
      cartItems: [],
      categoryID: '',
      tab_name: 'home',
      open:false,
      selectedimageIndex:0,
      modal_type:"",
      title: '',
      description: '',
      rating: 1,
      category_state:false,
      website_link_state:false,
      agro_video:""
    };
    this.validator = new SimpleReactvalidator();
  }

  componentDidMount() {
    let uniqueID = this.props.match.params.token;
    let agroID = this.props.match.params.id;
    const data = {
      "agroID":agroID
    }
    this.props.dispatch(agroProfile(uniqueID));
    this.props.dispatch(agroReviewListing(data));
    //this.props.dispatch(likeAndDislikeListing({"agroid":agroID}))
		this.props.dispatch(PackageListing({ agroID }));
    this.props.dispatch(MembershipListing({ agroID }));
    this.props.dispatch(FacilityListing({ agroID }));
    this.props.dispatch(agroImagesListing({agroID:agroID}));




  }
  openReviewModal = () => {
    const getUserIdentity = localStorage.getItem('token');
    if (!getUserIdentity) {
      swal({
        text: "You need to login to write Review!",
        icon: "info",
        buttons: true,
        dangerMode: true,
      })
        .then((yesDo) => {
          if (yesDo) {
            this.props.dispatch(RestaurantAction.ForceloginClick(true))
          } else {
            console.log("Record is Safe");
          }
        });
      return false;
    }
    this.setState({
      open: true,
      modal_type:"add_review"
    })
  }

  openModal = (value) =>{
    this.setState({
      open: true,
      selectedimageIndex:value,
      modal_type:"images_show"
    })
  }


  CloseModal = e => {
    this.setState({
      open: false,
      selectedimageIndex:0,
      selectedvideoIndex:0,
      modal_type:""
    })
  }

  onRadioChangeHandler = e => {
    this.setState({ rating: e.target.value });
  }

  onChangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  addRivew = async (e) => {
    const { title, description, rating } = this.state;
    let agroID = this.props.agro_profile && this.props.agro_profile.data && this.props.agro_profile.data.agrocenter && this.props.agro_profile.data.agrocenter.agroID ? this.props.agro_profile.data.agrocenter.agroID  : "";
    if (this.validator.allValid()) {
      let response = await this.props.dispatch(addAgroCenterReview({ title, description, points: (rating * 20), agroID }));
      if (response === 1) {
        this.CloseModal();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate()
    }
  }

  likeDislikeHandler = (value) =>{
    const getUserIdentity = localStorage.getItem('token');
    if (!getUserIdentity) {
      swal({
        text: "You need to login to Like Review!",
        icon: "info",
        buttons: true,
        dangerMode: true,
      })
        .then((yesDo) => {
          if (yesDo) {
            this.props.dispatch(RestaurantAction.ForceloginClick(true))
          } else {
            console.log("Record is Safe");
          }
        });
      return false;
    }
    else{
      let agroID = this.props.match.params.id;
      const data = {
        reviewID:value,
        agroID:agroID,
        status:"Like"
      }
      this.props.dispatch(likeAndDislike(data));
    }

  }

  categoryHandler = () =>{
    this.setState({
      category_state:!this.state.category_state
    })
  }

  formattedTime = (value) => {
    let Time;
    Time = value.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [Time];
    let startTime = ''
    if (Time.length > 1) {
         Time = Time.slice (1);
         Time[5] = +Time[0] < 12 ? 'AM' : 'PM';
         Time[0] = +Time[0] % 12 || 12;
         startTime =  Time.join ('');
    }
    return startTime
}

  render() {
    const { title, description, rating ,modal_type} = this.state;
    const {agro_image_list } = this.props
    const agrocenter = this.props.agro_profile && this.props.agro_profile.data && this.props.agro_profile.data.agrocenter ? this.props.agro_profile.data.agrocenter : {};
    //const agrocenter_other_information = this.props.agro_profile && this.props.agro_profile.data && this.props.agro_profile.data.agrocenter_other_information ? this.props.agro_profile.data.agrocenter_other_information : [];
    const image = agro_image_list && agro_image_list.length > 0 && agro_image_list[0].image ? agro_image_list[0].image : []
    const video = agro_image_list && agro_image_list.length > 0 && agro_image_list[0].video ? agro_image_list[0].video : []
    const building = agrocenter && Object.keys(agrocenter).length > 0 && agrocenter.building !== null && agrocenter.building !== "" ? agrocenter.building + "," : "";
    const city = agrocenter && Object.keys(agrocenter).length > 0 && agrocenter.city !== null && agrocenter.city !== ""  ? agrocenter.city + "," : "";
    const state = agrocenter && Object.keys(agrocenter).length > 0 && agrocenter.state !== null && agrocenter.state !== "" ? agrocenter.state + "," : "";
    const country = agrocenter && Object.keys(agrocenter).length > 0 && agrocenter.country !== null && agrocenter.country !== "" ? agrocenter.country  : "";
    //const pincode = agrocenter && Object.keys(agrocenter).length > 0 && agrocenter.pincode !== null && agrocenter.pincode !== "" ? agrocenter.pincode : "";

    return (
      <>
      <div className='main-section agro-info-area'>
        <div
          className='page-section restaurant-detail-image-section'
          style={{
            background:
              `url(${agrocenter && Object.keys(agrocenter).length > 0 &&
              agrocenter.cover_image ? bucketPath + agrocenter.cover_image : "/assets/extra-images/cover-photo01.jpg"}) no-repeat scroll 0 0 / cover`,
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <div className='company-info-detail'>
                  <div className='company-info'>
                    <div className='img-holder'>
                      <figure>
                        <img
                        src={agrocenter && Object.keys(agrocenter).length > 0 &&
                        agrocenter.profile_image ? bucketPath + agrocenter.profile_image : "../assets/extra-images/food_center.png"}
                          alt=''
                        />
                      </figure>
                    </div>
                    <div className='text-holder'>
                      <div className='rating-star'>
                        <span className='rating-box'
                          style={{ width: '100%' }}
                        ></span>
                      </div>
                      <span className='reviews'>(1 Reviews)</span>
                      <span className='restaurant-title'>
                      {
                        agrocenter && Object.keys(agrocenter).length > 0 &&
                        ((agrocenter.firstname)||(agrocenter.lastname)) &&
                        agrocenter.firstname + " " + agrocenter.lastname
                      }
                      </span>
                      <div className='text'>
                        <i className='icon-local_pizza'></i>
                        <p>
                        {
                          agrocenter && Object.keys(agrocenter).length > 0 &&
                          agrocenter.business_name &&
                          agrocenter.business_name
                        }
                        </p>
                      </div>
                    </div>
                    <div className="agro-login-option">
                      <Link to="#"
                      onClick={(e) => this.props.dispatch(ForceAgrologinClick(true))}>
                      Login
                      </Link>

                      {
                        //<a class="" href="/">Sign Up</a>
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='page-section'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-2 col-md-2 col-sm-12 col-xs-12 sticky-sidebar'>
                <div className='filter-toggle' onClick={() =>this.categoryHandler()}>
                  <span className='filter-toggle-text'>Categories By</span>
                  <i className='icon-chevron-down'></i>
                </div>
                <div className={`${this.state.category_state ? "" : "filter-wrapper"}`}>
                  <div className='categories-menu'>
                  {
                    // <h6>
                    //   Test
                    // </h6>
                  }

                    <div className="agro-left-profile-bx">
                    {
                      // <ul className="agro-leftside-images">
                      // {image && image.length > 0 ? image.map((item,index) =>(
                      //
                      //   <li key={index}>
                      //     <Link to="#">
                      //       <div className="img-holder">
                      //         <figure>
                      //           <img src={bucketPath + image[index]} alt="" onClick = {() =>this.openModal(index)}/>
                      //         </figure>
                      //       </div>
                      //     </Link>
                      //   </li>
                      // )) :null}
                      //
                      //   {
                      //     // <li><a href="#"> <div className="img-holder"><figure><img src="../assets/extra-images/add-img.png" alt=""/></figure></div> </a></li>
                      //   }
                      //
                      // </ul>
                    }
                          <h5>Address</h5>
                          <div className="agro-addres">
                            <p>
                             <i className="icon-location-pin"></i>
                            <span>
                            {
                              building + " " + city + " " + state + " " + country
                            }
                            </span>
                           </p>
                          </div>
                          <div className="agro-phone-num">
                            <p>
                            <i className="icon-phone4"></i>
                            <span>
                            {
                              agrocenter && Object.keys(agrocenter).length > 0 &&
                              agrocenter.phone &&
                              agrocenter.phone
                            }
                            </span>
                            </p>
                          </div>
                          <div className="agro-addres">
                          <p>
                          <i className='icon-globe2'></i>
                          <span>
                          <a href="www.mamachaiagrocenter.com">
                          www.mamachaiagrocenter.com
                          </a>
                          </span>
                          </p>

                          {
                          //   <p>
                          //
                          // <i className="icon-plus-circle"></i>
                          //     <span>dummy text</span>
                          //   </p>
                          }
                          {
                            // <>
                            // {agrocenter_other_information && agrocenter_other_information.length > 0 ? (
                            //   <div className='widget widget-timing agro-widget-timing'>
                            //     <h5>Opening Hours</h5>
                            //     <ul>
                            //     {agrocenter_other_information.map((item,index) =>(
                            //       <li key={index}>
                            //         <span>{item.day}</span>
                            //         {item.starttime !=="" && item.endtime !=="" ? this.formattedTime(item.starttime) + ' - '+ this.formattedTime(item.endtime) : (<span className="color-red">Closed</span>)}
                            //       </li>
                            //     ))}
                            //     </ul>
                            //   </div>
                            // ):null}
                            // </>
                          }

                          </div>
                          {
                            // <div className="agro-add-adress">
                            //   <div className='widget widget-timing agro-widget-social-link'>
                            //     <h5 onClick={() =>this.setState({website_link_state:!this.state.website_link_state})}>Website Links</h5>
                            //     <ul className={`${this.state.website_link_state ? "" : "website-links-display"}`}>
                            //     <li className='pizzaeast agro-center-link'>
                            //     <a href="www.mamachaiagrocenter.com">
                            //       <i className='icon-globe2'></i>
                            //         www.mamachaiagrocenter.com
                            //       </a>
                            //     </li>
                            //     {
                            //       // <>
                            //       // { agrocenter && Object.keys(agrocenter).length > 0 &&
                            //       //   agrocenter.website ? (
                            //       //     <li className='pizzaeast'>
                            //       //     <a href={agrocenter.website}>
                            //       //       <i className='icon-globe2'></i>
                            //       //         {agrocenter.website}
                            //       //       </a>
                            //       //     </li>
                            //       //   ):null}
                            //       // { agrocenter && Object.keys(agrocenter).length > 0 &&
                            //       //   agrocenter.twitter && (
                            //       //     <li className='pizzaeast'>
                            //       //       <a href={agrocenter.twitter}>
                            //       //       <i className='icon-twitter'></i>
                            //       //         {agrocenter.twitter}
                            //       //       </a>
                            //       //     </li>
                            //       //   )}
                            //       //
                            //       // { agrocenter && Object.keys(agrocenter).length > 0 &&
                            //       //   agrocenter.facebook && (
                            //       //     <li className='pizzaeast'>
                            //       //       <a href={agrocenter.facebook}>
                            //       //       <i className='icon-facebook'></i>
                            //       //       {agrocenter.facebook}
                            //       //       </a>
                            //       //     </li>
                            //       //   )}
                            //       //
                            //       // { agrocenter && Object.keys(agrocenter).length > 0 &&
                            //       //   agrocenter.instagram && (
                            //       //     <li className='pizzaeast'>
                            //       //       <a href={agrocenter.instagram}>
                            //       //       <i className='icon-instagram'></i>
                            //       //         {agrocenter.instagram}
                            //       //       </a>
                            //       //     </li>
                            //       //   )}
                            //       //
                            //       // {agrocenter && Object.keys(agrocenter).length > 0 &&
                            //       //   agrocenter.email && (
                            //       //     <li className='email'>
                            //       //       <Link className='text-color' to='#'>
                            //       //       <i className='icon-mail5'></i>
                            //       //       {agrocenter.email}
                            //       //       </Link>
                            //       //     </li>
                            //       //   )}
                            //       // </>
                            //     }
                            //
                            //
                            //     </ul>
                            //   </div>
                            //
                            // </div>

                          }

                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-7 col-md-7 col-sm-12 col-xs-12'>
                <div className='tabs-holder horizontal'>
                  <ul className='stickynav-tabs nav nav-tabs'>
                    <li className="active">
                      <a data-toggle='tab' href='#menu2'>
                        <i className='far fa-image'></i>Gallery
                      </a>
                    </li>
                    <li>
                      <a data-toggle='tab' href='#menu3'>
                        <i className='icon- icon-info3'></i>Visitor Tour
                      </a>
                    </li>
                    <li>
                      <a data-toggle='tab' href='#menu1'>
                          <i className='far fa-building '></i>Facilities
                      </a>
                    </li>
                    <li>
                      <a data-toggle='tab' href='#products'>
                          <i className='icon-lastfm-square'></i>Products
                      </a>
                    </li>
                    <li>
                      <a data-toggle='tab' href='#membership'>
                          <i className='fa fa-users'></i>Membership
                      </a>
                    </li>
                  </ul>
                  <div className='tab-content'>
                    <div id='menu2' className='tab-pane agro-image-tab fade fade in active'>
                      <div className="listing simple agro-reviewtab-div">
                      {
                        (image && image.length > 0) || (video && video.length > 0) ? (
                          <ul className="agro-leftside-images">
                          {image.map((item,index) =>(

                            <li key={index}>
                              <Link to="#">
                                <div className="img-holder">
                                  <figure>
                                    <img src={bucketPath + item.image} alt="" onClick = {() =>this.openModal(index)}/>
                                  </figure>
                                </div>
                              </Link>
                            </li>
                          ))}

                           {video && video.length > 0 ? video.map((data,index) =>
                             (
                                <li key={index}>
                                  <div className="img-holder agro-video-area" >
                                  <figure>
                                  <Link to="#" className="video-icon-area">
                                    <i className="fas fa-play play-video-ico" onClick={e => { this.setState({ open: true, agro_video: data.video,modal_type:"video_show" }) }}></i>
                                  </Link>
                                 <video controls>
                                   <source src={`${bucketPath}${data.video}`} >
                                    </source>
                                    Your browser does not support the video tag.
                                  </video>
                                  </figure>
                                  </div>
                                  </li>
                              )):null}

                            {
                              // <li><a href="#"> <div className="img-holder"><figure><img src="../assets/extra-images/add-img.png" alt=""/></figure></div> </a></li>
                            }

                          </ul>
                        ):<h4 className="text-center">
                           No Record Found
                         </h4>
                      }

                      {

                      // <>
                      //
                      //   <ul>
                      //     {agro_review_rating && agro_review_rating.length > 0 ? agro_review_rating.map((item,index)=>{
                      //       const like_dislike_data = agro_like_and_dislike_list && agro_like_and_dislike_list.length > 0 ?
                      //                                 agro_like_and_dislike_list.filter(filterdata =>filterdata.reviewID === item.id):[];
                      //         console.log("like_dislike_data",like_dislike_data)
                      //       return(
                      //         <li key={index}>
                      //           <div className="img-holder">
                      //             <figure>
                      //               <a href="#">
                      //                 <img src={bucketPath + item.profile_pic} alt="" />
                      //               </a>
                      //             </figure>
                      //           </div>
                      //           <div className="text-holder">
                      //             <div className="post-title">
                      //               <h5><a href="#">{item.title}</a></h5>
                      //             </div>
                      //             <div className="list-rating">
                      //               <div className="rating-star">
                      //                 <span className="rating-box" style={{ width: item.points + "%" }}></span>
                      //               </div>
                      //               {
                      //                 //<span className="reviews">(1)</span>
                      //               }
                      //             </div>
                      //             <div className="delivery-potions">
                      //               <span>{item.lastupdate.split(" ")[0]}</span>
                      //             </div>
                      //             <div className="delivery-potions">
                      //               <span>{item.description}</span>
                      //             </div>
                      //             {/*  */}
                      //             <div className=" like-share-div">
                      //               <div className="col-md-4">
                      //                 <Link to="#" onClick={() =>this.likeDislikeHandler(item.id)}><i className={`fas fa-thumbs-up ${like_dislike_data && like_dislike_data.length > 0 && like_dislike_data[0].status === "Like" ? "like-color" : ""}`}></i>Like</Link>
                      //               </div>
                      //                 {
                      //                   // <div className="col-md-4 text-center">
                      //                   //   <a><i className="fas fa-comment"></i>Comment</a>
                      //                   // </div>
                      //                 }
                      //
                      //
                      //                   <div class="col-md-4 text-right share-div"><a class="share-link dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-share"></i>Share</a>
                      //                     <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                      //                       <li>
                      //                       <FacebookShareButton url={`https://www.facebook.com/`}>
                      //                         <FacebookIcon  size={32} round={true}/>
                      //                       </FacebookShareButton>
                      //                         </li>
                      //                       <li>
                      //                       <TwitterShareButton url={`https://twitter.com/`}>
                      //                         <TwitterIcon  size={32} round={true}/>
                      //                       </TwitterShareButton>
                      //                       </li>
                      //                       <li>
                      //                       <LinkedinShareButton url={`https://www.linkedin.com/`}>
                      //                         <LinkedinIcon  size={32} round={true}/>
                      //                       </LinkedinShareButton>
                      //                       </li>
                      //                     </ul>
                      //                     </div>
                      //
                      //                     {
                      //                       // <div className="col-md-4 text-right">
                      //                       //   <a><i className="fas fa-share"></i>Share</a>
                      //                       // </div>
                      //                     }
                      //
                      //
                      //             </div>
                      //             {/*  */}
                      //           </div>
                      //         </li>
                      //       )
                      //     }):null}
                      //
                      //   </ul>
                      //
                      //   <div className="col-md-12 text-center review-btn-div">
                      //     <Link to="#" className="write-review-btn" onClick={() =>
                      //       this.openReviewModal()
                      //     }>Write Review</Link>
                      //   </div>
                      //   </>
                      }
                      </div>
                    </div>
                    <div id='menu3' className='tab-pane fade '>
                      <div className='contact-info-detail'>
                        <VisitorTour package_list ={this.props.package_list}/>
                      {
                        // <h4 className="text-center">
                        //    No Record Found
                        //  </h4>
                      }

                      {
                      // <>
                      //   <h5>{`Overview ${agrocenter && Object.keys(agrocenter).length > 0 &&
                      //   ((agrocenter.firstname)||(agrocenter.lastname)) &&
                      //   agrocenter.firstname + " " + agrocenter.lastname}`}</h5>
                      //   <p className='restaurant-desc'></p>
                      //   {
                      //     // <p>
                      //     //   Base prepared fresh daily. Extra toppings are
                      //     //   available in choose extraChoose you sauce: Go for BBQ
                      //     //   sauce or piri piri sauce on your pizza base for no
                      //     //   extra cost.Choose your cut: Triangular, square,
                      //     //   fingers or Un cut on any size pizza
                      //     // </p>
                      //   }
                      //
                      //   <div className='map-sec-holder'>
                      //     <div className='cs-map-section'>
                      //       <div className='cs-map'>
                      //         <div className='cs-map-content'>
                      //           <div className='mapouter'>
                      //           {
                      //             agrocenter && Object.keys(agrocenter).length > 0 && agrocenter.latitude !="" && agrocenter.longitude != "" && (
                      //               <div className='gmap_canvas mama-info-map'>
                      //               <MapAutocomplete
                      //                 center={{ lat: parseFloat(agrocenter.latitude), lng: parseFloat(agrocenter.longitude) }}
                      //                 height='400px'
                      //                 zoom={16}
                      //                 FetchAddress={this.FetchAddress}
                      //               />
                      //               </div>
                      //             )
                      //           }
                      //           </div>
                      //         </div>
                      //       </div>
                      //     </div>
                      //   </div>
                      //   <div className='row'>
                      //     <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                      //       <div className='contact-info'>
                      //         <h5>Contact details</h5>
                      //         <p>{
                      //           building + city + state + country
                      //         }</p>
                      //         <ul>
                      //         {agrocenter && Object.keys(agrocenter).length > 0 &&
                      //           agrocenter.phone && (
                      //             <li className='cell'>
                      //               <i className='icon-phone'></i>
                      //               <a href='#'>{agrocenter.phone}</a>
                      //             </li>
                      //           )}
                      //           { agrocenter && Object.keys(agrocenter).length > 0 &&
                      //             agrocenter.website ? (
                      //               <li className='pizzaeast'>
                      //                 <i className='icon-globe2'></i>
                      //                 <a href={agrocenter.website}>
                      //                   {agrocenter.website}
                      //                 </a>
                      //               </li>
                      //             ):null}
                      //           { agrocenter && Object.keys(agrocenter).length > 0 &&
                      //             agrocenter.twitter && (
                      //               <li className='pizzaeast'>
                      //                 <i className='icon-twitter'></i>
                      //                 <a href={agrocenter.twitter}>{
                      //                   agrocenter.twitter}</a>
                      //               </li>
                      //             )}
                      //
                      //           { agrocenter && Object.keys(agrocenter).length > 0 &&
                      //             agrocenter.facebook && (
                      //               <li className='pizzaeast'>
                      //                 <i className='icon-facebook'></i>
                      //                 <a href={agrocenter.facebook}>
                      //                 {agrocenter.facebook}
                      //                 </a>
                      //               </li>
                      //             )}
                      //
                      //           { agrocenter && Object.keys(agrocenter).length > 0 &&
                      //             agrocenter.instagram && (
                      //               <li className='pizzaeast'>
                      //                 <i className='icon-instagram'></i>
                      //                 <a href={agrocenter.instagram}>{agrocenter.instagram}</a>
                      //               </li>
                      //             )}
                      //
                      //           {agrocenter && Object.keys(agrocenter).length > 0 &&
                      //             agrocenter.email && (
                      //               <li className='email'>
                      //                 <i className='icon-mail5'></i>
                      //                 <a className='text-color' href='#'>
                      //                 {
                      //                   agrocenter.email}
                      //                 </a>
                      //               </li>
                      //             )}
                      //
                      //         </ul>
                      //       </div>
                      //     </div>
                      //     <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                      //     {agrocenter_other_information && agrocenter_other_information.length > 0 ? (
                      //       <div className='widget widget-timing'>
                      //         <h5>Opening Hours</h5>
                      //         <ul>
                      //         {agrocenter_other_information.map((item,index) =>(
                      //           <li key={index}>
                      //             <span>{item.day}</span>
                      //             {item.starttime !="" && item.endtime !="" ? item.starttime + ' - '+ item.endtime : "Off"}
                      //           </li>
                      //         ))}
                      //         </ul>
                      //       </div>
                      //     ):null}
                      //
                      //     </div>
                      //   </div>
                      //   </>
                      }
                      </div>
                    </div>
                    <div id='menu1' className='tab-pane fade'>
                      <div className="contact-info-detail">
                      <Amenities  facility_list={this.props.facility_list}/>
                      {
                        // <h4 className="text-center">
                        //    No Record Found
                        //  </h4>
                      }

                      {

                      // <>
                      // {video && video.length > 0 ? (
                      //   <div className="col-md-12 modal-video">
                      //     <video width="320" height="240" controls>
                      //       <source src={`${bucketPath}${video[0]}`}>
                      //       </source>
                      //       Your browser does not support the video tag.
                      //     </video>
                      //   </div>
                      // ): <p>
                      //   There is no content
                      //   </p>}
                      //   </>
                      }

                      </div>
                    </div>
                    <div id='products' className='tab-pane fade'>
                      <div className="contact-info-detail">
                      <Products id={this.props.match.params.token} {...this.props} />

                      </div>
                    </div>

                    <div id='membership' className='tab-pane fade'>
                      <div className="contact-info-detail">
                      <Membership  membership_list={this.props.membership_list}/>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              {/* <div className="sticky-sidebar col-lg-3 col-md-3 col-sm-12 col-xs-12">
              </div> */}
                 <Agrocart {...this.props} id = {this.props.match.params.token}/>


            </div>
          </div>
        </div>
      </div>
      <Modals open={this.state.open} className="modal-size">
        <ModalHeader CloseModal={this.CloseModal}  title={modal_type === "add_review" ? "Add Review" : ""}/>
        <ModalBody>
        {modal_type === "images_show" ? (
          <div className="row">
          <figure className="col-md-12 main-post agro-info-md">
                            {/* <a href="#"><img src="assets/extra-images/blogs-food-bakery-02-750x422.jpg" alt="" /></a> */}
                            <div
                              id='carousel-example-generic'
                              className='carousel slide'
                              data-ride='carousel'
                              data-interval="false"
                            >
                            {
                              // <ol className="carousel-indicators">
                              //   {CombinedImage?.split(',')?.map(
                              //     (imgVal, imgIndex) => {
                              //       return (
                              //         <li key={"dot_" + imgIndex} data-target="#carousel-example-generic" data-slide-to={`${imgIndex}`} className=""></li>
                              //       )
                              //     })
                              //   }
                              // </ol>
                            }

                              {/* <!-- Wrapper for slides --> */}
                              <div
                                className='carousel-inner'
                                role='listbox'
                              >

                                {image && image.length > 0 && image.map(
                                  (imgVal, imgIndex) => {
                                    return (
                                      <div className={`item ${imgIndex === this.state.selectedimageIndex ? "active" : ""}`} key={`item_${imgIndex}`}>
                                        <Link to='#'>
                                            <img
                                              src={`${bucketPath}${imgVal.image}`}
                                              alt=''
                                            />
                                        </Link>
                                      </div>
                                    );
                                  }
                                )}
                              </div>

                              {/* <!-- Controls --> */}
                              <a
                                className='left carousel-control'
                                href='#carousel-example-generic'
                                role='button'
                                data-slide='prev'
                              >
                                <span
                                  className='icon-arrows-1'
                                  aria-hidden='true'
                                ></span>
                                <span className='sr-only'>Previous</span>
                              </a>
                              <a
                                className='right carousel-control'
                                href='#carousel-example-generic'
                                role='button'
                                data-slide='next'
                              >
                                <span
                                  className='icon-arrows'
                                  aria-hidden='true'
                                ></span>
                                <span className='sr-only'>Next</span>
                              </a>
                            </div>
                          </figure>
          </div>
        ): modal_type === "add_review" ? (
          <div className="order-detail-inner">
            <div className="reviews-holder">
              <div className="add-new-review-holder add-new-review-2067">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h3>Rate and Write a Review</h3>
                  </div>
                  <div className="foodbakery-add-review-data">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div
                        className="total-rating user-rating-container overall-rating"
                        data-overall-rating="1"
                      >
                        <h6>Rating</h6>
                        <div className="rating-star">
                          <input
                            id="star-13"
                            type="radio"
                            name="rating1"
                            value="1"
                            className="star-1"
                            defaultChecked={rating === "1" || rating === "2" || rating === "3" || rating === "4" || rating === "5" ? "checked" : ""}//"checked"
                            onClick={e => this.onRadioChangeHandler(e)}
                          />
                          <label className="star-13" htmlFor="star-13">
                            1
                          </label>
                          <input
                            id="star-23"
                            type="radio"
                            name="rating2"
                            value="2"
                            className="star-2"
                            defaultChecked={rating === "2" || rating === "3" || rating === "4" || rating === "5" ? "checked" : ""}//"checked"
                            onClick={e => this.onRadioChangeHandler(e)}
                          />
                          <label className="star-2" htmlFor="star-23">
                            2
                          </label>
                          <input
                            id="star-33"
                            type="radio"
                            name="rating3"
                            value="3"
                            className="star-3"
                            defaultChecked={rating === "3" || rating === "4" || rating === "5" ? "checked" : ""}//"checked"
                            onClick={e => this.onRadioChangeHandler(e)}
                          />
                          <label className="star-3" htmlFor="star-33">
                            3
                          </label>
                          <input
                            id="star-43"
                            type="radio"
                            name="rating4"
                            value="4"
                            className="star-4"
                            defaultChecked={rating === "4" || rating === "5" ? "checked" : ""}//"checked"
                            onClick={e => this.onRadioChangeHandler(e)}
                          />
                          <label className="star-4" htmlFor="star-43">
                            4
                          </label>
                          <input
                            id="star-53"
                            type="radio"
                            name="rating5"
                            value="5"
                            className="star-5"
                            defaultChecked={rating === "5" ? "checked" : ""}//"checked"
                            onClick={e => this.onRadioChangeHandler(e)}
                          />
                          <label className="star-5" htmlFor="star-53">
                            5
                          </label>
                          <span style={{ width: (rating * 20) + "%" }}></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-element">
                        <i className="icon-edit2"></i>
                        <input
                          type="text"
                          placeholder="Title of your review *"
                          value={title}
                          name="title"
                          onChange={e => this.onChangeHandler(e)}
                        />
                        {this.validator.message('title', title, 'required')}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-element">
                        <textarea
                          placeholder="Tell about your experience or leave a tip for others"
                          cols="30"
                          rows="10"
                          maxLength="500"
                          name="description"
                          value={description}
                          onChange={e => this.onChangeHandler(e)}
                        ></textarea>
                        {this.validator.message('description', description, 'required|min:10')}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-element message-length">
                        <span>Min characters: 10</span>
                        <span>Max characters: 500</span>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-element">
                        <input
                          type="button"
                          defaultValue="Submit your Review"
                          onClick={e => this.addRivew(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ):modal_type === "video_show" ? (
          <>
          {this.state.agro_video !== "" ?
            <div className="col-md-12 modal-video">
            <video controls src={`${bucketPath}${this.state.agro_video}`}>
              Your browser does not support the video tag.
            </video>
           </div>
         :null}
         </>
          )
        :null}

        </ModalBody>
      </Modals>
      </>
    );
  }
}

const importStateFromProps = (state) => ({
  restaurant_data: state.restaurant,
  mama_cart: state.cart.mama_cart,
  agro_profile : state.agro.agro_profile,
  agro_review_rating : state.agro.agro_review_rating,
  agro_like_and_dislike_list : state.agro.agro_like_and_dislike_list,
  package_list:state.agro.package_list,
  membership_list:state.agro.membership_list,
  facility_list:state.agro.facility_list,
  agro_image_list:state.agro.agro_image_list

});

export default connect(importStateFromProps)(AgroInfo);
