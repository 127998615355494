import React from 'react';

function Earnings(){
  return(
    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <div className="user-dashboard">
        <div className="user-holder">
          <ul className="earning-calculation">
            <li>Earnings of this month is: <strong> Rs 00.00</strong></li>
            <li>Your balance: <strong> Rs 00.00</strong></li>
            <li>Total value of your sales : <strong> Rs 00.00</strong></li>
            <li>Your Total Withdrawals:<a href="#"><span>(View withdrawal history)</span></a><strong> Rs 00</strong></li>
          </ul>
          <div className="element-title right-filters -row">
            <h5>Earnings</h5>
            <div className="right-filters  row">
              <div className="col-lg-6 col-md-6 col-xs-6 pull-right">
                <div className="input-field">

                  <i className="icon-angle-down" />


                  <input type="text" data-id="daterange223" id="daterange"  placeholder="Select Date Range" />
                </div>
              </div>
            </div>
          </div>
          <div className="earnings-container-inner">
            <div className="tab-content">
              <div id="menu2" className="responsive-table tab-pane fade in active">
                <ul className="table-generic">
                  <li className="order-heading-titles">
                    <div>Date</div>
                    <div>Total Sales</div>
                    <div>Total Earnings</div>
                  </li>
                  {/* <li className="order-heading-titles">
                    <div>Mar 10,2020</div>
                    <div>Rs 50.85</div>
                    <div>Rs 45.77</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 11,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 12,2020</div>
                    <div>Rs 31.64</div>
                    <div>Rs 28.48</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 13,2020</div>
                    <div>Rs 29.95</div>
                    <div>Rs 26.96</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 14,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 15,2020</div>
                    <div>Rs 36.73</div>
                    <div>Rs 33.06</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 16,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 17,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 18,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 19,2020</div>
                    <div>Rs 33.90</div>
                    <div>Rs 30.51</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 20,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 21,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 22,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 23,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 24,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 25,2020</div>
                    <div>Rs 37.29</div>
                    <div>Rs 33.56</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 26,2020</div>
                    <div>Rs 29.38</div>
                    <div>Rs 26.44</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 27,2020</div>
                    <div>Rs 25.43</div>
                    <div>Rs 22.89</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 28,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 29,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 30,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Mar 31,2020</div>
                    <div>Rs 66.25</div>
                    <div>Rs 59.63</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Apr 1,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Apr 2,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Apr 3,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Apr 4,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Apr 5,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Apr 6,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Apr 7,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Apr 8,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Apr 9,2020</div>
                    <div>Rs 103.07</div>
                    <div>Rs 92.76</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>Apr 10,2020</div>
                    <div>Rs 0.00</div>
                    <div>Rs 0.00</div>
                  </li>
                  <li className="order-heading-titles">
                    <div>&nbsp;</div>
                    <div><b>Total Earnings:</b></div>
                    <div><b>Rs 400.06</b></div>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Earnings
