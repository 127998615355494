import React from 'react';

function Terms(props) {
    return (
        <div className="main-section diet-plan">
        <div
            className="page-section restaurant-detail-image-section"
            style={{
                background:
                `url("/assets/extra-images/cover-photo01.jpg") no-repeat scroll 0 0 / cover`,
            }}
        >
            <div className="container">
                <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="company-info-detail banner-info">
                    <div className="company-info">
                        <div className="text-holder">
                        <span className="restaurant-title"><b>Terms & Conditions</b></span>
                        {/* <div className='text'>
                            <p>
                        Terms
                            </p>
                        </div> */}
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
     
      <div className="page-section">
        <div className="container">
          <div className="row">
              <div className="col-lg-2 "></div>
                <div className="col-lg-8 col-md-12">
                    <div className="terms_box">
                        <h4 className="m-0">Terms and Conditions</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum labore similique hic accusantium praesentium corrupti 
                            ullam molestias commodi voluptas nihil, inventore, quas, minus nostrum iste dolores numquam? Voluptatibus, temporibus voluptatum.
                        </p>
                        <h4>Terms and Conditions</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum labore similique hic accusantium praesentium corrupti 
                            ullam molestias commodi voluptas nihil, inventore, quas, minus nostrum iste dolores numquam? Voluptatibus, temporibus voluptatum.
                        </p>
                        <h4>Terms and Conditions</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum labore similique hic accusantium praesentium corrupti 
                            ullam molestias commodi voluptas nihil, inventore, quas, minus nostrum iste dolores numquam? Voluptatibus, temporibus voluptatum.
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum labore similique hic accusantium praesentium corrupti 
                            ullam molestias commodi voluptas nihil, inventore, quas, minus nostrum iste dolores numquam? Voluptatibus, temporibus voluptatum.
                        </p>
                        <h4>Terms and Conditions</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum labore similique hic accusantium praesentium corrupti 
                            ullam molestias commodi voluptas nihil, inventore, quas, minus nostrum iste dolores numquam? Voluptatibus, temporibus voluptatum.
                        </p>
                        <h4>Terms and Conditions</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum labore similique hic accusantium praesentium corrupti 
                            ullam molestias commodi voluptas nihil, inventore, quas, minus nostrum iste dolores numquam? Voluptatibus, temporibus voluptatum.
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum labore similique hic accusantium praesentium corrupti 
                            ullam molestias commodi voluptas nihil, inventore, quas, minus nostrum iste dolores numquam? Voluptatibus, temporibus voluptatum.
                        </p>
                        <ul className="privacy_list">
                            <li>
                                <i class="fa fa-check text-red"></i>&nbsp; Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            </li>
                            <li>
                            <i class="fa fa-check text-red"></i>&nbsp; Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            </li>
                            <li>
                            <i class="fa fa-check text-red"></i>&nbsp; Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            </li>
                            <li>
                            <i class="fa fa-check text-red"></i>&nbsp; Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            </li>
                        </ul>
                    </div>
                </div>
          </div>
        </div>
      </div>
    
    </div>
    );
}

export default Terms;