import React, { Component } from "react";
import { connect } from "react-redux";
import {
    confOptions,
    initOptions,
    options,
} from "../../store/helpers/ConferenceOptions";
import swal from "sweetalert";
import { Link, withRouter } from "react-router-dom";
import { UpdateStreamingStatus } from "../../store/actions/restaurantActions";

const JitsiMeetJS = window.JitsiMeetJS;
const initialState = {
    connection: null,
    room: null,
    isJoined: false,
    recordingSessionId: null,
    jibriUser: false,
    duration: "00:00:00",
    roomName: "mamachai"
};

class Conference extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.localTracks = [];
        this.remoteTracks = {};
        this.durationInterval = null;
        this.audioOnRef = React.createRef();
        this.audioOffRef = React.createRef();
    }

    renderStreamingButton = () => {
        return (<div
            className="calling-ico button-container"
        >
            <Link
                to="#"
                style={{
                    background: "rgb(255, 255, 255)",
                    padding: "16px",
                    borderRadius: "50px",
                }}
                onClick={this.toggleStreaming}
            >
                <i
                    className={`far fa-dot-circle bottom-call-icon ${
                        this.state.recordingSessionId ? "text-red" : ""
                    }`}
                ></i>
                {this.state.recordingSessionId ? `Stop` : `Start`}{" "}
                Streaming
            </Link>
        </div>)
    }

    render() {
        const {isConferenceJoined} = this.state;
        return (
            <div style={{ position: "relative" }}>
                <audio ref={this.audioOnRef} id="on-streaming">
                    <source
                        src="/assets/audio/liveStreamingOn.mp3"
                        type="audio/mp3"
                    />
                </audio>
                <audio ref={this.audioOffRef} id="off-streaming">
                    <source
                        src="/assets/audio/liveStreamingOff.mp3"
                        type="audio/mp3"
                    />
                </audio>
                {this.state.recordingSessionId && !this.state.jibriUser && (
                    <div
                        style={{
                            position: "absolute",
                            color: "White",
                            top: "50%",
                            left: "42%",
                        }}
                    >
                        Please wait. Streaming starts within few seconds...
                    </div>
                )}
                {this.state.recordingSessionId && this.state.jibriUser && (
                    <div
                        style={{
                            position: "absolute",
                            color: "White",
                            top: "50%",
                            left: "48%",
                        }}
                    >
                        {this.state.duration}
                    </div>
                )}
                {this.props.data_recpies && this.props.data_recpies ==="recpies" ? null : (
                  <div className="back-button-container">
                      {!this.state.recordingSessionId && (
                          <Link to="#" onMouseUp={this.handleBackButton}>
                              <i className="fas fa-arrow-circle-left"></i> Back{" "}
                          </Link>
                      )}
                  </div>
                )}

                {this.props.children}
                {isConferenceJoined && this.renderStreamingButton()}
            </div>
        );
    }

    componentDidMount() {
        window.onpopstate = function () {
            if (this.state.isJoined && this.state.connection) {
                this.onUnload();
            }
        }.bind(this);
        JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
        JitsiMeetJS.init(initOptions);
        // window.onbeforeunload = this.onUnload;
        if(localStorage.data) {
            let roomName = `mama-${JSON.parse(localStorage.data).uniqueID}`;
            this.setState({roomName}, () => {
                this.onJoin();
            })
        }

    }


    // componentDidUpdate(prevProps) {
    //     if (this.props.triggerJoin !== prevProps.triggerJoin) {
    //         if(this.state.connection && !this.props.triggerJoin) {
    //             this.onUnload();
    //         } else {
    //             this.onJoin();
    //         }
    //     }
    // }

    onJoin = () => {
        console.error("Trigger join");
        let connection = new JitsiMeetJS.JitsiConnection(null, null, options);
        connection.connect();
        this.setState({ connection }, () => {
            this.createLocalTrack();
            const { connection } = this.state;
            connection.addEventListener(
                JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
                () => this.onConnectionSuccess()
            );
            connection.addEventListener(
                JitsiMeetJS.events.connection.CONNECTION_FAILED,
                () => this.onConnectionFailed
            );
            connection.addEventListener(
                JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
                () => this.disconnect
            );

            JitsiMeetJS.mediaDevices.addEventListener(
                JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
                () => console.error("device changed")
            );
        });
    };

    onConnectionSuccess = () => {
        console.error("connection success");
        let room = this.state.connection.initJitsiConference(
            this.state.roomName,
            confOptions
        );
        this.setState({ room }, () => {
            this.handleRoom();
        });
    };

    onConnectionFailed = () => {
        console.error("Connection Failed!");
    };

    handleRoom = () => {
        const { room } = this.state;

        room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, (track) => {
            console.log(`track removed!!!${track}`);
        });
        room.on(
            JitsiMeetJS.events.conference.CONFERENCE_JOINED,
            this.onConferenceJoined
        );
        room.on(JitsiMeetJS.events.conference.USER_JOINED, this.onUserJoined);
        room.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft);
        room.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, (track) => {
            console.log(`${track.getType()} - ${track.isMuted()}`);
        });
        room.on(
            JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
            (userID, displayName) => console.log(`${userID} - ${displayName}`)
        );
        room.on(
            JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
            (userID, audioLevel) => console.log(`${userID} - ${audioLevel}`)
        );
        room.on(JitsiMeetJS.events.conference.PHONE_NUMBER_CHANGED, () =>
            console.log(`${room.getPhoneNumber()} - ${room.getPhonePin()}`)
        );
        room.join();
    };

    disconnect = () => {
        console.log("disconnect!");
        const { connection } = this.state;
        connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            this.onConnectionSuccess()
        );
        connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            this.onConnectionFailed
        );
        connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            this.disconnect
        );
    };

    onConferenceJoined = () => {
        console.error("conference joined!");
        this.setState({ isJoined: true }, () => {
            this.localTracks.forEach((track) => {
                this.state.room.addTrack(track);
            });
        });
        console.error("myUserId =========== ",this.state.room.myUserId());
        if(this.state.room.myUserId()) {
            this.setState({ isConferenceJoined: true });
        }
    };

    onUserLeft = (id) => {
        console.log("user left");
        if (!this.remoteTracks[id]) {
            return;
        } else if (this.remoteTracks[id] === "jibri") {
            this.audioOffRef.current.play();
            return;
        }
        const tracks = this.remoteTracks[id];

        for (let track of tracks) {
            const element = document.getElementById(`${id}${track.getType()}`);
            track.detach(element);
            if (element) {
                element.remove();
            }
        }
    };

    onUserJoined = (id, user) => {
        console.error("USER JOINED ===================> ", id, user);
        let userType = null;
        Object.keys(user).forEach((key) => {
            if (key === "_statsID" && user[key] === "jibri") {
                userType = user[key];
            }
        });
        if (userType) {
            this.remoteTracks[id] = "jibri";
            let timer = 0;
            this.setState({ jibriUser: true }, () => {
                console.error("Recording is On =================");
                    this.audioOnRef.current.play();
                this.durationInterval = setInterval(() => {
                    timer += 1000;
                    this.setState({ duration: this.msToTimeString(timer) });
                }, 1000);
            });
        }
    };

    onUnload = () => {
        const { room, connection } = this.state;
        try {
            for (let track of this.localTracks) {
                track.dispose();
            }
            room.leave().then(() => {
                connection.disconnect();
                this.setState({ ...initialState }, () => {
                    this.props.history.push('/recepies-videos');
                });
            });
        } catch (error) {
            this.props.history.push('/recepies-videos');
        }
    };

    createLocalTrack = () => {
        JitsiMeetJS.createLocalTracks({ devices: ["audio", "video"] })
            .then(this.onLocalTracks)
            .catch((error) => {
                swal(
                    "Permission error!",
                    "Video needs to use your microphone and camera. Select Allow when your browser asks for permissions.",
                    "error"
                ).then(() => {
                    this.props.history.push("/recepies-videos");
                });
                throw error;
            });
    };

    onLocalTracks = (tracks) => {
        const { audioRef, videoRef } = this.props;
        this.localTracks = tracks;
        for (let i = 0; i < this.localTracks.length; i++) {
            this.localTracks[i].addEventListener(
                JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
                (audioLevel) => console.log(`Audio Level local: ${audioLevel}`)
            );
            this.localTracks[i].addEventListener(
                JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
                () => console.log("local track muted")
            );
            this.localTracks[i].addEventListener(
                JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
                () => console.log("local track stoped")
            );
            this.localTracks[i].addEventListener(
                JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
                (deviceId) =>
                    console.log(
                        `track audio output device was changed to ${deviceId}`
                    )
            );
            console.error(this.localTracks[i].getType());
            if (this.localTracks[i].getType() === "video") {
                this.localTracks[i].attach(videoRef.current);
            } else {
                this.localTracks[i].attach(audioRef.current);
            }
            if (this.state.isJoined) {
                this.state.room.addTrack(this.localTracks[i]);
            }
        }
    };

    toggleStreaming = () => {
        const { room, recordingSessionId } = this.state;
        console.error("room.options.name", room.options.name)
        swal({
            title: `Do you want to ${
                recordingSessionId ? "Stop" : "start"
            } streaming?`,
            // icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const appData = JSON.stringify({
                    file_recording_metadata: {
                        share: true,
                    },
                });
                const recordingOption = {
                    appData,
                    mode: "stream",
                    streamId: room.options.name,
                };

                if (!recordingSessionId) {
                    room.startRecording(recordingOption)
                        .then((response) => {
                            Object.keys(response).forEach((key) => {
                                if (key === "_sessionID") {
                                    this.setState(
                                        {
                                            recordingSessionId:
                                                response._sessionID,
                                        },
                                        () => {
                                            this.updateStreamingStatus(true);
                                            console.error(
                                                "TOGGLE RECORDING STATE ============ ",
                                                response
                                            );
                                        }
                                    );
                                }
                            });
                        })
                        .catch((error) => {
                            console.error(error);
                            swal(
                                "Streaming error!",
                                "Please try again later!",
                                "error"
                            );
                        });
                } else {
                    const stoptRecording = room.stopRecording(
                        recordingSessionId
                    );
                    stoptRecording
                        .then((response) => {
                            console.error(
                                "RECORDING Response =========> ",
                                response
                            );
                            this.setState({ recordingSessionId: null }, () => {
                                this.updateStreamingStatus(false);
                            });
                        })
                        .catch((error) => {
                            console.error("RECORDING ERROR =========> ", error);
                        });
                }
            }
        });
    };

    updateStreamingStatus = (streamStatus) => {
        const streaming = streamStatus ? "YES" : "NO";
        this.props.UpdateStreamingStatus({ streaming });
    };

    msToTimeString(ms) {
        let seconds = (ms / 1000) % 60;
        let minutes = Math.floor(ms / 1000 / 60) % 60;
        let hours = Math.floor(ms / 1000 / 60 / 60);

        seconds = ("0" + seconds).slice(-2);
        minutes = ("0" + minutes).slice(-2);
        hours = ("0" + hours).slice(-2);

        return `${hours}:${minutes}:${seconds}`;
    }

    handleBackButton = () => {
        this.onUnload();
    };
}

export default withRouter(connect(null, { UpdateStreamingStatus })(Conference));
