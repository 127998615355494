import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import SimpleReactvalidator from 'simple-react-validator'
import { RestaurantAddRecepie, RestaurantMenuListing, ViewRestaurantRecepie } from './../../store/actions/restaurantActions'
import { bucketPath } from '../../store/helpers/common';
import Select from 'react-select';

const initialState = {
	title: '',
	description: '',
	summary: '',
	editID: '',
	image: '',
	video: '',
	menu_item: {},
	prepration_time: '',
	cooking_time: '',
	serving_person: '',
	additional_time: '',
	recipe_images: [],
	ingredients: [1],
	recipe_videos: [],
	live_status:'',
	// live_video:'',
	// live_video_data:[]
};

let quantity = {};
let ingName = {};

[...Array(5).keys()].map((value, key) => {
	key = key + 1;
	quantity["quantity_" + key] = "";
	ingName["ingName_" + key] = "";
	return true
});

class PackageBuilder extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		Object.assign(
			this.state,
			quantity,
			ingName,
		);
		this.validator = new SimpleReactvalidator();
	}

	componentDidMount() {
		let uniqueID = this.props.match.params.recipeID;
		let menuuniqueID = JSON.parse(localStorage.getItem('data')).uniqueID;
		this.props.dispatch(RestaurantMenuListing(menuuniqueID));
		if (uniqueID) {
			this.props.dispatch(ViewRestaurantRecepie(uniqueID));
		}

	}

	componentDidUpdate(nextProps, prevState) {
		let recipe_data = this.props.recipe_data;
		const { menu_listing } = this.props.restaurant_data;
		if ((!recipe_data || recipe_data === nextProps.recipe_data) || (!menu_listing || menu_listing === nextProps.menu_listing)) {
			return false;
		}
		let selectedMenuItem = menu_listing?.filter(x => x.uniquekey === recipe_data.menu_uniqueID)[0];
		let ingredients = [];
		//recipe_data["ingredients"] = '[{"quantity":"1 cup","ingName":"Milk"},{"quantity":"1 spoon","ingName":"sugar"}]';
		this.setState({
			title: recipe_data.title,
			description: recipe_data.description,
			summary: recipe_data.summary,
			editID: recipe_data.recipeID,
			menu_item: {
				"label": selectedMenuItem?.name,
				"value": selectedMenuItem?.uniquekey
			},
			// image: recipe_data.image,
			// video: recipe_data.video,
			recipe_images: recipe_data.image ? recipe_data.image.split(",") : [],
			recipe_videos: recipe_data.video ? recipe_data.video.split(",") : [],
			//live_video_data: recipe_data.live_video ? recipe_data.live_video.split(",") : [],

			prepration_time: recipe_data.prepration_time,
			cooking_time: recipe_data.cooking_time,
			serving_person: recipe_data.serving_person,
			additional_time: recipe_data.additional_time,
			live_status:recipe_data.live_status
		})
		if (recipe_data.ingredients) {
			let quantity = {};
			let ingName = {};
			// let year = {};
			let _ingredientsArray = JSON.parse(recipe_data.ingredients);
			_ingredientsArray.map((value, key) => {
				key = key + 1;
				ingredients = [...ingredients, key];
				quantity[`quantity_${key}`] = value.quantity;
				ingName[`ingName_${key}`] = value.ingName;
				//   year[`year_${key}`] = parseInt(value.year);
				return true
			});
			Object.assign(this.state, quantity, ingName);//), year);
		}
	}

	handleSummaryEditorChange = (content, editor) => {
		this.setState({ summary: content })
	}

	handleDescEditorChange = (content, editor) => {
		this.setState({ description: content })
	}

	addRecepie = async (e) => {
		const { title, summary, description, editID, menu_item, prepration_time, cooking_time, serving_person, additional_time,live_status } = this.state;
		let response;
		if (this.validator.allValid()) {
			if (editID !== "") {
				response = await this.props.dispatch(RestaurantAddRecepie({ title, description, summary, recipeID: editID, menu_uniqueID: menu_item.value, prepration_time, cooking_time, serving_person, additional_time,live_status, ingredients: this.ingredientsArray() }, this));
			} else {
				response = await this.props.dispatch(RestaurantAddRecepie({ title, description, summary, menu_uniqueID: menu_item.value, prepration_time, cooking_time, serving_person, additional_time,live_status, ingredients: this.ingredientsArray() }));
			}
			if (response === 1) {
				this.props.history.push('/restaurant-recepies');
			}
		} else {
			this.validator.showMessages();
			this.forceUpdate()
		}
	}

	ingredientsArray = () => {
		const { ingredients } = this.state;
		let array = [];
		ingredients.map((value) => {
			let jsonobj = {
				quantity: this.state[`quantity_${value}`],
				ingName: this.state[`ingName_${value}`],
				// year: this.state[`year_${value}`],
			};
			return array.push(jsonobj);
		});
		return array;
	};

	onChangeHandler = e => {
		this.setState({ [e.target.name]: e.target.value });
		if (e.target.name === "image") {
			let file = window.$('#file_icon')[0].files;
			let recipe_images = [];
			for (let i = 0; i < file.length; i++) {
				let imageFile = URL.createObjectURL(file[i])
				recipe_images.push(imageFile);
			}
			this.setState({
				recipe_images: recipe_images
			})
		}
		else if (e.target.name === "video") {
			let videofile = window.$('#file_icon1')[0].files;
			let recipe_videos = [];
			for (let i = 0; i < videofile.length; i++) {
				let imageFile = URL.createObjectURL(videofile[i]);
				recipe_videos.push(imageFile);
			}
			this.setState({
				recipe_videos: recipe_videos
			})
		}
		// else if (e.target.name == "live_video") {
		// 	var file = window.$('#live_video')[0].files;
		// 	let live_videos = [];
		// 	for (let i = 0; i < file.length; i++) {
		// 		let videoFile = URL.createObjectURL(file[i]);
		// 		live_videos.push(videoFile);
		// 	}
		// 	this.setState({
		// 		live_video_data: live_videos
		// 	})
		// }
	}

	// openFileInput = e => {
	// 	this.fileinput.click()
	// }
	handleSelectChange = selectedOption => {
		this.setState({ menu_item: selectedOption });
	}

	imageDeleteHandler = () =>{
		this.setState({
			recipe_images:[]
		})
	}

	videoDeleteHandler = (value) =>{
		this.setState({
			recipe_videos:[]
		})

	}
	render() {
		const { menu_listing } = this.props.restaurant_data;
		const { title, summary, description, image, editID, video, menu_item, prepration_time, cooking_time, serving_person, additional_time, ingredients,live_status } = this.state;
		this.validator.purgeFields();
		let menu_option_array = []
		if (menu_listing && menu_listing.length > 0) {
			menu_listing.map((item, index) => {
				const objectnew = { label: item.name, value: item.uniquekey }
				menu_option_array.push(objectnew);
				return null;
			})
		}

		return (
			<div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">

				<div className="user-dashboard loader-holder">
					<div className="user-holder">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Title *</label>
								<input className="menu-item-title" id="title" name="title" value={title} onChange={e => this.onChangeHandler(e)} type="text"
									placeholder="Title" />
								{this.validator.message('title', title, 'required')}
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
							<div className="field-holder">
								{
									// <div class="add-recipe-div">
									// 	<input type="checkbox" id="add_menu" name="add_menu" checked={isChecked} onChange={e => this.onChangeHandler(e)}/>
									// 	<label class="control-label" for="add_menu"><a>Add To Menu</a></label>
									// 	<label class="control-label" for="price">Price</label>
									// 	<input class="menu-item-title" id="price" name="price" type="text" placeholder="price" onChange={e => this.onChangeHandler(e)} value={price} />
									// </div>
								}
								<label>Menu *</label>
								<Select id="menuItem" options={menu_option_array} onChange={this.handleSelectChange} value={menu_item} />
								{this.validator.message('menuItem', menu_item, 'required')}
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
							<div className="field-holder">
								<label>Preparation Time *</label>
								<input className="menu-item-title" id="prepration_time" name="prepration_time" value={prepration_time} onChange={e => this.onChangeHandler(e)} type="number"
									placeholder="Preparation Time" />
								{this.validator.message('Preparation Time', prepration_time, 'required')}
							</div>
						</div>

						<div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
							<div className="field-holder">
								<label>Cooking Time *</label>
								<input className="menu-item-title" id="cooking_time" name="cooking_time" value={cooking_time} onChange={e => this.onChangeHandler(e)} type="number"
									placeholder="Cooking Time" />
								{this.validator.message('Cook Time', cooking_time, 'required')}
							</div>
						</div>

						<div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
							<div className="field-holder">
								<label>Serving Person *</label>
								<input className="menu-item-title" id="serving_person" name="serving_person" value={serving_person} onChange={e => this.onChangeHandler(e)} type="number"
									placeholder="Serving Person" />
								{this.validator.message('serveTime', serving_person, 'required')}
							</div>
						</div>

						<div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
							<div className="field-holder">
								<label>Additional Time *</label>
								<input className="menu-item-title" id="additional_time" name="additional_time" value={additional_time} onChange={e => this.onChangeHandler(e)} type="number"
									placeholder="Additional Time" />
								{this.validator.message('Additional Time', additional_time, 'required')}
							</div>
						</div>

						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 add-ingredent-section">
							<div className="element-title has-border right-filters-row">
								<h5>My Recipes</h5>
							</div>
							{ingredients.map((value, key) => {
								return (
									<div
										className="row repeat-row"
										key={`Qual_${value}`}
									>
										<div className="col-md-4 doctor-setp-mid">
											<div className="form-group">
												<label htmlFor="exampleInputEmail1">
													Quantity
                                    </label>
												<input
													type="text"
													name={`quantity_${value}`}
													value={this.state[`quantity_${value}`]}
													className="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													onChange={(e) =>
														this.setState(
															{ [e.target.name]: e.target.value, errorMessages: [] }
														)
													}
													placeholder="quantity"
												/>
												{ingredients.includes(value)
													? this.validator.message(
														"quantity",
														this.state[`quantity_${value}`],
														"required"
													)
													: null}
											</div>
										</div>
										<div className="col-md-4 doctor-setp-mid">
											<div className="form-group">
												<label htmlFor="exampleInputEmail2">
													{" "}
													Ingredient Name
                                    </label>
												<input
													type="text"
													name={`ingName_${value}`}
													value={this.state[`ingName_${value}`]}
													className="form-control"
													id="exampleInputEmail2"
													aria-describedby="emailHelp"
													onChange={(e) =>
														this.setState(
															{ [e.target.name]: e.target.value, errorMessages: [] })
													}
													placeholder="Ingredient Name"
												/>
												{ingredients.includes(value)
													? this.validator.message(
														"ingName",
														this.state[`ingName_${value}`],
														"required"
													)
													: null}
											</div>
										</div>
										<div className="col-md-1 add-remove text-center align-self-center">
											{value > 1 && (
												<Link to="#">
													<i
														className="fas fa-minus-square"
														onClick={(e) =>
															this.setState(
																{
																	ingredients: ingredients.filter(
																		(val) => val !== value
																	),
																})
														}
													></i>
												</Link>
											)}
											{value < 10 && (
												<Link to="#">
													<i
														className="fas fa-plus-square"
														onClick={(e) =>
															this.setState(
																{
																	ingredients: [
																		...ingredients,
																		value + 1,
																	],
																})
														}
													></i>
												</Link>
											)}
										</div>
									</div>
								);
							})}
						</div>

						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Live Status *</label>
									<select className="menu-item-title" id="live_status" name="live_status" value={live_status} onChange={(e) => this.onChangeHandler(e)}>
											<option value="">Select</option>
											<option value="Y">Yes</option>
											<option value="N">No</option>
									</select>
								{this.validator.message('live_status', live_status, 'required|max:200,string')}
								{/* <Editor
									apiKey="xg6aofsienh3p2rx7w7qd63srneueilzfwumbic5cefxag6i"
									init={{
										height: 200,
										plugins: [
											"advlist autolink lists link image",
											"charmap print preview anchor help",
											"searchreplace visualblocks code",
											"insertdatetime media table paste wordcount",
										],
										menubar: 'file edit view insert format tools table help',
										toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
									}}
									value={this.state.summary}
									onEditorChange={this.handleSummaryEditorChange}
								/> */}
							</div>
						</div>
						{
							// live_status === "Y" && (
							// <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							// 	<div className="form-group row">
							// 		<label
							// 			className="col-md-7"
							// 			htmlFor="recipe_video"
							// 		>
							// 			Videos
	            //                 </label>
							// 		<div className="col-md-5">
							// 			<div id="file_icon-sec" className="browse-btn-sec" style={{ display: 'block !important' }}>
							// 				<input
							// 					type="file"
							// 					id="live_video"
							// 					ref={(input) => { this.livevideoFileinput = input; }}
							// 					name="live_video"
							// 					value={live_video}
							// 					onChange={this.onChangeHandler}
							// 					className="browse-menu-icon-file"
							// 					style={{ display: 'none' }}
							// 					accept="video/*"
							// 					multiple
							// 				/>
							// 				<i
							// 					className="fas fa-camera camera-ico"
							// 					onClick={() => {
							// 						this.livevideoFileinput.click();
							// 					}}
							// 				></i>
							// 				{editID === "" && live_status === "Y" ? this.validator.message('Live Video', live_video, 'required') : null}
							//
							// 			</div>
							// 		</div>
							// 		<div className="col-md-8">
							// 			{
							// 				this.state.live_video_data.map((value, index) => {
							// 					let vdoSrc = value;
							// 					if (editID) {
							// 						vdoSrc = bucketPath + vdoSrc
							// 					}
							// 					return (
							// 						<div key={"video_" + index} className="add-recepie-img-video">
							// 							<Link href="/">
							// 								<i className="fas fa-trash-alt del-profile-ico"></i>
							// 							</Link>
							// 							<video width="100">
							// 								<source src={`${vdoSrc}`} />
	            //                                             Your browser does not support HTML video.
	            //                                         </video>
							// 						</div>
							// 					)
							//
							// 				})
							// 			}
							// 		</div>
							// 	</div>
							// </div>
						//)
					}


						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Summary *</label>
								<textarea className="menu-item-title" id="summary" name="summary" value={summary} onChange={e => this.onChangeHandler(e)}
									placeholder="Summary" />
								{this.validator.message('summary', summary, 'required|max:200,string')}
								{/* <Editor
									apiKey="xg6aofsienh3p2rx7w7qd63srneueilzfwumbic5cefxag6i"
									init={{
										height: 200,
										plugins: [
											"advlist autolink lists link image",
											"charmap print preview anchor help",
											"searchreplace visualblocks code",
											"insertdatetime media table paste wordcount",
										],
										menubar: 'file edit view insert format tools table help',
										toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
									}}
									value={this.state.summary}
									onEditorChange={this.handleSummaryEditorChange}
								/> */}
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<div className="form-group row">
								<label
									className="col-md-7"
									htmlFor="recipe_image"
								>
									Images
									<small className="form-text allow-format-text" >Allowed JPG, GIF or PNG. Max size of 2MB</small>
                            </label>
								<div className="col-md-5">
									<div id="file_icon-sec" className="browse-btn-sec" style={{ display: 'block !important' }}>
										<input
											type="file"
											id="file_icon"
											ref={(input) => { this.fileinput = input; }}
											name="image"
											value={image}
											onChange={this.onChangeHandler}
											className="browse-menu-icon-file"
											style={{ display: 'none' }}
											accept="image/*"
											multiple
										/>
										<i
											className="fas fa-camera camera-ico"
											onClick={() => {
												this.fileinput.click();
											}}
										></i>
										{editID === "" ? this.validator.message('image', image, 'required') : null}
									</div>
								</div>
								<div className="col-md-8">
									{
										this.state.recipe_images.map((value, index) => {
											let imgSrc = ""

											if (value.includes("http://") === true || value.includes("https://") === true) {
												imgSrc = value;
											}
											else{
												imgSrc = `${bucketPath}${value}`
											}
											return (
												<div key={"img_" + index} className="add-recepie-img-video">
													<Link to="">
														<i className="fas fa-trash-alt del-profile-ico" onClick = {() =>this.imageDeleteHandler()}></i>
													</Link>
													<img alt="User" src={`${imgSrc}`} />
												</div>
											)

										})
									}
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<div className="form-group row">
								<label
									className="col-md-7"
									htmlFor="recipe_video"
								>
									Videos
                            </label>
								<div className="col-md-5">
									<div id="file_icon-sec" className="browse-btn-sec" style={{ display: 'block !important' }}>
										<input
											type="file"
											id="file_icon1"
											ref={(input) => { this.videoFileinput = input; }}
											name="video"
											value={video}
											onChange={this.onChangeHandler}
											className="browse-menu-icon-file"
											style={{ display: 'none' }}
											accept="video/*"
											multiple
										/>
										<i
											className="fas fa-camera camera-ico"
											onClick={() => {
												this.videoFileinput.click();
											}}
										></i>
										{
											// editID === "" ? this.validator.message('video', video, 'required') : null
										}

									</div>
								</div>
								<div className="col-md-8">
									{
										this.state.recipe_videos.map((value, index) => {
											let vdoSrc = ""

											if (value.includes("http://") === true || value.includes("https://") === true) {
												vdoSrc = value;
											}
											else{
												vdoSrc = `${bucketPath}${value}`
											}
											return (
												<div key={"video_" + index} className="add-recepie-img-video">
													<Link to="/">
														<i className="fas fa-trash-alt del-profile-ico" onClick={() =>this.videoDeleteHandler()}></i>
													</Link>
													<video width="100">
														<source src={`${vdoSrc}`} />
                                                        Your browser does not support HTML video.
                                                    </video>
												</div>
											)

										})
									}
								</div>
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Description *</label>
								<Editor
									apiKey="1ivg36dmtmtmhn742kx1fndzhnxxmn9wzvo5u5bwlv6kj4kn"
									init={{
										height: 200,
										plugins: [
											"advlist autolink lists link image",
											"charmap print preview anchor help",
											"searchreplace visualblocks code",
											"insertdatetime media table paste wordcount",
										],
										menubar: 'file edit view insert format tools table help',
										toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
									}}
									value={this.state.description}
									onEditorChange={this.handleDescEditorChange}
								/>
								{this.validator.message('description', description, 'required')}
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
							<div className="field-holder">
								<Link className="add-menu-item add-menu-item-list center-btn" to="#" onClick={e => this.addRecepie(e)}>Save Recepie
								</Link >
							</div>
						</div>
					</div>
				</div >
			</div >
		);
	}
}

const mapStateToProps = (state) => ({
	recipe_data: state.restaurant.recipe_data,
	restaurant_data: state.restaurant
});

export default connect(mapStateToProps)(PackageBuilder);
