import React, { Component } from "react";
import {Link} from 'react-router-dom'
import { connect } from 'react-redux';
import MapAutocomplete from '../../../gMaps/MapAutocomplete';
import Reviews from './Reviews';
class MamaInfo extends Component {
  formattedTime = (value) => {
    let Time;
    Time = value.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [Time];
    let startTime = ''
    if (Time.length > 1) {
         Time = Time.slice (1);
         Time[5] = +Time[0] < 12 ? 'AM' : 'PM';
         Time[0] = +Time[0] % 12 || 12;
         startTime =  Time.join ('');
    }
    return startTime
}
  render() {
    const {restaurant_view_profile } = this.props.restaurant_data;
    return (
      <>
      <div id="menu3" className="tab-pane fade">
      {restaurant_view_profile && restaurant_view_profile.data && Object.keys(restaurant_view_profile.data).length > 0 && (
          <div className="contact-info-detail">
            <h5>{`Overview ${restaurant_view_profile.data.resturants && restaurant_view_profile.data.resturants && Object.keys(restaurant_view_profile.data.resturants).length > 0 &&
            restaurant_view_profile.data.resturants.restaurant_name}`}</h5>
            <p className="restaurant-desc"></p>
            <p>
            {restaurant_view_profile.data.resturants && restaurant_view_profile.data.resturants && Object.keys(restaurant_view_profile.data.resturants).length > 0 &&
            restaurant_view_profile.data.resturants.info}
            </p>
            <div className="map-sec-holder">
              <div className="cs-map-section">
                <div className="cs-map">
                  <div className="cs-map-content">
                    <div className="mapouter">
                    {restaurant_view_profile.data.resturants && restaurant_view_profile.data.resturants && Object.keys(restaurant_view_profile.data.resturants).length > 0 &&
                    (
                      <div className="gmap_canvas mama-info-map">
                      {
                        // <iframe
                        //   width="100"
                        //   height="180"
                        //   id="gmap_canvas"
                        //   title="ABC"
                        //   src="https://maps.google.com/maps?q=university%20of%20san%20francisco&t=&z=13&ie=UTF8&iwloc=&output=embed"
                        // ></iframe>
                        // Google Maps Generator by{" "}
                        // <Link to="https://www.embedgooglemap.net">
                        //   embedgooglemap.net
                        // </Link>
                      }

                      <MapAutocomplete
                        center={{ lat: parseFloat(restaurant_view_profile.data.resturants.latitude), lng: parseFloat(restaurant_view_profile.data.resturants.longitude) }}
                        height='400px'
                        zoom={16}
                        FetchAddress={this.FetchAddress}
                      />
                      </div>
                    )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="contact-info">
                  <h5>Contact details</h5>
                  <p>{restaurant_view_profile.data.resturants && restaurant_view_profile.data.resturants && Object.keys(restaurant_view_profile.data.resturants).length > 0 &&
                  restaurant_view_profile.data.resturants.location}</p>
                  <ul>
                    <li className="cell">
                      <i className="icon-phone"></i>
                      <Link to="#">{restaurant_view_profile.data.resturants && restaurant_view_profile.data.resturants && Object.keys(restaurant_view_profile.data.resturants).length > 0 &&
                      restaurant_view_profile.data.resturants.phone}</Link>
                    </li>
                    <li className="cell">
                      <i className="icon-email"></i>
                      <Link to="#">{restaurant_view_profile.data.resturants && restaurant_view_profile.data.resturants && Object.keys(restaurant_view_profile.data.resturants).length > 0 &&
                      restaurant_view_profile.data.resturants.email}</Link>
                    </li>
                    {
                      // <li className="pizzaeast">
                      //   <i className="icon-globe2"></i>
                      //   <Link to="#">http://www.demo.com</Link>
                      // </li>
                    }

                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              {
                restaurant_view_profile.data.restaurantworking &&
                restaurant_view_profile.data.restaurantworking.length > 0 &&
                restaurant_view_profile.data.restaurantworking[0].restaurant_working_hour &&
              (
                <div className="widget widget-timing working-hours">
                  <h5>Opening Hours</h5>
                  <ul>
                  {(JSON.parse(restaurant_view_profile.data.restaurantworking[0].restaurant_working_hour)).map((item,index) =>(
                    <li key={index}>
                      <span>{item.day_name}</span>
                      {item.starttime !=="" && item.endtime !=="" ? this.formattedTime(item.starttime) + ' - '+ this.formattedTime(item.endtime) : (<span className="color-red">Closed</span>)}
                    </li>
                  ))}
                  </ul>
                </div>
              )}
              </div>
            </div>
          </div>

      )}

      <Reviews  review_token ={this.props.review_token} restaurant_data = {this.props.restaurant_data}/>
      </div>
    </>
    );
  }
}
const mapStateToProps = (state) => ({
  restaurant_data: state.restaurant,
});
export default connect(mapStateToProps)(MamaInfo);
