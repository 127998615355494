import * as types from "./../types/types";

const initalState = {
    data: [],
    user_profile:[],
    order_list:[],
    payment_list:[]

}

const BuyerReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_USER_PROFILE:
            return {
                ...state,
                user_profile: action.payload
            }
        case types.ORDER_LIST:
            return {
                ...state,
                order_list: action.payload
            }
            case types.PAYMENT_LIST:
                return {
                    ...state,
                    payment_list: action.payload
                }
        
        default:
            return state
    }
}

export default BuyerReducer;
