// @ts-nocheck
import React, { PureComponent } from 'react';
import { bucketPath } from './../../store/helpers/common';
import {
  foodProfile,ForceFoodloginClick,addFoodCenterReview,foodReviewListing,foodcenterlikeAndDislike,foodcenterlikeAndDislikeListing
} from './../../store/actions/foodActions';

import * as RestaurantAction from './../../store/actions/restaurantActions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MapAutocomplete from './../gMaps/MapAutocomplete';
import Modals from './../../modules/Html/Modals';
import ModalHeader from './../../modules/Html/ModalHeader';
import ModalBody from './../../modules/Html/ModalBody';
import SimpleReactvalidator from 'simple-react-validator'
import swal from 'sweetalert';
import {
  FacebookIcon,FacebookShareButton,TwitterIcon,LinkedinIcon,LinkedinShareButton,TwitterShareButton
} from 'react-share'

class FoodInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageOfItems: [],
      currentPage: 1,
      cartItems: [],
      categoryID: '',
      tab_name: 'home',
      open:false,
      selectedimageIndex:0,
      modal_type:"",
      title: '',
      description: '',
      rating: 1,
      category_state:false,
      website_link_state:false
    };
    this.validator = new SimpleReactvalidator();
  }

  componentDidMount() {
    let uniqueID = this.props.match.params.token;
    let foodcenterID = this.props.match.params.id;
    const data = {
      "foodcenterID":foodcenterID
    }
    this.props.dispatch(foodProfile(uniqueID));
    this.props.dispatch(foodReviewListing(data));
    this.props.dispatch(foodcenterlikeAndDislikeListing({"foodcenterID":foodcenterID}))



  }
  openReviewModal = () => {
    const getUserIdentity = localStorage.getItem('token');
    if (!getUserIdentity) {
      swal({
        text: "You need to login to write Review!",
        icon: "info",
        buttons: true,
        dangerMode: true,
      })
        .then((yesDo) => {
          if (yesDo) {
            this.props.dispatch(RestaurantAction.ForceloginClick(true))
          } else {
            console.log("Record is Safe");
          }
        });
      return false;
    }
    this.setState({
      open: true,
      modal_type:"add_review"
    })
  }

  openModal = (value) =>{
    this.setState({
      open: true,
      selectedimageIndex:value,
      modal_type:"images_show"
    })
  }

  CloseModal = e => {
    this.setState({
      open: false,
      selectedimageIndex:0,
      modal_type:""
    })
  }

  onRadioChangeHandler = e => {
    this.setState({ rating: e.target.value });
  }

  onChangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  addRivew = async (e) => {
    const { title, description, rating } = this.state;
    let foodcenterID = this.props.food_profile && this.props.food_profile.data && this.props.food_profile.data.foodcenter && this.props.food_profile.data.foodcenter.foodcenterID ? this.props.food_profile.data.foodcenter.foodcenterID  : "";
    if (this.validator.allValid()) {
      let response = await this.props.dispatch(addFoodCenterReview({ title, description, points: (rating * 20), foodcenterID }));
      if (response === 1) {
        this.CloseModal();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate()
    }
  }

  likeDislikeHandler = (value) =>{
    const getUserIdentity = localStorage.getItem('token');
    if (!getUserIdentity) {
      swal({
        text: "You need to login to Like Review!",
        icon: "info",
        buttons: true,
        dangerMode: true,
      })
        .then((yesDo) => {
          if (yesDo) {
            this.props.dispatch(RestaurantAction.ForceloginClick(true))
          } else {
            console.log("Record is Safe");
          }
        });
      return false;
    }
    else{
      let foodcenterID = this.props.match.params.id;
      const data = {
        reviewID:value,
        foodcenterID:foodcenterID,
        status:"Like"
      }
      this.props.dispatch(foodcenterlikeAndDislike(data));
    }

  }

  categoryHandler = () =>{
    this.setState({
      category_state:!this.state.category_state
    })
  }

  render() {
    const { title, description, rating ,modal_type} = this.state;
    const { food_review_rating,food_like_and_dislike_list } = this.props
    //const { food_profile } = this.props
    const foodcenter = this.props.food_profile && this.props.food_profile.data && Object.keys(this.props.food_profile.data).length > 0  && this.props.food_profile.data.foodcenter ? this.props.food_profile.data.foodcenter : {};
    const foodcenter_other_information = this.props.food_profile && this.props.food_profile.data && Object.keys(this.props.food_profile.data).length > 0 && this.props.food_profile.data.foodcenter_other_information ? this.props.food_profile.data.foodcenter_other_information : [];
    const image = foodcenter && Object.keys(foodcenter).length > 0 && foodcenter.other_images ? foodcenter.other_images.split(",") : []
    // const video = foodcenter && Object.keys(foodcenter).length > 0 && foodcenter.video ? foodcenter.video.split(",") : []
    const building = foodcenter && Object.keys(foodcenter).length > 0 && foodcenter.building !== null && foodcenter.building !== "" ? foodcenter.building + "," : "";
    const city = foodcenter && Object.keys(foodcenter).length > 0 && foodcenter.city !== null && foodcenter.city !== ""  ? foodcenter.city + "," : "";
    const state = foodcenter && Object.keys(foodcenter).length > 0 && foodcenter.state !== null && foodcenter.state !== "" ? foodcenter.state + "," : "";
    const country = foodcenter && Object.keys(foodcenter).length > 0 && foodcenter.country !== null && foodcenter.country !== "" ? foodcenter.country  : "";
    // const pincode = foodcenter && Object.keys(foodcenter).length > 0 && foodcenter.zipcode !== null && foodcenter.zipcode !== "" ? foodcenter.zipcode : "";
    return (
      <>
      <div className='main-section'>
        <div
          className='page-section restaurant-detail-image-section'
          style={{
            background:
              `url(${foodcenter && Object.keys(foodcenter).length > 0 &&
              foodcenter.cover_image ? bucketPath + foodcenter.cover_image : "/assets/extra-images/cover-photo01.jpg"}) no-repeat scroll 0 0 / cover`,
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <div className='company-info-detail'>
                  <div className='company-info'>
                    <div className='img-holder'>
                      <figure>
                        <img
                        src={foodcenter && Object.keys(foodcenter).length > 0 &&
                        foodcenter.profile_image ? bucketPath + foodcenter.profile_image : "../assets/extra-images/food_center.png"}
                          alt=''
                        />
                      </figure>
                    </div>
                    <div className='text-holder'>
                      <div className='rating-star'>
                        <span className='rating-box'
                          style={{ width: '100%' }}
                        ></span>
                      </div>
                      <span className='reviews'>(1 Reviews)</span>
                      <span className='restaurant-title'>
                      {
                        foodcenter && Object.keys(foodcenter).length > 0 &&
                        ((foodcenter.firstname)||(foodcenter.lastname)) &&
                        foodcenter.firstname + " " + foodcenter.lastname
                      }
                      </span>
                      <div className='text'>
                        <i className='icon-local_pizza'></i>
                        <p>
                        {
                          foodcenter && Object.keys(foodcenter).length > 0 &&
                          foodcenter.restaurant_name &&
                          foodcenter.restaurant_name
                        }
                        </p>
                      </div>
                    </div>
                    <div className="agro-login-option">
                      <Link to="#"
                      onClick={(e) => this.props.dispatch(ForceFoodloginClick(true))}>
                      Login
                      </Link>

                      {
                        //<a class="" href="/">Sign Up</a>
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='page-section'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12 sticky-sidebar'>
                <div className='filter-toggle' onClick={() =>this.categoryHandler()}>
                  <span className='filter-toggle-text'>Categories By</span>
                  <i className='icon-chevron-down'></i>
                </div>
                <div className={`${this.state.category_state ? "" : "filter-wrapper"}`}>
                  <div className='categories-menu'>
                  {
                    // <h6>
                    //   Test
                    // </h6>
                  }
                    <div className="agro-left-profile-bx">
                      <ul className="agro-leftside-images">
                      {image && image.length > 0 ? image.map((item,index) =>(

                        <li key={index}>
                          <Link to="#">
                            <div className="img-holder">
                              <figure>
                                <img src={bucketPath + image[index]} alt="" onClick = {() =>this.openModal(index)}/>
                              </figure>
                            </div>
                          </Link>
                        </li>
                      )) :null}

                        {
                          // <li><a href="#"> <div className="img-holder"><figure><img src="../assets/extra-images/add-img.png" alt=""/></figure></div> </a></li>
                        }

                      </ul>
                          <div className="agro-addres">
                            <p>
                             <i className="icon-location-pin"></i>
                            <span>
                            {
                              building + city + state + country
                            }
                            </span>
                           </p>
                          </div>
                          <div className="agro-phone-num">
                            <p>
                            <i className="icon-phone4"></i>
                            <span>
                            {
                              foodcenter && Object.keys(foodcenter).length > 0 &&
                              foodcenter.phone &&
                              foodcenter.phone
                            }
                            </span>
                            </p>
                          </div>
                          <div className="agro-add-adress">
                          {
                          //   <p>
                          //
                          // <i className="icon-plus-circle"></i>
                          //     <span>dummy text</span>
                          //   </p>
                          }
                          {
                          // <>
                          // {foodcenter_other_information && foodcenter_other_information.length > 0 ? (
                          //   <div className='widget widget-timing agro-widget-timing'>
                          //     <h5>Opening Hours</h5>
                          //     <ul>
                          //     {foodcenter_other_information.map((item,index) =>(
                          //       <li key={index}>
                          //         <span>{item.day}</span>
                          //         {item.starttime !=="" && item.endtime !=="" ? item.starttime + ' - '+ item.endtime : "Off"}
                          //       </li>
                          //     ))}
                          //     </ul>
                          //   </div>
                          // ):null}
                          // </>
                        }
                          </div>

                          <div className="agro-add-adress">
                          {
                          //   <p>
                          //
                          // <i className="icon-plus-circle"></i>
                          //     <span>dummy text</span>
                          //   </p>
                          }
                          {
                            // <>
                            // <div className='widget widget-timing agro-widget-social-link'>
                            //   <h5 onClick={() =>this.setState({website_link_state:!this.state.website_link_state})}>Websit Links</h5>
                            //   <ul className={`${this.state.website_link_state ? "" : "website-links-display"}`}>
                            //   {
                            //   // <>
                            //   // {agrocenter && Object.keys(agrocenter).length > 0 &&
                            //   //   agrocenter.phone && (
                            //   //     <li className='cell'>
                            //   //     <a href='#'>
                            //   //         <i className='icon-phone'></i>
                            //   //         {agrocenter.phone}
                            //   //         </a>
                            //   //     </li>
                            //   //   )}
                            //   //   </>
                            //   }
                            //     { foodcenter && Object.keys(foodcenter).length > 0 &&
                            //       foodcenter.website ? (
                            //         <li className='pizzaeast'>
                            //         <a href={foodcenter.website}>
                            //           <i className='icon-globe2'></i>
                            //             {foodcenter.website}
                            //           </a>
                            //         </li>
                            //       ):null}
                            //     { foodcenter && Object.keys(foodcenter).length > 0 &&
                            //       foodcenter.twitter && (
                            //         <li className='pizzaeast'>
                            //           <a href={foodcenter.twitter}>
                            //           <i className='icon-twitter'></i>
                            //             {foodcenter.twitter}
                            //           </a>
                            //         </li>
                            //       )}
                            //
                            //     { foodcenter && Object.keys(foodcenter).length > 0 &&
                            //       foodcenter.facebook && (
                            //         <li className='pizzaeast'>
                            //           <a href={foodcenter.facebook}>
                            //           <i className='icon-facebook'></i>
                            //           {foodcenter.facebook}
                            //           </a>
                            //         </li>
                            //       )}
                            //
                            //     { foodcenter && Object.keys(foodcenter).length > 0 &&
                            //       foodcenter.instagram && (
                            //         <li className='pizzaeast'>
                            //           <a href={foodcenter.instagram}>
                            //           <i className='icon-instagram'></i>
                            //             {foodcenter.instagram}
                            //           </a>
                            //         </li>
                            //       )}
                            //
                            //     {foodcenter && Object.keys(foodcenter).length > 0 &&
                            //       foodcenter.email && (
                            //         <li className='email'>
                            //           <a className='text-color' href='#'>
                            //           <i className='icon-mail5'></i>
                            //           {foodcenter.email}
                            //           </a>
                            //         </li>
                            //       )}
                            //
                            //   </ul>
                            //
                            // </div>
                            // </>
                          }

                          </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-7 col-md-7 col-sm-12 col-xs-12'>
                <div className='tabs-holder horizontal'>
                  <ul className='stickynav-tabs nav nav-tabs'>
                    <li className="active">
                      <a data-toggle='tab' href='#menu2'>
                        <i className='far fa-frown-open'></i>Review and Rating
                      </a>
                    </li>
                    <li>
                      <a data-toggle='tab' href='#menu3'>
                        <i className='icon- icon-info3'></i>Food Center
                      </a>
                    </li>
                    <li>
                      <a data-toggle='tab' href='#menu1'>
                        <i className='icon- icon-video2 '></i>video
                      </a>
                    </li>
                  </ul>
                  <div className='tab-content'>
                    <div id='menu2' className='tab-pane agro-image-tab fade fade in active'>
                      <div className="listing simple agro-reviewtab-div review-rating-tab-area">
                      {
                      // <ul className="agro-leftside-images">
                      // {image && image.length > 0 ? image.map((item,index) =>(
                      //
                      //   <li key={index}>
                      //     <a href="#">
                      //       <div className="img-holder">
                      //         <figure>
                      //           <img src={bucketPath + image[index]} alt="" onClick = {() =>this.openModal(index)}/>
                      //         </figure>
                      //       </div>
                      //     </a>
                      //   </li>
                      // )) :null}
                      //
                      //   {
                      //     // <li><a href="#"> <div className="img-holder"><figure><img src="../assets/extra-images/add-img.png" alt=""/></figure></div> </a></li>
                      //   }
                      //
                      // </ul>
                    }

                        <ul>
                          {food_review_rating && food_review_rating.length > 0 ? food_review_rating.map((item,index)=>{
                            const like_dislike_data = food_like_and_dislike_list && food_like_and_dislike_list.length > 0 ?
                                                      food_like_and_dislike_list.filter(filterdata =>filterdata.reviewID === item.id):[];
                            return(
                              <li key={index}>
                                <div className="img-holder">
                                  <figure>
                                    <Link to="#">
                                      <img src={bucketPath + item.profile_pic} alt="" />
                                    </Link>
                                  </figure>
                                </div>
                                <div className="text-holder">
                                  <div className="post-title">
                                    <h5><Link to="#">{item.title}</Link></h5>
                                  </div>
                                  <div className="list-rating">
                                    <div className="rating-star">
                                      <span className="rating-box" style={{ width: item.points + "%" }}></span>
                                    </div>
                                    {
                                      //<span className="reviews">(1)</span>
                                    }
                                  </div>
                                  <div className="delivery-potions">
                                    <span>{item.lastupdate.split(" ")[0]}</span>
                                  </div>
                                  <div className="delivery-potions">
                                    <span>{item.description}</span>
                                  </div>
                                  {/*  */}
                                  <div className=" like-share-div">
                                    <div className="col-md-4">
                                      <Link to="#" onClick={() =>this.likeDislikeHandler(item.id)}><i className={`fas fa-thumbs-up ${like_dislike_data && like_dislike_data.length > 0 && like_dislike_data[0].status === "Like" ? "like-color" : ""}`}></i>Like</Link>
                                    </div>
                                      {
                                        // <div className="col-md-4 text-center">
                                        //   <a><i className="fas fa-comment"></i>Comment</a>
                                        // </div>
                                      }


                                        <div className="col-md-4 text-right share-div"><Link to="#" className="share-link dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-share"></i>Share</Link>
                                          <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                                            <li>
                                            <FacebookShareButton url={`https://www.facebook.com/`}>
                                              <FacebookIcon  size={32} round={true}/>
                                            </FacebookShareButton>
                                              </li>
                                            <li>
                                            <TwitterShareButton url={`https://twitter.com/`}>
                                              <TwitterIcon  size={32} round={true}/>
                                            </TwitterShareButton>
                                            </li>
                                            <li>
                                            <LinkedinShareButton url={`https://www.linkedin.com/`}>
                                              <LinkedinIcon  size={32} round={true}/>
                                            </LinkedinShareButton>
                                            </li>
                                          </ul>
                                          </div>

                                          {
                                            // <div className="col-md-4 text-right">
                                            //   <a><i className="fas fa-share"></i>Share</a>
                                            // </div>
                                          }


                                  </div>
                                  {/*  */}
                                </div>
                              </li>
                            )
                          }):null}

                        </ul>

                        <div className="col-md-12 text-center review-btn-div">
                          <Link to="#" className="write-review-btn" onClick={() =>
                            this.openReviewModal()
                          }>Write Review</Link>
                        </div>
                      </div>
                    </div>
                    <div id='menu3' className='tab-pane fade '>
                      <div className='contact-info-detail'>

                       <>
                        <h5>{`Overview ${foodcenter && Object.keys(foodcenter).length > 0 &&
                        ((foodcenter.firstname)||(foodcenter.lastname)) &&
                        foodcenter.firstname + " " + foodcenter.lastname}`}</h5>
                        <p className='restaurant-desc'></p>
                        {
                          // <p>
                          //   Base prepared fresh daily. Extra toppings are
                          //   available in choose extraChoose you sauce: Go for BBQ
                          //   sauce or piri piri sauce on your pizza base for no
                          //   extra cost.Choose your cut: Triangular, square,
                          //   fingers or Un cut on any size pizza
                          // </p>
                        }

                        <div className='map-sec-holder'>
                          <div className='cs-map-section'>
                            <div className='cs-map'>
                              <div className='cs-map-content'>
                                <div className='mapouter'>
                                {
                                  foodcenter && Object.keys(foodcenter).length > 0 && foodcenter.latitude !=="" && foodcenter.longitude !== "" && (
                                    <div className='gmap_canvas mama-info-map'>
                                    <MapAutocomplete
                                      center={{ lat: parseFloat(foodcenter.latitude), lng: parseFloat(foodcenter.longitude) }}
                                      height='400px'
                                      zoom={16}
                                      FetchAddress={this.FetchAddress}
                                    />
                                    </div>
                                  )
                                }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                            <div className='contact-info'>
                              <h5>Contact details</h5>
                              <p>{
                                building + city + state + country
                              }</p>
                              <ul>
                              {foodcenter && Object.keys(foodcenter).length > 0 &&
                                foodcenter.phone && (
                                  <li className='cell'>
                                    <i className='icon-phone'></i>
                                    <Link to='#'>{foodcenter.phone}</Link>
                                  </li>
                                )}
                                { foodcenter && Object.keys(foodcenter).length > 0 &&
                                  foodcenter.website ? (
                                    <li className='pizzaeast'>
                                      <i className='icon-globe2'></i>
                                      <a href={foodcenter.website}>
                                        {foodcenter.website}
                                      </a>
                                    </li>
                                  ):null}
                                { foodcenter && Object.keys(foodcenter).length > 0 &&
                                  foodcenter.twitter && (
                                    <li className='pizzaeast'>
                                      <i className='icon-twitter'></i>
                                      <a href={foodcenter.twitter}>{
                                        foodcenter.twitter}</a>
                                    </li>
                                  )}

                                { foodcenter && Object.keys(foodcenter).length > 0 &&
                                  foodcenter.facebook && (
                                    <li className='pizzaeast'>
                                      <i className='icon-facebook'></i>
                                      <a href={foodcenter.facebook}>
                                      {foodcenter.facebook}
                                      </a>
                                    </li>
                                  )}

                                { foodcenter && Object.keys(foodcenter).length > 0 &&
                                  foodcenter.instagram && (
                                    <li className='pizzaeast'>
                                      <i className='icon-instagram'></i>
                                      <Link to={foodcenter.instagram}>{foodcenter.instagram}</Link>
                                    </li>
                                  )}

                                {foodcenter && Object.keys(foodcenter).length > 0 &&
                                  foodcenter.email && (
                                    <li className='email'>
                                      <i className='icon-mail5'></i>
                                      <Link className='text-color' to='#'>
                                      {
                                        foodcenter.email}
                                      </Link>
                                    </li>
                                  )}

                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                          {foodcenter_other_information && foodcenter_other_information.length > 0 ? (
                            <div className='widget widget-timing'>
                              <h5>Opening Hours</h5>
                              <ul>
                              {foodcenter_other_information.map((item,index) =>(
                                <li key={index}>
                                  <span>{item.day}</span>
                                  {item.starttime !=="" && item.endtime !=="" ? item.starttime + ' - '+ item.endtime : "Off"}
                                </li>
                              ))}
                              </ul>
                            </div>
                          ):null}

                          </div>
                        </div>
                        </>
                      </div>
                    </div>
                    <div id='menu1' className='tab-pane fade'>
                      <div className="contact-info-detail">
                      {

                      // <>
                      // {video && video.length > 0 ? (
                      //   <div className="col-md-12 modal-video">
                      //     <video width="320" height="240" controls>
                      //       <source src={`${bucketPath}${video[0]}`}>
                      //       </source>
                      //       Your browser does not support the video tag.
                      //     </video>
                      //   </div>
                      // ): <p>
                      //   There is no content
                      //   </p>}
                      //   </>
                      }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="sticky-sidebar col-lg-3 col-md-3 col-sm-12 col-xs-12">
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Modals open={this.state.open} className="modal-size">
        <ModalHeader CloseModal={this.CloseModal}  title={modal_type === "add_review" ? "Add Review" : ""}/>
        <ModalBody>
        {modal_type === "images_show" ? (
          <div className="row">
          <figure className="col-md-12 main-post agro-info-md">
                            {/* <a href="#"><img src="assets/extra-images/blogs-food-bakery-02-750x422.jpg" alt="" /></a> */}
                            <div
                              id='carousel-example-generic'
                              className='carousel slide'
                              data-ride='carousel'
                              data-interval="false"
                            >
                            {
                              // <ol className="carousel-indicators">
                              //   {CombinedImage?.split(',')?.map(
                              //     (imgVal, imgIndex) => {
                              //       return (
                              //         <li key={"dot_" + imgIndex} data-target="#carousel-example-generic" data-slide-to={`${imgIndex}`} className=""></li>
                              //       )
                              //     })
                              //   }
                              // </ol>
                            }

                              {/* <!-- Wrapper for slides --> */}
                              <div
                                className='carousel-inner'
                                role='listbox'
                              >

                                {image && image.length > 0 && image.map(
                                  (imgVal, imgIndex) => {
                                    return (
                                      <div className={`item ${imgIndex === this.state.selectedimageIndex ? "active" : ""}`} key={`item_${imgIndex}`}>
                                        <Link to='#'>
                                            <img
                                              src={`${bucketPath}${imgVal}`}
                                              alt=''
                                            />
                                        </Link>
                                      </div>
                                    );
                                  }
                                )}
                              </div>

                              {/* <!-- Controls --> */}
                              <a
                                className='left carousel-control'
                                href='#carousel-example-generic'
                                role='button'
                                data-slide='prev'
                              >
                                <span
                                  className='icon-arrows-1'
                                  aria-hidden='true'
                                ></span>
                                <span className='sr-only'>Previous</span>
                              </a>
                              <a
                                className='right carousel-control'
                                href='#carousel-example-generic'
                                role='button'
                                data-slide='next'
                              >
                                <span
                                  className='icon-arrows'
                                  aria-hidden='true'
                                ></span>
                                <span className='sr-only'>Next</span>
                              </a>
                            </div>
                          </figure>
          </div>
        ): modal_type === "add_review" ? (
          <div className="order-detail-inner">
            <div className="reviews-holder">
              <div className="add-new-review-holder add-new-review-2067">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h3>Rate and Write a Review</h3>
                  </div>
                  <div className="foodbakery-add-review-data">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div
                        className="total-rating user-rating-container overall-rating"
                        data-overall-rating="1"
                      >
                        <h6>Rating</h6>
                        <div className="rating-star">
                          <input
                            id="star-13"
                            type="radio"
                            name="rating1"
                            value="1"
                            className="star-1"
                            defaultChecked={rating === "1" || rating === "2" || rating === "3" || rating === "4" || rating === "5" ? "checked" : ""}//"checked"
                            onClick={e => this.onRadioChangeHandler(e)}
                          />
                          <label className="star-13" htmlFor="star-13">
                            1
                          </label>
                          <input
                            id="star-23"
                            type="radio"
                            name="rating2"
                            value="2"
                            className="star-2"
                            defaultChecked={rating === "2" || rating === "3" || rating === "4" || rating === "5" ? "checked" : ""}//"checked"
                            onClick={e => this.onRadioChangeHandler(e)}
                          />
                          <label className="star-2" htmlFor="star-23">
                            2
                          </label>
                          <input
                            id="star-33"
                            type="radio"
                            name="rating3"
                            value="3"
                            className="star-3"
                            defaultChecked={rating === "3" || rating === "4" || rating === "5" ? "checked" : ""}//"checked"
                            onClick={e => this.onRadioChangeHandler(e)}
                          />
                          <label className="star-3" htmlFor="star-33">
                            3
                          </label>
                          <input
                            id="star-43"
                            type="radio"
                            name="rating4"
                            value="4"
                            className="star-4"
                            defaultChecked={rating === "4" || rating === "5" ? "checked" : ""}//"checked"
                            onClick={e => this.onRadioChangeHandler(e)}
                          />
                          <label className="star-4" htmlFor="star-43">
                            4
                          </label>
                          <input
                            id="star-53"
                            type="radio"
                            name="rating5"
                            value="5"
                            className="star-5"
                            defaultChecked={rating === "5" ? "checked" : ""}//"checked"
                            onClick={e => this.onRadioChangeHandler(e)}
                          />
                          <label className="star-5" htmlFor="star-53">
                            5
                          </label>
                          <span style={{ width: (rating * 20) + "%" }}></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-element">
                        <i className="icon-edit2"></i>
                        <input
                          type="text"
                          placeholder="Title of your review *"
                          value={title}
                          name="title"
                          onChange={e => this.onChangeHandler(e)}
                        />
                        {this.validator.message('title', title, 'required')}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-element">
                        <textarea
                          placeholder="Tell about your experience or leave a tip for others"
                          cols="30"
                          rows="10"
                          maxLength="500"
                          name="description"
                          value={description}
                          onChange={e => this.onChangeHandler(e)}
                        ></textarea>
                        {this.validator.message('description', description, 'required|min:10')}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-element message-length">
                        <span>Min characters: 10</span>
                        <span>Max characters: 500</span>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-element">
                        <input
                          type="button"
                          defaultValue="Submit your Review"
                          onClick={e => this.addRivew(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ):null}

        </ModalBody>
      </Modals>
      </>
    );
  }
}

const importStateFromProps = (state) => ({
  restaurant_data: state.restaurant,
  mama_cart: state.cart.mama_cart,
  food_profile : state.food.food_profile,
  food_review_rating : state.food.food_review_rating,
  food_like_and_dislike_list : state.food.food_like_and_dislike_list
});

export default connect(importStateFromProps)(FoodInfo);
