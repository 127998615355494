import React,{useEffect} from "react";

const Modals = (props) => {

  useEffect(() => {
    if(props.open){
      window.$('#my_common_modal').modal({show:true})
    }else{
      window.$('#my_common_modal').modal('hide')
    }
}, [props.open])
  return (
    <div className={`modal fade`} id="my_common_modal" aria-labelledby="myModalLabel" data-backdrop="static">
      <div className={`modal-dialog modal-${props.size ? props.size : "lg"}`}>
        <div className="login-form">
          <div className="modal-content">
            <div className="tab-content">
              <div id="user-login-tab" className="tab-pane fade in active">                
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modals;
