export const userType = () => {
    let type = localStorage.getItem('type');
    if(type){
        return type==='U'?"User":"Mama";
    }else{
        return 'Guest';
    }
}

export const userData = () => {
    let data = localStorage.getItem('data');
    if(data){
        return JSON.parse(data);
    }else{
        return 'Guest';
    }
}

export const userUniqueID = () => {
    let data = localStorage.getItem('data');
    if(data){
        return JSON.parse(data)['uniqueID'];
    }else{
        return 'Guest';
    }
}