import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MapAutocomplete from "./../gMaps/MapAutocomplete";
import MapModal from "./../modals/MapModal";
import {profileInfo,UserProfileUpdate} from './../../store/actions/buyerActions';
import {UploadProfileImage} from './../../store/actions/userActions';
import {bucketPath} from './../../store/helpers/common'
import SimpleReactValidator from "simple-react-validator";
import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber, getCountryCallingCode, } from "react-phone-number-input";

const initialState = {firstname:'',lastname:'',email:"",phone:"+91",country:'',address:'',location:'',longitude:'',latitude:'',zipcode:'',state:'',city:'',prefix:'',profile_pic:'',correspondence_address:[],addMoreAddress:false,modalType:""}


class BuyerAccountSetting extends Component {
    constructor(props) {
        super(props);
        this.formValidator = new SimpleReactValidator();
        this.state = initialState;
      }

    componentDidMount(){
        this.props.dispatch(profileInfo())
    }

    onChangeHandler = e => {
        this.setState({[e.target.name]:e.target.value})
    }

    openModal = (value) => {
        this.setState({
          openMap: true ,
          modalType:value
        });
      };

      closeModal = () => {
        this.setState({
          openMap: false ,
          modalType:""
        });
      };

      FetchAddress = (data) => {
        const location = `${data.area}, ${data.state}${data.zip ? `, ${data.zip}` : ''}`;
        this.setState({
        country: data.country,
        location: location,
        longitude:data.lngValue,
        latitude:data.latValue,
        city:data.city,
        state:data.state,
        zipcode:data.zip
        });
    }

    FetchAddress2 = (data) => {
      const location = `${data.area}, ${data.state}${data.zip ? `, ${data.zip}` : ''}`;
      this.setState({
      country2: data.country,
      location2: location,
      longitude2:data.lngValue,
      latitude2:data.latValue,
      city2:data.city,
      state2:data.state,
      zipcode2:data.zip
      });
  }

    componentDidUpdate = (prevProps, prevState) => {
        let nextProps = this.props;
        if(nextProps.profile !== prevProps.profile){
          let correspondence_address_new_data = []
          if(nextProps && nextProps.profile && nextProps.profile.correspondence_address != null){
            correspondence_address_new_data = nextProps && nextProps.profile && nextProps.profile.correspondence_address && JSON.parse(nextProps.profile.correspondence_address)
          }
          this.setState({
            firstname : nextProps?.profile?.firstname,
            lastname: nextProps?.profile?.lastname,
            email:nextProps?.profile?.email,
            phone:nextProps?.profile?.phone,
            prefix:nextProps?.profile?.prefix,
            country:nextProps?.profile?.country,
            location:nextProps?.profile?.location,
            address:nextProps?.profile?.address,
            zipcode:nextProps?.profile?.zipcode,
            state:nextProps?.profile?.state,
            longitude:nextProps?.profile?.longitude,
            latitude:nextProps?.profile?.latitude,
            city:nextProps?.profile?.city,
            profile_pic:nextProps?.profile?.profile_pic,
            correspondence_address:correspondence_address_new_data
          })

        }
      }

      numberChange = (number) => {
        let phoneNumber = number ? parsePhoneNumber(number.toString()) : "";
        let prefix = "";
        if (phoneNumber) {
          if (phoneNumber.country) {
            if (getCountryCallingCode(phoneNumber.country)) {
              prefix = getCountryCallingCode(phoneNumber.country);
            }
          }
        }
        this.setState({ phone: number, prefix: prefix });
      };

    onSubmitHandler = async() => {
        const validator = this.formValidator;
          const data = {
            firstname:this.state.firstname,
            lastname:this.state.lastname,
            email:this.state.email,
            phone:this.state.phone,
            country:this.state.country,
            address:this.state.address,
            location:this.state.location,
            longitude:this.state.longitude,
            latitude:this.state.latitude,
            zipcode:this.state.zipcode,
            state:this.state.state,
            city:this.state.city,
            prefix:this.state.prefix,
            correspondence_address:this.state.correspondence_address
          }
        if(validator.allValid()){
          const response = await this.props.dispatch(UserProfileUpdate(data));
          if(response===1){
              this.setState(initialState)
              this.props.dispatch(profileInfo())
          }
        }else{
            validator.showMessages();
            this.forceUpdate();
        }
    }

    ProfileImageUpload = () => {
        this.props.dispatch(UploadProfileImage())
    }

    addMoreAddressHandler = () =>{
      this.setState({
        addMoreAddress:!this.state.addMoreAddress
      },() =>{
      })
    }


  render() {
    return (
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div className="user-dashboard loader-holder">
            <div className="user-holder">
                <div className="user-profile">
                    <div className="element-title has-border">
                        <h5>Account Settings</h5>
                    </div>
                    <div className="row">
                        <form id="publisher_profile">
                            <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="field-holder">
                                            <label>First Name*</label>
                                            <input type="text" className="foodbakery-dev-req-field"  name="firstname" value={this.state.firstname} onChange={this.onChangeHandler.bind(this)} />
                                            {this.formValidator.message( "firstname", this.state.firstname, "required|max:50,string" )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="field-holder">
                                            <label>Last Name*</label>
                                            <input type="text" className="foodbakery-dev-req-field"  name="lastname" value={this.state.lastname} onChange={this.onChangeHandler.bind(this)} />
                                            {this.formValidator.message( "lastname", this.state.lastname, "required|max:50,string" )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="field-holder">
                                            <label>Email Address *</label>
                                            <input type="text" className="foodbakery-dev-req-field" name="email" value={this.state.email} onChange={this.onChangeHandler.bind(this)} disabled={true} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="field-holder">
                                            <label>Phone Number *</label>
                                            <PhoneInput
                                                name="phone"
                                                placeholder="Enter phone number"
                                                value={this.state.phone}
                                                onChange={(number) => this.numberChange(number)}
                                                defaultCountry="IN"
                                                disabled={true}
                                            />
                                            {this.formValidator.message( "phone", this.state.phone, "required|min:7,string")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                <div className="user-profile-images">
                                    <div className="current-img">
                                        <div className="row mt">
                                            <div id="cropContainerModal" data-def-img="assets/extra-images/team-medium-img1.jpg" data-img-type="default">
                                                <figure>
                                                    <Link to="#"><img src={this.state.profile_pic?`${bucketPath}${this.state.profile_pic}`:"assets/extra-images/team-medium-img1.jpg"} alt="Profile" /></Link>
                                                </figure>
                                            </div>
                                        </div>
                                        <span>Upload a profile picture or choose one of the following</span>
                                    </div>
                                    <div className="upload-file">
                                        <input id="profile_image" type="file" className="hide" ref={input=>(this.uploadRef=input)} onChange={e=>this.ProfileImageUpload(e)} />
                                        <button type="button" onClick={e=>this.uploadRef.click()}><span>Upload Picture</span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="opt-conts">
                                    <div className="row">
                                        <div className="field-holder col-md-12">
                                            {/* <label>Find On Map</label> */}
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="field-holder">
                                                <label>Street/Lane Address *</label>
                                                <div className="select-holder">
                                                    <input type="text" placeholder="Street/Lane Address" className="form-control gllpLatitude" name="address" value={this.state.address} onChange={this.onChangeHandler.bind(this)}/>
                                                    {this.formValidator.message( "address", this.state.address, "required|max:50,string" )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <div className="field-holder">
                                                <label>Location * </label>
                                                <div className="select-holder">
                                                    <span>
                                                        <i
                                                        className="fa fa-map-marker"
                                                        style={{ color: "red" }}
                                                        aria-hidden="true"
                                                        onMouseUp={() =>this.openModal("location")}
                                                        ></i>
                                                    </span>
                                                    <input type="text" placeholder="Location" className="form-control gllpLatitude" name="location" value={this.state.location} onChange={this.onChangeHandler.bind(this)}/>
                                                    {this.formValidator.message( "location", this.state.location, "required|max:50,string" )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <div className="field-holder">
                                                <label>Country *</label>
                                                <div className="select-holder">
                                                    <input type="text" placeholder="Country" className="form-control gllpLatitude" name="country" value={this.state.country} onChange={this.onChangeHandler.bind(this)}/>
                                                    {this.formValidator.message( "country", this.state.country, "required|max:50,string" )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <div className="field-holder">
                                                <label>Pincode *</label>
                                                <div className="select-holder">
                                                    <input type="text" placeholder="PinCode" className="form-control gllpLatitude" name="zipcode" value={this.state.zipcode} onChange={this.onChangeHandler.bind(this)}/>
                                                    {this.formValidator.message( "Pincode", this.state.zipcode, "required|max:50,string" )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <MapModal
                                                openMap={this.state.openMap}
                                                closeModal={this.closeModal}
                                            >
                                            <MapAutocomplete
                                            center={{ lat: 18.5204, lng: 73.8567 }}
                                            height="400px"
                                            zoom={16}
                                            FetchAddress = {this.state.modalType === "location" ? this.FetchAddress : this.FetchAddress2}
                                            />
                                            </MapModal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="field-holder text-center">
                                    <button name="button" type="button" className="btn-submit" onClick={this.onSubmitHandler.bind(this)}>Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => ({
    profile : state.buyer.user_profile
});

export default connect(mapStateToProps)(BuyerAccountSetting);
