import React, { Component,Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as RestaurantAction from "./../../store/actions/restaurantActions";
import {bucketPath} from './../../store/helpers/common'
import Pagination from "./../../store/helpers/Pagination";
import SimpleReactValidator from "simple-react-validator";
import "react-phone-number-input/style.css";
import swal from 'sweetalert';


const RestaurantList = (props) => {
  //const [liveStream, setLiveStream] = useState("");
  const {favourite_restaurants} = props;


  const addToFavouriteHandler = () =>{
    const getUserIdentity = localStorage.getItem('token');
    if (!getUserIdentity) {
      swal({
        text: "You need to login to Add Favourite Restaurant !",
        icon: "info",
        buttons: true,
        dangerMode: true,
      })
        .then((yesDo) => {
          if (yesDo) {
            props.ParentforceloginUser()
          } else {
            console.log("Record is Safe");
          }
        });
      return false;
    }
    props.AddtoFavourite(props['restaurantsDetails'].uniqueID,favourite_restaurants.includes(props['restaurantsDetails'].uniqueID)?"N":"Y")
  }

  return (
    <Fragment>
      <div className="img-holder">
        <figure>
        {
          //<a href={void 0} onClick={() => openStream(props['restaurantsDetails'].streaming === "YES" ? props['restaurantsDetails'].uniqueID : '')} style={{cursor: "pointer"}}>
        }
          <Link to={`/menu-listing/${props['restaurantsDetails'] && props['restaurantsDetails'].uniqueID &&  props['restaurantsDetails'].uniqueID}`}>
            <img
              src={`${
                props['restaurantsDetails'] && props['restaurantsDetails'].profile_pic
                  ? `${bucketPath}${props['restaurantsDetails'].profile_pic}`
                  : "assets/extra-images/mama-logo.png"
              }`}
              className="img-list wp-post-image"
              alt="..."
            />
            </Link>
            {
              //</a>
            }

        </figure>
        <span className={`restaurant-status ${props['restaurantsDetails'] && (props['restaurantsDetails'].restaurant_status === "OPEN" || props['restaurantsDetails'].streaming === "YES") ? "open" : "close"}`}>
          <em className="bookmarkRibbon"></em>{ props['restaurantsDetails'] && (props['restaurantsDetails'].streaming === "YES" ? `Live` : props['restaurantsDetails'].restaurant_status === "OPEN") ? `OPEN` : `Close`}
        </span>
      </div>
      <div className="text-holder">
        <div className="post-title">
          <h5>
            <Link to={`/menu-listing/${props['restaurantsDetails'] && props['restaurantsDetails'].uniqueID}`}>
              {props['restaurantsDetails'] && props['restaurantsDetails'].restaurant_name}
            </Link>
          </h5>
        </div>
        {/* <span className="post-categories">
          <span>Apple Juice</span>
        </span> */}
        <div className="delivery-potions">
          <div className="post-time">
            <i className="icon-location-pin"></i>
          </div>
          <span>{props['restaurantsDetails'] && props['restaurantsDetails'].location}</span>
        </div>
        <div className="list-rating">
          <div className="rating-star">
            <span className="rating-box" style={{ width: `${ props['restaurantsDetails'] && props['restaurantsDetails'].average_points != null ? props['restaurantsDetails'].average_points : 0 }%` }}></span>
          </div>
          <span className="reviews">({props['restaurantsDetails'] && props['restaurantsDetails'].review_count})</span>
        </div>
      </div>
      <div className="list-option">
        <Link
          to="#"
          className="shortlist-btn"
          //onClick={e=>props.AddtoFavourite(props['restaurantsDetails'].uniqueID,favourite_restaurants.includes(props['restaurantsDetails'].uniqueID)?"D":"Y")}
          onClick={() =>addToFavouriteHandler()}
        >
          <i className={favourite_restaurants.includes(props['restaurantsDetails'] && props['restaurantsDetails'].uniqueID)?"icon-heart4":"icon-heart3"}></i>
        </Link>
        <Link
          to={`/menu-listing/${props['restaurantsDetails'] && props['restaurantsDetails'].uniqueID}`}
          className="viewmenu-btn text-color"
        >
          View Menu
        </Link>
      </div>
    </Fragment>
  );
};

class ShortList extends Component {
    constructor(props) {
        super(props);
        this.formValidator = new SimpleReactValidator();
        this.state ={
          pageOfItems: [],
          currentPage: 1,
          favouritRestaurantList:[]
        }
        this.favouriteList = []
      }

      componentDidMount() {
        let data = this.props.food_item ? this.props.food_item : ''
        const getUserType = localStorage.getItem('type');
        const getUserData =  localStorage.getItem('data');
        this.props.dispatch(RestaurantAction.RestaurantListing({food_item:data}));
        this.props.dispatch(RestaurantAction.fetchFoodData(this.props.food_item));
        if(getUserType === "U"){
          this.props.dispatch(RestaurantAction.FavouriteListing({userUniqueID:JSON.parse(getUserData).uniqueID}))
        }
      }

        AddtoFavourite = (restaurantUnique,favourite) => {
          const getUserData =  localStorage.getItem('data');
          this.props.dispatch(RestaurantAction.AddToFavourite({userUnique:JSON.parse(getUserData).uniqueID,restaurantUnique,favourite}))
        }

        onChangePage = (pageOfItems, page) => {
          this.setState({ pageOfItems: pageOfItems, currentPage: page });
        };

        UNSAFE_componentWillReceiveProps(newProps){
          let { restaurants:{favourite_restaurants} }= newProps
          const { restaurants:{restaurant_listing} } = newProps;
          this.favouriteList = []
          if(favourite_restaurants instanceof Array){
            favourite_restaurants.map(value=>{
              this.favouriteList = [...this.favouriteList,value.restaurantID];
              return null
            })

            const new_data = []
            favourite_restaurants.map((item,index) =>{
              const data2 = restaurant_listing.filter(itemData =>itemData.restaurantsDetails.uniqueID === item.restaurantID)
              new_data.push(data2[0])
              return null;
            })

             this.setState({
               favouritRestaurantList:new_data
             })
          }
        }

  render() {
    const { pageOfItems,favouritRestaurantList } = this.state;
    //const { restaurants:{restaurant_listing} } = this.props;


    return (
      <>
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div className="user-dashboard loader-holder">
            <div className="listing simple">
            <div className="element-title">
                        <h5>ShortLists</h5>
            </div>
              <ul>
                {pageOfItems instanceof Array &&
                  pageOfItems.map((value, index) => {
                    return (
                      <li key={`restaurant_list${index}`}>
                        <RestaurantList {...value} AddtoFavourite={this.AddtoFavourite} favourite_restaurants={this.favouriteList} ParentforceloginUser ={this.forceloginUser}/>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="page-nation">
                  {
                    favouritRestaurantList instanceof Array && favouritRestaurantList.length > 0 &&
                     (

                    <Pagination
                      pageSize={10}
                      items={favouritRestaurantList}
                      onChangePage={this.onChangePage}
                    />
                  )
                }
                </div>
              </div>
            </div>
        </div>
    </div>
    </>
    );
  }
}

const mapStateToProps = state => ({
    restaurants:state.restaurant,
    food_item:state.restaurant.food_item,
    restaurant_listing:state.restaurant.restaurant_listing
})

export default connect(mapStateToProps)(ShortList);
