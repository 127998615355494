  import React, { Component } from "react";
import { Link } from "react-router-dom";
import { bucketPath } from '../../../../store/helpers/common'
import Moment from 'moment';

// const daysOfWeek = [
//   "Monday",
//   "Tuesday",
//   "Wednesday",
//   "Thursday",
//   "Friday",
//   "Saturday",
//   "Sunday",
// ];

// const DayTimings = (props) => {
//   return (
//     <li key={`day_${props.dayName}`}>
//       <Link to="#">
//         <span className="opend-day">{props.dayName}</span>
//         <span className="opend-time">
//           <small>:</small> 11:00 am - 11:00 pm
//         </span>
//       </Link>
//     </li>
//   );
// };

class MenuListingUS extends Component {
  formattedTime = (value) => {
    let Time;
    Time = value.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [Time];
    let startTime = ''
    if (Time.length > 1) {
         Time = Time.slice (1);
         Time[5] = +Time[0] < 12 ? 'AM' : 'PM';
         Time[0] = +Time[0] % 12 || 12;
         startTime =  Time.join ('');
    }
    return startTime
}
  render() {
    const {
      restaurant_data: { restaurant_view_profile },
    } = this.props;
    const resturants = restaurant_view_profile?.data?.resturants;
    const restaurantsetting = restaurant_view_profile?.data?.restaurantsetting[0];
    const timing = restaurant_view_profile && restaurant_view_profile.data &&
                  Object.keys(restaurant_view_profile.data).length > 0 && restaurant_view_profile.data.restaurantworking &&
                  restaurant_view_profile.data.restaurantworking.length > 0 &&
                  restaurant_view_profile.data.restaurantworking[0].restaurant_working_hour ?
                  (JSON.parse(restaurant_view_profile.data.restaurantworking[0].restaurant_working_hour)) : [];
    let mydate = Moment();
    let currentday = Moment(mydate).format('dddd');
    const currenttiminginfo = timing && timing.length > 0 ? timing.filter(item =>item.day_name === currentday):[];
    return (
      <div
        className="page-section restaurant-detail-image-section"
        style={{
          background:
            `url(${resturants?.cover_pic ? bucketPath + resturants?.cover_pic : "/assets/extra-images/cover-photo01.jpg"}) no-repeat scroll 0 0 / cover`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="company-info-detail">
                <div className="company-info">
                  <div className="img-holder">
                    <figure>
                      <img
                        src={resturants?.profile_pic ? bucketPath + resturants?.profile_pic : "/assets/extra-images/listing-logo18.png"}
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="text-holder">
                    <div className="rating-star">
                      <span
                        className="rating-box"
                        style={{ width: `${resturants?.average_points}%` }}
                      ></span>
                    </div>
                    <span className="reviews">({resturants?.review_count} Reviews)</span>
                    <span className="restaurant-title">{resturants?.restaurant_name}</span>
                    <div className="text">
                      <i className="icon-local_pizza"></i>
                      <p>{resturants?.location}</p>
                    </div>
                  </div>
                </div>
                <div className="delivery-timing reviews-sortby">
                  <div className="text">
                    <i className="icon-motorcycle"></i>
                    <p>
                      Delivery fee: Rs {restaurantsetting?.delivery_fee}
                      <span>Min Order : Rs {restaurantsetting?.min_delivery_order}</span>
                    </p>
                  </div>
                  <ul>
                    <li>
                      <Link to="#" className="reviews-sortby-active">
                        <span>Today :</span>
                        {currenttiminginfo && currenttiminginfo.length > 0 ? <>{this.formattedTime(currenttiminginfo[0].starttime) + ' - '+ this.formattedTime(currenttiminginfo[0].endtime)}</> : "" }
                      </Link>
                      {
                        // <ul className="delivery-dropdown">
                        //   {daysOfWeek.map((dayName) => {
                        //     return <DayTimings dayName={dayName} key={dayName} />;
                        //   })}
                        // </ul>
                      }

                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuListingUS;
