import React, { Component } from 'react'; 
import {Link} from 'react-router-dom';
import Category from './Category';
import SubCategory from './SubCategory';
import Product from './Product';

class AdminProduct extends Component {
    state = {tab:'category'}
    render() {
        const {tab} = this.state
        return (  

            <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                <div className="user-dashboard loader-holder">
                    <div className="user-holder">
                        <form action="#">
                            <ul className="restaurant-menu-nav nav nav-tabs">
                                <li className={tab==="category"?"active":""}><Link to="#" onClick={e=>this.setState({tab:'category'})}>Category</Link></li>
                                <li className={tab==="sub-category"?"active":""}><Link to="#" onClick={e=>this.setState({tab:'sub-category'})}>Sub Category</Link ></li>
                                <li className={tab==="product"?"active":""}><Link to="#" onClick={e=>this.setState({tab:'product'})}>Products</Link ></li>
                            </ul>

                            {tab==="category" && <Category />}
                            {tab==="sub-category" && <SubCategory />}
                            {tab==="product" && <Product />} 
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminProduct