import React, { Component, Fragment, useState } from "react";
import LeftbarRL from "./LeftBarRL";
import { bucketPath } from "../../../../store/helpers/common";
import Pagination from "../../../../store/helpers/Pagination";
import * as RestaurantAction from "../../../../store/actions/restaurantActions";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import LiveStreamPlayer from "../../LiveStreamPlayer";
import Banner from "../../../Banner";
import CenterSidebar from "../../../CenterSidebar";

import swal from 'sweetalert';

const RestaurantList = (props) => {
  const [liveStream, setLiveStream] = useState("");
  const {favourite_restaurants} = props;

  // const openStream = (uniqueKey) => {
  //   if(uniqueKey) {
  //     const roomName = `mama-${uniqueKey.toLowerCase()}`;
  //     const liveStream = `https://rtc-mamachai.cap-tek.com:8443/app/${roomName}.m3u8`
  //     setLiveStream(liveStream);
  //   }
  // }

  const onCloseStreamModal = () => {
    setLiveStream("");
  }

  const addToFavouriteHandler = () =>{
    const getUserIdentity = localStorage.getItem('token');
    if (!getUserIdentity) {
      swal({
        text: "You need to login to Add Favourite Restaurant !",
        icon: "info",
        buttons: true,
        dangerMode: true,
      })
        .then((yesDo) => {
          if (yesDo) {
            props.ParentforceloginUser()
          } else {
            console.log("Record is Safe");
          }
        });
      return false;
    }
    props.AddtoFavourite(props['restaurantsDetails'].uniqueID,favourite_restaurants.includes(props['restaurantsDetails'].uniqueID)?"N":"Y")
  }

  return (
    <Fragment>
      <div className="img-holder">
        <figure>
        {
          //<a href={void 0} onClick={() => openStream(props['restaurantsDetails'].streaming === "YES" ? props['restaurantsDetails'].uniqueID : '')} style={{cursor: "pointer"}}>
        }
          <Link to={`/menu-listing/${props['restaurantsDetails'] && props['restaurantsDetails'].uniqueID &&  props['restaurantsDetails'].uniqueID}`}>
            <img
              src={`${
                props['restaurantsDetails'] && props['restaurantsDetails'].profile_pic
                  ? `${bucketPath}${props['restaurantsDetails'].profile_pic}`
                  : "assets/extra-images/mama-logo.png"
              }`}
              className="img-list wp-post-image"
              alt="..."
            />
            </Link>
            {
              //</a>
            }

        </figure>
        <span className={`restaurant-status ${props['restaurantsDetails'] && (props['restaurantsDetails'].restaurant_status === "OPEN" || props['restaurantsDetails'].streaming === "YES") ? "open" : "close"}`}>
          <em className="bookmarkRibbon"></em>{ props['restaurantsDetails'] && (props['restaurantsDetails'].streaming === "YES" ? `Live` : props['restaurantsDetails'].restaurant_status === "OPEN") ? `OPEN` : `Close`}
        </span>
      </div>
      <div className="text-holder">
        <div className="post-title">
          <h5>
            <Link to={`/menu-listing/${props['restaurantsDetails'] && props['restaurantsDetails'].uniqueID}`}>
              {props['restaurantsDetails'] && props['restaurantsDetails'].restaurant_name}
            </Link>
          </h5>
        </div>
        {/* <span className="post-categories">
          <span>Apple Juice</span>
        </span> */}
        <div className="delivery-potions">
          <div className="post-time">
            <i className="icon-location-pin"></i>
          </div>
          <span>{props['restaurantsDetails'] && props['restaurantsDetails'].location}</span>
        </div>
        <div className="list-rating">
          <div className="rating-star">
            <span className="rating-box" style={{ width: `${ props['restaurantsDetails'] && props['restaurantsDetails'].average_points != null ? props['restaurantsDetails'].average_points : 0 }%` }}></span>
          </div>
          <span className="reviews">({props['restaurantsDetails'] && props['restaurantsDetails'].review_count})</span>
        </div>
      </div>
      <div className="list-option">
        <Link
          to="#"
          className="shortlist-btn"
          //onClick={e=>props.AddtoFavourite(props['restaurantsDetails'].uniqueID,favourite_restaurants.includes(props['restaurantsDetails'].uniqueID)?"D":"Y")}
          onClick={() =>addToFavouriteHandler()}
        >
          <i className={favourite_restaurants.includes(props['restaurantsDetails'] && props['restaurantsDetails'].uniqueID)?"icon-heart4":"icon-heart3"}></i>
        </Link>
        <Link
          to={`/menu-listing/${props['restaurantsDetails'] && props['restaurantsDetails'].uniqueID}`}
          className="viewmenu-btn text-color"
        >
          View Menu
        </Link>
      </div>
      <LiveStreamPlayer video={liveStream} onCloseStreamModal={onCloseStreamModal}/>
    </Fragment>
  );
};

class RestaurantsListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageOfItems: [],
      currentPage: 1,
      alphabeticalSortType:false,
      ratingSortType:true,
      deliveryFeeSortType:false,
      restaurant_listing_new:[],
     };
    this.favouriteList = []
  }

  componentDidMount() {
    let data = this.props.food_item ? this.props.food_item : ''
    const getUserType = localStorage.getItem('type');
    const getUserData =  localStorage.getItem('data');
    this.props.dispatch(RestaurantAction.RestaurantListing({food_item:data}));
    this.props.dispatch(RestaurantAction.fetchFoodData(this.props.food_item));
    if(getUserType === "U"){
      this.props.dispatch(RestaurantAction.FavouriteListing({userUniqueID:JSON.parse(getUserData).uniqueID}))
    }
  }

  toggleAlphabeticalSort = () =>{
    this.setState({
      alphabeticalSortType:!this.state.alphabeticalSortType
    },() =>{
       const {pageOfItems } = this.state
      // const restaurant_listing_new_copy = restaurant_listing_new.sort((a,b) =>{
      //   const isReversed = (this.state.alphabeticalSortType === true) ? 1 : -1;
      //   return isReversed * a.restaurantsDetails.restaurant_name.localeCompare(b.restaurantsDetails.restaurant_name)
      // })
      const pageOfItems_copy = pageOfItems.sort((a,b) =>{
        const isReversedpage = (this.state.alphabeticalSortType === true) ? 1 : -1;
        return isReversedpage * a.restaurantsDetails.restaurant_name.localeCompare(b.restaurantsDetails.restaurant_name)
      })
      this.setState({
        //restaurant_listing_new:restaurant_listing_new_copy,
        pageOfItems:pageOfItems_copy
      })
    })
  }
  toggleRatingSort = () =>{
    this.setState({
      ratingSortType:!this.state.ratingSortType
    },() =>{
       const {pageOfItems } = this.state
      const pageOfItems_copy = pageOfItems.sort((a,b) =>{
        const isReversedpage = (this.state.ratingSortType === true) ? 1 : -1;
        const adata = a.restaurantsDetails.average_points != null ? a.restaurantsDetails.average_points : "0"
        const bdata = b.restaurantsDetails.average_points != null ? b.restaurantsDetails.average_points : "0"
        return isReversedpage * adata.localeCompare(bdata)
      })
      this.setState({
        pageOfItems:pageOfItems_copy
      })
    })
  }

  toggleDeliveryFeeSort = () =>{
    this.setState({
      deliveryFeeSortType:!this.state.deliveryFeeSortType
    },() =>{
       const {pageOfItems } = this.state
      const pageOfItems_copy = pageOfItems.sort((a,b) =>{
        const isReversedpage = (this.state.deliveryFeeSortType === true) ? 1 : -1;
        const adata = a.restaurantSetting && a.restaurantSetting.length > 0 &&  a.restaurantSetting[0].delivery_fee != null ? a.restaurantSetting[0].delivery_fee : "0"
        const bdata = b.restaurantSetting && b.restaurantSetting.length > 0 && b.restaurantSetting[0].delivery_fee != null ? b.restaurantSetting[0].delivery_fee : "0"
        return isReversedpage * adata.localeCompare(bdata)
      })
      this.setState({
        pageOfItems:pageOfItems_copy
      })
    })
  }


  cuisinesDataSort = async (value) =>{
    const food_item = value
    const response = await this.props.dispatch(RestaurantAction.GetRestaurantIds(food_item));
    if(response === 1){
      const { restaurants:{restaurant_Id_list} } = this.props;
      const { restaurants:{restaurant_listing} } = this.props;
      const new_data = []
      restaurant_Id_list.map((item,index) =>{
        const data2 = restaurant_listing.filter(itemData =>itemData.restaurantsDetails.restaurantID === item.restaurantID)
        return new_data.push(data2[0])
      })
      this.setState({
        pageOfItems:new_data,
        restaurant_listing_new:new_data
      })
    }
  }

  AddtoFavourite = (restaurantUnique,favourite) => {
    const getUserData =  localStorage.getItem('data');
    this.props.dispatch(RestaurantAction.AddToFavourite({userUnique:JSON.parse(getUserData).uniqueID,restaurantUnique,favourite}))
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

 forceloginUser = () =>{
    this.props.dispatch(RestaurantAction.ForceloginClick(true))
  }
  UNSAFE_componentWillReceiveProps(newProps){
    let { restaurants:{favourite_restaurants} }= newProps
    this.favouriteList = []
    if(favourite_restaurants instanceof Array){
      favourite_restaurants.map(value=>{
        this.favouriteList = [...this.favouriteList,value.restaurantID];
        return null
      })
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
      let nextProps = this.props;
      if(nextProps.restaurant_listing !== prevProps.restaurant_listing){
        this.setState({
          restaurant_listing_new:nextProps.restaurant_listing
        })

      }
    }




  render() {
    const { pageOfItems,restaurant_listing_new } = this.state;
    return (
      <div className="main-section">
        <Banner name="Mamas" description={`${restaurant_listing_new && restaurant_listing_new.length > 0 ? restaurant_listing_new.length : 0} Mama's found`}/>
        <div
          className="page-section"
          style={{
            marginTop: "0px",
            paddingTop: "0px",
            paddingBottom: "10px",
            marginBottom: "0px",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="row">
                  <LeftbarRL  parenttoggleAlphabeticalSort ={this.toggleAlphabeticalSort}
                  parentToggleRatingSort = {this.toggleRatingSort}
                  parentToggleDeliveryFeeSort ={this.toggleDeliveryFeeSort}
                  parentCuisinesDataSort = {this.cuisinesDataSort}
                  />
                  <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                  {
                    // <div className="listing-sorting-holder">
                    //   <div className="row">
                    //     <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    //       <h4>
                    //         {restaurant_listing_new instanceof Array
                    //           ? restaurant_listing_new.length
                    //           : 0}{" "}
                    //         Mama's found
                    //         <span className="pull-right list-grid-ico"></span>
                    //       </h4>
                    //     </div>
                    //   </div>
                    // </div>
                  }

                    <div className="listing simple">
                      <ul>
                        {pageOfItems instanceof Array &&
                          pageOfItems.map((value, index) => {
                            return (
                              <li key={`restaurant_list${index}`}>
                                <RestaurantList {...value} AddtoFavourite={this.AddtoFavourite} favourite_restaurants={this.favouriteList} ParentforceloginUser ={this.forceloginUser}/>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="page-nation">
                          {
                            restaurant_listing_new instanceof Array && restaurant_listing_new.length > 0 &&
                             (

                            <Pagination
                              pageSize={10}
                              items={restaurant_listing_new}
                              onChangePage={this.onChangePage}
                            />
                          )
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    //<RightbarRL />
                  }
                  <CenterSidebar center="mama"/>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const importStateFromProps = state => ({
    restaurants:state.restaurant,
    food_item:state.restaurant.food_item,
    restaurant_listing:state.restaurant.restaurant_listing
})

export default connect(importStateFromProps)(RestaurantsListing);
