import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {restaurantInfo,RestaurantWorkingHours} from './../../store/actions/restaurantActions';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
let start_time={}
let end_time={}
let closed = {}
let hideHour = {}
days.map(val => {
	start_time[`${val}_start`] = "";
    end_time[`${val}_end`] = "";
    closed[`${val}_closed`] = false
    hideHour[`${val}_hideHour`] = false
		return null;
});

class FoodTruckOpenHours extends Component {
    constructor(props) {
        super(props);
        this.state={
            start:""
        }
        Object.assign(this.state,start_time,end_time);

      }

    componentDidMount(){
        this.props.dispatch(restaurantInfo())
    }

    onTimeChange = (e, name) => {
        this.setState({[e.target.name]:e.target.value})
    }

    onSubmitHandler = async() => {
        let data = [];
            days.map((val) => {
               return data.push({ day_name: val, "starttime": this.state[`${val}_start`], "endtime": this.state[`${val}_end`] });
            });
        const postdata = {data:data}
        const response = await this.props.dispatch(RestaurantWorkingHours(postdata));
        if(response===1){
            this.props.history.push('/restaurant-profile');
            this.props.dispatch(restaurantInfo())
        }
    }


    componentDidUpdate = (prevProps, prevState) => {
        let nextProps = this.props;
        if(nextProps.restaurant !== prevProps.restaurant){
					let rest_data = nextProps.restaurant.data.restaurantworking &&
													nextProps.restaurant.data.restaurantworking.length > 0 &&
													nextProps.restaurant.data.restaurantworking[0].food_truck_timing ?
													JSON.parse(nextProps.restaurant.data.restaurantworking[0].food_truck_timing) :[]
            rest_data.map((val, i) => {
                this.setState({[`${val.day_name}_start`]:val.starttime,[`${val.day_name}_end`]:val.endtime});
                if(val.starttime === '' && val.endtime === ''){
                    this.setState({[`${val.day_name}_closed`]:true,[`${val.day_name}_hideHour`]:true})
                }
								return null;
            })
        }
    }

    FoodClosedHours=(e,id,val)=>{ 
        window.$('#'+id).addClass('hide');
        this.setState({[`${val}_start`]:'',[`${val}_end`]:'',[`${val}_closed`]:true});
    }

    FoodOpenHours=(e,id,val)=>{ 
        window.$('#'+id).removeClass('hide');
        this.setState({[`${val}_hideHour`]:false,[`${val}_closed`]:false})
    }

    render() {
			this.props.parentGetDataFoodTruck(this.state)
        //let restaurantsetting = this.props.restaurant?.data?.restaurantsetting.length
        //let restaurantworking = this.props.restaurant?.data?.restaurantworking.length
    return (
			<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<div className="element-title">
											<h4>Food Truck Opening Hours</h4>
									</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<div className="field-holder field-holder-custom">
											<div className="time-list">
													<ul>
													{
													days.map((val, i) => {
															return (
																	<li key={i}>
																			<div className="open-close-time opening-time">
																					<div className="day-sec">
																							<span>{val}</span>
																					</div>
																					<div className={`time-sec ${this.state[`${val}_hideHour`] ? 'hide' : ''}`} id={i+10} >
																							<select className="" name={`${val}_start`} value={this.state[`${val}_start`]} onChange={(e) => this.onTimeChange(e)}>
																									<option>Select</option>
																									<option value="00:00">12:00 AM</option>
																									<option value="01:00">01:00 AM</option>
																									<option value="02:00">02:00 AM</option>
																									<option value="03:00">03:00 AM</option>
																									<option value="04:00">04:00 AM</option>
																									<option value="05:00">05:00 AM</option>
																									<option value="06:00">06:00 AM</option>
																									<option value="07:00">07:00 AM</option>
																									<option value="08:00">08:00 AM</option>
																									<option value="09:00">09:00 AM</option>
																									<option value="10:00">10:00 AM</option>
																									<option value="11:00">11:00 AM</option>
																									<option value="12:00">12:00 PM</option>
																									<option value="13:00">01:00 PM</option>
																									<option value="14:00">02:00 PM</option>
																									<option value="15:00">03:00 PM</option>
																									<option value="16:00">04:00 PM </option>
																									<option value="17:00">05:00 PM</option>
																									<option value="18:00">06:00 PM</option>
																									<option value="19:00">07:00 PM</option>
																									<option value="20:00">08:00 PM</option>
																									<option value="21:00">09:00 PM</option>
																									<option value="22:00">10:00 PM</option>
																									<option value="23:00">11:00 PM</option>
																							</select>
																							<span className="option-label">to</span>
																							<select className="" name={`${val}_end`} value={this.state[`${val}_end`]} onChange={(e) => this.onTimeChange(e, `${val}_end`)} >
																									<option>Select</option>
																									<option value="00:00">12:00 AM</option>
																									<option value="01:00">01:00 AM</option>
																									<option value="02:00">02:00 AM</option>
																									<option value="03:00">03:00 AM</option>
																									<option value="04:00">04:00 AM</option>
																									<option value="05:00">05:00 AM</option>
																									<option value="06:00">06:00 AM</option>
																									<option value="07:00">07:00 AM</option>
																									<option value="08:00">08:00 AM</option>
																									<option value="09:00">09:00 AM</option>
																									<option value="10:00">10:00 AM</option>
																									<option value="11:00">11:00 AM</option>
																									<option value="12:00">12:00 PM</option>
																									<option value="13:00">01:00 PM</option>
																									<option value="14:00">02:00 PM</option>
																									<option value="15:00">03:00 PM</option>
																									<option value="16:00">04:00 PM </option>
																									<option value="17:00">05:00 PM</option>
																									<option value="18:00">06:00 PM</option>
																									<option value="19:00">07:00 PM</option>
																									<option value="20:00">08:00 PM</option>
																									<option value="21:00">09:00 PM</option>
																									<option value="22:00">10:00 PM</option>
																									<option value="23:00">11:00 PM</option>
																							</select>
																							<Link to="#" value={`${i+10}`}  onClick={(e)=>this.FoodClosedHours(e,i+10,val)} >
																								<i className="icon-close2"  value={`${i+10}`}></i>
																							</Link>
																					</div>
																					<div className={`time-sec ${this.state[`${val}_closed`] ? '' : 'hide'}`} >
																							<Link to="#" value={`${i+10}`}  onClick={(e)=>this.FoodOpenHours(e,i+10,val)} >
																								<i className="fa fa-plus-circle" aria-hidden="true" value={`${i+10}`}></i>
																							</Link>
																							 <h4>Closed</h4>
																					</div>
																			</div>

																	</li>
															)
													})
													}
											</ul>
									</div>
							</div>
					</div>
			</div>
    );
  }
}

const mapStateToProps = (state) => ({
    restaurant : state.restaurant.restaurant_profile
});

export default connect(mapStateToProps)(FoodTruckOpenHours);
