import React, { Component } from "react";
import { connect } from "react-redux";
import Conference from "./Conference";

class LiveStream extends Component {
    constructor(props) {
        super(props);
        this.state = { triggerJoin: false };
        this.videoRef = React.createRef();
        this.audioRef = React.createRef();
        this.remoteStreamRef = React.createRef();
    }

    componentDidMount() {
        this.setState({ triggerJoin: true });
    }

    render() {
        if (this.state.triggerJoin) {
            return (
                <Conference
                    triggerJoin={this.state.triggerJoin}
                    audioRef={this.audioRef}
                    videoRef={this.videoRef}
                    remoteStreamRef={this.remoteStreamRef}
                >
                    <div
                        className="local-stream-container"
                        id="local-stream-container"
                    >
                        <audio
                            ref={this.audioRef}
                            autoPlay={true}
                            muted={true}
                            id="localAudio"
                        ></audio>
                        <video
                            ref={this.videoRef}
                            autoPlay={true}
                            id="localVideo"
                        ></video>
                    </div>
                </Conference>
            );
        }

        return null;
    }
}

export default connect(null, null)(LiveStream);
