import React, { Component } from "react"; 
import { Link } from "react-router-dom"; 
import { connect } from "react-redux";

class ListingDetail extends Component {
  render() { 
    return (
        <div className="main-section">
        <div className="page-section restaurant-detail-image-section" style={{background: 'url(assets/extra-images/cover-photo01.jpg) no-repeat scroll 0 0 / cover' }}> 
            <div className="container"> 
                <div className="row"> 
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="company-info-detail">
                            <div className="company-info">
                                <div className="img-holder">
                                    <figure>
                                        <img src="assets/extra-images/listing-logo18.png" alt="" />
                                    </figure>
                                </div>
                                <div className="text-holder">
                                    <div className="rating-star">
                                        <span className="rating-box" style={{width: '100%'}}></span>
                                    </div>
                                    <span className="reviews">(1 Reviews)</span>
                                    <span className="restaurant-title">Tulip Mama</span>
                                    <div className="text">
                                        <i className="icon-local_pizza"></i>
                                        <p>Apple Juice</p>
                                    </div>
                                </div>
                            </div>
                            <div className="delivery-timing reviews-sortby">
                                <div className="text">
                                    <i className="icon-motorcycle"></i>
                                    <p>
                                        Delivery fee: Rs 10.00<span>
                                            Min Order : Rs 10.00 Max Order : Rs 50.00</span>
                                    </p>
                                </div>
                                <ul>
                                    <li>
                                        <Link to="#" className="reviews-sortby-active">
                                            <span>Today :</span>
                                            11:00 am - 11:00 pm <i className="icon-chevron-small-down"></i>
                                        </Link>
                                        <ul className="delivery-dropdown">
                                            <li><Link to="#"><span className="opend-day">Monday</span> <span className="opend-time"><small>:</small> 11:00 am - 11:00
                                                        pm</span></Link></li>
                                            <li><Link to="#"><span className="opend-day">Tuesday</span> <span className="opend-time"><small>:</small> 11:00 am - 11:00
                                                        pm</span></Link></li>
                                            <li><Link to="#"><span className="opend-day">Wednesday</span> <span className="opend-time"><small>:</small> 11:00 am - 11:00
                                                        pm</span></Link></li>
                                            <li><Link to="#"><span className="opend-day">Thursday</span> <span className="opend-time"><small>:</small> 11:00 am - 11:00
                                                        pm</span></Link></li>
                                            <li><Link to="#"><span className="opend-day">Friday</span> <span className="opend-time"><small>:</small> 11:00 am - 11:00
                                                        pm</span></Link></li>
                                            <li><Link to="#"><span className="opend-day">Saturday</span> <span className="opend-time"><small>:</small> 11:00 am - 11:00
                                                        pm</span></Link></li>
                                            <li><Link to="#"><span className="opend-day">Sunday</span> <span className="opend-time close-day"><small>:</small>Closed</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> 
                </div> 
            </div> 
        </div>
        <div className="page-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-3 col-xs-12 sticky-sidebar">
                        <div className="filter-toggle">
                            <span className="filter-toggle-text">Categories By</span><i className="icon-chevron-down"></i>
                        </div>
                        <div className="filter-wrapper">
                            <div className="categories-menu">
                                <h6><i className="icon-restaurant_menu"></i>Categories</h6>
                                <ul className="menu-list">
                                    <li className="active"><Link to="#" className="menu-category-link"> Pizzas </Link></li>
                                    <li className=""><Link to="#" className="menu-category-link"> Calzone </Link></li>
                                    <li className=""><Link to="#" className="menu-category-link"> Burgers </Link></li>
                                    <li className=""><Link to="#" className="menu-category-link"> Chicken </Link></li>
                                    <li className=""><Link to="#" className="menu-category-link"> Traditional Curries </Link>
                                    </li>
                                    <li className=""><Link to="#" className="menu-category-link"> Biryani Dishes </Link></li>
                                
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-9 col-xs-12">
                        <div className="tabs-holder horizontal">
                            <ul className="stickynav-tabs nav nav-tabs">
                                <li className="active"><Link data-toggle="tab" to="#home"><i className="icon- icon-room_service"></i>Menu</Link></li>
                                <li><Link data-toggle="tab" to="#menu1"><i className="icon- icon-textsms"></i>Reviews
                                        (1)</Link></li>
                                <li><Link data-toggle="tab" to="#menu3"><i className="icon- icon-info3"></i>Mama
                                        Info</Link>
                                </li>
                                <li><Link data-toggle="tab" to="#menu4"><i className="icon- icon-video2 "></i>Videos</Link>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div id="home" className="tab-pane fade in active">
                                    <div className="menu-itam-holder">
                                        <div className="field-holder sticky-search">
                                            <input id="menu-srch-6272" data-id="6272" className="input-field dev-menu-search-field" type="text" placeholder="Search food item" />
                                        </div>
                                        <div id="menu-item-list-6272" className="menu-itam-list">
                                            <div className="element-title" id="menu-category-0">
                                                <h5 className="text-color">Pizzas</h5>
                                                <span>Choose your cut: Triangular, square, fingers or Un cut on any
                                                    size
                                                    pizza.</span>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className="image-holder"> <Link to="assets/extra-images/cover-photo24-1024x187.jpg"><img src="assets/extra-images/cover-photo24-150x150.jpg" alt="" /></Link></div>
                                                    <div className="text-holder">
                                                        <h6>Chicken Tandoori Special 12" Deep Pan</h6>
                                                        <span>Cheese, tomatoes and italian herbs.</span>
                                                        <ul className="nutri-icons">
                                                            <li><Link to="#" data-toggle="tooltip" title="Contain Bnana"><img src="assets/extra-images/natural-item03.png" alt="" /></Link></li>
                                                        </ul>
                                                    </div>
                                                    <div className="price-holder">
                                                        <span className="price">Rs 4.50</span>
                                                        <Link to="#" className="restaurant-add-menu-btn restaurant-add-menu-btn-0"><i className="icon-plus4 text-color"></i>
                                                            <div className="foodbakery-button-loader" style={{display: 'block'}}>
                                                                <div className="spinner">
                                                                    <div className="double-bounce1"></div>
                                                                    <div className="double-bounce2"></div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        <span id="add-menu-loader-0"></span>
                                                    </div>
                                                </li>
                                                <li> 
                                                    <div className="image-holder"> <Link to="assets/extra-images/cover-photo23-1024x187.jpg"><img src="assets/extra-images/cover-photo23-150x150.jpg" alt="" /></Link></div>
                                                    <div className="text-holder">
                                                        <h6>Chicken Tandoori Special 09" Deep Pan</h6>
                                                        <span>Cheese, tomatoes, tuna fish, sweetcorn and italian
                                                            herbs
                                                        </span>
                                                    </div>
                                                    <div className="price-holder">
                                                        <span className="price">Rs 6.50</span>
                                                        <Link to="#" data-toggle="modal" data-target="#extras-0-1" className="dev-adding-menu-btn"><i className="icon-plus4 text-color"></i></Link>
                                                        <span id="add-menu-loader-1"></span>
                                                    </div>
                                                    <div className="modal fade menu-extras-modal" id="extras-0-2" tabIndex="-1" role="dialog">
                                                        <div className="modal-dialog" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                                                    <h2><Link to="#">Extras</Link></h2>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <div className="menu-selection-container">
                                                                        <div className="extras-detail-main">
                                                                            <h3>Drinks</h3>
                                                                            <div className="extras-detail-options">
                                                                                <div className="extras-detail-att">
                                                                                    <label htmlFor="extra-1">
                                                                                        <input type="radio" id="extra-1" name="extra-0-1" />
                                                                                        <span className="extra-title">Cold
                                                                                            Drink</span>
                                                                                        <span className="extra-price">Rs 1.50</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div className="extras-detail-att">
                                                                                    <label htmlFor="extra-2">
                                                                                        <input type="radio" id="extra-2" name="extra-0-1" />
                                                                                        <span className="extra-title">Redbull</span>
                                                                                        <span className="extra-price">Rs 2.50</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div className="extras-detail-att">
                                                                                    <label htmlFor="extra-3">
                                                                                        <input type="radio" id="extra-3" name="extra-0-1" />
                                                                                        <span className="extra-title">chillies</span>
                                                                                        <span className="extra-price">Rs 3.50</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="extras-detail-selected"></div>
                                                                        </div>
                                                                        <div className="extras-btns-holder">
                                                                            <button style={{display:'none'}} className="add-extra-menu-btn input-button-loader">Add
                                                                                to Menu</button>
                                                                            <Link to="#" className="reset-menu-fields btn">Reset
                                                                                Fields</Link>
                                                                            <span className="extra-loader"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="element-title" id="menu-category-1">
                                                <h5 className="text-color">Calzone</h5>
                                                <span>calzones are usually stuffed with cheeses such as ricotta,
                                                    mozzarella
                                                    or a different local cheese.</span>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className="image-holder"> <Link to="assets/extra-images/cover-photo13-1024x187.jpg"><img src="assets/extra-images/cover-photo13-150x150.jpg" alt="" /></Link></div>
                                                    <div className="text-holder">
                                                        <h6>Calzone (Folded Pizza)</h6>
                                                        <span>Cheese, tomatoes, ham, salami, mushrooms, garlic
                                                            sausage and
                                                            italian herbs</span>
                                                    </div>
                                                    <div className="price-holder">
                                                        <span className="price">Rs 3.50</span>
                                                        <Link to="#" data-toggle="modal" data-target="#extras-1-9" className="dev-adding-menu-btn"><i className="icon-plus4 text-color"></i></Link>
                                                        <span id="add-menu-loader-9"></span>
                                                    </div>
                                                    <div className="modal fade menu-extras-modal" id="extras-1-9" tabIndex="-1" role="dialog">
                                                        <div className="modal-dialog" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                                                    <h2><Link to="#">Extras</Link></h2>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <div className="menu-selection-container">
                                                                        <div className="extras-detail-main">
                                                                            <h3>Extras</h3>
                                                                            <div className="extras-detail-options">
                                                                                <div className="extras-detail-att">
                                                                                    <label htmlFor="extra-0-0-9">
                                                                                        <input type="radio" id="extra-0-0-9" name="extra-0-9" />
                                                                                        <span className="extra-title">Cold
                                                                                            Drink</span>
                                                                                        <span className="extra-price">Rs 2.50</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div className="extras-detail-att">
                                                                                    <label htmlFor="extra-1-0-9">
                                                                                        <input type="radio" id="extra-1-0-9" name="extra-0-9" />
                                                                                        <span className="extra-title">Souce</span>
                                                                                        <span className="extra-price">Rs 1.50</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div className="extras-detail-att">
                                                                                    <label htmlFor="extra-2-0-9">
                                                                                        <input type="radio" id="extra-2-0-9" name="extra-0-9" />
                                                                                        <span className="extra-title">Chees</span>
                                                                                        <span className="extra-price">Rs 0.80</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="extras-detail-selected"></div>
                                                                        </div>
                                                                        <div className="extras-btns-holder">
                                                                            <button style={{display:'none'}} className="add-extra-menu-btn input-button-loader">Add
                                                                                to Menu</button>
                                                                            <Link to="#" className="reset-menu-fields btn">Reset
                                                                                Fields</Link>
                                                                            <span className="extra-loader"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            
                                            <div className="element-title" id="menu-category-4">
                                                <h5 className="text-color">Burgers</h5>
                                                <span>Hamburger is a kind of sandwich consisting of grilled cutlets,
                                                    served
                                                    inside that cut round loaf.</span>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className="image-holder"> <Link to="assets/extra-images/fb_masonary_9.jpg"><img src="assets/extra-images/fb_masonary_9-150x150.jpg" alt="" /></Link></div>
                                                    <div className="text-holder">
                                                        <h6>Cheeseburger</h6>
                                                        <span>Topped with pineapple and cheese</span>
                                                    </div>
                                                    <div className="price-holder">
                                                        <span className="price">Rs 6.00</span>
                                                        <Link to="#" className="restaurant-add-menu-btn restaurant-add-menu-btn-21"><i className="icon-plus4 text-color"></i></Link>
                                                        <span id="add-menu-loader-21"></span>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="element-title" id="menu-category-7">
                                                <h5 className="text-color">Chicken</h5>
                                                <span>The chicken (Gallus gallus domesticus) is a type of
                                                    domesticated
                                                    fowl,</span>
                                            </div>
                                            <ul>
                                            
                                                <li>
                                                    <div className="image-holder"> <Link to="assets/extra-images/cover-photo17-1024x187.jpg"><img src="assets/extra-images/cover-photo17-150x150.jpg" alt="" /></Link></div>
                                                    <div className="text-holder">
                                                        <h6>1 Piece of Chicken Meal</h6>
                                                        <span>Salad topped reviewer Kelly's expectations for a
                                                            chicken
                                                            salad</span>
                                                    </div>
                                                    <div className="price-holder">
                                                        <span className="price">Rs 2.50</span>
                                                        <Link to="#" className="restaurant-add-menu-btn restaurant-add-menu-btn-35"><i className="icon-plus4 text-color"></i></Link>
                                                        <span id="add-menu-loader-35"></span>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="element-title" id="menu-category-11">
                                                <h5 className="text-color">Traditional Curries</h5>
                                                <span> chicken tikka masala, bhuna, dhansak, vindaloo</span>
                                            </div>
                                            <ul>
                                                
                                                <li>
                                                    <div className="image-holder"> <Link to="assets/extra-images/cover-photo16-1024x187.jpg"><img src="assets/extra-images/cover-photo16-150x150.jpg" alt="" /></Link></div>
                                                    <div className="text-holder">
                                                        <h6>King Prawn &amp; Rice</h6>
                                                        <span>Pour the eggs into the empty side of the pan, then
                                                            scramble
                                                            them, stirring</span>
                                                    </div>
                                                    <div className="price-holder">
                                                        <span className="price">Rs 6.99</span>
                                                        <Link to="#" className="restaurant-add-menu-btn restaurant-add-menu-btn-51"><i className="icon-plus4 text-color"></i></Link>
                                                        <span id="add-menu-loader-51"></span>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="element-title" id="menu-category-12">
                                                <h5 className="text-color">Biryani Dishes</h5>
                                                <span>Seafood dishes are distinct food dishes which use seafood
                                                </span>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className="image-holder"> <Link to="assets/extra-images/cover-photo14-1024x187.jpg"><img src="assets/extra-images/cover-photo14-150x150.jpg" alt="" /></Link></div>
                                                    <div className="text-holder">
                                                        <h6>Chicken</h6>
                                                        <span>Popular variations use chicken instead of goat</span>
                                                    </div>
                                                    <div className="price-holder">
                                                        <span className="price">Rs 6.00</span>
                                                        <Link to="#" className="restaurant-add-menu-btn restaurant-add-menu-btn-53"><i className="icon-plus4 text-color"></i></Link>
                                                        <span id="add-menu-loader-53"></span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div id="menu1" className="tab-pane fade">
                                    <div className="reviews-holder">
                                        <div className="add-new-review-holder" style={{display: 'none'}}>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="elements-title">
                                                        <h3>Rate and Write a Review</h3>
                                                        <Link to="#" className="close-post-new-reviews-btn">Close</Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{textAlign: 'center'}}>Please login
                                                    in order to post review.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="reviwes-restaurant-holder">
                                            <div className="review-listing">
                                                <div className="elements-title">
                                                    <h5>Customer Reviews For Mama Demo <span>1</span></h5>
                                                    <div className="sort-by">
                                                        <span className="ajax-loader-sorty-by" style={{display: 'none'}}>
                                                            <img src="assets/images/ajax-loader.gif" alt="" /></span>
                                                        <ul className="reviews-sortby">
                                                            <li>
                                                                <span className="active-sort">Newest Reviews</span>
                                                                <div className="reviews-sort-dropdown">
                                                                    <form>
                                                                        <div className="input-reviews">
                                                                            <div className="radio-field">
                                                                                <input name="review" id="check-1" type="radio" defaultValue="newest" checked="checked" readOnly />
                                                                                <label htmlFor="check-1">Newest
                                                                                    Reviews</label>
                                                                            </div>
                                                                            <div className="radio-field">
                                                                                <input name="review" id="check-2" type="radio" defaultValue="highest" />
                                                                                <label htmlFor="check-2">Highest
                                                                                    Rating</label>
                                                                            </div>
                                                                            <div className="radio-field">
                                                                                <input name="review" id="check-3" type="radio" defaultValue="lowest" />
                                                                                <label htmlFor="check-3">Lowest
                                                                                    Rating</label>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <ul className="review-restaurant">
                                                    <li className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                                        <div className="list-holder ">
                                                            <div className="review-text">
                                                                <div className="review-title">
                                                                    <h6>: Best Food </h6>
                                                                    <div className="rating-holder">
                                                                        <div className="rating-star">
                                                                            <span style={{width: '100%'}} className="rating-box"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <em className="review-date">25 Apr 2018</em>
                                                                <p>A wonderfull experience. </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                                        <div className="list-holder ">
                                                            <div className="review-text">
                                                                <div className="review-title">
                                                                    <h6>: Best food </h6>
                                                                    <div className="rating-holder">
                                                                        <div className="rating-star">
                                                                            <span style={{width: '100%'}} className="rating-box"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <em className="review-date">25 Apr 2018</em>
                                                                <p>I had great food the other day </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="menu3" className="tab-pane fade">
                                    <div className="contact-info-detail">
                                        <h5>Overview Mama Demo</h5>
                                        <p className="restaurant-desc"></p>
                                        <p>Base prepared fresh daily. Extra toppings are available in choose
                                            extraChoose
                                            you sauce: Go for BBQ sauce or piri piri sauce on your pizza base for no
                                            extra cost.Choose your cut: Triangular, square, fingers or Un cut on any
                                            size pizza
                                        </p>
                                        <div className="map-sec-holder">
                                            <div className="cs-map-section">
                                                <div className="cs-map">
                                                    <div className="cs-map-content">
                                                        <div className="mapouter">
                                                            <div className="gmap_canvas"><iframe width="100" height="180" id="gmap_canvas" title="Google Map" src="https://maps.google.com/maps?q=university%20of%20san%20francisco&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>Google Maps Generator
                                                                by <Link to="https://www.embedgooglemap.net">embedgooglemap.net</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="contact-info">
                                                    <h5>Contact details</h5>
                                                    <p>Himachal Pradesh, Kangra</p>
                                                    <ul>
                                                        <li className="cell"><i className="icon-phone"></i><Link to="#">(+91)
                                                                0123456789</Link></li>
                                                        <li className="pizzaeast"><i className="icon-globe2"></i><Link to="#">http://www.demo.com</Link></li> 
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="widget widget-timing">
                                                    <h5>Opening Hours</h5>
                                                    <ul>
                                                        <li><span>Monday</span>11:00 am - 11:00 pm</li>
                                                        <li><span>Tuesday</span>11:00 am - 11:00 pm</li>
                                                        <li><span>Wednesday</span>11:00 am - 11:00 pm</li>
                                                        <li><span>Thursday</span>11:00 am - 11:00 pm</li>
                                                        <li><span>Friday</span>11:00 am - 11:00 pm</li>
                                                        <li><span>Saturday</span>11:00 am - 11:00 pm</li>
                                                        <li><span>Sunday</span>Off</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="menu4" className="tab-pane fade">
                                    <div className="contact-info-detail">
                                        <p className="text-center">No Record Found</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sticky-sidebar col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div className="user-order-holder">
                            <div className="user-order">
                                <h6><i className="icon-shopping-basket"></i>Your Order</h6> 
                                <div className="select-option dev-select-fee-option">
                                    <ul>
                                        <li>
                                            <input id="order-pick-up-fee" type="radio" name="order_fee_type" />
                                            <label htmlFor="order-pick-up-fee">Pick-Up</label>
                                            <span>Rs 15.00</span>
                                        </li>
                                        <li>
                                            <input id="order-delivery-fee" checked="checked" type="radio" name="order_fee_type" readOnly/>
                                            <label htmlFor="order-delivery-fee">Delivery</label>
                                            <span>Rs 10.00</span>
                                        </li>
                                    </ul>
                                </div>
                                <div id="dev-menu-orders-list">
                                    <ul className="categories-order">
                                        <li>
                                            <Link to="#" className="btn-cross dev-remove-menu-item"><i className=" icon-cross3"></i></Link>
                                            <Link to="#"> Chicken Tandoori Special 12" Deep Pan</Link>
                                            <span className="category-price">Rs 4.50</span>
                                        </li>
                                        <li>
                                            <Link to="#" className="btn-cross dev-remove-menu-item"><i className=" icon-cross3"></i></Link>
                                            <Link to="#">Chicken Tandoori Special 09" Deep Pan</Link>
                                            <span className="category-price">Rs 6.50</span>
                                            <ul>
                                                <li>Drinks - chillies : <span className="category-price">Rs 3.50</span>
                                                </li>
                                            </ul>
                                            <Link to="#" data-toggle="modal" data-target="#extras-0-1" className="update-menu dev-update-menu-btn">Edit</Link>
                                        </li>
                                    </ul>
                                    <div className="price-area dev-menu-price-con">
                                        <ul>
                                            <li>Subtotal <span className="price">Rs <em className="dev-menu-subtotal">20.00</em></span></li>
                                            <li className="restaurant-fee-con">
                                                <span className="fee-title">Pick-Up</span>
                                                <span className="price">Rs <em className="dev-menu-charges">15.00</em></span>
                                            </li>
                                            <li>VAT (13%) <span className="price">Rs <em className="dev-menu-vtax">4.55</em></span></li>
                                        </ul>
                                        <p className="total-price">Total <span className="price">Rs <em className="dev-menu-grtotal">39.55</em></span></p>
                                    </div>
                                </div>
                                <div id="dev-no-menu-orders-list"> 
                                </div>
                                <div className="pay-option dev-order-pay-options">
                                    <ul>
                                        <li>
                                            <input id="order-cash-payment" type="radio" name="order_payment_method" />
                                            <label htmlFor="order-cash-payment"><i className="icon-coins"></i>Cash</label>
                                        </li>
                                        <li>
                                            <input id="order-card-payment" type="radio" checked="checked" name="order_payment_method" readOnly />
                                            <label htmlFor="order-card-payment"><i className="icon-credit-card4"></i>Card</label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <div className="input-group date">
                                                <input type="text" name="delivery_date" id="datetimepicker1" className="form-control" defaultValue="04-04-2020 10:07" placeholder="Select Date and Time" />
                                                <span className="input-group-addon">
                                                    <span className="icon-event_available"></span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Link to="#" className="menu-order-confirm">Confirm Order</Link>
                                <span className="menu-loader"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => ({ 
});
  
export default connect(mapStateToProps)(ListingDetail);
