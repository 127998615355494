// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import Pagination from "./../../../store/helpers/Pagination";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {ChangingPriority} from './../../../store/actions/restaurantActions'
import {connect} from 'react-redux';

// const reorder = (list, startIndex, endIndex) => {
//     const result = Array.from(list);
//     const [removed] = result.splice(startIndex, 1);
//     result.splice(endIndex, 0, removed);
//     return result;
// };

class CategoryListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pageOfItems: [], currentPage: 1 };
    }

    onDragEnd = (result) => {
        if (!result.destination) {return;}
        if(result.source && result.destination && typeof result.destination.index==="number" && typeof result.source.index==="number"){
            let sourcePriority          = result.source.index;
            let destinationPriority     = result.destination.index;
            this.props.dispatch(ChangingPriority({from:sourcePriority,to:destinationPriority}));
        }
    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    deleteCategory = id => {

    }

  render() {
    const { category_listing } = this.props;
    const { pageOfItems, currentPage } = this.state;
    return (
      <div className="form-elements">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="user-orders-list">
              <div className="table-responsive">
                {/* <ul className="table-generic table-generic-temp"> */}
                <DragDropContext onDragEnd={e=>this.onDragEnd(e)}>
                    <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <ul className="table-generic table-generic-temp" {...provided.droppableProps} ref={provided.innerRef} >
                            <li className="order-heading-titles">
                                <div>Sr No</div>
                                <div>Menu Name</div>
                                <div>Description</div>
                                <div>Action</div>
                            </li>
                        {pageOfItems instanceof Array && pageOfItems.map((value, index) => (
                            <Draggable key={`key_${index}`} draggableId={value.priority.toString()} index={value.priority}>
                            {(provided, snapshot) => (
                                <li className="order-heading-titles" draggable={true} key={`category_${value.priority}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    <div>{(index+1)+((currentPage-1)*10)}</div>
                                    <div>{value.name}</div>
                                    <div><p className="facilitydescription">{value.description}</p></div>
                                    <div><Link to="#" onClick={e=>this.props.ViewModal(value.uniquekey,'add_category')}><i className="fa fa-eye"></i></Link>&nbsp;&nbsp;<Link to="#" onClick={e=>this.props.EditModal(value.uniquekey,'add_category')}><i className="fa fa-edit alert-warning"></i></Link>&nbsp;&nbsp;<Link to="#" onClick={e=>this.props.DeleteRow(value.uniquekey,'category')}><i className="fa fa-trash alert-danger"></i></Link></div>
                                </li>
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </ul>
                    )}
                    </Droppable>
                </DragDropContext>
                  {/* <li className="order-heading-titles">
                    <div>Sr No</div>
                    <div>Menu Name</div>
                    <div>Description</div>
                  </li> */}
                {/* </ul> */}
              </div>
            </div>
          </div>
        </div>
        {category_listing instanceof Array && (
          <Pagination
            pageSize={10}
            items={category_listing}
            onChangePage={this.onChangePage}
          />
        )}
      </div>
    );
  }
}
export default connect()(CategoryListing);
