import React from "react";

const ModalBody = (props) => {
  return (
    <div className="modal-body">
			<div className="user-dashboard user-dashboard-temp">
				<div id="add-menu-cat-from" className="">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"> 
						<div className="row">
							{props.children}
						</div>
					</div>
				</div>
			</div>
		</div>  
  );
};
export default ModalBody;
