import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as RestaurantAction from './../../../../store/actions/restaurantActions';
import {bucketPath} from './../../../../store/helpers/common';

class RecipeTab extends Component {
  componentDidMount = () => {
    let uniqueID = this.props.match.params.token;
    this.props.dispatch(RestaurantAction.RecipeList({ uniqueID }));
  };

  render() {
    const {
      restaurant_data: { recipe_list },
    } = this.props;
    return (
      <div id='recipe' className='tab-pane fade'>
        <div className='menu-itam-holder'>
          <div className='field-holder sticky-search'>
            <input
              id='menu-srch-6272'
              data-id='6272'
              className='input-field dev-menu-search-field'
              type='text'
              placeholder='Search food item'
            />
          </div>
          <div className='menu-itam-list'>
            <div className='row'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <div className='blog blog-medium'>
                  <div className='row'>
                    {recipe_list.map((value, index) => {
                      return (
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' key={`recipetab_${index}`}>
                          <div className='blog-post recepie-img-box'>
                            <div className='img-holder'>
                              <figure>
                                <div className='recepie-img-overlay'>
                                  <div className='list-option'>
                                    <span
                                      className='label bgcolor time-label'
                                      style={{ display: 'none' }}
                                    ></span>
                                    <Link
                                      to='#'
                                      className='shortlist-btn'
                                      data-toggle='modal'
                                      data-target='#sign-in'
                                    >
                                      <i className='icon-heart-o'></i>{' '}
                                    </Link>
                                    <Link
                                      to='#'
                                      className='shortlist-btn pull-right'
                                    >
                                      <i className='icon-video-camera'></i>
                                    </Link>
                                  </div>
                                  {/* <div className='list-rating'>
                                    <div className='rating-star'>
                                      <span className='rating-box'></span>
                                    </div>
                                    <span className='reviews'>(1)</span>
                                  </div> */}
                                </div>
                                <Link to='#'>
                                    {
                                       value.image? <img
                                       src={`${bucketPath}${value?.image.split(',')?.[0]}`}
                                       alt=''
                                     /> :<video width="320" height="240" controls>
                                            <source src={`${bucketPath}${value?.video.split(',')?.[0]}`} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                     </video>
                                    }
                                </Link>
                              </figure>
                            </div>
                            <div className='text-holder'>
                              <div className='post-title'>
                                <h4>
                                    <Link to='#'>{value.title}</Link>
                                  {/* <label className='label resturant-label-stat label-success pull-right'>
                                    Open
                                  </label> */}
                                </h4>
                              </div>
                              <div dangerouslySetInnerHTML={{__html:`${value?.description.substring(0,150)??'<p></p>'}`}}/>
                              {/* <Link
                                to={`/recipe-info/${value.recipeID}`}
                                className='read-more text-color'
                              >
                                View More<i className='icon-arrow-right22'></i>
                              </Link> */}
                            </div>
                            <div className="row no-padding col-md-12 view-and-star">
                              <div className="col-md-6 col-sm-6 col-xs-6 no-padding">
                                <Link
                                  to={`/recipe-info/${value.recipeID}`}
                                  className='read-more text-color'
                                >
                                  View More<i className='icon-arrow-right22'></i>
                                </Link>
                              </div>
                              <div className="col-md-6  col-sm-6 col-xs-6 text-right no-padding">
                                <div className='list-rating'>
                                  <div className='rating-star'>
                                    <span className='rating-box'></span>
                                  </div>
                                  <span className='reviews'>(1)</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  restaurant_data: state.restaurant,
});
export default connect(mapStateToProps)(RecipeTab);
