import React, { Component } from "react";
import Modals from './../modules/Html/Modals';
import ModalHeader from './../modules/Html/ModalHeader';
import ModalBody from './../modules/Html/ModalBody';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { RestaurantRecepieListing } from '../store/actions/restaurantActions'
import { mamaCart } from '../store/actions/cartActions';
import {
  RestaurantMenuListing,
} from '../store/actions/restaurantActions';
import Pagination from "./../store/helpers/Pagination";
import { bucketPath } from '../store/helpers/common'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Conference from "./Restaurant/Conference";
import Banner from "./Banner";
import CenterSidebar from "./CenterSidebar";

class Recepie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageOfItems: [],
      currentPage: 1,
      recipe_video: {},
      live_modal:false,
      triggerJoin: false

    };
    this.videoRef = React.createRef();
    this.audioRef = React.createRef();
    this.remoteStreamRef = React.createRef();
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

  seeRecipe = () => {
    this.setState({
      open: false
    }, () => {
      this.props.history.push("/recipe-info/" + this.state.recipe_video.recipeID);
    })
  }


  CloseModal = e => {
    this.setState({
      open: false,
      recipe_video: {},
      live_modal:false,
      triggerJoin: false
    })
  }
  componentDidMount() {
    let data = {
      "status": "P"
    }
    this.props.dispatch(RestaurantRecepieListing(data));
  }

  onAddCart = async (menu_uniqueID,res_uniqueID) => {
    const response = await this.props.dispatch(RestaurantMenuListing(res_uniqueID));
    if(response===1){ 
        const menu_listing_data = this.props.restaurant_data.menu_listing
        const menu_data = menu_listing_data && menu_listing_data.length > 0 ?
                            menu_listing_data.filter(filterdata =>filterdata.uniquekey === menu_uniqueID):[];
          if(menu_data.length > 0){
            let uniqueID = menu_uniqueID;
            let name = menu_data[0].name;
            let price = parseFloat(menu_data[0].price);
            let cartItems  = this.props.mama_cart;
            let arrayFilter =
              cartItems instanceof Array &&
              cartItems.findIndex((obj) => obj.uniqueID === uniqueID);
            if (arrayFilter !== -1) {
              cartItems[arrayFilter] = {
                name: name,
                uniqueID: uniqueID,
                price: cartItems[arrayFilter].price + price,
                count: cartItems[arrayFilter].count + 1,
              };
            } else {
              cartItems = [
                ...cartItems,
                { name: name, uniqueID: uniqueID, price: price, count: 1 },
              ];
            }
            this.props.dispatch(mamaCart(cartItems));
            this.props.history.push(`/menu-listing/${res_uniqueID}`)
          }

    }

    return null;
  };

  render() {
    const { recepie_listing } = this.props.restaurant_data
    const { pageOfItems,open, recipe_video, additionalRecipe,live_modal } = this.state;
    return (
      <>
        <div>
          <div className="main-section receipes-details">
            <Banner name="Recepies" description="Choose your Recepie"/>
            <div className="container">
              <div className="row">
                <div className="page-content col-lg-9 col-md-9 col-sm-12 col-xs-12">
                {
                  //<div className="cs-section-title"><h2>Recepies</h2></div>
                }

                  <div className="page-section  cs-page-sec-388315  nopadding cs-nomargin">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="blog">
                          <div className="row">
                            <div className="listing grid-listing three-cols">
                              {pageOfItems instanceof Array && pageOfItems.map((value, index) => (
                                <div className="col-md-4 col-xs-6 grid-listing-col recipe-part" key={"recipe-" + index}>
                                  <div className="img-holder">
                                    <figure>
                                      <Link to={`/recipe-info/${value.recipeID}`}><img src={`${bucketPath}${value.image.split(",")[0]}`} alt="" className="img-responsive" /> </Link>
                                      <Link className="mama-coach-img" to={`/menu-listing/${value.uniqueID}`}><img src={`${bucketPath}${value.restaurant_profile_pic}`} alt="" className="img-responsive" /> </Link>
                                    </figure>

                                  </div>
                                  <div className="text-holder">
                                    <div className="listing-inner">
                                      <h4>
                                        <Link to={`/recipe-info/${value.recipeID}`}> {value.title}</Link>
                                      </h4>

                                        <p
                                      className='recipeSummary'
                                      dangerouslySetInnerHTML={{
                                        __html: value?.summary ?? '<p className="recipeSummary"></p>',
                                      }}
                                    />



                                      {
                                        //<p className="recipeSummary">{value.summary}</p>
                                      }


                                      <div className="list-rating pull-left recipe-rating">
                                        <div className="rating-star">
                                          <span className="rating-box" style={{ width: value.average_points + "%" }}></span>
                                        </div>
                                        <span className="reviews">({value.review_count})</span>
                                        {value.live_status === "Y" && (
                                          <span className="liveRecipe pull-right" onClick={e => this.setState({ live_modal:true,triggerJoin: true, open: true })}><i className="fa fa-circle blink-hard"></i>LIVE</span>
                                        )}

                                      </div>
                                      {/* <h5 className="coach-date-time">Date & Time:<span>Feb 08,2021 | 09:45 AM</span></h5>
                                                            <h5 className="coach-duration-time">Duration:<span>01:10</span></h5> */}
                                      <div className="no-padding col-md-12 food-coach-btn">
                                      {
                                        //<Link to={`/menu-listing/${value.uniqueID}`} className="book-now">Order Now</Link>
                                      }

                                        <Link to="#" className="book-now" onClick={(e) =>
                                          this.onAddCart(value.menu_uniqueID,value.uniqueID)
                                        }>Order Now</Link>
                                        <button className="btn btn-red pull-right" onClick={e => this.setState({ recipe_video: value, additionalRecipe: value.additionalRecipe, open: true })}>More Recipies</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              ))}
                            </div>

                            {recepie_listing instanceof Array && (
                              <Pagination
                                pageSize={10}
                                items={recepie_listing}
                                onChangePage={this.onChangePage}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ====== */}
                <CenterSidebar center="recipe" />
              </div>
            </div>
          </div>
        </div>

        <Modals open={open} >
          <ModalHeader CloseModal={this.CloseModal} title={recipe_video.title} />
          <ModalBody>
          {live_modal === false ? (
            <>
            <div className="row">
              {/* <div className="col-md-12">
    								<h5>{recipe_video.title}</h5>
    							</div> */}
            </div>
            <div className="row">
              <div className="col-md-12 modal-video">
                <video width="320" height="240" controls>
                  <source src={`${bucketPath}${recipe_video.video?.split(",")[0]}`}>
                  </source>
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="col-md-6 video-desc">
                <div>
                  {recipe_video?.summary}
                </div>
              </div>
              <div className="col-md-6">
                {
                  additionalRecipe && <OwlCarousel
                    className="owl-theme"
                    id="owl-carousel-banner-3"
                    dots={false}
                    loop={true}
                    margin={10}
                    nav
                    responsiveClass
                    responsive={{
                      		0: {
                      			items: 1,
                      			nav: true
                      		},
                      		600: {
                      			items: 2,
                      			nav: true
                      		},
                      		1000: {
                      			items: 3,
                      			nav: true,
                      			loop: false,
                      			margin: 20
                      		}
                        }
                      }
                  >

                    {
                      additionalRecipe?.map((recipe, i) => {
                        if (recipe) {
                          let videoUrl = recipe.video?.split(",")[0];
                          if (videoUrl) {
                            return (
                              <div className="item" key={"owl-carousel-" + i}>
                                <div className="recipe-img-bx">
                                  <Link to="#"><i className="icon-play_arrow" onClick={e => this.setState({ recipe_video: recipe })}></i></Link>
                                  <video width="100" controls src={`${bucketPath}${videoUrl}`}>
                                    {/* <source  /> */}
                                  Your browser does not support HTML video.
                                </video>
                                </div>
                                <div className="recipe-name-bx">
                                  <h2> <Link to="#">{recipe.title}</Link> </h2>
                                </div>
                              </div>
                            )
                          }
                        }
                      })
                    }
                  </OwlCarousel>
                }
              </div>
              <div className="col-md-6">
                <button className="book-now" onClick={this.seeRecipe}>See Recipe</button>
              </div>
            </div>
            </>
          ):(
            <Conference
                triggerJoin={this.state.triggerJoin}
                audioRef={this.audioRef}
                videoRef={this.videoRef}
                remoteStreamRef={this.remoteStreamRef}
                data_recpies="recpies"
            >
                <div
                    className="local-stream-container"
                    id="local-stream-container"
                >
                    <audio
                        ref={this.audioRef}
                        autoPlay={true}
                        muted={true}
                        id="localAudio"
                    ></audio>
                    <video
                        ref={this.videoRef}
                        autoPlay={true}
                        id="localVideo"
                    ></video>
                </div>
            </Conference>
          )}

          </ModalBody>
        </Modals>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  restaurant_data: state.restaurant,
  mama_cart: state.cart.mama_cart
});

export default connect(mapStateToProps)(Recepie);
