import * as actionTypes from '../types/types';


export const condimentCart = (data) => {
    return dispatch => {
        dispatch({ type: actionTypes.CONDIMENT_CART, payload: data })
    }
}


export function  addCart (value,state){
  return dispatch => {
  let uniqueID = value.uniquekey;
  let name = value.name;
  let price = parseFloat(value.price);
  let image = value.image;
  let quantity = value.quantity;
  let { cartItems } = state;
  let arrayFilter =
    cartItems instanceof Array &&
    cartItems.findIndex((obj) => obj.uniqueID === uniqueID);
  if (arrayFilter !== -1) {
    cartItems[arrayFilter] = {
      name: name,
      uniqueID: uniqueID,
      image:image,
      quantity:quantity,
      price: cartItems[arrayFilter].price + price,
      count: cartItems[arrayFilter].count + 1,
    };
  } else {
    cartItems = [
      ...cartItems,
      { name: name, uniqueID: uniqueID, price: price,image:image,quantity:quantity, count: 1 },
    ];
  }
  dispatch(condimentCart(cartItems));
  return null;
}
};

export function minusCart(value,state) {
  return dispatch => {
  let uniqueID = value.uniquekey;
  let name = value.name;
  let price = parseFloat(value.price);
  let { cartItems } = state;
  let arrayFilter =
    cartItems instanceof Array &&
    cartItems.findIndex((obj) => obj.uniqueID === uniqueID);
  if (arrayFilter !== -1) {
    if (cartItems[arrayFilter].count > 1) {
      cartItems[arrayFilter] = {
        name: name,
        uniqueID: uniqueID,
        image : value.image,
        quantity : value.quantity,
        price: cartItems[arrayFilter].price - price,
        count: cartItems[arrayFilter].count - 1,
      };
    } else {
      cartItems.splice(arrayFilter, 1);
    }
  }
  dispatch(condimentCart(cartItems));
  return null;
}
};

export function removeCart(uniqueID,state){
  return dispatch => {
  let  cartItems  = state;
  let arrayFilter =
    cartItems instanceof Array &&
    cartItems.filter((obj) => obj.uniqueID !== uniqueID);
  dispatch(condimentCart(arrayFilter));
  return null;
}
};
