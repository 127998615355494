import React from 'react';
import { history } from './history';
import {setLoader} from './../reducer/staticReducer'

export const queryResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            setLoader(false);
            if (response.status === 401 || response.status === 400) {
                localStorage.removeItem('token');
                localStorage.removeItem('data');
                localStorage.removeItem('type');
                history.push('/');
            }
            if (response.status === 422) {
                return Promise.reject(data);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

export const handleResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        const token = localStorage.getItem('token');
        if (!response.ok) {
            setLoader(false);
            if (response.status === 401 || response.status === 400) {
                localStorage.removeItem('token');
                localStorage.removeItem('data');
                localStorage.removeItem('type');
                history.push('/');
            }
            if (response.status === 422) {
                return Promise.reject(data);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if(!token || token===""){
            setLoader(false);
            localStorage.removeItem('token');
            localStorage.removeItem('data');
            localStorage.removeItem('type');
            history.push('/');     
            return Promise.reject('Unauthorized request');       
        }
        return data;
    });
}

export const handleAdminLoginResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            setLoader(false);
            if (response.status === 401 || response.status === 400) {
                localStorage.removeItem('admin-token');
                localStorage.removeItem('admin-data');
                localStorage.removeItem('admin-type');
                history.push('/');
            }
            if (response.status === 422) {
                return Promise.reject(data);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

export const handleAdminResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        const token = localStorage.getItem('admin-token');
        if (!response.ok) {
            setLoader(false);
            if (response.status === 401 || response.status === 400) {
                localStorage.removeItem('admin-token');
                localStorage.removeItem('admin-data');
                localStorage.removeItem('admin-type');
                history.push('/');
            }
            if (response.status === 422) {
                return Promise.reject(data);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if(!token || token===""){
            setLoader(false);
            localStorage.removeItem('admin-token');
            localStorage.removeItem('admin-data');
            localStorage.removeItem('admin-type');
            history.push('/');     
            return Promise.reject('Unauthorized request');       
        }
        return data;
    });
}

export const IdentityContext = React.createContext();