import React, { Component } from "react";
import { connect } from "react-redux";
import { UserPaymentList,UserOrderList } from "./../../store/actions/buyerActions";
import Pagination from "./../../store/helpers/Pagination";
import SimpleReactValidator from "simple-react-validator";
import "react-phone-number-input/style.css";
import Moment from 'moment';


class BuyerTranscation extends Component {
    constructor(props) {
        super(props);
        this.formValidator = new SimpleReactValidator();
        this.state ={
          pageOfItems: [],
          currentPage: 1,
          favouritRestaurantList:[]
        }
      }

      componentDidMount() {
        this.props.dispatch(UserPaymentList());
        this.props.dispatch(UserOrderList());
      }


        onChangePage = (pageOfItems, page) => {
          this.setState({ pageOfItems: pageOfItems, currentPage: page });
        };


  render() {
    const { pageOfItems } = this.state;
    const { payment_list } = this.props;
    //const filterData = order_list.filter(filterItem =>(filterItem.payment_type).toLowerCase() === "card")


    return (
      <>
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div className="user-dashboard loader-holder">
            <div className="listing simple">
            <div className="element-title">
                        <h5>Transcations</h5>
            </div>
            <div className="responsive-table">
                      <ul className="table-generic">
                        <li className="order-heading-titles">
                          <div>Transaction ID</div>
                          <div>Order ID</div>
                          <div>Date</div>
                          <div>Detail</div>
                          <div>Amount</div>
                        </li>
                        {pageOfItems instanceof Array && pageOfItems.map((item,index) =>{

                          return(
                            <li className="order-heading-titles" key={`transactions${index}`}>
                              <div>{item.paymentID}</div>
                              <div><a href="buyer-orders.html" className="orders-tab-link">{item.orderNumber}</a></div>
                              <div>{Moment(item.created).format('DD-MM-YYYY')}</div>
                              <div>Order - {item.restaurant_name}</div>
                              <div>Rs {item.paid_amount}</div>
                            </li>
                          )
                        })}
                      </ul>
                </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="page-nation">
                  {
                    payment_list instanceof Array && payment_list.length > 0 &&
                     (

                    <Pagination
                      pageSize={10}
                      items={payment_list}
                      onChangePage={this.onChangePage}
                    />
                  )
                }
                </div>
              </div>
            </div>
        </div>
    </div>
    </>
    );
  }
}

const mapStateToProps = state => ({
    restaurants:state.restaurant,
    food_item:state.restaurant.food_item,
    restaurant_listing:state.restaurant.restaurant_listing,
    payment_list: state.buyer.payment_list,
    order_list: state.buyer.order_list,
})

export default connect(mapStateToProps)(BuyerTranscation);
