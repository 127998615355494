export const creditExpiry = (value) => {
    let [month,year]    = value.split('/');
    let date            = new Date();
    month               = parseInt(month.replace('_',''));
    year                = parseInt(year.replace('_',''));
    let monthCurrent    = date.getMonth()+1;
    let yearCurrent     = date.getFullYear();
    if((month>=monthCurrent && year>=yearCurrent) || (month<monthCurrent && year>yearCurrent)){
        return true;
    }else{
        return false;
    }
}