import { BaseUrl } from '../helpers/common';
import * as actionTypes from '../types/types';
import { handleAdminResponse,handleResponse } from '../helpers/userServices';
import {ShowLoader,ShowAlert} from './staticActions'
import {RestaurantListing} from './../../store/actions/restaurantActions'

export function UserOrderList() {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/order/list`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json','X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });
        return fetch(request).then(handleAdminResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.ADMIN_ORDER_LIST, payload: data.data })
                return 1;
            } else {
                return 0;
            }
        }).catch(error=>{
            dispatch(ShowLoader(false));
        });;
    }
}
export function AdminUserList() {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/user/list`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json','X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });
        return fetch(request).then(handleAdminResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.ADMIN_USER_LIST, payload: data.data })
                return 1;
            } else {
                return 0;
            }
        }).catch(error=>{
            dispatch(ShowLoader(false));
        });;
    }
}


export function AddCategory(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let finalToken = "";
        const admin_token = localStorage.getItem('admin-token');
        const mama_token = localStorage.getItem('token');
        if(admin_token){
          finalToken = admin_token
        }
        else if(mama_token){
          finalToken = mama_token
        }
        const request = new Request(`${BaseUrl}/api/category-api`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${finalToken}` }),
        });
        return fetch(request).then(handleAdminResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch(CategoryListing({type:'list',Newtype:"Admin"}));
                dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));
                return 1;
            } else {
                dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 1500 }));
                return 0;
            }
        })
    }
}


export function CategoryListing(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let finalToken = "";
        const admin_token = localStorage.getItem('admin-token');
        const mama_token = localStorage.getItem('token');
        if(data.Newtype === "Admin"){
          finalToken = admin_token
        }
        else if(data.Newtype === "Restaurant"){
          finalToken = mama_token
        }
        const finalData = {
          type:data.type
        }
        const request = new Request(`${BaseUrl}/api/category-api`, {
            method: 'POST',
            body: JSON.stringify(finalData),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${finalToken}` }),
        });
        return fetch(request).then(data.Newtype === "Admin" ? handleAdminResponse : handleResponse).then((resdata) => {
            dispatch(ShowLoader(false));
            dispatch({
                type: actionTypes.FETCH_CATEGORY_LISTING,
                payload: resdata.data
            });
        }).catch((error)=>{
          dispatch(ShowLoader(false));
          console.log("error",error)
        })
    }
}

export function DeleteCategory(data) {
    return dispatch => {
      let finalToken = "";
      const admin_token = localStorage.getItem('admin-token');
      const mama_token = localStorage.getItem('token');
      if(admin_token){
        finalToken = admin_token
      }
      else if(mama_token){
        finalToken = mama_token
      }
        const request = new Request(`${BaseUrl}/api/category-api`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${finalToken}` })
        });

        return fetch(request)
            .then(res => res.json())
            .then(data => {
                if (data.ResponseCode === "1") {
                    dispatch(CategoryListing({type:'list',Newtype:"Admin"}));
                    dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));
                } else {
                    dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

}


export function AddSubCategory(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const admin_token = localStorage.getItem('admin-token');
        const request = new Request(`${BaseUrl}/api/add-subcategory`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${admin_token}` }),
        });
        return fetch(request).then(handleAdminResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));
                dispatch(SubCategoryListing({type:"Admin"}));
                return 1;
            } else {
                dispatch(ShowAlert({ Class: "alert-danger",Message: data.ResponseText, Timeout: 5000 }));
                return 0;
            }
        }).catch((error)=>{
          dispatch(ShowLoader(false));
          console.log("error",error)
        })
    }
}

export function UpdateSubCategory(data,id) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let finalToken = "";
        const admin_token = localStorage.getItem('admin-token');
        const mama_token = localStorage.getItem('token');
        if(admin_token){
          finalToken = admin_token
        }
        else if(mama_token){
          finalToken = mama_token
        }
        const request = new Request(`${BaseUrl}/api/edit-subcategory/${id}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${finalToken}` }),
        });
        return fetch(request).then(handleAdminResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));
                dispatch(SubCategoryListing({type:"Admin"}));
                return 1;
            } else {
                dispatch(ShowAlert({ Class: "alert-danger",Message: data.ResponseText, Timeout: 5000 }));
                return 0;
            }
        })
    }
}

export function SubCategoryListing(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let finalToken = "";
        const admin_token = localStorage.getItem('admin-token');
        const mama_token = localStorage.getItem('token');
        if(data.type === "Admin"){
          finalToken = admin_token
        }
        else if(data.type === "Restaurant"){
          finalToken = mama_token
        }
        const request = new Request(`${BaseUrl}/api/listing-subcategory`, {
            method: 'POST',
            body: JSON.stringify(),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${finalToken}` }),
        });
        return fetch(request).then(data.type === "Admin" ? handleAdminResponse : handleResponse).then((resdata) => {
            dispatch(ShowLoader(false));
            dispatch({
                type: actionTypes.FETCH_SUBCATEGORY_LISTING,
                payload: resdata.data
            });
        });
    }
}


export function DeleteSubCategory(data) {
    return dispatch => {
      let finalToken = "";
      const admin_token = localStorage.getItem('admin-token');
      const mama_token = localStorage.getItem('token');
      if(admin_token){
        finalToken = admin_token
      }
      else if(mama_token){
        finalToken = mama_token
      }
        const request = new Request(`${BaseUrl}/api/delete-subcategory`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${finalToken}` })
        });

        return fetch(request)
            .then(res => res.json())
            .then(data => {
                if (data.ResponseCode === "1") {
                    dispatch(SubCategoryListing({type:"Admin"}));
                    dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));
                } else {
                    dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

}

export function AddProduct(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let finalToken = "";
        const admin_token = localStorage.getItem('admin-token');
        const mama_token = localStorage.getItem('token');
        if(data.type === "Admin"){
          finalToken = admin_token
        }
        else if(data.type === "Restaurant"){
          finalToken = mama_token
        }
        const {
          name,
        categoryID,
        sub_categoryID,
        price,quantity,
        status,
        description,
        product_highlight,
        tax
      } = data
        let finalData = {
          name,
          categoryID,
          sub_categoryID,
          price,quantity,
          status,
          description,
          product_highlight,
          tax
        }
        const request = new Request(`${BaseUrl}/api/add-product`, {
            method: 'POST',
            body: JSON.stringify(finalData),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${finalToken}` }),
        });
        return fetch(request).then(data.type === "Admin" ? handleAdminResponse : handleResponse).then(resdata => {
            dispatch(ShowLoader(false));
            if (resdata.ResponseCode === "1") {
                localStorage.setItem('product', resdata.uniquekey);
                dispatch(ProductListing({type:data.type}));
                dispatch(ShowAlert({ Class: "alert-success", Message: resdata.ResponseText, Timeout: 5000 }));
                return 1;
            } else {
                dispatch(ShowAlert({ Class: "alert-danger", Message: resdata.ResponseText, Timeout: 5000 }));
                return 0;
            }
        }).catch((error)=>{
          dispatch(ShowLoader(false));
          console.log("error",error)
        })
    }
}

export function ProductListing(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let finalToken = "";
        const admin_token = localStorage.getItem('admin-token');
        const mama_token = localStorage.getItem('token');
        if(data.type === "Admin"){
          finalToken = admin_token
        }
        else if(data.type === "Restaurant"){
          finalToken = mama_token
        }
        const request = new Request(`${BaseUrl}/api/product-listing`, {
            method: 'POST',
            body: JSON.stringify(),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${finalToken}` }),
        });
        return fetch(request).then(data.type === "Admin" ? handleAdminResponse : handleResponse).then((resdata) => {
            dispatch(ShowLoader(false));
            dispatch({
                type: actionTypes.FETCH_PRODUCT_LISTING,
                payload: resdata.data
            });
        }).catch((error)=>{
          dispatch(ShowLoader(false));
          console.log("error",error)
        })
    }
}


export function DeleteProduct(data) {
    return dispatch => {
      let finalToken = "";
      const admin_token = localStorage.getItem('admin-token');
      const mama_token = localStorage.getItem('token');
      if(data.type === "Admin"){
        finalToken = admin_token
      }
      else if(data.type === "Restaurant"){
        finalToken = mama_token
      }
      const finaldata = {
        uniquekey:data.uniquekey
      }

        const request = new Request(`${BaseUrl}/api/delete-product`, {
            method: 'POST',
            body: JSON.stringify(finaldata),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${finalToken}` })
        });

        return fetch(request)
            .then(res => res.json())
            .then(resdata => {
                if (resdata.ResponseCode === "1") {
                    dispatch(ProductListing({type:data.type}));
                    dispatch(ShowAlert({ Class: "alert-success", Message: resdata.ResponseText, Timeout: 5000 }));
                } else {
                    dispatch(ShowAlert({ Class: "alert-danger", Message: resdata.ResponseText, Timeout: 5000 }));
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

}

export function EditProduct(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let finalToken = "";
        const admin_token = localStorage.getItem('admin-token');
        const mama_token = localStorage.getItem('token');
        if(data.type === "Admin"){
          finalToken = admin_token
        }
        else if(data.type === "Restaurant"){
          finalToken = mama_token
        }
        const {
          id,
          name,
        categoryID,
        sub_categoryID,
        price,quantity,
        status,
        description,
        product_highlight,
        tax
      } = data
        let finalData = {
          id,
          name,
          categoryID,
          sub_categoryID,
          price,quantity,
          status,
          description,
          product_highlight,
          tax
        }
        const request = new Request(`${BaseUrl}/api/edit-products`, {
            method: 'POST',
            body: JSON.stringify(finalData),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${finalToken}` }),
        });
        return fetch(request).then(data.type === "Admin" ? handleAdminResponse : handleResponse).then(resdata => {
            dispatch(ShowLoader(false));
            if (resdata.ResponseCode === "1") {
                dispatch(ShowAlert({ Class: "alert-success", Message: resdata.ResponseText, Timeout: 5000 }));
                dispatch(ProductListing({type:data.type}));
                return 1;
            } else {
                dispatch(ShowAlert({ Class: "alert-danger", Message: resdata.ResponseText, Timeout: 5000 }));
                return 0;
            }
        })
    }
}

export function ProductImagesListing(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let finalToken = "";
        const admin_token = localStorage.getItem('admin-token');
        const mama_token = localStorage.getItem('token');
        if(data.type === "Admin"){
          finalToken = admin_token
        }
        else if(data.type === "Restaurant"){
          finalToken = mama_token
        }
        const finalData = {
          id:data.id
        }
        const request = new Request(`${BaseUrl}/api/product-images-listing`, {
            method: 'POST',
            body: JSON.stringify(finalData),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${finalToken}` }),
        });
        return fetch(request).then(data.type === "Admin" ? handleAdminResponse : handleResponse).then((resdata) => {
            dispatch(ShowLoader(false));
            dispatch({
                type: actionTypes.FETCH_PRODUCT_IMAGES_LISTING,
                payload: resdata.data
            });
        });
    }
}

export function DeleteProductImage(post_data) {
    return dispatch => {
      let finalToken = "";
      const admin_token = localStorage.getItem('admin-token');
      const mama_token = localStorage.getItem('token');
      if(post_data.type === "Admin"){
        finalToken = admin_token
      }
      else if(post_data.type === "Restaurant"){
        finalToken = mama_token
      }
        const request = new Request(`${BaseUrl}/api/delete-product-images`, {
            method: 'POST',
            body: JSON.stringify({image:post_data.value}),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${finalToken}` })
        });

        return fetch(request)
            .then(res => res.json())
            .then(data => {
                if (data.ResponseCode === "1") {
                    let post_id =  localStorage.getItem('product');
                    let ID = post_data.isEdit ? post_data.id : post_id
                    dispatch(ProductImagesListing({id:ID,type:post_data.type}));
                    dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));
                } else {
                    dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

}

export function AdminAgroList() {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/agrocente_list`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json','X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });
        return fetch(request).then(handleAdminResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.ADMIN_AGRO_USER_LIST, payload: data.data })
                return 1;
            } else {
                return 0;
            }
        }).catch(error=>{
            dispatch(ShowLoader(false));
        });;
    }
}

export function AdminFoodList() {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/foodcenter/list`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json','X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });
        return fetch(request).then(handleAdminResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.ADMIN_FOOD_USER_LIST, payload: data.data })
                return 1;
            } else {
                return 0;
            }
        }).catch(error=>{
            dispatch(ShowLoader(false));
        });;
    }
}


export function DeleteAdminRecord(postData) {
    return dispatch => {
        const request = new Request(`${BaseUrl}/api/delete_User_restaurant_order`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });

        return fetch(request)
            .then(res => res.json())
            .then(data => {
                if (data.ResponseCode === "1") {
                    if(postData.type === 'user'){
                        dispatch(AdminUserList())
                    }else if(postData.type === 'order'){
                        dispatch(UserOrderList())
                    }else if(postData.type === 'restaurant'){
                        dispatch(RestaurantListing())
                    }
                    else if(postData.type === 'agroCenter'){
                        dispatch(AdminAgroList())
                    }
                    else if(postData.type === 'foodCenter'){
                        dispatch(AdminFoodList())
                    }

                    dispatch(ShowAlert({ Class: "alert-success", Message: 'Record deleted successfully.', Timeout: 5000 }));
                } else {
                    dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

}

export function AdminDataCountList() {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/data_count`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json','X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });
        return fetch(request).then(handleAdminResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.ADMIN_DATA_COUNT_LIST, payload: data.data })
                return 1;
            } else {
                return 0;
            }
        }).catch(error=>{
            dispatch(ShowLoader(false));
        });;
    }
}
