import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import {
    CategoryListing,
    AddSubCategory,
    SubCategoryListing,
    DeleteSubCategory,
    UpdateSubCategory
} from '../../../store/actions/adminActions';
import Pagination from '../../../store/helpers/Pagination';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import { BaseUrl } from "../../../store/helpers/common";
import Modals from '../../../modules/Html/Modals';
import ModalHeader from '../../../modules/Html/ModalHeader';
import ModalBody from '../../../modules/Html/ModalBody';

const initialState = { open : false,isEdit:false,pageOfItems:[],currentPage:1,category:'',name:''}
class SubCategory extends Component {
    constructor(props) {
        super(props)
        this.formValidator = new SimpleReactValidator();
        this.state = initialState
    }

    componentDidMount(){
        this.props.dispatch(CategoryListing({type:'list',Newtype:"Admin"}))
        this.props.dispatch(SubCategoryListing({type:"Admin"}))
    }


    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    setOpen = (value) => {
        this.setState({
            open: false, isEdit: false
        });
    }

    onSubmitSubCategory= async (e) => {
        const {name,category,id,isEdit} = this.state
        if (this.formValidator.allValid()) {
            if(isEdit){
                const data = {name,categoryID:category}
                let response = await this.props.dispatch(UpdateSubCategory(data,id))
                if(response === 1){
                    this.setState({
                        type:'',
                        open:false,
                        isEdit:false
                    })
                }
            }else{
                const data = {name,categoryID:category}
                let response = await this.props.dispatch(AddSubCategory(data))
                if(response === 1){
                    this.setState({
                        type:'',
                        open:false,
                        isEdit:false
                    })
                }
            }
        } else {
            this.formValidator.showMessages();
            this.forceUpdate();
          }
          e.preventDefault();
    }

    EditSubCategory = (e, id) => {
        const request = new Request(`${BaseUrl}/api/view-subcategory`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-Auth-Token": `${localStorage.getItem("admin-token")}`,
            },
            body: JSON.stringify({id}),
        });
        return fetch(request)
            .then((res) => res.json())
            .then((res) => {
                const data = res.data;
                this.setState({
                    id: data.uniquekey,
                    name:data.name,
                    category:data.categoryID,
                    isEdit: true,
                    open: true,
                });
            });
    };

    DeleteSubCategory = (e, uniquekey) => {
        let data={type:'delete',id:uniquekey}
        swal({
            title: "Are you sure you want to delete this Sub Category?",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.dispatch(DeleteSubCategory(data));
            } else {
                console.log("you clicked on false");
            }
        });
    };

    handleOpenModal = ()=>{
        this.setState({
            open:true,
            isEdit:'',
            category:'',
            name:''

        })
    }

    render() {
        const {open,pageOfItems,isEdit} = this.state
        const {category_listing,subcategory_listing} = this.props

        return (

            <div>
                <div className="tab-content">
                    <div id="menu-cats-items" className="tab-pane fade active in">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="element-title">
                                    <h5>Sub Category</h5>
                                    <Link to="#" className="add-menu-item collapsed" onClick={()=>this.handleOpenModal()}>Add Sub Category</Link>
                                </div>
                                <div className="form-elements">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="user-orders-list">
                                                <div className="table-responsive">
                                                    <ul className="table-generic table-generic-temp">
                                                        <li className="order-heading-titles">
                                                            <div>uniqueID</div>
                                                            <div>Name</div>
                                                            <div>Action</div>
                                                        </li>
                                                        {pageOfItems instanceof Array &&
                                                        pageOfItems.map((value, index) => {
                                                            return (
                                                                <li className="order-heading-titles" key={`menu_${index}`}>
                                                                    <div>{value.uniquekey}</div>
                                                                    <div>{value.name}</div>
                                                                    <div>
                                                                    <Link
                                                                        to="#"
                                                                        onClick={(e) =>this.EditSubCategory(e,value.uniquekey)}
                                                                    >
                                                                    <i className="fa fa-edit alert-warning"></i>
                                                                    </Link>&nbsp;&nbsp;
                                                                    <Link
                                                                        data-toggle="modal"
                                                                        to="#"
                                                                        onClick={( e ) => this.DeleteSubCategory(e,value.uniquekey)}
                                                                    >
                                                                        <i className="fa fa-trash alert-danger"></i>
                                                                    </Link>
                                                                    </div>

                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Pagination
                                    pageSize={20}
                                    items={subcategory_listing}
                                    onChangePage={this.onChangePage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <Modals open={open} size="sm" >
                <ModalHeader title= {isEdit ? 'Edit Sub Category' : 'Add Sub Category' }  CloseModal={this.setOpen}/>
                <ModalBody>
                    <form>
                        <div className="row form-row label-star">
                            <div className="col-md-12">
                            <div className="form-group">
                                        <label>Category</label>
                                        <select
                                            type="text"
                                            className="form-control"
                                            name="category"
                                            value={this.state.category}
                                            onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                        >
                                            <option value="">Select Category</option>
                                            {category_listing && category_listing.map((value,index)=>{
                                                return (
                                                    <option
                                                        key={`category_${index}`}
                                                        value={value.uniquekey}
                                                    >
                                                        {value.name}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                        {this.formValidator.message( "Category", this.state.category,"required")}
                                    </div>
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input
                                            className="form-control"
                                            name="name"
                                            value={this.state.name}
                                            onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                        />
                                        {this.formValidator.message( "Name", this.state.name,"required")}
                                    </div>
                            </div>
                        </div>
                        <div className="field-holder">
                            <Link to="#" className="add-menu-item add-menu-item-list"  onClick={e => this.onSubmitSubCategory(e)}>Save</Link>
                        </div>
                    </form>
                </ModalBody>
            </Modals>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    category_listing:state.admin.category_listing,
    subcategory_listing:state.admin.subcategory_listing
});

export default connect(mapStateToProps)(SubCategory);
