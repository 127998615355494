import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Pagination from "./../../store/helpers/Pagination";
import Modals from './../../modules/Html/Modals';
import ModalHeader from './../../modules/Html/ModalHeader';
import ModalBody from './../../modules/Html/ModalBody';
import { MembershipListing,DeleteMembership } from './../../store/actions/agroActions'

import swal from 'sweetalert';
class AllMembership extends Component {
	constructor(props) {
		super(props);
		this.state = { pageOfItems: [], currentPage: 1,open:false,membershipID:'' };
	}

	componentDidMount() {
		let data = JSON.parse(localStorage.getItem("data"));
		let agroID = data["agroID"];
		this.props.dispatch(MembershipListing({ agroID }));
	}

	onChangePage = (pageOfItems, page) => {
		this.setState({ pageOfItems: pageOfItems, currentPage: page });
	};

	DeleteRow = (uniqueID) => {
		swal({
			title: "Are you sure want to delete this record?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					this.props.dispatch(DeleteMembership({ uniqueID }));
				} else {
					swal({ title: "Record is safe!", icon: "success", });
				}
			});
	}



	CloseModal = e => {
		this.setState({open:false})
	}

	render() {
		const { membership_list } = this.props.agro
		const { pageOfItems,membershipID,open } = this.state;
		const modalData = membershipID !== '' && membership_list.length > 0 ? membership_list.filter(item =>item.membershipID === membershipID) : []
		return (
			<>
			<div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
				<div className="user-dashboard">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="element-title has-border right-filters-row">
								<h5>My Memberships</h5>
								<Link to="/add-membership" className="add-menu-item collapsed margin-bottom-10 btn-black">
									Add Membership
								</Link>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="user-orders-list">
								<div className="table-responsive">
									{/* <ul className="table-generic table-generic-temp"> */}

												<ul className="table-generic table-generic-temp">
													<li className="order-heading-titles">
														<div>Created On</div>
														<div>Updated On</div>
														<div>Title</div>
														<div>Action</div>
													</li>
													{pageOfItems instanceof Array && pageOfItems.map((value, index) => (
																<li className="order-heading-titles" draggable={true} key={`category_${value.membershipID}`} >
																	<div>{value.created_at}</div>
																	<div>{value.lastupdate}</div>
																	<div>{value.title}</div>
																	<div>
																		<Link className="action-ico view-ico"  title="View" to="#"  onClick={e => this.setState({open:true,membershipID:value.membershipID})}>
																			<i className="fa fa-eye"></i>
																		</Link>&nbsp;&nbsp;

																		<Link className="action-ico edit-ico"  title="Edit"  to={"/edit-membership/" + value.uniqueID}>
																			<i className="fa fa-edit"></i>
																		</Link>&nbsp;&nbsp;

																		<Link className="action-ico del-ico"  title="Delete" to="#" onClick={e => this.DeleteRow(value.uniqueID)}>
																			<i className="fa fa-trash"></i>
																		</Link>&nbsp;&nbsp;

																	</div>
																</li>
															))}

												</ul>
								</div>
							</div>
						</div>
						{membership_list instanceof Array && (
							<Pagination
								pageSize={10}
								items={membership_list}
								onChangePage={this.onChangePage}
							/>
						)}
					</div>
				</div>
			</div>

			<Modals open={open} size="lg" >
				<ModalHeader CloseModal={this.CloseModal} title="View Membership"/>
					<ModalBody>
					{modalData && modalData.length > 0 && modalData[0] ? (
						<>
						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Title*</label>
								<p>{modalData[0].title}</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-4 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Price * (£)</label>
								<p>{modalData[0].price}</p>
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Description</label>
								<p
							className=''
							dangerouslySetInnerHTML={{
								__html: modalData[0]?.description ?? '<p className="recipeSummary"></p>',
							}}
							/>
							</div>
						</div>
						</>
					):null}

					</ModalBody>
			</Modals>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	agro: state.agro
});

export default connect(mapStateToProps)(AllMembership);
