import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SimpleReactvalidator from 'simple-react-validator'
import { AddRecepieVideo, ViewRecepieVideo } from './../../store/actions/restaurantActions'

const initialState = {
	title: '',
	description: '',
	editID: '',
	video: '',
	recipe_videos: [],
	newVideo: false
};

class RecepieBuilder extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.validator = new SimpleReactvalidator();
	}

	componentDidMount() {
		let uniqueID = this.props.match.params.recordingID;
		if (uniqueID) {
			this.props.dispatch(ViewRecepieVideo(uniqueID));
		}
	}

	componentDidUpdate(nextProps, prevState) {
		let video_data = this.props.video_data;
		if (!video_data || video_data === nextProps.video_data) {
			return false;
		}
		this.setState({
			title: video_data.title,
			description: video_data.description,
			editID: video_data.recordingID,
			// image: video_data.image,
			// video: video_data.video,
			recipe_videos: video_data.file ? video_data.file.split(",") : []
		})
	}

	addVideo = async (e) => {
		const { title, description, editID } = this.state;
		let response;
		if (this.validator.allValid()) {
			if (editID !== "") {
				response = await this.props.dispatch(AddRecepieVideo({ title, description, recordingID: editID }, this));
			} else {
				response = await this.props.dispatch(AddRecepieVideo({ title, description }));
			}
			if (response === 1) {
				this.props.history.push('/recepies-videos');
			}
		} else {
			this.validator.showMessages();
			this.forceUpdate()
		}
	}

	onChangeHandler = e => {
		this.setState({ [e.target.name]: e.target.value });
		if (e.target.name === "video") {
			var file = window.$('#file_icon1')[0].files;
			let recipe_videos = [];
			for (let i = 0; i < file.length; i++) {
				let imageFile = URL.createObjectURL(file[i]);
				recipe_videos.push(imageFile);
			}
			this.setState({
				recipe_videos: recipe_videos,
				newVideo: true
			})
		}
	}

	// openFileInput = e => {
	// 	this.fileinput.click()
	// }

	render() {
		const { title, description,editID, video } = this.state;
		this.validator.purgeFields();
		return (
			<div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">

				<div className="user-dashboard loader-holder">
					<div className="user-holder">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Title *</label>
								<input className="menu-item-title" id="title" name="title" value={title} onChange={e => this.onChangeHandler(e)} type="text"
									placeholder="Title" />
								{this.validator.message('title', title, 'required')}
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Description *</label>
								<textarea className="menu-item-title" id="description" name="description" value={description} onChange={e => this.onChangeHandler(e)} type="text"
									placeholder="Description"></textarea>
								{this.validator.message('description', description, 'required')}
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="form-group row">
								<label
									className="col-md-4"
									htmlFor="recipe_video"
								>
									Videos
                            </label>
								<div className="col-md-8">
									<div id="file_icon-sec" className="browse-btn-sec" style={{ display: 'block !important' }}>
										<input
											type="file"
											id="file_icon1"
											ref={(input) => { this.videoFileinput = input; }}
											name="video"
											value={video}
											onChange={this.onChangeHandler}
											className="browse-menu-icon-file"
											style={{ display: 'none' }}
											accept="video/*"
										/>
										<i
											className="fas fa-camera camera-ico"
											onClick={() => {
												this.videoFileinput.click();
											}}
										></i>
										{editID === "" ? this.validator.message('video', video, 'required') : null}

									</div>
								</div>
								<div className="col-md-8">
									{
										this.state.recipe_videos.map((value, index) => {
											let vdoSrc = value;
											// if (!this.state.newVideo) {
											// 	vdoSrc = bucketPath + vdoSrc
											// }
											return (
												<div key={"video_" + index} className="add-recepie-img-video">
													<Link to="#" onClick={()=>this.setState({recipe_videos:[]})}>
														<i className="fas fa-trash-alt del-profile-ico"></i>
													</Link>
													<video width="100" id={"video_" + index}>
														<source src={`${vdoSrc}`} />
                                                        Your browser does not support HTML video.
                                                    </video>
												</div>
											)

										})
									}
								</div>
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
							<div className="field-holder">
								<Link className="add-menu-item add-menu-item-list center-btn" to="#" onClick={e => this.addVideo(e)}>Save Video
								</Link >
							</div>
						</div>
					</div>
				</div >
			</div >
		);
	}
}

const mapStateToProps = (state) => ({
	video_data: state.restaurant.video_data
});

export default connect(mapStateToProps)(RecepieBuilder);
