import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {foodotherInformationUpdate,foodotherInformation} from './../../store/actions/foodActions';
import SimpleReactValidator from "simple-react-validator";
import "react-phone-number-input/style.css";

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
let start_time={}
let end_time={}
let closed = {}
let hideHour = {}
days.map(val => {
	start_time[`${val}_start`] = "";
    end_time[`${val}_end`] = "";
    closed[`${val}_closed`] = false
    hideHour[`${val}_hideHour`] = false
		return null
});


let selectedCheckboxes = []

class FoodOtherInformation extends Component {
    constructor(props) {
      super(props);
      this.state={
          start:"",
          paymentMethod:[],
					establishment_year:''
      }
			this.formValidator = new SimpleReactValidator();
      Object.assign(this.state,start_time,end_time);

    }



  toggleCheckbox = e => {

    const data = e.target.name
    if(selectedCheckboxes.length > 0){
      const index = selectedCheckboxes.indexOf(data);
      if(index > -1){
        selectedCheckboxes.splice(index,1)
      }
      else{
        selectedCheckboxes.push(data)
      }
    }
    else{
      selectedCheckboxes.push(data)
    }
    this.forceUpdate();

  }

  componentDidMount(){
      this.props.dispatch(foodotherInformation())
  }

  onTimeChange = (e, name) => {
      this.setState({[e.target.name]:e.target.value})
  }
  onChangeHandler = e => {
      this.setState({[e.target.name]:e.target.value})
  }

  onSubmitHandler = async() => {
		const validator = this.formValidator;
		if(validator.allValid()){
	      let data = [];
	          days.map((val) => {
	             return data.push({ day_name: val, "starttime": this.state[`${val}_start`], "endtime": this.state[`${val}_end`] });
	          });
	      const postdata = {data:data,payment_mode:selectedCheckboxes.toString(),establishment_year:this.state.establishment_year}
	      const response = await this.props.dispatch(foodotherInformationUpdate(postdata));
	      if(response===1){
	          //this.props.history.push('/restaurant-profile');
	          this.props.dispatch(foodotherInformation())
	      }

		}
		else{
				validator.showMessages();
				this.forceUpdate();
		}

  }


  componentDidUpdate = (prevProps, prevState) => {
      let nextProps = this.props;
      if(nextProps.food_other_information !== prevProps.food_other_information){
				this.setState({
					establishment_year:nextProps?.food_other_information?.data[0]?.establishment_year
				})
          let rest_data = nextProps.food_other_information.data
          let str = nextProps.food_other_information.data[0].payment_modes
          let payment_modes_data = str.split(",");
          if(selectedCheckboxes.length === 0){
            selectedCheckboxes.push(...payment_modes_data)
          }
          rest_data.map((val, i) => {
              this.setState({[`${val.day}_start`]:val.starttime,[`${val.day}_end`]:val.endtime});
              if(val.starttime === '' && val.endtime === ''){
                  this.setState({[`${val.day}_closed`]:true,[`${val.day}_hideHour`]:true})
              }
							return null;
          })
      }
  }

  ClosedHours=(e,id,val)=>{
      window.$('#'+id).addClass('hide');
      this.setState({[`${val}_start`]:'',[`${val}_end`]:'',[`${val}_closed`]:true});
  }

  OpenHours=(e,id,val)=>{
      window.$('#'+id).removeClass('hide');
      this.setState({[`${val}_hideHour`]:false,[`${val}_closed`]:false})
  }

	onChangeHandler = e => {
			this.setState({[e.target.name]:e.target.value})
	}
  render() {
    return (
      <>
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div className="user-dashboard loader-holder">
            <div className="user-holder">
                <div className="user-profile">
                    <div className="element-title has-border">
                    {
                      //<h5>Account Settings</h5>
                    }

                    </div>
                    <div className="row">
                      <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                      <form id="publisher_profile">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div className="row">
															{
																// <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
																// 	<label className="other-information-heading">Company Information</label>
																// 	</div>
															}

																<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
																	<label>Year Of Establishment*</label>
																</div>
															<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
																	<div className="field-holder">

																			<input type="text" className="foodbakery-dev-req-field" name="establishment_year" value={this.state.establishment_year} onChange={this.onChangeHandler.bind(this)} />
																			{this.formValidator.message("Year Of Establishment", this.state.establishment_year, "required|max:50,string")}
																	</div>
															</div>
															<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-line"></div>
														<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
															<label className="other-information-heading">Hours Of Operation</label>
															</div>
                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="field-holder field-holder-custom">
                                      <div className="time-list">
                                          <ul>
                                          {
                                          days.map((val, i) => {
                                              return (
                                                  <li key={i}>
                                                      <div className="open-close-time opening-time">
                                                          <div className="day-sec">
                                                              <span>{val}</span>
                                                          </div>
                                                          <div className={`time-sec ${this.state[`${val}_hideHour`] ? 'hide' : ''}`} id={i} >
                                                              <select className="" name={`${val}_start`} value={this.state[`${val}_start`]} onChange={(e) => this.onTimeChange(e)}>
                                                                  <option>Select</option>
                                                                  <option value="00:00">12:00 AM</option>
                                                                  <option value="01:00">01:00 AM</option>
                                                                  <option value="02:00">02:00 AM</option>
                                                                  <option value="03:00">03:00 AM</option>
                                                                  <option value="04:00">04:00 AM</option>
                                                                  <option value="05:00">05:00 AM</option>
                                                                  <option value="06:00">06:00 AM</option>
                                                                  <option value="07:00">07:00 AM</option>
                                                                  <option value="08:00">08:00 AM</option>
                                                                  <option value="09:00">09:00 AM</option>
                                                                  <option value="10:00">10:00 AM</option>
                                                                  <option value="11:00">11:00 AM</option>
                                                                  <option value="12:00">12:00 PM</option>
                                                                  <option value="13:00">01:00 PM</option>
                                                                  <option value="14:00">02:00 PM</option>
                                                                  <option value="15:00">03:00 PM</option>
                                                                  <option value="16:00">04:00 PM </option>
                                                                  <option value="17:00">05:00 PM</option>
                                                                  <option value="18:00">06:00 PM</option>
                                                                  <option value="19:00">07:00 PM</option>
                                                                  <option value="20:00">08:00 PM</option>
                                                                  <option value="21:00">09:00 PM</option>
                                                                  <option value="22:00">10:00 PM</option>
                                                                  <option value="23:00">11:00 PM</option>
                                                              </select>
                                                              <span className="option-label">to</span>
                                                              <select className="" name={`${val}_end`} value={this.state[`${val}_end`]} onChange={(e) => this.onTimeChange(e, `${val}_end`)} >
                                                                  <option>Select</option>
                                                                  <option value="00:00">12:00 AM</option>
                                                                  <option value="01:00">01:00 AM</option>
                                                                  <option value="02:00">02:00 AM</option>
                                                                  <option value="03:00">03:00 AM</option>
                                                                  <option value="04:00">04:00 AM</option>
                                                                  <option value="05:00">05:00 AM</option>
                                                                  <option value="06:00">06:00 AM</option>
                                                                  <option value="07:00">07:00 AM</option>
                                                                  <option value="08:00">08:00 AM</option>
                                                                  <option value="09:00">09:00 AM</option>
                                                                  <option value="10:00">10:00 AM</option>
                                                                  <option value="11:00">11:00 AM</option>
                                                                  <option value="12:00">12:00 PM</option>
                                                                  <option value="13:00">01:00 PM</option>
                                                                  <option value="14:00">02:00 PM</option>
                                                                  <option value="15:00">03:00 PM</option>
                                                                  <option value="16:00">04:00 PM </option>
                                                                  <option value="17:00">05:00 PM</option>
                                                                  <option value="18:00">06:00 PM</option>
                                                                  <option value="19:00">07:00 PM</option>
                                                                  <option value="20:00">08:00 PM</option>
                                                                  <option value="21:00">09:00 PM</option>
                                                                  <option value="22:00">10:00 PM</option>
                                                                  <option value="23:00">11:00 PM</option>
                                                              </select>
                                                              <Link to="#" value={`${i}`}  onClick={(e)=>this.ClosedHours(e,i,val)} >
                                                                <i className="icon-close2"  value={`${i}`}></i>
                                                              </Link>
                                                          </div>
                                                          <div className={`time-sec ${this.state[`${val}_closed`] ? '' : 'hide'}`} >
                                                              <Link to="#" value={`${i}`}  onClick={(e)=>this.OpenHours(e,i,val)} >
                                                                <i className="fa fa-plus-circle" aria-hidden="true" value={`${i}`}></i>
                                                              </Link>
                                                               <h4>Closed</h4>
                                                          </div>
                                                      </div>

                                                  </li>
                                              )
                                          })
                                          }
                                      </ul>
                                  </div>
                              </div>
                          </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-line"></div>
                                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <label className="other-information-heading">Payment Modes Accepted By You</label>
                                    </div>

                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                      <input type="checkbox" className="foodbakery-dev-req-field closed-checkbox" name="Cash" checked = {selectedCheckboxes.indexOf("Cash") > -1 ? true :false}   onChange={this.toggleCheckbox.bind(this)} />
                                      <label className="closed-label">Cash</label>
                                      </div>

                                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        <input type="checkbox" className="foodbakery-dev-req-field closed-checkbox"   name="Master Card" checked = {selectedCheckboxes.indexOf("Master Card") > -1 ? true :false}  onChange={this.toggleCheckbox.bind(this)} />
                                        <label className="closed-label">Master Card</label>
                                        </div>

                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                          <input type="checkbox" className="foodbakery-dev-req-field closed-checkbox"   name="Visa Card" checked = {selectedCheckboxes.indexOf("Visa Card") > -1 ? true :false}   onChange={this.toggleCheckbox.bind(this)} />
                                          <label className="closed-label">Visa Card</label>
                                          </div>


                                          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                            <input type="checkbox" className="foodbakery-dev-req-field closed-checkbox"   name="Debit Card" checked = {selectedCheckboxes.indexOf("Debit Card") > -1 ? true :false}  onChange={this.toggleCheckbox.bind(this)} />
                                            <label className="closed-label">Debit Card</label>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                              <input type="checkbox" className="foodbakery-dev-req-field closed-checkbox"   name="Credit Card" checked = {selectedCheckboxes.indexOf("Credit Card") > -1 ? true :false}   onChange={this.toggleCheckbox.bind(this)} />
                                              <label className="closed-label">Credit Card</label>
                                              </div>

                                              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                <input type="checkbox" className="foodbakery-dev-req-field closed-checkbox"   name="Money Order" checked = {selectedCheckboxes.indexOf("Money Order") > -1 ? true :false}   onChange={this.toggleCheckbox.bind(this)} />
                                                <label className="closed-label">Money Order</label>
                                                </div>

                                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>

                              </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div className="field-holder text-center">
                                  <button name="button" type="button" className="btn btn-black" onClick={this.onSubmitHandler.bind(this)}>SAVE & CONTINUE</button>
                              </div>
                          </div>
                      </form>
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
    );
  }
}

const mapStateToProps = (state) => ({
    food_other_information : state.food.food_other_information
});

export default connect(mapStateToProps)(FoodOtherInformation);
