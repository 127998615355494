import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Pagination from "./../../store/helpers/Pagination";
import Modals from './../../modules/Html/Modals';
import ModalHeader from './../../modules/Html/ModalHeader';
import ModalBody from './../../modules/Html/ModalBody';
import SimpleReactValidator from 'simple-react-validator'; 
import swal from 'sweetalert';
import {BaseUrl,bucketPath } from "./../../store/helpers/common";
import { 
    AddAgroProduct, 
    EditAgroProduct, 
    AgroProductListing,
    DeleteAgroProduct,
    AgroProductImagesListing,
    DeleteAgroProductImage
} from './../../store/actions/agroActions';
import {ShowLoader,ShowAlert} from "./../../store/actions/staticActions";


const axios = require("axios");

const initialState = {pageOfItems: [], currentPage: 1 ,open:false,isEdit:false,modalType:'productDetails',name:'',status:'',tax:'',price:'',quantity:'',product_highlight:'',description:''}
 
class AgroProducts extends Component {
	constructor(props) {
        super(props);
        this.formValidator = new SimpleReactValidator();
		this.state = initialState;
	}

    componentWillMount(){
        this.props.dispatch(AgroProductListing())
    }
	 
		 

	onChangePage = (pageOfItems, page) => {
		this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };
    
    setOpen = (value) => {
        this.setState({
            open: false, 
            isEdit: false
        });
    }

    onSubmitProduct = async (e) => {
        const {name,price,quantity,status,description,product_highlight,id,isEdit,tax} = this.state
        if (this.formValidator.allValid()) {
            if(isEdit){
                const data = {uniquekey:id,name,price,quantity,status,description,product_highlight,tax}
                let response = await this.props.dispatch(EditAgroProduct(data))
                if(response === 1){
                    this.props.dispatch(AgroProductImagesListing({id:id}));
                    this.setState({
                        modalType:'productImages'
                    })
                }
            }else{
                const data = {name,price,quantity,status,description,product_highlight,tax}
                let response = await this.props.dispatch(AddAgroProduct(data))
                if(response === 1){
                    this.setState({
                        isEdit:false,
                        modalType:'productImages'
                    })
                }
            }

        } else {
            this.formValidator.showMessages();
            this.forceUpdate();
          }
          e.preventDefault();
    }

    EditAgroProduct = (uniquekey) => {
        const request = new Request(`${BaseUrl}/api/agrocenter/view_product`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-Auth-Token": `${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({uniquekey}),
        });
        return fetch(request)
            .then((res) => res.json())
            .then((res) => {
                const data = res.data;
                this.setState({
                    id: data.uniquekey,
                    name:data.name,
                    category:data.category,
                    subcategory:data.sub_category,
                    quantity:data.quantity,
                    price:data.price,
                    status:data.status,
                    description:data.description,
                    product_highlight:data.product_highlight,
                    tax:data.tax,
                    isEdit: true,
                    modalType:'productDetails',
                    open: true,
                });
            });
    };


    DeleteAgroProduct = (uniquekey) => {
        swal({
            title: "Are you sure you want to delete this Product?",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.dispatch(DeleteAgroProduct({uniquekey}));
            } else {
                console.log("you clicked on false");
            }
        });
    };

    uploadImage = (e) => {
        let localID=  localStorage.getItem('agro_product');
        let ID = this.state.isEdit ? this.state.id : localID
        this.props.dispatch(ShowLoader(true));
        let request;
        let formData = new FormData();
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
        formData.append(`file[${i}]`, files[i]);
        }
        formData.append('id',ID);
        request = {
          method: "post",
          url: `${BaseUrl}/api/agrocenter/upload_product_image`,
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Auth-Token": `${localStorage.getItem("token")}`,
          },
          data: formData,
        };
        axios(request)
          .then((res) => {
            if (res.data.ResponseCode === "1") { 
                this.props.dispatch(AgroProductImagesListing({id:ID}));
                this.props.dispatch(ShowLoader(false));
                this.props.dispatch(ShowAlert({ Class: "alert-success", Message: res.data.ResponseText, Timeout: 5000 }));
            }
          })
          .catch((error) => {
            this.props.dispatch(ShowLoader(false));
            console.log(error);
          });
      };

      DeleteImageProduct = (e,value) => {
          const {isEdit,id}=this.state
          let data = {value,isEdit,id}
        swal({
            title: "Are you sure you want to delete this Product Image?",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.dispatch(DeleteAgroProductImage(data));
            } else {
                console.log("you clicked on false");
            }
        });
    };

    handleOpenModal = () =>{
        this.setState({
            open:true,
            isEdit:false,
            modalType:'productDetails',
            name:'',
            price:"",
            quantity:'',
            tax:'',
            status:'',
            product_highlight:'',
            description:''
        })
    }
	 

	render() { 
        const { pageOfItems,modalType,open,isEdit } = this.state;
        const { agro_product,agro_product_images } = this.props
		return (
            <>
			<div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
				<div className="user-dashboard">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="element-title has-border right-filters-row">
								<h5>My Products</h5>
                                <Link 
                                    to="#" 
                                    className="add-menu-item collapsed margin-bottom-10 btn-black"
                                    onClick={()=>this.handleOpenModal()}
                                > 
                                    Add Product
								</Link>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="user-orders-list">
								<div className="table-responsive">  
                                    <ul className="table-generic table-generic-temp">
                                        <li className="order-heading-titles">
                                            <div>Product ID</div>
                                            <div>Name</div>
                                            <div>Price</div>
                                            <div>Quantity</div>
                                            <div>Action</div>
                                        </li>
                                        {pageOfItems instanceof Array && pageOfItems.map((value, index) => {
                                        
                                            return ( 
                                            <li className="order-heading-titles" key={`agro_product_${index}`}>
                                                <div>{value.uniquekey}</div>
                                                <div>{value.name}</div>
                                                <div>{`₹ ${value.price}`}</div>
                                                <div>{value.quantity}</div>
                                                <div> 
                                                    <Link 
                                                        className="action-ico edit-ico" 
                                                        to="#"
                                                        onClick={() =>this.EditAgroProduct(value.uniquekey)}
                                                    >
                                                        <i className="fa fa-edit"></i>
                                                    </Link>&nbsp;&nbsp; 
                                                    <Link 
                                                        className="action-ico del-ico"  
                                                        to="#" 
                                                        onClick={() => this.DeleteAgroProduct(value.uniquekey)}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Link> 

                                                </div>
                                            </li> 
                                        )})}
                                    </ul> 
								</div>
							</div>
						</div>
						{agro_product instanceof Array && (
							<Pagination
								pageSize={10}
								items={agro_product}
								onChangePage={this.onChangePage}
							/>
						)}
					</div>
				</div>
			</div>
             <Modals open={open}>
             <ModalHeader title= {isEdit ? 'Edit Product' : 'Add Product' }  CloseModal={this.setOpen}/>
             <ModalBody>
                {modalType === 'productDetails' && <form>
                     <div className="row form-row label-star product-add">
                        <div className="col-md-12">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        type='text'
                                        className=""
                                        name="name"
                                        value={this.state.name}
                                        onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                    />
                                    {this.formValidator.message( "Name", this.state.name,"required")}
                                </div> 
                            </div>
                            <div className="col-md-6">
                            <div className="form-group">
                                    <label> Quantity</label>
                                    <input
                                        type="text"
                                        className=""
                                        name="quantity"
                                        value={this.state.quantity}
                                        onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                    />
                                    {this.formValidator.message( "Quantity", this.state.quantity,"required")}
                                </div> 
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="col-md-6">
                                <div className="form-group"> 
                                    <label>Price</label>
                                    <div className="mb-3 admin-price-input">
                                        <span className=" price-input-text">₹</span>
                                        <input
                                            type="number"
                                            className=""
                                            name="price"
                                            value={this.state.price}
                                            onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                        />
                                    </div>
                                    {this.formValidator.message( "Price", this.state.price,"required")}
                                </div> 
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Tax</label>
                                    <div className="mb-3 admin-price-input">
                                        <span className=" price-input-text">%</span>
                                        <input
                                            type="number"
                                            className=""
                                            name="tax"
                                            value={this.state.tax}
                                            onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                        />
                                    </div>
                                    {this.formValidator.message( "tax", this.state.tax,"required")}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                 <label>Status</label>
                                 <select
                                     type="text"
                                     className=""
                                     name="status"
                                     value={this.state.status}
                                     onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                 >
                                     <option value="">Select Status</option>
                                     <option value="A">Active</option>
                                     <option value="D">Inactive</option>
                                 </select>
                                 {this.formValidator.message( "Status", this.state.status,"required")}
                             </div>
                             <div className="form-group">
                                 <label>Product Highlight</label>
                                 <textarea
                                     className=""
                                     name="product_highlight"
                                     value={this.state.product_highlight}
                                     onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                 >
                                 </textarea>
                                 {this.formValidator.message( "Product Highlight", this.state.product_highlight,"required")}
                             </div>
                             <div className="form-group">
                                 <label>Description</label>
                                 <textarea
                                     className=""
                                     name="description"
                                     value={this.state.description}
                                     onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                 >
                                 </textarea>
                                 {this.formValidator.message( "Description", this.state.description,"required")}
                             </div>
                        </div> 
                     </div>
                     <div className="field-holder">
                         <Link to="#" className="add-menu-item add-menu-item-list btn-black"  onClick={e => this.onSubmitProduct(e)}>Save</Link>
                     </div>
                 </form>  }

                {modalType === 'productImages' && <form>
                    <div className="row form-row label-star">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="product_image"
                                    name="product_image"
                                    onChange={(e) =>
                                        this.uploadImage(e)
                                    }
                                    multiple
                                    aria-describedby="inputGroupFileAddon01"
                                    accept="image/*"
                                    />
                            </div>
                            <div className="argoupload-img add-product-img col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                {agro_product_images && agro_product_images.map((value,index) => {
                                    return (
                                        <div className="add-recepie-img-video"  key={`agro_${index}`} >
                                            <Link to="#"  onClick={( e ) => this.DeleteImageProduct(e,value.image)}>
                                                <i className="fas fa-trash-alt del-profile-ico"></i>
                                            </Link>
                                            <img
                                                alt="User"
                                                src={`${bucketPath}${value.image}`}
                                            />
                                        </div>
                                    );
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                </form> }
             </ModalBody>
         </Modals>
         </>
		);
	}
}

const mapStateToProps = (state) => ({
    agro_product: state.agro.agro_product,
    agro_product_images:state.agro.agro_product_images
});

export default connect(mapStateToProps)(AgroProducts);
