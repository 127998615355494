import React, { Component } from "react";
import { connect } from "react-redux";
import MapAutocomplete from './../gMaps/MapAutocomplete';
import MapModal from './../modals/MapModal';
import {foodlocationInformation,foodlocationInformationUpdate} from './../../store/actions/foodActions';
import SimpleReactValidator from "simple-react-validator";
const initialState = {building:'',landmark:"",city:'',zipcode:'',state:'',country :'',location:'',longitude:'',latitude:'',openMap:false}


class FoodLocationInformation extends Component {
    constructor(props) {
        super(props);
        this.formValidator = new SimpleReactValidator();
        this.state = initialState;
      }

    componentDidMount(){
        this.props.dispatch(foodlocationInformation())
    }

    onChangeHandler = e => {
        this.setState({[e.target.name]:e.target.value})
    }

    openModal = () => {
        this.setState({ openMap: true });
      };

      closeModal = () => {
        this.setState({ openMap: false });
      };


    componentDidUpdate = (prevProps, prevState) => {
        let nextProps = this.props;
        if(nextProps.food_location_information !== prevProps.food_location_information){
          this.setState({
            building: nextProps?.food_location_information?.data?.building,
            landmark:nextProps?.food_location_information?.data?.landmark,
            city:nextProps?.food_location_information?.data?.city,
            zipcode:nextProps?.food_location_information?.data?.zipcode,
            state:nextProps?.food_location_information?.data?.state,
            country :nextProps?.food_location_information?.data?.country,
            location:nextProps?.food_location_information?.data?.location,
            longitude:nextProps?.food_location_information?.data?.longitude,
            latitude:nextProps?.food_location_information?.data?.latitude,
          })

        }
      }



    onSubmitHandler = async() => {
        const validator = this.formValidator;
        const data = {
          building:this.state.building,
          landmark:this.state.landmark,
          city:this.state.city,
          zipcode:this.state.zipcode,
          state:this.state.state,
          country :this.state.country,
          location:this.state.location,
          longitude:this.state.longitude,
          latitude:this.state.latitude
        }
        if(validator.allValid()){
          const response = await this.props.dispatch(foodlocationInformationUpdate(data));
          if(response===1){
              this.setState(initialState)
              this.props.dispatch(foodlocationInformation())
          }
        }else{
            validator.showMessages();
            this.forceUpdate();
        }
    }

    openModal = () => {
      this.setState({ openMap: true });
    };

    closeModal = () => {
      this.setState({ openMap: false });
    };

    FetchAddress = (data) => {
      const location = `${data.area}, ${data.state}${
        data.zip ? `, ${data.zip}` : ''
      }`;
      this.setState({
        location: location,
        longitude: data.lngValue,
        latitude: data.latValue,
        city: data.city,
        state: data.state,
        zipcode: data.zip,
      });
    };


  render() {

    return (
      <>
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div className="user-dashboard loader-holder">
            <div className="user-holder">
                <div className="user-profile">
                    <div className="element-title has-border">
                    {
                      //<h5>Account Settings</h5>
                    }

                </div>
                      <form id="publisher_profile">
                            <div className="form-fields-set">
                            <ul>
                                <li>
                                    <div className="row">
                                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                      <div className='field-holder'>
                                        <label>Location *</label>
                                        <div className='select-holder'>
                                          <span>
                                            <i
                                              className='fa fa-map-marker'
                                              style={{ color: 'red' }}
                                              aria-hidden='true'
                                              onMouseUp={this.openModal}
                                            ></i>
                                          </span>
                                          <div className='foodbakery_editor1'>
                                            <input
                                              type='text'
                                              name='location'
                                              value={this.state.location}
                                              onChange={this.onChangeHandler.bind(this)}
                                            />
                                            {this.formValidator.message(
                                              'location',
                                              this.state.location,
                                              'required|max:50,string'
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="field-holder">
                                                <label>Building*</label>
                                                <input type="text" className="foodbakery-dev-req-field" name="building" value={this.state.building} onChange={this.onChangeHandler.bind(this)} />
                                                {this.formValidator.message("Building", this.state.building, "required|max:50,string")}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="field-holder">
                                                <label>LandMark*</label>
                                                <input type="text" className="foodbakery-dev-req-field" name="landmark" value={this.state.landmark} onChange={this.onChangeHandler.bind(this)} />
                                                {this.formValidator.message("LandMark", this.state.landmark, "required|max:50,string")}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="field-holder">
                                                <label>City*</label>
                                                <input type="text" className="foodbakery-dev-req-field" name="city" value={this.state.city} onChange={this.onChangeHandler.bind(this)} />
                                                {this.formValidator.message("City", this.state.city, "required|max:50,string")}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="field-holder">
                                                <label>Pincode*</label>
                                                <input type="number" className="foodbakery-dev-req-field" name="zipcode" value={this.state.zipcode} onChange={this.onChangeHandler.bind(this)} />
                                                {this.formValidator.message("Pincode", this.state.zipcode, "required|max:50,string")}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="field-holder">
                                                <label>State*</label>
                                                <input type="text" className="foodbakery-dev-req-field" name="state" value={this.state.state} onChange={this.onChangeHandler.bind(this)} />
                                                {this.formValidator.message("State", this.state.state, "required|max:50,string")}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="field-holder">
                                                <label>Country*</label>
                                                <input type="text" className="foodbakery-dev-req-field" name="country" value={this.state.country} onChange={this.onChangeHandler.bind(this)} />
                                                {this.formValidator.message("Country", this.state.country, "required|max:50,string")}
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                          <MapModal
                                            openMap={this.state.openMap}
                                            closeModal={this.closeModal}
                                          >
                                            <MapAutocomplete
                                              center={{ lat: 18.5204, lng: 73.8567 }}
                                              height='400px'
                                              zoom={16}
                                              FetchAddress={this.FetchAddress}
                                            />
                                          </MapModal>
                                        </div>

                                    </div>
                                </li>
                            </ul>
                            </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div className="field-holder text-center">
                                        <button name="button" type="button" className="btn btn-black" onClick={this.onSubmitHandler.bind(this)}>SAVE & CONTINUE</button>
                              </div>
                          </div>
                      </form>
                </div>
            </div>
        </div>
    </div>
    </>
    );
  }
}

const mapStateToProps = (state) => ({
    food_location_information : state.food.food_location_information
});

export default connect(mapStateToProps)(FoodLocationInformation);
