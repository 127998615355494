import * as actionTypes from './../types/types';
import {queryResponse} from './../helpers/userServices';
import {BaseUrl} from './../helpers/common' 


export const fetchStart = () => {
    return {
        type: actionTypes.FETCH_DATA_BEGIN
    }
}

export function mapAddress(data) {
    return dispatch => {
        dispatch({type: actionTypes.GOOGLE_MAP_ADDRESS,payload: data})
    }
}

export function detectAddress(data){
    return dispatch => {
        const request = new Request(`${BaseUrl}/api/detect-address`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({'Content-Type': 'application/json'})
        });
        return fetch(request).then(queryResponse).then((response) => {
            if (response.ResponseCode === '1') {
                return {code:1,data:response.data};
            } else {
                return {code:0,response:response.ResponseText};
            }
        }).catch(error => {
            return {ResponseCode:'0'};
        });
    }
}

// export function clinicNumber(data) {
//     return dispatch => {
//         dispatch({type: actionTypes.GOOGLE_MAP_CLINIC,payload: data})
//     }
// }

// export function mapClinicAddress(data) {
//     return dispatch => {
//         dispatch({type: actionTypes.GOOGLE_MAP_CLINIC_ADDRESS,payload: data})
//     }
// }
