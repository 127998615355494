import { BaseUrl } from '../helpers/common';
//import * as actionTypes from '../types/types';
import { handleResponse} from '../helpers/userServices';
import swal from 'sweetalert';
import { ShowLoader } from './staticActions'

export function MakePayment(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/stripe/make-payment`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json','X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((responseData) => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                swal({ title: "Success!", text: responseData.ResponseText, icon: "success" });
                return 1;
            }else{
                swal({ title: "error!", text: responseData.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            swal({ title: "error!", text: error, icon: "error" });
            dispatch(ShowLoader(false));
            return 0;
        });;
    }
}
