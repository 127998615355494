import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CKEditor from 'ckeditor4-react';
import SimpleReactvalidator from 'simple-react-validator'
import { AddMembership,ViewMembership } from './../../store/actions/agroActions'

const initialState = {
	title: '',
	description: '',
	price:'',
	editID: ''
};



class MembershipBuilder extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.validator = new SimpleReactvalidator();
	}

	componentDidMount() {
		let uniqueID = this.props.match.params.uniqueID;
		if (uniqueID) {
			this.props.dispatch(ViewMembership(uniqueID));
		}

	}

	componentDidUpdate(nextProps, prevState) {
		let membership_data = this.props.membership_data;
		if ((!membership_data || membership_data === nextProps.membership_data)) {
			return false;
		}
		this.setState({
			title: membership_data.title,
			description: membership_data.description,
			price: membership_data.price,
			editID: membership_data.membershipID,
		})
	}



	handleDescEditorChange = (content, editor) => {
		this.setState({ description: content })
	}

	addRecepie = async (e) => {
		const { title, price, description, editID} = this.state;
		let response;
		if (this.validator.allValid()) {
			if (editID !== "") {
				response = await this.props.dispatch(AddMembership({ title,price, description, membershipID: editID,}, this));
			} else {
				response = await this.props.dispatch(AddMembership({ title,price, description, membershipID: editID}));
			}
			if (response === 1) {
				this.props.history.push('/all-membership');
			}
		} else {
			this.validator.showMessages();
			this.forceUpdate()
		}
	}


	onChangeHandler = e => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onEditorChange = ( evt ) => {
	        this.setState( {
	            description: evt.editor.getData()
	        } );
	    }


	render() {
		const { title, price, description,} = this.state;
		this.validator.purgeFields();


		return (
			<div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">

				<div className="user-dashboard loader-holder">
					<div className="user-holder">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Title *</label>
								<input className="menu-item-title" id="title" name="title" value={title} onChange={e => this.onChangeHandler(e)} type="text"
									placeholder="eg: Members Benefits" />
								{this.validator.message('title', title, 'required')}
							</div>
						</div>


						<div className="col-lg-12 col-md-12 col-sm-3 col-xs-6">
							<div className="field-holder">
								<label>Price *</label>
								<input className="menu-item-title" id="price" name="price" value={price} onChange={e => this.onChangeHandler(e)} type="number"
									placeholder="eg: 200" />
								{this.validator.message('Price', price, 'required')}
							</div>
						</div>

						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Description *</label>
								<CKEditor
                    data={this.state.description}
                    onChange={this.onEditorChange} />
										{
											// <Editor
											// 	apiKey="1ivg36dmtmtmhn742kx1fndzhnxxmn9wzvo5u5bwlv6kj4kn"
											// 	init={{
											// 		height: 200,
											// 		plugins: [
											// 			"advlist autolink lists link image",
											// 			"charmap print preview anchor help",
											// 			"searchreplace visualblocks code",
											// 			"insertdatetime media table paste wordcount",
											// 		],
											// 		menubar: 'file edit view insert format tools table help',
											// 		toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
											// 	}}
											// 	value={this.state.description}
											// 	onEditorChange={this.handleDescEditorChange}
											// />
										}

								{this.validator.message('description', description, 'required')}
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
							<div className="field-holder">
								<Link className="add-menu-item add-menu-item-list center-btn btn-black" to="#" onClick={e => this.addRecepie(e)}>Save Membership
								</Link >
							</div>
						</div>
					</div>
				</div >
			</div >
		);
	}
}

const mapStateToProps = (state) => ({
	membership_data: state.agro.membership_data,
});

export default connect(mapStateToProps)(MembershipBuilder);
