import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RestaurantReviewListing} from '../../../../store/actions/restaurantActions';
import Pagination from "../../../../store/helpers/Pagination";


class Reviews extends Component {
  constructor(props) {
    super(props);
    this.state = { pageOfItems: [], currentPage: 1 };
  }

  componentDidMount() {
    let uniqueID = this.props.review_token;
    this.props.dispatch(RestaurantReviewListing({ "uniquekey": uniqueID }));
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

  render() {
    const {
      restaurant_data: { restaurant_review },
    } = this.props;
    const { pageOfItems} = this.state;
    return (
        <div className="reviews-holder">
          <div
            className="add-new-review-holder"
            style={{ display: "none" }}
          >
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="elements-title">
                  <h3>Rate and Write a Review</h3>
                  <Link
                    to="#"
                    className="close-post-new-reviews-btn"
                  >
                    Close
                  </Link>
                </div>
              </div>
              <div
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                style={{ textAlign: "center" }}
              >
                Please login in order to post review.
                            </div>
            </div>
          </div>
          <div className="reviwes-restaurant-holder">
            <div className="review-listing">
              <div className="elements-title">
                <h5>
                  Customer Reviews
                </h5>
                <div className="sort-by">
                  <span
                    className="ajax-loader-sorty-by"
                    style={{ display: "none" }}
                  >
                    <img
                      src="/assets/images/ajax-loader.gif"
                      alt=""
                    />
                  </span>
                  <ul className="reviews-sortby">
                    <li>
                      <span className="active-sort">
                        Newest Reviews
                                    </span>
                      <div className="reviews-sort-dropdown">
                        <form>
                          <div className="input-reviews">
                            <div className="radio-field">
                              <input
                                name="review"
                                id="check-1"
                                type="radio"
                                defaultValue="newest"
                                checked="checked"
                                readOnly
                              />
                              <label htmlFor="check-1">
                                Newest Reviews
                                            </label>
                            </div>
                            <div className="radio-field">
                              <input
                                name="review"
                                id="check-2"
                                type="radio"
                                defaultValue="highest"
                              />
                              <label htmlFor="check-2">
                                Highest Rating
                                            </label>
                            </div>
                            <div className="radio-field">
                              <input
                                name="review"
                                id="check-3"
                                type="radio"
                                defaultValue="lowest"
                              />
                              <label htmlFor="check-3">
                                Lowest Rating
                                            </label>
                            </div>
                          </div>
                        </form>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <ul className="review-restaurant">
                {
                  pageOfItems instanceof Array && pageOfItems.map((value, index) => (
                    <li className="col-lg-12 col-md-12 col-sm-12 col-xs-12 " key={`${"review_" + index}`}>
                      <div className="list-holder ">
                        <div className="review-text">
                          <div className="review-title">
                            <h6>{value.title} </h6>
                            <div className="rating-holder">
                              <div className="rating-star">
                                <span
                                  style={{ width: value.points + "%" }}
                                  className="rating-box"
                                ></span>
                              </div>
                            </div>
                          </div>
                          <em className="review-date">{value.lastupdate.split(" ")[0]}</em>
                          <p>{value.description}</p>
                        </div>
                      </div>
                    </li>
                  ))
                }
                {/* <li className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                  <div className="list-holder ">
                    <div className="review-text">
                      <div className="review-title">
                        <h6>Best Food </h6>
                        <div className="rating-holder">
                          <div className="rating-star">
                            <span
                              style={{ width: "100%" }}
                              className="rating-box"
                            ></span>
                          </div>
                        </div>
                      </div>
                      <em className="review-date">25 Apr 2018</em>
                      <p>A wonderfull experience. </p>
                    </div>
                  </div>
                </li>
                <li className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                  <div className="list-holder ">
                    <div className="review-text">
                      <div className="review-title">
                        <h6>Best food </h6>
                        <div className="rating-holder">
                          <div className="rating-star">
                            <span
                              style={{ width: "100%" }}
                              className="rating-box"
                            ></span>
                          </div>
                        </div>
                      </div>
                      <em className="review-date">25 Apr 2018</em>
                      <p>I had great food the other day </p>
                    </div>
                  </div>
                </li> */}
              </ul>
              {restaurant_review instanceof Array && (
                <Pagination
                  pageSize={10}
                  items={restaurant_review}
                  onChangePage={this.onChangePage}
                />
              )}
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  restaurant_review: state.restaurant.restaurant_review
});

export default connect(mapStateToProps)(Reviews);
