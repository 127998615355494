import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AdminAgroList,DeleteAdminRecord } from './../../store/actions/adminActions'
import Pagination from './../../store/helpers/Pagination' 
import Modal from "../../modules/Html/Modal";
import swal from 'sweetalert';
import moment from 'moment'


// const avatarCss =  {
//     verticalAlign: "middle",
//     width: "35px",
//     height: "35px",
//     borderRadius: "50%",
// }

class AdminAgroCenter extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pageOfItems: [], currentPage: 1,open:false,viewData:[] }
    }

    componentDidMount() {
        this.props.dispatch(AdminAgroList())

    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page,open:false });
    }
    CloseModal=()=>{
        this.setState({open:false,viewData:[]})
    }

    DeleteUser = (id) => {
        let data={type:'agroCenter',uniqueID:id}
        swal({
            title: "Are you sure you want to delete this User?",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.dispatch(DeleteAdminRecord(data));
            } else {
                console.log("Record is Safe");
            }
        });
      };

    render() {
        const { pageOfItems, currentPage,open,viewData } = this.state
        const { admin_agro_list } = this.props 
        const modelbuilding = viewData && Object.keys(viewData).length > 0 && viewData.building !== null && viewData.building !== "" ? viewData.building + "," : "";
        const modelcity = viewData && Object.keys(viewData).length > 0 && viewData.city !== null && viewData.city !== ""  ? viewData.city + "," : "";
        const modelstate = viewData && Object.keys(viewData).length > 0 && viewData.state !== null && viewData.state !== "" ? viewData.state + "," : "";
        const modelcountry = viewData && Object.keys(viewData).length > 0 && viewData.country !== null && viewData.country !== "" ? viewData.country  : "";
        // const modelpincode = viewData && Object.keys(viewData).length > 0 && viewData.pincode !== null && viewData.pincode !== "" ? viewData.pincode : "";
        return (
            <div className="col-lg-10 col-md-9 col-sm-12 col-xs-12">
                <div className="form-elements">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="user-orders-list">
                                <div className="table-responsive">
                                    <ul className="table-generic table-generic-temp">
                                        <li className="order-heading-titles">
                                            <div>Sr No</div>
                                            <div>Name</div>
                                            <div>Email</div>
                                            <div>Phone No.</div>
                                            <div>Address</div>
                                            <div>Action</div>
                                        </li>
                                        {pageOfItems instanceof Array &&
                                            pageOfItems.map((value, index) => {
                                              const building =  value.building !== null && value.building !== "" ? value.building + "," : "";
                                              const city =  value.city !== null && value.city !== ""  ? value.city + "," : "";
                                              const state =  value.state !== null && value.state !== "" ? value.state + "," : "";
                                              const country =  value.country !== null && value.country !== "" ? value.country  : "";
                                            //   const pincode =  value.pincode !== null && value.pincode !== "" ? value.pincode : "";
                                                return (
                                                    <li className="order-heading-titles" key={`menu_${index}`}>
                                                        <div>{(index + 1) + ((currentPage - 1) * 10)}</div>
                                                        <div>{`${value.firstname} ${value.lastname}`}</div>
                                                        <div>{value.email}</div>
                                                        <div>{value.phone}</div>
                                                        <div>{building + city + state + country}</div>
                                                        <div>
                                                            <Link
                                                                className="pull-left"
                                                                to ="#"
                                                                onClick={e => this.setState({open:true,viewData:value})}
                                                            >
                                                                <i className="fa fa-eye"></i>
                                                            </Link>&nbsp;&nbsp;
                                                            <Link
                                                                data-toggle="modal"
                                                                to="#"
                                                                onClick={e => this.DeleteUser(value.uniqueID)}
                                                            >
                                                                <i className="fa fa-trash alert-danger"></i>
                                                            </Link>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        {pageOfItems instanceof Array && pageOfItems.length===0 &&
                                            <li className="order-heading-titles">
                                                       <p>No data available</p>
                                            </li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    {admin_agro_list instanceof Array && (
                        <Pagination
                            pageSize={10}
                            items={admin_agro_list}
                            onChangePage={this.onChangePage}
                        />
                    )}
                </div>
                 {/*  NEXT IS VIEW MODAL */}
                 <Modal
                 open={open}
                 size={"sl"}
                     title={"Agro Center Detail"}
                      CloseModal={this.CloseModal} >

                        <div role="tabpanel" className="tab-pane active">
                            <div id="result_296069" className="status-message"></div>
                            <form method="post" className="wp-user-form demo_test">
                            <ul className="order-detail-options">
                          <li className="name">
                            <strong><h6>Name:</h6></strong>
                            <span><b>{`${viewData.title} ${viewData.firstname} ${viewData.lastname}`}</b></span>
                          </li>
                          <li className="email">
                            <strong><h6>Email:</h6></strong>
                            <span><b>{viewData.email}</b></span>
                          </li>
                          <li className="phone-number">
                            <strong><h6>phone no:</h6></strong>
                            <span><b>{viewData.phone}</b></span>
                          </li>
                          <li className="creation-date">
                            <strong><h6>Create Date:</h6></strong>
                            <span><b>{viewData.creationdate?moment(viewData.creationdate).format('lll'):'NA'}</b></span>
                          </li>
                          <li className="last-update">
                            <strong><h6>Last Update:</h6></strong>
                            <span><b>{viewData.lastupdate?moment(viewData.lastupdate).format('lll'):'NA'}</b></span>
                          </li>
                          <li className="address">
                            <strong><h6>Address:</h6></strong>
                            <span><b>{modelbuilding + modelcity + modelstate + modelcountry}</b></span>
                          </li>

                           <li className="zipcode">
                            <strong><h6> Zipcode:</h6> </strong>
                            <span><b>{viewData.pincode?viewData.pincode:"NA"}</b></span>
                          </li>
                          <li className="status">
                            <strong><h6> Status:</h6> </strong>
                            <span><b>{viewData.status === 'A' ? 'Active' : 'Deactive'}</b></span>
                          </li>




                        </ul>
                                <div className="side-by-side select-icon clearfix">
                                    <div className="select-holder"></div>
                                </div>
                            </form>
                        </div>

                </Modal>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    admin_agro_list: state.admin.admin_agro_list,
});

export default connect(mapStateToProps)(AdminAgroCenter);
