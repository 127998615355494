import * as actionTypes from '../types/types';
import { BaseUrl } from '../helpers/common';
import { queryResponse } from '../helpers/userServices';
import swal from 'sweetalert';
import { ShowLoader} from './staticActions'

export const forceCondimentModelOpen = (data) => {
    return dispatch => {
        dispatch({ type: actionTypes.FORCE_CONDIMENT_MODEL_OPEN, payload: data })
    }
}

export function agroCondimentListing(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/condiment_product_review_list`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.CONDIMENTS_REVIEW_LISTING, payload: data.data })
                return 1;
            } else {
                dispatch({ type: actionTypes.CONDIMENTS_REVIEW_LISTING, payload: [] })
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function addCondimentReview(data) {
    let productID = data["productID"];
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/add_condiments_product_review`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                swal({ title: "Success!", text: "Review Added successfully!", icon: "success" });
                dispatch(agroCondimentListing({ "productID": productID }))
                return 1;
            } else {
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}
