import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import {Link} from 'react-router-dom'
import {LoginPhone, verifyOTP} from './../../store/actions/userActions'
import PhoneInput, {parsePhoneNumber,getCountryCallingCode,} from "react-phone-number-input";

class LoginViaPhone extends Component {
    constructor(props){
        super(props);
        this.state = {type:'',email:'',password:'',Class:'',Message:'',TimeOut:100000, viewOTPForm: false, otp:"", request_id: ''}
        this.storageType = localStorage.getItem("type");
        this.validator = new SimpleReactValidator();
      }

      componentDidMount(){ 
          let putVal = this.props.modalType==="login_phone_user" ? "user": this.props.modalType==="login_phone_agro"?"AG": ""
          if(this.state.type!==putVal){
            this.setState({type:putVal})
          } 
      }

      userFormChangeHandler = (value) => {
        this.props.UserFormChange(value);
      };

      submitHandler = async () => {
        const validator = this.validator;
        if(validator.allValid()){
          const response = await this.props.dispatch(LoginPhone(this.state));
          console.error("response", response);
          if(response.code===1){
            this.setState({
              email:'',
              password:'',
              request_id: response.data.request_id,
              phone: response.data.phone ,
              Class:'success',
              Message:response.text,
              viewOTPForm: true
            },()=>{
              // this.props.closeModal()
            })

          }else{
            const {ResponseText, request_id} = response.response;
            this.setState({Class:'danger',Message:ResponseText});
            if(ResponseText === "Concurrent verifications to the same number are not allowed") {
              this.setState({ request_id, viewOTPForm: true})
            }
          }
        }else{
          validator.showMessages();
          this.forceUpdate()
        }
      }

      numberChange = (number) => {
        let phoneNumber = number ? parsePhoneNumber(number.toString()) : "";
        let prefix = "";
        if (phoneNumber) {
          if (phoneNumber.country) {
            if (getCountryCallingCode(phoneNumber.country)) {
              prefix = getCountryCallingCode(phoneNumber.country);
            }
          }
          this.setState({ phone: number.replace('+',''), prefix: prefix });
        }
      };

      onOTPFormSubmit = async (event) => {
        event.preventDefault();
        const {otp:code, request_id, phone} = this.state;
        const postData = {code, request_id, phone};
        const response = await this.props.dispatch(verifyOTP(postData));

        if(response.code===1){
          this.setState({
            email:'',
            password:'',
            request_id: '',
            phone: '' ,
            Class:'success',
            Message:'',
            viewOTPForm: false
          },()=>{
            this.props.closeModal()
          })

          if(response.type==="U"){
            this.props.history.push('/buyer-dashboard') 
            // if(this.props.refinedUrl && this.props.refinedUrl!==""){
            //   this.props.history.push(this.props.refinedUrl)
            // }else{
            //   this.props.history.push('/buyer-dashboard')
            // }
          }else{
            this.props.history.push('/restaurant-profile')
          }
        } else {
          this.setState({Class:'danger',Message:response.response})
        }
      }

      render() {
        return (
          <div className="modal-body">
            <p className="foodbakery-dev-login-top-msg"></p>
            <div className="cs-login-pbox">
              <div className="status status-message"></div>
              {!this.state.viewOTPForm && <form
                method="post"
                className="wp-user-form webkit"
                id="ControlForm_43687"
              >
                <div className="input-filed">
              {/* <i className="icon-phone4"></i> */}
                <PhoneInput
                  name="phone"
                  placeholder="Enter phone number"
                  value={this.state.phone}
                  onChange={(number) => this.numberChange(number)}
                  defaultCountry="IN"
                />
                {this.validator.message( "phone", this.state.phone, "required|phone" )}

                </div>
                <div className="input-filed">
                  <Link to="#" className="btn btn-black btn-block" onClick={e=>this.submitHandler()}>
                    Send OTP
                  </Link>
                </div>
              </form>}

              {this.state.viewOTPForm && <form
                className="wp-user-form webkit"
                id="ControlForm_43687"
                onSubmit={this.onOTPFormSubmit}
              >
                <div className="input-filed">
                <input type="number" name="otp" placeholder="Enter your OTP" value={this.state.otp} onChange={e => this.setState({otp: e.target.value})}/>
                {this.validator.message( "OTP", this.state.otp, "required|integer" )}

                </div>
                <div className="input-filed" style={{marginTop:"10px"}}>
                  <button className="btn btn-black btn-block">
                    Submit
                  </button>
                </div>
              </form>}
              <br />
              {!this.state.viewOTPForm && <div className="signin-tab-link forget-password">
                New Here?
                <Link
                  to="#"
                  className="foodbakery-dev-login-box-btn forgot-switch"
                  onClick={(e) => this.userFormChangeHandler("user_signup")}
                >
                  Signup
                </Link>
              </div>}
            </div>
          </div>
        );
      }
    }

export default LoginViaPhone;