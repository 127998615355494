import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import {restaurantInfo,RestaurantDeliveryPickup} from './../../store/actions/restaurantActions';



const initialState = {deliver_pickup:'',cash_on_delivery:'',minimum_delivery_order:'',minimum_pickup_order:'',delivery_fee:'',pickup_fee:'',vat:'',showVat:true}
class RestaurantDelivery extends Component {
    constructor(props) {
        super(props);
        this.formValidator = new SimpleReactValidator();
        this.state=initialState
      }

      componentDidMount(){
        this.props.dispatch(restaurantInfo())
    }

    onChangeHandler = e => { 
        this.setState({[e.target.name]:e.target.value})
    }

    onSubmitHandler = async() => {
        const { cash_on_delivery,deliver_pickup,minimum_delivery_order,minimum_pickup_order,delivery_fee,vat} = this.state
        let data ={tax:vat,cod:cash_on_delivery,deliver_pickup:deliver_pickup,delivery_fee:delivery_fee,min_delivery_order:minimum_delivery_order,min_pickup_order:minimum_pickup_order}
        const validator = this.formValidator;
        if(validator.allValid()){
            const response = await this.props.dispatch(RestaurantDeliveryPickup(data));
            if(response===1){
                this.setState(initialState)
                this.props.history.push('/restaurant-open-close')
                this.props.dispatch(restaurantInfo())
            }
        }else{
            validator.showMessages();
            this.forceUpdate();
        }
  }

  componentDidUpdate = (prevProps, prevState) => {
    let nextProps = this.props;
    if(nextProps.restaurant !== prevProps.restaurant){
        let info = nextProps.restaurant.data
      this.setState({
        cash_on_delivery : info.restaurantsetting[0] &&  info.restaurantsetting[0].cod  ,
        deliver_pickup: info.restaurantsetting[0]  && info.restaurantsetting[0].deliver_pickup ,
        pickup_fee:info.restaurantsetting[0]  && info.restaurantsetting[0].pickup_fee ,
        delivery_fee:info.restaurantsetting[0]  &&  info.restaurantsetting[0].delivery_fee ,
        minimum_pickup_order:info.restaurantsetting[0] && info.restaurantsetting[0].min_pickup_order ,
        minimum_delivery_order:info.restaurantsetting[0] && info.restaurantsetting[0].min_delivery_order ,
        vat:info.restaurantsetting[0] && info.restaurantsetting[0].tax,
        showVat:info.restaurantsetting[0] && info.restaurantsetting[0].tax>0?true:false
      })
    }
  }


  render() {
      let restaurantsetting = this.props.restaurant?.data?.restaurantsetting.length
      let restaurantworking = this.props.restaurant?.data?.restaurantworking.length
      //const {currency} = this.props
      const {showVat} = this.state;
      //const currencyClass = {INR:"fa fa-rupee-sign"}
      this.formValidator.purgeFields();
    return (
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <div className="user-dashboard loader-holder">
                <div className="user-holder">
                    <form action="/restaurant-open-close">
                        {restaurantsetting > 0 && restaurantworking > 0 ?
                        (
                            <ul className='restaurant-settings-nav progressbar-nav'>
                                <li className='active cond-restaurant-settings '>
                                    <Link to='/restaurant-profile'>Restaurant Settings</Link>
                                </li>
                                <li className='cond-restaurant-settings active'>
                                    <Link to='/restaurant-delivery'>Delivery/Pickup</Link>
                                </li>
                                <li className='cond-restaurant-settings active'>
                                    <Link to='/restaurant-open-close'>Restaurant Open/Close</Link>
                                </li>
                            </ul>
                        ) : (
                            <ul className='restaurant-settings-nav progressbar-nav'>
                                <li className='active cond-restaurant-settings'>
                                    <Link to='#'>Restaurant Settings</Link>
                                </li>
                                <li className='cond-restaurant-settings active processing'>
                                    <Link to='#'>Delivery/Pickup</Link>
                                </li>
                                <li className='cond-restaurant-settings'>
                                    <Link to='#'>Restaurant Open/Close</Link>
                                </li>
                            </ul>
                        )}
                        <div className="form-fields-set">
                            <ul>
                                <li>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="element-title">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="col-md-6" style={{paddingLeft:"0px"}}>
                                                            <h4>Delivery/Pickup</h4>
                                                        </div>
                                                        <div className="col-md-6 pull-right" style={{paddingRight:"0px"}}>
                                                            <input type="checkbox" value="1" onChange={e=>this.setState({showVat:!showVat,vat:0})} checked={showVat} />
                                                            &nbsp;<label>Add tax</label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="opt-conts">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        <div className="field-holder">
                                                            <label>Delivery/Pickup</label>
                                                            <select className="" name="deliver_pickup" value={this.state.deliver_pickup} onChange={this.onChangeHandler.bind(this)} >
                                                                <option value="">Select</option>
                                                                <option value="D">Delivery</option>
                                                                <option value="P">Pickup</option>
                                                                <option value="B">Delivery &amp; Pickup</option>
                                                            </select>
                                                            {this.formValidator.message( "deliver_pickup", this.state.deliver_pickup, "required" )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        <div className="field-holder">
                                                            <label>Disable cash on Delivery?</label>
                                                            <select className="" name="cash_on_delivery" value={this.state.cash_on_delivery} onChange={this.onChangeHandler.bind(this)}>
                                                                <option value="">Select</option>
                                                                <option value="Y">Yes</option>
                                                                <option value="N">No</option>
                                                            </select>
                                                            {this.formValidator.message( "cash_on_delivery", this.state.cash_on_delivery, "required" )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        <div className="field-holder">
                                                            <label>Minimum Delivery Order</label>
                                                            <input type="number" name="minimum_delivery_order" value={this.state.minimum_delivery_order} onChange={this.onChangeHandler.bind(this)} />
                                                            {this.formValidator.message( "minimun_delivery_order", this.state.minimum_delivery_order, "required" )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        <div className="field-holder">
                                                            <label>Minimum Pickup Order</label>
                                                            <input type="number" name="minimum_pickup_order" value={this.state.minimum_pickup_order} onChange={this.onChangeHandler.bind(this)}/>
                                                            {this.formValidator.message( "minimum_pickup_order", this.state.minimum_pickup_order, "required" )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        <div className="field-holder merge-input-holder">
                                                            <label>Delivery Fee</label>
                                                            <span className="merge-icon"><i className="fa fa-rupee-sign"></i></span>
                                                            <input type="text" className="form-control" name="delivery_fee" value={this.state.delivery_fee} onChange={this.onChangeHandler.bind(this)} />
                                                            {this.formValidator.message( "delivery_fee", this.state.delivery_fee, "required|max:50,string" )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        {showVat && <div className="field-holder merge-input-holder">
                                                            <label>TAX</label>
                                                            <span className="merge-icon"><i className="fa fa-percent"></i></span>
                                                            <input type="text" name="vat" value={this.state.vat} onChange={this.onChangeHandler.bind(this)} />
                                                            {this.formValidator.message( "Tax", this.state.vat, "required|max:50,string" )}
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div>
                            <div className="field-holder text-center">
                                <div className="payment-holder input-button-loader">
                                    <Link to="/restaurant-profile" className="btn btn-black">Back</Link>
                                </div>&nbsp;&nbsp;
                                <div className="payment-holder input-button-loader">
                                    <Link to="#" className="btn btn-black" onClick={this.onSubmitHandler.bind(this)}>Save Changes</Link>
                                </div>
                            </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
		</div>
    );
  }
}

const mapStateToProps = (state) => ({
    restaurant : state.restaurant.restaurant_profile,
    currency : state.cart.currency
});

export default connect(mapStateToProps)(RestaurantDelivery);
