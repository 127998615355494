import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Modals from "./../modules/Html/Modals";
import ModalHeader from "./../modules/Html/ModalHeader";
import ModalBody from "./../modules/Html/ModalBody";
import ModalLayout from "./../modules/Modal/ModalLayout";
import ForgotPassword from "./../modules/Modal/ForgotPassword";
import UserSignup from "./../modules/Modal/UserSignup";
import UserLoginTab from "../modules/Modal/UserLoginTab";
import RestaurantLogin from "../modules/Modal/RestaurantLogin";
import AgroCenterLogin from "../modules/Modal/AgroCenterLogin";
import LoginViaPhone from "../modules/Modal/LoginViaPhone";
import ChangePasswordTab from "../modules/Modal/ChangePasswordTab";
import FoodCenterLogin from "../modules/Modal/FoodCenterLogin";
import {
  AgroProductModelOpen,
  addAgroProductReview,
  agroReviewListing,
} from "./../store/actions/AgroProductCartActions";
import {
  PlaceOrder,
  ForceloginClick,
  ForceMamaloginClick,
  RestaurantSearchList,
  RestaurantCategoryListing,
  RestaurantMenuListing,
  fetchFoodData,
  RestaurantListing,
  AfterForceloginClickHeaderUpdate,
  ViewCondiment,
  CondimentListing,
} from "./../store/actions/restaurantActions";
import { ForceAgrologinClick } from "./../store/actions/agroActions";
import { ForceFoodloginClick } from "./../store/actions/foodActions";
import SimpleReactvalidator from "simple-react-validator";
import { profileInfo } from "./../store/actions/buyerActions";
import LocationSearch from "./modules/LocationSearch";
import { IdentityContext } from "./../store/helpers/userServices";
import { bucketPath } from "./../store/helpers/common";
import { handlePermission } from "./../store/helpers/timeIntervals";
import {
  condimentCart,
  addCart,
  minusCart,
  removeCart,
} from "./../store/actions/condimentCartActions";
import {
  addAgroCart,
  minusAgroCart,
  removeAgroCart,
  ViewAgroProduct,
} from "./../store/actions/AgroProductCartActions";
import {
  forceCondimentModelOpen,
  agroCondimentListing,
  addCondimentReview,
} from "./../store/actions/condimentActions";
import swal from "sweetalert";
const initialState = {
  showModal: false,
  modalType: "",
  isLogin: false,
  refinedUrl: "",
  expanded: false,
  searchresto: "",
  delivery_type: "delivery",
  payment_type: "card",
  open: false,
  cartItems: [],
  agrocartItems: [],
  modal_type: "",
  title: "",
  description: "",
  rating: "0",
  isexist: false,
  AgroProductOpen: false,
  agroExist: false,
  modal_agro_type: "view_agro_detail",
  location: "",
};
class Header extends Component {
  subTotal = 0;
  pickup = 0;
  delivery = 0;
  vat = 0;
  vatApply = 0;
  totalToPay = 0;
  constructor(props) {
    super(props);
    this.storageToken = localStorage.getItem("token");
    this.storageData = localStorage.getItem("data");
    this.storageType = localStorage.getItem("type");
    this.state = initialState;
    this.validator = new SimpleReactvalidator();
    this.agro_validator = new SimpleReactvalidator();
  }

  componentDidMount() {
    this.sessionLocation = localStorage.getItem("location");
    if (this.sessionLocation) {
      let {
        value: { latitude, longitude, location },
      } = JSON.parse(this.sessionLocation);
      this.props.dispatch(
        RestaurantSearchList({ latitude, longitude, search: "" })
      );
      this.setState({ location });
    }
    this.props.dispatch(CondimentListing());
    this.props.dispatch(RestaurantListing({ food_item: "" }));
    handlePermission();
    var elem = window.$("#ex16b313324");
    if (elem.length !== "") {
      elem.slider({
        step: 1,
        min: 0,
        max: 500,
        value: 10,
      });
      elem.on("slideStop", function () {
        var rang_slider_val = elem.val();
        window.$("#ex16b313324CurrentSliderValLabel").html(rang_slider_val);
        window.$("#range-hidden-foodbakery-radius622192").val(rang_slider_val);
      });
      elem.on("slide", function () {
        window.$("#ex16b313324CurrentSliderValLabel").html(elem.val());
      });
    }

    window.$("#foodbakery_radius_location_open").click(function () {
      window.$("#foodbakery-radius-range622192").show();
    });
    window.$("#location_close_popup622192").click(function () {
      window.$("#foodbakery-radius-range622192").hide();
    });

    if (
      this.storageToken &&
      this.storageData &&
      this.storageToken !== "" &&
      this.storageToken !== ""
    ) {
      let uniqueID = JSON.parse(this.storageData)?.uniqueID;
      this.setState({
        isLogin: true,
      });
      if (uniqueID && localStorage.getItem("type") === "U") {
        this.props.dispatch(profileInfo());
      }
    } else {
      this.setState(
        {
          isLogin: false,
        },
        () => {
          localStorage.removeItem("token");
          localStorage.removeItem("data");
          localStorage.removeItem("type");
        }
      );
    }
  }

  openModal = (value) => {
    this.setState(
      {
        showModal: true,
        modalType: value,
      },
      () => {
        window.$("#user-actions").modal({ show: true, backdrop: "static" });
      }
    );
  };

  closeModal = () => {
    if (this.state.modalType === "change_password") {
      this.setState(
        {
          showModal: false,
          modalType: "",
          refinedUrl: "",
          expanded: false,
          searchresto: "",
        },
        () => {
          window.$("#user-actions").modal("hide");
        }
      );
    } else {
      this.setState(initialState, () => {
        window.$("#user-actions").modal("hide");
      });
    }
  };

  UserFormChange = (value) => {
    this.setState({ modalType: value });
  };

  submitHandler = (e) => {
    let promise = new Promise((resolve, reject) => {
      let token = localStorage.removeItem("token") ? true : false;
      let data = localStorage.removeItem("data") ? true : false;
      let type = localStorage.removeItem("type") ? true : false;
      if (!token && !data && !type) {
        resolve();
      }
    });

    promise.then(() => {
      window.location.href = "/";
      this.setState({ isLogin: false });
    });
  };

  checkHandler = (e) => {
    let promise = new Promise((resolve, reject) => {
      let token = localStorage.getItem("token");
      let data = localStorage.getItem("data");
      let type = localStorage.getItem("type");
      if (token && data && type) {
        resolve();
      }
    });

    promise.then(() => {
      this.setState({ isLogin: true });
    });
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    let condiment_cart = newProps.condiment_cart ?? [];
    let agro_product_cart = newProps.agro_product_cart ?? [];
    let condiment = newProps.condiment ?? [];
    let global_agro_product = newProps.global_agro_product ?? [];
    this.setState({
      cartItems: condiment_cart,
      agrocartItems: agro_product_cart,
    });
    if (newProps?.restaurant_data?.force_login_click) {
      this.openModal("user_login");
      this.setState({ refinedUrl: this.props.match.url });
      this.props.dispatch(ForceloginClick(false));
      this.forceUpdate();
    }
    if (newProps?.restaurant_data?.force_mama_login_click) {
      this.openModal("res_signup");
      this.setState({ refinedUrl: this.props.match.url });
      this.props.dispatch(ForceMamaloginClick(false));
      this.forceUpdate();
    }

    if (newProps?.agro_data?.force_agro_login_click) {
      this.openModal("agro_login");
      this.props.dispatch(ForceAgrologinClick(false));
      this.forceUpdate();
    }

    if (newProps?.food_data?.force_food_login_click) {
      this.openModal("food_login");
      this.setState({ refinedUrl: this.props.match.url });
      this.props.dispatch(ForceFoodloginClick(false));
      this.forceUpdate();
    }
    if (newProps?.restaurant_data?.after_force_login_click_header_update) {
      this.props.dispatch(AfterForceloginClickHeaderUpdate(false));
      this.setState({ isLogin: true });
      this.props.dispatch(profileInfo());
      window.location.reload();
    }
    if (newProps?.force_condiment_modal_open_data) {
      if (
        newProps.force_condiment_modal_open_data.model_open &&
        newProps.force_condiment_modal_open_data.model_open === true
      ) {
        let filtercondiment =
          condiment && condiment.length > 0
            ? condiment.filter(
                (item) =>
                  item.uniquekey ===
                  newProps.force_condiment_modal_open_data.uniquekey
              )
            : [];
        let productID =
          filtercondiment && filtercondiment.length > 0
            ? filtercondiment[0].productID
            : "";
        this.ViewCondiment(
          newProps.force_condiment_modal_open_data.uniquekey,
          productID
        );
        this.props.dispatch(forceCondimentModelOpen({}));
      }
    }

    if (newProps?.agro_modal) {
      if (
        newProps.agro_modal.model_open &&
        newProps.agro_modal.model_open === true
      ) {
        let filterAgroProduct =
          global_agro_product && global_agro_product.length > 0
            ? global_agro_product.filter(
                (item) => item.uniquekey === newProps.agro_modal.uniquekey
              )
            : [];
        let productID =
          filterAgroProduct && filterAgroProduct.length > 0
            ? filterAgroProduct[0].productID
            : "";
        this.setState({
          viewAgroData: filterAgroProduct,
          productID: productID,
        });
        this.ViewAgroProduct(newProps.agro_modal.uniquekey, productID);
        this.props.dispatch(AgroProductModelOpen({}));
      }
    }
    if (!this.state.isLogin) {
      this.checkHandler();
    }
  }

  onRemoveAgroCart = (value) => {
    this.props.dispatch(removeAgroCart(value, this.props.agro_product_cart));
  };

  onAddAgroCart = (value) => {
    this.props.dispatch(addAgroCart(value, this.state));
  };

  onMinusAgroCart = (value) => {
    this.props.dispatch(minusAgroCart(value, this.state));
  };

  onSearchHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (this.state.searchresto === "") {
        this.props.dispatch(
          RestaurantSearchList({ longitude: "", latitude: "", search: "" })
        );
      } else {
        this.props.dispatch(
          RestaurantSearchList({
            longitude: "",
            latitude: "",
            search: this.state.searchresto,
          })
        );
      }
    });
  };

  selectResto = (value, uniqueID) => {
    this.setState({ searchresto: value }, () => {
      this.props.dispatch(RestaurantCategoryListing(uniqueID));
      this.props.dispatch(RestaurantMenuListing(uniqueID));
      this.props.history.push(`/menu-listing/${uniqueID}`);
    });
  };

  selectFood = (data) => {
    this.setState({ searchresto: data }, () => {
      this.props.dispatch(
        RestaurantListing({ food_item: this.state.searchresto })
      );
      this.props.dispatch(fetchFoodData(this.state.searchresto));
      this.props.history.push("/restaurants-listing");
    });
  };

  onRemoveCart = (value) => {
    this.props.dispatch(removeCart(value, this.props.condiment_cart));
  };
  onEditCart = (value) => {
    const data = {
      uniquekey: value,
      model_open: true,
    };
    this.props.dispatch(forceCondimentModelOpen(data));
  };

  placeOrder = (e) => {
    const getUserIdentity = localStorage.getItem("token");
    if (!getUserIdentity) {
      swal({
        text: "You need to login to make an order!",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((yesDo) => {
        if (yesDo) {
          this.props.dispatch(ForceloginClick(true));
        } else {
          console.log("Record is Safe");
        }
      });
      return false;
    }
    const { delivery_type, payment_type } = this.state;
    let deliveryType = delivery_type === "delivery" ? "D" : "P";
    let order_type = "condiment";
    let data = {
      delivery_type: deliveryType,
      payment_type,
      pickup_cost: this.pickup,
      delivery_cost: this.delivery,
      vatPercentage: this.vat,
      vatApplied: this.vatApply,
      subTotal: this.subTotal,
      totalToPay: this.totalToPay,
      cartItems: this.props.condiment_cart,
      order_type: order_type,
    };

    const promise = new Promise(async (resolve, reject) => {
      let response = await this.props.dispatch(PlaceOrder(data));
      if (response.code === 1) {
        resolve(response);
      } else {
        reject();
      }
    });
    promise.then((response) => {
      this.props.dispatch(condimentCart([]));
      if (response.type === "cash") {
        this.props.history.push("/buyer-orders");
      } else if (response.type === "card") {
        this.props.history.push(`/stripe-payment/${response.order_number}`);
      }
    });
  };

  ViewCondiment = (id, productID) => {
    this.props.dispatch(ViewCondiment({ id: id }));
    this.props.dispatch(agroCondimentListing({ productID: productID }));

    this.setState(
      {
        isexist: true,
      },
      () => {
        this.setState({
          open: true,
          modal_type: "view_detail",
        });
      }
    );
  };

  ViewAgroProduct = (uniquekey, productID) => {
    this.props.dispatch(ViewAgroProduct({ uniquekey }));
    this.props.dispatch(agroReviewListing({ productID: productID }));
    this.setState(
      {
        agroExist: true,
      },
      () => {
        this.setState({
          AgroProductOpen: true,
          modal_agro_type: "view_agro_detail",
        });
      }
    );
  };

  productDetailCloseModal = (e) => {
    this.setState(
      {
        open: false,
        modal_type: "",
      },
      () => {
        this.setState({
          isexist: false,
        });
      }
    );
  };

  CloseAgroModal = (e) => {
    this.setState(
      {
        AgroProductOpen: false,
        modal_agro_type: "",
      },
      () => {
        this.setState({
          agroExist: false,
        });
      }
    );
  };

  onAddCart = (value) => {
    this.props.dispatch(addCart(value, this.state));
    return null;
  };

  onMinusCart = (value) => {
    this.props.dispatch(minusCart(value, this.state));
    return null;
  };

  openReviewModal = () => {
    const getUserIdentity = localStorage.getItem("token");
    if (!getUserIdentity) {
      this.setState({
        open: false,
        modal_type: "",
      });
      swal({
        text: "You need to login to write Review!",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((yesDo) => {
        if (yesDo) {
          this.props.dispatch(ForceloginClick(true));
        } else {
          console.log("Record is Safe");
        }
      });
      return false;
    }
    this.setState({
      open: true,
      modal_type: "add_review",
    });
  };

  openAgroReviewModal = () => {
    const getUserIdentity = localStorage.getItem("token");
    if (!getUserIdentity) {
      this.setState({
        AgroProductOpen: false,
        modal_agro_type: "",
      });
      swal({
        text: "You need to login to write Review!",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((yesDo) => {
        if (yesDo) {
          this.props.dispatch(ForceloginClick(true));
        } else {
          console.log("Record is Safe");
        }
      });
      return false;
    }
    this.setState({
      AgroProductOpen: true,
      modal_agro_type: "Review",
      title: "",
      description: "",
      rating: "",
    });
  };

  addRivew = async (e) => {
    const { title, description, rating } = this.state;
    let productID =
      this.props.view_condiment && this.props.view_condiment.productID
        ? this.props.view_condiment.productID
        : "";
    if (this.validator.allValid()) {
      let response = await this.props.dispatch(
        addCondimentReview({
          title,
          description,
          points: rating * 20,
          productID,
        })
      );
      if (response === 1) {
        this.productDetailCloseModal();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  addAgroReview = async (e) => {
    const { title, description, rating, productID } = this.state;
    if (this.agro_validator.allValid()) {
      let response = await this.props.dispatch(
        addAgroProductReview({
          title,
          description,
          points: rating * 20,
          productID,
        })
      );
      if (response === 1) {
        this.CloseAgroModal();
      }
    } else {
      this.agro_validator.showMessages();
      this.forceUpdate();
    }
  };

  onRadioChangeHandler = (e) => {
    this.setState({ rating: e.target.value });
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  restaurantlinkingHandler = (value) => {
    this.productDetailCloseModal();
    this.props.history.push(`/menu-listing/${value}`);
  };
  mamalinkingHandler = () => {
    this.productDetailCloseModal();
    this.props.history.push(`/`);
  };

  _FetchLocation = (value) => {
    this.setState({ location: value });
  };
  render() {
    const {
      modalType,
      isLogin,
      expanded,
      searchresto,
      delivery_type,
      open,
      title,
      rating,
      modal_type,
      AgroProductOpen,
      agroExist,
      modal_agro_type,
      viewAgroData,
      location,
    } = this.state;
    const {
      restaurant_data: { nearby_restaurants },
      condiment_cart,
      condiment,
      view_condiment,
      condiment_review_list,
      restaurant_listing,
      agro_product_cart,
      view_agro_product,
      agro_review_list,
    } = this.props;
    let total_price = 0;
    let name = view_condiment?.name ?? "";
    let price = view_condiment?.price ?? "";
    let description = view_condiment?.description ?? "";
    let product_highlight = view_condiment?.product_highlight ?? "";
    let images = view_condiment?.image ?? "";
    let created_by = view_condiment?.created_by ?? "";
    let type = view_condiment?.type ?? "";
    let agro_images = view_agro_product?.image;
    let viewarrayFilter =
      condiment_cart instanceof Array &&
      condiment_cart.findIndex(
        (obj) => obj.uniqueID === view_condiment.uniquekey
      );
    let agroFilter =
      agro_product_cart instanceof Array &&
      agro_product_cart.findIndex(
        (obj) => obj.uniqueID === view_agro_product.product.uniquekey
      );
    let restaurantdata =
      restaurant_listing && restaurant_listing.length > 0 && created_by !== ""
        ? restaurant_listing.filter(
            (item) => item.restaurantsDetails.restaurantID === created_by
          )
        : [];
    let restaurantName =
      restaurantdata &&
      restaurantdata.length > 0 &&
      restaurantdata[0].restaurantsDetails
        ? restaurantdata[0].restaurantsDetails.restaurant_name
        : null;

    return (
      <div>
        <IdentityContext.Consumer>
          {(context) => (
            <header id="header">
              <div
                className={`main-header ${
                  this.state.isLogin ? "main-login-area" : ""
                }`}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 no-padding">
                      <div className="logo">
                        <figure>
                          <Link to="/" className="light-logo">
                            <img
                              src="./../../assets/extra-images/logo1.png"
                              alt="Mamachai"
                            />
                          </Link>
                        </figure>
                      </div>
                      <div className="dropdown header-menu-ico">
                        <Link
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          to="#"
                          onClick={(e) =>
                            this.setState((prevState) => ({
                              expanded: !prevState.expanded,
                            }))
                          }
                          aria-expanded={expanded ? "true" : "false"}
                        >
                          <span
                            className={expanded ? "icon-menu5" : "icon-menu5"}
                          ></span>
                        </Link>

                        <ul className="dropdown-menu">
                          <li>
                            <Link to="/restaurants-listing">All Mamas</Link>
                          </li>

                          <li>
                            <Link to="/recepies">Receipes</Link>
                          </li>
                          <li>
                            <Link to="/mama-live">Live</Link>
                          </li>
                          <li>
                            <Link to="/store/condiments">
                              {" "}
                              Condiment/Spices
                            </Link>
                          </li>
                          <li>
                            <Link to="food-classes">Food Classes</Link>
                          </li>
                          <li>
                            <Link to="/nutrition-fact">Nutrition Facts</Link>
                          </li>
                          <li>
                            <Link to="/restaurants-listing">Food Trucks</Link>
                          </li>
                          <li>
                            <Link to="/agro-listing">Agro Center/Farms</Link>
                          </li>
                          <li>
                            <Link to="/food-listing">Food Center</Link>
                          </li>
                          <li>
                            <Link to="/diet-plan">Diet plan</Link>
                          </li>
                          <li>
                            <Link to="/store/festival-kit">Festival Kit</Link>
                          </li>
                          <li>
                            <Link to="/multi-order">Multi Orders</Link>
                          </li>
                        </ul>
                      </div>
                      <Link
                        className="live-cooking-btn btn"
                        id="signup_restaurant"
                        to="/mama-live"
                      >
                        Live Cooking
                      </Link>
                      <div className="download-ico">
                        <Link to="#">
                          <img
                            src="./../../assets/extra-images/ios.png"
                            alt="...chosen-container-active"
                          />
                        </Link>
                        <Link to="#">
                          <img
                            src="./../../assets/extra-images/android.png"
                            alt="...chosen-container-active"
                          />
                        </Link>
                      </div>
                      <div className="main-location">
                        <ul className="main-location-ul">
                          <LocationSearch
                            _FetchLocation={this._FetchLocation}
                            {...this.props}
                          />
                          <li className="search-li">
                            <input
                              className="dropdown-toggle form-control Search-control-outer loction-control-outer"
                              data-toggle="dropdown"
                              id="myInput"
                              name="searchresto"
                              value={searchresto}
                              type="text"
                              autoComplete="off"
                              placeholder="Search for Mamas or Cuisine..."
                              onChange={this.onSearchHandler.bind(this)}
                            />
                            <div className="dropdown-menu Search-control-drop dropdown-content dropdown-menu-right">
                              {nearby_restaurants.food &&
                                nearby_restaurants.food.map((value, index) => {
                                  return (
                                    <>
                                      <Link
                                        className="dropdown-item"
                                        key={`dropdown_${index}`}
                                        to="#"
                                        onClick={(e) =>
                                          this.selectFood(value.name)
                                        }
                                      >
                                        {value.name}{" "}
                                        <span>
                                          <small>Cuisine</small>
                                        </span>
                                      </Link>
                                    </>
                                  );
                                })}
                              {location !== "" &&
                                nearby_restaurants.restaurant instanceof
                                  Array &&
                                nearby_restaurants.restaurant.length > 0 && (
                                  <h5 className="dropdown-item add-current-location">
                                    <Link to="#">Nearby Restaurants</Link>
                                  </h5>
                                )}
                              {nearby_restaurants.restaurant &&
                                nearby_restaurants.restaurant.map(
                                  (value, index) => {
                                    return (
                                      <Link
                                        className="dropdown-item"
                                        key={`dropdown_${index}`}
                                        to="#"
                                        onClick={(e) =>
                                          this.selectResto(
                                            value.restaurant_name,
                                            value.uniqueID
                                          )
                                        }
                                      >
                                        {value.restaurant_name}
                                        {location !== "" && value.distance && (
                                          <>
                                            &nbsp;&nbsp;
                                            <span>
                                              {value.distance.toFixed(1)} KM
                                            </span>
                                          </>
                                        )}
                                        {/* {searchresto ==="" && <>&nbsp;&nbsp;<span>{value.distance}KM</span></>} */}
                                      </Link>
                                    );
                                  }
                                )}
                              {nearby_restaurants.length === 0 && (
                                <Link className="dropdown-item" to="#">
                                  No Record Available
                                </Link>
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {!isLogin && (
                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 no-padding heaer-right-side">
                        <div className="login-option">
                          <Link
                            className="get-start-btn"
                            to="#"
                            onClick={(e) => this.openModal("user_login")}
                          >
                            For User
                          </Link>
                          <Link
                            className="get-start-btn"
                            id="signup_restaurant"
                            to="#"
                            onClick={(e) => this.openModal("res_login")}
                          >
                            For Mamas
                          </Link>
                          <Link
                            className="provider-area btn provider-text"
                            id="provider-area"
                            to="#"
                            onClick={(e) => this.openModal("agro_login")}
                          >
                            Agro Centers
                          </Link>
                          {
                            // <div className="dropdown header-menu-ico provider-area">
                            //   <Link  className="dropdown-toggle" data-toggle="dropdown" to="#" onClick={e=>this.setState(prevState=>({expanded:!prevState.expanded}))} aria-expanded={expanded?"true":"false"}>
                            //     {/* <span className={expanded?"icon-cross3":"icon-menu5"}></span> */}
                            //     <span className="provider-text">For Provider</span>
                            //   </Link >
                            //
                            //   <ul className="dropdown-menu">
                            //     <li>
                            //       <Link to="#"
                            //       onClick={(e) => this.openModal("agro_login")}>
                            //       Agro Center
                            //       </Link>
                            //     </li>
                            //     <li>
                            //       <Link to="#"
                            //       onClick={(e) => this.openModal("food_login")}>
                            //       Food Center
                            //       </Link>
                            //     </li>
                            //   </ul>
                            // </div>
                          }

                          <div className="dropdown add-cart-drop">
                            <Link
                              to="#"
                              className="add-cart-ico dropdown-toggle"
                              data-toggle="dropdown"
                            >
                              <i className="icon-shopping_cart"></i>
                              {condiment_cart && condiment_cart.length > 0 && (
                                <label className="badge add-cart-badge">
                                  {condiment_cart.length}
                                </label>
                              )}
                            </Link>
                            <ul className="dropdown-menu">
                              {condiment_cart && condiment_cart.length > 0 ? (
                                <>
                                  {condiment_cart.map((item, index) => {
                                    // let filterdata = condiment && condiment.filter(data =>data.uniquekey === item.uniqueID)
                                    total_price += parseFloat(item.price);
                                    this.subTotal =
                                      index === 0 ? 0 : this.subTotal;
                                    this.subTotal += parseFloat(item.price);
                                    this.vatApply = (
                                      (this.subTotal * this.vat) /
                                      100
                                    ).toFixed(2);
                                    this.totalToPay = (
                                      parseFloat(this.subTotal) +
                                      parseFloat(this.vatApply) +
                                      parseFloat(
                                        `${
                                          delivery_type === "delivery"
                                            ? this.delivery
                                            : this.pickup
                                        }`
                                      )
                                    ).toFixed(2);
                                    return (
                                      <li key={`cartitem${index}`}>
                                        <div className="cart-items-main-div">
                                          <div className="col-md-2">
                                            <Link
                                              className="cart-item-img"
                                              to="#"
                                            >
                                              <img
                                                src={
                                                  item.image
                                                    ? bucketPath + item.image
                                                    : ""
                                                }
                                                alt="Mamachai"
                                              />
                                            </Link>
                                          </div>
                                          <div className="col-md-6 cart-item-name">
                                            <h3>
                                              <Link to="#">{item.name}</Link>
                                            </h3>
                                            <small>{item.quantity}</small>
                                            <div className="edit-remove">
                                              <Link
                                                to="#"
                                                onClick={(e) =>
                                                  this.onEditCart(item.uniqueID)
                                                }
                                              >
                                                <i className="icon-edit2"></i>
                                              </Link>
                                              <Link
                                                to="#"
                                                onClick={(e) =>
                                                  this.onRemoveCart(
                                                    item.uniqueID
                                                  )
                                                }
                                              >
                                                <i className="icon-trash-o"></i>
                                              </Link>
                                            </div>
                                          </div>
                                          <div className="col-md-2">
                                            <Link
                                              to="#"
                                              className="cart-item-count"
                                            >
                                              {item.count}
                                            </Link>
                                          </div>
                                          <div className="col-md-2 no-padding">
                                            <p className="cart-item-price">
                                              RS {item.price}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                  <div className="check-out-btn-div">
                                    <Link
                                      to="#"
                                      className="btn btn-red btn-chek-out-drop btn-block"
                                      onClick={(e) => this.placeOrder(e)}
                                    >
                                      Go to Checkout
                                      <span className="total-price">
                                        RS {total_price}
                                      </span>
                                    </Link>
                                  </div>
                                </>
                              ) : (
                                <div className="check-out-btn-div">
                                  <Link
                                    to="#"
                                    className="btn btn-red btn-chek-out-drop btn-block"
                                  >
                                    Cart is Empty
                                  </Link>
                                </div>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}

                    {/*  Buyer's Header */}

                    {isLogin && this.storageType === "U" && (
                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12  heaer-right-side">
                        <li className="dropdown login-drop-option">
                          <Link
                            to="#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <figure className="profile-image">
                              <img
                                src={
                                  context && context.profile_pic !== ""
                                    ? `${bucketPath}${context.profile_pic}`
                                    : "assets/extra-images/team-medium-img1.jpg"
                                }
                                alt=""
                              />
                            </figure>
                            {context ? `${context.firstname}` : "User"}
                            <span className="caret"></span>
                          </Link>
                          <ul className="dropdown-menu">
                            <li>
                              <Link to="/buyer-dashboard">
                                <i className="icon-dashboard3"></i>Dashboard
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/buyer-orders"
                                className="btn-edit-profile"
                              >
                                <i className="icon-add_shopping_cart"></i>My
                                Orders
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/buyer-bookings"
                                className="btn-edit-profile"
                              >
                                <i className="icon-file-text2"></i>My Bookings
                              </Link>
                            </li>
                            <li className="active">
                              <Link to="/buyer-account-setting">
                                <i className="icon-build"></i>Profile Settings
                              </Link>
                            </li>
                            <li>
                              <Link to="/buyer-more-address">
                                <i className="icon-location"></i>My Address
                              </Link>
                            </li>
                            <li>
                              <Link to="/buyer-short-list">
                                <i className="icon-heart"></i>ShortList
                              </Link>
                            </li>
                            <li>
                              <Link to="/buyer-transcation">
                                <i className="icon-file-text22"></i>Transcations
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/buyer-change-password" 
                              >
                                <i className="icon-build"></i>Change Password
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                onClick={(e) => this.submitHandler(e)}
                              >
                                <i className="icon-log-out"></i>Sign out
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <div className="dropdown add-cart-drop add-cart-drop-2">
                          <Link
                            to="#"
                            className="add-cart-ico dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            <i className="icon-shopping_cart"></i>
                            {condiment_cart && condiment_cart.length > 0 && (
                              <label className="badge add-cart-badge">
                                {condiment_cart.length}
                              </label>
                            )}
                          </Link>
                          <ul className="dropdown-menu">
                            {condiment_cart && condiment_cart.length > 0 ? (
                              <>
                                {condiment_cart.map((item, index) => {
                                  let filterdata =
                                    condiment &&
                                    condiment.filter(
                                      (data) => data.uniquekey === item.uniqueID
                                    );
                                  total_price += parseFloat(item.price);
                                  this.subTotal =
                                    index === 0 ? 0 : this.subTotal;
                                  this.subTotal += parseFloat(item.price);
                                  this.vatApply = (
                                    (this.subTotal * this.vat) /
                                    100
                                  ).toFixed(2);
                                  this.totalToPay = (
                                    parseFloat(this.subTotal) +
                                    parseFloat(this.vatApply) +
                                    parseFloat(
                                      `${
                                        delivery_type === "delivery"
                                          ? this.delivery
                                          : this.pickup
                                      }`
                                    )
                                  ).toFixed(2);
                                  return (
                                    <li key={index}>
                                      <div className="cart-items-main-div">
                                        <div className="col-md-2">
                                          <Link
                                            className="cart-item-img"
                                            to="#"
                                          >
                                            <img
                                              src={
                                                filterdata &&
                                                filterdata.length > 0
                                                  ? bucketPath +
                                                    filterdata[0].image
                                                  : ""
                                              }
                                              alt="Mamachai"
                                            />
                                          </Link>
                                        </div>
                                        <div className="col-md-6 cart-item-name">
                                          <h3>
                                            <Link to="#">{item.name}</Link>
                                          </h3>
                                          <small>
                                            {filterdata &&
                                              filterdata.length > 0 &&
                                              filterdata[0].quantity}
                                          </small>
                                          <div className="edit-remove">
                                            <Link
                                              to="#"
                                              onClick={(e) =>
                                                this.onEditCart(item.uniqueID)
                                              }
                                            >
                                              <i className="icon-edit2"></i>
                                            </Link>
                                            <Link
                                              to="#"
                                              onClick={(e) =>
                                                this.onRemoveCart(item.uniqueID)
                                              }
                                            >
                                              <i className="icon-trash-o"></i>
                                            </Link>
                                          </div>
                                        </div>
                                        <div className="col-md-2">
                                          <Link
                                            to="#"
                                            className="cart-item-count"
                                          >
                                            {item.count}
                                          </Link>
                                        </div>
                                        <div className="col-md-2 no-padding">
                                          <p className="cart-item-price">
                                            RS {item.price}
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                                <div className="check-out-btn-div">
                                  <Link
                                    to="#"
                                    className="btn btn-red btn-chek-out-drop btn-block"
                                    onClick={(e) => this.placeOrder(e)}
                                  >
                                    Go to Checkout
                                    <span className="total-price">
                                      RS {total_price}
                                    </span>
                                  </Link>
                                </div>
                              </>
                            ) : (
                              <div className="check-out-btn-div">
                                <Link
                                  to="#"
                                  className="btn btn-red btn-chek-out-drop btn-block"
                                >
                                  Cart is Empty
                                </Link>
                              </div>
                            )}
                          </ul>
                        </div>
                      </div>
                    )}

                    {/* Restaurant's Header */}

                    {isLogin && this.storageType === "R" && (
                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 heaer-right-side">
                        <li className="dropdown login-drop-option">
                          <Link
                            to="#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <figure className="profile-image">
                              <img
                                src={
                                  context && context.profile_pic !== ""
                                    ? `${bucketPath}${context?.data?.resturants?.profile_pic}`
                                    : "assets/extra-images/listing-logo18.png"
                                }
                                alt=""
                              />{" "}
                            </figure>
                            {context
                              ? `${context?.data?.resturants?.restaurant_name}`
                              : "User"}
                            <span className="caret"></span>
                          </Link>
                          <ul className="dropdown-menu">
                            <li>
                              <Link to="/restaurant-dashboard">
                                <i className="icon-dashboard3"></i>Dashboard
                              </Link>
                            </li>
                            <li className="active">
                              <Link to="/restaurant-profile">
                                <i className="icon-building"></i>Mama Profile
                              </Link>
                            </li>
                            <li>
                              <Link to="/restaurant-menu">
                                <i className="icon-menu5"></i>Menu Builder
                              </Link>
                            </li>
                            <li>
                              <Link to="/restaurant-orders">
                                <i className="icon-add_shopping_cart"></i>
                                Orders
                              </Link>
                            </li>
                            <li>
                              <Link to="/restaurant-cooking-classes">
                                <i className="icon-add_shopping_cart"></i>
                                Cooking Classes
                              </Link>
                            </li>
                            <li>
                              <Link to="/restaurant-earnings">
                                <i className="icon-money"></i>Earnings
                              </Link>
                            </li>
                            <li>
                              <Link to="/restaurant-recepies">
                                <i className="icon-food"></i>Recepies
                              </Link>
                            </li>
                            <li>
                              <Link to="/restaurant-products">
                                <i className="icon-lastfm-square"></i>Products
                              </Link>
                            </li>
                            <li>
                              <Link to="/recepies-videos">
                                <i className="icon-video-camera"></i>Videos
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="logout-btn"
                                onClick={(e) => this.submitHandler(e)}
                              >
                                <i className="icon-log-out"></i>
                                Signout
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </div>
                    )}

                    {isLogin && this.storageType === "AG" && (
                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 heaer-right-side">
                        <li className="dropdown login-drop-option">
                          <Link
                            to="#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <figure className="profile-image">
                              <img
                                src={
                                  this.storageData &&
                                  JSON.parse(this.storageData).profile_image &&
                                  JSON.parse(this.storageData).profile_image !==
                                    ""
                                    ? `${bucketPath}${
                                        JSON.parse(this.storageData)
                                          .profile_image
                                      }`
                                    : "assets/extra-images/agro-img.jpg"
                                }
                                alt=""
                              />{" "}
                            </figure>
                            {this.storageData &&
                            JSON.parse(this.storageData).firstname &&
                            JSON.parse(this.storageData).lastname
                              ? JSON.parse(this.storageData).firstname +
                                " " +
                                JSON.parse(this.storageData).lastname
                              : ""}
                            <span className="caret"></span>
                          </Link>
                          <ul className="dropdown-menu">
                            <li>
                              <Link to="/contact-information">
                                <i className="icon-user4"></i>Profile
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                onClick={(e) =>
                                  this.openModal("change_password")
                                }
                              >
                                <i className="icon-build"></i>Change Password
                              </Link>
                            </li>
                            <li>
                              <Link to="/agro-products">
                                <i className="icon-lastfm-square"></i>Products
                              </Link>
                            </li>
                            <li>
                              <Link to="/visitor-packages">
                                <i className="fa fa-gift"></i>Packages
                              </Link>
                            </li>
                            <li>
                              <Link to="/facilities">
                                <i className="far fa-building"></i>Facilities
                              </Link>
                            </li>
                            <li>
                              <Link to="/all-membership">
                                <i className="fa fa-users"></i>Memberships
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="logout-btn"
                                onClick={(e) => this.submitHandler(e)}
                              >
                                <i className="icon-log-out"></i>Signout
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </div>
                    )}

                    {isLogin && this.storageType === "FC" && (
                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 heaer-right-side">
                        <li className="dropdown login-drop-option">
                          <Link
                            to="#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <figure className="profile-image">
                              <img
                                src={
                                  this.storageData &&
                                  JSON.parse(this.storageData).profile_image &&
                                  JSON.parse(this.storageData).profile_image !==
                                    ""
                                    ? `${bucketPath}${
                                        JSON.parse(this.storageData)
                                          .profile_image
                                      }`
                                    : "assets/extra-images/agro-img.jpg"
                                }
                                alt=""
                              />{" "}
                            </figure>
                            {this.storageData &&
                            JSON.parse(this.storageData).firstname &&
                            JSON.parse(this.storageData).lastname
                              ? JSON.parse(this.storageData).firstname +
                                " " +
                                JSON.parse(this.storageData).lastname
                              : ""}
                            <span className="caret"></span>
                          </Link>
                          <ul className="dropdown-menu">
                            <li>
                              <Link to="/food-center-contact-information">
                                <i className="icon-user4"></i>Profile
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                onClick={(e) =>
                                  this.openModal("change_password")
                                }
                              >
                                <i className="icon-build"></i>Change Password
                              </Link>
                            </li>
                            <li>
                              <Link to="/food-center-bookings">
                                <i className="icon-file-text2"></i>Bookings
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="logout-btn"
                                onClick={(e) => this.submitHandler(e)}
                              >
                                <i className="icon-log-out"></i>Signout
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </header>
          )}
        </IdentityContext.Consumer>
        <ul className="social-fixed-ul social-fixed-ul-2">
          <li>
            <Link to="#">
              <i className="fab fa-facebook social-ico"></i>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="fab fa-instagram social-ico"></i>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="fab fa-twitter social-ico"></i>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="fab fa-linkedin-in social-ico"></i>
            </Link>
          </li>
        </ul>

        {/* user register modal */}

        <ModalLayout modalType={modalType} closeModal={this.closeModal}>
          <>
            {modalType === "user_login" && (
              <UserLoginTab
                closeModal={this.closeModal}
                UserFormChange={this.UserFormChange}
                modalType={modalType}
                refinedUrl={this.state.refinedUrl}
                {...this.props}
                size="lg"
              />
            )}
            {(modalType === "login_phone_agro" ||
              modalType === "login_phone_user") && (
              <LoginViaPhone
                closeModal={this.closeModal}
                UserFormChange={this.UserFormChange}
                modalType={modalType}
                refinedUrl={this.state.refinedUrl}
                {...this.props}
              />
            )}
            {(modalType === "user_signup" ||
              modalType === "res_signup" ||
              modalType === "agro_signup" ||
              modalType === "user_otp" ||
              modalType === "agro_otp" ||
              modalType === "food_center_signup" ||
              modalType === "food_center_otp") && (
              <UserSignup
                closeModal={this.closeModal}
                UserFormChange={this.UserFormChange}
                modalType={modalType}
                {...this.props}
              />
            )}
            {modalType === "forgot_password" && (
              <ForgotPassword
                closeModal={this.closeModal}
                UserFormChange={this.UserFormChange}
                modalType={modalType}
              />
            )}

            {modalType === "res_login" && (
              <RestaurantLogin
                closeModal={this.closeModal}
                UserFormChange={this.UserFormChange}
                modalType={modalType}
                refinedUrl={this.state.refinedUrl}
                {...this.props}
              />
            )}

            {modalType === "agro_login" && (
              <AgroCenterLogin
                closeModal={this.closeModal}
                UserFormChange={this.UserFormChange}
                modalType={modalType}
                refinedUrl={this.state.refinedUrl}
                {...this.props}
              />
            )}

            {modalType === "food_login" && (
              <FoodCenterLogin
                closeModal={this.closeModal}
                UserFormChange={this.UserFormChange}
                modalType={modalType}
                refinedUrl={this.state.refinedUrl}
                {...this.props}
              />
            )}

            {modalType === "change_password" && (
              <ChangePasswordTab
                closeModal={this.closeModal}
                UserFormChange={this.UserFormChange}
                modalType={modalType}
                refinedUrl={this.state.refinedUrl}
                {...this.props}
              />
            )}
          </>
        </ModalLayout>
        {this.state.isexist && (
          <Modals open={open}>
            <ModalHeader
              CloseModal={this.productDetailCloseModal}
              title={
                modal_type === "add_review" ? "Add Review" : "Product Details"
              }
            />
            <ModalBody>
              {modal_type === "view_detail" ? (
                <div className="product-details-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="prudct-slider-side main-post">
                        <figure className="col-md-9">
                          <div
                            id="carousel-example-generic"
                            className="carousel slide"
                            data-ride="carousel"
                          >
                            <ol className="carousel-indicators">
                              {images &&
                                images.map((imgVal, imgIndex) => {
                                  return (
                                    <li
                                      key={"dot_" + imgIndex}
                                      data-target="#carousel-example-generic"
                                      data-slide-to={`${imgIndex}`}
                                      className=""
                                    ></li>
                                  );
                                })}
                            </ol>
                            <div className="carousel-inner" role="listbox">
                              {images &&
                                images.map((imgVal, imgIndex) => {
                                  return (
                                    <div
                                      className={`item ${
                                        imgIndex === 0 ? "active" : ""
                                      }`}
                                      key={`item_${imgIndex}`}
                                    >
                                      <Link to="#">
                                        <img
                                          src={`${bucketPath}${imgVal.image}`}
                                          alt=""
                                        />
                                      </Link>
                                    </div>
                                  );
                                })}
                            </div>
                            <Link
                              className="left carousel-control"
                              to="#carousel-example-generic"
                              role="button"
                              data-slide="prev"
                            >
                              <span
                                className="icon-arrows-1"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Previous</span>
                            </Link>
                            <Link
                              className="right carousel-control"
                              to="#carousel-example-generic"
                              role="button"
                              data-slide="next"
                            >
                              <span
                                className="icon-arrows"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Next</span>
                            </Link>
                          </div>
                        </figure>
                        {/* <img
                                    src="assets/extra-images/condiment-product-img.jpg"
                                    src={`${bucketPath}${this.state.image}`}
                                    className="img-fluid mx-auto d-block"
                                    alt="..."
                                />  */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="prudct-content-side ">
                        <h2 className="product-dt-name">
                          {name.toUpperCase()}
                        </h2>
                        <div className="list-rating">
                          <div className="rating-star">
                            <span
                              className="rating-box"
                              style={{ width: "100%" }}
                            ></span>
                          </div>
                          <span className="reviews">(1)</span>
                        </div>
                        <h3 className="prodcut-md-price">{`₹${price}`}</h3>
                        {condiment_cart instanceof Array &&
                        viewarrayFilter !== -1 ? (
                          <div
                            className={`row condiment-increment-decrement-area modal-condiment-increment-decrement-area `}
                          >
                            <div className="col-md-4">
                              <Link
                                to="#"
                                onClick={(e) =>
                                  this.onMinusCart(view_condiment)
                                }
                              >
                                {condiment_cart[viewarrayFilter].count > 1 ? (
                                  <i className="icon-minus-circle"></i>
                                ) : (
                                  <i className="icon-trash-o text-red condiment-delete-icon"></i>
                                )}
                              </Link>
                            </div>
                            <div className="col-md-4 no-padding">
                              <p>{condiment_cart[viewarrayFilter].count}</p>
                            </div>
                            <div className="col-md-4">
                              <Link
                                to="#"
                                onClick={(e) => this.onAddCart(view_condiment)}
                              >
                                <i className="icon-plus-circle"></i>
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <Link
                            className="btn btn-sm book-now "
                            to="#"
                            onClick={(e) => {
                              this.onAddCart(view_condiment);
                              this.addedItemPop();
                            }}
                          >
                            Add to Cart
                          </Link>
                        )}

                        <ul className="discription-body highlight">
                          <h4 className="margin-top-10">Product Highlights</h4>
                          <p>{product_highlight}</p>
                          <div className="stock-area">
                            <p className="stock-heading">In stock.</p>
                            <p>
                              Sold by{" "}
                              {type === "R" ? (
                                <span
                                  onClick={() =>
                                    this.restaurantlinkingHandler(
                                      restaurantdata[0].restaurantsDetails
                                        .uniqueID
                                    )
                                  }
                                >
                                  {restaurantName}
                                </span>
                              ) : (
                                <span onClick={() => this.mamalinkingHandler()}>
                                  MamaChai
                                </span>
                              )}{" "}
                              and Fulfilled by{" "}
                              <span onClick={() => this.mamalinkingHandler()}>
                                MamaChai
                              </span>
                              .
                            </p>
                          </div>
                          {/* <li>Phasellus suscipit ante id congue maximus.</li>
                                    <li>Etiam ut urna accumsan, tempus metus a, sagittis nunc.</li>
                                    <li>Sed suscipit leo vel pharetra varius.</li>
                                    <li>Etiam ut urna accumsan, tempus metus a, sagittis nunc.</li>
                                    <li>Sed suscipit leo vel pharetra varius.</li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* comment area strt */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="discription-body">
                        <h2>Product Description</h2>
                        <p>{description}</p>
                      </div>
                    </div>
                  </div>
                  {/* comment area strt */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="comment-body">
                        <h2>
                          <i className="icon-comment2"></i>Comments / Reviews
                        </h2>
                        <ul className="product-review">
                          {condiment_review_list &&
                          condiment_review_list.length > 0
                            ? condiment_review_list.map((item, index) => {
                                return (
                                  <li
                                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding"
                                    key={index}
                                  >
                                    <div className="list-holder ">
                                      <div className="product-review-text">
                                        <div className="product-title">
                                          <h6>{item.title}</h6>
                                        </div>
                                        <div className="list-rating">
                                          <div className="rating-star">
                                            <span
                                              className="rating-box"
                                              style={{
                                                width: item.points + "%",
                                              }}
                                            ></span>
                                          </div>
                                          <em className="prodcut-date pull-right">
                                            {item.lastupdate.split(" ")[0]}
                                          </em>
                                        </div>
                                      </div>

                                      <p>{item.description}</p>
                                    </div>
                                  </li>
                                );
                              })
                            : null}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-12 text-center review-btn-div">
                      <Link
                        to="#"
                        className="write-review-btn"
                        onClick={() => this.openReviewModal()}
                      >
                        Wirte Review
                      </Link>
                    </div>
                  </div>
                  {/* comment area end */}
                </div>
              ) : modal_type === "add_review" ? (
                <div className="order-detail-inner">
                  <div className="reviews-holder">
                    <div className="add-new-review-holder add-new-review-2067">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <h3>Rate and Write a Review</h3>
                        </div>
                        <div className="foodbakery-add-review-data">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div
                              className="total-rating user-rating-container overall-rating"
                              data-overall-rating="1"
                            >
                              <h6>Rating</h6>
                              <div className="rating-star">
                                <input
                                  id="star-13"
                                  type="radio"
                                  name="rating1"
                                  value="1"
                                  className="star-1"
                                  checked={
                                    rating === 1 ||
                                    rating === 2 ||
                                    rating === 3 ||
                                    rating === 4 ||
                                    rating === 5
                                      ? "checked"
                                      : ""
                                  } //"checked"
                                  onClick={(e) => this.onRadioChangeHandler(e)}
                                />
                                <label className="star-13" htmlFor="star-13">
                                  1
                                </label>
                                <input
                                  id="star-23"
                                  type="radio"
                                  name="rating2"
                                  value="2"
                                  className="star-2"
                                  checked={
                                    rating === 2 ||
                                    rating === 3 ||
                                    rating === 4 ||
                                    rating === 5
                                      ? "checked"
                                      : ""
                                  } //"checked"
                                  onClick={(e) => this.onRadioChangeHandler(e)}
                                />
                                <label className="star-2" htmlFor="star-23">
                                  2
                                </label>
                                <input
                                  id="star-33"
                                  type="radio"
                                  name="rating3"
                                  value="3"
                                  className="star-3"
                                  checked={
                                    rating === 3 || rating === 4 || rating === 5
                                      ? "checked"
                                      : ""
                                  } //"checked"
                                  onClick={(e) => this.onRadioChangeHandler(e)}
                                />
                                <label className="star-3" htmlFor="star-33">
                                  3
                                </label>
                                <input
                                  id="star-43"
                                  type="radio"
                                  name="rating4"
                                  value="4"
                                  className="star-4"
                                  checked={
                                    rating === 4 || rating === 5
                                      ? "checked"
                                      : ""
                                  } //"checked"
                                  onClick={(e) => this.onRadioChangeHandler(e)}
                                />
                                <label className="star-4" htmlFor="star-43">
                                  4
                                </label>
                                <input
                                  id="star-53"
                                  type="radio"
                                  name="rating5"
                                  value="5"
                                  className="star-5"
                                  checked={rating === 5 ? "checked" : ""} //"checked"
                                  onClick={(e) => this.onRadioChangeHandler(e)}
                                />
                                <label className="star-5" htmlFor="star-53">
                                  5
                                </label>
                                <span
                                  style={{ width: rating * 20 + "%" }}
                                ></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="form-element">
                              <i className="icon-edit2"></i>
                              <input
                                type="text"
                                placeholder="Title of your review *"
                                value={title}
                                name="title"
                                onChange={(e) => this.onChangeHandler(e)}
                              />
                              {this.validator.message(
                                "title",
                                title,
                                "required"
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="form-element">
                              <textarea
                                placeholder="Tell about your experience or leave a tip for others"
                                cols="30"
                                rows="10"
                                maxLength="500"
                                name="description"
                                value={this.state.description}
                                onChange={(e) => this.onChangeHandler(e)}
                              ></textarea>
                              {this.validator.message(
                                "description",
                                this.state.description,
                                "required|min:10"
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="form-element message-length">
                              <span>Min characters: 10</span>
                              <span>Max characters: 500</span>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="form-element">
                              <input
                                type="button"
                                defaultValue="Submit your Review"
                                onClick={(e) => this.addRivew(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modals>
        )}

        {agroExist && (
          <>
            <Modals open={AgroProductOpen}>
              <ModalHeader
                CloseModal={this.CloseAgroModal}
                title={
                  modal_agro_type === "review"
                    ? "Add Review"
                    : "Product Details"
                }
              />
              <ModalBody>
                {modal_agro_type === "view_agro_detail" ? (
                  <div className="product-details-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="prudct-slider-side main-post">
                          <figure className="col-md-9">
                            <div
                              id="carousel-example-generic"
                              className="carousel slide"
                              data-ride="carousel"
                            >
                              <ol className="carousel-indicators">
                                {agro_images &&
                                  agro_images.map((imgVal, imgIndex) => {
                                    return (
                                      <li
                                        key={"dot_" + imgIndex}
                                        data-target="#carousel-example-generic"
                                        data-slide-to={`${imgIndex}`}
                                        className=""
                                      ></li>
                                    );
                                  })}
                              </ol>
                              <div className="carousel-inner" role="listbox">
                                {agro_images &&
                                  agro_images.map((imgVal, imgIndex) => {
                                    return (
                                      <div
                                        className={`item ${
                                          imgIndex === 0 ? "active" : ""
                                        }`}
                                        key={`item_${imgIndex}`}
                                      >
                                        <Link to="#">
                                          <img
                                            src={`${bucketPath}${imgVal.image}`}
                                            alt=""
                                          />
                                        </Link>
                                      </div>
                                    );
                                  })}
                              </div>
                              <Link
                                className="left carousel-control"
                                to="#carousel-example-generic"
                                role="button"
                                data-slide="prev"
                              >
                                <span
                                  className="icon-arrows-1"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Previous</span>
                              </Link>
                              <Link
                                className="right carousel-control"
                                to="#carousel-example-generic"
                                role="button"
                                data-slide="next"
                              >
                                <span
                                  className="icon-arrows"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Next</span>
                              </Link>
                            </div>
                          </figure>
                          {/* <img
                                    src="assets/extra-images/condiment-product-img.jpg"
                                    src={`${bucketPath}${this.state.image}`}
                                    className="img-fluid mx-auto d-block"
                                    alt="..."
                                />  */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="prudct-content-side ">
                          <h2 className="product-dt-name">
                            {view_agro_product?.product?.name}
                          </h2>
                          <div className="list-rating">
                            <div className="rating-star">
                              <span
                                className="rating-box"
                                style={{ width: "100%" }}
                              ></span>
                            </div>
                            <span className="reviews">(1)</span>
                          </div>
                          <h3 className="prodcut-md-price">{`₹ ${view_agro_product?.product?.price}`}</h3>
                          {agro_product_cart instanceof Array &&
                          agroFilter !== -1 ? (
                            <div
                              className={`row condiment-increment-decrement-area modal-condiment-increment-decrement-area `}
                            >
                              <div className="col-md-4">
                                <Link
                                  to="#"
                                  onClick={(e) =>
                                    this.onMinusAgroCart(viewAgroData[0])
                                  }
                                >
                                  {agro_product_cart[agroFilter].count > 1 ? (
                                    <i className="icon-minus-circle"></i>
                                  ) : (
                                    <i className="icon-trash-o text-red condiment-delete-icon"></i>
                                  )}
                                </Link>
                              </div>
                              <div className="col-md-4 no-padding">
                                <p>{agro_product_cart[agroFilter].count}</p>
                              </div>
                              <div className="col-md-4">
                                <Link
                                  to="#"
                                  onClick={(e) =>
                                    this.onAddAgroCart(viewAgroData[0])
                                  }
                                >
                                  <i className="icon-plus-circle"></i>
                                </Link>
                              </div>
                            </div>
                          ) : (
                            <Link
                              className="btn btn-sm book-now "
                              to="#"
                              onClick={(e) => {
                                this.onAddAgroCart(viewAgroData[0]);
                              }}
                            >
                              Add to Cart
                            </Link>
                          )}

                          <ul className="discription-body highlight">
                            <h4 className="margin-top-10">
                              Product Highlights
                            </h4>
                            <p>
                              {view_agro_product?.product?.product_highlight}
                            </p>
                            {/* <div className="stock-area">
                                    <p className="stock-heading">In stock.</p>
                                    <p>Sold by {type === "R" ? <span onClick={() =>this.restaurantlinkingHandler(restaurantdata[0].restaurantsDetails.uniqueID)}>{restaurantName}</span> : <span onClick={() =>this.mamalinkingHandler()}>MamaChai</span>} and Fulfilled by <span onClick={() =>this.mamalinkingHandler()}>MamaChai</span>.</p>
                                    </div> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* comment area strt */}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="discription-body">
                          <h2>Product Description</h2>
                          <p>{view_agro_product?.product?.description}</p>
                        </div>
                      </div>
                    </div>
                    {/* comment area strt */}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="comment-body">
                          <h2>
                            <i className="icon-comment2"></i>Comments / Reviews
                          </h2>
                          <ul className="product-review">
                            {agro_review_list && agro_review_list.length > 0
                              ? agro_review_list.map((item, index) => {
                                  return (
                                    <li
                                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding"
                                      key={index}
                                    >
                                      <div className="list-holder ">
                                        <div className="product-review-text">
                                          <div className="product-title">
                                            <h6>{item.title}</h6>
                                          </div>
                                          <div className="list-rating">
                                            <div className="rating-star">
                                              <span
                                                className="rating-box"
                                                style={{
                                                  width: item.points + "%",
                                                }}
                                              ></span>
                                            </div>
                                            <em className="prodcut-date pull-right">
                                              {item.lastupdate.split(" ")[0]}
                                            </em>
                                          </div>
                                        </div>

                                        <p>{item.description}</p>
                                      </div>
                                    </li>
                                  );
                                })
                              : null}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-12 text-center review-btn-div">
                        <Link
                          to="#"
                          className="write-review-btn"
                          onClick={() => this.openAgroReviewModal()}
                        >
                          Wirte Review
                        </Link>
                      </div>
                    </div>
                    {/* comment area end */}
                  </div>
                ) : modal_agro_type === "Review" ? (
                  <div className="order-detail-inner">
                    <div className="reviews-holder">
                      <div className="add-new-review-holder add-new-review-2067">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h3>Rate and Write a Review</h3>
                          </div>
                          <div className="foodbakery-add-review-data">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                              <div
                                className="total-rating user-rating-container overall-rating"
                                data-overall-rating="1"
                              >
                                <h6>Rating</h6>
                                <div className="rating-star">
                                  <input
                                    id="star-13"
                                    type="radio"
                                    name="rating1"
                                    value="1"
                                    className="star-1"
                                    checked={
                                      rating === 1 ||
                                      rating === 2 ||
                                      rating === 3 ||
                                      rating === 4 ||
                                      rating === 5
                                        ? "checked"
                                        : ""
                                    } //"checked"
                                    onClick={(e) =>
                                      this.onRadioChangeHandler(e)
                                    }
                                  />
                                  <label className="star-13" htmlFor="star-13">
                                    1
                                  </label>
                                  <input
                                    id="star-23"
                                    type="radio"
                                    name="rating2"
                                    value="2"
                                    className="star-2"
                                    checked={
                                      rating === 2 ||
                                      rating === 3 ||
                                      rating === 4 ||
                                      rating === 5
                                        ? "checked"
                                        : ""
                                    } //"checked"
                                    onClick={(e) =>
                                      this.onRadioChangeHandler(e)
                                    }
                                  />
                                  <label className="star-2" htmlFor="star-23">
                                    2
                                  </label>
                                  <input
                                    id="star-33"
                                    type="radio"
                                    name="rating3"
                                    value="3"
                                    className="star-3"
                                    checked={
                                      rating === 3 ||
                                      rating === 4 ||
                                      rating === 5
                                        ? "checked"
                                        : ""
                                    } //"checked"
                                    onClick={(e) =>
                                      this.onRadioChangeHandler(e)
                                    }
                                  />
                                  <label className="star-3" htmlFor="star-33">
                                    3
                                  </label>
                                  <input
                                    id="star-43"
                                    type="radio"
                                    name="rating4"
                                    value="4"
                                    className="star-4"
                                    checked={
                                      rating === 4 || rating === 5
                                        ? "checked"
                                        : ""
                                    } //"checked"
                                    onClick={(e) =>
                                      this.onRadioChangeHandler(e)
                                    }
                                  />
                                  <label className="star-4" htmlFor="star-43">
                                    4
                                  </label>
                                  <input
                                    id="star-53"
                                    type="radio"
                                    name="rating5"
                                    value="5"
                                    className="star-5"
                                    checked={rating === 5 ? "checked" : ""} //"checked"
                                    onClick={(e) =>
                                      this.onRadioChangeHandler(e)
                                    }
                                  />
                                  <label className="star-5" htmlFor="star-53">
                                    5
                                  </label>
                                  <span
                                    style={{ width: rating * 20 + "%" }}
                                  ></span>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div className="form-element">
                                <i className="icon-edit2"></i>
                                <input
                                  type="text"
                                  placeholder="Title of your review *"
                                  value={title}
                                  name="title"
                                  onChange={(e) => this.onChangeHandler(e)}
                                />
                                {this.agro_validator.message(
                                  "title",
                                  this.state.title,
                                  "required"
                                )}
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div className="form-element">
                                <textarea
                                  placeholder="Tell about your experience..."
                                  cols="30"
                                  rows="10"
                                  maxLength="500"
                                  name="description"
                                  value={this.state.description}
                                  onChange={(e) => this.onChangeHandler(e)}
                                ></textarea>
                                {this.agro_validator.message(
                                  "description",
                                  this.state.description,
                                  "required|min:10"
                                )}
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div className="form-element message-length">
                                <span>Min characters: 10</span>
                                <span>Max characters: 500</span>
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div className="form-element">
                                <input
                                  type="button"
                                  defaultValue="Submit your Review"
                                  onClick={(e) => this.addAgroReview(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </ModalBody>
            </Modals>{" "}
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  restaurant_data: state.restaurant,
  agro_data: state.agro,
  food_data: state.food,
  condiment_cart: state.condimentCart.condiment_cart,
  condiment: state.restaurant.condiment_listing,
  view_condiment: state.restaurant.view_condiment,
  force_condiment_modal_open_data:
    state.condiments.force_condiment_modal_open_data,
  condiment_review_list: state.condiments.condiment_review_list,
  restaurant_listing: state.restaurant.restaurant_listing,
  agro_modal: state.AgroCart.agro_modal,
  global_agro_product: state.agro.global_agro_product,
  agro_product_cart: state.AgroCart.agro_product_cart,
  view_agro_product: state.AgroCart.view_agro_product,
  agro_review_list: state.AgroCart.agro_review_list,
});

export default connect(mapStateToProps)(Header);
