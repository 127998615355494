import * as types from "./../types/types";

const initalState = {
    loading: true,
    data: [], 
}

const adminReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.GOOGLE_MAP_ADDRESS:            
            return {
                ...state,
                loading: true,
                data:action.payload
            }
        // case types.GOOGLE_MAP_CLINIC:            
        //     return {
        //         ...state,
        //         loading: true,
        //         clinicNumber:action.payload
        //     }
        // case types.GOOGLE_MAP_CLINIC_ADDRESS:            
        //     return {
        //         ...state,
        //         loading: true,
        //         clinicAddress:action.payload
        //     }
        default:
            return state
    }
}

export default adminReducer;