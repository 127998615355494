import React from 'react';
import {useDispatch} from 'react-redux'
import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import * as RestaurantAction from './../../store/actions/restaurantActions';

function Membership(props) {
  const dispatch = useDispatch()
  const history = useHistory();

  const checkIfLoggedIn = (title,price,uniqueID) => {
    const getUserIdentity = localStorage.getItem('token');
    if(!getUserIdentity){
      swal({
        text: "You need to login to Join Membership!",
        icon: "info",
        buttons: true,
        dangerMode: true,
      })
      .then((yesDo) => {
        if (yesDo) {
          dispatch(RestaurantAction.ForceloginClick(true))
        } else {
          console.log("Record is Safe");
        }
      });
      return false;
    }

    let membershipItems = [
      {name:title,uniqueID:uniqueID,price:price}
    ]

    let subTotal = parseFloat(price);
    let totalToPay = subTotal
    const payment_type = 'card';

    let order_type = "agroCenter_membership";

    let data = {payment_type,subTotal:subTotal,totalToPay:totalToPay,cartItems:membershipItems,order_type:order_type};

    const promise = new Promise(async (resolve,reject)=>{
      let response = await dispatch(RestaurantAction.PlaceOrder(data));
      if(response.code===1){
        resolve(response)
      }else{
        reject()
      }
    })

    promise.then((response)=>{
      if(response.type==='card'){
        history.push(`/stripe-payment/${response.order_number}`)
      }

    })

    }

    return (
      // <></>
      <div className="menu-itam-holder">
        {props && props.membership_list && props.membership_list.length > 0 ? props.membership_list.map((item,index) =>(
          <div id="menu-item-list-6272" className="menu-itam-list agro-menu-itam-list" key={index}>
          <div className="row">
            <div className="col-md-12 mebership-section">
              <h3>{item.title}</h3>
              <ul className="facilites-ul">
              <p
            className=''
            dangerouslySetInnerHTML={{
              __html: item.description ? item.description : '<p className=""></p>',
            }}
            />
              </ul>
            </div>
            <div className="col-md-12 membership-join-sec">
              <span className="price">Fee: Rs {item.price}</span>
              <Link className="book-now btn" to="#" onClick={() =>checkIfLoggedIn(item.title,item.price,item.uniqueID)}>Join now </Link>
            </div>
          </div>
          </div>
        )) : null}


      </div>
    )
}

export default Membership
