import React from 'react';

function RestaurantDashboard(){
  return(
    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div className="user-dashboard loader-holder">
          <div className="user-holder">
            {/* <div className="user-message" style={{backgroundColor: '#1e73be'}}>
              <a className="close" href="#"><i className="icon-cross-out" /></a>
              <h2>Welcome to your Restaurant.</h2>
              <p>Restaurant Dashboard gives you quick access to settings and tools for managing your Account like [Change address] and [Change password] . You can [manage Restaurant] Build Menu , Manage Orders, Bookings, Reviews, Memberships, Withdrawals, Earnings, Statements, Change Password, Location and if you are you Restaurant Owner can also [Manage Team]. </p>
            </div> */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="row">
                <div className="element-title has-border right-filters-row">
                  <h5>Recent Orders</h5>
                  {/* <div className="right-filters row pull-right">
                    <div className="col-lg-6 col-md-6 col-xs-6">
                      <div className="input-field">
                        <select className="chosen-select-no-single" style={{display: 'none'}}>
                          <option selected="selected" value>Select Orders Status</option>
                          <option value="Processing">Processing</option>
                          <option value="Cancelled">Cancelled</option>
                          <option value="Completed">Completed</option>
                        </select><div className="chosen-container chosen-container-single chosen-container-single-nosearch" style={{width: '190px'}} title><a className="chosen-single" tabIndex={-1}><span>Select Orders Status</span><div><b /></div></a><div className="chosen-drop"><div className="chosen-search"><input type="text" autoComplete="off" readOnly /></div><ul className="chosen-results" /></div></div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-xs-6">
                      <div className="input-field">
                        <i className="icon-angle-down" />
                        <input type="text" data-id="daterange223" id="daterange" placeholder="Select Date Range" />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="user-orders-list">
                  <div className="responsive-table">
                    <ul className="table-generic">
                      <li className="order-heading-titles">
                        <div>Order id</div>
                        <div>Date</div>
                        <div>Total Price</div>
                        <div>Charges</div>
                        <div>Received</div>
                        <div>Status</div>
                        <div>Detail</div>
                      </li>
                      {/* <li className="order-heading-titles">
                        <div><a href="#" data-toggle="modal" data-target="#order-det-22606">order-22606</a></div>
                        <div>Apr 9,2020</div>
                        <div>Rs  38.99</div>
                        <div>Rs  3.90</div>
                        <div>Rs  35.09</div>
                        <div><span className="order-status" style={{backgroundColor: '#047a06'}}>Completed</span></div>
                        <div><a href="#" data-toggle="modal" data-target="#order-det-22606"><i className="icon-plus2 text-color" /></a></div>
                      </li>
                      <li className="order-heading-titles">
                        <div><a href="#" data-toggle="modal" data-target="#order-det-22606">order-22594</a></div>
                        <div>Apr 9,2020</div>
                        <div>Rs  37.86</div>
                        <div>Rs  3.79</div>
                        <div>Rs  34.07</div>
                        <div><span className="order-status" style={{backgroundColor: '#1e73be'}}>Processing</span></div>
                        <div><a href="#" data-toggle="modal" data-target="#order-det-22606"><i className="icon-plus2 text-color" /></a></div>
                      </li>
                      <li className="order-heading-titles">
                        <div><a href="#" data-toggle="modal" data-target="#order-det-22606">order-22589</a></div>
                        <div>Apr 9,2020</div>
                        <div>Rs  26.22</div>
                        <div>Rs  2.62</div>
                        <div>Rs  23.60</div>
                        <div><span className="order-status" style={{backgroundColor: '#047a06'}}>Completed</span></div>
                        <div><a href="#" data-toggle="modal" data-target="#order-det-22606"><i className="icon-plus2 text-color" /></a></div>
                      </li>
                      <li className="order-heading-titles">
                        <div><a href="#" data-toggle="modal" data-target="#order-det-22606">order-22586</a></div>
                        <div>Apr 9,2020</div>
                        <div>Rs  26.22</div>
                        <div>Rs  2.62</div>
                        <div>Rs  23.60</div>
                        <div><span className="order-status" style={{backgroundColor: '#047a06'}}>Completed</span></div>
                        <div><a href="#" data-toggle="modal" data-target="#order-det-22606"><i className="icon-plus2 text-color" /></a></div>
                      </li>
                      <li className="order-heading-titles">
                        <div><a href="#" data-toggle="modal" data-target="#order-det-22606">order-22583</a></div>
                        <div>Apr 9,2020</div>
                        <div>Rs  26.22</div>
                        <div>Rs  2.62</div>
                        <div>Rs  23.60</div>
                        <div><span className="order-status" style={{backgroundColor: '#1e73be'}}>Processing</span></div>
                        <div><a href="#" data-toggle="modal" data-target="#order-det-22606"><i className="icon-plus2 text-color" /></a></div>
                      </li>
                      <li className="order-heading-titles">
                        <div><a href="#" data-toggle="modal" data-target="#order-det-22606">order-22493</a></div>
                        <div>Apr 2,2020</div>
                        <div>Rs  28.24</div>
                        <div>Rs  2.82</div>
                        <div>Rs  25.42</div>
                        <div><span className="order-status" style={{backgroundColor: '#047a06'}}>Completed</span></div>
                        <div><a href="#" data-toggle="modal" data-target="#order-det-22606"><i className="icon-plus2 text-color" /></a></div>
                      </li> */}
                    </ul>
                  </div>
                  <div className="print-order-detail menu-order-detail order-detail" style={{display: 'none'}}>
                    <div className="logo"><img src="assets/extra-images/main-logo.png" alt="" /></div>
                    <h2>Order Detail</h2>
                    <div className="order-detail-inner">
                      <div className="description-holder">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="list-detail-options has-checkbox">
                              <h3>Restaurant Demo </h3>
                              <ul className="order-detail-options">
                                <li className="order-number">
                                  <strong>Order ID:</strong>
                                  <span>22606</span>
                                </li>
                                <li className="req-delivery">
                                  <strong>Delivery Time:</strong>
                                  <span>10 Minutes </span>
                                </li>
                                <li className="created-date">
                                  <strong>Delivery Date:</strong>
                                  <span>Apr 9, 2020 06:38 AM </span>
                                </li>
                                <li className="order-type">
                                  <strong>Type:</strong>
                                  <span>order</span>
                                </li>
                                <li className="order-type">
                                  <strong>Payment Status:</strong>
                                  <span>Approved</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="customer-detail-holder">
                              <h3>Customer Detail</h3>
                              <ul className="customer-detail">
                                <li>
                                  <strong>Name :</strong>
                                  <span>Buyer Demo</span>
                                </li>
                                <li>
                                  <strong>Phone Number :</strong>
                                  <span>0123456789</span>
                                </li>
                                <li>
                                  <strong>Email :</strong>
                                  <span>dum4@chimpgroup.com</span>
                                </li>
                                <li>
                                  <strong>Address :</strong>
                                  <span>London</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div className="order-status-holder">
                                <h3> Order Status:</h3>
                                <div className="order-status-process order-status">
                                  <p>Completed</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h2 className="heading">Food Menu</h2>
                            <div className="responsive-table">
                              <ul className="categories-order table-generic">
                                <li className="order-heading-titles">
                                  <div>Products</div>
                                  <div>Price per</div>
                                </li>
                                <li className="order-heading-titles">
                                  <div>
                                    <h4>Pizzas</h4>
                                    <h5>Foodbakery Special 9" Deep Pan</h5>
                                  </div>
                                  <div><span className="category-price">Rs 4.80</span></div>
                                </li>
                                <li className="order-heading-titles">
                                  <div>
                                    <h4>Pizzas</h4>
                                    <h5>Foodbakery Special 9" Deep Pan</h5>
                                  </div>
                                  <div><span className="category-price">Rs 4.80</span></div>
                                </li>
                                <li className="order-heading-titles">
                                  <div>
                                    <h4>Pizzas</h4>
                                    <h5>Foodbakery Special 12" Deep Pan</h5>
                                  </div>
                                  <div><span className="category-price">Rs 3.90</span></div>
                                </li>
                                <li className="order-heading-titles">
                                  <div>
                                    <h4>Garlic Bread</h4>
                                    <h5>Garlic Bread 12" Deep</h5>
                                  </div>
                                  <div><span className="category-price">Rs 3.50</span></div>
                                </li>
                                <li className="order-heading-titles">
                                  <div>
                                    <h4>Kebabs</h4>
                                    <h5>Kebabs With Naan</h5>
                                  </div>
                                  <div><span className="category-price">Rs 4.50</span></div>
                                </li>
                                <li className="order-heading-titles">
                                  <div>
                                    <h4>Burgers</h4>
                                    <h5>Quarter Pounder</h5>
                                  </div>
                                  <div><span className="category-price">Rs 3.00</span></div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="row">
                              <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                <h3>Order Total</h3>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <ul className="order-detail-options order-total">
                                  <li className="created-date">
                                    <strong>Subtotal:</strong>
                                    <span>Rs 24.50</span>
                                  </li>
                                  <li className="order-type">
                                    <strong>
                                      Pick Up Fee: </strong>
                                    <span>Rs 10.00</span>
                                  </li>
                                  <li className="order-type">
                                    <strong>VAT (13%)</strong>
                                    <span>Rs 4.48</span>
                                  </li>
                                  <li className="order-type total-price">
                                    <strong>Total:</strong>
                                    <span>Rs 38.99</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal fade menu-order-detail order-detail" id="order-det-22606" tabIndex={-1} role="dialog" style={{display: 'none'}}>
                    <div className="modal-dialog">
                      <div className="modal-content mCustomScrollbar _mCS_1 mCS-autoHide" style={{height: '724px', overflow: 'visible'}}><div id="mCSB_1" className="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside" style={{maxHeight: '672px'}} tabIndex={0}><div id="mCSB_1_container" className="mCSB_container mCS_y_hidden mCS_no_scrollbar_y" style={{position: 'relative', top: 0, left: 0}} dir="ltr">
                            <div className="modal-header">
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                              <h2>Order Detail</h2>
                              <button className="btn-print"><i className="icon-printer" /><span>Receipt</span></button>
                            </div>
                            <div className="modal-body">
                              <div className="order-detail-inner">
                                <div className="description-holder">
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                      <div className="list-detail-options has-checkbox">
                                        <h3>
                                          Restaurant Demo </h3>
                                        <ul className="order-detail-options">
                                          <li className="order-number">
                                            <strong>Order ID:</strong>
                                            <span>22606</span>
                                          </li>
                                          <li className="req-delivery">
                                            <strong>Delivery Time:</strong>
                                            <span>10 Minutes </span>
                                          </li>
                                          <li className="created-date">
                                            <strong>Delivery Date:</strong>
                                            <span>
                                              Apr 9, 2020 06:38 AM </span>
                                          </li>
                                          <li className="order-type">
                                            <strong>Type:</strong>
                                            <span>order</span>
                                          </li>
                                          <li className="order-type">
                                            <strong>Payment Status:</strong>
                                            <span>Approved</span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                      <div className="customer-detail-holder">
                                        <h3>Customer Detail</h3>
                                        <ul className="customer-detail">
                                          <li>
                                            <strong>Name :</strong>
                                            <span>Buyer Demo</span>
                                          </li>
                                          <li>
                                            <strong>Phone Number :</strong>
                                            <span>0123456789</span>
                                          </li>
                                          <li>
                                            <strong>Email :</strong>
                                            <span>dum4@chimpgroup.com</span>
                                          </li>
                                          <li>
                                            <strong>Address :</strong>
                                            <span>London</span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                      <div className="order-status-holder">
                                        <div className="row">
                                          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                                            <h3>Order Status </h3>
                                          </div>
                                          <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                                            <div className="input-field">
                                              <select className="chosen-select-no-single" style={{display: 'none'}}>
                                                <option value="Processing">Processing</option>
                                                <option value="Cancelled">Cancelled</option>
                                                <option selected="selected" value="Completed">Completed</option>
                                              </select><div className="chosen-container chosen-container-single chosen-container-single-nosearch" style={{width: '0px'}} title><a className="chosen-single" tabIndex={-1}><span>Completed</span><div><b /></div></a><div className="chosen-drop"><div className="chosen-search"><input type="text" autoComplete="off" readOnly /></div><ul className="chosen-results" /></div></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                      <h2 className="heading">Food Menu</h2>
                                      <div className="responsive-table">
                                        <ul className="categories-order table-generic">
                                          <li className="order-heading-titles">
                                            <div>Products</div>
                                            <div>Price per</div>
                                          </li>
                                          <li className="order-heading-titles">
                                            <div>
                                              <h4>Pizzas</h4>
                                              <h5>Foodbakery Special 9" Deep Pan</h5>
                                            </div>
                                            <div><span className="category-price">Rs 4.80</span></div>
                                          </li>
                                          <li className="order-heading-titles">
                                            <div>
                                              <h4>Pizzas</h4>
                                              <h5>Foodbakery Special 9" Deep Pan</h5>
                                            </div>
                                            <div><span className="category-price">Rs 4.80</span></div>
                                          </li>
                                          <li className="order-heading-titles">
                                            <div>
                                              <h4>Pizzas</h4>
                                              <h5>Foodbakery Special 12" Deep Pan</h5>
                                            </div>
                                            <div><span className="category-price">Rs 3.90</span></div>
                                          </li>
                                          <li className="order-heading-titles">
                                            <div>
                                              <h4>Garlic Bread</h4>
                                              <h5>Garlic Bread 12" Deep</h5>
                                            </div>
                                            <div><span className="category-price">Rs 3.50</span></div>
                                          </li>
                                          <li className="order-heading-titles">
                                            <div>
                                              <h4>Kebabs</h4>
                                              <h5>Kebabs With Naan</h5>
                                            </div>
                                            <div><span className="category-price">Rs 4.50</span></div>
                                          </li>
                                          <li className="order-heading-titles">
                                            <div>
                                              <h4>Burgers</h4>
                                              <h5>Quarter Pounder</h5>
                                            </div>
                                            <div><span className="category-price">Rs 3.00</span></div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                      <div className="row">
                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                          <h3>Order Total</h3>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                          <ul className="order-detail-options order-total">
                                            <li className="created-date">
                                              <strong>Subtotal:</strong>
                                              <span>Rs 24.50</span>
                                            </li>
                                            <li className="order-type">
                                              <strong>
                                                Pick Up Fee: </strong>
                                              <span>Rs 10.00</span>
                                            </li>
                                            <li className="order-type">
                                              <strong>VAT (13%)</strong>
                                              <span>Rs 4.48</span>
                                            </li>
                                            <li className="order-type total-price">
                                              <strong>Total:</strong>
                                              <span>Rs 38.99</span>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div></div><div id="mCSB_1_scrollbar_vertical" className="mCSB_scrollTools mCSB_1_scrollbar mCS-minimal-dark mCSB_scrollTools_vertical" style={{display: 'none'}}><div className="mCSB_draggerContainer"><div id="mCSB_1_dragger_vertical" className="mCSB_dragger" style={{position: 'absolute', minHeight: '30px', top: '0px'}}><div className="mCSB_dragger_bar" style={{lineHeight: '30px'}} /></div><div className="mCSB_draggerRail" /></div></div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <ul className="pagination">
              <li className="active"><a>1</a></li>
              <li><a href="#">2</a></li>
              <li><a href="#">3</a></li>
              <li><span className="page-numbers dots">…</span></li>
              <li><a href="#">5</a></li>
              <li><a href="#">Next </a></li>
            </ul> */}
          </div>
        </div>
      </div>
  )
}

export default RestaurantDashboard
