import React, { Component } from "react"; 
import { Link } from "react-router-dom"; 
import { connect } from "react-redux";

class BuyerDashboard extends Component {
  render() { 
    return ( 
            <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                <div className="user-dashboard">
                    <div className="user-holder"> 
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="user-suggest-list listing simple"> 
                                    <ul className="user-suggest-list-holder">
                                        <li>
                                            <div className="suggest-list-holder">
                                                <div className="img-holder">
                                                    <figure>
                                                        <Link to="listings.html"><img src="assets/extra-images/mama-logo.png" alt="" /> </Link>
                                                    </figure>
                                                </div>
                                                <div className="text-holder">
                                                    <div className="post-title">
                                                        <h5><Link to="/listings">Dragon Express</Link></h5>
                                                    </div>
                                                    <span className="post-categories"> Apple Juice , Carrot Juice , Ice Cream </span>
                                                    <div className="list-option">
                                                        <Link to="#" className="shortlist-btn shortlisted"><i className="icon-heart4"></i></Link>
                                                        <Link to="/listings" className="viewmenu-btn">View Menu</Link>
                                                    </div>
                                                    <div className="delivery-potions">
                                                        <div className="post-time">
                                                            <i className="icon-location-pin"></i>
                                                        </div>
                                                        <span>Kangra (HP)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="suggest-list-holder">
                                                <div className="img-holder">
                                                    <figure>
                                                        <Link to="/listings"><img src="assets/extra-images/listing-logo18.png" alt="" /> </Link>
                                                    </figure>
                                                </div>
                                                <div className="text-holder">
                                                    <div className="post-title">
                                                        <h5><Link to="/listings">Restaurant Demo </Link></h5>
                                                    </div>
                                                    <span className="post-categories">  Apple Juice</span>
                                                    <div className="list-option">
                                                        <Link to="#" className="shortlist-btn shortlist-btn"><i className="icon-heart-o"></i> </Link>
                                                        <Link to="/listings" className="viewmenu-btn">View Menu</Link>
                                                    </div>
                                                    <div className="delivery-potions">
                                                        <div className="post-time">
                                                            <i className="icon-location-pin"></i>
                                                        </div>
                                                        <span>Kangra (HP)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="img-holder">
                                                <figure>
                                                    <Link to="#"> <img src="assets/extra-images/mama-logo.png" className="img-list wp-post-image" alt="" /> </Link>
                                                </figure>
                                            </div>
                                            <div className="text-holder">
                                                <div className="post-title">
                                                    <h5>
                                                        <Link to="/listing-detail">Jet’s Kitchen</Link>
                                                    </h5>
                                                </div>
                                                <span className="post-categories"><span>
                                                    </span> Chines
                                                    Soup, Egg Fry, Fish Fry</span>
                                                <div className="delivery-potions">
                                                    <div className="post-time">
                                                        <i className="icon-location-pin"></i>
                                                    </div>
                                                    <span>Kangra (HP)</span>
                                                </div>
                                            </div>
                                            <div className="list-option">
                                                <Link to="#" className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </Link>
                                                <Link to="#" className="viewmenu-btn text-color">View Menu</Link>
                                            </div>
                                        </li>
                                    
                                        <li>
                                            <div className="img-holder">
                                                <figure>
                                                    <Link to="#"><img src="assets/extra-images/mama-logo.png" className="img-list wp-post-image" alt="" /> </Link>
                                                </figure>
                                            </div>
                                            <div className="text-holder">
                                                <div className="post-title">
                                                    <h5>
                                                        <Link to="/listing-detail ">Nature Healthy Food</Link>
                                                    </h5>
                                                </div>
                                                <span className="post-categories"><span>
                                                    </span> Apple
                                                    Juice, Chicken Roast, Chines Soup, Magi</span>
                                                <div className="delivery-potions">
                                                    <div className="post-time">
                                                        <i className="icon-location-pin"></i>
                                                    </div>
                                                    <span>Kangra (HP)</span>
                                                </div>
                                            </div>
                                            <div className="list-option">
                                                <Link to="#" className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </Link>
                                                <Link to="#" className="viewmenu-btn text-color">View Menu</Link>
                                            </div>
                                        </li>
                                        
                                    </ul>
                                </div>
                                <ul className="pagination">
                                    <li className="active"><Link to="#">1</Link></li>
                                    <li><Link to="#">2</Link></li>
                                    <li><Link to="#">3</Link></li>
                                    <li><span className="page-numbers dots">…</span></li>
                                    <li><Link to="#">24</Link></li>
                                    <li><Link to="#">Next </Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="modal fade" id="suggestions-box" tabIndex="-1" role="dialog" style={{display: 'none'}}>
                            <div className="modal-dialog" role="document">
                                <div className="login-form">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                            <h3 className="modal-title">Suggested Restaurants Settings</h3>
                                        </div>
                                        <div className="modal-body">
                                            <div className="status status-message"></div>
                                            <form method="post" className="wp-user-form webkit" id="ControlForm_suggestions">
                                                <div className="input-filed">
                                                    <ul className="form-elements" id="wrapper_suggested_restaurants_categories">
                                                        <li className="to-label"><label>Categories for suggestions</label></li>
                                                        <li className="to-field multiple"><select required="required" className="multiple" multiple="multiple" id="foodbakery_suggested_restaurants_categories" name="foodbakery_suggested_restaurants_categories[]" style={{height: '110px !important', display: 'none'}} data-placeholder="Please Select">
                                                                <option value="all_categories">All Categories</option>
                                                                <option value="apple-juice">Apple Juice</option>
                                                                <option value="bb-q">BB.Q</option>
                                                                <option value="beef-roast">Beef Roast</option>
                                                                <option value="carrot-juice">Carrot Juice</option>
                                                                <option value="cheese-burger">Cheese Burger</option>
                                                                <option value="chicken-roast">Chicken Roast</option>
                                                                <option value="chines-soup">Chines Soup</option>
                                                                <option value="cold-coffee">Cold Coffee</option>
                                                                <option value="cold-drink">Cold Drink</option>
                                                                <option value="cupcake">Cupcake</option>
                                                                <option value="doughnut">Doughnut</option>
                                                                <option value="drum-stick">Drum Stick</option>
                                                                <option value="egg-fry">Egg Fry</option>
                                                                <option value="fish-fry">Fish Fry</option>
                                                                <option value="fresh-juice">Fresh Juice</option>
                                                                <option value="hot-dogs">Hot Dogs</option>
                                                                <option value="ice-cream">Ice Cream</option>
                                                                <option value="magi">Magi</option>
                                                                <option value="noodles">Noodles</option>
                                                                <option value="pastry">Pastry</option>
                                                                <option value="pizza">Pizza</option>
                                                                <option value="potato-fries">Potato Fries</option>
                                                                <option value="prawns">Prawns</option>
                                                                <option value="sham-pane">Sham pane</option>
                                                                <option value="shawarma">Shawarma</option>
                                                                <option value="soda-water">Soda water</option>
                                                                <option value="stakes">Stakes</option>
                                                                <option value="steam-roast">Steam Roast</option>
                                                                <option value="tea">Tea</option>
                                                            </select>
                                                            <div className="chosen-container chosen-container-multi" style={{width: '0px'}} title="" id="foodbakery_suggested_restaurants_categories_chosen">
                                                                <ul className="chosen-choices">
                                                                    <li className="search-choice"><span>BB.Q</span><Link to="#" className="search-choice-close" data-option-array-index="2"></Link></li>
                                                                    <li className="search-choice"><span>Carrot Juice</span><Link to="#" className="search-choice-close" data-option-array-index="4"></Link></li>
                                                                    <li className="search-field"><input type="text" defaultValue="Please Select" className="" autoComplete="off" style={{width: '25px'}} /></li>
                                                                </ul>
                                                                <div className="chosen-drop">
                                                                    <ul className="chosen-results">
                                                                        <li className="active-result" data-option-array-index="0">All Categories</li>
                                                                        <li className="active-result" data-option-array-index="1">Apple Juice</li>
                                                                        <li className="result-selected" data-option-array-index="2">BB.Q</li>
                                                                        <li className="active-result" data-option-array-index="3">Beef Roast</li>
                                                                        <li className="result-selected" data-option-array-index="4">Carrot Juice</li>
                                                                        <li className="active-result" data-option-array-index="5">Cheese Burger</li>
                                                                        <li className="active-result" data-option-array-index="6">Chicken Roast</li>
                                                                        <li className="active-result" data-option-array-index="7">Chines Soup</li>
                                                                        <li className="active-result" data-option-array-index="8">Cold Coffee</li>
                                                                        <li className="active-result" data-option-array-index="9">Cold Drink</li>
                                                                        <li className="active-result" data-option-array-index="10">Cupcake</li>
                                                                        <li className="active-result" data-option-array-index="11">Doughnut</li>
                                                                        <li className="active-result" data-option-array-index="12">Drum Stick</li>
                                                                        <li className="active-result" data-option-array-index="13">Egg Fry</li>
                                                                        <li className="active-result" data-option-array-index="14">Fish Fry</li>
                                                                        <li className="active-result" data-option-array-index="15">Fresh Juice</li>
                                                                        <li className="active-result" data-option-array-index="16">Hot Dogs</li>
                                                                        <li className="active-result" data-option-array-index="17">Ice Cream</li>
                                                                        <li className="active-result" data-option-array-index="18">Magi</li>
                                                                        <li className="active-result" data-option-array-index="19">Noodles</li>
                                                                        <li className="active-result" data-option-array-index="20">Pastry</li>
                                                                        <li className="active-result" data-option-array-index="21">Pizza</li>
                                                                        <li className="active-result" data-option-array-index="22">Potato Fries</li>
                                                                        <li className="active-result" data-option-array-index="23">Prawns</li>
                                                                        <li className="active-result" data-option-array-index="24">Sham pane</li>
                                                                        <li className="active-result" data-option-array-index="25">Shawarma</li>
                                                                        <li className="active-result" data-option-array-index="26">Soda water</li>
                                                                        <li className="active-result" data-option-array-index="27">Stakes</li>
                                                                        <li className="active-result" data-option-array-index="28">Steam Roast</li>
                                                                        <li className="active-result" data-option-array-index="29">Tea</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="input-filed">
                                                    <label>Number of suggestions to show</label>
                                                    <input type="text" tabIndex="11" placeholder="example 20" className="form-control" id="suggested_restaurants_max_restaurants" name="suggested_restaurants_max_restaurants" defaultValue="7" /> </div>
                                                <div className="input-filed">
                                                    <div className="save-search-criteria input-button-loader">
                                                        <input type="button" className="btn-suggestions-settings cs-bgcolor" name="submit-suggestions-settings" defaultValue="Save Settings" />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
		    </div> 
    );
  }
}

const mapStateToProps = (state) => ({ 
});
  
export default connect(mapStateToProps)(BuyerDashboard);
