import React from 'react';
import {Switch } from 'react-router-dom';
import RouteWithLayout from './../layout/RouteWithLayout';
import InnerLayout from './../layout/InnerLayout';
import HomeLayout from './../layout/HomeLayout';
import MainLayout from './../layout/MainLayout';
import EmptyLayout from './../layout/EmptyLayout';
import ExceptionLayout from './../layout/ExceptionLayout';
import AdminLayout from './../layout/AdminLayout/AdminLayout';


import HomePage from './../components/homepage'
import setPassword from './../components/SetPassword'
import Recepies from './../components/recepies'
import FoodCaoch from './../components/foodCoach'
import Condiments from './../components/Condiments'
import FestivalKit from './../components/FestivalKit'
import DietPlan from './../components/DietPlan'
import MultiOrder from './../components/MultiOrder'
import NutritionFact from './../components/NutritionFact'
import MainStore from './../components/MainStore'


import Listings from './../components/listings'
import ListingDetail from './../components/listingDetail'
import Home from './../components/gMaps/Home';

import RestaurantsListing from './../components/Restaurant/SubCategory/Listing/RestaurantsListing';
import MenuListing from './../components/Restaurant/SubCategory/Listing/MenuListing';
import RecipeDetail from './../components/Restaurant/SubCategory/Listing/RecipeDetail';
import RecipeInfo from './../components/Restaurant/RecipeCategory/RecipeInfo';
import MamaLive from  '../components/MamaLive';
import PaymentDesign from '../components/PaymentDesign'

import AgroListing from './../components/Agro/AgroListing';
import AgroInfo from './../components/Agro/AgroInfo';
import LocationInformation from './../components/Agro/LocationInformation';
import ContactInformation from './../components/Agro/ContactInformation';
import OtherInformation from './../components/Agro/OtherInformation';
import UploadInformation from './../components/Agro/UploadInformation';
import AgroChangePassword from './../components/Agro/AgroChangePassword';
import Packages from './../components/Agro/Packages';
import PackageBuilder from './../components/Agro/PackageBuilder';
import AgroProducts from './../components/Agro/AgroProducts';
import AllMembership from './../components/Agro/AllMembership';
import MembershipBuilder from './../components/Agro/MembershipBuilder';
import Facilities from './../components/Agro/Facilities';
import FacilitiesBuilder from './../components/Agro/FacilitiesBuilder';





import FoodListing from './../components/Food/FoodListing';
import FoodContactInformation from './../components/Food/FoodContactInformation';
import FoodLocationInformation from './../components/Food/FoodLocationInformation';
import FoodOtherInformation from './../components/Food/FoodOtherInformation';
import FoodInfo from './../components/Food/FoodInfo';
import FoodBookings from './../components/Food/FoodBookings';
import FoodUploadInformation from './../components/Food/FoodUploadInformation';


// ************************************BUYER'S ROUTES ***************************************************
import BuyerDashboard from '../components/Buyers/buyerDashboard';
import BuyerOrder from '../components/Buyers/buyerOrder';
import BuyerAccountSetting from '../components/Buyers/buyerAccountSetting';
import BuyerChangePassword from '../components/Buyers/buyerChangePassword';
import ShortList from '../components/Buyers/ShortList';
import BuyerDashboardNew from '../components/Buyers/BuyerDashboardNew';
import BuyerTranscation from '../components/Buyers/BuyerTranscation';
import BuyerAddMoreAddress from '../components/Buyers/BuyerAddMoreAddress';
import BuyerBookings from '../components/Buyers/BuyerBookings';
import BuyerProductOrder from '../components/Buyers/BuyerProductOrder';




// **************************************RESTAURANT'S ROUTES ********************************************
import RestaurantProfile from '../components/Restaurant/restaurantProfile';
import RestaurantDelivery from '../components/Restaurant/restaurantDelivery';
import RestaurantOpenClose from '../components/Restaurant/restaurantOpenHours';
import RestaurantMenuBuilder from '../components/Restaurant/restaurantMenuBuilder';
import RestaurantRecepies from '../components/Restaurant/restaurantRecepies';
import RecepieBuilder from '../components/Restaurant/recepieBuilder';
import RecipeView from '../components/Restaurant/recepieView';
import RecipeVideos from '../components/Restaurant/recipeVideos';
import RecepieVideoBuilder from '../components/Restaurant/recepieVideoBuilder';
import Orders from '../components/Restaurant/Orders';
import RestaurantCookingClasses from '../components/Restaurant/RestaurantCookingClasses';
import RestaurantProducts from '../components/Restaurant/RestaurantProducts';

import AddCookingClass from '../components/Restaurant/AddCookingClass';
import Earnings from '../components/Restaurant/Earnings';
import RestaurantDashboard from '../components/Restaurant/RestaurantDashboard';


import LiveStream from '../components/Restaurant/LiveStream';
// **************************************TEST'S ROUTES ********************************************
import DraggableCell from '../components/Restaurant/SubCategory/DraggableCell';
// *****************************************ADMIN ROUTES********************************************8888
import AdminLogin from './../components/admin/AdminLogin';
import Dashboard from'../components/admin/Dashboard';
import MyUser from '../components/admin/MyUser';
import RestaurantListing from '../components/admin/RestaurantListing';
import AdminOrder from '../components/admin/AdminOrder';
import AdminAgroCenter from '../components/admin/AdminAgroCenter';
import AdminFoodCenter from '../components/admin/AdminFoodCenter';
import AdminProduct from '../components/admin/AdminProduct/AdminProduct';
// import AdminListing from '../components/admin/AdminListing'

// ***************STRIPE PAYMENT///////////////////////////////
import PaymentForm from './../components/Stripe/PaymentForm'

// ****************************EXCEPTION PAGE********************************
import ExceptionPage from '../components/Exception/ExceptionPage'
import Terms from '../layout/Terms';
import Privacy from '../layout/Privacy';
import SpecialOffer from '../layout/SpecialOffer'; 
import Support from '../layout/Support';
import Feedback from '../layout/Feedback';


function AppRouter(props) {
    return ( < Switch >
        <RouteWithLayout layout = { HomeLayout } path="/" exact component={HomePage}/>
        <RouteWithLayout layout = { InnerLayout } path="/set-password/:id" component={setPassword} />
        <RouteWithLayout layout = { InnerLayout } path="/recepies" component={Recepies} />
        <RouteWithLayout layout = { InnerLayout } path="/food-classes" component={FoodCaoch} />
        <RouteWithLayout layout = { InnerLayout } path="/condiments" component={Condiments} />
        <RouteWithLayout layout = { InnerLayout } path="/festival-kit" component={FestivalKit} />
        <RouteWithLayout layout = { InnerLayout } path="/diet-plan" component={DietPlan} />
        <RouteWithLayout layout = { InnerLayout } path="/multi-order" component={MultiOrder} />
        <RouteWithLayout layout = { InnerLayout } path="/nutrition-fact" component={NutritionFact} />
        <RouteWithLayout layout = { InnerLayout } path="/store/:urldata" component={MainStore} />



        <RouteWithLayout layout = { InnerLayout } path="/listings" component={Listings} />
        <RouteWithLayout layout = { InnerLayout } path="/listing-detail" component={ListingDetail} />
        <RouteWithLayout layout = { InnerLayout } path="/google-maps" component={Home}/>
        <RouteWithLayout layout = { InnerLayout } path="/restaurants-listing" component={RestaurantsListing} />
        <RouteWithLayout layout = { InnerLayout } path="/menu-listing/:token" component={MenuListing} />
        <RouteWithLayout layout = { InnerLayout } path="/stripe-payment/:token" component={PaymentForm} />


        <RouteWithLayout layout = { InnerLayout } path="/agro-listing" component={AgroListing} />
        <RouteWithLayout layout = { MainLayout } path="/location-Information" component={LocationInformation} />
        <RouteWithLayout layout = { MainLayout } path="/contact-Information" component={ContactInformation} />
        <RouteWithLayout layout = { MainLayout } path="/other-Information" component={OtherInformation} />
        <RouteWithLayout layout = { MainLayout } path="/upload-information" component={UploadInformation} />
        <RouteWithLayout layout = { MainLayout } path="/agro-change-password" component={AgroChangePassword} />
        <RouteWithLayout layout = { MainLayout } path="/visitor-packages" component={Packages} />
        <RouteWithLayout layout = { MainLayout } path="/add-package" component={PackageBuilder} />
        <RouteWithLayout layout = { MainLayout } path="/edit-package/:packageID" component={PackageBuilder} />
        <RouteWithLayout layout = { MainLayout } path="/agro-products" component={AgroProducts} />
        <RouteWithLayout layout = { MainLayout } path="/all-membership" component={AllMembership} />
        <RouteWithLayout layout = { MainLayout } path="/add-membership" component={MembershipBuilder} />
        <RouteWithLayout layout = { MainLayout } path="/edit-membership/:uniqueID" component={MembershipBuilder} />
        <RouteWithLayout layout = { MainLayout } path="/facilities" component={Facilities} />
        <RouteWithLayout layout = { MainLayout } path="/add-facility" component={FacilitiesBuilder} />
        <RouteWithLayout layout = { MainLayout } path="/edit-facility/:uniqueID" component={FacilitiesBuilder} />




        <RouteWithLayout layout = { InnerLayout } path="/agro-info/:token/:id" component={AgroInfo} />
        <RouteWithLayout layout = { InnerLayout } path="/food-listing" component={FoodListing} />
        <RouteWithLayout layout = { MainLayout } path="/food-center-contact-information" component={FoodContactInformation} />
        <RouteWithLayout layout = { MainLayout } path="/food-center-location-information" component={FoodLocationInformation} />
        <RouteWithLayout layout = { MainLayout } path="/food-center-other-information" component={FoodOtherInformation} />
        <RouteWithLayout layout = { MainLayout } path="/food-center-bookings" component={FoodBookings} />
        <RouteWithLayout layout = { MainLayout } path="/food-center-upload-information" component={FoodUploadInformation} />

        <RouteWithLayout layout = { InnerLayout } path="/food-info/:token/:id" component={FoodInfo} />







        {/* ************************************* BUYER'S ROUTES *************************************************   */}
        <RouteWithLayout layout = { MainLayout } path="/buyer-dash" exact component={BuyerDashboard} />
        <RouteWithLayout layout = { MainLayout } path="/buyer-orders" exact component={BuyerOrder} />
        <RouteWithLayout layout = { MainLayout } path="/buyer-account-setting" exact component={BuyerAccountSetting} />
        <RouteWithLayout layout = { MainLayout } path="/buyer-change-password" exact component={BuyerChangePassword} />
        <RouteWithLayout layout = { MainLayout } path="/buyer-short-list" exact component={ShortList} />
        <RouteWithLayout layout = { MainLayout } path="/buyer-dashboard" exact component={BuyerDashboardNew} />
        <RouteWithLayout layout = { MainLayout } path="/buyer-transcation" exact component={BuyerTranscation} />
        <RouteWithLayout layout = { MainLayout } path="/buyer-more-address" exact component={BuyerAddMoreAddress} />
        <RouteWithLayout layout = { MainLayout } path="/buyer-bookings" exact component={BuyerBookings} />
        <RouteWithLayout layout = { MainLayout } path="/buyer-product-orders" exact component={BuyerProductOrder} /> 





        {/* ************************************* BUYER'S ROUTES *************************************************   */}

        {/* ****************************************** RESTAURANT"S ROUTES ******************************************** */}
        <RouteWithLayout layout = { MainLayout } path="/restaurant-profile" exact component={RestaurantProfile} />
        <RouteWithLayout layout = { MainLayout } path="/restaurant-delivery" exact component={RestaurantDelivery} />
        <RouteWithLayout layout = { MainLayout } path="/restaurant-open-close" exact component={RestaurantOpenClose} />
        <RouteWithLayout layout = { MainLayout } path="/restaurant-menu" exact component={RestaurantMenuBuilder} />
        <RouteWithLayout layout = { MainLayout } path="/restaurant-orders" exact component={Orders} />
        <RouteWithLayout layout = { MainLayout } path="/restaurant-cooking-classes" exact component={RestaurantCookingClasses} />
        <RouteWithLayout layout = { MainLayout } path="/restaurant-products"  component={RestaurantProducts} />
        <RouteWithLayout layout = { MainLayout } path="/restaurant-earnings"  component={Earnings} />
        <RouteWithLayout layout = { MainLayout } path="/restaurant-dashboard"  component={RestaurantDashboard} />


        <RouteWithLayout layout = { MainLayout } path="/restaurant-recepies" component={RestaurantRecepies} />
        <RouteWithLayout layout = { MainLayout } path="/add-recepie" component={RecepieBuilder} />
        <RouteWithLayout layout = { MainLayout } path="/edit-recepie/:recipeID" component={RecepieBuilder} />
        <RouteWithLayout layout = { MainLayout } path="/add-cooking-class" component={AddCookingClass} />
        <RouteWithLayout layout = { MainLayout } path="/edit-cooking-class/:uniqueID" component={AddCookingClass} />

        <RouteWithLayout layout = { InnerLayout } path="/recipe-view/:recipeID" component={RecipeView} />
        <RouteWithLayout layout = { MainLayout } path="/recepies-videos" component={RecipeVideos} />
        <RouteWithLayout layout = { MainLayout } path="/add-video" component={RecepieVideoBuilder} />
        <RouteWithLayout layout = { EmptyLayout } path="/live-stream" component={LiveStream} />
        <RouteWithLayout layout = { MainLayout } path="/edit-video/:recordingID" component={RecepieVideoBuilder} />
        {/* <RouteWithLayout layout = { InnerLayout } path="/recipe-detail/:recipeID" component={RecipeDetail} /> */}
        <RouteWithLayout layout = { InnerLayout } path="/recipe-detail" component={RecipeDetail} />
        <RouteWithLayout layout = { InnerLayout } path="/mama-live" component={MamaLive} />
        <RouteWithLayout layout = { InnerLayout } path="/recipe-info/:token" component={RecipeInfo} />
        <RouteWithLayout layout = { InnerLayout } path="/terms" component={Terms} />
        <RouteWithLayout layout = { InnerLayout } path="/privacy" component={Privacy} />
        <RouteWithLayout layout = { InnerLayout } path="/offers" component={SpecialOffer} />
        <RouteWithLayout layout = { InnerLayout } path="/feedback" component={Feedback} />
        <RouteWithLayout layout = { InnerLayout } path="/support" component={Support} />


        {/* ****************************************** Test"S ROUTES ******************************************** */}
        <RouteWithLayout layout = { InnerLayout } path="/test"  component={DraggableCell} />
        {/* ****************************************** ADMIN"S ROUTES ******************************************** */}
        <RouteWithLayout layout = { EmptyLayout } path="/admin-login" exact strict component={AdminLogin} />
        <RouteWithLayout layout = { AdminLayout } path="/admin-dashboard"  component={Dashboard} />
        <RouteWithLayout layout = { AdminLayout } path="/admin-restaurant"  component={RestaurantListing} />
        <RouteWithLayout layout = { AdminLayout } path="/admin-users"  component={MyUser} />
        <RouteWithLayout layout = { AdminLayout } path="/admin-order"  component={AdminOrder} />
        <RouteWithLayout layout = { AdminLayout } path="/admin-agro-center"  component={AdminAgroCenter} />
        <RouteWithLayout layout = { AdminLayout } path="/admin-food-center"  component={AdminFoodCenter} />
          <RouteWithLayout layout = { AdminLayout } path="/admin-product"  component={AdminProduct} />


        <RouteWithLayout layout = { InnerLayout } path="/payment-design"  component={PaymentDesign} />


          {/* ****************************EXCEPTION PAGE ROUTES******************************** */}
          <RouteWithLayout layout = { ExceptionLayout } component={ExceptionPage} />



        </Switch>
    )
}

export default AppRouter;
