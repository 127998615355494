import { BaseUrl } from '../helpers/common';
import * as actionTypes from '../types/types';
import { handleResponse, queryResponse } from '../helpers/userServices';
import swal from 'sweetalert';
import { ShowLoader} from './staticActions'
import axios from 'axios'

export const foodlocationInformationRequest = (agrocenterId) =>{
  return{
    type : actionTypes.FOOD_LOCATION_INFORMATION_REQUEST,
    payload : agrocenterId
  }
}


export const foodlocationInformationSuccess = (location_success) =>{
  return{
    type : actionTypes.FOOD_LOCATION_INFORMATION_SUCCESS,
    payload : location_success
  }
}

export const foodlocationInformationFaliure = (error) =>{
  return{
    type : actionTypes.FOOD_LOCATION_INFORMATION_FALIURE,
    payload : error
  }
}
export const foodlocationInformation = (agrocenterId) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(foodlocationInformationRequest(agrocenterId))
      const uniqueID = agrocenterId ? agrocenterId : localStorage.getItem("data") ? `${JSON.parse(localStorage.getItem("data")).uniqueID}` : "";
      const request = new Request(`${BaseUrl}/api/foodcenter/location_information`, {
          method: 'POST',
          body: JSON.stringify({ uniqueID: uniqueID }),
          headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
      });
      return fetch(request).then(agrocenterId ? queryResponse : handleResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        const location_success = data
        dispatch(foodlocationInformationSuccess(location_success))
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(foodlocationInformationFaliure(errorMsg))
    })
    }
}


export const foodlocationInformationUpdate = (location_update_data) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      const request = new Request(`${BaseUrl}/api/foodcenter/update_location_information`, {
          method: 'POST',
          body: JSON.stringify(location_update_data),
        headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
      });
      return fetch(request).then(handleResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        swal({ title: "Success!", text: "Location Information Updated successfully!", icon: "success" });
      }else {
          swal({ title: "Error!", text: data.ResponseText, icon: "error" });
          return 0;
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
    })
    }
}

export const foodcontactInformationRequest = (foodcenterId) =>{
  return{
    type : actionTypes.FOOD_CONTACT_INFORMATION_REQUEST,
    payload : foodcenterId
  }
}

export const foodcontactInformationSuccess = (contact_success) =>{
  return{
    type : actionTypes.FOOD_CONTACT_INFORMATION_SUCCESS,
    payload : contact_success
  }
}

export const foodcontactInformationFaliure = (error) =>{
  return{
    type : actionTypes.FOOD_CONTACT_INFORMATION_FALIURE,
    payload : error
  }
}

export const foodcontactInformation = (foodcenterId) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(foodcontactInformationRequest(foodcenterId))
      const uniqueID = foodcenterId ? foodcenterId : localStorage.getItem("data") ? `${JSON.parse(localStorage.getItem("data")).uniqueID}` : "";
      const request = new Request(`${BaseUrl}/api/foodcenter/contact_information`, {
          method: 'POST',
          body: JSON.stringify({ uniqueID: uniqueID }),
          headers: new Headers({ 'Content-Type': 'application/json','X-Auth-Token': `${localStorage.getItem('token')}` })
      });
      return fetch(request).then(foodcenterId ? queryResponse : handleResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        const contact_success = data
        dispatch(foodcontactInformationSuccess(contact_success))
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(foodcontactInformationFaliure(errorMsg))
    })
    }
}




export const foodContactInformationUpdate = (contact_update_data) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      let formData = new FormData();
      formData.append("restaurant_name", contact_update_data.restaurant_name);
      formData.append("restaurant_phone", contact_update_data.restaurant_phone);
      formData.append("manager_name", contact_update_data.manager_name);
      formData.append("manager_phone", contact_update_data.manager_phone);
      formData.append("contact_email", contact_update_data.contact_email);
      formData.append("website", contact_update_data.website);
      formData.append("facebook", contact_update_data.facebook);
      formData.append("twitter", contact_update_data.twitter);
      formData.append("instagram", contact_update_data.instagram);

        let imageFile = window.$('#file_icon')[0].files[0];
        let coverimageFile = window.$('#cover_image')[0].files[0];
        formData.append(`profile_image`, imageFile);
        formData.append(`cover_image`, coverimageFile);
      const request = {
          method: 'POST',
          url: `${BaseUrl}/api/foodcenter/update_contact_information`,
          headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
          data: formData
      };
      return axios(request)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.data.ResponseCode === "1"){
        swal({ title: "Success!", text: "Contact Information Updated successfully!", icon: "success" });
      }else {
          swal({ title: "Error!", text: data.ResponseText, icon: "error" });
          return 0;
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
    })
    }
}

export const foodotherInformationRequest = (foodcenterId) =>{
  return{
    type : actionTypes.FOOD_OTHER_INFORMATION_REQUEST,
    payload : foodcenterId
  }
}

export const foodotherInformationSuccess = (other_success) =>{
  return{
    type : actionTypes.FOOD_OTHER_INFORMATION_SUCCESS,
    payload : other_success
  }
}

export const foodotherInformationFaliure = (error) =>{
  return{
    type : actionTypes.FOOD_OTHER_INFORMATION_FALIURE,
    payload : error
  }
}

export const foodotherInformation = (foodcenterId) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(foodotherInformationRequest(foodcenterId))
      const foodcenterID = foodcenterId ? foodcenterId : localStorage.getItem("data") ? `${JSON.parse(localStorage.getItem("data")).foodcenterID}` : "";
      const request = new Request(`${BaseUrl}/api/foodcenter/get_other_information`, {
          method: 'POST',
          body: JSON.stringify({ foodcenterID: foodcenterID }),
          headers: new Headers({ 'Content-Type': 'application/json' })
      });
      return fetch(request).then(foodcenterId ? queryResponse : handleResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        const other_success = data
        dispatch(foodotherInformationSuccess(other_success))
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(foodotherInformationFaliure(errorMsg))
    })
    }
}

export const foodotherInformationUpdate = (other_update_data) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      const request = new Request(`${BaseUrl}/api/foodcenter/other_information`, {
          method: 'POST',
          body: JSON.stringify(other_update_data),
        headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
      });
      return fetch(request).then(handleResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        swal({ title: "Success!", text: "Other Information Updated successfully!", icon: "success" });
        return 1;
      }else {
          swal({ title: "Error!", text: data.ResponseText, icon: "error" });
          return 0;
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
    })
    }
}

export const foodListingRequest = () =>{
  return{
    type : actionTypes.FOOD_LISTING_REQUEST
  }
}

export const foodListingSuccess = (agro_success) =>{
  return{
    type : actionTypes.FOOD_LISTING_SUCCESS,
    payload : agro_success
  }
}

export const foodListingFaliure = (error) =>{
  return{
    type : actionTypes.FOOD_LISTING_FALIURE,
    payload : error
  }
}


export const foodListing = () =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(foodListingRequest())
      const request = new Request(`${BaseUrl}/api/foodcenter/get_foodcenter_list`, {
          method: 'POST',
          headers: new Headers({ 'Content-Type': 'application/json' })
      });
      return fetch(request).then(queryResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        const agro_success = data
        dispatch(foodListingSuccess(agro_success))
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(foodListingFaliure(errorMsg))
    })
    }
}

export const ForceFoodloginClick = (data) => {
    return dispatch => {
        dispatch({ type: actionTypes.FORCE_FOOD_LOGIN_CLICK, payload: data })
    }
}

export function bookFoodCenterTable(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/foodcenter/bookTable`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                swal({ title: "Success!", text: "Table Booked successfully!", icon: "success" });
                return 1;
            } else {
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export const bookFoodCenterTableListingRequest = () =>{
  return{
    type : actionTypes.BOOK_FOOD_CENTER_TABLE_LISTING_REQUEST
  }
}

export const bookFoodCenterTableListingSuccess = (food_success) =>{
  return{
    type : actionTypes.BOOK_FOOD_CENTER_TABLE_LISTING_SUCCESS,
    payload : food_success
  }
}

export const bookFoodCenterTableListingFaliure = (error) =>{
  return{
    type : actionTypes.BOOK_FOOD_CENTER_TABLE_LISTING_FALIURE,
    payload : error
  }
}

export const bookFoodCenterTableListing = () =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(bookFoodCenterTableListingRequest())
      const request = new Request(`${BaseUrl}/api/foodcenter/get-bookTable`, {
          method: 'POST',
          headers: new Headers({ 'Content-Type': 'application/json','X-Auth-Token': `${localStorage.getItem('token')}` })
      });
      return fetch(request).then(queryResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        const food_success = data
        dispatch(bookFoodCenterTableListingSuccess(food_success))
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(bookFoodCenterTableListingFaliure(errorMsg))
    })
    }
}

export const foodProfileRequest = (foodUniqueId) =>{
  return{
    type : actionTypes.FOOD_PROFILE_REQUEST,
    payload : foodUniqueId
  }
}

export const foodProfileSuccess = (food_success) =>{
  return{
    type : actionTypes.FOOD_PROFILE_SUCCESS,
    payload : food_success
  }
}

export const foodProfileFaliure = (error) =>{
  return{
    type : actionTypes.FOOD_PROFILE_FALIURE,
    payload : error
  }
}

export const foodProfile = (foodUniqueId) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(foodProfileRequest(foodUniqueId))
      const uniqueID = foodUniqueId ? foodUniqueId : localStorage.getItem("data") ? `${JSON.parse(localStorage.getItem("data")).uniqueID}` : "";
      const request = new Request(`${BaseUrl}/api/foodcenter/get-foodcenter_profile`, {
          method: 'POST',
          body: JSON.stringify({ uniqueID: uniqueID }),
          headers: new Headers({ 'Content-Type': 'application/json' })
      });
      return fetch(request).then(foodUniqueId ? queryResponse : handleResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        const food_success = data
        dispatch(foodProfileSuccess(food_success))
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(foodProfileFaliure(errorMsg))
    })
    }
}

export function foodReviewListing(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/foodcenter/review-list`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.FOOD_REVIEW_LISTING, payload: data.data })
                return 1;
            } else {
                dispatch({ type: actionTypes.FOOD_REVIEW_LISTING, payload: [] })
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });
    }
}

export function addFoodCenterReview(data) {
    let foodcenterID = data["foodcenterID"];
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/foodcenter/add-review`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                swal({ title: "Success!", text: "Review Added successfully!", icon: "success" });
                dispatch(foodReviewListing({ "foodcenterID": foodcenterID }))
                return 1;
            } else {
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function foodcenterlikeAndDislikeListing(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/foodcenter/review_like_dislike_list`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.FOOD_LIKE_AND_DISLIKE_LISTING, payload: data.data })
                return 1;
            } else {
                dispatch({ type: actionTypes.FOOD_LIKE_AND_DISLIKE_LISTING, payload: [] })
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export const foodcenterlikeAndDislikeRequest = (data) =>{
  return{
    type : actionTypes.FOOD_CENTER_LIKE_AND_DISLIKE_REQUEST,
    payload : data
  }
}


export const foodcenterlikeAndDislikeSuccess = (foodcenter_like_and_dislike_success) =>{
  return{
    type : actionTypes.FOOD_CENTER_LIKE_AND_DISLIKE_SUCCESS,
    payload : foodcenter_like_and_dislike_success
  }
}

export const foodcenterlikeAndDislikeFaliure = (error) =>{
  return{
    type : actionTypes.FOOD_CENTER_LIKE_AND_DISLIKE_FALIURE,
    payload : error
  }
}

export const foodcenterlikeAndDislike = (data) =>{
    const foodcenterID = data.foodcenterID
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(foodcenterlikeAndDislikeRequest(data))
      const request = new Request(`${BaseUrl}/api/foodcenter/review_like_dislike`, {
          method: 'POST',
          body: JSON.stringify({ reviewID: data.reviewID,foodcenterID:data.foodcenterID,status:data.status }),
          headers: new Headers({ 'Content-Type': 'application/json','X-Auth-Token': `${localStorage.getItem('token')}` })
      });
      return fetch(request).then(queryResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        const foodcenter_like_and_dislike_success = data
        dispatch(foodcenterlikeAndDislikeSuccess(foodcenter_like_and_dislike_success))
        dispatch(foodcenterlikeAndDislikeListing({ "foodcenterID": foodcenterID }))
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(foodcenterlikeAndDislikeFaliure(errorMsg))
    })
    }
}

export function particularFoodBookingsList() {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/foodcenter/get_bookedTable_list`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json','X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.PARTICULAR_FOOD_BOOKINGS_LIST, payload: data.data })
                return 1;
            } else {
              dispatch({ type: actionTypes.PARTICULAR_FOOD_BOOKINGS_LIST, payload: [] })
                return 0;
            }
        }).catch(error=>{
            dispatch(ShowLoader(false));
        });;
    }
}

export const updateBookingStatus = (data) => {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/foodcenter/update_bookedTable_status`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
              let textData=""
                if(data.status === "A"){
                  textData= "Accepted"
                }
                else if(data.status === "R"){
                  textData= "Rejected"
                }
                swal({ title: "Success!", text: `Booking ${textData} successfully`, icon: "success" });
                dispatch(particularFoodBookingsList());
                return 1;
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
            return 0;
        });
    }
}
export const fooduploadInformationRequest = (fooduniqueID) =>{
  return{
    type : actionTypes.FOOD_UPLOAD_INFORMATION_REQUEST,
    payload : fooduniqueID
  }
}

export const fooduploadInformationSuccess = (upload_success) =>{
  return{
    type : actionTypes.FOOD_UPLOAD_INFORMATION_SUCCESS,
    payload : upload_success
  }
}

export const fooduploadInformationFaliure = (error) =>{
  return{
    type : actionTypes.FOOD_UPLOAD_INFORMATION_FALIURE,
    payload : error
  }
}

export const fooduploadInformation = (fooduniqueID) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(fooduploadInformationRequest(fooduniqueID))
      const uniqueID = fooduniqueID ? fooduniqueID : localStorage.getItem("data") ? `${JSON.parse(localStorage.getItem("data")).uniqueID}` : "";
      const request = new Request(`${BaseUrl}/api/foodcenter/image_list`, {
          method: 'POST',
          body: JSON.stringify({ uniqueID: uniqueID }),
          headers: new Headers({ 'Content-Type': 'application/json' })
      });
      return fetch(request).then(fooduniqueID ? queryResponse : handleResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        const upload_success = data
        dispatch(fooduploadInformationSuccess(upload_success))
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(fooduploadInformationFaliure(errorMsg))
    })
    }
}

export const fooduploadInformationUpdateSuccess = (upload_update_success) =>{
  return{
    type : actionTypes.FOOD_UPLOAD_INFORMATION_UPDATE_SUCCESS,
    payload : upload_update_success
  }
}

export const fooduploadInformationUpdateFaliure = (error) =>{
  return{
    type : actionTypes.FOOD_UPLOAD_INFORMATION_UPDATE_FALIURE,
    payload : error
  }
}

export const fooduploadInformationUpdate = (upload_update_data,this_ref) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      let formData = new FormData();
      //let imageFile = window.$('#file_icon')[0].files;
      //let videoFile = window.$('#file_icon1')[0].files;
        let imageFile = upload_update_data.imageFile;
       // let videoFile = upload_update_data.video;
      if (imageFile.length > 0) {
          let selectedImagesLength = imageFile.length;
          for(let k = 0; k < selectedImagesLength; k++) {
              formData.append(`image[${k}]`, imageFile[k]);
          }
      }
      // if (videoFile.length > 0) {
      //     let selectedVideosLength = videoFile.length;
      //     for(let k = 0; k < selectedVideosLength; k++) {
      //         formData.append(`video[${k}]`, videoFile[k]);
      //
      //     }
      // }
      const request = {
          method: 'POST',
          url: `${BaseUrl}/api/foodcenter/upload_image`,
          headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
          data: formData
      };
      // const request = new Request(`${BaseUrl}/api/agrocenter_upload_image`, {
      //     method: 'POST',
      //     data: formData,
      //   headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
      // });
      return axios(request)
    .then(data =>{

      dispatch(ShowLoader(false));
      if(data.data.ResponseCode === "1"){
        swal({ title: "Success!", text: " Upload Information Updated successfully!", icon: "success" });
        dispatch(fooduploadInformation())
      }else {
          swal({ title: "Error!", text: data.data.ResponseText, icon: "error" });
          return 0;
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(fooduploadInformationUpdateFaliure(errorMsg))
    })
    }
}
