import React, { Component } from 'react'; 
import Loaders from '../modules/Html/Loaders'

class ExceptionLayout extends Component {
    //Property applied to check only for admin still
    constructor(props){
        super(props);
        this.UserType = localStorage.getItem('admin-type')
    }
    componentDidMount(){
        if(this.UserType && this.UserType==="A"){
            this.CheckIdentity();
        }        
    }

    CheckIdentity = () => {
        let admintoken = localStorage.getItem('admin-token');
        let path = this.props.match.path;
        if(admintoken && path==='/admin-login'){
            this.props.history.push('/admin-dashboard')
        }
    }
    render() {
        return (
            <div id="page_container" className="toggled">
                <div className="main_container"> 
                    <div className="page_content">
                        {this.props.children}
                    </div> 
                </div>
                <Loaders/>
            </div>
        );
    }
}

export default ExceptionLayout;