import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Modals from './../../modules/Html/Modals';
import ModalHeader from './../../modules/Html/ModalHeader';
// import ModalFooter from './../../modules/Html/ModalFooter';
import ModalBody from './../../modules/Html/ModalBody';
import CategoryListing from './SubCategory/CategoryListing'
import MenuListing from './SubCategory/MenuListing'
import {RestaurantAddCategory,RestaurantCategoryListing,RestaurantMenuListing,RestaurantAddMenu,DeleteMenu,DeleteCategory,UpdateCategory} from './../../store/actions/restaurantActions'
import {bucketPath} from './../../store/helpers/common'
import SimpleReactvalidator from 'simple-react-validator'
import swal from 'sweetalert';
const modalStates = {open:false,name:'',description:'',modal_name:'',price:'',category:'',image:'',editID:"",viewID:false};
const initialState = {open:false,name:'',description:'',tab:'category',modal_name:'',price:'',category:'',image:'',editID:"",viewID:false};
class RestaurantMenuBuilder extends Component {
    constructor(props) {
        super(props);
		this.state = initialState;
		this.validator = new SimpleReactvalidator();
	}

	componentDidMount(){
		let uniqueID = JSON.parse(localStorage.getItem('data')).uniqueID;
		this.props.dispatch(RestaurantCategoryListing(uniqueID));
		this.props.dispatch(RestaurantMenuListing(uniqueID));
	}

	CloseModal = e => {
		this.setState(modalStates)
	}

	onChangeHandler = e => {
		this.setState({[e.target.name]:e.target.value})
	}

	AddCategory = async (e) => {
		const {name,description,editID} = this.state;
		let response;
		if(this.validator.allValid()){
			if(editID!==""){
				response = await this.props.dispatch(UpdateCategory({name,description,uniquekey:editID}));
			}else{
				response = await this.props.dispatch(RestaurantAddCategory({name,description}))
			}
			if(response===1){
				this.CloseModal();
			}
		}else{
			this.validator.showMessages();
			this.forceUpdate();
		}
	}

	AddMenu = async (e) => {
		const {name,description,category,price,editID} = this.state;
		let response;
		if(this.validator.allValid()){
			if(editID!==""){
				response = await this.props.dispatch(RestaurantAddMenu({name,description,category,price,uniquekey:editID}));
			}else{
				response = await this.props.dispatch(RestaurantAddMenu({name,description,category,price}));
			}
			if(response===1){this.setState(modalStates)}
		}else{
			this.validator.showMessages();
			this.forceUpdate()
		}
	}

	openFileInput = e => {
		this.fileinput.click()
	}

	ViewModal = (viewID,modal_name) => {
		const {category_listing,menu_listing} = this.props.restaurant_data
		if(modal_name==="add_menu"){
			let menulist = menu_listing.filter(value=>value.uniquekey===viewID)
			menulist = menulist instanceof Array && menulist.length>0 ?menulist[0]:{name:'',description:'',price:'',category:'',image:''}
			this.setState({open:true,viewID:true,name:menulist.name,description:menulist.description,price:menulist.price,category:menulist.category_name,modal_name:'menu',image:menulist.image})
		}else if(modal_name==="add_category"){
			let categorylist = category_listing.filter(value=>value.uniquekey===viewID)
			categorylist = categorylist instanceof Array && categorylist.length>0 ?categorylist[0]:{name:'',description:''}
			this.setState({open:true,viewID:true,name:categorylist.name,description:categorylist.description,modal_name:'category'})
		}
	}

	EditModal = (editID,modal_name) => {
		const {category_listing,menu_listing} = this.props.restaurant_data
		if(modal_name==="add_menu"){
			let menulist = menu_listing.filter(value=>value.uniquekey===editID)
			menulist = menulist instanceof Array && menulist.length>0 ?menulist[0]:{name:'',description:'',price:'',category:'',image:''}
			this.setState({open:true,editID:editID,name:menulist.name,description:menulist.description,price:menulist.price,category:menulist.categoryID,modal_name:'add_menu',image:menulist.image})
		}else if(modal_name==="add_category"){
			let categorylist = category_listing.filter(value=>value.uniquekey===editID)
			categorylist = categorylist instanceof Array && categorylist.length>0 ?categorylist[0]:{name:'',description:''}
			this.setState({open:true,editID:editID,name:categorylist.name,description:categorylist.description,modal_name:'add_category'})
		}
	}

	DeleteRow = (deleteID,type) => {
		swal({
			title: "Are you sure want to delete this record?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  })
		  .then((willDelete) => {
			if (willDelete) {
				if(type==="menu"){
					this.props.dispatch(DeleteMenu({uniquekey:deleteID}));
				}else if(type==="category"){
					this.props.dispatch(DeleteCategory({uniquekey:deleteID}));
				}
			} else {
			  swal({title: "Record is safe!",icon: "success",});
			}
		  });
	}



  render() {
	  const {open,name,description,modal_name,tab,category,image,price,viewID,editID} = this.state;
	  const {category_listing,menu_listing} = this.props.restaurant_data
	  this.validator.purgeFields();
    return (
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <div className="user-dashboard loader-holder">
                <div className="user-holder">
                    <form action="#">
                        <ul className="restaurant-menu-nav nav nav-tabs">
                            <li className={tab==="category"?"active":""}><Link to="#" onClick={e=>this.setState({tab:'category'})}>Menu Categories</Link></li>
                            <li className={tab==="menu"?"active":""}><Link to="#" onClick={e=>this.setState({tab:'menu'})}>Food Items</Link ></li>
                        </ul>
                        <div className="tab-content">
                            <div id="menu-cats-items" className="tab-pane fade active in">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="element-title">
                                            <h5>{tab==="category"?"Menu Categories":"Food Items"}</h5>
                                            <Link to="#" className="add-menu-item collapsed" onClick={e=>this.setState({open:true,modal_name:tab==="category"?'add_category':'add_menu'})}>{tab==="category"?"Add Category":"Add Menu Item"}</Link>
                                        </div>
                                        {tab==="category" && <CategoryListing category_listing={category_listing} ViewModal={this.ViewModal} EditModal={this.EditModal} DeleteRow={this.DeleteRow} />}
                                        {tab==="menu" && <MenuListing menu_listing={menu_listing} ViewModal={this.ViewModal} EditModal={this.EditModal} DeleteRow={this.DeleteRow} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

		<Modals open={open} size={modal_name==="add_category"?"sm":"lg"} >
			<ModalHeader CloseModal={this.CloseModal} title={modal_name==="add_category"?`${editID!==""?"Edit":"Add"} Menu Category`:modal_name==="add_menu"?`${editID!==""?"Edit":"Add"} Food Menu`:modal_name==="category"?"View Menu Category":"View Food category"}/>
				<ModalBody>
					{ modal_name==="add_category" && <>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Menu Name *</label>
								<input className="menu-item-title" id="name" name="name" value={name} onChange={e=>this.onChangeHandler(e)} type="text"
									placeholder="Menu Category Title" />
									{this.validator.message('name',name,'required|min:5,string')}
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Description</label>
								<textarea className="menu-item-desc" id="description" name="description" value={description} onChange={e=>this.onChangeHandler(e)}
									placeholder="Category Description"></textarea>
									{this.validator.message('description',description,'required')}
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder">
								<Link className="add-menu-item add-menu-item-list" to="#" onClick={e=>this.AddCategory(e)}>Save</Link >
							</div>
						</div>
						</>
					}
					{ modal_name==="add_menu" &&
						<>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div className="field-holder">
									<label>Choose Category *</label>
									<div className="restaurants-menu">
										<select className="chosen-select" name="category" value={category} onChange={e=>this.onChangeHandler(e)} id="restaurant_menu_295979393">
											<option value="">Select</option>
											{
												category_listing instanceof Array && category_listing.map((value,index)=>{
													return (
														<option key={`category_${index}`} value={value.categoryID}>{value.name}</option>
													)
												})
											}
										</select>
										{this.validator.message('category',category,'required')}
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div className="field-holder">
									<label>Menu Item *</label>
									<input className="menu-item-title" type="text" placeholder="Menu Item Title" name="name" value={name} onChange={e=>this.onChangeHandler(e)}/>
									{this.validator.message('name',name,'required|min:5,string')}
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div className="field-holder">
									<label>Price * (£)</label>
									<input className="menu-item-price" type="number" placeholder="Menu Item Price" name="price" value={price} onChange={e=>this.onChangeHandler(e)}/>
									{this.validator.message('price',price,'required|min:1,num')}
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div className="field-holder">
									<label>Food Image</label>
									<div id="file_icon-sec" className="browse-btn-sec" style={{display: 'block !important'}}>
										<input type="file" id="file_icon"  ref={(input) => { this.fileinput = input; }} name="image"  onChange={e=>this.onChangeHandler(e)} className="browse-menu-icon-file" style={{display: 'none'}} />
										<Link  to="#" className="browse-menu-icon-img btn bgcolor" onClick={this.openFileInput.bind(this)}>Browse</Link >
                    {image !=="" ? (<i className="fa fa-check food-image-tick" aria-hidden="true"></i>):null}
										{editID===""?this.validator.message('image',image,'required'):null}

									</div>
									<div id="browse-img-sec-295979393" className="browse-image-sec"
										style={{display: 'none !important'}}>
										<div className="icon-img-holder">
											<img id="img-val-base-295979393" src="#" alt="" />
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div className="field-holder">
									<label>Description</label>
									<textarea className="menu-item-desc foodbakery_editor" name="description" value={description} onChange={e=>this.onChangeHandler(e)} placeholder="Menu Item Description test"></textarea>
									{this.validator.message('description',description,'required')}
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div className="field-holder">
									<Link className="add-menu-item add-menu-item-list" to="#" onClick={e=>this.AddMenu(e)}>Save</Link >
								</div>
							</div>
						</>
					}
					{ viewID && modal_name==="category" &&
						<>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div className="field-holder">
									<label>Menu Name *</label>
									<p>{name}</p>
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div className="field-holder">
									<label>Description</label>
									<p>{description}</p>
								</div>
							</div>
						</>
					}
					{ viewID && modal_name==="menu" &&
						<>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div className="field-holder">
									<label>Choose Category *</label>
									<p>{category}</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div className="field-holder">
									<label>Menu Item *</label>
									<p>{name}</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div className="field-holder">
									<label>Price * (£)</label>
									<p>{price}</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div className="field-holder">
									<label>Food Image</label>
									<div id="file_icon-sec" className="browse-btn-sec" style={{display: 'block !important'}}>
										<img className='thumbnail' src={image?`${bucketPath}${image}`:`/assets/extra-images/logo1.png`} alt={name}/>
                    {image !=="" ? (<i class="fa fa-check food-image-tick" aria-hidden="true"></i>):null}
									</div>
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div className="field-holder">
									<label>Description</label>
									<p>{description}</p>
								</div>
							</div>
						</>
					}
				</ModalBody>
		</Modals>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
	restaurant_data:state.restaurant
});

export default connect(mapStateToProps)(RestaurantMenuBuilder);
