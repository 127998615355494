import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Pagination from "./../store/helpers/Pagination";
import { RecepieVideoListing } from './../store/actions/restaurantActions'
import ReactJWPlayer from "react-jw-player";
import Banner from "./Banner";

class MamaLive extends Component {
    constructor(props) {
        super(props);
        this.state = { pageOfItems: [], currentPage: 1 };
    }
    componentDidMount() {
        let  data = {
            "status": "P"
        }
        this.props.dispatch(RecepieVideoListing(data));
    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    render() {
        const { video_listing } = this.props.restaurant_data;
        const { pageOfItems } = this.state;
        return (
            <div className="main-section">
              <Banner name="Live Cooking" description="Check your favourite Live Cooking"/>
                <div className="page-section recepies-section pdTop-10" >
                    <div className="container">
                        <div className="row">
                        {
                          // <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          //     <div className="cs-section-title" style={{ textAlign: 'center !important' }}>
                          //         <h2>Check your favourite Mama's Live</h2>
                          //     </div>
                          // </div>
                        }

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="row">
                                    <div className="listing grid-listing three-cols">
                                        {pageOfItems instanceof Array && pageOfItems.map((value, index) => {
                                            return (
                                                <div className="col-md-4 col-xs-6 grid-listing-col " key={`video-${index}`}>
                                                    <div className="img-holder">
                                                        <figure>
                                                            <Link to="#">
                                                                {
                                                                    value.streaming && value.streaming === "YES" && <ReactJWPlayer
                                                                            className="live-video-section"
                                                                            playerId="player"
                                                                            playerScript="https://cdn.jwplayer.com/libraries/lqsWlr4Z.js"
                                                                            file={`https://rtc-mamachai.cap-tek.com:8443/app/mama-${value.uniqueID}.m3u8`}
                                                                            image="https://beta-stream.meetn.com/player/livestream_og_image.jpg"
                                                                        />
                                                                }
                                                                {!value.streaming &&
                                                                    <video controls>
                                                                        <source
                                                                            src={`${value.file}`}
                                                                        />
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                }

                                                            </Link>
                                                        </figure>
                                                        {
                                                          //<span className="restaurant-status close"><em className="bookmarkRibbon"></em>{value.streaming === "YES" ? `LIVE` : `CLOSE`}</span>
                                                        }

                                                    </div>
                                                    <div className="text-holder">
                                                        <div className="listing-inner">
                                                            <h4><Link to="#">{ value.streaming === "YES" ? value.restaurant_name : value.title}</Link></h4>
                                                            <p>{value.description}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        }
                                        )}
                                        {/* <div className="col-md-4 col-xs-6 grid-listing-col ">
                                            <div className="img-holder">
                                                <figure>
                                                    <Link to="#"><img src="assets/extra-images/cover-photo20-359x212.jpg" alt="" /> </Link>
                                                    <figcaption className="listing-meta">
                                                        <div className="listing-inner clearfix">
                                                            <div className="list-option">
                                                                <span className="label bgcolor time-label" style={{ display: 'none' }}></span>
                                                                <Link to="#" className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i> </Link>
                                                                <Link to="#" className="shortlist-btn pull-right"><i className="icon-video-camera"></i>
                                                                </Link>
                                                            </div>
                                                            <div className="list-rating">
                                                                <div className="rating-star">
                                                                    <span className="rating-box" style={{ width: '100' }}></span>
                                                                </div>
                                                                <span className="reviews">(1)</span>
                                                            </div>
                                                        </div>
                                                    </figcaption>
                                                </figure>
                                                <span className="restaurant-status close"><em className="bookmarkRibbon"></em>Close</span>
                                            </div>
                                            <div className="text-holder">
                                                <div className="listing-inner">
                                                    <h4><Link to="#">Nature Healthy Food</Link></h4>
                                                    <p>Apple Juice, Beef Roast, Cheese Burger</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xs-6 grid-listing-col ">
                                            <div className="img-holder">
                                                <figure>
                                                    <Link to="#"><img src="assets/extra-images/cover-photo19-359x212.jpg" alt="" /></Link>
                                                    <figcaption className="listing-meta">
                                                        <div className="listing-inner clearfix">
                                                            <div className="list-option">
                                                                <span className="label bgcolor time-label distance-1705" style={{ display: 'none' }}></span>
                                                                <Link to="#" className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i></Link>
                                                            </div>
                                                        </div>
                                                    </figcaption>
                                                </figure>
                                                <span className="restaurant-status open"><em className="bookmarkRibbon"></em>Open</span>
                                            </div>
                                            <div className="text-holder">
                                                <div className="listing-inner">
                                                    <h4><Link to="#">Menu &amp; Drinks</Link></h4>
                                                    <p>Chicken Roast, Chines Soup, Cold Coffee</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xs-6 grid-listing-col">
                                            <div className="img-holder">
                                                <figure>
                                                    <Link to="#"> <img src="assets/extra-images/cover-photo18-359x212.jpg" alt="" /></Link>
                                                    <figcaption className="listing-meta">
                                                        <div className="listing-inner clearfix">
                                                            <div className="list-option">
                                                                <span className="label bgcolor time-label" style={{ display: 'none' }}></span>
                                                                <Link to="#" className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i></Link>
                                                                <Link to="#" className="shortlist-btn pull-right"><i
                                                                    className="icon-video-camera"></i>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </figcaption>
                                                </figure>
                                                <span className="restaurant-status close"><em className="bookmarkRibbon"></em>Close</span>
                                            </div>
                                            <div className="text-holder">
                                                <div className="listing-inner">
                                                    <h4><Link to="#">Chefs</Link></h4>
                                                    <p>Egg Fry, Noodles, Pastry</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xs-6 grid-listing-col featured">
                                            <div className="img-holder">
                                                <figure>
                                                    <Link to="#"><img src="assets/extra-images/cover-photo17-359x212.jpg" alt="" /></Link>
                                                    <figcaption className="listing-meta">
                                                        <div className="listing-inner clearfix">
                                                            <div className="list-option">
                                                                <Link to="#" className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i></Link>
                                                            </div>
                                                        </div>
                                                    </figcaption>
                                                </figure>
                                                <span className="restaurant-status open"><em className="bookmarkRibbon"></em>Open</span>
                                            </div>
                                            <div className="text-holder">
                                                <div className="listing-inner">
                                                    <h4><Link to="#">Menu’s</Link></h4>
                                                    <p>Fish Fry, Fresh Juice, Stakes</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xs-6 grid-listing-col ">
                                            <div className="img-holder">
                                                <figure>
                                                    <Link to="#"><img src="assets/extra-images/cover-photo16-359x212.jpg" alt="" /></Link>
                                                    <figcaption className="listing-meta">
                                                        <div className="listing-inner clearfix">
                                                            <div className="list-option">
                                                                <span className="label bgcolor time-label distance-1566" style={{ display: 'none' }}></span>
                                                                <Link to="#" className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i></Link>
                                                            </div>
                                                        </div>
                                                    </figcaption>
                                                </figure>
                                                <span className="restaurant-status close"><em className="bookmarkRibbon"></em>Close</span>
                                            </div>
                                            <div className="text-holder">
                                                <div className="listing-inner">
                                                    <h4><Link to="#">Food N&amp;H</Link></h4>
                                                    <p>Beef Roast, Cheese Burger, Doughnut</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xs-6 grid-listing-col featured">
                                            <div className="img-holder">
                                                <figure>
                                                    <Link to="#"><img src="assets/extra-images/cover-photo15-359x212.jpg" alt="" /></Link>
                                                    <figcaption className="listing-meta">
                                                        <div className="listing-inner clearfix">
                                                            <div className="list-option">
                                                                <Link to="#" className="shortlist-btn" data-toggle="modal" data-target="#sign-in"><i className="icon-heart-o"></i></Link>
                                                            </div>
                                                        </div>
                                                    </figcaption>
                                                </figure>
                                                <span className="restaurant-status open"><em className="bookmarkRibbon"></em>Open</span>
                                            </div>
                                            <div className="text-holder">
                                                <div className="listing-inner">
                                                    <h4><Link to="#">GM Mama</Link></h4>
                                                    <p>Apple Juice, BB.Q</p>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                {video_listing instanceof Array && (
                                    <Pagination
                                        pageSize={10}
                                        items={video_listing}
                                        onChangePage={this.onChangePage}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    restaurant_data: state.restaurant
});

export default connect(mapStateToProps)(MamaLive);
