import React,{Component} from 'react'
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import * as RestaurantAction from './../../store/actions/restaurantActions';
import Modals from './../../modules/Html/Modals';
import ModalHeader from './../../modules/Html/ModalHeader';
import ModalBody from './../../modules/Html/ModalBody';
import SimpleReactvalidator from 'simple-react-validator'
import swal from 'sweetalert';
import {bookVisitorTour} from './../../store/actions/agroActions'
import { withRouter } from "react-router-dom";

class VisitorTour extends Component {
  constructor(props){
    super(props);
    this.state = {
      open:false,
      first_name:'',
      last_name:'',
      email:'',
      phone:'',
      booking_date:'',
      packageID:'',
      agroID:'',
      bookingPackageInfo:{}
    }
    this.validator = new SimpleReactvalidator();
  }

  openTableBookModal = (value,agroIDvalue,bookingPackageInfo) => {
    const getUserIdentity = localStorage.getItem('token');
    const getUserIdentityType = localStorage.getItem('type');
    if (!getUserIdentity || getUserIdentityType !== "U") {
      swal({
        text: "You need to login!",
        icon: "info",
        buttons: true,
        dangerMode: true,
      })
        .then((yesDo) => {
          if (yesDo) {
            this.props.dispatch(RestaurantAction.ForceloginClick(true))
          } else {
            console.log("Record is Safe");
          }
        });
      return false;
    }
    this.setState({
      open: true,
      packageID:value,
      agroID:agroIDvalue,
      bookingPackageInfo:bookingPackageInfo
    })
  }

  CloseModal = e => {
    this.setState({
      open: false,
      packageID:'',
      agroID:'',
      bookingPackageInfo:{}
    })
  }

  onChangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  bookTableHandler = async (e) => {
    const {first_name,last_name,email,phone,booking_date,packageID,agroID,bookingPackageInfo} = this.state
    const data = {
      first_name:first_name,
      last_name:last_name,
      email:email,
      phone:phone,
      booking_date:booking_date,
      packageID:packageID,
      agroID:agroID
    }
    if (this.validator.allValid()) {
      let response = await this.props.dispatch(bookVisitorTour(data));
      if (response === 1) {
        //this.CloseModal();
        let membershipItems = [
          {name:bookingPackageInfo.title,uniqueID:bookingPackageInfo.uniqueID,price:bookingPackageInfo.price}
        ]

        let subTotal = parseFloat(bookingPackageInfo.price);
        let totalToPay = subTotal
        const payment_type = 'card';

        let order_type = "agroCenter_visitorTour";

        let data = {payment_type,subTotal:subTotal,totalToPay:totalToPay,cartItems:membershipItems,order_type:order_type};

        const promise = new Promise(async (resolve,reject)=>{
          let response = await this.props.dispatch(RestaurantAction.PlaceOrder(data));
          if(response.code===1){
            this.CloseModal();
            resolve(response)
          }else{
            reject()
          }
        })

        promise.then((response)=>{
          if(response.type==='card'){
            this.props.history.push(`/stripe-payment/${response.order_number}`)
          }

        })
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate()
    }
  }
  render(){
        const {first_name,last_name,email,booking_date,phone} = this.state
    return (
      <>
<div className="menu-itam-holder">
{
  // <div className="field-holder sticky-search">
  //   <input id="menu-srch-6272" data-id={6272} className="input-field dev-menu-search-field" type="text" placeholder="Search food item" defaultValue />
  // </div>
}

  <div id="menu-item-list-6272" className="menu-itam-list agro-menu-itam-list">
  {
    // <div className="element-title" id="menu-category-5">
    //   <h5 className="text-color"> Appetizers/Starters</h5>
    // </div>
  }

    <ul className="vistor-main-ul">
    {this.props && this.props.package_list && this.props.package_list.length > 0 ?
      this.props.package_list.map((item,index) =>
      <li key={index}>
      {
        // <div className="image-holder">
        //   <a href="/menu-listing/1350f3f939466b49505d4259c4cbd421">
        //     <img src="https://storage.googleapis.com/mama-9bdd1.appspot.com/restaurants/1350f3f939466b49505d4259c4cbd421/1bcd13773c15d9091ab672e2a4bed3fe.webp" alt=" Kakori Kebabs" />
        //   </a>
        // </div>
      }
      <div className="row" key={`package${index}`}>
        <div className="col-md-8 col-xs-8">
            <h3>{item.title}</h3>
            <p>{`${item.people}`}</p>
        </div>
        < div className = "col-md-4 col-xs-4 text-center" >
            <span className="price">Rs {item.price}</span>
            <Link className="book-now btn" to="#" onClick={() => this.openTableBookModal(item.packageID,item.agroID,item)}>Book Now</Link>
            <span id="add-menu-loader-0" />
        </div>
      </div>
        <div className="row">
            <div className="col-md-12">
            <ul className="agro-vistor-include-ul">
              <h5>Includes:</h5>
              <p
            className=''
            dangerouslySetInnerHTML={{
              __html: item.description ? item.description : '<p className=""></p>',
            }}
            />
            </ul>
            </div>
        </div>
        {/* < div className = "price-holder agro-price-holder">

        </div> */}
      </li>
    )
       : null}
       {
         // <li>
         // {
         //   // <div className="image-holder">
         //   //   <a href="/menu-listing/1350f3f939466b49505d4259c4cbd421">
         //   //     <img src="https://storage.googleapis.com/mama-9bdd1.appspot.com/restaurants/1350f3f939466b49505d4259c4cbd421/1bcd13773c15d9091ab672e2a4bed3fe.webp" alt=" Kakori Kebabs" />
         //   //   </a>
         //   // </div>
         // }
         // <div className="row">
         //   <div className="col-md-8 col-xs-8">
         //       <h3>Poolside Cottage</h3>
         //       <p>Per night for upto 5 people</p>
         //   </div>
         //   <div className="col-md-4 col-xs-4 text-center">
         //       <span className="price">Rs 430.00</span>
         //       <Link className="book-now btn" to="#">Book Now</Link>
         //       <span id="add-menu-loader-0" />
         //   </div>
         // </div>
         //   <div className="row">
         //       <div className="col-md-12">
         //       <ul className="agro-vistor-include-ul">
         //         <h5>Includes:</h5>
         //         <li><i className="icon-check_circle"></i>Activities (Air Rifle Shooting, Bullock Cart Ride, Soft Archery, Outdoor Games)</li>
         //         <li><i className="icon-check_circle"></i>This Package only includes stay in the dorm type room for upto 5 people</li>
         //         <li><i className="icon-check_circle"></i>No Access to swimming pool due to Covid-19 situation.</li>
         //         <li><i className="icon-check_circle"></i><b>50% Advance Payment is mandatory for confirmation of booking.</b></li>
         //       </ul>
         //       </div>
         //   </div>
         //   {/* < div className = "price-holder agro-price-holder">
         //
         //   </div> */}
         // </li>
       }


    </ul>
    </div>
</div>

<Modals open={this.state.open} className="modal-size">
  <ModalHeader CloseModal={this.CloseModal}  title="Book Visitor Tour"/>
  <ModalBody>
    <div className="order-detail-inner book-table-area">
      <div className="reviews-holder">
        <div className="add-new-review-holder  add-new-review-2067">
          <div className="row">
            <div className="foodbakery-add-review-data">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="field-holder">
                <label>First Name *</label>
                  <input
                    type="text"
                    placeholder="First Name *"
                    value={first_name}
                    name="first_name"
                    onChange={e => this.onChangeHandler(e)}
                    className="margin-bottom-0"
                  />
                  {this.validator.message('First Name', first_name, 'required')}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="field-holder">
                <label>Last Name *</label>
                  <input
                    type="text"
                    placeholder="Last Name *"
                    value={last_name}
                    name="last_name"
                    onChange={e => this.onChangeHandler(e)}
                    className="margin-bottom-0"
                  />
                  {this.validator.message('Last Name', last_name, 'required')}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="field-holder">
                <label>Email *</label>
                  <input
                    type="email"
                    placeholder="Email *"
                    value={email}
                    name="email"
                    onChange={e => this.onChangeHandler(e)}
                    className="margin-bottom-0"
                  />
                  {this.validator.message('Email', email, 'required')}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="field-holder">
                <label>Phone *</label>
                  <input
                    type="text"
                    placeholder="Phone *"
                    value={phone}
                    name="phone"
                    onChange={e => this.onChangeHandler(e)}
                    className="margin-bottom-0"
                  />
                  {this.validator.message('Phone', phone, 'required')}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="field-holder ">
                <label>Booking Date *</label>
                  <input
                    type="date"
                    placeholder="Booking Date *"
                    value={booking_date}
                    name="booking_date"
                    onChange={e => this.onChangeHandler(e)}
                  />
                  {this.validator.message('Booking Date', booking_date, 'required')}
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pd-top-26 book-visitor tour">
                <div className="form-element">
                  <input
                    type="button"
                    defaultValue="Book Visitor Tour"
                    onClick={e => this.bookTableHandler(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ModalBody>
</Modals>
</>
    )
  }
}

export default connect()(withRouter(VisitorTour))
