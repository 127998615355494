import React, { Component } from "react";
import { Link } from "react-router-dom";
class MenuListingLHS extends Component {
  constructor(props) {
    super(props);
    this.state = { categoryID: "" };
  }
  render() {
    const { category_listing } = this.props;
    const { categoryID } = this.state;
    return (
      <div className="col-lg-2 col-md-2 col-sm-3 col-xs-12 sticky-sidebar">
        <div className="filter-toggle">
          <span className="filter-toggle-text">Categories By</span>
          <i className="icon-chevron-down"></i>
        </div>
        <div className="filter-wrapper">
          <div className="categories-menu">
            <h6>
              <i className="icon-restaurant_menu"></i>Categories
            </h6>
            <ul className="menu-list">
              {category_listing instanceof Array &&
                category_listing.map((value, index) => {
                  return (
                    <li
                      className={
                        categoryID === value.categoryID ? "active" : ""
                      }
                      key={`listing_${index}`}
                    >
                      <Link
                        to="#"
                        className="menu-category-link"
                        onClick={(e) =>
                          this.setState({ categoryID: value.categoryID },()=>{
                              this.props.categoryChosen(value.categoryID)
                              this.props.tabRef.click()
                          })
                        }
                      >
                        {" "}
                        {value.name}{" "}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuListingLHS;
