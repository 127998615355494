import React, { Component } from 'react';
import Modals from './Modals'
import ModalHeader from './ModalHeader'
import ModalBody from './ModalBody'
import ModalFooter from './ModalFooter'

const WrapperComponent = (WrappedComponent) => {
    return class ModalHOC extends Component {        
        render() {
            return (
                <Modals open={this.props.open} size={this.props.size}>
                    <ModalHeader title={this.props.title} CloseModal={this.props.CloseModal}/>
                    <ModalBody><WrappedComponent>{this.props.children}</WrappedComponent></ModalBody>
                    {this.props.footer && <ModalFooter/>}
                </Modals>
            );
        }
    }
}

export default WrapperComponent;