import React from "react";
import { Link } from 'react-router-dom';
import Loaders from './../modules/Html/Loaders'
import {fetchContactUs} from "./../store/actions/staticActions";
class Footer extends React.Component {


  render() {
    return (
        <footer id="footer" className="footer-style-2">
			<div className="footer-links">
				<div className="col-md-12 footer-inline-ul">
					<ul className="col-md-4 text-left">
						<li><Link to="/feedback">Feedback</Link></li>
						<li><Link to="/support"> Help Support</Link></li>
						<li><Link to="/terms">Terms</Link></li>
						<li><Link to="#" onClick={()=>this.props.dispatch(fetchContactUs({modal:"contact-us"}))}>Contact Us</Link></li>
					</ul>
					<div className="col-md-4 text-center">
						<div className="download-ico">
							<Link to="#"><img src="./../assets/extra-images/ios-white.png" alt="...chosen-container-active" /></Link>
							<Link to="#"><img src="./../assets/extra-images/android-white.png" alt="...chosen-container-active" /></Link>
						</div>
					</div>
					<p className="col-md-4 text-right">© 2020 MamaChai.com All Rights Reserved. </p>
				</div>
			</div>
			<Loaders/>
		</footer>
    );
  }
}
export default Footer;
