import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CKEditor from 'ckeditor4-react';
import SimpleReactvalidator from 'simple-react-validator'
import { AddFacility,ViewFacility } from './../../store/actions/agroActions'

const initialState = {
	description: '',
	editID: ''
};



class FacilitiesBuilder extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.validator = new SimpleReactvalidator();
	}

	componentDidMount() {
		let uniqueID = this.props.match.params.uniqueID;
		if (uniqueID) {
			this.props.dispatch(ViewFacility(uniqueID));
		}

	}

	componentDidUpdate(nextProps, prevState) {
		let facility_data = this.props.facility_data;
		if ((!facility_data || facility_data === nextProps.facility_data)) {
			return false;
		}
		this.setState({
			description: facility_data.description,
			editID: facility_data.facilityID,
		})
	}



	handleDescEditorChange = (content, editor) => {
		this.setState({ description: content })
	}

	addRecepie = async (e) => {
		const {description, editID} = this.state;
		let response;
		if (this.validator.allValid()) {
			if (editID !== "") {
				response = await this.props.dispatch(AddFacility({ description, facilityID: editID,}, this));
			} else {
				response = await this.props.dispatch(AddFacility({description, facilityID: editID}));
			}
			if (response === 1) {
				this.props.history.push('/facilities');
			}
		} else {
			this.validator.showMessages();
			this.forceUpdate()
		}
	}


	onChangeHandler = e => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onEditorChange = ( evt ) => {
	        this.setState( {
	            description: evt.editor.getData()
	        } );
	    }


	render() {
		const {description} = this.state;
		this.validator.purgeFields();


		return (
			<div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">

				<div className="user-dashboard loader-holder">
					<div className="user-holder">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="field-holder">
								<label>Description *</label>
								<CKEditor
                    data={this.state.description}
                    onChange={this.onEditorChange} />


								{this.validator.message('description', description, 'required')}
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
							<div className="field-holder">
								<Link className="add-menu-item add-menu-item-list center-btn btn-black" to="#" onClick={e => this.addRecepie(e)}>Save Facility
								</Link >
							</div>
						</div>
					</div>
				</div >
			</div >
		);
	}
}

const mapStateToProps = (state) => ({
	facility_data: state.agro.facility_data,
});

export default connect(mapStateToProps)(FacilitiesBuilder);
