import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from 'simple-react-validator'
import {ChangePassword} from './../../store/actions/userActions'

const initialState = {password:"",new_password:"",confirm_password:"",oldPasswordHidden:true,newPasswordHidden:true,confirmPasswordHidden:true}


class AgroChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.validator = new SimpleReactValidator({
            messages: {
              in: 'Passwords need to match!'
            }
        });
      }

    onChangeHandler = e => {
        this.setState({[e.target.name]:e.target.value});
    }

    onSubmitHandler = e => {
        const {password,new_password,confirm_password} = this.state
        if(this.validator.allValid()){
            this.props.dispatch(ChangePassword({current_password: password,new_password,confirm_password}))
        }else{
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    toggleNewPasswordShow = () =>{
    this.setState({
      newPasswordHidden: !this.state.newPasswordHidden
    })
  }
  toggleConfirmPasswordShow = () =>{
    this.setState({
      confirmPasswordHidden: !this.state.confirmPasswordHidden
    })
  }

	toggleOldPasswordShow = () =>{
    this.setState({
      oldPasswordHidden: !this.state.oldPasswordHidden
    })
  }

    render() {
        const {password,new_password,confirm_password} = this.state;
        return (
            <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <div className="user-dashboard loader-holder">
                <div className="user-holder">
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12"></div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
					<div className="user-profile">
                        <div className="element-title has-border">
                            <h5>Change Password</h5>
                        </div>
                        <div className="row">
                            <form id="publisher_profile">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="field-holder">
                                        <label> Current Password*</label>
                                        <input type={this.state.oldPasswordHidden ? "password" : "text"} name="password" value={password} onChange={this.onChangeHandler.bind(this)} />
                                        {this.state.oldPasswordHidden ? (
                                            <i className="far fa-eye agro-eye-password-icon"  onClick={() =>this.toggleOldPasswordShow()}></i>
                                          ) : (
                                            <i className="fa fa-eye-slash agro-eye-password-icon" aria-hidden="true" onClick={() =>this.toggleOldPasswordShow()}></i>
                                          )}
                                        {this.validator.message('Password', password, `required`)}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="field-holder">
                                        <label> New Password*</label>
                                        <input type={this.state.newPasswordHidden ? "password" : "text"} name="new_password" value={new_password} onChange={this.onChangeHandler.bind(this)}/>
                                        {this.state.newPasswordHidden ? (
                                            <i className="far fa-eye agro-eye-password-icon"  onClick={() =>this.toggleNewPasswordShow()}></i>
                                          ) : (
                                            <i className="fa fa-eye-slash agro-eye-password-icon" aria-hidden="true" onClick={() =>this.toggleNewPasswordShow()}></i>
                                          )}
                                        {this.validator.message('New Password', new_password, `required`)}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="field-holder">
                                        <label> Confirm New Password* </label>
                                        <input type={this.state.confirmPasswordHidden ? "password" : "text"} name="confirm_password" value={confirm_password} onChange={this.onChangeHandler.bind(this)}/>
                                        {this.state.confirmPasswordHidden ? (
                                          <i className="far fa-eye agro-eye-password-icon"  onClick={() =>this.toggleConfirmPasswordShow()}></i>
                                        ) : (
                                          <i className="fa fa-eye-slash agro-eye-password-icon" aria-hidden="true" onClick={() =>this.toggleConfirmPasswordShow()}></i>
                                        )}
                                        {this.validator.message('Confirm Password', confirm_password, `required|in:${new_password}`)}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="field-holder agro-save-button-area">
                                        <button name="button" type="button" className="btn btn-black" onClick={e => this.onSubmitHandler(e)} >Save</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12"></div>

                </div>
            </div>
        </div>
        );
    }
}

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(AgroChangePassword);
