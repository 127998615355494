import React, { Component } from 'react'
import { connect } from "react-redux"
import {Link} from 'react-router-dom'
import { ViewRestaurantRecepie } from '../../store/actions/restaurantActions';
import ReactHtmlParser from 'react-html-parser';
import { bucketPath } from '../../store/helpers/common';

class RecipeView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageOfItems: [],
			currentPage: 1,
			title: '',
			description: '',
			summary: '',
			editID: '',
			recipe_images: [],
			recipe_videos: []
		};
	}

	componentDidMount() {
		let recipeID = this.props.match.params.recipeID;
		this.props.dispatch(ViewRestaurantRecepie(recipeID));
	}

	componentDidUpdate(nextProps, prevState) {
		let recipe_data = this.props.recipe_data;
		if (!recipe_data || recipe_data === nextProps.recipe_data) {
			return false;
		} 
		this.setState({
			title: recipe_data.title,
			description: recipe_data.description,
			summary: recipe_data.summary,
			editID: recipe_data.recipeID,
			recipe_images: recipe_data.image ? recipe_data.image.split(",") : [],
			recipe_videos: recipe_data.video ? recipe_data.video.split(",") : []
		})
	}

	render() {
		return (
			<div>
				<div className="main-section receipes-details">
					<div className="container">
						<div className="row">
							<div className="page-content col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div className="page-section">
									<div className="row">
										<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
											<div className="blog-detail">
												<div className="row">
													<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
														<div className="title-area">
															<h2>{this.state.title}
															</h2>
															<span>
																{ReactHtmlParser(this.state.summary)}
															</span>
														</div>
														<div className="main-post">
															<figure>
																{/* <a href="#"><img src="assets/extra-images/blogs-food-bakery-02-750x422.jpg" alt="" /></a> */}
																<div id="carousel-example-generic" className="carousel slide" data-ride="carousel">

																	{/* <!-- Wrapper for slides --> */}
																	<div className="carousel-inner" role="listbox">
																		{/* <div className="item active">
                                                                            <a href="#"><img src="assets/extra-images/blogs-food-bakery-02-750x422.jpg" alt="" /></a>

                                                                        </div> */}
																		{
																			this.state.recipe_images.map(
																				(value, index) => {
																					return (
																						<div key={"imgitem_" + index} className={index === 0 ? "item active" : "item"}>
																							<Link to="#"><img src={`${bucketPath}${value}`} alt="..." /></Link>
																						</div>
																					)

																				}
																			)
																		}
																		{
																			this.state.recipe_videos.map(
																				(value, index) => {
																					return (
																						<div key={"imgvdoitem_" + index} className="item">
																							<Link to="#">
																								<video controls>
																									<source src={`${bucketPath}${value}`} />
																									Your browser does not support HTML video.
																								</video>
																							</Link>
																						</div>
																					)

																				}
																			)
																		}

																	</div>

																	{/* <!-- Controls --> */}
																	<a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
																		<span className="icon-arrows-1" aria-hidden="true"></span>
																		<span className="sr-only">Previous</span>
																	</a>
																	<a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
																		<span className="icon-arrows" aria-hidden="true"></span>
																		<span className="sr-only">Next</span>
																	</a>
																</div>
															</figure>
														</div>
													</div>
													<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
														<div className="rich-editor-text">
															{ReactHtmlParser(this.state.description)}
														</div>
													</div>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- Main Section End --></div> */}
			</div>
		)
	}
}
const mapStateToProps = (state) => ({
	recipe_data: state.restaurant.recipe_data
});

export default connect(mapStateToProps)(RecipeView);