import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bookFoodCenterTableListing } from "./../../store/actions/foodActions";
import Pagination from "./../../store/helpers/Pagination";
import SimpleReactValidator from "simple-react-validator";
import "react-phone-number-input/style.css";
import Moment from 'moment';
import ModalHeader from "./../../modules/Html/ModalHeader";
import ModalBody from "./../../modules/Html/ModalBody";
import Modals from "../../modules/Html/Modals";


const OrderStatus = {
  N: "Waiting for FoodCenter",
  P: "Processing",
  A: "Accepted",
  R: "Rejected",
  C: "Completed",
};

const OrderColor = {
  N: "#1e73be",
  P: "#1e73be",
  A: "#047a06",
  R: "#c33332",
  C: "#047a06",
};

class BuyerBookings extends Component {
    constructor(props) {
        super(props);
        this.formValidator = new SimpleReactValidator();
        this.state ={
          pageOfItems: [],
          currentPage: 1,
          favouritRestaurantList:[],
          open:false,
          bookingId:''
        }
      }

      componentDidMount() {
        this.props.dispatch(bookFoodCenterTableListing());
      }


        onChangePage = (pageOfItems, page) => {
          this.setState({ pageOfItems: pageOfItems, currentPage: page });
        };

        openModal = (value) =>{
          this.setState({
            open: true,
            bookingId:value
          })
        }

        CloseModal = e => {
          this.setState({
            open: false,
            bookingId:''
          })
        }
  render() {

    const { pageOfItems,bookingId } = this.state;
    const { book_food_center_table_listing } = this.props;
    const modalData = pageOfItems && pageOfItems.length > 0 ? pageOfItems.filter(item =>item.id === bookingId) : [];

    return (
      <>
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div className="user-dashboard loader-holder">
            <div className="listing simple">
            <div className="element-title">
                        <h5>Bookings</h5>
            </div>
            <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="user-orders-list responsive-table">
            <ul className="table-generic" id="portfolio">
              <li>
                <div className="orders-title">Restaurant Name</div>
                <div className="orders-date">Date</div>
                <div className="orders-type">Status</div>
                <div className="orders-price">Detail</div>
              </li>
              {pageOfItems instanceof Array && pageOfItems.map((item,index) =>(
                <li key={index}>
                  <div className="orders-title">
                    <h6 className="order-title">
                      <Link to="#">{item.foodcenter_name}</Link>
                      <span>{`( #${item.foodcenterID})`}</span>
                    </h6>
                  </div>
                  <div className="orders-date">
                    <span>{Moment(item.booking_date).format('DD-MM-YYYY')}</span>
                  </div>
                  {item.status === "N" ? (
                    <div className="orders-status">
                      <span className="booking-status" style={{backgroundColor: OrderColor.N}}>{OrderStatus.N}</span>
                    </div>
                  ):item.status === "A"? (
                    <div className="orders-status">
                      <span className="booking-status" style={{backgroundColor: OrderColor.A}}>{OrderStatus.A}</span>
                    </div>
                  ):item.status === "R"? (
                    <div className="orders-status">
                      <span className="booking-status" style={{backgroundColor: OrderColor.R}}>{OrderStatus.R}</span>
                    </div>
                  ):item.status === "C"?(
                    <div className="orders-status">
                      <span className="booking-status" style={{backgroundColor: OrderColor.C}}>{OrderStatus.C}</span>
                    </div>
                  ):null}

                  <div className="orders-price">
                    <Link  to="#" onClick={() =>this.openModal(item.id)}><i className="icon-plus2 text-color" /></Link>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="page-nation">
                  {
                    book_food_center_table_listing.data && book_food_center_table_listing.data instanceof Array && book_food_center_table_listing.data.length > 0 &&
                     (

                    <Pagination
                      pageSize={10}
                      items={book_food_center_table_listing.data}
                      onChangePage={this.onChangePage}
                    />
                  )
                }
                </div>
              </div>
            </div>
        </div>
    </div>

    <Modals open={this.state.open} size="sm">
      <ModalHeader CloseModal={this.CloseModal}  title="Booking Detail"/>
      <ModalBody>
      {modalData && modalData.length > 0 && modalData[0] && (
        <div className=" my-booking-detail order-detail-inner">
        <h3>{modalData[0].foodcenter_name}</h3>
        <ul className="my-booking-detail order-detail-options">
          <li>
            <strong>Booking ID :</strong>
            <span>#{modalData[0].booknumber}</span>
          </li>
          <li>
            <strong>Booking Date & Time :</strong>
            <span>{Moment(modalData[0].booking_date).format('DD-MM-YYYY') + " " + modalData[0].booking_time} </span>
          </li>
        </ul>
        <h3>Customer Deatil</h3>
        <ul className="my-booking-detail order-detail-options">
          <li>
            <strong>First Name:</strong>
            <span>{modalData[0].first_name}</span>
          </li>
          <li>
            <strong>Last Name:</strong>
            <span>{modalData[0].last_name}</span>
          </li>
          <li>
            <strong>Email:</strong>
            <span>{modalData[0].email}</span>
          </li>
          <li>
            <span>{modalData[0].number_of_guest}-guest</span>
          </li>
          <li>
            <span>{Moment(modalData[0].creationdate).format('DD-MM-YYYY') + " " + Moment(modalData[0].creationdate).format('HH:mm')}</span>
          </li>
          <li className="my-booking-detail order-detail-message">
            <strong>Instructions:</strong>
            <span>{modalData[0].instruction}</span>
          </li>
        </ul>
        <div className="booking-status-holder">
          <div className="booking-status-process booking-status">
              {modalData[0].status === "N" ? (
                <p style={{background: OrderColor.N}}>{`Your booking is ${OrderStatus.N}.`}</p>
              ):modalData[0].status === "A"? (
                <p style={{background: OrderColor.A}}>{`Your booking is ${OrderStatus.A}.`}</p>
              ):modalData[0].status === "R"? (
                <p style={{background: OrderColor.R}}>{`Your booking is ${OrderStatus.R}.`}</p>
              ):modalData[0].status === "C"?(
                <p style={{background: OrderColor.C}}>{`Your booking is ${OrderStatus.C}.`}</p>
              ):null}


          </div>
        </div>
      </div>
      )}


      </ModalBody>
    </Modals>
    </>
    );
  }
}

const mapStateToProps = state => ({
    restaurants:state.restaurant,
    food_item:state.restaurant.food_item,
    restaurant_listing:state.restaurant.restaurant_listing,
    payment_list: state.buyer.payment_list,
    book_food_center_table_listing: state.food.book_food_center_table_listing,
})

export default connect(mapStateToProps)(BuyerBookings);
