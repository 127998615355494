import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import {connect} from 'react-redux';
import {IdentityContext} from './../store/helpers/userServices'
import {profileInfo} from './../store/actions/buyerActions'
import {restaurantInfo} from './../store/actions/restaurantActions'

class InnerLayout extends Component {

  componentDidMount(){
    if(localStorage.getItem("type") && ['U','R'].includes(localStorage.getItem("type"))){
      if(localStorage.getItem("type")==="U"){
        this.props.dispatch(profileInfo())
      }else{
        this.props.dispatch(restaurantInfo())
      }
    }
  }

  render() {
    return (
      <IdentityContext.Provider value={this.props.profile_info}>
        <div className="main-wrapper">
          <Header {...this.props} />
          <div className="min-height-area">
          <div className="main-section">
          {this.props.children }
          </div>
          </div>
        <Footer {...this.props} />
        </div>
      </IdentityContext.Provider>
    );
  }
}
const mapStateToProps = state => ({
  profile_info : localStorage.getItem("type")==="R"?state.restaurant.restaurant_profile:localStorage.getItem("type")==="U"?state.buyer.user_profile:{}
})
export default connect(mapStateToProps)(InnerLayout);
