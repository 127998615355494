import React from 'react';
import AppRouter from './Routes/Router';


function App() {
  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
