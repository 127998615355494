import React, { Component } from 'react'
import { connect } from "react-redux"
import { Link } from "react-router-dom";
import Pagination from "./../../store/helpers/Pagination";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RecepieVideoListing, DeleteRecipeVideo, PublishVideo } from './../../store/actions/restaurantActions'
import Modals from './../../modules/Html/Modals';
import ModalHeader from './../../modules/Html/ModalHeader';
import ModalBody from './../../modules/Html/ModalBody';
import swal from 'sweetalert';

class RecipeVideos extends Component {
	constructor(props) {
		super(props);
		this.state = { pageOfItems: [], currentPage: 1, recipe_video: {} };
	}

	componentDidMount() {
		let data = JSON.parse(localStorage.getItem("data"));
		let restaurantID = data["restaurantID"];
		this.props.dispatch(RecepieVideoListing({ restaurantID }));
	}

	onChangePage = (pageOfItems, page) => {
		this.setState({ pageOfItems: pageOfItems, currentPage: page });
	};

	DeleteRow = (recordingID) => {
		swal({
			title: "Are you sure want to delete this record?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					this.props.dispatch(DeleteRecipeVideo({ recordingID }));
				} else {
					swal({ title: "Record is safe!", icon: "success", });
				}
			});
	}

	publish = (recordingID, status) => {
		swal({
			title: status !== "P" ? "Are you sure want to remove this from published videos?" : "Are you sure want to publish this video?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					this.props.dispatch(PublishVideo({ recordingID, status }));
				} else {
					swal({ title: "Record is safe!", icon: "success", });
				}
			});
	}

	CloseModal = e => {
		this.setState({
			open: false,
			recipe_video: {}
		})
	}

	render() {
		const { video_listing } = this.props.restaurant_data
		const { pageOfItems,open, recipe_video } = this.state;
		return (
			<div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
				<div className="user-dashboard">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="element-title has-border right-filters-row">
								<h5>My Videos</h5>
								<Link to="/add-video" className="add-menu-item collapsed margin-bottom-10">
									Add Video
								</Link>
								<Link to="/live-stream" className="add-menu-item collapsed margin-bottom-10" style={{marginRight:"10px"}}>
									Live Stream
								</Link>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="user-orders-list">
								<div className="table-responsive">
									{/* <ul className="table-generic table-generic-temp"> */}
									<DragDropContext onDragEnd={e => this.onDragEnd(e)}>
										<Droppable droppableId="droppable">
											{(provided, snapshot) => (
												<ul className="table-generic table-generic-temp" {...provided.droppableProps} ref={provided.innerRef} >
													<li className="order-heading-titles">
														<div>Created On</div>
														<div>Title</div>
														<div>Description</div>
														<div>Video</div>
														<div>Action</div>
													</li>
													{pageOfItems instanceof Array && pageOfItems.map((value, index) => (
														<Draggable key={`key_${index}`} draggableId={value.recordingID.toString()} index={value.recordingID}>
															{(provided, snapshot) => (
																<li className="order-heading-titles" draggable={true} key={`category_${value.recordingID}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
																	<div>{value.creationdate}</div>
																	<div>{value.title}</div>
																	<div><p className="facilitydescription">{value.description}</p></div>
																	<div className="add-recepie-img-video list-video-thumb">
																		<Link to="#">
																			<i className="fas fa-play play-video-ico" onClick={e => { this.setState({ open: true, recipe_video: value }) }}></i>
																		</Link>
																		<video width="100" id={"video_" + index}>
																			<source src={`${value.file}`} />
																			Your browser does not support HTML video.
																		</video>
																	</div>
																	<div>
																		{/* <Link to={"/video-view/" + value.recordingID}>
																	<i className="fa fa-eye"></i>
																</Link>&nbsp;&nbsp; */}

																		<Link className="action-ico edit-ico" title="Edit" to={"/edit-video/" + value.recordingID}>
																			<i className="fa fa-edit"></i>
																		</Link>&nbsp;&nbsp;

																		<Link className="action-ico del-ico" title="Delete" to="#" onClick={e => this.DeleteRow(value.recordingID)}>
																			<i className="fa fa-trash"></i>
																		</Link>&nbsp;&nbsp;
																		{
																			value.status && value.status !== "P"
																				?
																				<Link className="action-ico publish-ico" title="Publish" to="#" onClick={e => this.publish(value.recordingID, "P")}>
																					<i className="icon-share"></i>
																				</Link>
																				:
																				<Link className="action-ico discard-ico" title="Remove Publish" to="#" onClick={e => this.publish(value.recordingID, "A")}>
																					<i className="icon-block2"></i>
																				</Link>
																		}
																	</div>
																</li>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</ul>
											)}
										</Droppable>
									</DragDropContext>
								</div>
							</div>
						</div>
						{video_listing instanceof Array && (
							<Pagination
								pageSize={10}
								items={video_listing}
								onChangePage={this.onChangePage}
							/>
						)}
					</div>

				</div>
				<Modals open={open} >
					<ModalHeader CloseModal={this.CloseModal} title={recipe_video.title} />
					<ModalBody>
						<div className="row">
							{/* <div className="col-md-12">
								<h5>{recipe_video.title}</h5>
							</div> */}
							<div className="col-md-12 video-desc">
								<p>{recipe_video.description}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<video controls src={`${recipe_video.file}`}>
									Your browser does not support the video tag.
								</video>
							</div>
						</div>
					</ModalBody>
				</Modals>
			</div>
		)
	}
}
const mapStateToProps = (state) => ({
	restaurant_data: state.restaurant
});

export default connect(mapStateToProps)(RecipeVideos);
