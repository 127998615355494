import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { particularFoodBookingsList,updateBookingStatus } from "./../../store/actions/foodActions";
import Pagination from "./../../store/helpers/Pagination";
import Modals from "./../../modules/Html/Modals";
import ModalHeader from "./../../modules/Html/ModalHeader";
// import ModalFooter from './../../modules/Html/ModalFooter';
import ModalBody from "./../../modules/Html/ModalBody";
import Moment from 'moment';

const OrderStatus = {
  N: "Waiting for FoodCenter",
  P: "Processing",
  A: "Accepted",
  R: "Rejected",
  C: "Completed",
};

const OrderColor = {
  N: "#1e73be",
  P: "#1e73be",
  A: "#047a06",
  R: "#c33332",
  C: "#047a06",
};
const initialState = {
  pageOfItems: [],
  currentPage: 1,
  open: false,
  modal_type: "",
  order_detail: [],
  changed_status:''
};
class FoodBookings extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.storage = JSON.parse(localStorage.getItem("data")) ?? {};
  }

  componentDidMount() {
    this.props.dispatch(particularFoodBookingsList());
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

  CloseModal = () => {
    this.setState({ open: false, modal_type: "" });
  };

  onClickHandler = (status, booknumber,trigger=null) => {
    this.props.dispatch(
      updateBookingStatus({ status: status, booknumber: booknumber })
    );
    this.setState({changed_status:status})
    if(trigger==='trigger'){
      window.$(`clickable_${booknumber}`).trigger('click')
    }
  };

  render() {
    const { particular_food_booking_list } = this.props;
    const { pageOfItems, open, order_detail } = this.state;
    // let new_order_details = order_detail.items ? JSON.parse(order_detail.items) : []
    return (
      <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div className="user-dashboard loader-holder">
          <div className="user-holder">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="row">
                <div className="element-title has-border right-filters-row">
                  <h5>My Bookings</h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="user-orders-list">
                  <div className="responsive-table">
                    <ul className="table-generic">
                      <li className="order-heading-titles">
                        <div>Booking id</div>
                        <div>Date</div>
                        <div>Detail</div>
                      </li>
                      {pageOfItems instanceof Array &&
                        pageOfItems.map((value, indexes) => {
                          return (
                            <li
                              className="order-heading-titles"
                              key={`indexes${indexes}`}
                            >
                              <div>#{value.booknumber}</div>
                              <div>{Moment(value.creationdate).format('DD-MM-YYYY') + " " + Moment(value.creationdate).format('HH:mm') }</div>
                              {value.status === "N" && (
                                <div>
                                  <span
                                    className="order-status"
                                    style={{ backgroundColor: "#047a06" }}
                                    onClick={(e) =>
                                      this.onClickHandler(
                                        "A",
                                        value.booknumber
                                      )
                                    }
                                  >
                                    {"Accept" ?? "New"}
                                  </span>
                                  &nbsp;
                                  <span
                                    className="order-status"
                                    style={{
                                      backgroundColor: "rgb(195, 51, 50)",
                                    }}
                                    onClick={(e) =>
                                      this.onClickHandler(
                                        "R",
                                        value.booknumber
                                      )
                                    }
                                  >
                                    {"Reject" ?? "New"}
                                  </span>
                                </div>
                              )}
                              {value.status !== "N" && (
                                <div>
                                  {" "}
                                  <span
                                    className="order-status"
                                    style={{
                                      backgroundColor:
                                        OrderColor?.[value.status],
                                    }}
                                  >
                                    {OrderStatus?.[value.status]}
                                  </span>
                                </div>
                              )}

                              <div>
                                <Link
                                  to="#"
                                  id={`clickable_${value.booknumber}`}
                                  onClick={(e) =>
                                      this.setState({
                                          open: true,
                                          modal_type: "view_detail",
                                          order_detail: value,
                                        })
                                  }
                                >
                                  <i className="icon-plus2 text-color"></i>
                                </Link>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {particular_food_booking_list instanceof Array && (
              <Pagination
                pageSize={10}
                items={particular_food_booking_list}
                onChangePage={this.onChangePage}
              />
            )}
          </div>
        </div>
        {/* Modal Data */}
        <Modals open={open} size={"sm"}>
          <ModalHeader
            CloseModal={this.CloseModal}
            title=
                "Booking detail"

          />
          <ModalBody>
          {order_detail && Object.keys(order_detail).length > 0 && (
            <div className=" my-booking-detail order-detail-inner">
            <ul className="my-booking-detail order-detail-options">
              <li>
                <strong>Booking ID :</strong>
                <span>#{order_detail.booknumber}</span>
              </li>
              <li>
                <strong>Booking Date & Time :</strong>
                <span>{Moment(order_detail.booking_date).format('DD-MM-YYYY') + " " + order_detail.booking_time} </span>
              </li>
            </ul>
            <h3>Customer Deatil</h3>
            <ul className="my-booking-detail order-detail-options">
              <li>
                <strong>First Name:</strong>
                <span>{order_detail.first_name}</span>
              </li>
              <li>
                <strong>Last Name:</strong>
                <span>{order_detail.last_name}</span>
              </li>
              <li>
                <strong>Email:</strong>
                <span>{order_detail.email}</span>
              </li>
              <li>
                <strong>Total Guest:</strong>
                <span>{order_detail.number_of_guest}-guest</span>
              </li>
              <li>
                <span>{Moment(order_detail.creationdate).format('DD-MM-YYYY') + " " + Moment(order_detail.creationdate).format('HH:mm')}</span>
              </li>
              <li className="my-booking-detail order-detail-message">
                <strong>Instructions:</strong>
                <span>{order_detail.instruction}</span>
              </li>
            </ul>
            <div className="booking-status-holder">
              <div className="booking-status-process booking-status">
                  {order_detail.status === "N" ? (
                    <p style={{background: OrderColor.N}}>{`Your booking is ${OrderStatus.N}.`}</p>
                  ):order_detail.status === "A"? (
                    <p style={{background: OrderColor.A}}>{`Your booking is ${OrderStatus.A}.`}</p>
                  ):order_detail.status === "R"? (
                    <p style={{background: OrderColor.R}}>{`Your booking is ${OrderStatus.R}.`}</p>
                  ):order_detail.status === "C"?(
                    <p style={{background: OrderColor.C}}>{`Your booking is ${OrderStatus.C}.`}</p>
                  ):null}


              </div>
            </div>
          </div>
          )}
          </ModalBody>
        </Modals>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  particular_food_booking_list: state.food.particular_food_booking_list,
});

export default connect(mapStateToProps)(FoodBookings);
