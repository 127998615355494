import React, { PureComponent } from "react";
import Banner from "./Banner";
class MultiOrder extends PureComponent {

    render() {
        return (
            <>
            {/* Main Section Start */}
            <div className="main-section diet-plan">
              <Banner name="Multi Orders"/>
              {/* 404 Page Start */}
              <div className="page-section nopadding cs-nomargin">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    {
                      //<div className="cs-section-title"><h2>Multi Order</h2></div>
                    }

                      <div className="page-not-found coming-soon-page">
                        <div className="cs-text">
                          <span className="red-color">Coming soon</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 404 Page End */}
            </div>
            </>
        );
    }
}


export default MultiOrder;
