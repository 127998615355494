import * as types from "./../types/types";

const initalState = {
    admin_order_list:[],
    admin_user_list:[],
    category_listing:[],
    subcategory_listing:[],
    product_listing:[],
    product_image:[],
    admin_agro_list:[],
    admin_food_list:[],
    data_count:{}
}

const AdminReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.ADMIN_ORDER_LIST:
            return {
                ...state,
                admin_order_list: action.payload

            }
            case types.ADMIN_USER_LIST:
                return {
                    ...state,
                    admin_user_list: action.payload
                }
            case types.FETCH_CATEGORY_LISTING:
                return {
                    ...state,
                    category_listing: action.payload
                }
            case types.FETCH_SUBCATEGORY_LISTING:
                return {
                    ...state,
                    subcategory_listing: action.payload
                }
            case types.FETCH_PRODUCT_LISTING:
            return {
                ...state,
                product_listing: action.payload
            }
            case types.FETCH_PRODUCT_IMAGES_LISTING:
                return {
                    ...state,
                    product_image: action.payload
                }
            case types.ADMIN_AGRO_USER_LIST:
                  return {
                    ...state,
                    admin_agro_list: action.payload
                }
            case types.ADMIN_FOOD_USER_LIST:
                  return {
                    ...state,
                    admin_food_list: action.payload
                }
            case types.ADMIN_DATA_COUNT_LIST:
                  return {
                    ...state,
                    data_count: action.payload
                }


        default:
            return state
    }
}

export default AdminReducer;
