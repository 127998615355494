import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Listings extends Component {
  render() {
    return (
      <>
        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
          <div className="listing-sorting-holder">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h4>
                  15 Mama's found
                  <span className="pull-right list-grid-ico"></span>
                </h4>
              </div>
            </div>
          </div>
          <div className="listing simple">
            <ul>
              <li>
                <div className="img-holder">
                  <figure>
                    <Link to="#">
                      <img
                        src="assets/extra-images/mama-logo.png"
                        className="img-list wp-post-image"
                        alt=""
                      />
                    </Link>
                  </figure>
                  <span className="restaurant-status close">
                    <em className="bookmarkRibbon"></em>Close
                  </span>
                </div>
                <div className="text-holder">
                  <div className="post-title">
                    <h5>
                      <Link to="/listing-detail">Casio Mama</Link>
                    </h5>
                  </div>
                  <span className="post-categories">
                    <span></span> Apple Juice
                  </span>
                  <div className="delivery-potions">
                    <div className="post-time">
                      <i className="icon-location-pin"></i>
                    </div>
                    <span>Kangra (HP)</span>
                  </div>
                  <div className="list-rating">
                    <div className="rating-star">
                      <span
                        className="rating-box"
                        style={{ width: "100%" }}
                      ></span>
                    </div>
                    <span className="reviews">(1)</span>
                  </div>
                </div>
                <div className="list-option">
                  <Link
                    to="#"
                    className="shortlist-btn"
                    data-toggle="modal"
                    data-target="#sign-in"
                  >
                    <i className="icon-heart4"></i>{" "}
                  </Link>
                  <Link
                    to="/listing-detail"
                    className="viewmenu-btn text-color"
                  >
                    View Menu
                  </Link>
                </div>
              </li>
              <li>
                <div className="img-holder">
                  <figure>
                    <Link to="#">
                      {" "}
                      <img
                        src="assets/extra-images/fb-restaurant-01.jpg"
                        className="img-list wp-post-image"
                        alt=""
                      />{" "}
                    </Link>
                  </figure>
                  <span className="restaurant-status close">
                    <em className="bookmarkRibbon"></em>Close
                  </span>
                </div>
                <div className="text-holder">
                  <div className="post-title">
                    <h5>
                      <Link to="/listing-detail">Jet’s Kitchen</Link>
                    </h5>
                  </div>
                  <span className="post-categories">
                    <span></span> Chines Soup, Egg Fry, Fish Fry
                  </span>
                  <div className="delivery-potions">
                    <div className="post-time">
                      <i className="icon-location-pin"></i>
                    </div>
                    <span>Kangra (HP)</span>
                  </div>
                </div>
                <div className="list-option">
                  <Link
                    to="#"
                    className="shortlist-btn"
                    data-toggle="modal"
                    data-target="#sign-in"
                  >
                    <i className="icon-heart-o"></i>{" "}
                  </Link>
                  <Link
                    to="/listing-detail"
                    className="viewmenu-btn text-color"
                  >
                    View Menu
                  </Link>
                </div>
              </li>
              <li>
                <div className="img-holder">
                  <figure>
                    <Link to="#">
                      <img
                        src="assets/extra-images/mama-logo.png"
                        className="img-list wp-post-image"
                        alt=""
                      />{" "}
                    </Link>
                  </figure>
                  <span className="restaurant-status close">
                    <em className="bookmarkRibbon"></em>Close
                  </span>
                </div>
                <div className="text-holder">
                  <div className="post-title">
                    <h5>
                      <Link to="/listing-detail">Golden Chick Fries</Link>
                    </h5>
                  </div>
                  <span className="post-categories">
                    <span></span>
                    Doughnut, Hot Dogs, Ice Cream
                  </span>
                  <div className="delivery-potions">
                    <div className="post-time">
                      <i className="icon-location-pin"></i>
                    </div>
                    <span>Kangra (HP)</span>
                  </div>
                </div>
                <div className="list-option">
                  <Link
                    to="#"
                    className="shortlist-btn"
                    data-toggle="modal"
                    data-target="#sign-in"
                  >
                    <i className="icon-heart-o"></i>{" "}
                  </Link>
                  <Link
                    to="/listing-detail"
                    className="viewmenu-btn text-color"
                  >
                    View Menu
                  </Link>
                </div>
              </li>
              <li>
                <div className="img-holder">
                  <figure>
                    <Link to="#">
                      <img
                        src="assets/extra-images/mama-logo.png"
                        className="img-list wp-post-image"
                        alt=""
                      />{" "}
                    </Link>
                  </figure>
                  <span className="restaurant-status open">
                    <em className="bookmarkRibbon"></em>Open
                  </span>
                </div>
                <div className="text-holder">
                  <div className="post-title">
                    <h5>
                      <Link to="/listing-detail">Dragon Express</Link>
                    </h5>
                  </div>
                  <span className="post-categories">
                    <span></span> Apple Juice, Carrot Juice, Ice Cream
                  </span>
                  <div className="delivery-potions">
                    <div className="post-time">
                      <i className="icon-location-pin"></i>
                    </div>
                    <span>Kangra (HP)</span>
                  </div>
                </div>
                <div className="list-option">
                  <Link
                    to="#"
                    className="shortlist-btn"
                    data-toggle="modal"
                    data-target="#sign-in"
                  >
                    <i className="icon-heart-o"></i>{" "}
                  </Link>
                  <Link
                    to="/listing-detail"
                    className="viewmenu-btn text-color"
                  >
                    View Menu
                  </Link>
                </div>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="page-nation">
                <ul className="pagination pagination-large">
                  <li className="disabled">
                    <span>Prev</span>
                  </li>
                  <li className="active">
                    <span>
                      <Link className="page-numbers active" to="#">
                        1
                      </Link>
                    </span>
                  </li>
                  <li>
                    <Link to="#">2</Link>
                  </li>
                  <li>
                    <Link to="#">Next</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section-sidebar col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <div
            className="message-box message-box-top"
            style={{ backgroundColor: "#fa9918" }}
          >
            <strong>I‘ m not Listed!</strong>
            <span>is your mama or business not listed on over site</span>
            <Link to="#" className="request-btn">
              Add Your Mama
            </Link>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Listings);
