import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {fooduploadInformationUpdate,fooduploadInformation} from './../../store/actions/foodActions';
import {bucketPath} from './../../store/helpers/common'
import SimpleReactValidator from "simple-react-validator";
import "react-phone-number-input/style.css";
const initialState = {image:[],video:[],imagenew:'',videonew:'',imageFile:[]}


class FoodUploadInformation extends Component {
    constructor(props) {
        super(props);
        this.formValidator = new SimpleReactValidator();
        this.state = initialState;
      }

    componentDidMount(){
        this.props.dispatch(fooduploadInformation())
    }

    onChangeHandler = e => {
        if(e.target.name === "image"){
          let file = window.$('#file_icon')[0].files;
          let recipe_images = [];
    			for (let i = 0; i < file.length; i++) {
    				let imageFile = URL.createObjectURL(file[i])
    				recipe_images.push(imageFile);
    			}
          this.setState({
    				image: this.state.image.concat(recipe_images),
            imagenew : recipe_images,
            imageFile : this.state.imageFile.concat(file[0]),
    			})
        }
      else if(e.target.name === "video"){
      let file = window.$('#file_icon1')[0].files;
  			let recipe_videos = [];
  			for (let i = 0; i < file.length; i++) {
  				let videoFile = URL.createObjectURL(file[i]);
  				recipe_videos.push(videoFile);
  			}
  			this.setState({
  				video: recipe_videos,
          videonew:recipe_videos
  			})
      }
    }
    imageDeleteHandler = (value,value2) =>{
      let arrayimage = [...this.state.image];
      let index = arrayimage.indexOf(value)
      if (index !== -1) {
        arrayimage.splice(index, 1);
        this.setState({image: arrayimage});
      }
      let difference = this.state.image.length - this.state.imageFile.length;
      let indeximageFile = value2 - difference;
      let arrayimageFile = [...this.state.imageFile]
      if(indeximageFile !== -1){
        arrayimageFile.splice(indeximageFile, 1);
        this.setState({imageFile: arrayimageFile},() =>{
          if(this.state.imageFile.length === 0){
            this.setState({imagenew:''})
          }
        });
      }


    }

    videoDeleteHandler = (value) =>{
      let arrayvideo = [...this.state.video];
      let index = arrayvideo.indexOf(value)
      if (index !== -1) {
        arrayvideo.splice(index, 1);
        this.setState({video: arrayvideo},() =>{
          if(this.state.video.length === 0){
            this.setState({videonew:''})
          }
        });
      }

    }



    openModal = () => {
        this.setState({ openMap: true });
      };

      closeModal = () => {
        this.setState({ openMap: false });
      };


    componentDidUpdate = (prevProps, prevState) => {
        let nextProps = this.props;
        if(nextProps.food_upload_information !== prevProps.food_upload_information){

          this.setState({
            image : nextProps.food_upload_information.data.other_images ? nextProps.food_upload_information.data.other_images.split(",") : [],
            video: nextProps.food_upload_information.data.video ? nextProps.food_upload_information.data.video.split(",") : [],
          })

        }
      }


    onSubmitHandler = () => {
        const validator = this.formValidator;
        if(validator.allValid()){
           this.props.dispatch(fooduploadInformationUpdate(this.state,this));
        }else{
            validator.showMessages();
            this.forceUpdate();
        }
    }


  render() {


    return (
      <>
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div className="user-dashboard loader-holder">
            <div className="user-holder">
                <div className="user-profile">
                    <div className="element-title has-border">
                    {
                      //<h5>Account Settings</h5>
                    }

                    </div>
                      <form id="publisher_profile">
                      <div className="form-fields-set">
                        <ul>
                          <li>
                              <div className="row">
                                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                      <div className="field-holder">
                                          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                              <label>Images*</label>
                                          </div>
                                          {
                                            // <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            //   <select>
                                            //     <option>Mr.</option>
                                            //     <option>Mrs.</option>
                                            //   </select>
                                            // </div>
                                          }

                                          <div className="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                                          <div id="file_icon-sec" className="browse-btn-sec" style={{ display: 'block !important' }}>
                        										<input
                        											type="file"
                        											id="file_icon"
                                              ref={(input) => { this.fileinput = input; }}
                        											name="image"
                        											onChange={this.onChangeHandler}
                        											className="browse-menu-icon-file"
                        											style={{ display: 'none' }}
                        											accept="image/*"
                        											multiple
                        										/>
                        										<i
                        											className="fas fa-camera camera-ico"
                        											onClick={() => {
                        												this.fileinput.click();
                        											}}
                        										></i>
                                            {this.formValidator.message( "image", this.state.imagenew, "required" )}
                        									</div>
                                          </div>
                                          <div className="argoupload-img col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                          {
                                            this.state.image.map((value, index) => {
                                              let imgSrc = ""

                                              if (value.includes("http://") === true || value.includes("https://") === true) {
                                                imgSrc = value;
                                              }
                                              else{
                                                imgSrc = `${bucketPath}${value}`
                                              }

                                              return (
                                                <div key={"img_" + index} className="add-recepie-img-video">
                                                  <Link to="#">
                                                    <i className="fas fa-trash-alt del-profile-ico" onClick = {() =>this.imageDeleteHandler(value,index)}></i>
                                                  </Link>
                                                  <img src={`${imgSrc}`} alt=""/>
                                                </div>
                                              )

                                            })
                                          }
                                          </div>
                                      </div>

                                  </div>
                                  {
                                    // <>
                                    // <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    // </div>
                                    // <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    //     <div className="field-holder">
                                    //         <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                    //             <label>Video*</label>
                                    //         </div>
                                    //         <div className="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                                    //         <div id="file_icon-sec" className="browse-btn-sec" style={{ display: 'block !important' }}>
                                    //           <input
                                    //             type="file"
                                    //             id="file_icon1"
                                    //             ref={(input) => { this.videoFileinput = input; }}
                                    //             name="video"
                                    //             //value={this.state.videonew}
                                    //             onChange={this.onChangeHandler}
                                    //             className="browse-menu-icon-file"
                                    //             style={{ display: 'none' }}
                                    //             accept="video/*"
                                    //             multiple
                                    //           />
                                    //           <i
                                    //             className="fas fa-camera camera-ico"
                                    //             onClick={() => {
                                    //               this.videoFileinput.click();
                                    //             }}
                                    //           ></i>
                                    //         </div>
                                    //         </div>
                                    //         <div className="argoupload-img col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    //         {
                                    //           this.state.video.map((value, index) => {
                                    //             let vdoSrc = "";
                                    //             if (value.includes("http://") === true || value.includes("https://") === true) {
                                    //               vdoSrc = value;
                                    //             }
                                    //             else{
                                    //               vdoSrc = `${bucketPath}${vdoSrc}`
                                    //             }
                                    //
                                    //             return (
                                    //               <div key={"video_" + index} className="add-recepie-img-video">
                                    //                 <Link to="/">
                                    //                   <i className="fas fa-trash-alt del-profile-ico" onClick = {() =>this.videoDeleteHandler(value)}></i>
                                    //                 </Link>
                                    //                 <video width="100">
                                    //                   <source src={`${vdoSrc}`} />
                                    //                                               Your browser does not support HTML video.
                                    //                                           </video>
                                    //               </div>
                                    //             )
                                    //
                                    //           })
                                    //         }
                                    //         </div>
                                    //     </div>
                                    // </div>
                                    // </>
                                  }



                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    </div>

                              </div>
                          </li>
                        </ul>
                      </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div className="field-holder text-center">
                                  <button name="button" type="button" className="btn btn-black" onClick={this.onSubmitHandler.bind(this)}>SAVE & CONTINUE</button>
                              </div>
                          </div>
                      </form>
                </div>
            </div>
        </div>
    </div>
    </>
    );
  }
}

const mapStateToProps = (state) => ({
    food_upload_information : state.food.food_upload_information
});

export default connect(mapStateToProps)(FoodUploadInformation);
