import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import MaskedInput from "react-maskedinput";
import { connect } from "react-redux";
import { OrderDetail } from "./../../store/actions/restaurantActions";
import { MakePayment } from "./../../store/actions/paymentsActions";
import { creditExpiry } from "./../../store/helpers/validator";

import "./style.css";

const InitialState = {
  name: "",
  card_number: "",
  cvv: "",
  expiry_date: "",
  order_number: "",
};

class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = InitialState;
    this.validator = new SimpleReactValidator({
      validators: {
        validDate: {  // name the rule
          message: 'Expiry date must be greater than current date',
          rule: (val, params, validator) => {
              return val?creditExpiry(val):true;
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
          required: true  // optional
        }
      }
    });
  }

  componentDidMount = async () => {
    let token = this.props?.match?.params?.token;
    let response = await this.props.dispatch(
      OrderDetail({ order_number: token })
    );
    if (response.code === 1) {
      this.setState({ order_number: token });
    }
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = async (e) => {
    const { name, card_number, cvv, expiry_date, order_number } = this.state;
    const { order_detail } = this.props;
    let [exp_month,exp_year] = expiry_date.split('/')
    if (this.validator.allValid()) {
      let data = {name,amount:order_detail.grand_total,card_number,exp_month,exp_year,cvv,orderNumber:order_number}
      const response = await this.props.dispatch(MakePayment(data));
      if(response===1){
        if(order_detail.order_type === "condiment"){
          this.props.history.push('/buyer-product-orders')
        }
        else{
          this.props.history.push('/buyer-orders')
        }

      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { name, card_number, cvv, expiry_date} = this.state;
    const { order_detail } = this.props;
    const ordered_items = order_detail.items? JSON.parse(order_detail.items): [];
    return (
      <div className="container">
        <div className="row justify-content-center payment-section">
          <div className="col-md-10 col-md-offset-1 payment-section-inner">
          <div className="card card0 rounded-0">
            <div className="row">
              <div className="col-md-5 d-md-block d-none p-0 box no-padding">
                <div className="card rounded-0 border-0 card1 bill-side" id="bill">
                  <h3 id="heading1">Payment Summary</h3>
                  {ordered_items.map((value, index) => {
                    return (
                      <div className="row" key={`order${index}`}>
                        <div className="col-lg-7 col-8 mt-4 line pl-4">
                          <h2 className="bill-head">
                          {order_detail.order_type === "cooking_class" || order_detail.order_type === "agroCenter_membership" || order_detail.order_type === "agroCenter_visitorTour" ? value.name : (<>{value.name} x {value.count}</>) }

                          </h2>
                        </div>
                        <div className="col-lg-5 col-4 mt-4">
                          <h2 className="bill-head px-xl-5 px-lg-4">
                            Rs. {value.price}
                          </h2>
                        </div>
                      </div>
                    );
                  })}
                  {order_detail.order_type === "cooking_class" || order_detail.order_type === "agroCenter_membership" || order_detail.order_type === "agroCenter_visitorTour" ? null : (
                    <>
                    <div className="row" key={`order2323`}>
                      <div className="col-lg-7 col-8 mt-4 line pl-4">
                        <h2 className="bill-head">
                          {order_detail.type === "D"
                            ? "Delivery Charge"
                            : "Pickup charge"}
                        </h2>
                      </div>
                      <div className="col-lg-5 col-4 mt-4">
                        <h2 className="bill-head px-xl-5 px-lg-4">
                          Rs. {order_detail==="D"?order_detail.delivery_fee:order_detail.pickup_fee}
                        </h2>
                      </div>
                    </div>
                    <div className="row" key={`order265323`}>
                      <div className="col-lg-7 col-8 mt-4 line pl-4">
                        <h2 className="bill-head">
                          VAT
                        </h2>
                      </div>
                      <div className="col-lg-5 col-4 mt-4">
                        <h2 className="bill-head px-xl-5 px-lg-4">
                          Rs. {order_detail.vat}
                        </h2>
                      </div>
                    </div>
                    </>
                  )}

                  <div className="row">
                    <div className="col-md-12 red-bg">
                      <p className="bill-date" id="total-label">
                        Total Price
                      </p>
                      <h2 className="bill-head" id="total">
                        Rs. {order_detail?.grand_total}
                      </h2>
                      <small className="bill-date" id="total-label">
                        Price includes all taxes
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-sm-12 p-0 box">
                <div className="card rounded-0 border-0 card2" id="paypage">
                  <div className="form-card">
                    <h2 id="heading2" className="text-danger">
                      Payment Method
                    </h2>
                    <div className="radio-group">
                      <div className="radio" data-value="credit">
                        <img
                          src="https://i.imgur.com/28akQFX.jpg"
                          alt="imgur"
                        />
                      </div>
                      <div className="radio" data-value="paypal">
                        <img
                          src="https://i.imgur.com/5QFsx7K.jpg"
                          alt="imgur"
                        />
                      </div>
                      <br />
                    </div>
                    <label className="pay">Name on Card</label>
                    <input
                      type="text"
                      name="name"
                      value={name}
                      placeholder="John Smith"
                      onChange={this.onChangeHandler.bind(this)}
                    />
                    {this.validator.message(
                      "Name",
                      name,
                      "required|alpha_space|min:5,string,max:30,string"
                    )}
                    <div className="row">
                      <div className="col-8 col-md-6">
                        <label className="pay">Card Number</label>
                        <MaskedInput
                          mask="1111 1111 1111 1111"
                          name="card_number"
                          id="cr_no"
                          size="20"
                          value={card_number}
                          onChange={this.onChangeHandler.bind(this)}
                        />
                        {this.validator.message(
                          "Card Number",
                          card_number,
                          "required"
                        )}
                      </div>
                      <div className="col-4 col-md-6">
                        <label className="pay">CVV</label>
                        <MaskedInput
                          mask="111"
                          className="placeicon"
                          name="cvv"
                          value={cvv}
                          // placeholder="&#9679;&#9679;&#9679;"
                          onChange={this.onChangeHandler.bind(this)}
                        />
                        {this.validator.message(
                          "Cvv",
                          cvv,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label className="pay">Expiration Date</label>
                      </div>
                      <div className="col-md-12">
                        <MaskedInput
                          mask="11/1111"
                          id="exp"
                          name="expiry_date"
                          placeholder="MM/YYYY"
                          value={expiry_date}
                          onChange={this.onChangeHandler.bind(this)}
                        />
                        {this.validator.message(
                          "Card Expiry",
                          expiry_date,
                          "required|validDate"
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <input
                          type="submit"
                          value="MAKE A PAYMENT"
                          className="btn btn-info placeicon make-pay-btn"
                          onClick={this.submitHandler.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

const getStateFromProps = (state) => ({
  order_detail: state.restaurant.order_detail,
});
export default connect(getStateFromProps)(PaymentForm);
