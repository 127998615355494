import React, { Component } from "react";
import { connect } from "react-redux";
import MapAutocomplete from "./../gMaps/MapAutocomplete";
import MapModal from "./../modals/MapModal";
import {profileInfo,UserProfileUpdate} from './../../store/actions/buyerActions';
import SimpleReactValidator from "simple-react-validator";
import "react-phone-number-input/style.css";
import { parsePhoneNumber, getCountryCallingCode, } from "react-phone-number-input";

const initialState = {firstname:'',lastname:'',email:"",phone:"+91",country:'',address:'',location:'',longitude:'',latitude:'',zipcode:'',state:'',city:'',prefix:'',profile_pic:'',correspondence_address:[],addMoreAddress:false,modalType:"",country2:'',address2:'',location2:'',longitude2:'',latitude2:'',zipcode2:'',state2:'',city2:'',country3:'',address3:'',latitude3:'',zipcode3:'',state3:'',city3:''}


class BuyerAddMoreAddress extends Component {
    constructor(props) {
        super(props);
        this.formValidator = new SimpleReactValidator();
        this.state = initialState;
      }

    componentDidMount(){
        this.props.dispatch(profileInfo())
    }

    onChangeHandler = e => {
        this.setState({[e.target.name]:e.target.value})
    }

    openModal = (value) => {
        this.setState({
          openMap: true ,
          modalType:value
        });
      };

      closeModal = () => {
        this.setState({
          openMap: false ,
          modalType:""
        });
      };

      FetchAddress = (data) => {
        const location = `${data.area}, ${data.state}${data.zip ? `, ${data.zip}` : ''}`;
        this.setState({
        country3: data.country,
        location3: location,
        longitude3:data.lngValue,
        latitude3:data.latValue,
        city3:data.city,
        state3:data.state,
        zipcode3:data.zip
        });
    }

    FetchAddress2 = (data) => {
      const location = `${data.area}, ${data.state}${data.zip ? `, ${data.zip}` : ''}`;
      this.setState({
      country2: data.country,
      location2: location,
      longitude2:data.lngValue,
      latitude2:data.latValue,
      city2:data.city,
      state2:data.state,
      zipcode2:data.zip
      });
  }

    componentDidUpdate = (prevProps, prevState) => {
        let nextProps = this.props;
        if(nextProps.profile !== prevProps.profile){
          let correspondence_address_new_data = []
          if(nextProps && nextProps.profile && nextProps.profile.correspondence_address != null){
            correspondence_address_new_data = nextProps && nextProps.profile && nextProps.profile.correspondence_address && JSON.parse(nextProps.profile.correspondence_address)
          }
          this.setState({
            firstname : nextProps?.profile?.firstname,
            lastname: nextProps?.profile?.lastname,
            email:nextProps?.profile?.email,
            phone:nextProps?.profile?.phone,
            prefix:nextProps?.profile?.prefix,
            country:nextProps?.profile?.country,
            location:nextProps?.profile?.location,
            address:nextProps?.profile?.address,
            zipcode:nextProps?.profile?.zipcode,
            state:nextProps?.profile?.state,
            longitude:nextProps?.profile?.longitude,
            latitude:nextProps?.profile?.latitude,
            city:nextProps?.profile?.city,
            profile_pic:nextProps?.profile?.profile_pic,
            correspondence_address:correspondence_address_new_data,
            country2:correspondence_address_new_data.length > 0 && correspondence_address_new_data[0] && correspondence_address_new_data[0].country2 ? correspondence_address_new_data[0].country2 : "" ,
            address2:correspondence_address_new_data.length > 0 && correspondence_address_new_data[0] && correspondence_address_new_data[0].address2 ? correspondence_address_new_data[0].address2 :"",
            location2:correspondence_address_new_data.length > 0 && correspondence_address_new_data[0] && correspondence_address_new_data[0].location2 ? correspondence_address_new_data[0].location2 :"",
            longitude2:correspondence_address_new_data.length > 0 && correspondence_address_new_data[0] && correspondence_address_new_data[0].longitude2 ? correspondence_address_new_data[0].longitude2 :"",
            latitude2:correspondence_address_new_data.length > 0 && correspondence_address_new_data[0] && correspondence_address_new_data[0].latitude2 ? correspondence_address_new_data[0].latitude2 :"",
            zipcode2:correspondence_address_new_data.length > 0 && correspondence_address_new_data[0] && correspondence_address_new_data[0].zipcode2 ? correspondence_address_new_data[0].zipcode2 :"",
            state2:correspondence_address_new_data.length > 0 && correspondence_address_new_data[0] && correspondence_address_new_data[0].state2 ? correspondence_address_new_data[0].state2 :"",
            city2:correspondence_address_new_data.length > 0 && correspondence_address_new_data[0] && correspondence_address_new_data[0].city2 ? correspondence_address_new_data[0].city2 :"",
            country3:correspondence_address_new_data.length > 0 && correspondence_address_new_data[1] && correspondence_address_new_data[1].country3 ? correspondence_address_new_data[1].country3 : "" ,
            address3:correspondence_address_new_data.length > 0 && correspondence_address_new_data[1] && correspondence_address_new_data[1].address3 ? correspondence_address_new_data[1].address3 :"",
            location3:correspondence_address_new_data.length > 0 && correspondence_address_new_data[1] && correspondence_address_new_data[1].location3 ? correspondence_address_new_data[1].location3 :"",
            longitude3:correspondence_address_new_data.length > 0 && correspondence_address_new_data[1] && correspondence_address_new_data[1].longitude3 ? correspondence_address_new_data[1].longitude3 :"",
            latitude3:correspondence_address_new_data.length > 0 && correspondence_address_new_data[1] && correspondence_address_new_data[1].latitude3 ? correspondence_address_new_data[1].latitude3 :"",
            zipcode3:correspondence_address_new_data.length > 0 && correspondence_address_new_data[1] && correspondence_address_new_data[1].zipcode3 ? correspondence_address_new_data[1].zipcode3 :"",
            state3:correspondence_address_new_data.length > 0 && correspondence_address_new_data[1] && correspondence_address_new_data[1].state3 ? correspondence_address_new_data[1].state3 :"",
            city3:correspondence_address_new_data.length > 0 && correspondence_address_new_data[1] && correspondence_address_new_data[1].city3 ? correspondence_address_new_data[1].city3 :"",
          })

        }
      }

      numberChange = (number) => {
        let phoneNumber = number ? parsePhoneNumber(number.toString()) : "";
        let prefix = "";
        if (phoneNumber) {
          if (phoneNumber.country) {
            if (getCountryCallingCode(phoneNumber.country)) {
              prefix = getCountryCallingCode(phoneNumber.country);
            }
          }
        }
        this.setState({ phone: number, prefix: prefix });
      };

    onSubmitHandler = async() => {
        const validator = this.formValidator;
          const correspondence_address_data = [{
            country2:this.state.country2,
            address2:this.state.address2,
            location2:this.state.location2,
            longitude2:this.state.longitude2,
            latitude2:this.state.latitude2,
            zipcode2:this.state.zipcode2,
            state2:this.state.state2,
            city2:this.state.city2
          },{
            country3:this.state.country3,
            address3:this.state.address3,
            location3:this.state.location3,
            longitude3:this.state.longitude3,
            latitude3:this.state.latitude3,
            zipcode3:this.state.zipcode3,
            state3:this.state.state3,
            city3:this.state.city3
          }]
          this.setState({
            correspondence_address:correspondence_address_data
          })
          const data = {
            firstname:this.state.firstname,
            lastname:this.state.lastname,
            email:this.state.email,
            phone:this.state.phone,
            country:this.state.country,
            address:this.state.address,
            location:this.state.location,
            longitude:this.state.longitude,
            latitude:this.state.latitude,
            zipcode:this.state.zipcode,
            state:this.state.state,
            city:this.state.city,
            prefix:this.state.prefix,
            correspondence_address: correspondence_address_data,
          }
        if(validator.allValid()){
          const response = await this.props.dispatch(UserProfileUpdate(data));
          if(response===1){
              this.setState(initialState)
              this.props.dispatch(profileInfo())
          }
        }else{
            validator.showMessages();
            this.forceUpdate();
        }
    }


    addMoreAddressHandler = () =>{
      this.setState({
        addMoreAddress:!this.state.addMoreAddress
      },() =>{
        // if(!this.state.addMoreAddress){
        //   this.setState({
        //     country2:'',
        //     address2:'',
        //     location2:'',
        //     longitude2:'',
        //     latitude2:'',
        //     zipcode2:'',
        //     state2:'',
        //     city2:''
        //   })
        // }
      })
    }


  render() {
    return (
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div className="user-dashboard loader-holder">
            <div className="user-holder">
                <div className="user-profile">
                    <div className="element-title has-border">
                        <h5>Add More Address</h5>
                    </div>
                    <div className="row">
                        <form id="publisher_profile">

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="opt-conts">
                                    <div className="row">
                                        <div className="field-holder col-md-12">
                                            {/* <label>Find On Map</label> */}
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="field-holder">
                                                <label>Street/Lane Address *</label>
                                                <div className="select-holder">
                                                    <input type="text" placeholder="Street/Lane Address" className="form-control gllpLatitude" name="address2" value={this.state.address2} onChange={this.onChangeHandler.bind(this)}/>
                                                    {this.formValidator.message( "address", this.state.address2, "required|max:50,string" )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <div className="field-holder">
                                                <label>Location * </label>
                                                <div className="select-holder">
                                                    <span>
                                                        <i
                                                        className="fa fa-map-marker"
                                                        style={{ color: "red" }}
                                                        aria-hidden="true"
                                                        onMouseUp={() =>this.openModal("location2")}
                                                        ></i>
                                                    </span>
                                                    <input type="text" placeholder="Location" className="form-control gllpLatitude" name="location2" value={this.state.location2} onChange={this.onChangeHandler.bind(this)}/>
                                                    {this.formValidator.message( "location", this.state.location2, "required|max:50,string" )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <div className="field-holder">
                                                <label>Country *</label>
                                                <div className="select-holder">
                                                    <input type="text" placeholder="Country" className="form-control gllpLatitude" name="country2" value={this.state.country2} onChange={this.onChangeHandler.bind(this)}/>
                                                    {this.formValidator.message( "country", this.state.country2, "required|max:50,string" )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <div className="field-holder">
                                                <label>Pincode *</label>
                                                <div className="select-holder">
                                                    <input type="text" placeholder="PinCode" className="form-control gllpLatitude" name="zipcode2" value={this.state.zipcode2} onChange={this.onChangeHandler.bind(this)}/>
                                                    {this.formValidator.message( "Pincode", this.state.zipcode2, "required|max:50,string" )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center">
                                            <label className="add-more-address" onClick={() =>this.addMoreAddressHandler()}>Add More Address</label>
                                        </div>
                                        {this.state.addMoreAddress && (
                                          <>
                                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                              <div className="field-holder">
                                                  <label>Street/Lane Address *</label>
                                                  <div className="select-holder">
                                                      <input type="text" placeholder="Street/Lane Address" className="form-control gllpLatitude" name="address3" value={this.state.address3} onChange={this.onChangeHandler.bind(this)}/>
                                                      {this.formValidator.message( "address", this.state.address3, "required|max:50,string" )}
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                              <div className="field-holder">
                                                  <label>Location * </label>
                                                  <div className="select-holder">
                                                      <span>
                                                          <i
                                                          className="fa fa-map-marker"
                                                          style={{ color: "red" }}
                                                          aria-hidden="true"
                                                          onMouseUp={() =>this.openModal("location3")}
                                                          ></i>
                                                      </span>
                                                      <input type="text" placeholder="Location" className="form-control gllpLatitude" name="location3" value={this.state.location3} onChange={this.onChangeHandler.bind(this)}/>
                                                      {this.formValidator.message( "location", this.state.location3, "required|max:50,string" )}
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                              <div className="field-holder">
                                                  <label>Country *</label>
                                                  <div className="select-holder">
                                                      <input type="text" placeholder="Country" className="form-control gllpLatitude" name="country3" value={this.state.country3} onChange={this.onChangeHandler.bind(this)}/>
                                                      {this.formValidator.message( "country", this.state.country3, "required|max:50,string" )}
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                              <div className="field-holder">
                                                  <label>Pincode *</label>
                                                  <div className="select-holder">
                                                      <input type="text" placeholder="PinCode" className="form-control gllpLatitude" name="zipcode3" value={this.state.zipcode3} onChange={this.onChangeHandler.bind(this)}/>
                                                      {this.formValidator.message( "Pincode", this.state.zipcode3, "required|max:50,string" )}
                                                  </div>
                                              </div>
                                          </div>
                                          </>
                                        )}

                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <MapModal
                                                openMap={this.state.openMap}
                                                closeModal={this.closeModal}
                                            >
                                            <MapAutocomplete
                                            center={{ lat: 18.5204, lng: 73.8567 }}
                                            height="400px"
                                            zoom={16}
                                            FetchAddress = {this.state.modalType === "location3" ? this.FetchAddress : this.FetchAddress2}
                                            />
                                            </MapModal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="field-holder text-center">
                                    <button name="button" type="button" className="btn-submit" onClick={this.onSubmitHandler.bind(this)}>Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => ({
    profile : state.buyer.user_profile
});

export default connect(mapStateToProps)(BuyerAddMoreAddress);
