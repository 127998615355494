import * as actionTypes from '../types/types';
import { BaseUrl } from '../helpers/common'; 
import { handleResponse, queryResponse } from '../helpers/userServices'; 
import swal from 'sweetalert';
import { ShowLoader, ShowAlert } from './staticActions'  


export const AgroProductCart = (data) => { 
    return dispatch => {
        dispatch({ type: actionTypes.AGRO_PRODUCT_CART, payload: data })
    }
}

export const AgroProductModelOpen = (data) => {
  return dispatch => {
      dispatch({ type: actionTypes.AGRO_PRODUCT_MODEL_OPEN, payload: data })
  }
}



export function  addAgroCart (value,state){ 
  return dispatch => {
  let uniqueID = value.uniquekey;
  let name = value.name;
  let price = parseFloat(value.price);
  let image = value.image;
  let quantity = value.quantity;
  let { agrocartItems } = state;
  let arrayFilter =
    agrocartItems instanceof Array &&
    agrocartItems.findIndex((obj) => obj.uniqueID === uniqueID);
  if (arrayFilter !== -1) {
    agrocartItems[arrayFilter] = {
      name: name,
      uniqueID: uniqueID,
      image:image,
      quantity:quantity,
      price: agrocartItems[arrayFilter].price + price,
      count: agrocartItems[arrayFilter].count + 1,
    };
  } else {
    agrocartItems = [
      ...agrocartItems,
      { name: name, uniqueID: uniqueID, price: price,image:image,quantity:quantity, count: 1 },
    ];
  } 
  dispatch(AgroProductCart(agrocartItems));
  return null;
}
};

export function minusAgroCart(value,state) {
  return dispatch => {
  let uniqueID = value.uniquekey;
  let name = value.name;
  let price = parseFloat(value.price);
  let image = value.image;
  let quantity = value.quantity;
  let { agrocartItems } = state; 
  let arrayFilter =
    agrocartItems instanceof Array &&
    agrocartItems.findIndex((obj) => obj.uniqueID === uniqueID); 
  if (arrayFilter !== -1) {
    if (agrocartItems[arrayFilter].count > 1) {
      agrocartItems[arrayFilter] = {
        name: name,
        uniqueID: uniqueID,
        image : image,
        quantity :quantity,
        price: agrocartItems[arrayFilter].price - price,
        count: agrocartItems[arrayFilter].count - 1,
      };
    } else {
      agrocartItems.splice(arrayFilter, 1);
    }
  } 
  dispatch(AgroProductCart(agrocartItems));
  return null;
}
};

export function removeAgroCart(uniqueID,state){
  return dispatch => {
  let  agrocartItems  = state;
  let arrayFilter =
  agrocartItems instanceof Array &&
  agrocartItems.filter((obj) => obj.uniqueID !== uniqueID);
  dispatch(AgroProductCart(arrayFilter));
  return null;
}
};

export function ViewAgroProduct(data) {
  return dispatch => {
      dispatch(ShowLoader(true));
      const request = new Request(`${BaseUrl}/api/agrocenter/view_product`, {
          method: 'POST',
          body:JSON.stringify(data),
          headers: new Headers({ 'Content-Type': 'application/json' })
      });
      return fetch(request).then(queryResponse).then((data) => {
          dispatch(ShowLoader(false));
          if (data.ResponseCode === '1') {
              dispatch({ type: actionTypes.VIEW_AGRO_PRODUCT, payload: data.data })
              return 1;
          } else {
              dispatch({ type: actionTypes.VIEW_AGRO_PRODUCT, payload :[]})
              return 0;
          }
      }).catch(error => {
          dispatch(ShowLoader(false));
      });;
  }
}


export function addAgroProductReview(data) { 
  let productID = data["productID"];
  return dispatch => {
      dispatch(ShowLoader(true));
      const request = new Request(`${BaseUrl}/api/agrocenter/add_product_review`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
      });
      return fetch(request).then(queryResponse).then((data) => {
          dispatch(ShowLoader(false));
          if (data.ResponseCode === '1') {
              swal({ title: "Success!", text: "Review Added successfully!", icon: "success" });
              dispatch(agroReviewListing({ "productID": productID }))
              return 1;
          } else {
              return 0;
          }
      }).catch(error => {
          dispatch(ShowLoader(false));
      });;
  }
}


export function agroReviewListing(data) { 
  return dispatch => {
      dispatch(ShowLoader(true));
      const request = new Request(`${BaseUrl}/api/agrocenter/product_review_list`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
      });
      return fetch(request).then(queryResponse).then((data) => {
          dispatch(ShowLoader(false));
          if (data.ResponseCode === '1') {
              dispatch({ type: actionTypes.AGRO_PRODUCT_REVIEW_LISTING, payload: data.data })
              return 1;
          } else {
              dispatch({ type: actionTypes.AGRO_PRODUCT_REVIEW_LISTING, payload: [] })
              return 0;
          }
      }).catch(error => {
          dispatch(ShowLoader(false));
      });;
  }
}