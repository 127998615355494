// JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
export const initOptions = {
    disableAudioLevels: true,

    // The ID of the jidesha extension for Chrome.
    desktopSharingChromeExtId: 'mbocklcggfhnbahlnepmldehdhpjfcjp',

    // Whether desktop sharing should be disabled on Chrome.
    desktopSharingChromeDisabled: false,

    // The media sources to use when using screen sharing with the Chrome
    // extension.
    desktopSharingChromeSources: ['screen', 'window'],

    // Required version of Chrome extension
    desktopSharingChromeMinExtVersion: '0.1',

    // Whether desktop sharing should be disabled on Firefox.
    desktopSharingFirefoxDisabled: true
};

export const options = {
    hosts: {
        domain: 'rtc-mamachai.cap-tek.com',
        muc: 'conference.rtc-mamachai.cap-tek.com'
    },
    bosh: 'https://rtc-mamachai.cap-tek.com/http-bind',
    clientNode: 'http://jitsi.org/jitsimeet',
}
export const confOptions = {
    openBridgeChannel: true 
};  