import { BaseUrl } from '../helpers/common';
import * as actionTypes from '../types/types';
import { handleResponse, queryResponse } from '../helpers/userServices';
import swal from 'sweetalert';
import { ShowLoader,ShowAlert } from './staticActions'
import axios from 'axios'

export const locationInformationRequest = (agrocenterId) =>{
  return{
    type : actionTypes.LOCATION_INFORMATION_REQUEST,
    payload : agrocenterId
  }
}


export const locationInformationSuccess = (location_success) =>{
  return{
    type : actionTypes.LOCATION_INFORMATION_SUCCESS,
    payload : location_success
  }
}

export const locationInformationFaliure = (error) =>{
  return{
    type : actionTypes.LOCATION_INFORMATION_FALIURE,
    payload : error
  }
}

export const locationInformation = (agrocenterId) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(locationInformationRequest(agrocenterId))
      const uniqueID = agrocenterId ? agrocenterId : localStorage.getItem("data") ? `${JSON.parse(localStorage.getItem("data")).uniqueID}` : "";
      const request = new Request(`${BaseUrl}/api/agrocenter_location`, {
          method: 'POST',
          body: JSON.stringify({ uniqueID: uniqueID }),
          headers: new Headers({ 'Content-Type': 'application/json' })
      });
      return fetch(request).then(agrocenterId ? queryResponse : handleResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        const location_success = data
        dispatch(locationInformationSuccess(location_success))
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(locationInformationFaliure(errorMsg))
    })
    }
}

export const locationInformationUpdateRequest = (location_update_data) =>{
  return{
    type : actionTypes.LOCATION_INFORMATION_UPDATE_REQUEST,
    payload : location_update_data
  }
}

export const locationInformationUpdateSuccess = (location_update_success) =>{
  return{
    type : actionTypes.LOCATION_INFORMATION_UPDATE_SUCCESS,
    payload : location_update_success
  }
}

export const locationInformationUpdateFaliure = (error) =>{
  return{
    type : actionTypes.LOCATION_INFORMATION_UPDATE_FALIURE,
    payload : error
  }
}

export const locationInformationUpdate = (location_update_data) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(locationInformationUpdateRequest(location_update_data))

      const request = new Request(`${BaseUrl}/api/update_location_infromation`, {
          method: 'POST',
          body: JSON.stringify(location_update_data),
        headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
      });
      return fetch(request).then(handleResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        swal({ title: "Success!", text: "Location Information Updated successfully!", icon: "success" });
      }else {
          swal({ title: "Error!", text: data.ResponseText, icon: "error" });
          return 0;
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(locationInformationUpdateFaliure(errorMsg))
    })
    }
}

export const contactInformationRequest = (agrocenterId) =>{
  return{
    type : actionTypes.CONTACT_INFORMATION_REQUEST,
    payload : agrocenterId
  }
}

export const contactInformationSuccess = (contact_success) =>{
  return{
    type : actionTypes.CONTACT_INFORMATION_SUCCESS,
    payload : contact_success
  }
}

export const contactInformationFaliure = (error) =>{
  return{
    type : actionTypes.CONTACT_INFORMATION_FALIURE,
    payload : error
  }
}

export const contactInformation = (agrocenterId) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(contactInformationRequest(agrocenterId))
      const uniqueID = agrocenterId ? agrocenterId : localStorage.getItem("data") ? `${JSON.parse(localStorage.getItem("data")).uniqueID}` : "";
      const request = new Request(`${BaseUrl}/api/agrocenter_contact_informatiom`, {
          method: 'POST',
          body: JSON.stringify({ uniqueID: uniqueID }),
          headers: new Headers({ 'Content-Type': 'application/json' })
      });
      return fetch(request).then(agrocenterId ? queryResponse : handleResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        const contact_success = data
        dispatch(contactInformationSuccess(contact_success))
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(contactInformationFaliure(errorMsg))
    })
    }
}

export const contactInformationUpdateRequest = (contact_update_data) =>{
  return{
    type : actionTypes.LOCATION_INFORMATION_UPDATE_REQUEST,
    payload : contact_update_data
  }
}

export const contactInformationUpdateSuccess = (contact_update_success) =>{
  return{
    type : actionTypes.LOCATION_INFORMATION_UPDATE_SUCCESS,
    payload : contact_update_success
  }
}

export const contactInformationUpdateFaliure = (error) =>{
  return{
    type : actionTypes.LOCATION_INFORMATION_UPDATE_FALIURE,
    payload : error
  }
}


export const contactInformationUpdate = (contact_update_data) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(contactInformationUpdateRequest(contact_update_data))
      let formData = new FormData();
      formData.append("contact_person", contact_update_data.contact_person);
      formData.append("landline_number", contact_update_data.landline_number);
      formData.append("phone", contact_update_data.phone);
      formData.append("email", contact_update_data.email);
      formData.append("website", contact_update_data.website);
      formData.append("facebook", contact_update_data.facebook);
      formData.append("twitter", contact_update_data.twitter);
      formData.append("instagram", contact_update_data.instagram);

        let imageFile = window.$('#file_icon')[0].files[0];
        let coverimageFile = window.$('#cover_image')[0].files[0];
        formData.append(`profile_image`, imageFile);
        formData.append(`cover_image`, coverimageFile);
      const request = {
          method: 'POST',
          url: `${BaseUrl}/api/update_contact_information`,
          headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
          data: formData
      };
      return axios(request)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.data.ResponseCode === "1"){
        swal({ title: "Success!", text: "Contact Information Updated successfully!", icon: "success" });
      }else {
          swal({ title: "Error!", text: data.ResponseText, icon: "error" });
          return 0;
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(contactInformationUpdateFaliure(errorMsg))
    })
    }
}

export const otherInformationRequest = (agrocenterId) =>{
  return{
    type : actionTypes.OTHER_INFORMATION_REQUEST,
    payload : agrocenterId
  }
}

export const otherInformationSuccess = (other_success) =>{
  return{
    type : actionTypes.OTHER_INFORMATION_SUCCESS,
    payload : other_success
  }
}

export const otherInformationFaliure = (error) =>{
  return{
    type : actionTypes.OTHER_INFORMATION_FALIURE,
    payload : error
  }
}

export const otherInformation = (agrocenterId) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(otherInformationRequest(agrocenterId))
      const agroID = agrocenterId ? agrocenterId : localStorage.getItem("data") ? `${JSON.parse(localStorage.getItem("data")).agroID}` : "";
      const request = new Request(`${BaseUrl}/api/get_agrocenter_other_informatiom`, {
          method: 'POST',
          body: JSON.stringify({ agroID: agroID }),
          headers: new Headers({ 'Content-Type': 'application/json' })
      });
      return fetch(request).then(agrocenterId ? queryResponse : handleResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        const other_success = data
        dispatch(otherInformationSuccess(other_success))
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(otherInformationFaliure(errorMsg))
    })
    }
}

export const otherInformationUpdateRequest = (other_update_data) =>{
  return{
    type : actionTypes.OTHER_INFORMATION_UPDATE_REQUEST,
    payload : other_update_data
  }
}

export const otherInformationUpdateSuccess = (other_update_success) =>{
  return{
    type : actionTypes.OTHER_INFORMATION_UPDATE_SUCCESS,
    payload : other_update_success
  }
}

export const otherInformationUpdateFaliure = (error) =>{
  return{
    type : actionTypes.OTHER_INFORMATION_UPDATE_FALIURE,
    payload : error
  }
}

export const otherInformationUpdate = (other_update_data) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(otherInformationUpdateRequest(other_update_data))
      const request = new Request(`${BaseUrl}/api/agrocenter_other_informatiom`, {
          method: 'POST',
          body: JSON.stringify(other_update_data),
        headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
      });
      return fetch(request).then(handleResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        swal({ title: "Success!", text: "Other Information Updated successfully!", icon: "success" });
      }else {
          swal({ title: "Error!", text: data.ResponseText, icon: "error" });
          return 0;
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(otherInformationUpdateFaliure(errorMsg))
    })
    }
}

export const agroListingRequest = () =>{
  return{
    type : actionTypes.AGRO_LISTING_REQUEST
  }
}

export const agroListingSuccess = (agro_success) =>{
  return{
    type : actionTypes.AGRO_LISTING_SUCCESS,
    payload : agro_success
  }
}

export const agroListingFaliure = (error) =>{
  return{
    type : actionTypes.AGRO_LISTING_FALIURE,
    payload : error
  }
}


export const agroListing = () =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(agroListingRequest())
      const request = new Request(`${BaseUrl}/api/get_agrocenter_list`, {
          method: 'GET',
          headers: new Headers({ 'Content-Type': 'application/json' })
      });
      return fetch(request).then(queryResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        const agro_success = data
        dispatch(agroListingSuccess(agro_success))
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(agroListingFaliure(errorMsg))
    })
    }
}

export const uploadInformationUpdateRequest = (upload_update_data) =>{
  return{
    type : actionTypes.UPLOAD_INFORMATION_UPDATE_REQUEST,
    payload : upload_update_data
  }
}

export const uploadInformationUpdateSuccess = (upload_update_success) =>{
  return{
    type : actionTypes.UPLOAD_INFORMATION_UPDATE_SUCCESS,
    payload : upload_update_success
  }
}

export const uploadInformationUpdateFaliure = (error) =>{
  return{
    type : actionTypes.UPLOAD_INFORMATION_UPDATE_FALIURE,
    payload : error
  }
}

export const uploadInformationUpdate = (upload_update_data,this_ref) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      let formData = new FormData();
      //let imageFile = window.$('#file_icon')[0].files;
      let videoFile = window.$('#file_icon1')[0].files;
        let imageFile = upload_update_data.imageFile;
       // let videoFile = upload_update_data.video;
      if (imageFile.length > 0) {
          let selectedImagesLength = imageFile.length;
          for(let k = 0; k < selectedImagesLength; k++) {
              formData.append(`image[${k}]`, imageFile[k]);
          }
      }
      if (videoFile.length > 0) {
          let selectedVideosLength = videoFile.length;
          for(let k = 0; k < selectedVideosLength; k++) {
              formData.append(`video[${k}]`, videoFile[k]);

          }
      }
      const request = {
          method: 'POST',
          url: `${BaseUrl}/api/agrocenter_upload_image`,
          headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
          data: formData
      };
      // const request = new Request(`${BaseUrl}/api/agrocenter_upload_image`, {
      //     method: 'POST',
      //     data: formData,
      //   headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
      // });
      return axios(request)
    .then(data =>{

      dispatch(ShowLoader(false));
      if(data.data.ResponseCode === "1"){
        swal({ title: "Success!", text: " Upload Information Updated successfully!", icon: "success" });
        dispatch(uploadInformation())
      }else {
          swal({ title: "Error!", text: data.data.ResponseText, icon: "error" });
          return 0;
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(uploadInformationUpdateFaliure(errorMsg))
    })
    }
}

export const uploadInformationRequest = (agrouniqueID) =>{
  return{
    type : actionTypes.UPLOAD_INFORMATION_REQUEST,
    payload : agrouniqueID
  }
}

export const uploadInformationSuccess = (upload_success) =>{
  return{
    type : actionTypes.UPLOAD_INFORMATION_SUCCESS,
    payload : upload_success
  }
}

export const uploadInformationFaliure = (error) =>{
  return{
    type : actionTypes.UPLOAD_INFORMATION_FALIURE,
    payload : error
  }
}

export const uploadInformation = (agrouniqueID) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(uploadInformationRequest(agrouniqueID))
      const uniqueID = agrouniqueID ? agrouniqueID : localStorage.getItem("data") ? `${JSON.parse(localStorage.getItem("data")).uniqueID}` : "";
      const request = new Request(`${BaseUrl}/api/agrocenter_image_video_list`, {
          method: 'POST',
          body: JSON.stringify({ uniqueID: uniqueID }),
          headers: new Headers({ 'Content-Type': 'application/json' })
      });
      return fetch(request).then(agrouniqueID ? queryResponse : handleResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        const upload_success = data
        dispatch(uploadInformationSuccess(upload_success))
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(uploadInformationFaliure(errorMsg))
    })
    }
}

export const agroProfileRequest = (agroUniqueId) =>{
  return{
    type : actionTypes.AGRO_PROFILE_REQUEST,
    payload : agroUniqueId
  }
}

export const agroProfileSuccess = (agro_success) =>{
  return{
    type : actionTypes.AGRO_PROFILE_SUCCESS,
    payload : agro_success
  }
}

export const agroProfileFaliure = (error) =>{
  return{
    type : actionTypes.AGRO_PROFILE_FALIURE,
    payload : error
  }
}

export const agroProfile = (agroUniqueId) =>{
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(agroProfileRequest(agroUniqueId))
      const uniqueID = agroUniqueId ? agroUniqueId : localStorage.getItem("data") ? `${JSON.parse(localStorage.getItem("data")).uniqueID}` : "";
      const request = new Request(`${BaseUrl}/api/agrocenter_profile`, {
          method: 'POST',
          body: JSON.stringify({ uniqueID: uniqueID }),
          headers: new Headers({ 'Content-Type': 'application/json' })
      });
      return fetch(request).then(agroUniqueId ? queryResponse : handleResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        const agro_success = data
        dispatch(agroProfileSuccess(agro_success))
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(agroProfileFaliure(errorMsg))
    })
    }
}

export const ForceAgrologinClick = (data) => {
    return dispatch => {
        dispatch({ type: actionTypes.FORCE_AGRO_LOGIN_CLICK, payload: data })
    }
}

export function agroReviewListing(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/agrocenter_review_list`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.AGRO_REVIEW_LISTING, payload: data.data })
                return 1;
            } else {
                dispatch({ type: actionTypes.AGRO_REVIEW_LISTING, payload: [] })
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function addAgroCenterReview(data) {
    let agroID = data["agroID"];
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/agrocenter_add_review`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                swal({ title: "Success!", text: "Review Added successfully!", icon: "success" });
                dispatch(agroReviewListing({ "agroID": agroID }))
                return 1;
            } else {
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function likeAndDislikeListing(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/agerocenter_review_like_dislike_list`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.AGRO_LIKE_AND_DISLIKE_LISTING, payload: data.data })
                return 1;
            } else {
                dispatch({ type: actionTypes.AGRO_LIKE_AND_DISLIKE_LISTING, payload: [] })
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export const likeAndDislikeRequest = (data) =>{
  return{
    type : actionTypes.LIKE_AND_DISLIKE_REQUEST,
    payload : data
  }
}


export const likeAndDislikeSuccess = (like_and_dislike_success) =>{
  return{
    type : actionTypes.LIKE_AND_DISLIKE_SUCCESS,
    payload : like_and_dislike_success
  }
}

export const likeAndDislikeFaliure = (error) =>{
  return{
    type : actionTypes.LIKE_AND_DISLIKE_FALIURE,
    payload : error
  }
}

export const likeAndDislike = (data) =>{
    const agroID = data.agroID
    return(dispatch) => {
      dispatch(ShowLoader(true));
      dispatch(likeAndDislikeRequest(data))
      const request = new Request(`${BaseUrl}/api/agrocenter_review_like_dislike`, {
          method: 'POST',
          body: JSON.stringify({ reviewID: data.reviewID,agroID:data.agroID,status:data.status }),
          headers: new Headers({ 'Content-Type': 'application/json','X-Auth-Token': `${localStorage.getItem('token')}` })
      });
      return fetch(request).then(queryResponse)
    .then(data =>{
      dispatch(ShowLoader(false));
      if(data.ResponseCode === "1"){
        const like_and_dislike_success = data
        dispatch(likeAndDislikeSuccess(like_and_dislike_success))
        dispatch(likeAndDislikeListing({ "agroid": agroID }))
      }
    })
    .catch(error => {
      dispatch(ShowLoader(false));
      const errorMsg = error
      dispatch(likeAndDislikeFaliure(errorMsg))
    })
    }
}

export function PackageListing(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/agrocenter_package/list`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.PACKAGE_LISTING, payload: data.data })
                return 1;
            } else {
              dispatch({ type: actionTypes.PACKAGE_LISTING, payload: [] })
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function AddPackage(packagedata, this_ref) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let request;
        let formData = new FormData();
        if (packagedata.packageID !== "" && packagedata.packageID) {
            formData.append("packageID", packagedata.packageID);
        }
        formData.append("title", packagedata.title);
        formData.append("description", packagedata.description);
        formData.append("people", packagedata.people);
        formData.append("price", packagedata.price);

        request = {
            method: 'POST',
            url: `${BaseUrl}/api/agrocenter_package/${packagedata.packageID ? "update" : "create" }`,
            headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
            data: formData
        };

        return axios(request).then((data) => {
            dispatch(ShowLoader(false));
            swal({ title: "Success!", text: `Package ${packagedata.packageID !== "" ? "update" : "create" } successfully!`, icon: "success" });
            //dispatch(RestaurantMenuListing(JSON.parse(localStorage.getItem('data')).uniqueID));
            return 1;
        }).catch((error) => {
            dispatch(ShowLoader(false));
            if (error && error.response && error.response.data) {
              swal({ title: "Error!", text: error.response.data.ResponseText, icon: "error" });
                //dispatch(ShowAlert({ Class: "alert-danger", Message: error.response.data.ResponseText, Timeout: 1500 }));
                return 0;
            } else {
              swal({ title: "Error!", text: 'Error Occured', icon: "error" });
                //dispatch(ShowAlert({ Class: "alert-danger", Message: 'Error Occured', Timeout: 1500 }));
                return 0;
            }
        });
    }
}

export function DeletePackage(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/agrocenter_package/delete`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode !== '1') {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 1;
            } else {
              let data = JSON.parse(localStorage.getItem("data"));
              let agroID = data["agroID"];
                dispatch(PackageListing({agroID}))
                swal({ title: "Deleted!", text: response.ResponseText, icon: "success" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });
    }
}

export function ViewPackage(uniqueID) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let data = {
            uniqueID
        }
        const request = new Request(`${BaseUrl}/api/agrocenter_package/view`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                dispatch({ type: actionTypes.PACKAGE_VIEW, payload: response.data });
                return 1;
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function AddAgroProduct(data) {
  return dispatch => {
      dispatch(ShowLoader(true));
      const request = new Request(`${BaseUrl}/api/agrocenter/add_product`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
      });
      return fetch(request).then(handleResponse).then(data => {
          dispatch(ShowLoader(false));
          if (data.ResponseCode === "1") {
              localStorage.setItem('agro_product', data.uniquekey);
              dispatch(AgroProductListing());
              dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));
              return 1;
          } else {
              dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
              return 0;
          }
      })
  }
}


export function EditAgroProduct(data) {
  return dispatch => {
      dispatch(ShowLoader(true));
      const request = new Request(`${BaseUrl}/api/agrocenter/update_product`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
      });
      return fetch(request).then(handleResponse).then(data => {
          dispatch(ShowLoader(false));
          if (data.ResponseCode === "1") {
              dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));
              dispatch(AgroProductListing());
              return 1;
          } else {
              dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
              return 0;
          }
      })
  }
}

export function AgroProductListing() {
  return dispatch => {
      dispatch(ShowLoader(true));
      const request = new Request(`${BaseUrl}/api/agrocenter/product_listing`, {
          method: 'POST',
          body: JSON.stringify(),
          headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
      });
      return fetch(request).then(handleResponse).then((data) => {
          dispatch(ShowLoader(false));
          dispatch({
              type: actionTypes.FETCH_AGROCENTER_PRODUCT_LISTING,
              payload: data.data
          });
      });
  }
}


export function DeleteAgroProduct(data) {
  return dispatch => {
      const request = new Request(`${BaseUrl}/api/agrocenter/delete_product`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
      });

      return fetch(request)
          .then(res => res.json())
          .then(data => {
              if (data.ResponseCode === "1") {
                  dispatch(AgroProductListing());
                  dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));
              } else {
                  dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
              }
          })
          .catch(err => {
              console.log(err);
          });
  }

}


export function AgroProductImagesListing(data) {
  return dispatch => {
      dispatch(ShowLoader(true));
      const request = new Request(`${BaseUrl}/api/agrocenter/product_image_listing`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
      });
      return fetch(request).then(handleResponse).then((data) => {
          dispatch(ShowLoader(false));
          dispatch({
              type: actionTypes.FETCH_AGRO_PRODUCT_IMAGES_LISTING,
              payload: data.data
          });
      });
  }
}

export function DeleteAgroProductImage(post_data) {
  return dispatch => {
      const request = new Request(`${BaseUrl}/api/agrocenter/delete-product-images`, {
          method: 'POST',
          body: JSON.stringify({image:post_data.value}),
          headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
      });

      return fetch(request)
          .then(res => res.json())
          .then(data => {
              if (data.ResponseCode === "1") {
                  let post_id =  localStorage.getItem('agro_product');
                  let ID = post_data.isEdit ? post_data.id : post_id
                  dispatch(AgroProductImagesListing({id:ID}));
                  dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));
              } else {
                  dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
              }
          })
          .catch(err => {
              console.log(err);
          });
  }

}

export function GlobalAgroProductListing(data) {
  return dispatch => {
      dispatch(ShowLoader(true));
      const request = new Request(`${BaseUrl}/api/agrocenter/global-product-listing`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: new Headers({ 'Content-Type': 'application/json'}),
      });
      return fetch(request).then(queryResponse).then((data) => {
          dispatch(ShowLoader(false));
          dispatch({
              type: actionTypes.FETCH_GLOBAL_AGRO_PRODUCT_LISTING,
              payload: data.data
          });
      });
  }
}


export function AddMembership(membershipdata, this_ref) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let request;
        let formData = new FormData();
        if (membershipdata.membershipID !== "" && membershipdata.membershipID) {
            formData.append("membershipID", membershipdata.membershipID);
        }
        formData.append("title", membershipdata.title);
        formData.append("description", membershipdata.description);
        formData.append("price", membershipdata.price);

        request = {
            method: 'POST',
            url: `${BaseUrl}/api/agrocenter_membership/${membershipdata.membershipID ? "update" : "create" }`,
            headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
            data: formData
        };

        return axios(request).then((data) => {
            dispatch(ShowLoader(false));
            swal({ title: "Success!", text: `Membership ${membershipdata.membershipID !== "" ? "update" : "create" } successfully!`, icon: "success" });
            //dispatch(RestaurantMenuListing(JSON.parse(localStorage.getItem('data')).uniqueID));
            return 1;
        }).catch((error) => {
            dispatch(ShowLoader(false));
            if (error && error.response && error.response.data) {
              swal({ title: "Error!", text: error.response.data.ResponseText, icon: "error" });
                //dispatch(ShowAlert({ Class: "alert-danger", Message: error.response.data.ResponseText, Timeout: 1500 }));
                return 0;
            } else {
              swal({ title: "Error!", text: 'Error Occured', icon: "error" });
                //dispatch(ShowAlert({ Class: "alert-danger", Message: 'Error Occured', Timeout: 1500 }));
                return 0;
            }
        });
    }
}

export function MembershipListing(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/agrocenter_membership/list`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.MEMBERSHIP_LISTING, payload: data.data })
                return 1;
            } else {
              dispatch({ type: actionTypes.MEMBERSHIP_LISTING, payload: [] })
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}

export function ViewMembership(uniqueID) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let data = {
            uniqueID
        }
        const request = new Request(`${BaseUrl}/api/agrocenter_membership/view`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode === '1') {
                dispatch({ type: actionTypes.MEMBERSHIP_VIEW, payload: response.data });
                return 1;
            } else {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });;
    }
}
export function DeleteMembership(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/agrocenter_membership/delete`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((response) => {
            dispatch(ShowLoader(false));
            if (response.ResponseCode !== '1') {
                swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                return 1;
            } else {
              let data = JSON.parse(localStorage.getItem("data"));
              let agroID = data["agroID"];
                dispatch(MembershipListing({agroID}))
                swal({ title: "Deleted!", text: response.ResponseText, icon: "success" });
                return 0;
            }
        }).catch(error => {
            dispatch(ShowLoader(false));
        });
    }
  }

  export function AddFacility(facilitydata, this_ref) {
      return dispatch => {
          dispatch(ShowLoader(true));
          let request;
          let formData = new FormData();
          if (facilitydata.facilityID !== "" && facilitydata.facilityID) {
              formData.append("facilityID", facilitydata.facilityID);
          }
          formData.append("description", facilitydata.description);


          request = {
              method: 'POST',
              url: `${BaseUrl}/api/agrocenter_facility/${facilitydata.facilityID ? "update" : "create" }`,
              headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
              data: formData
          };

          return axios(request).then((data) => {
              dispatch(ShowLoader(false));
              swal({ title: "Success!", text: `Facility ${facilitydata.facilityID !== "" ? "update" : "create" } successfully!`, icon: "success" });
              //dispatch(RestaurantMenuListing(JSON.parse(localStorage.getItem('data')).uniqueID));
              return 1;
          }).catch((error) => {
              dispatch(ShowLoader(false));
              if (error && error.response && error.response.data) {
                swal({ title: "Error!", text: error.response.data.ResponseText, icon: "error" });
                  //dispatch(ShowAlert({ Class: "alert-danger", Message: error.response.data.ResponseText, Timeout: 1500 }));
                  return 0;
              } else {
                swal({ title: "Error!", text: 'Error Occured', icon: "error" });
                  //dispatch(ShowAlert({ Class: "alert-danger", Message: 'Error Occured', Timeout: 1500 }));
                  return 0;
              }
          });
      }
  }

  export function FacilityListing(data) {
      return dispatch => {
          dispatch(ShowLoader(true));
          const request = new Request(`${BaseUrl}/api/agrocenter_facility/list`, {
              method: 'POST',
              body: JSON.stringify(data),
              headers: new Headers({ 'Content-Type': 'application/json' })
          });
          return fetch(request).then(queryResponse).then((data) => {
              dispatch(ShowLoader(false));
              if (data.ResponseCode === '1') {
                  dispatch({ type: actionTypes.FACILITY_LISTING, payload: data.data })
                  return 1;
              } else {
                dispatch({ type: actionTypes.FACILITY_LISTING, payload: [] })
                  return 0;
              }
          }).catch(error => {
              dispatch(ShowLoader(false));
          });;
      }
  }

  export function ViewFacility(uniqueID) {
      return dispatch => {
          dispatch(ShowLoader(true));
          let data = {
              uniqueID
          }
          const request = new Request(`${BaseUrl}/api/agrocenter_facility/view`, {
              method: 'POST',
              body: JSON.stringify(data),
              headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
          });
          return fetch(request).then(handleResponse).then((response) => {
              dispatch(ShowLoader(false));
              if (response.ResponseCode === '1') {
                  dispatch({ type: actionTypes.FACILITY_VIEW, payload: response.data });
                  return 1;
              } else {
                  swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                  return 0;
              }
          }).catch(error => {
              dispatch(ShowLoader(false));
          });;
      }
  }
  export function DeleteFacility(data) {
      return dispatch => {
          dispatch(ShowLoader(true));
          const request = new Request(`${BaseUrl}/api/agrocenter_facility/delete`, {
              method: 'POST',
              body: JSON.stringify(data),
              headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
          });
          return fetch(request).then(handleResponse).then((response) => {
              dispatch(ShowLoader(false));
              if (response.ResponseCode !== '1') {
                  swal({ title: "Error!", text: response.ResponseText, icon: "error" });
                  return 1;
              } else {
                let data = JSON.parse(localStorage.getItem("data"));
                let agroID = data["agroID"];
                  dispatch(FacilityListing({agroID}))
                  swal({ title: "Deleted!", text: response.ResponseText, icon: "success" });
                  return 0;
              }
          }).catch(error => {
              dispatch(ShowLoader(false));
          });
      }
    }
    export function bookVisitorTour(data) {
        return dispatch => {
            dispatch(ShowLoader(true));
            const request = new Request(`${BaseUrl}/api/agrocenter/book_visitor_tour`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
            });
            return fetch(request).then(queryResponse).then((data) => {
                dispatch(ShowLoader(false));
                if (data.ResponseCode === '1') {
                    return 1;
                } else {
                    return 0;
                }
            }).catch(error => {
                dispatch(ShowLoader(false));
            });;
        }
    }

    export function agroImagesListing(data) {
        return dispatch => {
            dispatch(ShowLoader(true));
            const request = new Request(`${BaseUrl}/api/agro_image_listing`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({ 'Content-Type': 'application/json'}),
            });
            return fetch(request).then(queryResponse).then((resdata) => {
                dispatch(ShowLoader(false));
                dispatch({
                    type: actionTypes.FETCH_AGRO_IMAGES_LISTING,
                    payload: resdata.data
                });
            });
        }
    }

    export function deleteAgroImage(data) {
        return dispatch => {
            const request = new Request(`${BaseUrl}/api/delete_agro_image`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
            });

            return fetch(request)
                .then(res => res.json())
                .then(data => {
                    if (data.ResponseCode === "1") {
                      let agrodata = localStorage.getItem("data");
                      let agroID = JSON.parse(agrodata)?.agroID
                        dispatch(agroImagesListing({agroID:agroID}));
                        dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));
                    } else {
                        dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }

    }
