import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import NavBar from "./NavBar";
import Alerts from "./../modules/Html/Alerts"
import {connect} from 'react-redux'
import {IdentityContext} from './../store/helpers/userServices'
import {restaurantInfo} from './../store/actions/restaurantActions'
import {profileInfo} from './../store/actions/buyerActions'
import {bucketPath} from './../store/helpers/common'
class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.storageType = localStorage.getItem("type");

    if(localStorage.getItem("data")){
      this.storageData = JSON.parse(localStorage.getItem("data"))
    }else{
      this.storageData = {}
    }
  }

  componentDidMount(){
    if(localStorage.getItem("type") && ['U','R'].includes(localStorage.getItem("type"))){
      if(localStorage.getItem("type")==="U"){
        this.props.dispatch(profileInfo())
      }else{
        this.props.dispatch(restaurantInfo())
      }
    }
  }
  render() {
    var backgroundImage = this.props.profile_info ?`${bucketPath}${this.props.profile_info?.data?.resturants?.cover_pic}`:"assets/extra-images/cover-photo01.jpg"

    return (
      <IdentityContext.Provider value={this.props.profile_info}>
      <div className="main-wrapper">
        <Header {...this.props}/>
        <div className="min-height-area">
        <Alerts/>
       { this.storageType === 'U' && <div className="main-section">
			    <div className="page-section account-header buyer-logged-in">
				    <div className="container">
              <div className="row">
                <NavBar {...this.props}/>
                {this.props.children}
              </div>
            </div>
          </div>
        </div> }

        { this.storageType === 'AG' && <div className="main-section">
 			    <div className="page-section account-header buyer-logged-in">
 				    <div className="container">
               <div className="row">
                 <NavBar {...this.props}/>
                 {this.props.children}
               </div>
             </div>
           </div>
         </div> }

         { this.storageType === 'FC' && <div className="main-section">
  			    <div className="page-section account-header buyer-logged-in">
  				    <div className="container">
                <div className="row">
                  <NavBar {...this.props}/>
                  {this.props.children}
                </div>
              </div>
            </div>
          </div> }

      { this.storageType === 'R' &&  <div className="main-section">
        <div className="page-section restaurant-detail-image-section" style={{background: "url("+backgroundImage+") no-repeat scroll 0 0 / cover" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="company-info-detail">
                  <div className="company-info">
                    <div className="img-holder">
                      <figure>
                        <img src={this.props.profile_info ?`${bucketPath}${this.props.profile_info?.data?.resturants?.profile_pic}`:"assets/extra-images/listing-logo18.png"} alt="" />
                      </figure>
                    </div>

                    <div className="text-holder">
                      <span className="restaurant-title">{this.props.profile_info ?`${this.props.profile_info?.data?.resturants?.restaurant_name}`:"MAMA"}</span>
                      <div className="text">
                        <i className="icon-local_pizza"></i>
                        <p>{this.props.profile_info ?`${this.props.profile_info?.data?.resturants?.location}`:"Apple Juice"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section account-header account-header-paddig-tp buyer-logged-in">
          <div className="container">
            <div className="row">
              <NavBar {...this.props}/>
              {this.props.children}
            </div>
          </div>
        </div>
      </div> }
      </div>
        <Footer />

      </div>
      </IdentityContext.Provider>
    );
  }
}

const mapStateToProps = state => ({
  profile_info : localStorage.getItem("type")==="R"?state.restaurant.restaurant_profile:localStorage.getItem("type")==="U"?state.buyer.user_profile:{}
})

export default connect(mapStateToProps)(MainLayout);
