import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  parsePhoneNumber,
  getCountryCallingCode,
} from 'react-phone-number-input';
import MapAutocomplete from './../gMaps/MapAutocomplete';
import MapModal from './../modals/MapModal';
import {
  restaurantInfo,
  RestaurantUpdate,
  UploadImage,
} from './../../store/actions/restaurantActions';
import { bucketPath } from './../../store/helpers/common';

const initialState = {
  restaurant_name: '',
  firstname: '',
  lastname: '',
  country: '',
  phone: '+91',
  prefix: '',
  location: '',
  address: '',
  email: '',
  information: '',
  mama_type:[],
};
class RestaurantProfile extends Component {
  constructor(props) {
    super(props);
    this.formValidator = new SimpleReactValidator();
    this.state = initialState;
  }

  componentDidMount() {
    this.props.dispatch(restaurantInfo());
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  openModal = () => {
    this.setState({ openMap: true });
  };

  closeModal = () => {
    this.setState({ openMap: false });
  };

  numberChange = (number) => {
    let phoneNumber = number ? parsePhoneNumber(number.toString()) : '';
    let prefix = '';
    if (phoneNumber) {
      if (phoneNumber.country) {
        if (getCountryCallingCode(phoneNumber.country)) {
          prefix = getCountryCallingCode(phoneNumber.country);
        }
      }
    }
    this.setState({ phone: number, prefix: prefix });
  };

  FetchAddress = (data) => {
    const location = `${data.area}, ${data.state}${
      data.zip ? `, ${data.zip}` : ''
    }`;
    this.setState({
      location: location,
      longitude: data.lngValue,
      latitude: data.latValue,
      city: data.city,
      state: data.state,
      zipcode: data.zip,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    let nextProps = this.props;
    if (nextProps.restaurant !== prevProps.restaurant) {
      this.setState({
        firstname: nextProps.restaurant.data.resturants.firstname,
        lastname: nextProps.restaurant.data.resturants.lastname,
        email: nextProps.restaurant.data.resturants.email,
        phone: nextProps.restaurant.data.resturants.phone,
        prefix: nextProps.restaurant.data.resturants.prefix,
        country: nextProps.restaurant.data.resturants.country,
        location: nextProps.restaurant.data.resturants.location,
        address: nextProps.restaurant.data.resturants.address,
        information: nextProps.restaurant.data.resturants.info,
        city: nextProps.restaurant.data.resturants.city,
        restaurant_name: nextProps.restaurant.data.resturants.restaurant_name,
        longitude: nextProps.restaurant.data.resturants.longitude,
        latitude: nextProps.restaurant.data.resturants.latitude,
        state: nextProps.restaurant.data.resturants.state,
        zipcode: nextProps.restaurant.data.resturants.zipcode,
        profile_pic: nextProps.restaurant.data.resturants.profile_pic,
        cover_pic: nextProps.restaurant.data.resturants.cover_pic,
        mama_type: nextProps.restaurant.data.resturants.mama_type.split(","),
      });
    }
  };

  onSubmitHandler = async () => {
    const {
      firstname,
      lastname,
      restaurant_name,
      address,
      city,
      state,
      zipcode,
      country,
      location,
      latitude,
      longitude,
      information,
      mama_type
    } = this.state;
    let data = {
      firstname: firstname,
      lastname: lastname,
      restaurant_name: restaurant_name,
      address: address,
      city: city,
      state: state,
      zipcode: zipcode,
      country: country,
      location: location,
      latitude: latitude,
      longitude: longitude,
      info: information,
      mama_type:mama_type.toString()
    };
    const validator = this.formValidator;
    if (validator.allValid()) {
      const response = await this.props.dispatch(RestaurantUpdate(data));
      if (response === 1) {
        this.props.history.push('/restaurant-delivery');
      }
    } else {
      validator.showMessages();
      this.forceUpdate();
    }
  };

  ImageUpload = (data, e) => {
    this.props.dispatch(UploadImage(data));
    e.preventDefault();
  };

  handleCheckbox = (e, value) => {
    if (e.target.checked) {
      this.setState({ mama_type: [...this.state.mama_type, value] });
    } else {
      let array = [...this.state.mama_type];
      let index = array.indexOf(value);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ mama_type: array });
      }
    }
  };

  selectTab = (selectedTab,e)=>{
    this.setState({
        tab:selectedTab
    })
}

  render() {
    let restaurantsetting = this.props.restaurant?.data?.restaurantsetting.length
    let restaurantworking = this.props.restaurant?.data?.restaurantworking.length
    return (
      <div className='col-lg-9 col-md-9 col-sm-12 col-xs-12'>
        <div className='user-dashboard loader-holder'>
          <div className='user-holder'>
            <form action='restaurant-location.html'>
                  {restaurantsetting > 0 && restaurantworking > 0 ?
                        (
                            <ul className='restaurant-settings-nav progressbar-nav'>
                                <li className='active cond-restaurant-settings'>
                                    <Link to='/restaurant-profile'>Restaurant Settings</Link>
                                </li>
                                <li className='cond-restaurant-settings active'>
                                    <Link to='/restaurant-delivery'>Delivery/Pickup</Link>
                                </li>
                                <li className='cond-restaurant-settings active'>
                                    <Link to='/restaurant-open-close'>Restaurant Open/Close</Link>
                                </li>
                            </ul>
                        ) : (
                            <ul className='restaurant-settings-nav progressbar-nav'>
                              <li className='active cond-restaurant-settings processing'>
                                <Link to='#'>Restaurant Settings</Link>
                              </li>
                              <li className='cond-restaurant-settings'>
                                <Link to='#'>Delivery/Pickup</Link>
                              </li>
                              <li className='cond-restaurant-settings'>
                                <Link to='#'>Restaurant Open/Close</Link>
                              </li>
                            </ul>

                        )}

             <div className='form-fields-set'>
                <ul>
                  <li>
                    <div className='row'>
                      <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <div className='restaurant-info'>
                          <div className='img-holder'>
                            <ul className='foodbakery-gallery-holder'>
                              <li className='gal-img'>
                                <div className='drag-list'>
                                  <div
                                    className='item-thumb'
                                    data-def-img='assets/extra-images/listing-logo18.png'
                                    data-img-type='default'
                                  >
                                    <figure>
                                      <Link to='#'>
                                        <img
                                          src={
                                            this.state.profile_pic
                                              ? `${bucketPath}${this.state.profile_pic}`
                                              : 'assets/extra-images/listing-logo18.png'
                                          }
                                          alt='Logo'
                                        />
                                      </Link>
                                    </figure>
                                  </div>
                                  <div className='item-assts'>
                                    <ul className='list-inline pull-right'>
                                      <li className='close-btn'>
                                        <Link to='#'>
                                          <i className='icon-cross-out'></i>
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className='text-holder'>
                            <strong>{this.state.restaurant_name}</strong>
                            <div className='upload-gallery'>
                              <input
                                className='foodbakery-dev-gallery-uploader'
                                style={{ display: 'none' }}
                                type='file'
                                id='logo_image'
                                ref={(input) => (this.uploadRef = input)}
                                onChange={(e) => this.ImageUpload('profile', e)}
                              />
                              <Link
                                to='#'
                                className='upload-btn foodbakery-dev-featured-upload-btn'
                                onClick={(e) => this.uploadRef.click()}
                              >
                                Upload Logo
                              </Link>
                            </div>
                            <span>
                              Update your avatar manually, If the not set the
                              default Gravatar will be the same as your login
                              email/user account. Max Upload Size: 1MB,
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <div className='restaurant-info'>
                          <div className='img-holder'>
                            <ul className='foodbakery-gallery-holder'>
                              <li className='gal-img'>
                                <div className='drag-list'>
                                  <div
                                    className='item-thumb'
                                    data-def-img='assets/extra-images/cover-photo01.jpg'
                                    data-img-type='default'
                                  >
                                    <figure>
                                      <Link to='#'>
                                        <img
                                          src={
                                            this.state.cover_pic
                                              ? `${bucketPath}${this.state.cover_pic}`
                                              : 'assets/extra-images/cover-photo01.jpg'
                                          }
                                          alt='Cover'
                                        />
                                      </Link>
                                    </figure>
                                  </div>
                                  <div className='item-assts'>
                                    <ul className='list-inline pull-right'>
                                      <li className='close-btn'>
                                        <Link to='#'>
                                          <i className='icon-cross-out'></i>
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className='text-holder'>
                            <div className='upload-gallery'>
                              <input
                                className='foodbakery-dev-gallery-uploader'
                                id='cover_image'
                                style={{ display: 'none' }}
                                type='file'
                                ref={(input) => (this.uploadcoverRef = input)}
                                onChange={(e) => this.ImageUpload('cover', e)}
                              />
                              <Link
                                to='#'
                                className='upload-btn foodbakery-dev-featured-upload-btn'
                                onClick={(e) => this.uploadcoverRef.click()}
                              >
                                Upload Cover Image
                              </Link>
                            </div>
                            <span>
                              Update your cover image manually, If the not set
                              the default cover image will be showing on your
                              restaurant detail page. Max Upload Size: 1MB,
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                          <label className="control-label col-md-3">Mama Type</label>
                          <div className="col-md-8 mama-type-div">
                              <div className="form-check form-check-inline ml-2">
                                <input
                                  type="checkbox"
                                  value='restaurant'
                                  name="mama_type"
                                  onChange={e=> this.handleCheckbox(e, 'restaurant')}
                                  checked={this.state.mama_type.includes(
                                    'restaurant'
                                  )}
                                />&nbsp;&nbsp;&nbsp;<label className="control-label" htmlFor="materialInline1">Restaurant</label>
                              </div>
                              <div className="form-check form-check-inline ml-2">
                                  <input
                                      type="checkbox"
                                      value='food_truck'
                                      name="mama_type"
                                      onChange={e=>this.handleCheckbox(e, 'food_truck')}
                                      checked={this.state.mama_type.includes(
                                        'food_truck'
                                      )}
                                  />&nbsp;&nbsp;&nbsp;<label className="control-label" htmlFor="materialInline2">Food Truck</label>
                              </div>
                          </div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                        <div className='field-holder'>
                          <label>Mama Name *</label>
                          <input
                            type='text'
                            className='foodbakery-dev-req-field'
                            name='restaurant_name'
                            value={this.state.restaurant_name}
                            onChange={this.onChangeHandler.bind(this)}
                          />
                          {this.formValidator.message(
                            'restaurant_name',
                            this.state.restaurant_name,
                            'required|max:50,string'
                          )}
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                        <div className='field-holder'>
                          <label>Mama Phone *</label>
                          <PhoneInput
                            name='phone'
                            placeholder='Enter phone number'
                            value={this.state.phone}
                            onChange={(number) => this.numberChange(number)}
                            defaultCountry='IN'
                            disabled={true}
                          />
                          {this.formValidator.message(
                            'phone',
                            this.state.phone,
                            'required|min:7,string'
                          )}
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                        <div className='field-holder'>
                          <label>Manager's Firstname *</label>
                          <input
                            type='text'
                            name='firstname'
                            value={this.state.firstname}
                            onChange={this.onChangeHandler.bind(this)}
                          />
                          {this.formValidator.message(
                            'firstname',
                            this.state.firstname,
                            'required|max:50,string'
                          )}
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                        <div className='field-holder'>
                          <label>Manager's Lastname *</label>
                          <input
                            type='text'
                            name='lastname'
                            value={this.state.lastname}
                            onChange={this.onChangeHandler.bind(this)}
                          />
                          {this.formValidator.message(
                            'lastname',
                            this.state.lastname,
                            'required|max:50,string'
                          )}
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                        <div className='field-holder'>
                          <label>Contact Email *</label>
                          <input
                            type='text'
                            name='email'
                            value={this.state.email}
                            onChange={this.onChangeHandler.bind(this)}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6  col-sm-12 col-xs-12'>
                        <div className='field-holder'>
                          <label>Country *</label>
                          <div className='foodbakery_editor1'>
                            <input
                              type='text'
                              name='country'
                              value={this.state.country}
                              onChange={this.onChangeHandler.bind(this)}
                            />
                            {this.formValidator.message(
                              'country',
                              this.state.country,
                              'required|max:50,string'
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4  col-sm-12 col-xs-12'>
                        <div className='field-holder'>
                          <label>Street/Lane Address *</label>
                          <div className='foodbakery_editor1'>
                            <input
                              type='text'
                              name='address'
                              value={this.state.address}
                              onChange={this.onChangeHandler.bind(this)}
                            />
                            {this.formValidator.message(
                              'address',
                              this.state.address,
                              'required|max:50,string'
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4  col-sm-12 col-xs-12'>
                        <div className='field-holder'>
                          <label>Location *</label>
                          <div className='select-holder'>
                            <span>
                              <i
                                className='fa fa-map-marker'
                                style={{ color: 'red' }}
                                aria-hidden='true'
                                onMouseUp={this.openModal}
                              ></i>
                            </span>
                            <div className='foodbakery_editor1'>
                              <input
                                type='text'
                                name='location'
                                value={this.state.location}
                                onChange={this.onChangeHandler.bind(this)}
                              />
                              {this.formValidator.message(
                                'location',
                                this.state.location,
                                'required|max:50,string'
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4  col-sm-12 col-xs-12'>
                        <div className='field-holder'>
                          <label>Pincode *</label>
                          <div className='select-holder'>
                            <div className='foodbakery_editor1'>
                              <input
                                type='text'
                                name='zipcode'
                                value={this.state.zipcode}
                                onChange={this.onChangeHandler.bind(this)}
                              />
                              {this.formValidator.message(
                                'zipcode',
                                this.state.zipcode,
                                'required|max:50,string'
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <div className='field-holder'>
                          <label>Information *</label>
                          <div className='foodbakery_editor1'>
                            <textarea
                              name='information'
                              value={this.state.information}
                              onChange={this.onChangeHandler.bind(this)}
                            ></textarea>
                            {this.formValidator.message(
                              'information',
                              this.state.information,
                              'required|max:500,string'
                            )}
                          </div>
                        </div>
                      </div>

                      <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                        <MapModal
                          openMap={this.state.openMap}
                          closeModal={this.closeModal}
                        >
                          <MapAutocomplete
                            center={{ lat: 18.5204, lng: 73.8567 }}
                            height='400px'
                            zoom={16}
                            FetchAddress={this.FetchAddress}
                          />
                        </MapModal>
                      </div>
                    </div>
                  </li>
                </ul>
                <div>
                  <div className='field-holder text-center'>
                    <div className='payment-holder input-button-loader'>
                      <Link
                        to='#'
                        className='btn btn-black'
                        onClick={this.onSubmitHandler.bind(this)}
                      >
                        Save Changes
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  restaurant: state.restaurant.restaurant_profile,
});

export default connect(mapStateToProps)(RestaurantProfile);
