import React,{Component} from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {  GlobalAgroProductListing } from './../../store/actions/agroActions';
import Pagination from "./../../store/helpers/Pagination";
import { bucketPath } from './../../store/helpers/common';
import { addAgroCart,minusAgroCart,removeAgroCart,AgroProductModelOpen } from './../../store/actions/AgroProductCartActions'

class ProductListing extends Component {
  constructor(props) {
    super(props);
  this.state = {  pageOfItems: [], currentPage: 1, agrocartItems:[],  };
}

  componentWillMount(){
      let ID = this.props.id
      this.props.dispatch(GlobalAgroProductListing({uniqueID:ID}))
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

  UNSAFE_componentWillReceiveProps(newProps) {
    let agro_product_cart = newProps.agro_product_cart ?? [];
      this.setState({ agrocartItems: agro_product_cart });
    }

  onRemoveCart = (value) =>{
    this.props.dispatch(removeAgroCart(value,this.props.agro_product_cart))
  }

  onAddCart = (value) => {
    this.props.dispatch(addAgroCart(value,this.state));
  };

  onMinusCart = (value) => {
    this.props.dispatch(minusAgroCart(value,this.state));
  };



  render(){

    const {global_agro_product,agro_product_cart} = this.props 
    const {pageOfItems} = this.state
    return (
          <div className="menu-itam-holder">
            <div id="menu-item-list-6272" className="menu-itam-list agro-menu-itam-list">
            <ul className="vistor-main-ul">
            {pageOfItems instanceof Array && pageOfItems.map((value, index) => {
               let arrayFilter =
               agro_product_cart instanceof Array &&
               agro_product_cart.findIndex(
                 (obj) =>
                   obj.uniqueID === value.uniquekey
               );
              return (
              <li key={`product_${index}`}>
                <div className="row">
                  <div className="image-holder">
                    <Link to="#" onClick={() =>this.props.dispatch(AgroProductModelOpen({uniquekey:value.uniquekey,model_open:true,agroID:this.props.id}))}>
                      <img
                          src={
                            value.image
                              ? `${bucketPath}${value.image}`
                              : `/assets/extra-images/logo1.png`
                          }
                          alt={value.name}
                        />
                    </Link>
                  </div>
                  <div className="col-md-7 col-xs-7">
                      <h3 onClick={() =>this.props.dispatch(AgroProductModelOpen({uniquekey:value.uniquekey,model_open:true,agroID:this.props.id}))}>{value.name}</h3>
                      <p>{value.description}</p>
                  </div>
                  <div className="col-md-3 col-xs-3 text-right agro-product-pricediv">
                      <span className="price">{`₹ ${value.price}`}</span>
                      <div className="price-holder">
                        {agro_product_cart instanceof Array && arrayFilter !== -1 ? (
                            <Link
                              to='#'
                              className='restaurant-add-menu-btn restaurant-minus-menu-btn-53'
                              onClick={(e) =>
                                this.onMinusCart(value)
                              }
                            >
                              <i className='icon-minus text-color'></i>
                            </Link>
                            ):(
                            <Link
                              to='#'
                              className='restaurant-add-menu-btn restaurant-add-menu-btn-53'
                              onClick={(e) =>
                                this.onAddCart(value)
                              }
                            >
                              <i className='icon-plus4 text-color'></i>
                            </Link>
                          )}
                          {agro_product_cart instanceof Array && arrayFilter !== -1
                            ? `${agro_product_cart[arrayFilter].count}`
                            : ''}
                          {agro_product_cart instanceof Array &&
                            arrayFilter !== -1 && (
                              <Link
                                to='#'
                                className='restaurant-add-menu-btn restaurant-add-menu-btn-53'
                                onClick={(e) =>
                                  this.onAddCart(value)
                                }
                              >
                                <i className='icon-plus4 text-color margin-15'></i>
                              </Link>
                            )}
                      </div>
                      {/* <a class="book-now btn" href="#">Buy</a> */}
                  </div>
                </div>
              </li>
              )})}
               {global_agro_product.length === 0 && <h4 className="text-center">Agro Products not found</h4>}
            </ul>
            {global_agro_product instanceof Array && (
							<Pagination
								pageSize={10}
								items={global_agro_product}
								onChangePage={this.onChangePage}
							/>
						)}
          </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => ({
  global_agro_product:state.agro.global_agro_product,
  agro_product_cart:state.AgroCart.agro_product_cart,
});

export default connect(mapStateToProps)(ProductListing);
