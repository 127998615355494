import * as types from "../types/types";

const initalState = {
    force_condiment_modal_open_data:{},
    condiment_review_list:[]
}

const CondimentReducer = (state = initalState, action) => {
    switch (action.type) {
            case types.FORCE_CONDIMENT_MODEL_OPEN:
                return {
                    ...state,
                    force_condiment_modal_open_data: action.payload
                }
                case types.CONDIMENTS_REVIEW_LISTING:
                    return {
                        ...state,
                        condiment_review_list: action.payload
                    }

        default:
            return state
    }
}

export default CondimentReducer;
