import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {RestaurantCategoryListing} from './../../../store/actions/restaurantActions'
import Pagination from "./../../../store/helpers/Pagination";
import {connect} from 'react-redux'


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};



class DraggableCell extends Component {
  constructor(props) {
    super(props);
    this.state = {pageOfItems:[],currentPage:1};
  }

  componentDidMount(){
    this.props.dispatch(RestaurantCategoryListing());
  }

  onDragEnd = (result) => { 
    if (!result.destination) {
      return;
    }

    const pageOfItems = reorder(this.state.pageOfItems,result.source.index,result.destination.index);

    this.setState({
        pageOfItems
    });
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
      const {category_listing} = this.props
    return (
        <>
      <DragDropContext onDragEnd={e=>this.onDragEnd(e)}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} >
              {this.state.pageOfItems.map((item, index) => (
                <Draggable key={`key_${index}`} draggableId={item.priority.toString()} index={(index)}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      {item.name}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {category_listing instanceof Array && (
          <Pagination
            pageSize={10}
            items={category_listing}
            onChangePage={this.onChangePage}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = state => ({
    category_listing:state.restaurant.category_listing
})
export default connect(mapStateToProps)(DraggableCell);