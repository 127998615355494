import React, { Component } from 'react';
import SearchLocationInput from './searchLocationInput'
class Home extends Component {

	abc = (xyz) => {
		console.log('i m called',xyz);
	}
	render() {


		return(
				<SearchLocationInput
				/>
		);
	}
}

export default Home;

