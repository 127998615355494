import { BaseUrl } from '../helpers/common';
import * as actionTypes from '../types/types';
import { handleResponse,queryResponse } from '../helpers/userServices';
import swal from 'sweetalert';
import {ShowLoader,ShowAlert} from './staticActions'

export function profileInfo() {
    const uniqueID =  localStorage.getItem("data") ? `${JSON.parse(localStorage.getItem("data")).uniqueID}` : "";
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/user/profile`, {
            method: 'POST',
            body:JSON.stringify({uniqueID:uniqueID}),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.FETCH_USER_PROFILE, payload: data.data })
            }
        }).catch(error=>{
            dispatch(ShowLoader(false));
        });
    }
}


export function UserProfileUpdate(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        let formData = new FormData();
        formData.append("firstname", data.firstname);
        formData.append("lastname", data.lastname);
        formData.append("email", data.email);
        formData.append("phone", data.phone);
        formData.append("country", data.country);
        formData.append("address", data.address);
        formData.append("location", data.location);
        formData.append("longitude", data.longitude);
        formData.append("latitude", data.latitude);
        formData.append("zipcode", data.zipcode);
        formData.append("state", data.state);
        formData.append("city", data.city);
        formData.append("prefix", data.prefix);
        formData.append("correspondence_address", JSON.stringify(data.correspondence_address));
        const request = new Request(`${BaseUrl}/api/user/update`, {
            method: 'POST',
            body: formData,
            headers: new Headers({'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                swal({title: "Success!",text: "User Profile Updated successfully!",icon: "success"});
                return 1;
            } else {
                swal({title: "Error!",text: data.ResponseText,icon: "error"});
                return 0;
            }
        }).catch(error=>{
            dispatch(ShowLoader(false));
        });;
    }
}

export function UserOrderList() {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/order/list`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json','X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.ORDER_LIST, payload: data.data })
                return 1;
            } else {
                return 0;
            }
        }).catch(error=>{
            dispatch(ShowLoader(false));
        });;
    }
}

export function SendOtp(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/send-otp`, {
            method: 'POST',
            body:JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json'})
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                sessionStorage.setItem('request_id',data.request_id);
                return {code:1,ResponseText:data.ResponseText}
            } else {
                swal({title: "Error!",text: data.ResponseText,icon: "error"});
                return {code:0,ResponseText:data.ResponseText}
            }
        }).catch(error=>{
            swal({title: "Error!",text: error.ResponseText??"Error Occured",icon: "error"});
            dispatch(ShowLoader(false));
        });;
    }
}

export function VerifyOtp(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/sign-up`, {
            method: 'POST',
            body:JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json'})
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                sessionStorage.removeItem('request_id');
                localStorage.setItem('data',JSON.stringify(data.data));
                localStorage.setItem('token',data.token);
                localStorage.setItem('type','U');
                dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));
                return {code:1,ResponseText:data.ResponseText}
            } else {
                swal({title: "Error!",text: data.ResponseText,icon: "error"});
                return {code:0,ResponseText:data.ResponseText}
            }
        }).catch(error=>{
            swal({title: "Error!",text: error.ResponseText??"Error Occured",icon: "error"});
            dispatch(ShowLoader(false));
        });;
    }
}

export function VerifyAgroOtp(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/signup`, {
            method: 'POST',
            body:JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json'})
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                sessionStorage.removeItem('request_id');
                localStorage.setItem('data',JSON.stringify(data.data));
                localStorage.setItem('token',data.token);
                localStorage.setItem('type','AG');
                dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));
                return {code:1,ResponseText:data.ResponseText}
            } else {
                swal({title: "Error!",text: data.ResponseText,icon: "error"});
                return {code:0,ResponseText:data.ResponseText}
            }
        }).catch(error=>{
            swal({title: "Error!",text: error.ResponseText??"Error Occured",icon: "error"});
            dispatch(ShowLoader(false));
        });;
    }
}

export function VerifyFoodOtp(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/foodcenter-signup`, {
            method: 'POST',
            body:JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json'})
        });
        return fetch(request).then(queryResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                sessionStorage.removeItem('request_id');
                localStorage.setItem('data',JSON.stringify(data.data));
                localStorage.setItem('token',data.token);
                localStorage.setItem('type','FC');
                dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));
                return {code:1,ResponseText:data.ResponseText}
            } else {
                swal({title: "Error!",text: data.ResponseText,icon: "error"});
                return {code:0,ResponseText:data.ResponseText}
            }
        }).catch(error=>{
            swal({title: "Error!",text: error.ResponseText??"Error Occured",icon: "error"});
            dispatch(ShowLoader(false));
        });;
    }
}

export function UserPaymentList() {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${BaseUrl}/api/stripe/user-payment-list`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json','X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === '1') {
                dispatch({ type: actionTypes.PAYMENT_LIST, payload: data.data })
                return 1;
            } else {
                return 0;
            }
        }).catch(error=>{
            dispatch(ShowLoader(false));
        });;
    }
}
