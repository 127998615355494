import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { connect } from 'react-redux'; 
import {RestaurantListing as RestaurantListings} from './../../store/actions/restaurantActions'
import {DeleteAdminRecord } from './../../store/actions/adminActions'
import Pagination from './../../store/helpers/Pagination'
import {bucketPath} from './../../store/helpers/common'
import moment from 'moment'
import Modal from './../../modules/Html/Modal'
import swal from 'sweetalert';
const avatarCss =  {
    verticalAlign: "middle",
    width: "35px",
    height: "35px",
    borderRadius: "50%",
}

 class RestaurantListing extends Component {

  constructor(props){
    super(props);
    this.state = {pageOfItems:[],currentPage:1,open:false,viewData:[]}
}

componentDidMount(){
    this.props.dispatch(RestaurantListings())
}

onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page,open:false });
}
CloseModal=()=>{
    this.setState({open:false,viewData:[]})
}

DeleteRestaurant = (uniqueID) => {
    let data={type:'restaurant',uniqueID:uniqueID}
    swal({
        title: "Are you sure you want to delete this Restaurant?",
        buttons: true,
        dangerMode: true,
    }).then((willDelete) => {
        if (willDelete) {
            this.props.dispatch(DeleteAdminRecord(data));
        } else { 
            console.log("Record is Safe");
        }
    });
};

render() {

    const {pageOfItems,currentPage,open,viewData} = this.state
    const {restaurant_listing} = this.props 
    return (
        <div className="col-lg-10 col-md-9 col-sm-12 col-xs-12">
            <div className="user-dashboard loader-holder">
                <div className="user-holder">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="row">
                            <div className="element-title has-border right-filters-row">
                                <h5>Restaurant List</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="user-orders-list">
                            <div className="table-responsive">
                                    <ul className="table-generic table-generic-temp">
                                        <li className="order-heading-titles">
                                            <div>Sr No</div>
                                            <div>Name</div>
                                            <div>Date</div>
                                            <div>Address</div>
                                            <div>Profile</div>
                                            <div>Action</div>

                                        </li>
                                        {pageOfItems instanceof Array &&
                                            pageOfItems.map((value, index) => {
                                                return (
                                                    <li className="order-heading-titles" key={`menu_${index}`}>
                                                        <div>{(index + 1) + ((currentPage - 1) * 10)}</div>
                                                        <div>
                                                            {`${value.restaurantsDetails.restaurant_name}`}
                                                        </div>
                                                        <div>{moment(value.creationdate).format("lll")}</div>
                                                        <div>{value.restaurantsDetails.location? value.restaurantsDetails.location: "N/A"}</div>
                                                        <div>
                                                            {value.restaurantsDetails.profile_pic && <img
                                                                className="avatar"
                                                                src={
                                                                    value.restaurantsDetails.profile_pic
                                                                    ? `${bucketPath}${value.restaurantsDetails.profile_pic}`
                                                                    : "assets/extra-images/listing-logo18.png"
                                                                }
                                                                alt="dp"
                                                                style={avatarCss}
                                                                />
                                                            }
                                                        </div>
                                                        <div>
                                                            <Link 
                                                                className="pull-left" 
                                                                to="#" 
                                                                onClick={e => this.setState({open:true,viewData:value.restaurantsDetails})}
                                                            >
                                                                <i className="fa fa-eye"></i>
                                                            </Link>&nbsp;&nbsp;
                                                            <Link
                                                                data-toggle="modal"
                                                                to="#" 
                                                                onClick={e => this.DeleteRestaurant(value.restaurantsDetails.uniqueID)}
                                                            >
                                                                <i className="fa fa-trash alert-danger"></i> 
                                                            </Link>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        {pageOfItems instanceof Array && pageOfItems.length===0 &&
                                            <li className="order-heading-titles">
                                                       <p>No data available</p>
                                            </li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {restaurant_listing instanceof Array && (
                        <Pagination
                            pageSize={10}
                            items={restaurant_listing}
                            onChangePage={this.onChangePage}
                        />
                    )}
                </div>
            </div>
 {/*  NEXT IS VIEW MODAL */}
 <Modal open={open} size={"xl"}
                   title={"Restaurant Detail"} CloseModal={this.CloseModal} >
                   
                        <div role="tabpanel" className="tab-pane active">
                            <div id="result_296069" className="status-message"></div>
                            <form method="post" className="wp-user-form demo_test">
                            <ul className="order-detail-options">
                         
                          <li className="unique_Id">
                            <strong><h6>Id:</h6></strong>
                            <span><b> {viewData.uniqueID}</b></span>
                          </li>
                          <li className="name">
                            <strong><h6>Name:</h6></strong>
                            <span><b>{viewData.restaurant_name}</b></span>
                          </li>
                          <li className="restaurant_email">
                            <strong><h6>Email:</h6></strong>
                            <span><b>{viewData.email}</b></span>
                          </li>
                        
                          <li className="created-date">
                            <strong><h6> Date:</h6> </strong>
                            <span>
                         <b>{viewData.creationdate?moment(viewData.creationdate).format('lll'):'NA'}</b>
                            </span>
                          </li>
                          <li className="grand-total">
                            <strong><h6>Address:</h6></strong>
                            <span><b> {viewData.location?viewData.location:'NA'}</b></span>
                          </li>
                          <li className="zip_code">
                            <strong><h6>Zipcode:</h6></strong>
                            <span><b> {viewData.zipcode?viewData.zipcode:'NA'}</b></span>
                          </li>

                        </ul>
                                <div className="side-by-side select-icon clearfix">
                                    <div className="select-holder"></div>
                                </div>
                            </form>
                        </div>
                    
                </Modal>
        </div>
    )
}
}

const mapStateToProps = (state) => ({
    restaurant_listing: state.restaurant.restaurant_listing,
});

export default connect(mapStateToProps)(RestaurantListing);
