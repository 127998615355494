import React, { Component } from "react";

const titles = {user_login:"User Login",user_signup:'Sign Up User',res_login:"Mamas Login",res_signup:"Sign Up Restaurant",forgot_password:"Forgot Password",login_phone_agro:'Login Via Phone', login_phone_user: 'Login Via Phone',agro_login:'Agro Center Login',food_login:'Food Center Login',agro_signup:"Sign Up Agro Center",user_otp:"Enter OTP for User",agro_otp:"Enter OTP for Agro Center",change_password:"Change Password",food_center_signup:"Sign Up Food Center",food_center_otp:"Enter OTP for Food Center"};
class ModalLayout extends Component {
  render() {
    const {modalType} = this.props
    return (
      <div
        className="modal fade"
        id="user-actions"
        aria-labelledby="myModalLabel"
      >
        <div className="modal-dialog">
          <div className="login-form">
            <div className="modal-content">
              <div className="tab-content">
                <div id="user-login-tab" className="tab-pane fade in active">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      onClick={this.props.closeModal}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h5 className="modal-title foodbakery-dev-login-main-title">
                      {titles[modalType]}
                    </h5>
                  </div>
                </div>
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalLayout;
