import React, { Component } from "react";
import { bucketPath } from "../../store/helpers/common";
import * as RestaurantAction from "../../store/actions/restaurantActions";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import {foodListing,bookFoodCenterTable} from './../../store/actions/foodActions';
import Modals from './../../modules/Html/Modals';
import ModalHeader from './../../modules/Html/ModalHeader';
import ModalBody from './../../modules/Html/ModalBody';
import SimpleReactvalidator from 'simple-react-validator'
import Banner from "../Banner";
import CenterSidebar from "../CenterSidebar";
import swal from 'sweetalert';

class FoodListing extends Component {
  constructor(props) {
    super(props);
    this.state = { pageOfItems: [],
       currentPage: 1,
       open:false,
       first_name:'',
       last_name:'',
       email:'',
       number_of_guest:'',
       booking_date:'',
       booking_time:'',
       instructions:'',
       foodcenterID:''
     };
    this.favouriteList = [];
    this.validator = new SimpleReactvalidator();
  }

  componentDidMount() {
      this.props.dispatch(foodListing())
  }

  openTableBookModal = (value) => {
    const getUserIdentity = localStorage.getItem('token');
    const getUserIdentityType = localStorage.getItem('type');
    if (!getUserIdentity || getUserIdentityType !== "U") {
      swal({
        text: "You need to login!",
        icon: "info",
        buttons: true,
        dangerMode: true,
      })
        .then((yesDo) => {
          if (yesDo) {
            this.props.dispatch(RestaurantAction.ForceloginClick(true))
          } else {
            console.log("Record is Safe");
          }
        });
      return false;
    }
    this.setState({
      open: true,
      foodcenterID:value
    })
  }

  openModal = (value) =>{
    this.setState({
      open: true,
      foodcenterID:value
    })
  }

  CloseModal = e => {
    this.setState({
      open: false,
      foodcenterID:''
    })
  }

  onChangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  bookTableHandler = async (e) => {
    const {first_name,last_name,email,number_of_guest,booking_date,booking_time,instructions,foodcenterID} = this.state
    const data = {
      first_name:first_name,
      last_name:last_name,
      email:email,
      number_of_guest:number_of_guest,
      booking_date:booking_date,
      booking_time:booking_time,
      instruction:instructions,
      foodcenterID:foodcenterID
    }
    if (this.validator.allValid()) {
      let response = await this.props.dispatch(bookFoodCenterTable(data));
      if (response === 1) {
        this.CloseModal();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate()
    }
  }

  render() {
    const {first_name,last_name,email,number_of_guest,booking_date,booking_time,instructions} = this.state
    return (
      <>
      <div className="main-section">
        <Banner name="Food Center" description={`${this.props.food_listing && this.props.food_listing.data && this.props.food_listing.data.length > 0 ? this.props.food_listing.data.length : 0} Food Center's Found`}/>
        <div
          className="page-section"
          style={{
            marginTop: "0px",
            paddingTop: "0px",
            paddingBottom: "10px",
            marginBottom: "0px",
          }}
        >
          <div className="container">
					<div className="row">
						<div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 agro-listing-div">
							<div className="row">
								{/* <aside className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
									<div className="filter-wrapper">
										<div className="foodbakery-filters listing-filter">
											<div className="filter-holder panel-default sort-by-left">
												<div className="filter-heading">
                            <h6><i className="icon-location-pin2"></i>Popular Area</h6>
												</div>
												<ul>
                            <li><a href="#" className="sort-by-alphabetical">Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016</a>
													</li>
                            <li><a href="#" className="sort-by-ratings">Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016</a></li>
                            <li><a href="#" className="sort-by-minimum_order_value">Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016</a>
													</li>
												</ul>
											</div>
                      </div>
									</div>
								</aside> */}
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {
                  // <div className="listing-sorting-holder">
                  //   <div className="row">
                  //     <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  //       <h4>{`${this.props.food_listing && this.props.food_listing.data && this.props.food_listing.data.length > 0 ? this.props.food_listing.data.length : 0} Food Center's Found`}
                  //         <span className="pull-right list-grid-ico">
                  //       </span></h4>
                  //     </div>
                  //   </div>
                  // </div>
                }

									<div className="listing simple">
										<div className="row">
                    {this.props.food_listing && this.props.food_listing.data && this.props.food_listing.data.length > 0 ?
                      this.props.food_listing.data.map((item,index) =>{
                        const building = item.building !== null && item.building !== "" ? item.building + "," : "";
                        const city = item.city !== null && item.city !== ""  ? item.city + "," : "";
                        const state = item.state !== null && item.state !== "" ? item.state + "," : "";
                        const country = item.country !== null && item.country !== "" ? item.country  : "";
                        //const pincode = item.pincode !== null && item.pincode !== "" ? item.pincode : "";
                      return (
                        <div className="col-md-6  col-sm-6 col-xs-12 agro-outer-bx" key={index}>
                          <div className="agro-inner-bx">
                            <div className="img-holder">
                              <figure>
                                <Link to="#"><img src={item.profile_image && item.profile_image != null ? bucketPath + item.profile_image : "" } className="img-list wp-post-image" alt="" /></Link>
                              </figure>
                              {item.foodcenter_status === "" || item.foodcenter_status === "CLOSED" ? (
                                <span className="restaurant-status close"><em className="bookmarkRibbon"></em>Close</span>
                              ):(
                                <span className="restaurant-status open"><em className="bookmarkRibbon"></em>Open</span>
                              )}

                            </div>
                            <div className="text-holder">
													<div className="post-title">
														<h5>
                                <Link to={`/food-info/${item.uniqueID}/${item.foodcenterID}`}>{item.firstname + " " + item.lastname}</Link>
															{/* <span className="sponsored text-color">Sponsored</span> */}
														</h5>
													</div>
													{/* <span className="post-categories"><span>Type of food :
														</span> Apple
														Juice</span> */}
                            <div className="delivery-potions">
                              <div className="post-time">
                                <i className="icon-location-pin"></i>
                              </div>
                              <span>{building + city + state + country}</span>
                            </div>

                              <div className="delivery-potions phone-num">
                                <div className="post-time">
                                  <i className="icon-phone4"></i>
                                </div>
                                <span>{item.restaurant_phone}</span>
                              </div>


													<div className="list-rating">
														<div className="rating-star">
															<span className="rating-box" style={{width: '100%'}}></span>
														</div>
														<span className="reviews">(1)</span>
													</div>
                              <Link to="#" className="book-now" onClick={() => this.openTableBookModal(item.foodcenterID)}>Book Now</Link>
												</div>

                          {/* <div class="no-padding col-md-12 book-your-visit-btn"><Link  to="#" className="book-now" onClick={() =>this.openTableBookModal(item.foodcenterID)}>Book Now</Link></div> */}


											</div>
                        </div>
                      )}):null
                    }
										</div>
									</div>
									<div className="row">
										<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
											<div className="page-nation">
												<ul className="pagination pagination-large">
													<li className="disabled"><span>Prev</span></li>
													<li className="active"><span><Link to="#" className="page-numbers active">1</Link></span></li>
													<li><Link to="#">2</Link></li>
													<li><Link to="#">Next</Link></li>
												</ul>
											</div>
										</div>
									</div>
								</div>

              </div>
						</div>
            <CenterSidebar center="food"/>
					</div>
				</div>
        </div>
      </div>
      <Modals open={this.state.open} className="modal-size">
        <ModalHeader CloseModal={this.CloseModal}  title="Book Table"/>
        <ModalBody>
          <div className="order-detail-inner book-table-area">
            <div className="reviews-holder">
              <div className="add-new-review-holder  add-new-review-2067">
                <div className="row">
                  <div className="foodbakery-add-review-data">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="field-holder">
                      <label>First Name *</label>
                        <input
                          type="text"
                          placeholder="First Name *"
                          value={first_name}
                          name="first_name"
                          onChange={e => this.onChangeHandler(e)}
                          className="margin-bottom-0"
                        />
                        {this.validator.message('First Name', first_name, 'required')}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="field-holder">
                      <label>Last Name *</label>
                        <input
                          type="text"
                          placeholder="Last Name *"
                          value={last_name}
                          name="last_name"
                          onChange={e => this.onChangeHandler(e)}
                          className="margin-bottom-0"
                        />
                        {this.validator.message('Last Name', last_name, 'required')}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="field-holder">
                      <label>Email *</label>
                        <input
                          type="email"
                          placeholder="Email *"
                          value={email}
                          name="email"
                          onChange={e => this.onChangeHandler(e)}
                          className="margin-bottom-0"
                        />
                        {this.validator.message('Email', email, 'required')}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="field-holder">
                      <label>Number of Guest *</label>
                        <input
                          type="text"
                          placeholder="Number of Guest *"
                          value={number_of_guest}
                          name="number_of_guest"
                          onChange={e => this.onChangeHandler(e)}
                          className="margin-bottom-0"
                        />
                        {this.validator.message('Number of Guest', number_of_guest, 'required')}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="field-holder ">
                      <label>Booking Date *</label>
                        <input
                          type="date"
                          placeholder="Booking Date *"
                          value={booking_date}
                          name="booking_date"
                          onChange={e => this.onChangeHandler(e)}
                        />
                        {this.validator.message('Booking Date', booking_date, 'required')}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="field-holder">
                      <label>Booking Time *</label>
                        <input
                          type="time"
                          placeholder="Booking Time *"
                          value={booking_time}
                          name="booking_time"
                          onChange={e => this.onChangeHandler(e)}
                        />
                        {this.validator.message('Booking Time', booking_time, 'required')}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="field-holder ">
                      <label>Instructions *</label>
                        <textarea
                          placeholder="I need to book a special table for my friends. Make it some special.!"
                          cols="30"
                          rows="3"
                          maxLength="500"
                          name="instructions"
                          value={instructions}
                          onChange={e => this.onChangeHandler(e)}
                          className="margin-bottom-0"
                        ></textarea>
                        {this.validator.message('Instructions', instructions, 'required|min:10')}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-element message-length">
                        <span>Min characters: 10</span>
                        <span>Max characters: 500</span>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-element">
                        <input
                          type="button"
                          defaultValue="Book Your Table"
                          onClick={e => this.bookTableHandler(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </ModalBody>
      </Modals>
      </>
    );
  }
}

const mapStateToProps = state => ({
    food_listing:state.food.food_listing
})

export default connect(mapStateToProps)(FoodListing);
