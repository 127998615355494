import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Pagination from "./../../store/helpers/Pagination";
import Modals from './../../modules/Html/Modals';
import ModalHeader from './../../modules/Html/ModalHeader';
import ModalBody from './../../modules/Html/ModalBody';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import {BaseUrl,bucketPath } from "./../../store/helpers/common";
import {
    CategoryListing,
    SubCategoryListing,
    AddProduct,
    ProductListing,
    DeleteProduct,
    EditProduct,
    ProductImagesListing,
    DeleteProductImage
} from './../../store/actions/adminActions';
import {ShowLoader,ShowAlert} from "./../../store/actions/staticActions";


const axios = require("axios");

class RestaurantProducts extends Component {
	constructor(props) {
        super(props);
        this.formValidator = new SimpleReactValidator();
		this.state = { pageOfItems: [], currentPage: 1 ,open:false,isEdit:false,modalType:'productDetails'};
	}

    componentDidMount(){
      this.props.dispatch(CategoryListing({type:'list',Newtype:"Restaurant"}))
      this.props.dispatch(SubCategoryListing({type:"Restaurant"}))
      this.props.dispatch(ProductListing({type:"Restaurant"}))
    }



	onChangePage = (pageOfItems, page) => {
		this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    setOpen = (value) => {
        this.setState({
            open: false,
            isEdit: false
        });
    }

    onSubmitProduct = async (e) => {
        const {name,category,subcategory,price,quantity,status,description,product_highlight,id,isEdit,tax} = this.state
        if (this.formValidator.allValid()) {
            if(isEdit){
                const data = {id,name,categoryID:category,sub_categoryID:subcategory,price,quantity,status,description,product_highlight,tax,type:"Restaurant"}
                let response = await this.props.dispatch(EditProduct(data))
                if(response === 1){
                    this.props.dispatch(ProductImagesListing({id:id,type:"Restaurant"}));
                    this.setState({
                        modalType:'productImages'
                    })
                }
            }else{
                const data = {name,categoryID:category,sub_categoryID:subcategory,price,quantity,status,description,product_highlight,tax,type:"Restaurant"}
                let response = await this.props.dispatch(AddProduct(data))
                console.log("response",response)
                if(response === 1){
                    this.setState({
                        isEdit:false,
                        modalType:'productImages'
                    })
                }
            }

        } else {
            this.formValidator.showMessages();
            this.forceUpdate();
          }
          e.preventDefault();
    }

    EditProduct = (uniquekey) => {
      const mama_token = localStorage.getItem('token');
        const request = new Request(`${BaseUrl}/api/view-products`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-Auth-Token": `${mama_token}`,
            },
            body: JSON.stringify({uniquekey}),
        });
        return fetch(request)
            .then((res) => res.json())
            .then((res) => {
                const data = res.data;
                this.setState({
                    id: data.uniquekey,
                    name:data.name,
                    category:data.category,
                    subcategory:data.sub_category,
                    quantity:data.quantity,
                    price:data.price,
                    status:data.status,
                    tax:data.tax,
                    description:data.description,
                    product_highlight:data.product_highlight,
                    isEdit: true,
                    modalType:'productDetails',
                    open: true,
                });
            });
    };


    DeleteProduct = (uniquekey) => {
      console.log("uniquekey",uniquekey)
      const data = {uniquekey:uniquekey,type:"Restaurant"}
        swal({
            title: "Are you sure you want to delete this Product?",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.dispatch(DeleteProduct(data));
            } else {
                console.log("you clicked on false");
            }
        });
    };


    uploadImage = (e) => {
        let localID=  localStorage.getItem('product');
        let ID = this.state.isEdit ? this.state.id : localID
        this.props.dispatch(ShowLoader(true));
        let request;
        let formData = new FormData();
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
        formData.append(`file[${i}]`, files[i]);
        }
        formData.append('id',ID);
        const mama_token = localStorage.getItem('token');
        request = {
          method: "post",
          url: `${BaseUrl}/api/upload-product-images`,
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Auth-Token": `${mama_token}`,
          },
          data: formData,
        };
        axios(request)
          .then((res) => {
            if (res.data.ResponseCode === "1") {
              //let response = res.data.imagename;
                this.props.dispatch(ProductImagesListing({id:ID,type:"Restaurant"}));
                this.props.dispatch(ShowLoader(false));
                this.props.dispatch(ShowAlert({ Class: "alert-success", Message: res.data.ResponseText, Timeout: 5000 }));
            }
          })
          .catch((error) => {
            this.props.dispatch(ShowLoader(false));
            console.log(error);
          });
      };

      DeleteImageProduct = (e,value) => {
        const {isEdit,id}=this.state
        let data = {value,isEdit,id,type:"Restaurant"}
        swal({
            title: "Are you sure you want to delete this Product Image?",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.dispatch(DeleteProductImage(data));
            } else {
                console.log("you clicked on false");
            }
        });
    };


	render() {
    const {open,modalType,pageOfItems,isEdit} = this.state
    const {category_listing,subcategory_listing,product_listing,product_image} = this.props
		return (
            <>
			<div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
				<div className="user-dashboard">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="element-title has-border right-filters-row">
								<h5>My Products</h5>
                                <Link
                                    to="#"
                                    className="add-menu-item collapsed margin-bottom-10 btn-black"
                                    onClick={e => this.setState({
                                      open: true,
                                      modalType:'productDetails',
                                      category:'',
                                      subcategory:'',
                                      name:'',
                                      price:"",
                                      quantity:'',
                                      tax:'',
                                      status:'',
                                      product_highlight:'',
                                      description:''
                                     })}
                                >
                                    Add Product
								</Link>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="user-orders-list">
								<div className="table-responsive">
                                    <ul className="table-generic table-generic-temp">
                                        <li className="order-heading-titles">
                                            <div>Product ID</div>
                                            <div>Name</div>
                                            <div>Category</div>
                                            <div>Sub Category</div>
                                            <div>Price</div>
                                            <div>Quantity</div>
                                            <div>Action</div>
                                        </li>
                                        {pageOfItems instanceof Array && pageOfItems.filter(filteritem =>filteritem.type === "R").map((value, index) => {

                                            return (
                                            <li className="order-heading-titles" key={`agro_product_${index}`}>
                                                <div>{value.uniquekey}</div>
                                                <div>{value.name}</div>
                                                <div>{value.category_name}</div>
                                                <div>{value.sub_category_name}</div>
                                                <div>{`₹ ${value.price}`}</div>
                                                <div>{value.quantity}</div>
                                                <div>
                                                    <Link
                                                        className="action-ico edit-ico"
                                                        to="#"
                                                        onClick={() =>this.EditProduct(value.uniquekey)}
                                                    >
                                                        <i className="fa fa-edit"></i>
                                                    </Link>&nbsp;&nbsp;
                                                    <Link
                                                        className="action-ico del-ico"
                                                        to="#"
                                                        onClick={() => this.DeleteProduct(value.uniquekey)}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Link>

                                                </div>
                                            </li>
                                        )})}
                                    </ul>
								</div>
							</div>
						</div>
						{product_listing instanceof Array && (
							<Pagination
								pageSize={10}
								items={product_listing}
								onChangePage={this.onChangePage}
							/>
						)}
					</div>
				</div>
			</div>
      <Modals open={open}  >
          <ModalHeader title= {isEdit ? 'Edit Product' : 'Add Product' }  CloseModal={this.setOpen}/>
          <ModalBody>
              {modalType === 'productDetails' && <form>
                  <div className="row form-row label-star product-add">
                      <div className="col-md-12">
                          <div className="form-group">
                              <label>Name</label>
                              <input
                                  type='text'
                                  className=""
                                  name="name"
                                  value={this.state.name}
                                  onChange={e=>this.setState({[e.target.name]:e.target.value})}
                              />
                              {this.formValidator.message( "Name", this.state.name,"required|string|max:50")}
                          </div>
                          <div className="form-group">
                              <label>Category</label>
                              <select
                                  type="text"
                                  className=""
                                  name="category"
                                  value={this.state.category}
                                  onChange={e=>this.setState({[e.target.name]:e.target.value})}
                              >
                                  <option value="">Select Category</option>
                                  {category_listing && category_listing.map((value,index)=>{
                                      return (
                                          <option
                                              key={`category_${index}`}
                                              value={value.uniquekey}
                                          >
                                              {value.name}
                                          </option>
                                      )
                                  })}
                              </select>
                              {this.formValidator.message( "Category", this.state.category,"required")}
                          </div>
                          <div className="form-group">
                              <label>Sub Category</label>
                              <select
                                  type="text"
                                  className=""
                                  name="subcategory"
                                  value={this.state.subcategory}
                                  onChange={e=>this.setState({[e.target.name]:e.target.value})}
                              >
                                  <option value="">Select Sub Category</option>
                                  {subcategory_listing && subcategory_listing.map((value,index)=>{
                                      return (
                                          <option
                                              key={`subcategory_${index}`}
                                              value={value.uniquekey}
                                          >
                                              {value.name}
                                          </option>
                                      )
                                  })}
                              </select>
                              {this.formValidator.message( "Sub Category", this.state.subcategory,"required")}
                          </div>
                          <div className="form-group">
                              <label>Price</label>
                              <div className="mb-3 admin-price-input">
                                  <span className=" price-input-text">₹</span>
                                  <input
                                      type="number"
                                      className=""
                                      name="price"
                                      value={this.state.price}
                                      onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                  />
                              </div>
                              {this.formValidator.message( "Price", this.state.price,"required")}
                          </div>
                          <div className="form-group">
                              <label>Tax</label>
                              <div className="mb-3 admin-price-input">
                                  <span className=" price-input-text">%</span>
                                  <input
                                      type="number"
                                      className=""
                                      name="tax"
                                      value={this.state.tax}
                                      onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                  />
                              </div>
                              {this.formValidator.message( "tax", this.state.tax,"required")}
                          </div>
                          <div className="form-group">
                              <label> Quantity</label>
                              <input
                                  type="text"
                                  className=""
                                  name="quantity"
                                  value={this.state.quantity}
                                  onChange={e=>this.setState({[e.target.name]:e.target.value})}
                              />
                              {this.formValidator.message( "Quantity", this.state.quantity,"required")}
                          </div>
                          <div className="form-group">
                              <label>Status</label>
                              <select
                                  type="text"
                                  className=""
                                  name="status"
                                  value={this.state.status}
                                  onChange={e=>this.setState({[e.target.name]:e.target.value})}
                              >
                                  <option value="">Select Status</option>
                                  <option value="A">Active</option>
                                  <option value="D">Inactive</option>
                              </select>
                              {this.formValidator.message( "Status", this.state.status,"required")}
                          </div>
                          <div className="form-group">
                              <label>Product Highlight</label>
                              <textarea
                                  className=""
                                  name="product_highlight"
                                  value={this.state.product_highlight}
                                  onChange={e=>this.setState({[e.target.name]:e.target.value})}
                              >
                              </textarea>
                              {this.formValidator.message( "Product Highlight", this.state.product_highlight,"required")}
                          </div>
                          <div className="form-group">
                              <label>Description</label>
                              <textarea
                                  className=""
                                  name="description"
                                  value={this.state.description}
                                  onChange={e=>this.setState({[e.target.name]:e.target.value})}
                              >
                              </textarea>
                              {this.formValidator.message( "Description", this.state.description,"required")}
                          </div>
                      </div>
                  </div>
                  <div className="field-holder">
                      <Link to="#" className="add-menu-item add-menu-item-list"  onClick={e => this.onSubmitProduct(e)}>Save</Link>
                  </div>
              </form> }

              {modalType === 'productImages' && <form>
                  <div className="row form-row label-star">
                      <div className="col-md-12">
                      <div className="form-group">
                                      <label>Image</label>
                                      <input
                                          type="file"
                                          className="form-control"
                                          id="product_image"
                                          name="product_image"
                                          onChange={(e) =>
                                              this.uploadImage(e)
                                          }
                                          multiple
                                          aria-describedby="inputGroupFileAddon01"
                                          accept="image/*"
                                          />
                                  </div>
                                  <div className="argoupload-img add-product-img col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                      {product_image && product_image.map((value,index) => {
                                          return (
                                              <div className="add-recepie-img-video"  key={`avatar${index}`} >
                                                  <Link to="#"  onClick={( e ) => this.DeleteImageProduct(e,value.image)}>
                                                      <i className="fas fa-trash-alt del-profile-ico"></i>
                                                  </Link>
                                                  <img
                                                      alt="User"
                                                      src={`${bucketPath}${value.image}`}
                                                  />
                                              </div>
                                          );
                                          }
                                      )}
                                  </div>
                      </div>
                  </div>
              </form> }
          </ModalBody>
      </Modals>
         </>
		);
	}
}

const mapStateToProps = (state) => ({
    category_listing:state.admin.category_listing,
    subcategory_listing:state.admin.subcategory_listing,
    product_listing:state.admin.product_listing,
    product_image:state.admin.product_image
});

export default connect(mapStateToProps)(RestaurantProducts);
