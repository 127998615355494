import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
 

class Header extends Component {
  constructor(props){
    super(props);
    this.state = {modalType:'',isLogin:false}
  }
logout=(e)=>{
  localStorage.clear()
  this.props.history.push("/admin-login")
}
  render() {
    const { isLogin } = this.state;
    return (
      <div>
        <header id="header">
          <div className="main-header">
            <div className="container">
              <div className="row">
                <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                  <div className="logo">
                    <figure>
                      <Link
                        to="#"
                        className="light-logo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <img
                          src="./../assets/extra-images/logo1.png"
                          alt="Mamachai"
                        />
                      </Link>
                    </figure>
                  </div>
                  <div className="main-location">
                    <ul>
                    </ul>
                  </div>
                </div>
                {!isLogin && (
                  <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 text-right admin-right-head-1">
                    <div className="login-option">
                    <Link to="#" onClick={(e) => this.logout(e)}>
                      <i className="fas fa-sign-out-alt"></i>
                    </Link>
                    </div>
                  </div>
                )}

                {/*  Buyer's Header */}

                {isLogin && this.storageType === "U" && (
                  <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div className="login-option">
                      <div className="user-dashboard-menu">
                        <ul>
                          <li className="user-dashboard-menu-children">
                            <Link to="#" className="">
                              <figure className="profile-image">
                                <img
                                  src="assets/extra-images/team-medium-img1.jpg"
                                  alt=""
                                />
                              </figure>
                              Dev Smith
                            </Link>
                            <ul>
                              <li>
                                <Link to="buyer-dashboard ">
                                  <i className="icon-dashboard3"></i>Dashboard
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="buyer-orders "
                                  className="btn-edit-profile"
                                >
                                  <i className="icon-add_shopping_cart"></i>My
                                  Orders
                                </Link>
                              </li>
                              <li className="active">
                                <Link to="/buyer-account-setting">
                                  <i className="icon-build"></i>Account Settings
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  onClick={(e) => this.submitHandler(e)}
                                >
                                  <i className="icon-log-out"></i>Sign out
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                {/* Restaurant's Header */}

                {isLogin && this.storageType === "R" && (
                  <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div className="login-option">
                      <div className="user-dashboard-menu">
                        <ul>
                          <li className="user-dashboard-menu-children">
                            <Link to="#" className="">
                              <figure className="profile-image">
                                <img
                                  src="assets/extra-images/listing-logo18.png"
                                  alt=""
                                />{" "}
                              </figure>
                              Tulip Mama
                            </Link>
                            <ul>
                              <li>
                                <Link to="restaurant-dashboard">
                                  <i className="icon-dashboard3"></i>Dashboard
                                </Link>
                              </li>
                              <li className="active">
                                <Link to="restaurant-restaurant">
                                  <i className="icon-building"></i>Mama Profile
                                </Link>
                              </li>
                              <li>
                                <Link to="restaurant-menu-builder">
                                  <i className="icon-menu5"></i>Menu Builder
                                </Link>
                              </li>
                              <li>
                                <Link to="restaurant-orders">
                                  <i className="icon-add_shopping_cart"></i>
                                  Orders
                                </Link>
                              </li>
                              <li>
                                <Link to="restaurant-earnings">
                                  <i className="icon-money"></i>Earnings
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  className="logout-btn"
                                  onClick={(e) => this.submitHandler(e)}
                                >
                                  <i className="icon-log-out"></i>Signout
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>

       
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  force_login_click: state.restaurant.force_login_click,
});

export default connect(mapStateToProps)(Header);
