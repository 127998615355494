import React,{Component} from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Pagination from "../../../../store/helpers/Pagination";
import { bucketPath } from '../../../../store/helpers/common';
import { forceCondimentModelOpen} from '../../../../store/actions/condimentActions'
import { addCart,minusCart,removeCart } from '../../../../store/actions/condimentCartActions'
import { CondimentListing,RestaurantListing} from '../../../../store/actions/restaurantActions'

class ProductListing extends Component {
  constructor(props) {
    super(props);
  this.state = {  pageOfItems: [], currentPage: 1, cartItems:[],  };
}

  componentDidMount(){
      this.props.dispatch(RestaurantListing({food_item:''}));
      this.props.dispatch(CondimentListing());
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

  UNSAFE_componentWillReceiveProps(newProps) {
    let condiment_cart = newProps.condiment_cart ?? [];
        console.log("condiment_cart",condiment_cart)
      this.setState({
        cartItems: condiment_cart
      });
    }


    onRemoveCart = (value) =>{
      this.props.dispatch(removeCart(value,this.props.condiment_cart))
    }

  onAddCart = (value) => {
    console.log('value',value)
    this.props.dispatch(addCart(value,this.state));
    return null;
  };

  onMinusCart = (value) => {
    this.props.dispatch(minusCart(value,this.state));
    return null;
  };



  render(){
    const {condiment,condiment_cart,restaurant_listing} = this.props

    const {pageOfItems} = this.state
    const flterUniqueIdData = restaurant_listing && restaurant_listing.length > 0 ? restaurant_listing.filter(item =>item.restaurantsDetails.uniqueID === this.props.uniqueID) : [];

    const createdId = flterUniqueIdData && flterUniqueIdData.length > 0 && flterUniqueIdData[0].restaurantsDetails ?  flterUniqueIdData[0].restaurantsDetails.restaurantID : '';
    return (
      <div id="menu1" className="tab-pane fade">
          <div className="menu-itam-holder">
            <div id="menu-item-list-6272" className="menu-itam-list agro-menu-itam-list">
            <ul className="vistor-main-ul">
            {pageOfItems instanceof Array && pageOfItems.filter(filterfirstitem =>filterfirstitem.type === "R" && filterfirstitem.created_by === createdId).map((value, index) => {
               let arrayFilter =
               condiment_cart instanceof Array &&
               condiment_cart.findIndex(
                 (obj) =>
                   obj.uniqueID === value.uniquekey
               );
              return (
              <li key={`product_${index}`}>
                <div className="row">
                  <div class="image-holder">
                    <Link to="#" onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>
                      <img
                          src={
                            value.image
                              ? `${bucketPath}${value.image}`
                              : `/assets/extra-images/logo1.png`
                          }
                          alt={value.name}
                        />
                    </Link>
                  </div>
                  <div className="col-md-7 col-xs-7">
                      <h3 onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>{value.name}</h3>
                      <p onClick={() =>this.props.dispatch(forceCondimentModelOpen({uniquekey:value.uniquekey,model_open:true}))}>{value.description}</p>
                  </div>
                  <div className="col-md-3 col-xs-3 text-right agro-product-pricediv">
                      <span className="price">{`₹ ${value.price}`}</span>
                      <div className="price-holder">
                        {condiment_cart instanceof Array && arrayFilter !== -1 ? (
                            <Link
                              to='#'
                              className='restaurant-add-menu-btn restaurant-minus-menu-btn-53'
                              onClick={(e) =>
                                this.onMinusCart(value)
                              }
                            >
                              <i className='icon-minus text-color'></i>
                            </Link>
                            ):(
                            <Link
                              to='#'
                              className='restaurant-add-menu-btn restaurant-add-menu-btn-53'
                              onClick={(e) =>
                                this.onAddCart(value)
                              }
                            >
                              <i className='icon-plus4 text-color'></i>
                            </Link>
                          )}
                          {condiment_cart instanceof Array && arrayFilter !== -1
                            ? `${condiment_cart[arrayFilter].count}`
                            : ''}
                          {condiment_cart instanceof Array &&
                            arrayFilter !== -1 && (
                              <Link
                                to='#'
                                className='restaurant-add-menu-btn restaurant-add-menu-btn-53'
                                onClick={(e) =>
                                  this.onAddCart(value)
                                }
                              >
                                <i className='icon-plus4 text-color margin-15'></i>
                              </Link>
                            )}
                      </div>
                      {/* <a class="book-now btn" href="#">Buy</a> */}
                  </div>
                </div>
              </li>
              )})}
               {pageOfItems instanceof Array && (pageOfItems.filter(filterfirstitem =>filterfirstitem.type === "R" && filterfirstitem.created_by === createdId)).length === 0 && <h4 className="text-center">Mama Products not found</h4>}
            </ul>

          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="page-nation">
              {condiment instanceof Array && (
                <Pagination
                  pageSize={10}
                  items={condiment}
                  onChangePage={this.onChangePage}
                />
              )}
              </div>
            </div>
          </div>
        </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => ({
  condiment: state.restaurant.condiment_listing,
  condiment_cart:state.condimentCart.condiment_cart,
  restaurant_listing:state.restaurant.restaurant_listing
});

export default connect(mapStateToProps)(ProductListing);
