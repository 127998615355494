import * as types from "./../types/types"; 

const initalState = {
    data: []
}


const paymentsReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_RESTAURANT_PROFILE:
            return {
                ...state,
                restaurant_profile: action.payload
            }
        default:
            return state
    }
}

export default paymentsReducer;