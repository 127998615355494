import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { UserOrderList } from "./../../store/actions/buyerActions";
import { AcceptOrder } from "./../../store/actions/restaurantActions";
import Pagination from "./../../store/helpers/Pagination";
import moment from "moment";
import Modals from "./../../modules/Html/Modals";
import ModalHeader from "./../../modules/Html/ModalHeader";
// import ModalFooter from './../../modules/Html/ModalFooter';
import ModalBody from "./../../modules/Html/ModalBody";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const OrderStatus = {
  N: "Accept Order",
  P: "Processed",
  A: "Accepted",
  R: "Rejected",
  D: "Dispatched",
  C: "Completed",
};
const OrderColor = {
  N: "#1e73be",
  P: "#1e73be",
  R: "#c33332",
  D: "#047a06",
  C: "#047a06",
  A: "#047a06",
};
const initialState = {
  pageOfItems: [],
  currentPage: 1,
  open: false,
  modal_type: "",
  order_detail: [],
  changed_status:''
};
class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.storage = JSON.parse(localStorage.getItem("data")) ?? {};
  }

  componentDidMount() {
    this.props.dispatch(UserOrderList());
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

  CloseModal = () => {
    this.setState({ open: false, modal_type: "" });
  };

  onClickHandler = (status, order_number,trigger=null) => {
    this.props.dispatch(
      AcceptOrder({ status: status, order_number: order_number })
    );
    this.setState({changed_status:status})
    if(trigger==='trigger'){
      window.$(`clickable_${order_number}`).trigger('click')
    }
  };

  render() {
    const { order_list } = this.props;
    const { pageOfItems, open, modal_type, order_detail } = this.state;
    // let new_order_details = order_detail.items ? JSON.parse(order_detail.items) : []
    return (
      <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div className="user-dashboard loader-holder">
          <div className="user-holder">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="row">
                <div className="element-title has-border right-filters-row">
                  <h5>My Orders</h5>
                  <div className="right-filters row pull-right">
                    {/* <div className="col-lg-6 col-md-6 col-xs-6">
                      <div className="input-field">
                        <select className="chosen-select-no-single">
                          <option value="">Select Orders Status</option>
                          {Object.keys(OrderStatus).map((value, key) => {
                            return (
                              <option value={value} key={`orderss_${key}`}>
                                {OrderStatus[value]}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div> */}
                    {/* <div className="col-lg-6 col-md-6 col-xs-6">
                      <div className="input-field">
                        <i className="icon-angle-down"></i>
                        <input
                          type="text"
                          data-id="daterange223"
                          id="daterange"
                          placeholder="Select Date Range"
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="user-orders-list">
                  <div className="responsive-table">
                    <ul className="table-generic">
                      <li className="order-heading-titles">
                        <div>Order id</div>
                        <div>Date</div>
                        <div>Total Price</div>
                        <div>Order Status </div>
                        <div>Detail</div>
                      </li>
                      {pageOfItems instanceof Array &&
                        pageOfItems.map((value, indexes) => {
                          return (
                            <li
                              className="order-heading-titles"
                              key={`indexes${indexes}`}
                            >
                              <div>#{value.order_number}</div>
                              <div>{value.creationdate}</div>
                              <div>{value.grand_total}</div>
                              {value.status === "N" && (
                                <div>
                                  <span
                                    className="order-status"
                                    style={{ backgroundColor: "#047a06" }}
                                    onClick={(e) =>
                                      this.onClickHandler(
                                        "A",
                                        value.order_number
                                      )
                                    }
                                  >
                                    {"Accept" ?? "New"}
                                  </span>
                                  &nbsp;
                                  <span
                                    className="order-status"
                                    style={{
                                      backgroundColor: "rgb(195, 51, 50)",
                                    }}
                                    onClick={(e) =>
                                      this.onClickHandler(
                                        "R",
                                        value.order_number
                                      )
                                    }
                                  >
                                    {"Reject" ?? "New"}
                                  </span>
                                </div>
                              )}
                              {value.status !== "N" && (
                                <div>
                                  {" "}
                                  <span
                                    className="order-status"
                                    style={{
                                      backgroundColor:
                                        OrderColor?.[value.status],
                                    }}
                                  >
                                    {OrderStatus?.[value.status]}
                                  </span>
                                </div>
                              )}

                              <div>
                                <Link
                                  to="#"
                                  id={`clickable_${value.order_number}`} 
                                  onClick={(e) =>
                                    value.status !== "N"
                                      ? this.setState({
                                          open: true,
                                          modal_type: "view_detail",
                                          order_detail: value,
                                        })
                                      : e.preventDefault()
                                  }
                                >
                                  <i className="icon-plus2 text-color"></i>
                                </Link>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {order_list instanceof Array && (
              <Pagination
                pageSize={10}
                items={order_list}
                onChangePage={this.onChangePage}
              />
            )}
          </div>
        </div>
        {/* Modal Data */}
        <Modals open={open} size={"lg"}>
          <ModalHeader
            CloseModal={this.CloseModal}
            title={
              modal_type === "view_detail" ? "Order detail" : "Order Review"
            }
          />
          <ModalBody>
            {modal_type === "view_detail" && (
              <div className="order-detail-inner restaurant-order-detail">
                <div className="description-holder">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="list-detail-options has-checkbox">
                        <h3> {this.storage?.restaurant_name} </h3>

                        <ul className="order-detail-options">
                          <li className="order-number">
                            <strong>Order ID:</strong>
                            <span>{order_detail?.order_number}</span>
                          </li>
                          <li className="req-delivery">
                            <strong>Delivery Time:</strong>
                            <span>{order_detail?.expected_delivery_time} </span>
                          </li>
                          <li className="created-date">
                            <strong>Delivery Date:</strong>
                            <span>
                              {order_detail?.lastupdate
                                ? moment(order_detail.lastupdate).format("lll")
                                : "NA"}
                            </span>
                          </li>
                          <li className="order-type">
                            <strong>Type:</strong>
                            <span>order</span>
                          </li>
                          <li className="order-type">
                            <strong>Payment Status:</strong>
                            <span>Done</span>
                          </li>
                          <li className="order-type">
                            <strong>Paid:</strong>
                            <span>{order_detail.payment_type === "Card" ? "Online" : "Cash"}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="customer-detail-holder">
                        <h3>Customer Detail</h3>
                        <ul className="customer-detail">
                          <li>
                            <strong>Name :</strong>
                            <span>
                              {order_detail?.user_firstname +
                                " " +
                                order_detail?.user_lastname}
                            </span>
                          </li>
                          <li>
                            <strong>Phone Number :</strong>
                            <span>{order_detail?.user_phone}</span>
                          </li>
                          <li>
                            <strong>Email :</strong>
                            <span>{order_detail?.user_email}</span>
                          </li>
                          <li>
                            <strong>Address :</strong>
                            <span>{order_detail?.user_location}</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="order-status-holder">
                        <div className="row">
                          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                            <h3>Order Status </h3>
                          </div>
                          <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            {order_detail.status === "R" && (
                              <Link to="#" className="btn btn-danger">
                                Rejected
                              </Link>
                            )}
                            {order_detail.status !== "R" && (
                              <div className="field-holder">
                                <select className="chosen-select-no-single" value={this.state.changed_status??order_detail.status} onChange={e=>this.onClickHandler(e.target.value,order_detail?.order_number,'trigger')}>
                                <option>Select Status</option>
                                  {!['P','D'].includes(order_detail.status) && <option value="P">Processed</option>}
                                  {!['D','C'].includes(order_detail.status) && <option value="D">Disptached</option>}
                                  <option value="C">Completed</option>
                                </select>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      // <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      //   <div className="row">
                      //     <h2 className="heading">Food Menu</h2>
                      //   </div>
                      //
                      //
                      //
                      //   {order_detail && Object.keys(order_detail).length > 0 && (<>
                      //     <div className="row">
                      //         <div className="col-md-6">
                      //           <ul>
                      //           {order_detail.items && order_detail.items.length > 0 && order_detail.items.map((item,index) =>(
                      //                 <>
                      //                 <li className="order-detail-item-name"><span>{item.name}</span></li>
                      //                 <li className="order-detail-item-qty-price"><span>{`(${item.count} * ${item.price})`}</span></li>
                      //                 </>
                      //               ))}
                      //
                      //           </ul>
                      //         </div>
                      //         <div className="col-md-6">
                      //           <ul>
                      //           {order_detail.items.map((item,index) =>(
                      //             <li className="order-detail-item-price"><span>{`Rs.${(parseFloat(item.price) * item.count).toFixed(2)}`}</span></li>
                      //           ))}
                      //
                      //           </ul>
                      //         </div>
                      //     </div>
                      //     </>)}
                      // </div>
                    }


                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <h2 className="heading">Food Menu</h2>
                      <div className="responsive-table">
                        <ul className="categories-order table-generic">
                          <li className="order-heading-titles">
                            <div>Products</div>
                            <div>Price per</div>
                          </li>
                          {order_detail && Object.keys(order_detail).length > 0 && order_detail.items && order_detail.items.length > 0 && order_detail.items.map((item,index) =>(
                            <li className="order-heading-titles" key={`orderdetail${index}`}>
                              <div>
                                <h4>{item.name}</h4>

                              </div>
                              <div><span className="category-price">Rs {(parseFloat(item.price) * item.count).toFixed(2)}</span></div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="row">
                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                          <h3>Order Total</h3>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                          <ul className="order-detail-options order-total">
                            <li className="created-date">
                              <strong>Subtotal:</strong>
                              <span>{order_detail?.total}</span>
                            </li>
                            <li className="order-type">
                              <strong>Pick Up Fee: </strong>
                              <span>{order_detail?.pickup_fee}</span>
                            </li>
                            <li className="order-type">
                              <strong>VAT (13%)</strong>
                              <span>{order_detail?.total*13/100}</span>
                            </li>
                            <li className="order-type total-price">
                              <strong>Total:</strong>
                              <span>{order_detail?.grand_total}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </ModalBody>
        </Modals>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  order_list: state.buyer.order_list,
});

export default connect(mapStateToProps)(Orders);
