import * as types from "../types/types";

const initalState = {
    agro_product_cart:[],
    agro_modal:{},
    view_agro_product:[],
    agro_review_list:[]
}

const CondimentCartReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.AGRO_PRODUCT_CART: 
            return {
                ...state,
                agro_product_cart: action.payload 
            }
        case types.AGRO_PRODUCT_MODEL_OPEN:
            return {
                ...state,
                agro_modal: action.payload
            }
        case types.VIEW_AGRO_PRODUCT:
            return {
                ...state,
                view_agro_product: action.payload
            }
        case types.AGRO_PRODUCT_REVIEW_LISTING:
        return {
            ...state,
            agro_review_list: action.payload
        }
           
        default:
            return state
    }
}

export default CondimentCartReducer;
