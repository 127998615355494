import React, { Component } from 'react';
import WrapperComponent from './ModalHOC'

class Modal extends Component {
    render() {
        return (
            <>{this.props.children}</>
        );
    }
}

export default WrapperComponent(Modal);