import * as actionTypes from './../types/types';

export const ShowLoader = (value) => {
    return dispatch => {   
         dispatch({type: actionTypes.SHOW_LOADER,payload: value}) 
    }
}

export const ShowAlert = (value) => {
    return dispatch => {   
         dispatch({type: actionTypes.SHOW_ALERT,payload: value}) 
    }
}

export const fetchContactUs = (data) => {
    console.log('data',data)
    return dispatch => {
        dispatch({ type: actionTypes.FETCH_CONTACT_US, payload: data })
    }
}