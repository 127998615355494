import React from "react";

function Banner(props){
  return(
    <div
      className="page-section restaurant-detail-image-section"
      style={{
        background:
          `url("/assets/extra-images/cover-photo01.jpg") no-repeat scroll 0 0 / cover`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="company-info-detail banner-info">
              <div className="company-info">
                <div className="text-holder">
                  <span className="restaurant-title"><b>{props.name}</b></span>
                  <div className='text'>
                    <p>
                    {
                      props.description
                    }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner;
