import React,{Component} from 'react'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {  GlobalAgroProductListing } from './../../store/actions/agroActions';
import { AgroProductCart,addAgroCart,minusAgroCart} from './../../store/actions/AgroProductCartActions'
import * as RestaurantAction from './../../store/actions/restaurantActions';
import swal from 'sweetalert';
import { bucketPath } from './../../store/helpers/common';

class Agrocart extends Component {
  subTotal = 0;
  pickup = 0;
  delivery = 0;
  vat   = 0;
  vatApply = 0;
  totalToPay = 0;
  constructor(props) {
    super(props);
      this.state = {
        agrocartItems:[],
        delivery_type:'delivery',
        payment_type:'card'  };
      }

    componentDidMount(){
      let ID = this.props.id 
      this.props.dispatch(GlobalAgroProductListing({uniqueID:ID}))
    }

  UNSAFE_componentWillReceiveProps(newProps) {
    let agro_product_cart = newProps.agro_product_cart ?? [];
      this.setState({ agrocartItems: agro_product_cart });
    }

    onAddCart = (value) => {
      this.props.dispatch(addAgroCart(value,this.state));
      return null;
    };

    onMinusCart = (value) => {
      this.props.dispatch(minusAgroCart(value,this.state));
      return null;
    };

    placeOrder = e => {
      const getUserIdentity = localStorage.getItem('token');
      if(!getUserIdentity){
        swal({
          text: "You need to login to make an order!",
          icon: "info",
          buttons: true,
          dangerMode: true,
        })
        .then((yesDo) => {
          if (yesDo) {
            this.props.dispatch(RestaurantAction.ForceloginClick(true))
          } else {
            console.log("Record is Safe");
          }
        });
        return false;
      }
      const {delivery_type,payment_type} = this.state;
      let deliveryType = delivery_type==="delivery"?"D":"P";
      let order_type = "agro_product";
      let data = {delivery_type:deliveryType,payment_type,pickup_cost:this.pickup,delivery_cost:this.delivery,vatPercentage:this.vat,vatApplied:this.vatApply,subTotal:this.subTotal,totalToPay:this.totalToPay,cartItems:this.props.agro_product_cart,order_type:order_type};

      const promise = new Promise(async (resolve,reject)=>{
        let response = await this.props.dispatch(RestaurantAction.PlaceOrder(data));
        if(response.code===1){
          resolve(response)
        }else{
          reject()
        }
      })

      promise.then((response)=>{
        this.props.dispatch(AgroProductCart([]));
        if(response.type==='cash'){
          this.props.history.push('/buyer-orders')
        }else if(response.type==='card'){
          this.props.history.push(`/stripe-payment/${response.order_number}`)
        }

      })

    }


  render(){
    const {agro_product_cart,global_agro_product} = this.props 
    const {delivery_type} = this.state;
    let total_price = 0;
    return (
        <div className="col-md-3 col-xs-12 condiment-left-cart condiment-sidebar-left-cart agro-cart">
          <ul className="dropdown-menu add-cart-drop">
            {agro_product_cart && agro_product_cart.map((item,index) =>{
              let filterdata = global_agro_product && global_agro_product.filter(data =>data.uniquekey === item.uniqueID)
               total_price += parseFloat(item.price);
               this.subTotal = index === 0 ?  0 : this.subTotal;
               this.subTotal += parseFloat(item.price);
               this.vatApply = ((this.subTotal*this.vat)/100).toFixed(2);
               this.totalToPay = (parseFloat(this.subTotal)+parseFloat(this.vatApply)+parseFloat(`${delivery_type==="delivery"?this.delivery:this.pickup}`)).toFixed(2);
              return (
            <li>
              <div className="cart-items-main-div">
                <div className="row">
                  <div class="col-md-3 col-xs-3 image-area">
                    <a class="cart-item-img" href="/condiments">
                      <img
                        src={item.name ? `${bucketPath}${item.image}` : ''}
                        alt={item.name}
                      />
                    </a>
                  </div>
                  <div className="col-md-9 col-xs-9 cart-item-name">
                    <h3><Link to="#">{item.name}</Link></h3></div>
                </div>
                <div className="row">
                  <div className="col-md-3 col-xs-3" />
                  <div className="col-md-5 col-xs-5">
                    <div className={`condiment-increment-decrement-area condiment-right-cart-area `}>
                      <div className="col-md-4 col-xs-4 delete-icon-area  no-padding">
                          <Link
                            to='#'  onClick={(e) =>
                            this.onMinusCart(filterdata[0])
                          }>{item.count > 1 ? <i className="icon-minus-circle"></i> : <i className="icon-trash-o text-red condiment-delete-icon"></i>}</Link>
                        </div>
                                    <div className="col-md-4 col-xs-4 no-padding"><p className="count-area">{item.count}</p></div>
                        <div className="col-md-4 col-xs-4 no-padding"><Link
                          to='#'  onClick={(e) =>
                          this.onAddCart(filterdata[0])
                        }><i className="icon-plus-circle"></i></Link></div>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-4 no-padding">
                    <p className="cart-item-price">{`₹ ${item.price}`}</p>
                  </div>
                </div>
              </div>
            </li>
            )
            })}
             {agro_product_cart.length > 0 &&   <div className="check-out-btn-div">
                <Link
                  className="btn btn-red btn-chek-out-drop btn-block right-condiment-cart-checkout"
                  to="#"
                  onClick={e=>this.placeOrder(e)}
                >
                  Go to Checkout
                  <span className="total-price">{`₹ ${total_price}`}</span>
                </Link>
              </div>
             }
            {agro_product_cart.length === 0 &&  <div className="check-out-btn-div cart-empty-area">
              <Link to="#" className="btn btn-red btn-chek-out-drop btn-block">Cart is Empty
              </Link>
            </div>}
          </ul>
        </div>
    )
  }
}

const mapStateToProps = (state) => ({
  agro_product_cart:state.AgroCart.agro_product_cart,
  global_agro_product:state.agro.global_agro_product,
});

export default connect(mapStateToProps)(Agrocart);
