import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AdminUserList,DeleteAdminRecord } from './../../store/actions/adminActions'
import Pagination from './../../store/helpers/Pagination'
import { bucketPath } from './../../store/helpers/common'
import Modal from "../../modules/Html/Modal";
import swal from 'sweetalert'; 
import moment from 'moment'


const avatarCss =  {
    verticalAlign: "middle",
    width: "35px",
    height: "35px",
    borderRadius: "50%",
}

class MyUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pageOfItems: [], currentPage: 1,open:false,viewData:[] }
    }

    componentDidMount() {
        this.props.dispatch(AdminUserList())

    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page,open:false });
    }
    CloseModal=()=>{
        this.setState({open:false,viewData:[]})
    }

    DeleteUser = (id) => {
        let data={type:'user',uniqueID:id}
        swal({
            title: "Are you sure you want to delete this User?",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.dispatch(DeleteAdminRecord(data));
            } else { 
                console.log("Record is Safe");
            }
        });
      };

    render() {
        const { pageOfItems, currentPage,open,viewData } = this.state
        const { admin_user_list } = this.props 
        return (
            <div className="col-lg-10 col-md-9 col-sm-12 col-xs-12">
                <div className="form-elements">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="user-orders-list">
                                <div className="table-responsive">
                                    <ul className="table-generic table-generic-temp">
                                        <li className="order-heading-titles">
                                            <div>Sr No</div>
                                            <div>Name</div>
                                            <div>Email</div>
                                            <div>Phone No.</div>
                                            <div>Address</div>
                                            <div>Profile</div>
                                            <div>Action</div>
                                        </li>
                                        {pageOfItems instanceof Array &&
                                            pageOfItems.map((value, index) => {
                                                return (
                                                    <li className="order-heading-titles" key={`menu_${index}`}>
                                                        <div>{(index + 1) + ((currentPage - 1) * 10)}</div>
                                                        <div>{`${value.firstname} ${value.lastname}`}</div>
                                                        <div>{value.email}</div>
                                                        <div>{value.phone}</div>
                                                        <div>{value.address}</div>
                                                        <div> 
                                                            {value.profile_pic && <img
                                                                className="avatar"
                                                                src={
                                                                    value.profile_pic
                                                                    ? `${bucketPath}${value.profile_pic}`
                                                                    : "assets/extra-images/listing-logo18.png"
                                                                }
                                                                alt="dp"
                                                                style={avatarCss}
                                                                />
                                                            }
                                                        </div>
                                                        <div>
                                                            <Link 
                                                                className="pull-left" 
                                                                to ="#" 
                                                                onClick={e => this.setState({open:true,viewData:value})}
                                                            >
                                                                <i className="fa fa-eye"></i> 
                                                            </Link>&nbsp;&nbsp;
                                                            <Link
                                                                data-toggle="modal"
                                                                to="#" 
                                                                onClick={e => this.DeleteUser(value.uniqueID)}
                                                            >
                                                                <i className="fa fa-trash alert-danger"></i> 
                                                            </Link>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        {pageOfItems instanceof Array && pageOfItems.length===0 && 
                                            <li className="order-heading-titles">
                                                       <p>No data available</p>
                                            </li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    {admin_user_list instanceof Array && (
                        <Pagination
                            pageSize={10}
                            items={admin_user_list}
                            onChangePage={this.onChangePage}
                        />
                    )}
                </div>
                 {/*  NEXT IS VIEW MODAL */}
                 <Modal
                 open={open} 
                 size={"sl"}
                     title={"User Detail"}
                      CloseModal={this.CloseModal} >
             
                        <div role="tabpanel" className="tab-pane active">
                            <div id="result_296069" className="status-message"></div>
                            <form method="post" className="wp-user-form demo_test">
                            <ul className="order-detail-options">
                          <li className="name">
                            <strong><h6>Name:</h6></strong>
                            <span><b>{`${viewData.title} ${viewData.firstname} ${viewData.lastname}`}</b></span>
                          </li>
                          <li className="email">
                            <strong><h6>Email:</h6></strong>
                            <span><b>{viewData.email}</b></span>
                          </li>
                          <li className="phone-number">
                            <strong><h6>phone no:</h6></strong>
                            <span><b>{viewData.phone}</b></span>
                          </li>
                          <li className="creation-date">
                            <strong><h6>Create Date:</h6></strong>
                            <span><b>{viewData.creationdate?moment(viewData.creationdate).format('lll'):'NA'}</b></span>
                          </li>
                          <li className="last-update">
                            <strong><h6>Last Update:</h6></strong>
                            <span><b>{viewData.lastupdate?moment(viewData.lastupdate).format('lll'):'NA'}</b></span>
                          </li>
                          <li className="address">
                            <strong><h6>Address:</h6></strong>
                            <span><b>{viewData.address?viewData.address:"NA"}</b></span>
                          </li>
                         
                           <li className="zipcode">
                            <strong><h6> Zipcode:</h6> </strong>
                            <span><b>{viewData.zipcode?viewData.zipcode:"NA"}</b></span>
                          </li> 
                          <li className="status">
                            <strong><h6> Status:</h6> </strong>
                            <span><b>{viewData.status}</b></span>
                          </li> 
                           
                         
                         
                          
                        </ul>
                                <div className="side-by-side select-icon clearfix">
                                    <div className="select-holder"></div>
                                </div>
                            </form>
                        </div>
                
                </Modal>
            </div>
            
        )
    }
}

const mapStateToProps = (state) => ({
    admin_user_list: state.admin.admin_user_list,
});

export default connect(mapStateToProps)(MyUser);