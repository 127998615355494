import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { ShowAlert } from "./../../store/actions/staticActions";
//import { Alert } from "react-bootstrap";

const Alerts = (props) => {
  const usePrevious = (value) => {
    const ref = useRef();

    useEffect(() => {
      ref.current = value;
    });

    return ref.current;
  };

  const [show, setShow] = useState(false);

  const { Class, Message, Timeout } = props;

  const prevMessage = usePrevious(Message);

  useEffect(() => {
    if (Message !== "" && prevMessage !== Message) {
      setShow(true);
    }
  }, [Message,prevMessage]);

  const onClickHide = () => {
    setShow(false);
    props.dispatch(ShowAlert({ Class: "", Message: "", Timeout: "" }));
  };

  setTimeout(() => {
    setShow(false);
    props.dispatch(ShowAlert({ Class: "", Message: "", Timeout: "" }));
  }, Timeout);

  return (
    <div role="alert" className={`${Class} alert-dismissible alert_common_message ${ show ? "show" : "hide" }`} >
      <button type="button" className="close" onClick={onClickHide.bind()}>
        <span aria-hidden="true">×</span>
        <span className="sr-only">Close alert</span>
      </button>{" "}
      {Message}
    </div>
  );
};
const mapStateToProps = (state) => ({
  Class: state.static.Class,
  Message: state.static.Message,
  Timeout: state.static.Timeout,
});
export default connect(mapStateToProps)(Alerts);
