import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as RestaurantAction from '../../../store/actions/restaurantActions';
import { mamaCart } from '../../../store/actions/cartActions';
import { bucketPath } from './../../../store/helpers/common';
import { Link } from 'react-router-dom';
import SimpleReactvalidator from 'simple-react-validator'
import Modal from "../../../modules/Html/Modal";
import Pagination from "../../../store/helpers/Pagination";
import swal from 'sweetalert';

class RecipeInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      moreInfo: false,
      title: '',
      description: '',
      rating: 1,
      isLogin: false
    }

    this.storageToken = localStorage.getItem("token");
    this.storageData = localStorage.getItem("data");
    this.storageType = localStorage.getItem("type");

    this.validator = new SimpleReactvalidator();
  }

  componentDidMount() {
    if (
      this.storageToken &&
      this.storageData &&
      this.storageToken !== "" &&
      this.storageToken !== ""
    ) {
      this.setState({
        isLogin: true,
      });
    }
    let recipeID = this.props.match.params.token;
    this.props.dispatch(RestaurantAction.RecipeInfo({ recipeID }));
    this.props.dispatch(RestaurantAction.RestaurantRecipeReviewListing({ "recipeID": recipeID }));
  }
  onRadioChangeHandler = e => {
    this.setState({ rating: e.target.value });
  }

  onChangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  addRivew = async (e) => {
    const { title, description, rating} = this.state;
    let recipeID = this.props.match.params.token;
    if (this.validator.allValid()) {
      let response = await this.props.dispatch(RestaurantAction.AddRecipeReview({ title, description, points: (rating * 20), recipeID }));
      if (response === 1) {
        this.CloseModal();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate()
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let mama_cart = newProps.mama_cart ?? [];
    this.setState({ cartItems: mama_cart });
  }

  addToCart = (value, restaurantId) => {
    let uniqueID = value.uniquekey;
    let name = value.name;
    let price = parseFloat(value.price);
    let { cartItems } = this.state;
    let arrayFilter =
      cartItems instanceof Array &&
      cartItems.findIndex((obj) => obj.uniqueID === uniqueID);
    if (arrayFilter !== -1) {
      cartItems[arrayFilter] = {
        name: name,
        uniqueID: uniqueID,
        price: cartItems[arrayFilter].price + price,
        count: cartItems[arrayFilter].count + 1,
      };
    } else {
      cartItems = [
        ...cartItems,
        { name: name, uniqueID: uniqueID, price: price, count: 1 },
      ];
    }
    this.props.dispatch(mamaCart(cartItems));
    this.props.history.push("/menu-listing/" + restaurantId);
    return null;
  };

  CloseModal = () => {
    this.setState({ open: false });
  };

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

  openReviewModal = () => {
    const getUserIdentity = localStorage.getItem('token');
    if (!getUserIdentity) {
      swal({
        text: "You need to login to make an order!",
        icon: "info",
        buttons: true,
        dangerMode: true,
      })
        .then((yesDo) => {
          if (yesDo) {
            this.props.dispatch(RestaurantAction.ForceloginClick(true))
          } else {
            console.log("Record is Safe");
          }
        });
      return false;
    }
    this.setState({
      open: true,
    })
  }

  render() {
    const { moreInfo, title, description, rating, open, pageOfItems} = this.state
    const {
      restaurant_data: {
        recipe_individual_info: { additionalInfo, recipeInfo, restaurantInfo },
      },
      restaurant_recipe_review
    } = this.props;
    const summaryLength = recipeInfo?.summary ? recipeInfo.summary.length : 0
    const substringSumary = summaryLength > 150 ? `${recipeInfo.summary.substring(0, 150)}...` : recipeInfo?.summary
    let Images = recipeInfo?.image ?? '';
    let Videos = recipeInfo?.video ?? '';
    let ImageLength = recipeInfo?.image?.split(',')?.length;
    let CombinedImage = `${Images},${Videos}`;
    let ingredients = recipeInfo?.ingredients;
    if (ingredients) {
      ingredients = JSON.parse(ingredients);
    }
    let Prep = recipeInfo?.prepration_time ?? '';
    let Cook = recipeInfo?.cooking_time ?? '';
    let Additional = recipeInfo?.additional_time ?? '';
    let Serving = recipeInfo?.serving_person ?? '';
    let Total = recipeInfo?.total_time ?? '';
    return (
      <div>
        <div className='main-section receipes-details'>
          <div className='container'>
            <div className='row'>
              <div className='page-content col-lg-8 col-md-8 col-sm-12 col-xs-12'>
                <div className='page-section'>
                  <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                      <div className='blog-detail'>
                        <div className='row'>
                          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            <div className='title-area'>
                              {/* <div className="post-mama">
                                <a href='/'>
                                  <img
                                    src={restaurantInfo?.profile_pic ? `${bucketPath}${restaurantInfo.profile_pic}` : ''}
                                    className='img-list wp-post-image'
                                    alt='No Img'
                                  />
                                  <span className="post-mam-name">By {restaurantInfo?.restaurant_name}</span>
                                </a>
                              </div> */}
                              <h2 className="pull-left col-md-12 no-padding">{recipeInfo?.title}</h2>
                              <Link onClick={e => this.addToCart(recipeInfo?.food_menu, restaurantInfo?.uniqueID)} to="#" className="book-now pull-right">Order Now</Link>
                              <div className="rating-review-div">
                                <div className="row">
                                  <div className="col-md-2 no-padding-right">
                                    <div className='list-rating'>
                                      <div className='rating-star'>
                                        <span className='rating-box' style={{ width: recipeInfo?.recipe_average_points + "%" }}></span>
                                      </div>
                                      <span className='reviews'>({recipeInfo?.recipe_review_count})</span>
                                    </div>
                                  </div>
                                  <div className="col-md-7  no-padding">
                                    <ul className="review-phots">
                                      <li><a href="/">{restaurantInfo?.review_count === 1 ? "Review" : "Reviews"}</a></li>
                                      {/* <li><a href="/">{restaurantInfo?.recipe_image_count} {restaurantInfo?.recipe_image_count === 1? "Photo" : "Photos"}</a></li> */}
                                    </ul>
                                  </div>
                                </div>

                              </div>
                              <span>
                                {moreInfo && recipeInfo?.summary}
                                {!moreInfo && substringSumary}
                                {summaryLength > 150 && !moreInfo && <Link to="#" onClick={e => this.setState({ moreInfo: true })}>Read More</Link>}
                              </span>

                            </div>
                            <div className='main-post'>
                              <figure className="col-md-9">
                                {/* <a href="#"><img src="assets/extra-images/blogs-food-bakery-02-750x422.jpg" alt="" /></a> */}
                                <div
                                  id='carousel-example-generic'
                                  className='carousel slide'
                                  data-ride='carousel'

                                >
                                  <ol className="carousel-indicators">
                                    {CombinedImage?.split(',')?.map(
                                      (imgVal, imgIndex) => {
                                        return (
                                          <li key={"dot_" + imgIndex} data-target="#carousel-example-generic" data-slide-to={`${imgIndex}`} className=""></li>
                                        )
                                      })
                                    }
                                  </ol>
                                  {/* <!-- Wrapper for slides --> */}
                                  <div
                                    className='carousel-inner'
                                    role='listbox'
                                  >
                                    {CombinedImage?.split(',')?.map(
                                      (imgVal, imgIndex) => {
                                        return (
                                          <div className={`item ${imgIndex === 0 ? "active" : ""}`} key={`item_${imgIndex}`}>
                                            <Link to='#'>
                                              {imgIndex < ImageLength ? (
                                                <img
                                                  src={`${bucketPath}${imgVal}`}
                                                  alt=''
                                                />
                                              ) : (
                                                  <video controls>
                                                    <source
                                                      src={`${bucketPath}${imgVal}`}
                                                      type={`video/${
                                                        imgVal?.split('.')?.[1]
                                                        }`}
                                                    />
                                                  Your browser does not support
                                                  the video tag.
                                                  </video>
                                                )}
                                            </Link>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>

                                  {/* <!-- Controls --> */}
                                  <a
                                    className='left carousel-control'
                                    href='#carousel-example-generic'
                                    role='button'
                                    data-slide='prev'
                                  >
                                    <span
                                      className='icon-arrows-1'
                                      aria-hidden='true'
                                    ></span>
                                    <span className='sr-only'>Previous</span>
                                  </a>
                                  <a
                                    className='right carousel-control'
                                    href='#carousel-example-generic'
                                    role='button'
                                    data-slide='next'
                                  >
                                    <span
                                      className='icon-arrows'
                                      aria-hidden='true'
                                    ></span>
                                    <span className='sr-only'>Next</span>
                                  </a>
                                </div>
                              </figure>
                              <div className="col-md-3  no-padding-left">
                                <div className="right-side-summary-box">
                                  <div className="clock-ico">
                                    <i className="icon-clock-o"></i>
                                  </div>
                                  <ul className="right-side-summary-ul">
                                    <li><strong>Prep</strong> {Prep}</li>
                                    <li><strong>Cook</strong> {Cook}</li>
                                    <li><strong>Additional</strong> {Additional}</li>
                                    <li><strong>total</strong> {Total}</li>
                                    <li><strong>Serving</strong> {Serving}</li>
                                  </ul>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            <Link className="btn btn-red pull-right margin-bottom-10" to="/condiments">
                              Add All Ingredents
                            </Link>
                          </div>
                          {
                            ingredients && <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                              <h5>Ingredients</h5>
                              <ul>
                                {
                                  ingredients.map((item, index) => {
                                    return (
                                      <li key={`ingredient_${index}`}>
                                        {item.quantity} - {item.ingName}
                                      </li>
                                    )

                                  })
                                }
                              </ul>
                            </div>
                          }
                          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            <div
                              className='rich-editor-text'
                              dangerouslySetInnerHTML={{
                                __html: recipeInfo?.description ?? '<p></p>',
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                            <div className="add-review-comment-div">
                              <div className="col-md-9">
                                <h2><i className="icon-comment2"></i>Comments / Reviews</h2>
                              </div>
                              <div className="pull-right col-md-3 text-right">
                                <Link to="#" className="add-review-btn text-color" onClick={this.openReviewModal}>Add Review</Link>
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <ul className="review-restaurant">
                                {
                                  pageOfItems instanceof Array && pageOfItems.map((value, index) => (
                                    <li className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding" key={`${"review_" + index}`}>
                                      <div className="list-holder ">
                                        <div className="review-text">
                                          <div className="review-title">
                                            <h6>{value.title} </h6>
                                            <div className="rating-holder">
                                              <div className="rating-star">
                                                <span
                                                  style={{ width: value.points + "%" }}
                                                  className="rating-box"
                                                ></span>
                                              </div>
                                              <em className="review-date pull-right">{value.lastupdate.split(" ")[0]}</em>
                                            </div>
                                          </div>

                                          <p>{value.description}</p>
                                        </div>
                                      </div>
                                    </li>
                                  ))
                                }
                              </ul>
                              {restaurant_recipe_review instanceof Array && (
                                <Pagination
                                  pageSize={10}
                                  items={restaurant_recipe_review}
                                  onChangePage={this.onChangePage}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <aside className='page-sidebar right col-lg-4 col-md-4 col-sm-12 col-xs-12'>
                <div className="widget-holder">
                  <div className='widget widget_search'>
                    <div className="col-md-12 col-xs-12 grid-listing-col recipe-part detail-page-right-side-bx" >
                      <div className="img-holder">
                        <figure>
                          <Link to="#">
                            <img
                              src={restaurantInfo?.profile_pic ? `${bucketPath}${restaurantInfo.profile_pic}` : ''}
                              className='img-list wp-post-image center-blcok img-responsive center-block'
                              alt='No Img'
                            />
                          </Link>
                          {/* <Link to="#"><img src={`${bucketPath}${value.image.split(",")[0]}`} alt="" className="img-responsive" /> </Link>
                        <Link className="mama-coach-img" to="#"><img src={`${bucketPath}${value.restaurant_profile_pic}`} alt="" className="img-responsive" /> </Link> */}
                        </figure>

                      </div>
                      <div className="text-holder">
                        <div className="listing-inner">
                          <h4>
                            {/* <Link to={`/recipe-info/${value.recipeID}`}> {value.title}</Link> */}
                            <Link to="/"> {restaurantInfo?.restaurant_name}</Link>
                          </h4>

                          {/* <p className="recipeSummary">{value.summary}</p> */}
                          <div className="list-rating pull-left recipe-rating">
                            <div className="rating-star">
                              <span className="rating-box" style={{ width: "100%" }}></span>
                            </div>
                            <span className="reviews">(1)</span>
                            <span className="liveRecipe pull-right"><i className="fa fa-circle blink-hard"></i>LIVE</span>
                          </div>
                          {/* <h5 className="coach-date-time">Date & Time:<span>Feb 08,2021 | 09:45 AM</span></h5>
                                                            <h5 className="coach-duration-time">Duration:<span>01:10</span></h5> */}
                          <div className="no-padding col-md-12 food-coach-btn">
                            <Link to="/recepies" className="btn btn-red ">More Recipies</Link>
                            <Link to={`/menu-listing/${restaurantInfo?.uniqueID}`} className="book-now pull-right">Mama Profile</Link>

                            {/* <Link to={`/menu-listing/${value.uniqueID}`} className="book-now">Order Now</Link>
                          <button className="btn btn-red pull-right" onClick={e => this.setState({ recipe_video: value, additionalRecipe: value.additionalRecipe, open: true })}>More Recipies</button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='widget widget-related-post popular-recipie-div'>
                      {/* <div className='widget widget-recent-blog-post'> */}
                      <div className='widget-recent-blog-post'>
                        <div className='widget-title'>
                          <h5>Popular Recepies</h5>
                        </div>
                        <ul>
                          {additionalInfo instanceof Array && additionalInfo.map((adInfo, adKey) => {
                            return (
                              <li key={`adInfo${adKey}`}>
                                <div className='img-holder'>
                                  <figure>
                                    <Link to='#'>
                                      <img
                                        src={`${bucketPath}${adInfo?.image?.split(',')?.[0]}`}
                                        alt=''
                                      />
                                    </Link>
                                  </figure>
                                </div>
                                <div className='text-holder'>
                                  <div className='post-title'>
                                    <h6>
                                      <Link to='#' onClick={e => this.props.dispatch(RestaurantAction.RecipeInfo({ recipeID: adInfo?.recipeID }))}>{adInfo?.title?.substring(0, 50)}</Link>
                                    </h6>
                                      <span style={{fontSize:'12px'}}>{adInfo?.summary?.substring(0, 45)}...</span>
                                    <div className='list-rating'>
                                      <div className='rating-star'>
                                        <span className='rating-box' style={{ width: adInfo?.recipe_average_points + "%" }}></span>
                                      </div>
                                      <span className='reviews'>({adInfo?.recipe_review_count})</span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
        {/* <!-- Main Section End --></div> */}
        <Modal
          open={open}
          size={"lg"}
          title="Add Review"
          CloseModal={this.CloseModal}
        >
          <div className="order-detail-inner">
            <div className="reviews-holder">
              <div className="add-new-review-holder add-new-review-2067">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h3>Rate and Write a Review</h3>
                  </div>
                  <div className="foodbakery-add-review-data">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div
                        className="total-rating user-rating-container overall-rating"
                        data-overall-rating="1"
                      >
                        <h6>Rating</h6>
                        <div className="rating-star">
                          <input
                            id="star-13"
                            type="radio"
                            name="rating1"
                            value="1"
                            className="star-1"
                            defaultChecked={rating === "1" || rating === "2" || rating === "3" || rating === "4" || rating === "5" ? "checked" : ""}//"checked"
                            onClick={e => this.onRadioChangeHandler(e)}
                          />
                          <label className="star-13" htmlFor="star-13">
                            1
                            </label>
                          <input
                            id="star-23"
                            type="radio"
                            name="rating2"
                            value="2"
                            className="star-2"
                            defaultChecked={rating === "2" || rating === "3" || rating === "4" || rating === "5" ? "checked" : ""}//"checked"
                            onClick={e => this.onRadioChangeHandler(e)}
                          />
                          <label className="star-2" htmlFor="star-23">
                            2
                            </label>
                          <input
                            id="star-33"
                            type="radio"
                            name="rating3"
                            value="3"
                            className="star-3"
                            defaultChecked={rating === "3" || rating === "4" || rating === "5" ? "checked" : ""}//"checked"
                            onClick={e => this.onRadioChangeHandler(e)}
                          />
                          <label className="star-3" htmlFor="star-33">
                            3
                            </label>
                          <input
                            id="star-43"
                            type="radio"
                            name="rating4"
                            value="4"
                            className="star-4"
                            defaultChecked={rating === "4" || rating === "5" ? "checked" : ""}//"checked"
                            onClick={e => this.onRadioChangeHandler(e)}
                          />
                          <label className="star-4" htmlFor="star-43">
                            4
                            </label>
                          <input
                            id="star-53"
                            type="radio"
                            name="rating5"
                            value="5"
                            className="star-5"
                            defaultChecked={rating === "5" ? "checked" : ""}//"checked"
                            onClick={e => this.onRadioChangeHandler(e)}
                          />
                          <label className="star-5" htmlFor="star-53">
                            5
                            </label>
                          <span style={{ width: (rating * 20) + "%" }}></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-element">
                        <i className="icon-edit2"></i>
                        <input
                          type="text"
                          placeholder="Title of your review *"
                          value={title}
                          name="title"
                          onChange={e => this.onChangeHandler(e)}
                        />
                        {this.validator.message('title', title, 'required')}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-element">
                        <textarea
                          placeholder="Tell about your experience or leave a tip for others"
                          cols="30"
                          rows="10"
                          maxLength="500"
                          name="description"
                          value={description}
                          onChange={e => this.onChangeHandler(e)}
                        ></textarea>
                        {this.validator.message('description', description, 'required|min:10')}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-element message-length">
                        <span>Min characters: 10</span>
                        <span>Max characters: 500</span>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-element">
                        <input
                          type="button"
                          defaultValue="Submit your Review"
                          onClick={e => this.addRivew(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  restaurant_data: state.restaurant,
  restaurant_recipe_review: state.restaurant.restaurant_recipe_review
});

export default connect(mapStateToProps)(RecipeInfo);
