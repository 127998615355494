import React, { Component } from "react";
import { Link } from "react-router-dom";
import {PlaceOrder,ForceloginClick} from './../../../../store/actions/restaurantActions';
import {connect} from 'react-redux';
import {mamaCart} from "../../../../store/actions/cartActions";
import swal from 'sweetalert';
class MenuListingRHS extends Component {
  subTotal = 0;
  pickup = 0;
  delivery = 0;
  vat   = 0;
  vatApply = 0;
  totalToPay = 0;
  state = {delivery_type:'delivery',payment_type:'cash'}

  placeOrder = e => {
    const getUserIdentity = localStorage.getItem('token');
    if(!getUserIdentity){
      swal({
        text: "You need to login to make an order!",
        icon: "info",
        buttons: true,
        dangerMode: true,
      })
      .then((yesDo) => {
        if (yesDo) {
          this.props.dispatch(ForceloginClick(true))
        } else {
          console.log("Record is Safe");
        }
      });
      return false;
    }
    const {delivery_type,payment_type} = this.state;
    const {cart:{mama_cart}} = this.props
    let deliveryType = delivery_type==="delivery"?"D":"P";
    let order_type = "food";
    let data = {delivery_type:deliveryType,payment_type,pickup_cost:this.pickup,delivery_cost:this.delivery,vatPercentage:this.vat,vatApplied:this.vatApply,subTotal:this.subTotal,totalToPay:this.totalToPay,cartItems:mama_cart,order_type:order_type};

    const promise = new Promise(async (resolve,reject)=>{
      let response = await this.props.dispatch(PlaceOrder(data));
      if(response.code===1){
        resolve(response)
      }else{
        reject()
      }
    })

    promise.then((response)=>{
      this.props.dispatch(mamaCart([]));
      if(response.type==='cash'){
        this.props.history.push('/buyer-orders')
      }else if(response.type==='card'){
        this.props.history.push(`/stripe-payment/${response.order_number}`)
      }

    })

  }

  render() {

    const {
      restaurant_data: { restaurant_view_profile },
    } = this.props;
    const restaurantsetting = restaurant_view_profile?.data?.restaurantsetting[0];
    const {cart:{mama_cart}} = this.props
    const {delivery_type,payment_type} = this.state;
    this.totalToPay = this.subTotal = 0
    let showVat = restaurantsetting?.vat
    this.vat = showVat
    this.pickup = restaurantsetting && restaurantsetting.pickup_fee ? Number(restaurantsetting?.pickup_fee):0
    this.delivery = restaurantsetting && restaurantsetting.delivery_fee ? Number(restaurantsetting?.delivery_fee):0

    return (
      <div className="sticky-sidebar col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <div className="user-order-holder">
          <div className="user-order">
            <h6>
              <i className="icon-shopping-basket"></i>Your Order
            </h6>
            <div className="select-option dev-select-fee-option">
              <ul>
                <li>
                  <input
                    id="order-pick-up-fee"
                    type="radio"
                    name="delivery_type"
                    value="pickup"
                    onChange= {e=>this.setState({[e.target.name]:e.target.value})}
                    checked={delivery_type==="pickup"?true:false}
                  />
                  <label htmlFor="order-pick-up-fee">Pick-Up</label>

                     <span>Rs 0.00</span>


                </li>
                <li>
                  <input
                    id="order-delivery-fee"
                    type="radio"
                    name="delivery_type"
                    value="delivery"
                    onChange= {e=>this.setState({[e.target.name]:e.target.value})}
                    checked={delivery_type==="delivery"?true:false}
                  />
                  <label htmlFor="order-delivery-fee">Delivery</label>
                  <span>Rs {(this.delivery.toFixed(2))}</span>
                </li>
              </ul>
            </div>
            <div id="dev-menu-orders-list">
              <ul className="categories-order">
                {mama_cart instanceof Array && mama_cart.map((value,key)=>{
                  this.subTotal = key === 0 ?  0 : this.subTotal;
                  this.subTotal += parseFloat(value.price);
                  let VAT = showVat ? this.vat : 0
                  this.vatApply = ((this.subTotal*VAT)/100).toFixed(2);
                  this.totalToPay = (parseFloat(this.subTotal)+parseFloat(this.vatApply)+parseFloat(`${delivery_type==="delivery"?this.delivery:this.pickup}`)).toFixed(2);
                  return (
                    <li key={`listingrhs_${key}`}>
                      <Link to="#" onClick={e=>this.props.onRemoveCart(value.uniqueID)} className="btn-cross dev-remove-menu-item">
                        <i className="left-card-menu-cross-btn icon-cross3 text-color"></i>
                      </Link>
                      <Link to="#"> {value.name} ({value.count})</Link>
                      <span className="category-price">Rs {(value.price).toFixed(2)}</span>
                  </li>
                  )
                })}

                {/* <li>
                  <Link to="#" className="btn-cross dev-remove-menu-item">
                    <i className=" icon-cross3"></i>
                  </Link>
                  <Link to="#">Chicken Tandoori Special 09" Deep Pan</Link>
                  <span className="category-price">Rs 6.50</span>
                  <ul>
                    <li>
                      Drinks - chillies :{" "}
                      <span className="category-price">Rs 3.50</span>
                    </li>
                  </ul>
                  <Link
                    to="#"
                    data-toggle="modal"
                    data-target="#extras-0-1"
                    className="update-menu dev-update-menu-btn"
                  >
                    Edit
                  </Link>
                </li> */}
              </ul>
              <div className="price-area dev-menu-price-con">
                <ul>
                  <li>
                    Subtotal{" "}
                    <span className="price">
                      Rs <em className="dev-menu-subtotal">{(this.subTotal).toFixed(2)}</em>
                    </span>
                  </li>
                  <li className="restaurant-fee-con">
              <span className="fee-title">{delivery_type==="delivery"?'Delivery':'Pick-Up'}</span>
                    <span className="price">
                      Rs <em className="dev-menu-charges">{delivery_type==="delivery"?(this.delivery).toFixed(2):(this.pickup).toFixed(2)}</em>
                    </span>
                  </li>
                  {showVat && <li>
                    TAX ({this.vat}%){" "}
                    <span className="price">
                    Rs <em className="dev-menu-vtax">{this.vatApply}</em>
                    </span>
                  </li>}
                </ul>
                <p className="total-price">
                  Total{" "}
                  <span className="price">
                  Rs <em className="dev-menu-grtotal">{this.totalToPay}</em>
                  </span>
                </p>
              </div>
            </div>
            <div id="dev-no-menu-orders-list"></div>
            <div className="pay-option dev-order-pay-options">
              <ul>
                <li>
                  <input
                    type="radio"
                    name="payment_type"
                    value="cash"
                    onChange= {e=>this.setState({[e.target.name]:e.target.value})}
                    checked={payment_type==="cash"?true:false}
                  />
                  <label htmlFor="order-cash-payment">
                    <i className="icon-coins"></i>Cash
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="payment_type"
                    value="card"
                    onChange= {e=>this.setState({[e.target.name]:e.target.value})}
                    checked={payment_type==="card"?true:false}
                  />
                  <label htmlFor="order-card-payment">
                    <i className="icon-credit-card4"></i>Card
                  </label>
                </li>
              </ul>
            </div>
            {/* <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="input-group date">
                    <input
                      type="text"
                      name="delivery_date"
                      id="datetimepicker1"
                      className="form-control"
                      defaultValue="04-04-2020 10:07"
                      placeholder="Select Date and Time"
                    />
                    <span className="input-group-addon">
                      <span className="icon-event_available"></span>
                    </span>
                  </div>
                </div>
              </div>
            </div> */}
            <Link to="#" className="menu-order-confirm" onClick={e=>this.placeOrder(e)}>
              Confirm Order
            </Link>
            <span className="menu-loader"></span>
          </div>
        </div>
      </div>
    );
  }
}

const importStateFromProps = (state) => ({
  cart:state.cart
});

export default connect(importStateFromProps)(MenuListingRHS);
