import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class LeftBarRL extends Component {
    render() {
        return (
            <aside className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
        <div className="filter-wrapper">
          <div className="foodbakery-filters listing-filter">
            <div className="filter-holder panel-default sort-by-left">
              <div className="filter-heading">
                <h6>
                  <i className="icon-folder_special"></i>Sort By
                </h6>
              </div>
              <ul>
                <li className="active">
                  <Link to="#" className="sort-by-best_match">
                    <i className="icon-thumbs-up2"></i>Best Match
                  </Link>
                </li>
                <li>
                  <Link to="#" className="sort-by-alphabetical" onClick={this.props.parenttoggleAlphabeticalSort}>
                    <i className="icon-sort-alpha-asc"></i>Alphabetical
                  </Link>
                </li>
                <li>
                  <Link to="#" className="sort-by-ratings" onClick={this.props.parentToggleRatingSort}>
                    {" "}
                    <i className="icon-star-o"></i>Ratings
                  </Link>
                </li>
                <li>
                  <Link to="#" className="sort-by-minimum_order_value">
                    <i className="icon-user-minus"></i>Minimum order value
                  </Link>
                </li>
                <li>
                  <Link to="#" className="sort-by-delivery_fee" onClick={this.props.parentToggleDeliveryFeeSort}>
                    <i className="icon-dollar"></i>Delivery fee
                  </Link>
                </li>
                <li>
                  <Link to="#" className="sort-by-fastest_delivery">
                    <i className="icon-fast-forward"></i>Fastest delivery
                  </Link>
                </li>
              </ul>
            </div>
            <div className="filter-holder panel-default">
              <div className="filter-heading">
                <h6>
                  <i className="icon-food"></i>Cuisines
                </h6>
              </div>
              <div className="select-categories">
                <ul className="filter-list cs-checkbox-list">
                  <li>
                  <div className="Cusines-area">
                    <Link to="#" className="sort-by-delivery_fee Cusines-area" onClick={() =>this.props.parentCuisinesDataSort("Apple Juice")}>
                      <label htmlFor="foodbakery_restaurant_category_2">Apple Juice</label>
                    </Link>
                    </div>
                  </li>
                  <li>
                  <div className="Cusines-area">
                    <Link to="#" className="sort-by-delivery_fee Cusines-area" onClick={() =>this.props.parentCuisinesDataSort("Fresh LimeSoda")}>
                      <label htmlFor="foodbakery_restaurant_category_2">Fresh LimeSoda</label>
                    </Link>
                    </div>
                  </li>
                  <li>
                  <div className="Cusines-area">
                    <Link to="#" className="sort-by-delivery_fee Cusines-area" onClick={() =>this.props.parentCuisinesDataSort("Bread Toast")}>
                      <label htmlFor="foodbakery_restaurant_category_2">Bread Toast</label>
                    </Link>
                    </div>
                  </li>
                  <li>
                  <div className="Cusines-area">
                    <Link to="#" className="sort-by-delivery_fee Cusines-area" onClick={() =>this.props.parentCuisinesDataSort("Cheese Burst Pizza")}>
                      <label htmlFor="foodbakery_restaurant_category_2">Cheese Burst Pizza</label>
                    </Link>
                    </div>
                  </li>
                  <li>
                  <div className="Cusines-area">
                    <Link to="#" className="sort-by-delivery_fee Cusines-area" onClick={() =>this.props.parentCuisinesDataSort("Burgur")}>
                      <label htmlFor="foodbakery_restaurant_category_2">Burgur</label>
                    </Link>
                    </div>
                  </li>
                  <li>
                  <div className="Cusines-area">
                    <Link to="#" className="sort-by-delivery_fee Cusines-area" onClick={() =>this.props.parentCuisinesDataSort("Alfredo Pasta")}>
                      <label htmlFor="foodbakery_restaurant_category_2">Alfredo Pasta</label>
                    </Link>
                    </div>
                  </li>
                  <li className="expand">See more cuisines</li>
                </ul>
              </div>
            </div>
            <div className="filter-holder panel-default">
              <div className="filter-heading">
                <h6>
                  <i className="icon-folder_special"></i>Specials
                </h6>
              </div>
              <div className="select-categories">
                <ul className="filter-list cs-checkbox-list">
                  <li>
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        id="specials_1"
                        className="specials"
                        value="deals"
                      />
                      <label htmlFor="specials_1">Deals</label>
                      <span>(5)</span>
                    </div>
                  </li>
                  <li>
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        id="specials_2"
                        className="specials"
                        value="free-delivery"
                      />
                      <label htmlFor="specials_2">Free Delivery</label>
                      <span>(12)</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </aside>
        );
    }
}

export default LeftBarRL;
