export const FETCH_DATA_BEGIN                           =   'FETCH_DATA_BEGIN';
export const GOOGLE_MAP_ADDRESS                         =   'GOOGLE_MAP_ADDRESS';
export const FETCH_USER_PROFILE                         =   'FETCH_USER_PROFILE';
export const FETCH_RESTAURANT_PROFILE                   =   'FETCH_RESTAURANT_PROFILE'
export const FETCH_RESTAURANT_VIEW_PROFILE              =   'FETCH_RESTAURANT_VIEW_PROFILE'
export const SHOW_LOADER                                =   'SHOW_LOADER'
export const SHOW_ALERT                                 =   'SHOW_ALERT'
export const CATEGORY_LISTING                           =   'CATEGORY_LISTING'
export const MENU_LISTING                               =   'MENU_LISTING'
export const RESTAURANT_LISTING                         =   'RESTAURANT_LISTING'
export const MAMA_CART                                  =   'MAMA_CART'
export const FORCE_LOGIN_CLICK                          =   'FORCE_LOGIN_CLICK'
export const ORDER_LIST                                 =   'ORDER_LIST'
export const ADMIN_ORDER_LIST                           =   'ADMIN_ORDER_LIST'
export const ADMIN_USER_LIST                            =   'ADMIN_USER_LIST'
export const RECEPI_LISTING                             =   'RECEPI_LISTING'
export const ORDER_DETAIL                               =   'ORDER_DETAIL'
export const NEARBY_RESTAURANTS                         =   'NEARBY_RESTAURANTS'
export const RECEPI_VIEW                                =   'RECEPI_VIEW'
export const RECIPE_LIST                                =   'RECIPE_LIST'
export const RECIPE_INDIVIDUAL_INFO                     =   'RECIPE_INDIVIDUAL_INFO'
export const VIDEO_VIEW                                 =   'VIDEO_VIEW'
export const VIDEO_LISTING                              =   'VIDEO_LISTING'
export const FAVOURITE_RESTAURANTS                      =   'FAVOURITE_RESTAURANTS'
export const REVIEW_LISTING                             =   'REVIEW_LISTING'
export const FETCH_FOOD_DATA                            =   'FETCH_FOOD_DATA'
export const RECIPE_REVIEW_LISTING                      =   'RECIPE_REVIEW_LISTING'
export const FETCH_CATEGORY_LISTING                     =   'FETCH_CATEGORY_LISTING'
export const FETCH_SUBCATEGORY_LISTING                  =   'FETCH_SUBCATEGORY_LISTING'
export const FETCH_PRODUCT_LISTING                      =   'FETCH_PRODUCT_LISTING'
export const FETCH_PRODUCT_IMAGES_LISTING               =   'FETCH_PRODUCT_IMAGES_LISTING'
export const MAMA_CURRENCY                              =   'MAMA_CURRENCY'
export const LOCATION_INFORMATION_REQUEST               =   'LOCATION_INFORMATION_REQUEST'
export const LOCATION_INFORMATION_SUCCESS               =   'LOCATION_INFORMATION_SUCCESS'
export const LOCATION_INFORMATION_FALIURE               =   'LOCATION_INFORMATION_FALIURE'
export const LOCATION_INFORMATION_UPDATE_REQUEST        =   'LOCATION_INFORMATION_UPDATE_REQUEST'
export const LOCATION_INFORMATION_UPDATE_SUCCESS        =   'LOCATION_INFORMATION_UPDATE_SUCCESS'
export const LOCATION_INFORMATION_UPDATE_FALIURE        =   'LOCATION_INFORMATION_UPDATE_FALIURE'
export const CONTACT_INFORMATION_REQUEST                =   'CONTACT_INFORMATION_REQUEST'
export const CONTACT_INFORMATION_SUCCESS                =   'CONTACT_INFORMATION_SUCCESS'
export const CONTACT_INFORMATION_FALIURE                =   'CONTACT_INFORMATION_FALIURE'
export const CONTACT_INFORMATION_UPDATE_REQUEST         =   'CONTACT_INFORMATION_UPDATE_REQUEST'
export const CONTACT_INFORMATION_UPDATE_SUCCESS         =   'CONTACT_INFORMATION_UPDATE_SUCCESS'
export const CONTACT_INFORMATION_UPDATE_FALIURE         =   'CONTACT_INFORMATION_UPDATE_FALIURE'
export const OTHER_INFORMATION_REQUEST                  =   'OTHER_INFORMATION_REQUEST'
export const OTHER_INFORMATION_SUCCESS                  =   'OTHER_INFORMATION_SUCCESS'
export const OTHER_INFORMATION_FALIURE                  =   'OTHER_INFORMATION_FALIURE'
export const OTHER_INFORMATION_UPDATE_REQUEST           =   'OTHER_INFORMATION_UPDATE_REQUEST'
export const OTHER_INFORMATION_UPDATE_SUCCESS           =   'OTHER_INFORMATION_UPDATE_SUCCESS'
export const OTHER_INFORMATION_UPDATE_FALIURE           =   'OTHER_INFORMATION_UPDATE_FALIURE'
export const AGRO_LISTING_REQUEST                       =   'AGRO_LISTING_REQUEST'
export const AGRO_LISTING_SUCCESS                       =   'AGRO_LISTING_SUCCESS'
export const AGRO_LISTING_FALIURE                       =   'AGRO_LISTING_FALIURE'
export const UPLOAD_INFORMATION_UPDATE_REQUEST          =   'UPLOAD_INFORMATION_UPDATE_REQUEST'
export const UPLOAD_INFORMATION_UPDATE_SUCCESS          =   'UPLOAD_INFORMATION_UPDATE_SUCCESS'
export const UPLOAD_INFORMATION_UPDATE_FALIURE          =   'UPLOAD_INFORMATION_UPDATE_FALIURE'
export const UPLOAD_INFORMATION_REQUEST                 =   'UPLOAD_INFORMATION_REQUEST'
export const UPLOAD_INFORMATION_SUCCESS                 =   'UPLOAD_INFORMATION_SUCCESS'
export const UPLOAD_INFORMATION_FALIURE                 =   'UPLOAD_INFORMATION_FALIURE'
export const AGRO_PROFILE_REQUEST                       =   'AGRO_PROFILE_REQUEST'
export const AGRO_PROFILE_SUCCESS                       =   'AGRO_PROFILE_SUCCESS'
export const AGRO_PROFILE_FALIURE                       =   'AGRO_PROFILE_FALIURE'
export const FORCE_AGRO_LOGIN_CLICK                     =   'FORCE_AGRO_LOGIN_CLICK'
export const FETCH_CONDIMENT_LISTING                    =   'FETCH_CONDIMENT_LISTING'
export const FETCH_CONDIMENT_VIEW                       =   'FETCH_CONDIMENT_VIEW'
export const CONDIMENT_CART                             =   'CONDIMENT_CART'
export const FORCE_CONDIMENT_MODEL_OPEN                 =   'FORCE_CONDIMENT_MODEL_OPEN'
export const AGRO_REVIEW_LISTING                        =   'AGRO_REVIEW_LISTING'
export const CONDIMENTS_REVIEW_LISTING                  =   'CONDIMENTS_REVIEW_LISTING'
export const LIKE_AND_DISLIKE_REQUEST                   =   'LIKE_AND_DISLIKE_REQUEST'
export const LIKE_AND_DISLIKE_SUCCESS                   =   'LIKE_AND_DISLIKE_SUCCESS'
export const LIKE_AND_DISLIKE_FALIURE                   =   'LIKE_AND_DISLIKE_FALIURE'
export const AGRO_LIKE_AND_DISLIKE_LISTING              =   'AGRO_LIKE_AND_DISLIKE_LISTING'
export const COOKING_CLASS_LISTING                      =   'COOKING_CLASS_LISTING'
export const COOKING_CLASS_VIEW                         =   'COOKING_CLASS_VIEW'
export const ALL_RESTAURANT_COOKING_CLASS_LISTING       =   'ALL_RESTAURANT_COOKING_CLASS_LISTING'
export const BOOKING_CLASS_DETAIL                       =   'BOOKING_CLASS_DETAIL'
export const FILTER_RESTAURANT_ID                       =   'FILTER_RESTAURANT_ID'
export const PAYMENT_LIST                               =   'PAYMENT_LIST'
export const FETCH_VIDEO_LIST                           =   'FETCH_VIDEO_LIST'
export const FOOD_CONTACT_INFORMATION_REQUEST                =   'FOOD_CONTACT_INFORMATION_REQUEST'
export const FOOD_CONTACT_INFORMATION_SUCCESS                 = 'FOOD_CONTACT_INFORMATION_SUCCESS'
export const FOOD_CONTACT_INFORMATION_FALIURE                 = 'FOOD_CONTACT_INFORMATION_FALIURE'
export const FOOD_LOCATION_INFORMATION_REQUEST                =   'FOOD_LOCATION_INFORMATION_REQUEST'
export const FOOD_LOCATION_INFORMATION_SUCCESS                 = 'FOOD_LOCATION_INFORMATION_SUCCESS'
export const FOOD_LOCATION_INFORMATION_FALIURE                 = 'FOOD_LOCATION_INFORMATION_FALIURE'
export const FOOD_OTHER_INFORMATION_REQUEST                =   'FOOD_OTHER_INFORMATION_REQUEST'
export const FOOD_OTHER_INFORMATION_SUCCESS                 = 'FOOD_OTHER_INFORMATION_SUCCESS'
export const FOOD_OTHER_INFORMATION_FALIURE                 = 'FOOD_OTHER_INFORMATION_FALIURE'
export const FOOD_LISTING_REQUEST                =   'FOOD_LISTING_REQUEST'
export const FOOD_LISTING_SUCCESS                 = 'FOOD_LISTING_SUCCESS'
export const FOOD_LISTING_FALIURE                 = 'FOOD_LISTING_FALIURE'
export const FORCE_FOOD_LOGIN_CLICK                          =   'FORCE_FOOD_LOGIN_CLICK'
export const BOOK_FOOD_CENTER_TABLE_LISTING_REQUEST                =   'BOOK_FOOD_CENTER_TABLE_LISTING_REQUEST'
export const BOOK_FOOD_CENTER_TABLE_LISTING_SUCCESS                 = 'BOOK_FOOD_CENTER_TABLE_LISTING_SUCCESS'
export const BOOK_FOOD_CENTER_TABLE_LISTING_FALIURE                 = 'BOOK_FOOD_CENTER_TABLE_LISTING_FALIURE'
export const FOOD_PROFILE_REQUEST                       =   'FOOD_PROFILE_REQUEST'
export const FOOD_PROFILE_SUCCESS                       =   'FOOD_PROFILE_SUCCESS'
export const FOOD_PROFILE_FALIURE                       =   'FOOD_PROFILE_FALIURE'
export const FOOD_REVIEW_LISTING                        =   'FOOD_REVIEW_LISTING'
export const FOOD_CENTER_LIKE_AND_DISLIKE_REQUEST                   =   'FOOD_CENTER_LIKE_AND_DISLIKE_REQUEST'
export const FOOD_CENTER_LIKE_AND_DISLIKE_SUCCESS                   =   'FOOD_CENTER_LIKE_AND_DISLIKE_SUCCESS'
export const FOOD_CENTER_LIKE_AND_DISLIKE_FALIURE                   =   'FOOD_CENTER_LIKE_AND_DISLIKE_FALIURE'
export const FOOD_LIKE_AND_DISLIKE_LISTING              =   'FOOD_LIKE_AND_DISLIKE_LISTING'
export const FORCE_LOGIN_CLICK_HEADER_UPDATE              =   'FORCE_LOGIN_CLICK_HEADER_UPDATE'
export const ADMIN_AGRO_USER_LIST              =   'ADMIN_AGRO_USER_LIST'
export const ADMIN_FOOD_USER_LIST              =   'ADMIN_FOOD_USER_LIST'
export const PARTICULAR_FOOD_BOOKINGS_LIST              =   'PARTICULAR_FOOD_BOOKINGS_LIST'
export const FORCE_MAMA_LOGIN_CLICK              =   'FORCE_MAMA_LOGIN_CLICK'
export const FOOD_UPLOAD_INFORMATION_UPDATE_SUCCESS          =   'FOOD_UPLOAD_INFORMATION_UPDATE_SUCCESS'
export const FOOD_UPLOAD_INFORMATION_UPDATE_FALIURE          =   'FOOD_UPLOAD_INFORMATION_UPDATE_FALIURE'
export const FOOD_UPLOAD_INFORMATION_REQUEST                 =   'FOOD_UPLOAD_INFORMATION_REQUEST'
export const FOOD_UPLOAD_INFORMATION_SUCCESS                 =   'FOOD_UPLOAD_INFORMATION_SUCCESS'
export const FOOD_UPLOAD_INFORMATION_FALIURE                 =   'FOOD_UPLOAD_INFORMATION_FALIURE'
export const ADMIN_DATA_COUNT_LIST                 =   'ADMIN_DATA_COUNT_LIST'
export const PACKAGE_LISTING                 =   'PACKAGE_LISTING'
export const PACKAGE_VIEW                 =   'PACKAGE_VIEW'
export const FETCH_AGROCENTER_PRODUCT_LISTING       =   'FETCH_AGROCENTER_PRODUCT_LISTING'
export const FETCH_AGRO_PRODUCT_IMAGES_LISTING      =   'FETCH_AGRO_PRODUCT_IMAGES_LISTING'
export const FETCH_GLOBAL_AGRO_PRODUCT_LISTING      =   'FETCH_GLOBAL_AGRO_PRODUCT_LISTING'
export const MEMBERSHIP_LISTING                 =   'MEMBERSHIP_LISTING'
export const MEMBERSHIP_VIEW                 =   'MEMBERSHIP_VIEW'
export const FACILITY_LISTING                 =   'FACILITY_LISTING'
export const FACILITY_VIEW                 =   'FACILITY_VIEW'
export const AGRO_PRODUCT_CART                             =   'AGRO_PRODUCT_CART'
export const AGRO_PRODUCT_MODEL_OPEN            =   'AGRO_PRODUCT_MODEL_OPEN'
export const FETCH_AGRO_IMAGES_LISTING            =   'FETCH_AGRO_IMAGES_LISTING'
export const FETCH_AGRO_VIDEOS_LISTING            =   'FETCH_AGRO_VIDEOS_LISTING'
export const VIEW_AGRO_PRODUCT                     =     'VIEW_AGRO_PRODUCT'
export const AGRO_PRODUCT_REVIEW_LISTING            =   'AGRO_PRODUCT_REVIEW_LISTING'
export const FETCH_CONTACT_US               =   "FETCH_CONTACT_US"
export const NEARBY_LOCATIONS               =   "NEARBY_LOCATIONS"
