import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import ReactJWPlayer from "react-jw-player";

const initialState = {
    show: false,
    video: "",
};
class LiveStreamPlayer extends Component {
    state = initialState;

    handleClose = () => {
        this.setState({ ...initialState }, () => {
            this.props.onCloseStreamModal();
        });
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.video) {
            this.setState({
                show: true,
                video: newProps.video,
            });
        }
    }

    render() {
        const { video, show } = this.state;
        return (
            <Modal show={show} onHide={this.handleClose} size="lg">
                <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <ReactJWPlayer
                        className="live-video-section"
                        playerId="player"
                        playerScript="https://cdn.jwplayer.com/libraries/lqsWlr4Z.js"
                        file={video}
                        image="https://beta-stream.meetn.com/player/livestream_og_image.jpg"
                    />
                    <div id="player"></div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        );
    }
}

export default connect(null, null)(LiveStreamPlayer);
